import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { 
  StyledContainer3,
  StyledContainer4,
  StylesCss,
} from '../ScoreTask.styled';

interface Props {
  notes: string,
}

const WritingBody = ({
  notes,
}: Props) => {
  const theme = useTheme();
  const Styles = StylesCss(theme);
  return (
    <StyledContainer3 padding={theme.spacing(3)}>
      <StyledContainer4>
        {
          notes.length > 0
            ? (
              <Typography variant='sLabel' sx={Styles.WritingBodyTitle}>
                {notes}
              </Typography>
            )
            : (
              <Typography variant="sLabel" sx={Styles.noData}>
                No Data Found
              </Typography>
            )
        }

      </StyledContainer4>
    </StyledContainer3>
  );
};

export default React.memo(WritingBody);
