import { styled } from '@mui/material';
import { Box, TableContainer } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 300px)',
});

export const StyledContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(0.5),
  width: '100%',
}));

export const StyledContentContainerBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  marginTop: theme.spacing(0.25),
  width: '100%',
}));