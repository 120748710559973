import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';

export const WelcomeMessage = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: 8,
});

export const StyledButton = styled(Button)({
  height: '50px',
});
