import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import GroupList from './GroupsTable';
import ManageGroup from './ManageGroup/index';

const Groups = () => (
  <Layout>
    <Switch>
      <Route
        path="/groups"
        component={GroupList}
        exact
      />
      <Route
        path="/groups/:id/manage"
        component={ManageGroup}
        exact
      />
    </Switch>
  </Layout>
);

export default Groups;
