import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BenchmarkIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}>
    <path d="M8.39392 0.080855C8.14237 -0.0269517 7.85763 -0.0269517 7.60608 0.080855L0.606081 3.08085C0.238397 3.23843 0 3.59997 0 4C0 4.40003 0.238397 4.76157 0.606081 4.91915L3.25037 6.05241C3.34653 5.94347 3.46706 5.85473 3.60608 5.79515L7.60608 4.08087C8.11371 3.86331 8.70159 4.09846 8.91915 4.60609C9.1367 5.11372 8.90155 5.7016 8.39392 5.91916L5.66668 7.08797L7.60608 7.91915C7.85763 8.02695 8.14237 8.02695 8.39392 7.91915L15.3939 4.91915C15.7616 4.76157 16 4.40003 16 4C16 3.59997 15.7616 3.23843 15.3939 3.08085L8.39392 0.080855Z"  fill={props?.pathfill}/>
    <path d="M1.31004 7.39673L3 8.121V12.2226C2.65723 12.1449 2.30705 12.0867 1.95071 12.0494C1.48094 12.0001 1.1097 11.6289 1.06044 11.1591C1.02046 10.7778 1 10.391 1 9.99983C1 9.10331 1.10741 8.23149 1.31004 7.39673Z"  fill={props?.pathfill}/>
    <path d="M7.29996 14.5725C6.62708 13.9129 5.85167 13.3584 5 12.9351V8.97814L6.81824 9.75739C7.57289 10.0808 8.42711 10.0808 9.18176 9.75739L14.69 7.39673C14.8926 8.23149 15 9.10331 15 9.99983C15 10.391 14.9795 10.7778 14.9396 11.1591C14.8903 11.6289 14.5191 12.0001 14.0493 12.0494C11.9765 12.2667 10.1124 13.188 8.70004 14.5725C8.31119 14.9537 7.68881 14.9537 7.29996 14.5725Z"  fill={props?.pathfill}/>
    <path d="M4 16C4.55228 16 5 15.5523 5 15V12.9351C4.37136 12.6227 3.70117 12.3817 3 12.2226V15C3 15.5523 3.44772 16 4 16Z"  fill={props?.pathfill}/>
  </SvgIcon>
);

export default BenchmarkIcon;
