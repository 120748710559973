import { useEffect, useMemo } from 'react';
import { useScoreSchemeListQuery } from '../../generated/graphql';

// Must provide setValue if shouldDefault is set to true
interface Params {
  shouldDefault?: boolean
  value?: string
  setValue?: (value: string) => void
}

const useScoreSchemeRadioGroup = ({ shouldDefault, value, setValue }: Params) => {
  const { data: scoreSchemesDetails, loading: scoreSchemesDetailsLoading } = useScoreSchemeListQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!value && setValue && shouldDefault && scoreSchemesDetails?.scoreSchemeList) {
      setValue(scoreSchemesDetails?.scoreSchemeList?.find((item) => item.title_key === 'default_scheme')?.id ?? '');
    }
  }, [shouldDefault, scoreSchemesDetails, setValue, value]);

  const scoreSchemeOptions = useMemo(() => scoreSchemesDetails?.scoreSchemeList?.map((scoreScheme) => ({
    id: scoreScheme.id,
    value: scoreScheme.id,
    label: scoreScheme.title,
  })), [scoreSchemesDetails]);

  return {
    value,
    scoreSchemeOptions,
    scoreSchemesDetailsLoading,
  };
};

export default useScoreSchemeRadioGroup;
