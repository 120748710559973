import React from 'react';
import { Box, Typography } from '@mui/material';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { StaticResource } from '../../../generated/graphql';
import useUpload from './upload-image-hook';
import ImageCard from '../../../components/ImageCard';
import { ImageBox, StyledLeftArrow, StyledNavIcon, StyledScrollGradient } from '../ImageUpload.styled';
import { palette  } from '../../../theme/palette';
import { useHistory } from 'react-router-dom';

const styles = {
  menuWrapper: {
    width: '100%',
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },
  menuItemWrapper: {
    '&:focus': {
      outline: 'none',
    },
  },
};

interface Props {
  selected?: string,
  disableTile?: (selectedItemKey : string | number | null) => void;
  resourceId?: string,
  editableField?: boolean,
}


const ImageLibrary = ({ selected }: Props) => {
  const { setGrades } = useUpload();
  const history = useHistory();
  const routePath = window.location.href.indexOf('benchmarks') > -1;

  const ArrowLeft = () => {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    if (isFirstItemVisible) {
      return null;
    }
    return (
      <StyledLeftArrow onClick={() => scrollPrev()}>
        <StyledNavIcon pathFill={palette.customBlack.main} />
      </StyledLeftArrow>
    );
  };
  const ArrowRight = () => {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    if (isLastItemVisible) {
      return null;
    }
    return (
      <StyledScrollGradient onClick={() => scrollNext()}>
        <StyledNavIcon pathFill={palette.customBlack.main} />
      </StyledScrollGradient>
    );
  };

  const handleEditImage = (imgId: string) => {
    history.push(routePath ? `/benchmarks/manage-images/${imgId}` : `/settings/manage-images/${imgId}`);
  };

  const renderCards = (rows: StaticResource[]) => rows
    .map((row: StaticResource) => (
      <ImageCard
        imageSrc={row?.file?.url || ''}
        thumbnailImageSrc={row?.file?.thumbnail_url || ''}
        cardTitle={row.title}
        key={row.id}
        selected={selected}
        editable={true}
        handleEdit = {() => handleEditImage(row.id)}
      />
    ));

  const renderRows = () => {
    const rows = [];
    for (const [key, value] of Object.entries(setGrades)) {
      // @ts-ignore
      const resources: StaticResource[] = value;
      rows.push(
        <Box
          position="relative"
          width="100%"
        >
          <Typography variant='sFormTitle' component='p'>{`Grade ${key}`}</Typography>
          <Box sx={{ ...styles.menuWrapper, ...styles.menuItemWrapper }}>
            <ScrollMenu
              LeftArrow={ArrowLeft}
              RightArrow={ArrowRight}
              transitionBehavior="smooth"
            >
              {renderCards(resources)}
            </ScrollMenu>
          </Box>
        </Box>,
      );
    }
    return rows;
  };

  return (
    <>
      <ImageBox>
        <Typography variant='sContentHeader'>All Images</Typography>
        {renderRows()}
      </ImageBox>
    </>
  );
};

export default React.memo(ImageLibrary);
