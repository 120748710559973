import React from 'react';
import { Box, Typography } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';
import Loader from '../../../../components/Loader/loader';
import { File as APIFIle, SubmissionStatus } from '../../../../generated/graphql';
import MiniPlayerWithDeleteButton from '../../../../components/MiniPlayerWithDeleteButton';
import Recorder from '../../../../components/Recorder';
import { ScoreTabs } from '../../../../variables/types';
import {
  StyledContainer13,
  StyledContainer14,
  StyledContainer15,
  StyledStudentFeedbackInput,
} from '../../ScoreTask.styled';
import { ASSIGNMENT_PROMPT_DELAY_TIME } from '../../../../variables/constant';

interface Props {
  loading: boolean,
  onRecord: (file: File) => void,
  audioData: Pick<APIFIle, 'url' | 'file_type'> | null,
  handleStartRecording: (state: boolean) => void,
  isPlaying: boolean,
  notes: string,
  updateNotes: Function,
  onDelete: () => Promise<void>,
  submissionStatus: string,
  setIsPlaying?: Function,
}

const FeedbackRow = ({
  loading,
  onRecord,
  audioData,
  handleStartRecording,
  isPlaying,
  notes,
  updateNotes,
  onDelete,
  submissionStatus,
  setIsPlaying,
}: Props) => {
  const location = useLocation();

  return (
    <StyledContainer14>
      <StyledContainer13>
        <Loader open={loading} />
        <Typography variant='sContentHeader'>
          Provide Student Feedback (Optional)
        </Typography>
        <Box>
          <StyledStudentFeedbackInput
            placeholder="Type Here"
            defaultValue={notes || ''}
            autoFocus
            multiline
            rows={5}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateNotes(event.target.value?.trim());
            }}
            disabled={submissionStatus !== SubmissionStatus.Submitted}
          />
        </Box>
        {
          matchPath(location.pathname, `/tasks/:id/score/${ScoreTabs.Speaking}`) || matchPath(location.pathname, `/tasks/:id/benchmark/score/${ScoreTabs.Speaking}`) ? (
            <Box ml={1}>
              <Typography variant='sLabel'>
                You will be able to record feedback on the next screen
              </Typography>
            </Box>
          ) : (
            <StyledContainer15>
              <Typography variant="font22" sx={{ alignSelf: 'center' }}>
                Record a message
              </Typography>
              <Box display="flex" flexDirection="row-reverse">
                {
                  audioData ? (
                    <MiniPlayerWithDeleteButton
                      url={audioData?.url}
                      fileType={audioData?.file_type}
                      onDelete={onDelete}
                      disableDelete={submissionStatus === SubmissionStatus.Reviewed}
                      setIsPlaying={setIsPlaying}
                    />
                  ) : (
                    <Recorder
                      disable={isPlaying || submissionStatus !== SubmissionStatus.Submitted}
                      onRecord={onRecord}
                      setIsRecording={(state: boolean) => handleStartRecording(state)}
                      showElapsedTime
                      recordButtonHeight={60}
                      recordButtonWidth={60}
                      delayTime={ASSIGNMENT_PROMPT_DELAY_TIME}
                      maxRecordingTime={300}
                    />
                  )
                }
              </Box>
            </StyledContainer15>
          )
        }
      </StyledContainer13>
    </StyledContainer14>
  );
};

export default FeedbackRow;
