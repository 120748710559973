import React, { useEffect } from 'react';
import { Box, CheckboxProps, Divider, InputAdornment, SxProps, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SchoolsIcon from '../Icons/SchoolsIcon';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import { SchoolValue } from '../SchoolFilterTeacher';

interface Props {
  schools: SchoolValue[];
  error?: boolean;
  value?: any[] | null;
  onChange?: (data: SchoolValue[] | string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: any;
  limitTags?: number;
  isStartAdornment?: boolean;
  zIndexPopper?: number;
}

const styles = {
  textField: {
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    '& input.MuiInputBase-input.Mui-disabled': {
      backgroundColor: palette.customWhite.inputDisabled,
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '30px !important',
      zIndex: 2,
    },
  },
  box: {
    backgroundColor: palette.customWhite.main,
    height: '45px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

const MultiSelectTeacherSchoolFilter = ({
  placeholder = '',
  onChange,
  value,
  errorMessage,
  disabled,
  limitTags = 3,
  schools,
  isStartAdornment = true,
  zIndexPopper = 1300,
}: Props) => {

  const allSchoolsOption: SchoolValue = {
    id: 'all',
    name: ' All Schools',
  };

  const schoolOptions = schools?.length ? [allSchoolsOption, ...schools] : schools;
  const [checkAll, setCheckAll] = React.useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onSelectChange = (_: any, schoolList: SchoolValue[] | undefined = []) => {
    const schoolListIds = schoolList?.map((school: SchoolValue) => school?.id);
    onChange?.(schoolListIds as string[] || []);
  };
  const filterIdsWithSchools = (selectedValue: any[] | undefined | null) => {
    const schoolIds = schools?.map((school: any) => school.id) ?? [];
    const filteredIds = selectedValue?.filter((id) => schoolIds.includes(id)) ?? [];
    const schoolList = schools?.filter((school) => filteredIds.includes(school?.id));
    if (selectedValue?.length !== filteredIds.length && schools) onChange?.(schoolList?.map((school: any) => school?.id) ?? []);
    if (selectedValue?.length === 0 || selectedValue?.length !== schools?.length) {
      setCheckAll(false);
    } else if (selectedValue?.length === schools?.length) {
      setCheckAll(true);
    }
  };
  useEffect(() => {
    filterIdsWithSchools(value);
  }, [value]);

  const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      onChange?.(schools?.map((School: any) => School?.id) as string[]);
    } else {
      onChange?.([]);
    }
  };
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={limitTags}
      options={schoolOptions ?? []}
      getOptionLabel={(option: any) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={disabled}
      onChange={onSelectChange}
      slotProps={{ popper: { sx: { zIndex: zIndexPopper } } }}
      value={schools?.filter((school: any) => value?.includes(school?.id!))}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Box sx={{ maxWidth: '120px' }}>
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.name ?? ''}</Typography>}
              {...getTagProps({ index })}
            />
          </Box>
        ))
      }
      renderOption={(props, option: any, { selected }) => (
        option?.id === 'all' ? (
          <>
            <Box sx={{ ...styles.box } as SxProps} key={option?.id}>
              <Checkbox
                checked={checkAll}
                onChange={checkAllChange}
                id="check-all"
                onMouseDown={(e) => e.preventDefault()}
              />
              Select All
            </Box>
            <Divider />
          </>
        ) : <Box component="li" {...props} key={option?.id}>
          <Checkbox
            color={'primary' as CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
          />
          {`${option?.name ?? ''}`}
        </Box>
      )}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            sx={{ ...styles.textField }}
            placeholder={params.InputProps.startAdornment ? undefined : placeholder.length > 1 ? placeholder : 'Select At Least 1 School Per Download'}
            error={!!value}
            helperText={value?.length === 0 ? errorMessage?.schoolHelperText : ''} 
            required={value?.length === 0}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '300px', overflow: 'scroll', fontSize: '16px', background: palette.customWhite.main, zIndex: zIndexPopper },
              startAdornment: isStartAdornment ? (
                <>
                  <InputAdornment position="start">
                    <SchoolsIcon
                      pathfill={palette.customBlue.primaryBlue}
                      sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ) : <>{params.InputProps.startAdornment}</>,
            }}
          />
        </>
      )}
    />
  );
};

export default MultiSelectTeacherSchoolFilter;
