import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StudentsIcon } from '../../../components/Icons';
import { openSnackbar } from '../../../components/Notifier';
import { useAuthProvider } from '../../../core/authContext';
import {
  StudentsSectionFragFragment,
  useGetStudentsWithClassesQuery,
  useGetTeacherByUserQuery,
  useRemoveStudentsFromClassesMutation,
} from '../../../generated/graphql';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';

interface Props {
  studentIDs?: string[]
  refetch: () => void;
  closeDialog?: (type?: string | undefined, isForm?: boolean | undefined) => void;
}

export interface SelectedStudentClass {
  studentID: string,
  sectionIDs: string[]
}


const useRemoveStudentFromClass = ({ studentIDs, refetch, closeDialog }: Props) => {
  const [selectedClasses, setSelectedClasses] = useState<SelectedStudentClass[]>();
  const [students, setStudents] = useState<StudentsSectionFragFragment[]>();
  const history = useHistory();
  const { getUser } = useAuthProvider();
  const user = getUser();

  const { data: studentsData, loading: studentsLoading } = useGetStudentsWithClassesQuery({
    fetchPolicy: 'network-only',
    variables: {
      studentIDs: studentIDs,
    },
    skip: !studentIDs,
  });

  const { data: teacherDetails } = useGetTeacherByUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      userID: user.id,
    },
    skip: !user.id,
  });

  const onSelectClass = (studentId: string, classId: string) => {
    // Find the student in the selectedClass array
    const allSelectedStudents = selectedClasses ?? [];
    const student = allSelectedStudents.find((s) => s.studentID === studentId);
    if (student) {
      const sectionIndex = student.sectionIDs.indexOf(classId);
      if (sectionIndex !== -1) {
        student.sectionIDs.splice(sectionIndex, 1);
      } else {
        student.sectionIDs.push(classId);
      }
      const indexOfStudent = allSelectedStudents.findIndex((st) => st.studentID == student.studentID);
      allSelectedStudents[indexOfStudent] = student;
      const sts = allSelectedStudents.filter((item) => item.sectionIDs.length > 0);
      setSelectedClasses([...sts]);
    } else {
      allSelectedStudents.push({ studentID: studentId, sectionIDs: [classId] });
      setSelectedClasses([...allSelectedStudents]);
    }
  };

  useEffect(() => {
    if (studentsData?.getStudentsWithClasses) {
      setStudents(studentsData?.getStudentsWithClasses);
    }
  }, [studentsData]);

  const onRemoveStudent = (id: string) => {
    const index =  students?.findIndex(student => student.id === id);
    if (index != -1) {
      students?.splice(index!, 1);
      setStudents([...students!]);
      if (students && students?.length === 0) closeDialog?.();
    }
  };
  const [removeStudentsFromClasses, { loading: removeLoading }] = useRemoveStudentsFromClassesMutation();
  const removeStudentFromClass = async (selectedClass?: string) => {
    let studentClassMapping = selectedClasses ?? [];
    if (studentClassMapping.length || selectedClass) {
      if (selectedClass) {
        studentClassMapping = students?.map((stu) => {
          return { studentID:stu?.id, sectionIDs: stu.section?.filter((sect) => sect.id === selectedClass).map((sec) => sec.id) ?? []  } as SelectedStudentClass;
        }) ?? [];
      }
      try {
        const { data: removedStudents } =  await removeStudentsFromClasses({
          variables:{
            input: {
              students: [...studentClassMapping!]!,
            },
          },
        });
        if (removedStudents?.removeStudentsFromClasses?.length){
          refetch();
          closeDialog?.();
          openSnackbar({
            message: 'Student(s) removed from selected class(es)',
            customIcon: StudentsIcon,
            actionButtonText: 'View',
            onActionButtonClick: () => { history.push('/students'); },
          }, NotifierType.Success);
        }
      } catch (err) {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    } else {
      openSnackbar({ message: 'Please select which class(es) they should be removed from.' }, NotifierType.Error);
    }
  };

  const getSingleSection = () => {
    const hasMultipleClasses = students?.some((student) => student.section && student.section.length !== 1);
    const hasDifferentClasses = students?.some((student) => student?.section?.[0]?.id !== students?.[0]?.section?.[0]?.id);
    const sectionId = !hasMultipleClasses && !hasDifferentClasses ? students?.[0]?.section?.[0]?.id : '';
    return sectionId;
  };

  return {
    loading: studentsLoading || removeLoading,
    students,
    onSelectClass,
    selectedClasses,
    onRemoveStudent,
    removeStudentFromClass,
    sectionId: getSingleSection(),
    teacher: teacherDetails?.teacherByUser,
  };
};

export default useRemoveStudentFromClass;
