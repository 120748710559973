/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const AppleIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    {...props}
  >
    <path d="M8.87767 1.35714C10.5609 1.9223 11.3807 4.76224 11.4031 6.24133C10.343 5.76153 8.68458 5.45822 6.7337 5.45822C3.77858 5.45822 0.554811 6.91419 0.621973 13.1541C0.621973 17.7994 4.58451 25.4952 9.01719 25.4952C11.0982 25.4952 12.1056 24.8317 12.1056 24.5939C12.2399 25.0397 14.2548 25.4952 15.3294 25.4952C19.7621 25.4952 23.7246 17.7994 23.7246 13.1541C23.7918 6.91419 20.568 5.45822 17.6129 5.45822C15.8431 5.45822 14.4212 5.78694 13.3285 6.24133C12.9323 1.73592 10.7458 0.441047 10.1537 0.178408C9.08333 -0.29636 8.0759 1.08799 8.87767 1.35714Z" fill={palette.customBlue.primaryBlue} />
  </SvgIcon>
);

export default AppleIcon;
