/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SolidPhotograph = (props: any) => (
  <SvgIcon
    fontSize="medium"
    width="24"
    height="17"
    viewBox="0 0 20 15"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V12C0 13.1046 0.895431 14 2 14H14C15.1046 14 16 13.1046 16 12V2C16 0.895431 15.1046 0 14 0H2ZM14 12H2L6 4L9 10L11 6L14 12Z"
    />
  </SvgIcon>
);

export default React.memo(SolidPhotograph);
