import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledContainer1, StyledContainer10, StyledRecordingContainer } from '../ScoreTask/ScoreTask.styled';
import { ScoreTabs } from '../../variables/types';
import { PromptFile } from '../../generated/graphql';
import ScoringHeader from '../ScoreTask/Common/ScoringHeader';
import AudioPlayer from '../../components/Player';
import { StyledFlexColBoxContainer } from '../../components/Common/Common.styled';
import { unescapeSpecialCharacters } from '../../utils/handleEscapeCharacters';

interface PTPromtCardProps {
  taskType: string
  benchmark: any | undefined
}

const PTPromptCard = ({
  taskType,
  benchmark,
}: PTPromtCardProps) => {
  const theme = useTheme();

  let promptContent: string;
  let promptRecording: Pick<PromptFile, 'id' | 'url' | 'file_type'> | undefined;
  if (taskType === ScoreTabs.Speaking || benchmark?.is_common_prompt) {
    promptContent = benchmark?.speaking_prompt ?? '';
    if (benchmark?.speaking_prompt_file) {
      promptRecording = benchmark?.speaking_prompt_file;
    }
  } else {
    promptContent = benchmark?.writing_prompt ?? '';
    if (benchmark?.writing_prompt_file) {
      promptRecording = benchmark?.writing_prompt_file;
    }
  }

  return (
    <StyledFlexColBoxContainer sx={{ cursor: 'pointer' }}>
      <ScoringHeader
        title={`${taskType} Prompt`}
      />
      <StyledContainer1>
        {
          (promptContent.length <= 0 && promptRecording === undefined) ? (
            <StyledContainer10>
              <Typography variant="rubricOrderContent"
                sx={{
                  color: theme.palette.customBlack.lightBlack,
                }}
              >
                {`No ${taskType} Prompt Assigned.`}
              </Typography>
            </StyledContainer10>
          ) : (
            <Box>
              <StyledContainer10>
                {promptContent.length > 0
                  ? (
                    <Typography variant='sLabel' sx={{ letterSpacing: '1px' }} >
                        {unescapeSpecialCharacters(promptContent)}
                    </Typography>
                  )
                  : ('')}
              </StyledContainer10>
              {
                promptRecording !== undefined ? (
                  <StyledRecordingContainer
                    sx={{ minHeight: theme.spacing(10), ml: theme.spacing(-2) }}
                    key={promptRecording?.id}
                  >
                    <AudioPlayer
                      url={promptRecording?.url}
                      fileType={promptRecording?.file_type}
                      flexStructure={false}
                    />
                  </StyledRecordingContainer>
                ) : ('')
              }
            </Box>
          )
        }
      </StyledContainer1>
    </StyledFlexColBoxContainer>
  );
};

export default PTPromptCard;
