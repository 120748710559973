import React, { useContext } from 'react';
import {
  OrderBy,
  DistrictWideGradeSpecificDetailsSortType,
  useDistrictWideGradeSpecificDataQuery,
} from '../../../../generated/graphql';
import { PAGE_SIZE } from '../../../../variables/constant';
import usePagination from '../../../../utils/usePagination';
import {
  DistrictWideGradeSpecificQueryInput,
  DistrictWideComparisonsContext,
} from '../district-wide-comparisons-hook';

interface Props {
  districtId?: string;
  schoolId?: string;
  gradeId: string,
}

const useGradeSpecificDetails = ({ districtId, schoolId, gradeId }: Props) => {
  const {
    gradeSpecificDataQueryInput, setGradeSpecificDataQueryInput,
  } = useContext(DistrictWideComparisonsContext);

  const updateGradeSpecificDataQueryInput = (data: Object) => {
    setGradeSpecificDataQueryInput?.({
      ...gradeSpecificDataQueryInput,
      ...data,
    });
  };

  const pagination = usePagination('gradeSpecificPage');

  var queryInput: DistrictWideGradeSpecificQueryInput = gradeSpecificDataQueryInput ? gradeSpecificDataQueryInput : {
    grade_id: gradeId,
    limit: PAGE_SIZE,
    page: pagination.page,
    sort: DistrictWideGradeSpecificDetailsSortType.Teacher,
    orderBy: OrderBy.Asc,
  };
  if (schoolId) queryInput.school_id = schoolId;
  if (districtId) queryInput.district_id = districtId;

  const updateQueryInput = (queryInputUpdate: DistrictWideGradeSpecificQueryInput) => {
    queryInput = {
      ...queryInput,
      ...queryInputUpdate,
    };
    updateGradeSpecificDataQueryInput(queryInput);
  };

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    updateQueryInput({
      grade_id: gradeId,
      page: value as number,
    });
  };

  const onSortChange = (sortBy: DistrictWideGradeSpecificDetailsSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    updateQueryInput({
      grade_id: gradeId,
      page: 1,
      sort: sortBy,
      orderBy: orderBy,
    });
  };

  const handleSort = (column: DistrictWideGradeSpecificDetailsSortType) => () => {
    if (column === queryInput.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const { data, loading: districtWideGradeSpecificDataLoading } = useDistrictWideGradeSpecificDataQuery({
    fetchPolicy: 'network-only',
    variables: { ...queryInput },
    skip: !gradeId,
  });

  const districtWideGradeSpecificData = data?.districtWideGradeSpecificData?.districtWideGradeSpecificDetails ?? [];
  const districtWideGradeSpecificDataCount = data?.districtWideGradeSpecificData?.districtWideGradeSpecificDetailsCount ?? 0;
  const isTableEmpty = !districtWideGradeSpecificDataLoading && districtWideGradeSpecificDataCount === 0;

  return ({
    loading: districtWideGradeSpecificDataLoading,
    queryInput, handleSort,
    pagination, handlePageChange,
    districtWideGradeSpecificDataCount, districtWideGradeSpecificData, isTableEmpty,
  });
};

export default useGradeSpecificDetails;
