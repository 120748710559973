import React from 'react';
import { Collapse } from '@mui/material';
import  Alert  from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { palette } from '../../theme/palette';

const styles = {
  alertClass: {
    backgroundColor:palette.customBlue.primaryBlue,
    color: palette.customWhite.main,
  },
};

type Props = {
  message?: string,
  open: boolean
  setOpen: Function
};

const CollapsedAlert = ({
  message,
  open,
  setOpen,
}: Props) => {

  return (
    <Collapse in={open}>
      <Alert
        icon={false}
        severity="info"
        color="info"
        sx={{ ...styles.alertClass }}
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      >
        {message}
      </Alert>
    </Collapse>
  );
};

export default CollapsedAlert;
