/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TeachersIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <g id="icon-teachers">
      <path
        d="M6.04852 1.72355C7.10053 2.07677 7.61294 3.85174 7.62693 4.77617C6.96434 4.47629 5.92783 4.28672 4.70853 4.28672C2.86158 4.28672 0.84673 5.19671 0.888706 9.09665C0.888706 11.9999 3.36529 16.8099 6.13572 16.8099C7.43634 16.8099 8.06598 16.3951 8.06598 16.2465C8.14993 16.5251 9.40922 16.8099 10.0808 16.8099C12.8513 16.8099 15.3279 11.9999 15.3279 9.09665C15.3698 5.19671 13.355 4.28672 11.508 4.28672C10.4019 4.28672 9.51323 4.49217 8.8303 4.77617C8.58269 1.96028 7.2161 1.15099 6.84603 0.986841C6.17705 0.690111 5.54741 1.55533 6.04852 1.72355Z"
        fill={props?.pathfill}
      />
    </g>
  </SvgIcon>
);

export default TeachersIcon;
