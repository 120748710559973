import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonType, ButtonVariant } from '../../../variables/types';
import HelpModal from './HelpModal';
import { SubmitTaskContext } from '../submit-task-context';
import ConfirmationImage from '../../../components/Icons/ConfirmationImage';
import Loader from '../../../components/Loader/loader';
import { Button } from '@mui/material';

interface Props {
  handleSave?: Function,
  disabled?: boolean,
  backBtnLabel?: string,
  redirectTo?: string,
}

const buttonStyles = {
  saveAndQuitBtn: {
    width: '160px',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    marginLeft: 1.25,
  },
};

const SaveAndQuitButton = ({ redirectTo, handleSave, disabled = false, backBtnLabel } : Props) => {
  const {
    showQuitConfirmation,
    setShowQuitConfirmation,
  } = useContext(SubmitTaskContext);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  if (loading) {
    return <Loader open={loading} />;
  }
  return (
    <>
      <Button
        sx={{ ...buttonStyles.saveAndQuitBtn }}
        variant="outlined"
        onClick={() => {
          setShowQuitConfirmation?.(true);
        }}
        disabled={disabled}
      >
        { backBtnLabel ?? 'SAVE AND QUIT'}
      </Button>

      <HelpModal
        openDialog={showQuitConfirmation}
        closeDialog={() => setShowQuitConfirmation?.(false)}
        dialogTitle="Are you sure you want to quit?"
        dialogContent="If you quit, your progress will be saved and you can resume later."
        dialogImage={<ConfirmationImage sx={{  height: '300px', width: '100%' }} />}
        actions={[{
          title: 'KEEP GOING',
          event: () => {
            setShowQuitConfirmation?.(false);
          },
          buttonVariant: 'outlined' as ButtonVariant,
          btnType: 'danger' as ButtonType,
        }, {
          title: 'SAVE AND QUIT',
          event: async () => {
            setLoading(true);
            await handleSave?.();
            setShowQuitConfirmation?.(false);
            setLoading(false);

            redirectTo ? history.push(redirectTo) : history.push('/');
          },
          buttonVariant: 'contained',
          buttonColor: 'primary',
          btnType: 'default',
        }]}
      />
    </>
  );
};

export default SaveAndQuitButton;
