/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const FlashlightLogo = ({ torchIconFillColor = palette.customOrange.flashlightSaffron, iconFillColor = palette.customBlue.flashlightBlue, ...props } : any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="736.06"
    height="138.06"
    viewBox="0 0 736.06 138.06"
    fill="none"
    {...props}
  >
    <g>
      <path fill={iconFillColor} d="M216.37,18.55h15.42v88.07H216.37Z" />
      <path
        fill={iconFillColor}
        d="M164.93,46.5H177V39.6C177,22.86,189,17,198.88,17c8.48,0,13.5,3.17,13.5,3.17L206.59,33a10.73,10.73,0,0,0-6.17-1.78c-3,0-8,1.65-8,9.13V46.5h14.91V60.2H192.45v46.42H177V60.2H164.93Z"
      />
      <path
        fill={iconFillColor}
        d="M303.15,46.5v60.12H288.88V98.75s-5.27,9.39-18.38,9.39c-15.82,0-29.71-13.06-29.71-31.58S254.68,45,270.5,45a21.89,21.89,0,0,1,18.38,9.38V46.5Zm-15.43,21s-4.5-8.37-14.4-8.37c-10.41,0-16.84,7.48-16.84,17.38s6.43,17.37,16.84,17.37c9.9,0,14.4-8.37,14.4-8.37Z"
      />
      <path
        fill={iconFillColor}
        d="M318.25,86.2s8.1,8.62,18.39,8.62c6.3,0,11.57-1.77,11.57-5.45,0-3.94-2.83-4.7-12.08-6.6-12.09-2.54-22.64-7.61-22.64-19C313.49,54.36,323,45,339.73,45a39.23,39.23,0,0,1,22.88,7.35L354.9,63.49s-6.94-5.2-15.17-5.2c-5.79,0-11.06,2-11.06,5.71s4.11,4.57,14.4,6.72c12.09,2.54,20.7,6.47,20.7,18.9,0,10.27-11.06,18.52-26.62,18.52-17.87,0-27.51-10.91-27.51-10.91Z"
      />
      <path
        fill={iconFillColor}
        d="M427.34,67.68v38.94H411.92V71.48c0-8.62-5.41-12.3-12.09-12.3-8,0-12.09,7.87-12.09,7.87v39.57H372.31V18.55h15.43V53.73A20.73,20.73,0,0,1,405,45C416.8,45,427.34,53.22,427.34,67.68Z"
      />
      <path fill={iconFillColor} d="M439.49,18.55h15.42v88.07H439.49Z" />
      <path
        fill={iconFillColor}
        d="M477.21,17.07a10,10,0,1,1-10.16,10A10,10,0,0,1,477.21,17.07ZM469.49,46.5h15.44v60.12H469.49Z"
      />
      <path
        fill={iconFillColor}
        d="M523.3,108.14c-15.82,0-29.71-13.06-29.71-31.58S507.48,45,523.3,45a21.89,21.89,0,0,1,18.38,9.38V46.5H556v60.63c0,20.92-15.68,30.18-32.79,30.18-17.48,0-28.93-11.41-28.93-11.41L503,114.23s8.49,8.88,20.18,8.88c12,0,17.37-6.47,17.37-15.48V100.4A21.87,21.87,0,0,1,523.3,108.14Zm17.23-40.59A16.9,16.9,0,0,0,526,59.18c-10.41,0-16.71,7.48-16.71,17.38s6.3,17.37,16.71,17.37c10,0,14.54-8.37,14.54-8.37Z"
      />
      <path
        fill={iconFillColor}
        d="M623.13,67.68v38.94H607.71V71.48c0-8.62-5.41-12.3-12.09-12.3-8,0-12.09,7.87-12.09,7.87v39.57H568.1V18.55h15.43V53.73A20.73,20.73,0,0,1,600.76,45C612.59,45,623.13,53.22,623.13,67.68Z"
      />
      <path
        fill={iconFillColor}
        d="M628.59,46.5h12V28.61H656V46.5h18.39V60.2H656V84.42c0,7.36,3.86,9.51,8,9.51a12.35,12.35,0,0,0,6.43-2.15L676,104.21a28.06,28.06,0,0,1-14.66,3.93c-11.83,0-20.83-8.5-20.83-21.31V60.2h-12Z"
      />
      <path
        fill={iconFillColor}
        d="M685.09,17.61A7.35,7.35,0,0,0,688,11.85C688,6.61,684,3,678.41,3A10.15,10.15,0,0,0,669,9.2l-.17.46L674,12.07l.19-.57a4.58,4.58,0,0,1,4.25-3,3.37,3.37,0,0,1,3.65,3.37c0,1.46-.44,3.38-4.29,3.38h-2v5.45h2.53c1.36,0,4.53.34,4.53,3.42,0,1.95-1.74,3.26-4.33,3.26a5.56,5.56,0,0,1-5.35-3.89l-.2-.65L668,25.65l.13.4a10.54,10.54,0,0,0,10.28,6.84c6,0,10.18-3.62,10.18-8.79C688.63,20.09,686.41,18.31,685.09,17.61Z"
      />
      <path
        fill={iconFillColor}
        d="M701.65,12.92,706.54,5l-4.89-3-.28.46C701,3,693,16.14,692.58,16.94A12.84,12.84,0,0,0,691,23a10.31,10.31,0,0,0,20.61-.08A9.78,9.78,0,0,0,701.65,12.92Zm-.38,14.44a4.25,4.25,0,0,1-4.42-4.46,4.42,4.42,0,1,1,8.83,0A4.24,4.24,0,0,1,701.27,27.36Z"
      />
      <path
        fill={iconFillColor}
        d="M724.56,3c-7.63,0-11,7.52-11,15s3.42,15,11,15c5.07,0,11-3.92,11-15S729.63,3,724.56,3Zm0,24.41c-3.84,0-5.21-5.09-5.21-9.44s1.37-9.44,5.21-9.44,5.14,4.87,5.14,9.44S728.35,27.36,724.56,27.36Z"
      />
      <rect fill={torchIconFillColor} x="63.21" y="39.21" width="6.78" height="50.27" />
      <path
        fill={torchIconFillColor}
        d="M31.73,76.61A25.23,25.23,0,0,0,53.62,89.47h2.66V39.21H53.62A25.21,25.21,0,0,0,31.73,52.07l-1,1.76H1a63.2,63.2,0,0,0,0,21H30.73Z"
      />
      <path
        fill={torchIconFillColor}
        d="M64,.38A64.06,64.06,0,0,0,2.53,46.9H26.76A32.16,32.16,0,0,1,53.62,32.28h23.3V96.4H53.62A32.16,32.16,0,0,1,26.76,81.78H2.53A63.95,63.95,0,1,0,64,.38ZM84.59,44.19l17.48-9.49a54.06,54.06,0,0,1,3.47,6l-21,11.37ZM102.07,94,84.59,84.49V76.61l21,11.37A53,53,0,0,1,102.07,94ZM84.59,67.8V60.87H111c.08,1.15.12,2.3.12,3.47s0,2.32-.12,3.46Z"
      />
    </g>
  </SvgIcon>
);

export default FlashlightLogo;
