import React from 'react';
import { Box, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import { AnalyticsTabs, Skills } from '../../variables/types';
import AssignmentFilter from '../../components/AssignmentsFilter';
import {
  AssignmentFilterFragmentFragment,
  SectionFilterFragmentFragment,
} from '../../generated/graphql';
import SectionFilter from '../../components/SectionsFilter';
import useRedirect from '../../hooks/useRedirect';
import NewOverview from './Overview';
import NewGoals from './Goals';
import { AnalyticsContext } from './analytics-context';
import useAnalytics from './analytics-hook';
import Writing from './Writing';
import Speaking from './Speaking';
import PerformanceDetails from './PerformanceDetails';
import { StyledFlexColBoxContainer } from '../../components/Common/Common.styled';
import { AnalyticsStylesCSS } from './Analytics.styled';
import SchoolFilterTeacher, { SchoolValue } from '../../components/SchoolFilterTeacher';

const Analytics = () => {
  const theme = useTheme();
  const styles = AnalyticsStylesCSS(theme);
  const { navigateTo, search } = useRedirect();
  const {
    tab,
    setTab,
    assignment,
    setAssignment,
    selectedSection,
    setSelectedSection,
    selectedSchool,
    setSchoolValue,
  } = useAnalytics();

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    switch (newValue) {
      case 1:
        navigateTo(`/analytics/${AnalyticsTabs.Speaking}`);
        return;
      case 2:
        navigateTo(`/analytics/${AnalyticsTabs.Writing}`);
        return;
      case 3:
        navigateTo(`/analytics/${AnalyticsTabs.SpeakingGoals}`);
        return;
      case 4:
        navigateTo(`/analytics/${AnalyticsTabs.WritingGoals}`);
        return;
      case 0:
      default:
        navigateTo(`/analytics/${AnalyticsTabs.Overview}`);
    }
  };

  const onAssignmentChange = (data: string | AssignmentFilterFragmentFragment | null) => {
    setAssignment(data as string);
  };

  const onSectionChange = (data: string | SectionFilterFragmentFragment | null) => {
    setSelectedSection(data as string);
    setAssignment(undefined);
  };

  const onSchoolChange = (schoolData: string | SchoolValue) => {
    setAssignment(undefined);
    setSelectedSection(undefined);
    setSchoolValue(schoolData as string);
  };

  return (
    <Layout>
      <AnalyticsContext.Provider value={{
        assignment,
        selectedSection,
        selectedSchool,
      }}
      >
        <StyledFlexColBoxContainer>
          <Box sx={styles.stickyHeader as SxProps}>
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="pageTitle">Actionable Data Dashboard</Typography>
              <Box
                display="grid"
                gridTemplateColumns="repeat(3, 240px)"
                sx={{
                  gridGap: 2,
                }}
              >
                <SchoolFilterTeacher
                  value={selectedSchool}
                  onChange={onSchoolChange}
                  placeholder="All Schools"
                  idSelectOnly
                />
                <SectionFilter
                  value={selectedSection}
                  onChange={onSectionChange}
                  idSelectOnly
                  placeholder="All Classes"
                  schoolId={selectedSchool!}
                />
                <AssignmentFilter
                  onChange={onAssignmentChange}
                  idSelectOnly
                  placeholder="Select Assignment"
                  value={assignment}
                  sectionId={selectedSection || ''}
                  schoolId={selectedSchool || ''}
                />
              </Box>
            </Box>
            <Box
              flexDirection="row"
              width="100%"
              pt={theme.spacing(3)}
            >
              <Tabs
                value={tab}
                onChange={handleChange}
                textColor="primary"
                scrollButtons={undefined}
              >
                <Tab
                  label="Overview"
                  disableRipple
                  sx={styles.tabStyle}
                />
                <Tab
                  label="Speaking"
                  disableRipple
                  sx={styles.tabStyle}
                />
                <Tab
                  label="Writing"
                  disableRipple
                  sx={styles.tabStyle}
                />
                <Tab
                  label="Speaking Goals"
                  disableRipple
                  sx={styles.tabStyle}
                />
                <Tab
                  label="Writing Goals"
                  disableRipple
                  sx={styles.tabStyle}
                />
              </Tabs>
            </Box>
          </Box>
          <Box display="flex" mt={2}>
            <Switch>
              <Route
                path={`/analytics/${AnalyticsTabs.Overview}`}
                component={NewOverview}
                exact
              />
              <Route
                path={`/analytics/${AnalyticsTabs.Speaking}`}
                component={Speaking}
                exact
              />
              <Route
                path={`/analytics/${AnalyticsTabs.Writing}`}
                component={Writing}
                exact
              />
              <Route
                path={[
                  `/analytics/${AnalyticsTabs.SpeakingGoals}/:skillKey`,
                  `/analytics/${AnalyticsTabs.SpeakingGoals}`,
                ]}
                render={() => (
                  <NewGoals
                    section={AnalyticsTabs.SpeakingGoals}
                    skillSection={Skills.Speaking}
                  />
                )}
              />
              <Route
                path={[
                  `/analytics/${AnalyticsTabs.WritingGoals}/:skillKey`,
                  `/analytics/${AnalyticsTabs.WritingGoals}`,
                ]}
                render={() => (
                  <NewGoals
                    section={AnalyticsTabs.WritingGoals}
                    skillSection={Skills.Writing}
                  />
                )}
              />
              <Route
                path={[
                  '/analytics/:section/details/:skillType/:skillKey',
                  '/analytics/:section/details/:skillType',
                ]}
                component={PerformanceDetails}
                exact
              />
              <Redirect
                from="/analytics"
                exact
                to={{
                  pathname: `/analytics/${AnalyticsTabs.Overview}`,
                  search,
                }}
              />
            </Switch>
          </Box>
        </StyledFlexColBoxContainer>
      </AnalyticsContext.Provider>
    </Layout>
  );
};

export default React.memo(Analytics);
