import { useState } from 'react';
import { uploadWithProgress, abortUpload } from './uploader';
import { sentryErrorLog } from '../sentry';

export interface UploadResponseType {
  key: string,
  bucket: string,
  url: string,
  fileType: string,
}

const useUploader = ({
  uploadUrl,
  accessToken,
  chunkSize = 5500000, // ~5.25MB
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [done, setDone] = useState(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [uploadData, setUploadData] = useState<any>(null);

  const onUploadProgress = (progressData: any) => {
    const { progress: currentProgress, finished, ...fileData } = progressData;
    if (finished || currentProgress === 100) {
      onUploadComplete(fileData);
      return;
    }
    if (Number.isInteger(currentProgress) && currentProgress !== 100) {
      setProgress(currentProgress);
    }
  };

  const onUploadComplete = (fileData: any) => {
    setIsLoading(false);
    setData(fileData);
    setProgress(100);
    setDone(true);
  };

  const abort = async () => {
    if (uploadData) {
      // setIsLoading(false);
      const { key, uploadId } = uploadData;
      try {
        await abortUpload({
          key,
          uploadId,
          accessToken,
          uploadUrl,
        });
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-async-promise-executor
  const uploadAsync = async (file: File, maxRetry: number = 1) => new Promise<UploadResponseType>(async (resolve, reject) => {
    let retry = maxRetry;
    do {
      try {
        setIsLoading(true);
        setProgress(0);
        const req = await uploadWithProgress({
          uploadUrl,
          file,
          accessToken,
          chunkSize,
          handleUpload: (progressData: any) => {
            onUploadProgress(progressData);
            if (progressData.finished) {
              resolve(progressData);
            }
          },
        });
        setUploadData(req);
        retry = 0;
      } catch (err) {
        setError(err);
        setIsLoading(false);
        if (retry === 0) reject(err);
        else {
          retry -= 1;
          sentryErrorLog(err, [
            { label: 's3', value: `S3 File Upload Retry Attempt ${maxRetry - retry}` },
          ]);
        }
      }
    } while (retry !== 0);
  });

  return { uploadAsync, abort, isLoading, progress, data, error, done };
};

export default useUploader;
