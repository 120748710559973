import React from 'react';
import { Box } from '@mui/material';
import AppTooltip from '../../../../../../components/AppTooltip';
import { AdminBenchmarkReportWidgets } from '../../../../../../variables/constant';
import StyledCard from '../../../../../../components/StyledCard';
import List from './List';
import { AdminBenchmarkGrowthReportChartData } from '../../benchmark-summary-hook';
import NoResults from '../../../../../../components/NoResults';
import EmptyBenchmarkResults from '../../../../../../components/Icons/EmptyBenchmarksResults';
import DonutChartLoader from '../../../../../../components/Loader/DonutChartLoader';

interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  hasData: boolean;
  loading: boolean;
  growthReportData: AdminBenchmarkGrowthReportChartData[];
}

const BenchmarkScoreReport = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
  loading,
  hasData,
  growthReportData,
}: Props) => {
  const title: string = 'Benchmark Scores';

  return (
    <Box id={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkScores.name} sx={{ height: '100%', width: '100%' }}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        fullHeight
        marginBottom='0px'

      >
        {!loading && growthReportData && !hasData  && (
          <NoResults
            description={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkScores.insufficientData.description}
            svgComponent={<EmptyBenchmarkResults />}
          />
        )}
        {!loading && growthReportData && hasData && (
          <List
            isTableEmpty={growthReportData?.length === 0}
            loading={loading!}
            totalCount={growthReportData?.length}
            benchmarkScores={growthReportData!}
          />
        )}
        {loading && <DonutChartLoader />}
      </StyledCard>
    </Box>

  );
};

export default BenchmarkScoreReport;
