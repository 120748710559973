import { useEffect, useState } from 'react';
import {
  useLearningGoalsPerformanceWidgetQuery,
} from '../../../../generated/graphql';
import { goalScoreOptions, GraphSetData } from '../../../../variables/types';
import { AdminDashboards } from '../../../../variables/constant';
import { DashboardWidgetsDataStatusProps, useDashboardContext } from '../../admin-analytics-hook';
import { getColor } from '../../../../components/AdminGraphs/CommonFunctions';

export interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  skillTypes?: string,
}

const useGoalPerformanceWidget = () => {

  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    analyticsFilters: queryFilters,
    updateDashboardWidgetsDataStatus,
  } = useDashboardContext();

  const [currentSkill, setCurrentSkill] = useState(null);
  const [skillFetch, setSkillFetch] = useState();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasData, setHasData] = useState<boolean>(false);
  const [performanceSkillData, setPerformanceSkillData] = useState<GraphSetData[]>([]);
  const [barGraphData, setBarGraphData] = useState<Record<string, string | number > | undefined>();

  const handleTooltipClick = (data: any | null, skillScore: any) => {
    setCurrentSkill(data);
    setSkillFetch(skillScore);
  };

  var queryInput = {
    input: queryFilters,
  };
  const updateQueryInput = (data: any) => {
    queryInput = {
      ...queryInput,
      ...data,
    };
  };
  if (isSchoolAdmin && mySchoolData?.id) updateQueryInput({ 'school_id': mySchoolData?.id });
  if (isDistrictAdmin && myDistrictData?.id) updateQueryInput({ 'district_id': myDistrictData?.id });

  const { data: goalPerformanceWidgetDataResp, loading: goalPerformanceWidgetDataLoading } = useLearningGoalsPerformanceWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryInput  },
  });

  const getBarGraphData = (skillDataset: any) => skillDataset?.reduce(
    (prevValue: any, currentValue: any) => {
      const colorKey = `${currentValue?.skill_key}_key_color`;
      const skillKey = `${currentValue?.skill_key}_key`;
      const skillDescription = `${currentValue?.skill_key}_key_description`;
      const skillLevel = `${currentValue?.skill_key}_key_level`;
      const skillLabel = `${currentValue?.skill_key}_key_label`;
      const skillType = `${currentValue?.skill_key}_key_type`;
      const skillId = `${currentValue?.skill_key}_key_id`;
      const numberOfStudents = `${currentValue?.skill_key}_key_numberOfStudents`;
      const students = `${currentValue?.skill_key}_key_students`;
      return {
        ...prevValue,
        [skillKey]: currentValue?.numberOfStudents ?? 0,
        [colorKey]: currentValue?.color,
        [skillDescription]: currentValue?.skill_key,
        [skillLevel]: currentValue?.skill_level,
        [skillLabel]: currentValue?.skill_label,
        [skillType]: currentValue?.skillType ?? '',
        [skillId]: currentValue?.id,
        [numberOfStudents]: currentValue?.numberOfStudents,
        [students]: currentValue?.students ?? [],
      };
    },
    {
      name: 'Performance',
    },
  );

  // RePopulate Bar performanceSkillData on performanceWidgetDataResp Refresh
  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: AdminDashboards.learningGoals.GoalPerformanceWidget.name,
      loading: goalPerformanceWidgetDataLoading,
      dataMissing: undefined,
    };
    if (!goalPerformanceWidgetDataLoading && !!goalPerformanceWidgetDataResp?.learningGoalsPerformanceData) {
      const newPerformanceSkillData: GraphSetData[] = [];
      goalPerformanceWidgetDataResp?.learningGoalsPerformanceData?.forEach((skillData: any, index) => newPerformanceSkillData.push({
        color: getColor(skillData?.title)!,
        skill_description: goalScoreOptions?.find((skill) => skill?.value === skillData?.title)?.value.toString() || '',
        id: `${index}`,
        numberOfStudents: skillData?.total_students,
        skill_label: goalScoreOptions?.find((skill) => skill?.value === skillData?.title)?.label.toString() || '',
        skillType: '',
        students: [],
        skill_key: skillData?.title,
        skill_level: goalScoreOptions?.find((skill) => skill?.value === skillData?.title)?.score,
        total_count: totalCount,
      }));

      setPerformanceSkillData(newPerformanceSkillData.sort((xSkill, ySkill) => xSkill?.skill_level! - ySkill?.skill_level!));
      setBarGraphData(getBarGraphData(newPerformanceSkillData));

      const performanceTotalCount = goalPerformanceWidgetDataResp?.learningGoalsPerformanceData?.map((skillData) => skillData?.total_students).reduce((total, num) => total + num, 0)!;
      setTotalCount(performanceTotalCount);
      // update widget's data status in root component data status tracker
      dashboardWidgetStatus.dataMissing = !performanceTotalCount;
    }
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalPerformanceWidgetDataLoading, goalPerformanceWidgetDataResp]);

  useEffect(() => {
    setHasData(totalCount > 0 && !!performanceSkillData && !!barGraphData);
  }, [totalCount, performanceSkillData, barGraphData]);

  return ({
    currentSkill, setCurrentSkill,
    skillFetch, setSkillFetch,
    totalCount, setTotalCount,
    hasData, setHasData,
    performanceSkillData, setPerformanceSkillData,
    barGraphData, setBarGraphData, getBarGraphData,
    handleTooltipClick,
    goalPerformanceWidgetDataLoading, goalPerformanceWidgetData: goalPerformanceWidgetDataResp?.learningGoalsPerformanceData,
    title: `${queryInput.input?.submission_score_type} Goals Performance`,
  });
};

export default useGoalPerformanceWidget;
