import React from 'react';
import { Box, CheckboxProps, InputAdornment, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SchoolsIcon from '../Icons/SchoolsIcon';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import { Maybe, SchoolFilterFragmentFragment } from '../../generated/graphql';
import useSchoolsFilter from '../SchoolFilter/school-filter-hook';

interface Props {
  error?: boolean;
  className?: string;
  value?: Maybe<string>[];
  onChange?: (schoolList: Maybe<string>[], data: SchoolFilterFragmentFragment) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  stateIds?: string[] | undefined;
  districts?: string[] | undefined;
  disabled?: boolean;
  selectBoxIcon?: JSX.Element;
  primarySchools?: string[];
  errorMessage?: any; //this needs to be taken care of in optimization of FL-1430
  limitTags?: number;
  textInputPlaceholder?: string;
  onSelectedSchoolsChange?: (selectedSchools: SchoolFilterFragmentFragment[]) => void;
  schoolsToEnable?: string[];
  updateLoader?: Function;
  dropDownIcon?: boolean;
}

const SchoolFilterWithMultiSelect = ({
  placeholder,
  onChange,
  value,
  stateIds,
  districts,
  disabled,
  primarySchools = [],
  errorMessage,
  limitTags = 3,
  textInputPlaceholder,
  onSelectedSchoolsChange,
  schoolsToEnable,
  updateLoader,
  dropDownIcon = true,
}: Props) => {
  const {
    schools,
    loading,
  } = useSchoolsFilter({ stateIds, districts });
  updateLoader?.(loading);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const onSelectChange = (_: any, schoolList: SchoolFilterFragmentFragment[] | undefined = []) => {
    onChange?.(schoolList?.map((school: SchoolFilterFragmentFragment) => school?.id),
      schoolList[0]);
    onSelectedSchoolsChange?.(schoolList);
  };
  const schoolOptions = schoolsToEnable && schoolsToEnable.length ? schools?.filter((school:any) => schoolsToEnable?.includes(school?.id!)) : schools;

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={limitTags}
      options={schoolOptions ?? []}
      getOptionLabel={(option: any) => `${option?.name ?? ''}`}
      getOptionDisabled={(option: any) => primarySchools?.includes(option.id)}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={loading || disabled}
      disableClearable={!!primarySchools?.length}
      onChange={onSelectChange}
      value={schools?.filter((school: any) => value?.includes(school?.id!))}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Box sx={{ maxWidth: '120px' }}>
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.name ?? ''}</Typography>}
              {...getTagProps({ index })}
              disabled={primarySchools?.includes(option.id) || disabled}
            />
          </Box>
        ))
      }
      renderOption={(props, option: any, { selected }) => (
        <Box component="li" {...props} key={option?.id}>
          <Checkbox
            color={'primary' as CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            disabled={primarySchools?.includes(option.id)}
            sx={{ mr: '8px' }}
          />
          {`${option?.name ?? ''}`}
        </Box>
      )}
      renderInput={(params) => (
        <>
          {placeholder && <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>}
          <TextField
            {...params}
            placeholder={params.InputProps.startAdornment ? undefined : (!textInputPlaceholder ? 'Select Schools' : textInputPlaceholder)}
            error={!!value} //this needs to be taken care of in optimization of FL-1430
            helperText={value?.length === 0 ? errorMessage?.schoolHelperText : ''} //this needs to be taken care of in optimization of FL-1430
            required={value?.length === 0}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: (
                <>
                  { dropDownIcon && (
                    <InputAdornment position="start">
                      <SchoolsIcon
                        pathfill={palette.customBlue.primaryBlue}
                        sx={{ ...svgStyles.icon }} />
                    </InputAdornment>)}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default SchoolFilterWithMultiSelect;
