import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryParam } from 'use-query-params';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';
import {
  SchoolFilterFragmentFragment,
  SchoolSectionListInput,
  SectionCategory,
  useCreateTeacherFormMutation, useDistrictDetailsQuery,
  useTeacherEditFormQuery,
  useUpdateTeacherFormMutation,
} from '../../../generated/graphql';
import TeachersIcon from '../../../components/Icons/TeachersIcon';
import { titles } from './constants';
import { PROVIDER_TYPE } from '../../../variables/constant';
import useTeacher from '../Teacher/teacher-hook';

type TeacherFormData = {
  id: string
  state_id: string
  district_id: string
  schools: SchoolSectionListInput[] | null | string[]
  first_name: string
  last_name: string
  teacher_id: string
  title?: string
  email: string
  job_title: string
  phone_number: string
  password: string
  classes?: any[] | null
  classes_primary?: any[] | null
  classTypes?: SectionCategory[];
};

interface RouteProp {
  id: string
}

const useTeacherForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
    clearErrors,
  } = useForm<TeacherFormData>();

  const [districtParam] = useQueryParam('districtId');
  const [stateParam] = useQueryParam('stateId');
  const [schoolParam] = useQueryParam('schoolId'); //Param set when adding teacher from school
  const primarySchools = useRef<string[]>([]); //Only for edit
  const [createTeacherForm, { loading: createLoading }] = useCreateTeacherFormMutation();
  const [updateTeacherForm, { loading: updateLoading }] = useUpdateTeacherFormMutation();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [cleverMappedSchoolSections, setCleverMappedSchoolSections] = useState([]);
  const [primarySections, setPrimarySections] = useState([]);
  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setChecked(event.target.checked);
  };
  const {
    teacher,
  } = useTeacher({});
  const sourceParam = teacher?.source || undefined;

  // TODO - update with the correct type for event
  const handleCase = (elemName: string) => (event: any) => {
    const content = event.target.value.toString().trim();
    if (content !== ''){
      setValue(elemName, content.toLowerCase());
    }
  };

  useEffect(() => {
    if (stateParam) {
      setValue('state_id', stateParam);
    }
  }, [setValue, stateParam]);

  useEffect(() => {
    if (districtParam) {
      setValue('district_id', districtParam);
    }
  }, [setValue, districtParam]);

  useEffect(() => {
    if (schoolParam) {
      setValue('schools', [schoolParam]);
    }
  }, [setValue, schoolParam]);

  const onStateChange = () => {
    setValue('district_id', null);
    setValue('schools', null);
    setValue('classes', []);
  };

  //Format classes and schools for create/update as only school form value to be passed as input variable
  const formatSchoolClasses = (sectionList: any[], schoolList: SchoolSectionListInput[] | null)=>{
    const temp = sectionList.reduce((acc, section:any) => {
      (acc[section.school_id] = acc[section.school_id] || []).push(section.id);
      return acc;
    }, {});

    const result = [] as any[];
    Object.keys(temp).forEach((tempKey: string) => result.push({
      school_id : tempKey,
      sections_ids: temp[tempKey],
    }));

    if (schoolList && schoolList.length > Object.keys(temp).length)
      schoolList?.forEach((school) => {
        if (!Object.keys(temp).includes(school.school_id)){
          result.push({ school_id: school, sections_ids: [] });
        }
      });
    return result;
  };

  //When no class is selected only schools will be updated
  const formatSchools = (schools: any) => schools?.map((school:any)=> {
    return { school_id: school, sections_ids:[] };
  });

  const onDistrictChange = (stateId?: string | null) => {
    if (stateId) {
      setValue('state_id', stateId);
      clearErrors('state_id');
    }

    setValue('schools', null);
    setValue('classes', []);
  };

  const { id } = useParams<RouteProp>();

  if (id) {
    register('id', { required: true });
  }

  const { data: teacherDetails, loading: teacherDetailsLoading } = useTeacherEditFormQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id!,
      classTypes: [SectionCategory.CustomClass, SectionCategory.Class],
    },
    skip: !id,
  });

  const onSchoolChange = (schoolData?: SchoolFilterFragmentFragment | null) => {
    if (schoolData?.district_id) {
      setValue('district_id', schoolData?.district_id);
      clearErrors('district_id');
    }
    if (schoolData?.district_state_id) {
      setValue('state_id', schoolData?.district_state_id);
      clearErrors('state_id');
    }
    onClassChange();
  };

  const onClassChange = ()=>{
    const schools = getValues('schools');
    const classes = getValues('classes');
    setValue('classes', classes?.filter(
      initialValue => schools?.includes(initialValue.school_id),
    ));
  };

  useEffect(() => {
    if (teacherDetails) {
      const teacher = teacherDetails?.teacher;
      const schoolSections = teacher?.school_sections.map((section) => ({ id: section.section_id, school_id: section.school_id, school: section.school_name, name: section.section_name, primary_teacher: section.primary_teacher, source: section?.source }));
      const primaryTeacher = schoolSections.filter((section) => section.primary_teacher); //all classes with primary teacher
      primarySchools.current = primaryTeacher.map((section) => section.school_id);//primary schools to disable for schools in edit form
      const secondaryClasses = schoolSections.filter((section) => !section.primary_teacher);//all classes with secondary teacher
      const cleverSchoolSections = schoolSections.filter((section) => section.source !== PROVIDER_TYPE.FLASHLIGHT)?.map((section) => section.id);
      const primaryClasses = schoolSections.filter((section) => section.primary_teacher)?.map((section) => section.id);
      setCleverMappedSchoolSections(cleverSchoolSections as any);
      setPrimarySections(primaryClasses as any);
      reset({
        id: teacher?.id,
        schools: teacher?.schools.map((school) => school.id),
        district_id: teacher?.district_id,
        state_id: teacher?.state_id ? teacher?.state_id : undefined,
        first_name: teacher?.first_name,
        last_name: teacher?.last_name,
        teacher_id: teacher?.teacher_id,
        title: teacher?.title,
        email: teacher?.email,
        job_title: teacher?.job_title,
        classes: secondaryClasses,
        phone_number: teacher?.phone_number || undefined,
        ...(primaryTeacher.length > 0 ? { classes_primary : primaryTeacher } : {}),
      });
    }
  }, [id, reset, teacherDetails]);

  const create = async (teacher: TeacherFormData) => {
    const firstName = teacher?.first_name?.trim() ?? teacherDetails?.teacher?.first_name;
    const lastName = teacher.last_name?.trim() ?? teacherDetails?.teacher?.last_name;
    const teacherName = `${firstName} ${lastName}`;
    const successMessage = `${teacherName} teacher was ${teacher.id ? 'updated' : 'added'}!`;
    const inputData = {
      id: teacher.id,
      first_name: firstName,
      last_name: lastName,
      schools: teacher.classes && teacher.classes?.length > 0
        ? formatSchoolClasses(teacher.classes, teacher.schools as SchoolSectionListInput[])
        : formatSchools(teacher.schools),
      district_id: teacher.district_id,
      state_id: teacher.state_id ? teacher.state_id : null,
      teacher_id: teacher.teacher_id?.trim() ?? teacherDetails?.teacher?.teacher_id,
      email: teacher.email?.toLowerCase() ?? teacherDetails?.teacher?.email?.toLowerCase(),
      job_title: teacher?.job_title ?? '',
      phone_number: teacher.phone_number ?? teacherDetails?.teacher?.phone_number,
      password: teacher.password,
      class_types: [SectionCategory.CustomClass, SectionCategory.Class],
      ...(teacher.title !== titles?.[0]?.value ? { title: teacher.title ?? teacherDetails?.teacher?.title } : {}),
    };
    try {
      let response;
      if (inputData.id){
        response = await updateTeacherForm({
          variables: {
            input: inputData,
          },
        });
      } else {
        response = await createTeacherForm({
          variables: {
            input: inputData,
          },
        });
      }
      const responseData = response.data;
      const teacherData = inputData.id ? responseData?.updateTeacher : responseData?.createTeacher;
      const classDetailsPath = `/teachers/${teacherData}`;
      if (checked) {
        reset({
          id: '',
          state_id: teacher.state_id,
          district_id: teacher.district_id,
          schools: teacher.schools,
          first_name: '',
          last_name: '',
          teacher_id: '',
          title: '',
          email: '',
          job_title: '',
          phone_number: '',
          password: '',
        }, {
          isSubmitted: false,
        });
      } else {
        history.push(classDetailsPath);
      }
      openSnackbar({
        message: successMessage,
        customIcon: TeachersIcon,
        actionButtonText: 'View',
        onActionButtonClick: () => { history.push(classDetailsPath); },
      }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const validateSecondaryOnEdit = () => {
    const secondaryClasses = getValues('classes')?.map((section) => section?.id);
    const primaryClasses = getValues('classes_primary')?.map((section) => section?.id);
    return primaryClasses?.length ? !secondaryClasses?.some((sClass:any) => primaryClasses?.includes(sClass)) : true;
  };

  const selectedStateId = watch('state_id');
  const selectedDistrictId = watch('district_id');
  const selectedSchoolIds = watch('schools');

  const { data: sourceDetails, loading: sourceDetailsLoading } = useDistrictDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: selectedDistrictId!,
    },
    skip: !selectedDistrictId,
  });

  return {
    loading: createLoading || updateLoading || teacherDetailsLoading || sourceDetailsLoading,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    createTeacher: create,
    checked,
    handleChange,
    onStateChange,
    onDistrictChange,
    setValue,
    selectedStateId,
    selectedDistrictId,
    selectedSchoolIds,
    primarySchools : primarySchools.current,
    teacherID: id,
    validateSecondaryOnEdit,
    onSchoolChange,
    cleverMappedSchoolSections,
    primarySections,
    sourceParam,
    districtSource: sourceDetails?.districtDetails?.source,
    handleCase,
  };
};

export default useTeacherForm;
