import React from 'react';
import {
  Typography,
  Button, Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { palette  } from '../../theme/palette';

interface Props {
  redirectionLink: string;
  Icon: any;
  label: string;
  description: string;
  primaryBackground: string;
  secondaryBackground: string
}

const TileBox = ({
  redirectionLink,
  Icon,
  label,
  description,
  primaryBackground,
  secondaryBackground,
}: Props) => {
  const buttonContent = (
    <Box>
      <Box
        style={{
          backgroundColor: primaryBackground,
          height: '135px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px 16px 0 0',
        }}
      >
        <Icon
          style={{
            backgroundColor: secondaryBackground,
            padding: 10,
            width: 96,
            height: 98,
            borderRadius: 12,
          }}
        />
      </Box>
      <Box
        style={{
          padding: 22,
          minHeight: 220,
        }}
      >
        <Typography style={{
          fontWeight: 800,
          fontSize: 20,
          color: palette.fontColors.fontBlack,
          marginTop: 8,
          textTransform: 'capitalize',
          lineHeight: '27px',
          letterSpacing: '0.1px',
        }}>
          {label}
        </Typography>
        <Typography style={{
          fontWeight: 600,
          fontSize: '16px',
          color: palette.fontColors.fontBlack,
          marginTop: 8,
          textTransform: 'none',
          lineHeight: '22px',
        }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
  return (
    <Button
      variant="outlined"
      color="inherit"
      component={Link}
      to={redirectionLink}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '360px',
        padding: 0,
      }}
    >
      {buttonContent}
    </Button>
  );
};
export default React.memo(TileBox);
