import { SizeMetrics } from '../variables/types';

export const checkFileSize = (size_metric: string, max_size: number, tfile: File) => {
  let filesize = tfile.size;
  switch (size_metric) {
    case SizeMetrics.KB:
      filesize = filesize / Math.pow(1024, 1);
      break;
    case SizeMetrics.MB:
      filesize = filesize / Math.pow(1024, 2);
      break;
    case SizeMetrics.GB:
      filesize = filesize / Math.pow(1024, 3);
      break;
  }
  if (filesize > max_size) {
    return false;
  }
  return true;
};
