import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../variables/constant';
import {
  BenchmarkGradedBy,
  BenchmarkSubmissionListInput,
  BenchmarkSubmissionListSortType,
  OrderBy,
  SubmissionStatus,
  useAdminBenchmarkGradingQueueQuery,
  useBenchmarkGradesListQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import useBenchmarksQueueHook from '../../ScoreTaskBenchmark/score-benchmark-queue-hook';
import { SearchContext } from '../../../core/searchContext';

const useBenchmarkGradesList = () => {
  const StatusInitial = SubmissionStatus.Submitted;
  const gradedByInitial = BenchmarkGradedBy.Flashlight;
  const pagination = usePagination();
  const history = useHistory();
  const { searchTerm } = useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, BenchmarkSubmissionListSortType.SubmittedAt));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [benchmarkTypes, setBenchmarkTypes] = useState<any []>([]);
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(StatusInitial);
  const [selectedStates, setSelectedStates] = useState<string[] | undefined>(undefined);
  const [selectedDistricts, setSelectedDistricts] = useState<string[] | undefined>(undefined);
  const [selectedSchools, setSelectedSchools] = useState<string[] | undefined>(undefined);
  const [selectedGrades, setSelectedGrades] = useState<string[] | undefined>(undefined);
  const [selectedGradedBy, setSelectedGradedBy] = useState<string | null>(gradedByInitial);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const initialInputVariables = {
    limit: rowsPerPage,
    page: pagination.page,
    sort: BenchmarkSubmissionListSortType.SubmittedAt,
    order_by: order as OrderBy,
    grade_statuses: [StatusInitial],
    graded_by: [gradedByInitial],
    search: searchTerm,
  };

  const [queryVariables, setQueryVariables] = useState<BenchmarkSubmissionListInput>({
    ...initialInputVariables,
  });
  useEffect(() => {
    pagination.setPage(1);
    setQueryVariables({ ...queryVariables, page: 1, limit: rowsPerPage });
  }, [rowsPerPage]);

  const { initializeSubmissionQueue } = useBenchmarksQueueHook({});

  const createBenchmarkQueue = (submissionQueueIDs: string[], submissionID: string) => {
    initializeSubmissionQueue(submissionQueueIDs, submissionID);
  };

  const { data, loading } = useBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...queryVariables,
      ...(searchTerm && searchTerm.length > 2 ? { search: searchTerm } : {}),
    },
  });

  const { data:submissionQueueData, loading: submissionQueueLoading } = useAdminBenchmarkGradingQueueQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...queryVariables,
      ...(searchTerm && searchTerm.length > 2 ? { search: searchTerm } : {}),
      limit: undefined,
      page: undefined,
    },
  });

  const handleClearFilters = async () => {
    pagination.setPage(1);
    setQueryVariables({
      ...initialInputVariables,
      state_ids: undefined,
      district_ids: undefined,
      school_ids: undefined,
      predefined_grade_ids: undefined,
    });
    setBenchmarkTypes([]);
    setSelectedStatus( SubmissionStatus.Submitted);
    setSelectedStates(undefined);
    setSelectedDistricts(undefined);
    setSelectedSchools(undefined);
    setSelectedGrades(undefined);
    setSelectedGradedBy(gradedByInitial);
  };

  const handleApplyFilters = async () => {
    pagination.setPage(1);
    setQueryVariables({
      limit: rowsPerPage,
      page: 1,
      sort: sort as BenchmarkSubmissionListSortType,
      order_by: order as OrderBy,
      ...(selectedGradedBy !== 'all' ? { graded_by: [selectedGradedBy as BenchmarkGradedBy] } : { graded_by: [BenchmarkGradedBy.Teacher, BenchmarkGradedBy.Flashlight] }),
      ...(benchmarkTypes.length ? { benchmark_types: benchmarkTypes } : {}),
      ...(selectedStatus !== 'all' ? { grade_statuses: [selectedStatus as SubmissionStatus] } : { grade_statuses: [SubmissionStatus.Submitted, SubmissionStatus.Reviewed] }),
      ...(selectedStates ? { state_ids: selectedStates } : {}),
      ...(selectedDistricts ? { district_ids: selectedDistricts } : {}),
      ...(selectedSchools ? { school_ids: selectedSchools } : {}),
      ...(selectedGrades ? { predefined_grade_ids: selectedGrades } : {}),
      ...(searchTerm && searchTerm.length > 2 ? { search: searchTerm } : {}),
    });
  };

  const onSortChange = (sortBy: BenchmarkSubmissionListSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
    setQueryVariables({
      ...queryVariables,
      sort: sortBy,
      order_by: orderBy,
      page: 1,
    });
  };

  const handlePagination = (value: React.SetStateAction<number>) => {
    pagination.setPage(value as number);
    setQueryVariables({
      ...queryVariables,
      page: value as number,
      limit: rowsPerPage,
    });
  };

  const handleRedirection = (submissionQueueIDs: string[], submissionID: string) => async () => {
    createBenchmarkQueue(submissionQueueIDs, submissionID);
    if (submissionID) history.push(`/tasks/${submissionID}/benchmark/score`);
  };

  return {
    loading: loading || submissionQueueLoading,
    handleRedirection,
    gradeBenchmarks: data?.adminBenchmarkSubmissionList?.nodes ?? [],
    pagination,
    totalCount: data?.adminBenchmarkSubmissionList?.node_count ?? 0,
    onSortChange,
    sort,
    order: order as OrderBy,
    benchmarkTypes, setBenchmarkTypes,
    selectedStatus, setSelectedStatus,
    selectedStates, setSelectedStates,
    selectedDistricts, setSelectedDistricts,
    selectedSchools, setSelectedSchools,
    handleApplyFilters, handleClearFilters, handlePagination,
    submissionQueueData: submissionQueueData?.adminBenchmarkGradingQueue ?? [],
    createBenchmarkQueue,
    selectedGradedBy, setSelectedGradedBy,
    selectedGrades, setSelectedGrades,
    rowsPerPage, setRowsPerPage,
  };
};

export default useBenchmarkGradesList;
