import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StudentForm from './student_form';
import StudentSearchForm from './search-form';


interface Props {
  openDialog: boolean;
  closeDialog: (type?: string, id?: string) => void;
  componentType?: string;
  closeRemoveStudentDialog: (type?: string | undefined) => void;
  refetch: () => void;
}


const AddStudentSearch = ({ openDialog, closeDialog, componentType, closeRemoveStudentDialog, refetch }: Props) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      onClose={closeDialog}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {openDialog ? (
          <IconButton
            aria-label="close"
            onClick={() => closeDialog()}
            color='primary'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        { componentType === 'create' || componentType === 'edit' ?
          <StudentForm closeDialog={closeDialog} closeRemoveStudentDialog={closeRemoveStudentDialog} refetch={refetch}/>
          : <StudentSearchForm closeDialog={closeDialog} /> }
      </DialogContent>
    </Dialog>
  );
};

export default AddStudentSearch;
