import React, { useState } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import MultipleSelectChips from '../../../components/MultiSelectChips';
import useManageImage from './manage-image-hook';
import Loader from '../../../components/Loader/loader';
import { AddButton, CancelButton, DeleteButton, ImageBox } from '../ImageUpload.styled';
import TaskTypes from '../../../components/TaskTypes';
import KeywordSearch from '../../../components/AddKeywordTags';
import { GradeOptions, THUMBNAIL_RES, THUMBNAIL_MAX_FILE_SIZE_KB } from '../../../variables/constant';
import WarningWindow from '../../../components/WarningWindow';
import { SettingsValidation } from '../../../variables/formValidation';
import { palette } from '../../../theme/palette';
import ImageUpload from '../../../components/ImageUpload';
import FullscreenImage from '../../../components/FullscreenImage/FullscreenImage';
import BackdropModal from '../../../components/BackdropModal/BackdropModal';

interface GradeSelectorProp {
  onChange: (selectedItems: string[] | null) => void,
  value?: string[],
}

const style = {
  imageContainer : {
    display: 'flex',
    border:`2px solid ${palette.customGrey.dragBorder}`,
    borderRadius:'12px',
    width: 440,
    height: 220,
    overflow: 'hidden',
    padding: '18px 15px 15px 18px',
  },
  fullscreenIconPosition: {
    position: 'absolute',
    top: 'unset',
    right: '30px',
    bottom: '30px',
  },
};

const FileUploadEdit = () => {
  const {
    onSaveResource,
    onDeleteResource,
    onCancel,
    handleSubmit,
    errors,
    register,
    loading,
    control,
    subjectAreas,
    resource,
    isWarningEnabled,
    openWarning,
    closeWarning,
    thumbnailFile, setThumbnailFile,
    showThumbnailWarning, setShowThumbnailWarning,
    openThumbnail, handleOpenThumbnail,
    getThumbnailRootProps, getThumbnailInputProps,
    removeThumbnail,
  } = useManageImage();

  const [showImagePreview, setShowImagePreview] = useState(false);
  const [showThumbnailPreview, setShowThumbnailPreview] = useState(false);

  const handleImagePreview = () => {
    setShowImagePreview(!showImagePreview);
  };

  const handleThumbnailPreview = () => {
    setShowThumbnailPreview(!showThumbnailPreview);
  };

  const subjectOptions = subjectAreas?.map((subject: any) => ({ label: subject.title, value:subject.id }));

  return (
    <>
      <Loader open={loading} />
      <ImageBox sx={{ height: 'auto', pb: '20px' }}>
        <Typography align={'left'} variant='tileTitle'>
          Edit Image
        </Typography>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSaveResource)}
          onError={() => console.log('eror')}
        >
          <Stack direction='row' spacing={2} flexGrow={0} flexShrink={1}>
            <Box>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>Image Title</Typography>
                <TextField
                  id="image-title"
                  aria-describedby="image-title"
                  error={!!errors.title}
                  fullWidth
                  name="title"
                  helperText={errors.title && 'Please enter the image title.'}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>Grade Level</Typography>
                <Controller
                  name="grades"
                  helperText={errors?.grades && SettingsValidation.GRADE_LEVEL}
                  render={({ onChange, value }: GradeSelectorProp) => (
                    <MultipleSelectChips
                      value={value}
                      onChange={onChange}
                      options={GradeOptions}
                    />
                  )}
                  rules={{
                    required: SettingsValidation.GRADE_LEVEL,
                    validate: (value) => value?.length > 0 || SettingsValidation.GRADE_LEVEL,
                  }}
                  control={control}
                  defaultValue={[]}
                />
                {
                  errors.grades && <Typography variant='sFormHelper' sx={{ fontSize: '14px' }}>{SettingsValidation.GRADE_LEVEL}</Typography>
                }
              </Box>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>Subject Area</Typography>
                <Controller
                  name="subject_areas"
                  helperText={errors?.subject_areas && SettingsValidation.SUBJECT_AREA}
                  render={({ onChange, value }) => (
                    <MultipleSelectChips
                      value={value}
                      onChange={onChange}
                      options={subjectOptions as Object[]}
                    />
                  )}
                  rules={{
                    required: SettingsValidation.SUBJECT_AREA,
                    validate: (value) => value?.length > 0 || SettingsValidation.SUBJECT_AREA,
                  }}
                  control={control}
                  defaultValue={[]}
                />
                {
                  errors.subject_areas && <Typography variant='sFormHelper' sx={{ fontSize: '14px' }}>{SettingsValidation.SUBJECT_AREA}</Typography>
                }
              </Box>
              <Box mt={2}>
                <Controller
                  name="task_types"
                  helperText={errors?.task_types && SettingsValidation.TASK_TYPE}
                  render={({ onChange, value }) => (
                    <TaskTypes
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: SettingsValidation.TASK_TYPE,
                    validate: (value) => value?.length > 0 || SettingsValidation.TASK_TYPE,
                  }}
                  control={control}
                  defaultValue={[]}
                />

                {
                  errors.task_types && <Typography variant='sFormHelper' sx={{ fontSize: '14px' }}>{SettingsValidation.TASK_TYPE}</Typography>
                }
              </Box>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>Add Keyword Tags</Typography>
                <Controller
                  name="keywords"
                  render={({ onChange, value }) => (
                    <KeywordSearch
                      onChange={onChange}
                      value={value}/>
                  )}
                  rules={{
                    required: SettingsValidation.KEYWORDS,
                    validate: (value) => value?.length > 0 || SettingsValidation.KEYWORDS,
                  }}
                  control={control}
                  defaultValue={[]}
                />

                {
                  errors.keywords && <Typography variant='sFormHelper' sx={{ fontSize: '14px' }}>{SettingsValidation.KEYWORDS}</Typography>
                }
              </Box>
            </Box>
            <Box pl='15%'>
              <Box>
                <Typography variant='sFormTitle' component='p' sx={{ fontWeight: '800' }}>Image</Typography>
                <BackdropModal
                  open={showImagePreview}
                  onClose={handleImagePreview}
                >
                  <FullscreenImage
                    imageSrc={resource?.file?.url}
                    setShowPreview={setShowImagePreview}
                    showPreview={showImagePreview}
                    fullScreen
                  />
                </BackdropModal>
                <FullscreenImage
                  imageSrc={resource?.file?.url}
                  setShowPreview={setShowImagePreview}
                  showPreview={showImagePreview}
                  fullScreen={false}
                  containerStyles={style.imageContainer}
                  fullscreenIconStyles={style.fullscreenIconPosition}
                />
              </Box>
              <Box>
                <Typography variant='sFormTitle' component='p' sx={{ fontWeight: '800' }}>
                  Thumbnail Image
                  <Typography sx={{ fontSize: '12px' }} component="span" >{' (Optional)'}</Typography>
                </Typography>
                { resource?.file?.thumbnail_url ? (
                  <>
                    <BackdropModal
                      open={showThumbnailPreview}
                      onClose={handleThumbnailPreview}
                    >
                      <FullscreenImage
                        imageSrc={resource?.file?.thumbnail_url}
                        setShowPreview={setShowThumbnailPreview}
                        showPreview={showThumbnailPreview}
                        fullScreen
                      />
                    </BackdropModal>
                    <FullscreenImage
                      imageSrc={resource?.file?.thumbnail_url!}
                      setShowPreview={setShowThumbnailPreview}
                      showPreview={showThumbnailPreview}
                      fullScreen={false}
                      containerStyles={style.imageContainer}
                      fullscreenIconStyles={style.fullscreenIconPosition}
                    />
                  </>
                ) : (
                  <>
                    <Typography variant='font16'>File needs to be less than {THUMBNAIL_MAX_FILE_SIZE_KB} KB</Typography>
                    <ImageUpload
                      thumbnail
                      resolution={THUMBNAIL_RES}
                      dropzoneRefKey="upload-images-thumbnail"
                      files={thumbnailFile}
                      setFiles={setThumbnailFile}
                      getRootProps={getThumbnailRootProps}
                      getInputProps={getThumbnailInputProps}
                      deleteImage={removeThumbnail}
                      showWarning={showThumbnailWarning}
                      setShowWarning={setShowThumbnailWarning}
                      header="Delete this image?"
                      subheader="Teachers and students will not be able to see or use this image once its deleted."
                      primaryTitle="Keep Thumbnail Image"
                      secondaryTitle="Delete Thumbnail Image"
                      open={openThumbnail}
                      handleClose={handleOpenThumbnail}
                      handleOpen={handleOpenThumbnail}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Stack>
          <Box
            display="flex"
            columnGap={3}
          >
            <AddButton
              variant="contained"
              color="primary"
              type="submit"
            >
              Save Changes
            </AddButton>
            <CancelButton
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              variant="outlined"
              color="error"
              onClick={openWarning}
            >
              Delete
            </DeleteButton>
            <WarningWindow
              openDialog={isWarningEnabled}
              header="Delete this image?"
              subheader={(<><p>Teacher and students will not be able to use this image once this is deleted.</p>
                <p>This action will delete the image for all the Grades</p></>)}
              actions={[{
                title: 'Keep Image',
                event: closeWarning,
                buttonVariant: 'outlined',
              }, {
                title: 'Delete Image',
                event: onDeleteResource,
                buttonVariant: 'contained',
              }]}
            />
          </Box>
        </form>
      </ImageBox>
    </>
  );
};

export default FileUploadEdit;
