import React from 'react';
import { Box } from '@mui/material';

import { StudentBenchmarkDashBoard } from '../../../../variables/constant';
import StyledCard from '../../../../components/StyledCard';
import AppTooltip from '../../../../components/AppTooltip';
import NoResults from '../../../../components/NoResults';
import EmptyBenchmarkResults from '../../../../components/Icons/EmptyBenchmarksResults';
import useBenchmarkScoresWidget from './benchmark-scores-widget-hook';
import List from './List';
import DonutChartLoader from '../../../../components/Loader/DonutChartLoader';

interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
}

const BenchmarkScoresWidget = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
}: Props) => {
  const title = 'Benchmark Scores';
  const {
    totalCount,
    studentBenchmarkScoresWidgetLoading,
    studentBenchmarkScoresData,
  } = useBenchmarkScoresWidget();

  return (
    <Box id={StudentBenchmarkDashBoard.BenchmarkScoresWidget.name} sx={{ height: '100%', width: '100%' }}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        fullHeight
        marginBottom='0px'
      >
        {!studentBenchmarkScoresWidgetLoading && !studentBenchmarkScoresData && (
          <NoResults
            description={StudentBenchmarkDashBoard.BenchmarkScoresWidget.insufficientData.description}
            svgComponent={<EmptyBenchmarkResults />}
          />
        )}
        {!studentBenchmarkScoresWidgetLoading && studentBenchmarkScoresData && (
          <List
            isTableEmpty={studentBenchmarkScoresData?.length === 0}
            loading={studentBenchmarkScoresWidgetLoading!}
            totalCount={totalCount}
            benchmarkScores={studentBenchmarkScoresData!}
          />
        )}
        {studentBenchmarkScoresWidgetLoading && <DonutChartLoader />}
      </StyledCard>

    </Box>
  );
};

export default BenchmarkScoresWidget;
