import { styled, Box } from '@mui/material';

const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  padding: 20,
  borderRadius: 12,
  backgroundColor: theme.palette.customWhite.main,
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export default StyledBoxWrapper;
