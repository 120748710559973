import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../../../../components/Layout';
import AppBreadcrumbs from '../../../../components/AppBreadcrumbs';
import PDFViewer from '../../../../components/PDFViewer';
import { StyledStickyBreadcrumbBox } from '../../TeacherToolbox.styled';
import { SHARED_BUCKET_URL } from '../../../../variables/constant';
import useRubricIndicatorAndStandards from '../rubricIndicatorStandards-hook';
import { ScoreSchemeTitleKeys } from '../../../../variables/types';

const breadcrumb = [{
  label: 'Toolbox',
  route: '/toolbox',
},
{
  label: 'Rubric, Indicators, & standards',
  route: '/toolbox/rubric-indicators-standards',
},
{
  label: 'rubric & indicators',
}];

const RubricIndicatorsView = () => {
  const { user } = useRubricIndicatorAndStandards();

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <StyledStickyBreadcrumbBox>
          <Typography variant="pageTitle">Rubric & Indicators</Typography>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
        </StyledStickyBreadcrumbBox>
        <Box>
          <PDFViewer
            downloadFile
            pdfLink={(user?.score_scheme.title_key === ScoreSchemeTitleKeys.Scheme1) ? `${SHARED_BUCKET_URL}/pdfs/RubricIndicatorsAndStandardsTexas.pdf` : `${SHARED_BUCKET_URL}/pdfs/RubricIndicatorsAndStandards.pdf`}
            downloadLink="RubricIndicatorsAndStandards.pdf"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default React.memo(RubricIndicatorsView);
