/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const PauseIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    {...props}
  >
    <path
      d="M19.8938 10.0653L3.39375 0.310619C2.05313 -0.481569 0 0.287182 0 2.24656V21.7512C0 23.5091 1.90781 24.5684 3.39375 23.6872L19.8938 13.9372C21.3656 13.07 21.3703 10.9325 19.8938 10.0653Z"
      fill={props.pathFill || palette.customWhite.main}
    />
  </SvgIcon>
);

export default React.memo(PauseIcon);
