import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { openSnackbar } from '../../../../components/Notifier';
import { NotifierType } from '../../../../variables/types';
import {
  BenchmarkScoresInput,
  BenchmarkScoresLineItemFragmentFragment,
  BenchmarkScoresSortType,
  OrderBy,
  useBenchmarkScoresQuery,
} from '../../../../generated/graphql';
import { useIndividualStudentBenchmarksDashboardContext } from '../individual-student-dashboard-hook';
import { DashboardWidgetsDataStatusProps } from '../../../AdminAnalytics/admin-analytics-hook';
import { BenchmarkScoresRecordLimit, StudentBenchmarkDashBoard } from '../../../../variables/constant';

type RouteParams = {
  id?: string;
};

const useBenchmarkScoresWidget = () => {
  const { id: studentId } = useParams<RouteParams>();
  const [studentBenchmarkScoresData, setStudentBenchmarkScoresData] = useState<BenchmarkScoresLineItemFragmentFragment[]>();
  const [hasData, setHasData] = useState<boolean>(false);
  const { updateDashboardWidgetsDataStatus } = useIndividualStudentBenchmarksDashboardContext();

  const inputQuery: BenchmarkScoresInput = {
    student_user_id: studentId || '',
    // FL-4586
    limit: BenchmarkScoresRecordLimit,
    sort_by: BenchmarkScoresSortType.SubmittedAt,
    order_by: OrderBy.Desc,
  };

  const {
    data: studentBenchmarkScoresWidgetData,
    loading: studentBenchmarkScoresWidgetLoading,
    error: studentBenchmarkScoresWidgetError,
  } = useBenchmarkScoresQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        input: inputQuery,
      },
    });

  useEffect(() => {
    if (studentBenchmarkScoresWidgetError) {
      openSnackbar({ message: getErrorMessage(studentBenchmarkScoresWidgetError) }, NotifierType.Error);
    }

    if (!studentBenchmarkScoresWidgetLoading) {
      setStudentBenchmarkScoresData(studentBenchmarkScoresWidgetData?.benchmarkScores?.submission_line_items);
    }
  }, [studentBenchmarkScoresWidgetData, studentBenchmarkScoresWidgetLoading, studentBenchmarkScoresWidgetError]);

  useEffect(() => {
    if (!studentBenchmarkScoresWidgetLoading && studentBenchmarkScoresData) {
      setHasData(studentBenchmarkScoresData?.length > 0);
      const dashboardWidgetsDataStatus: DashboardWidgetsDataStatusProps = {
        widget: StudentBenchmarkDashBoard.BenchmarkScoresWidget.name,
        loading: studentBenchmarkScoresWidgetLoading,
        dataMissing: studentBenchmarkScoresData?.length === 0,
      };
      updateDashboardWidgetsDataStatus(dashboardWidgetsDataStatus);
    }

  }, [studentBenchmarkScoresWidgetLoading, studentBenchmarkScoresData]);

  return {
    totalCount: studentBenchmarkScoresWidgetData?.benchmarkScores?.total_count as number || 0,
    studentBenchmarkScoresWidgetLoading,
    studentBenchmarkScoresData,
    hasData,
  };
};


export default useBenchmarkScoresWidget;
