import { palette  } from '../palette';
import zIndex from '../z-index';

const MuiGenerics = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MUIDataTableBodyCell: {
    root: {
      height: 'auto !important',
    },
  },
  MUIDataTable: {
    paper: {
      width: '100% !important',
    },
  },
  MuiListItemAvatar: {
    root: {
      minWidth: 0,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 0,
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'none',
      },
    },
    icon: {
      width: 14,
      height: 8,
      right: 15,
      top: 'calc(50% - 5px)',
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(2px, 20px)',
    },
    shrink: {
      display: 'none',
      transform: 'translate(0, 0) scale(0)',
      transformOrigin: 'none',
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        '&.MuiPaper-root.MuiPaper-root.MuiPopover-paper': {
          border: `2px solid ${palette.customRed.progressPrimary}`,
          borderRadius: 12,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.MuiButtonBase-root': {
          cursor: 'pointer',
          borderBottom: `2px solid ${palette.customRed.progressPrimary}`,
          borderLeft: 'none',
          borderRight: 'none',
          height: 55,
          '&:hover, &:focus': {
            backgroundColor: 'rgba(66, 176, 255, 0.2)',
            borderBottom: `2px solid ${palette.customBlue.primaryBlue}`,
            borderLeft: 'none',
            borderRight: 'none',
            cursor: 'pointer',
          },
          '&:first-of-type': {
            '&:hover, &:focus': {
              borderTop: 'none',
            },
          },
          '&:last-child': {
            borderBottom: 'none',
            '&:hover, &:focus': {
              borderBottom: 'none',
            },
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(66, 176, 255, 0.2) !important',
          },
        },
      },
    },
  },
  MuiPickersPopper: {
    root: {
      zIndex: zIndex.zIndex9999,
    },
    paper: {
      width: 282,
      minHeight: 250,
      borderRadius: 12,
      border: `2px solid ${palette.customRed.progressPrimary}`,
      padding: '16px 16px 8px',
      marginLeft: 65,
    },
  },
  MuiPickersBasePicker: {
    root: {
      width: '100%',
      height: '100%',
    },
    pickerView: {
      width: '100%',
    },
  },
  MuiPickersArrowSwitcher: {
    iconButton: {
      color: palette.fontColors.fontBlack,
      width: 12,
      height: 8,
    },
    previousMonthButtonMargin: {
      marginRight: 40,
    },
  },
  MuiPickersCalendarHeader: {
    root: {
      paddingBottom: 0,
      marginTop: 0,
      paddingLeft: 0,
      marginBottom: 14,
      paddingRight: 0,
    },
    yearSelectionSwitcher: {
      color: palette.fontColors.fontBlack,
    },
    monthTitleContainer: {
      fontWeight: 800,
      color: palette.fontColors.fontBlack,
      fontSize: 14,
    },
  },
  MuiPickersCalendar: {
    root: {
      minHeight: 225,
      overflow: 'hidden',
    },
    weekDayLabel: {
      fontWeight: 800,
      color: palette.fontColors.fontBlack,
      fontSize: 14,
    },
  },
  MuiIconButton: {
    edgeEnd: {
      '&.MuiButtonBase-root': {
        marginRight: 0,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: `2px solid ${palette.customBackground.disabled}`,
        width: '100%',
      },
      indicator: {
        backgroundColor:palette.customBlue.primaryBlue,
        border: `2px solid ${palette.customBlue.primaryBlue}`,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '18px',
        fontWeight: 800,
        letterSpacing: '1px',
        lineHeight: '25px',
        /*
         * Default color of Tab is COLORS.disabled. When selected,
         * the color is controlled by '&.Mui-selected' selector.
        */
        color: palette.customBlack.lightBlack,
        '&.Mui-selected': {
          color:palette.customBlue.primaryBlue,
        },
      },
      /*
       * When <Tabs textColor="primary" /> is provided, color is controlled
       * by 'textColorPrimary' key.
      */
      textColorPrimary: {
        color: palette.customBlack.lightBlack,
      },
      label: {
        textTransform: 'uppercase',
      },
    },
  },
  MuiRadio: {
    root: {
      color: palette.customBackground.disabled,
    },
    /*
     * <Radio /> prop 'color=primary' is needed for 'colorPrimary' styles to be
     * effective. Default value of 'color' prop is 'secondary', which is
     * controlled by 'colorSecondary' key.
     */
    colorPrimary: {
      '&$checked': {
        color:palette.customBlue.primaryBlue,
      },
    },
  },
  MuiPaginationItem: {
    root: {
      minWidth: 24,
      height: 24,
      padding: '0 4px',
      fontSize: 18,
      fontWeight: 600,
    },
    page: {
      '&.Mui-selected': {
        color:palette.customBlue.primaryBlue,
        textDecoration: 'underline',
        background: 'none',
        '&:hover': {
          backgroundColor: `${palette.transparent.main} !important`,
        },
        '&:focus-visible': {
          backgroundColor: 'none !important',
        },
      },
      '&:hover': {
        backgroundColor: 'none',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        '&.MuiMenu-list': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
  MuiPickersDay: {
    root: {
      '&:focus': {
        backgroundColor:palette.customBlue.primaryBlue,
        '&.Mui-selected': {
          color: palette.customWhite.main,
          backgroundColor:palette.customBlue.primaryBlue,
        },
      },
      '&.Mui-selected': {
        color: palette.customWhite.main,
        backgroundColor:palette.customBlue.primaryBlue,
      },
    },
    dayWithMargin: {
      margin: 0,
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        width: '100%',
        '& fieldset': {
          border: 'none',
        },
        '&.MuiOutlinedInput-root':{
          padding: 0,
        },
      },
      input: {
        backgroundColor: palette.customWhite.whiteSmoke,
        border: `2px solid ${palette.customBackground.disabled}`,
        borderRadius: '12px',
        padding: 8,
      },
      inputMultiline: {
        padding: 8,
      },
    },
  },
};

export default MuiGenerics;
