/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FullScreenIcon = ({ pathFill, ...remainingProps }: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    {...remainingProps}
  >
    <path d="M0 7.4375V1.625C0 1.00156 0.501562 0.5 1.125 0.5H6.9375C7.24687 0.5 7.5 0.753125 7.5 1.0625V1.4375C7.5 1.74688 7.24687 2 6.9375 2H1.5V7.4375C1.5 7.74687 1.24688 8 0.9375 8H0.5625C0.253125 8 0 7.74687 0 7.4375ZM14.0625 0.5H19.875C20.4984 0.5 21 1.00156 21 1.625V7.4375C21 7.74687 20.7469 8 20.4375 8H20.0625C19.7531 8 19.5 7.74687 19.5 7.4375V2H14.0625C13.7531 2 13.5 1.74688 13.5 1.4375V1.0625C13.5 0.753125 13.7531 0.5 14.0625 0.5ZM21 14.5625V20.375C21 20.9984 20.4984 21.5 19.875 21.5H14.0625C13.7531 21.5 13.5 21.2469 13.5 20.9375V20.5625C13.5 20.2531 13.7531 20 14.0625 20H19.5V14.5625C19.5 14.2531 19.7531 14 20.0625 14H20.4375C20.7469 14 21 14.2531 21 14.5625ZM6.9375 21.5H1.125C0.501562 21.5 0 20.9984 0 20.375V14.5625C0 14.2531 0.253125 14 0.5625 14H0.9375C1.24688 14 1.5 14.2531 1.5 14.5625V20H6.9375C7.24687 20 7.5 20.2531 7.5 20.5625V20.9375C7.5 21.2469 7.24687 21.5 6.9375 21.5Z" fill={pathFill} />
  </SvgIcon>
);

export default FullScreenIcon;
