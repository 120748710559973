import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ToolBox from './ToolBox';
import Videos from './Videos';
import GettingStarted from './GettingStarted';
import RubricIndicatorsStandards from './RubricIndicatorsStandards';
import SpeakingWritingAnchors from './SpeakingWritingAnchors';
import RubricIndicatorsView from './RubricIndicatorsStandards/Views/RubricIndicatorsView';
import StandardsOverviewView from './RubricIndicatorsStandards/Views/StandardsOverviewView';
import StandardsK5View from './RubricIndicatorsStandards/Views/StandardsK5View';
import Standards9To12View from './RubricIndicatorsStandards/Views/Standards9To12View';
import Standards6To8View from './RubricIndicatorsStandards/Views/Standards6To8View';
import { Skills } from '../../variables/types';
import Anchors from './Anchors';

const TeacherToolBox = () => (
  <>
    <Switch>

      {/* Routes to be active in upcoming PR's */}

      <Route
        path="/toolbox/getting-started"
        component={GettingStarted}
        exact
      />
      <Route
        path="/toolbox"
        component={ToolBox}
        exact
      />
      <Route
        path="/toolbox/videos"
        component={Videos}
        exact
      />
      <Route
        path="/toolbox/rubric-indicators-standards"
        component={RubricIndicatorsStandards}
        exact
      />
      <Route
        path="/toolbox/speaking-writing-anchors"
        component={SpeakingWritingAnchors}
        exact
      />
      <Route
        path="/toolbox/rubric-indicators-standards/rubric"
        component={RubricIndicatorsView}
        exact
      />
      <Route
        path="/toolbox/rubric-indicators-standards/standards-overview"
        component={StandardsOverviewView}
        exact
      />
      <Route
        path="/toolbox/rubric-indicators-standards/k-5"
        component={StandardsK5View}
        exact
      />
      <Route
        path="/toolbox/rubric-indicators-standards/6-8"
        component={Standards6To8View}
        exact
      />
      <Route
        path="/toolbox/rubric-indicators-standards/9-12"
        component={Standards9To12View}
        exact
      />
      <Route
        path={[
          `/toolbox/speaking-writing-anchors/${Skills.Speaking}`,
        ]}
        render={() => <Anchors section={Skills.Speaking} />}
      />
      <Route
        path={[
          `/toolbox/speaking-writing-anchors/${Skills.Writing}`,
        ]}
        render={() => <Anchors section={Skills.Writing} />}
      />
      <Route
        path="/toolbox/videos"
        component={Videos}
        exact
      />
      { /* <Route
        path="/toolbox/teaching-ideas"
        component={TeachingIdeas}
        exact
      />
      <Route
        path="/toolbox/research"
        component={Research}
        exact
      /> */}
    </Switch>
  </>
);

export default React.memo(TeacherToolBox);
