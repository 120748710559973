import React from 'react';
import {
  Box,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { GraphSetData, Skills } from '../../../variables/types';
import StyledTableContainer from '../../Assignments/AssignmentsTable/List.styled';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { SKILL_TYPE_TEXT } from '../../../variables/constant';
import useRedirect from '../../../hooks/useRedirect';
import { toSingleReadableFormat } from '../../../utils/dateFormat';
import { SxProps } from '@mui/system';

interface Props {
  data?: GraphSetData | null,
  loading?: boolean
  section?: Skills
  onClose?: () => void,
  hoverData?: boolean,
}

interface StyleProps {
  color?: string;
  skillKey?: string;
  hoverData?: boolean,
}

interface StudentData {
  student_id: string,
  student_name: string,
  current_goal?: string
  current_goal_skill?: string
  assigned_date?: string
  icon_base64?: string
}

const StylesCSS = ( theme: Theme, { color, skillKey, hoverData }: StyleProps) => ({
  assignmentNameCell: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2.25),
  },
  goal: {
    color: theme.palette.fontColors.fontBlack,
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.75),
  },
  width20: {
    width: '20%',
  },
  width60: {
    width: '60%',
  },
  tableWithNoData: {
    '& tbody tr:hover': {
      cursor: 'initial',
    },
  },
  tableHeadRoot: {
    borderRadius: 0,
  },
  tableHeadCell: {
    '&.MuiTableCell-root': {
      backgroundColor: color ? color : 'inherit',
      color: (skillKey === 'emerging' || skillKey === 'intermediate') ? theme.palette.fontColors.fontBlack : theme.palette.customWhite.main,
      borderBottom: 0,
    },
  },
  tableContainerRoot: {
    '&.MuiTableContainer-root': {
      overflow: 'scroll',
      borderColor: color ? color : theme.palette.customRed.progressPrimary,
      borderTopLeftRadius: hoverData ? theme.spacing(1.5) : 0,
      height: 'auto',
      minHeight: 'auto',
      maxHeight: hoverData ? theme.spacing(37.5) : 'auto',
    },
  },
  goalIconText: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
    margin: '0px 0px 0px auto',
    color: theme.palette.fontColors.fontBlack,
    fontSize: theme.spacing(3.5),
    float: 'right',
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      color: theme.palette.customBlack.lightBlack,
    },
  },
  imageDimensions: {
    height: theme.spacing(2.75),
    width: theme.spacing(2.75),
  },
  imageStyles: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    paddingTop: theme.spacing(5),
  },
  noFilter: {
    filter: 'none',
  },
});

const ScoreTable = ({
  loading,
  data,
  section,
  onClose,
  hoverData,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme,  { color: data?.color ?? '', skillKey: data?.skill_key, hoverData });
  const isTableEmpty = !data || data?.numberOfStudents === 0;
  const { search } = useRedirect();
  const GetIconFromBase = (iconString: string) => (
    <img alt="performanceIcon" src={`data:image/svg+xml;base64,${iconString}`} />
  );

  return (
    <Box width="100%">
      {hoverData
      && (
        <Box>
          <CloseIcon
            onClick={onClose}
            sx={styles.closeIcon as SxProps}
          />
          <Box sx={styles.headerContainer}>
            <Box sx={styles.imageStyles} style={{ backgroundColor: data?.color }}>
              <img style={{ ...styles.imageDimensions, ...styles.noFilter }} alt="performanceIcon" src={`data:image/svg+xml;base64,${data?.iconString}`} />
            </Box>
            <Typography variant='tileTitle' component='p' sx={{ letterSpacing: '1px', padding: theme.spacing(0, 2.5, 2.5) }}>
              {data?.skill_label}
              {' '}
              {data?.skillType}
              {' '}
              Performance
            </Typography>
          </Box>
        </Box>
      )}
      <Box>
        <StyledTableContainer
          sx={styles.tableContainerRoot}
        >
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={ loading || isTableEmpty ? styles.tableWithNoData : {} }
          >
            <TableHead
              sx={styles.tableHeadRoot}
            >
              <TableRow>
                <TableCell
                  sx={{ ...styles.width20, ...styles.tableHeadCell }}
                >
                  Student
                </TableCell>
                <TableCell
                  sx={{ ...styles.width60, ...styles.tableHeadCell }}
                >
                  {`Active ${SKILL_TYPE_TEXT[section ?? Skills.Speaking]} Goal`}
                </TableCell>
                <TableCell
                  sx={{ ...styles.width20, ...styles.tableHeadCell }}
                >
                  Assigned
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && data?.students?.map((student: StudentData) => (
                <TableRow key={student.student_id}>
                  <TableCell
                    sx={styles.assignmentNameCell}
                  >
                    <Link
                      component={RouterLink}
                      to={{
                        pathname: `/students/${student.student_id}/view/task`,
                        search,
                      }}
                      underline='none'
                    >
                      {student.student_name}
                    </Link>
                  </TableCell>
                  <TableCell
                    sx={styles.goal}
                  >
                    <Typography sx={styles.goalIconText}>
                      {student.current_goal !== null ? GetIconFromBase(student?.icon_base64 || '') : null}
                      {' '}
                      <Typography component="span" sx={{ marginLeft: theme.spacing(2) }}>
                        {student.current_goal ?? 'No goals Assigned'}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={styles.goal}
                  >
                    {student.assigned_date ? toSingleReadableFormat(student.assigned_date) : '-'}
                  </TableCell>
                </TableRow>
              ))}
              {!loading && isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="center"
                  >
                    <NoResults
                      label="No students in this section"
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </Box>
  );
};

export default React.memo(ScoreTable);
