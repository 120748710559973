import React from 'react';
import {
  Box, Button, Typography,
} from '@mui/material';
import StyledCard from '../../../../components/StyledCard';
import { StyledImageBox } from '../../../../components/PTTile/PTTtile.styled';
import AudioPlayer from '../../../../components/Player';
import useStudentsProgressScores from './student-progress-score-hooks';
import StyledBodyTypography from './feedback.styled';
import { isIpad } from '../../../../variables/constant';
import { palette  } from '../../../../theme/palette';

interface Props {
  imageSrc?: string,
  teacherFeedback?: string,
}


const styles = {
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
    objectFit: 'fill',
    display: 'block',
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 12,
    border: `2px solid ${palette.customBackground.disabled}`,
    borderTopWidth: '1px',
    borderRadius: '0 0 12px 12px',
  },
  viewBtn: {
    color: palette.customWhite.main,
  },
  feedbackHeader: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '33px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
    color: palette.fontColors.fontBlack,
  },
  audioHeader: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '33px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
    color: palette.fontColors.fontBlack,
  },
  feedbackText: {
    fontSize: '18px',
    lineHeight: '33px',
    display: 'flex',
    alignItems: 'center',
    color: palette.fontColors.fontBlack,
  },
  customWidth: {
    width: isIpad ? '100%' : '70%',
  },
};


const Feedback = ({ imageSrc, teacherFeedback }: Props) => {
  const {
    studentTasks,
    handleRedirection,
  } = useStudentsProgressScores();
  return (
    <Box
      display="grid"
      mt={3}
      sx={{ ...styles.customWidth }}
    >
      <Box mt={3}>
        <StyledCard titleAlignment="left" contentWrapperMinHeightAuto>
          <Box
            mt={3}
            display="grid"
            width="100%"
            gridTemplateColumns="1fr 2fr"
            sx={{
              gridGap: 24,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <StyledImageBox>
                <img
                  src={imageSrc}
                  alt="task"
                  style={styles.image}
                />
              </StyledImageBox>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRedirection(studentTasks?.id || '')}
                sx={{ ...styles.viewBtn }}
              >
                View my work
              </Button>
            </Box>
            <Box>
              {
                (teacherFeedback
                  || (studentTasks?.getAdditionalFeedback?.audio && studentTasks?.getAdditionalFeedback?.audio?.length > 0))
                && (
                  <Box>
                    <Typography variant='sContentHeader' sx={{ lineHeight: '24px' }}>
                      Teacher Feedback
                    </Typography>
                    <StyledBodyTypography>
                      {teacherFeedback}
                    </StyledBodyTypography>
                    <Box
                      mt={3}
                    >
                      {studentTasks?.getAdditionalFeedback?.audio && studentTasks?.getAdditionalFeedback?.audio?.length > 0
                        && (
                          <AudioPlayer
                            customStyle
                            showLabel
                            label="A message from your teacher"
                            url={studentTasks?.getAdditionalFeedback?.audio[0]?.url}
                            fileType={studentTasks?.getAdditionalFeedback?.audio[0]?.file_type}
                            backpackPlayer
                          />
                        )}
                    </Box>
                  </Box>
                )
              }
            </Box>
          </Box>
        </StyledCard>
      </Box>
    </Box>
  );
};
export default Feedback;
