/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SuperAdminIcon = (props: any) => (
  <SvgIcon width="72" height="72" viewBox="0 0 72 72" fill="none" {...props}>
    <g clip-path="url(#clip0_13559_32487)">
      <path d="M52.4167 33.9167C53.465 33.9167 54.4825 34.04 55.5 34.1942V19.3325L32.375 9.25L9.25 19.3325V34.4717C9.25 48.47 19.1167 61.5742 32.375 64.75C34.0708 64.3492 35.705 63.7633 37.3083 63.0542C35.1808 60.0325 33.9167 56.3633 33.9167 52.4167C33.9167 42.2108 42.2108 33.9167 52.4167 33.9167Z" fill="#2A2A8B" />
      <path d="M52.4166 40.0833C45.6024 40.0833 40.0833 45.6024 40.0833 52.4166C40.0833 59.2308 45.6024 64.7499 52.4166 64.7499C59.2308 64.7499 64.7499 59.2308 64.7499 52.4166C64.7499 45.6024 59.2308 40.0833 52.4166 40.0833ZM52.4166 44.3383C54.3283 44.3383 55.8699 45.9108 55.8699 47.7916C55.8699 49.6724 54.2974 51.2449 52.4166 51.2449C50.5358 51.2449 48.9633 49.6724 48.9633 47.7916C48.9633 45.9108 50.5049 44.3383 52.4166 44.3383ZM52.4166 60.8958C49.5491 60.8958 47.0516 59.4774 45.5099 57.2883C45.6641 55.0683 50.1658 53.9583 52.4166 53.9583C54.6674 53.9583 59.1691 55.0683 59.3232 57.2883C57.7816 59.4774 55.2841 60.8958 52.4166 60.8958Z" fill="#2A2A8B" />
    </g>
    <defs>
      <clipPath id="clip0_13559_32487">
        <rect width="78" height="78" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>);
export default SuperAdminIcon;
