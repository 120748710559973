import React from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Text } from 'recharts';
import { Box } from '@mui/material';
import { palette } from '../../../theme/palette';
import { NoDataPieChart } from '../../../variables/constant';
import { PieChartData } from '../../../pages/Reports/AdminBenchmarkReport/BenchmarkSummary/benchmark-summary-hook';

interface Props {
  dataKey: string,
  boxHeight?: number | string,
  boxWidth?: number | string,
  chartHeight?: number,
  skillType?: string,
  chartWidth?: number,
  onLinkClick?: (data: any, skillType: string) => void,
  startAngle?: number,
  endAngle?: number,
  Icon?: React.ReactNode
  tooltipType?: string
  label?: boolean
  innerRadius?: number
  outerRadius?: number
  hideTooltip?: boolean;
  hideGoalsLabel?: boolean;
  iconString?: string,
  innerLabel?: string,
  sectorLabel?: string,
  showPointerCursor?: boolean,
  paddingAngle?: number,
}

const styles = {
  averageScoreText: {
    width: '82.14px',
    fontSize: '34px',
    fontWeight: 700,
    fill: palette.customBlack.fontBlack,
    lineHeight: '46.38px',

  },
  totalGoal: {
    width: '82.14px',
    height: '77.45px',
    fontWeight: 800,
    fill: palette.customBlack.fontBlack,
    fontSize: '16px',
  },
  totalGoalText: {
    width: '119.81px',
    height: '24.84px',
    fontWeight: 400,
    fontSize: '14px',
    fill: palette.customBlack.fontBlack,
    letterSpacing: '0.0757853px',
  },
  labelText: {
    width: '65px',
    height: '20px',
    fontSize: '14px',
    maxWidth: '65px',
    fontWeight: 400,
  },
  imageDimensions: {
    height: 55,
    width: 48,
  },
};

const DonutLabel = ({ viewBox, onLinkClick }: any) => {
  const { cx, cy } = viewBox;
  return (
    <g textAnchor="middle" onClick={onLinkClick}>
      <Text style={styles.totalGoalText} textAnchor="middle" x={cx} y={cy} dy={8} fontSize={14}>
        {NoDataPieChart.Label}
      </Text>
    </g>
  );
};

const EmptyDonutChart = ({
  dataKey,
  boxHeight = 330,
  boxWidth = 410,
  chartHeight = 310,
  chartWidth = 390,
  onLinkClick,
  startAngle = 90,
  endAngle = -270,
  label = false,
  innerRadius = 82,
  outerRadius = 110,
  iconString,
  skillType,
  showPointerCursor = false,
}: Props) => {
  const conditionalStyles = {
    outlineStyles: {
      '.recharts-wrapper>svg>g': {
        outline: 'none !important',
        cursor: showPointerCursor ? 'pointer' : 'default',
      },
    },
  };

  const pieChartData: PieChartData[] = [];
  pieChartData.push({
    studentCount: 1,
    skillLabel: NoDataPieChart.SkillLabel,
    color: NoDataPieChart.NoDataColor,
  });

  return (
    <Box width={boxWidth} height={boxHeight} sx={{ ...conditionalStyles.outlineStyles }}>
      <ResponsiveContainer width="100%" height="100%" >
        <PieChart width={chartWidth} height={chartHeight} >
          <Pie
            data={pieChartData}
            cx={chartWidth / 2}
            cy={chartHeight / 2}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={palette.customWhite.main}
            dataKey={dataKey}
            cornerRadius={4}
            labelLine={label}
            onClick={(data1: any) => { onLinkClick?.(data1, skillType || ''); }}
            minAngle={20}
            blendStroke={true}
          >
            <Label content={<DonutLabel iconString={iconString} onLinkClick={() => onLinkClick?.({}, skillType!)} />} />
            {pieChartData?.map((item, index) => <Cell style={{ outline: 'none' }} key={index} fill={item.color} />)}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default EmptyDonutChart;
