import React, { ReactNode } from 'react';
import {
  useMediaQuery,
  Typography,
} from '@mui/material';
import SwitchToggle from '../../../components/Switch/SwitchToggle';
import { StyledContainer6, StyledContainer7 } from '../ScoreTask.styled';
import { palette  } from '../../../theme/palette';

interface ScoringHeaderProps {
  title: string,
  subTitle?: string,
  showInfo?: boolean,
  titleStyles?: Object,
  headerContainerStyles?: Object,
  headerAction?: ReactNode,
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  switchValue?: boolean,
  anchorEnabled?: boolean | undefined,
  anchorToggle?: Function | undefined,
  updateAction?: boolean
}

interface StyleProps {
  matches: boolean
}

const ScoringHeader = ({
  title,
  subTitle,
  headerAction,
  titleStyles,
  headerContainerStyles,
  onClick,
  anchorEnabled = false,
  anchorToggle,
  updateAction,
}: ScoringHeaderProps) => {
  const matches = useMediaQuery('(max-width:1366px)');
  const scoringSubHeaderStyles = ({ matches }: StyleProps) => ({
    display: 'flex',
    color: `${palette.customRed.darkRed} !important`,
    fontSize: matches ? '15px' : '18px',
  });
  const chromeBookFontStyles = ({ matches }: StyleProps) => ({
    position: 'absolute',
    fontSize: matches ? '15px' : '18px',
  });
  return (
    <StyledContainer6
      sx={headerContainerStyles}
      onClick={onClick}
    >
      <StyledContainer7>
        <Typography variant='sContentHeader' sx={titleStyles}>
          {title}
          {
            headerAction
          }
        </Typography>
        {
          title && (title.includes('Scores')) && (
            <SwitchToggle
              switchLabel="Show Anchors"
              name={title}
              value={anchorEnabled}
              handleChange={(ev) => { anchorToggle?.(ev.target.checked); }}
            />
          )
        }
      </StyledContainer7>
      {
        updateAction && (
          <Typography variant='sLabel' sx={chromeBookFontStyles({ matches })}>
            Saving...
          </Typography>
        )
      }
      <div>
        {
          subTitle && (
            <Typography variant='sLabel' sx={scoringSubHeaderStyles({ matches })}>
              {subTitle}
            </Typography>
          )
        }
      </div>
    </StyledContainer6>
  );
};

export default ScoringHeader;
