import React from 'react';

const SiteMaintenance = () => {
  return (
    <div>
      <h1>Site Maintenance</h1>
    </div>
  );
};


export default SiteMaintenance;
