import React from 'react';
import { useTheme } from '@mui/material/styles';
import Layout from '../../components/Layout';
import StudentDashboard from '../StudentDashboard';
import TeacherDashboard from '../TeacherDashboard';
import useRole from '../../hooks/useRole';
import AdminDashboard from '../AdminDashboard';
import { StyledFlexColBoxContainer } from '../../components/Common/Common.styled';

const Dashboard = () => {
  const theme = useTheme();
  const { isStudent, isTeacher, isSuperAdmin } = useRole();
  return (
    <Layout>
      <StyledFlexColBoxContainer
        alignItems="center"
        m={theme.spacing(0)}
        p={theme.spacing(0)}
      >
        {isStudent && <StudentDashboard />}
        {isTeacher && <TeacherDashboard />}
        {isSuperAdmin && <AdminDashboard />}
      </StyledFlexColBoxContainer>
    </Layout>
  );
};

export default React.memo(Dashboard);
