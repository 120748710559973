import { StringParam, useQueryParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import {
  useGetScoreSummaryQuery,
  useGetBackPackSubmissionQuery,
} from '../../../../generated/graphql';
import { RubricScoreDetail } from '../../../../variables/types';
import { palette  } from '../../../../theme/palette';

const useStudentsProgressScores = () => {
  const [submission, setSubmission] = useQueryParam('submission', StringParam);

  const { data: scoreData, loading: scoreLoading } = useGetScoreSummaryQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      submissionID: submission || '',
    },
  });

  const maxScoreCount = scoreData?.scoreSummaryForStudent?.max_score_count || 5;

  const { data, loading } = useGetBackPackSubmissionQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: submission || '',
    },
  });

  const scoreGraphData = scoreData?.scoreSummaryForStudent?.rubric_groups.map((scoreSubmission) => {
    const totalScore = scoreSubmission.score_line_items.reduce((acc, score: RubricScoreDetail) => acc + score.count, 0);
    const averageScore = parseFloat((totalScore / (scoreSubmission.score_line_items.length || 1)).toFixed(1));
    return {
      submissionType: scoreSubmission.submission_type,
      average: averageScore,
      barData: scoreSubmission.score_line_items.map((score: RubricScoreDetail) => ({
        skillLevel: score.count,
        skillLabel: score.score_detail_title,
        skillIcon: score.rubric_icon_base_64,
        skillDescription: score.score_detail_description,
        skillLinkText: 'student',
        color: score.score_detail_color_code,
        scoreLabel: score.rubric_title,
        scoreKey: score.rubric_title,
      })),
      donutGraphData: [{
        color: scoreSubmission.score_line_items?.find((score: RubricScoreDetail) => score.count === Math.floor(averageScore))?.score_detail_color_code,
        average: averageScore,
      },
      {
        color: palette.customBackground.graphBackgroundColor,
        average: maxScoreCount - averageScore,
      }],
    };
  }).sort((score1, score2) => (score1.submissionType > score2.submissionType ? 1 : -1));

  const donutData = scoreData?.scoreSummaryForStudent?.score_details.map((scoreType: any) => ({
    name: scoreType.title_key,
    value: 1,
    color: scoreType.color_code,
  }));

  const studentTasks = data?.submission;

  const history = useHistory();
  const handleRedirection = (submissionId: string) => async () => {
    if (submissionId) history.push(`/backpack/${submissionId}/view`);
  };

  return {
    loading: loading || scoreLoading,
    studentTasks,
    submission,
    setSubmission,
    handleRedirection,
    maxScoreCount,
    scoreGraphData,
    donutData,
  };
};

export default useStudentsProgressScores;
