import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

const TypeformFlashlightIcon = (props: any) => (
  <SvgIcon 
    width="30"
    height="30" 
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M69.8684 38.8301H63.0884V89.1001H69.8684V38.8301Z" fill={props?.pathFill || palette.customOrange.flashlightSaffron}/>
    <path
      d="M31.6083 76.2301C33.7949 80.1171 36.9727 83.355 40.8179 85.614C44.6632 87.8731 49.0386 89.0725 53.4983 89.0901H56.1583V38.8301H53.4983C49.0382 38.8456 44.662 40.0442 40.8163 42.3034C36.9707 44.5627 33.7933 47.8017 31.6083 51.6901L30.6083 53.4501H0.878334C-0.292778 60.4011 -0.292778 67.4991 0.878334 74.4501H30.6083L31.6083 76.2301Z"
      fill={props?.pathFill || palette.customOrange.flashlightSaffron}/>
    <path
      d="M63.8782 2.21034e-05C49.9712 0.0308477 36.4519 4.58652 25.3625 12.9789C14.2731 21.3712 6.216 33.1444 2.4082 46.52H26.6382C29.55 42.0451 33.53 38.3655 38.2192 35.8131C42.9085 33.2607 48.1594 31.9159 53.4982 31.9H76.7982V96.02H53.4982C48.1594 96.0041 42.9085 94.6593 38.2192 92.1069C33.53 89.5546 29.55 85.875 26.6382 81.4H2.4082C5.655 92.8472 12.0311 103.161 20.8193 111.183C29.6076 119.205 40.4596 124.615 52.1548 126.806C63.85 128.997 75.9245 127.882 87.0206 123.586C98.1167 119.29 107.794 111.984 114.965 102.488C122.135 92.9929 126.514 81.6853 127.61 69.8372C128.705 57.989 126.473 46.0702 121.165 35.4211C115.857 24.7721 107.683 15.8151 97.5628 9.55761C87.4424 3.30007 75.7769 -0.00986899 63.8782 2.21034e-05ZM84.4682 43.81L101.948 34.32C103.232 36.2437 104.391 38.2479 105.418 40.32L84.4182 51.69L84.4682 43.81ZM101.948 93.62L84.4682 84.11V76.23L105.468 87.6C104.428 89.6818 103.252 91.6928 101.948 93.62ZM84.4682 67.42V60.49H110.878C110.958 61.64 110.998 62.79 110.998 63.96C110.998 65.13 110.998 66.28 110.878 67.42H84.4682Z"
      fill={props?.pathFill || palette.customOrange.flashlightSaffron}/>
  </SvgIcon>
);

export default TypeformFlashlightIcon;
