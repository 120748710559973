/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const VideoIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="55"
    viewBox="0 0 69 55"
    fill="none"
    {...props}
  >
    <path d="M61.5 0H6.83333C3.075 0 0 3.075 0 6.83333V47.8333C0 51.5917 3.075 54.6667 6.83333 54.6667H61.5C65.2583 54.6667 68.3333 51.5917 68.3333 47.8333V6.83333C68.3333 3.075 65.2583 0 61.5 0ZM25.625 42.7083V11.9583L49.5417 27.3333L25.625 42.7083Z" fill={props.pathFill || palette.customWhite.main} />
  </SvgIcon>
);

export default React.memo(VideoIcon);
