import React from 'react';
import { Box, Typography } from '@mui/material';
import StyledCard from '../../../../components/StyledCard';
import BarGraphVertical from '../../../../components/Charts/Graph/BarGraphVertical';
import NoResults from '../../../../components/NoResults';
import EmptyResults from '../../../../components/Icons/EmptyResults';
import DonutScoreChart from '../../../../components/Charts/Graph/DonutScoreChart';
import DonutStickIcon from '../../../../components/Icons/DonutStickIcon';
import { SubmissionTypes } from '../../../../variables/types';
import { BackpackAssignmentFragment, BackpackBenchmarkFragment } from '../../../../generated/graphql';
import BackpackPrompt from './prompt';
import {
  SPEAKING_DEFAULT_ASSIGNMENT_PROMPT_TEXT,
  SPEAKING_DEFAULT_BENCHMARK_PROMPT_TEXT,
  WRITING_DEFAULT_PROMPT_TEXT,
} from '../../../../variables/constant';
import { palette } from '../../../../theme/palette';
import { unescapeSpecialCharacters } from '../../../../utils/handleEscapeCharacters';

interface Props {
  stickyHeader?: boolean;
  loading?: boolean;
  maxScoreCount?: number;
  scoreGraphData: any;
  donutData: any;
  assignmentOrBenchmark?: BackpackAssignmentFragment | BackpackBenchmarkFragment | null;
}

const styles = {
  customFlex: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: window.matchMedia('(max-width: 1024px)').matches ? '1fr 1fr' : '1fr 3fr',
    marginTop: '24px',
  },
  barStyles: {
    stroke: palette.customWhite.main,
    strokeWidth: 5,
  },
  customWidth: {
    width: window.matchMedia('(max-width: 1024px)').matches ? '100%' : '70%',
  },
  graphsContainer: {
    display: 'grid',
    gap: '50px',
    gridTemplateColumns: window.matchMedia('(max-width: 1024px)').matches ? '' : '2fr 2fr',
  },
};

const Scores = ({ loading, maxScoreCount = 5, scoreGraphData, donutData, assignmentOrBenchmark }: Props) => {
  const getScoreAngle = (score: number) => (240 / maxScoreCount) * score;

  const speakingPromptLength = assignmentOrBenchmark?.speaking_prompt?.length;
  const writingPromptLength = assignmentOrBenchmark?.writing_prompt?.length;

  return (
    <Box display="grid" sx={{ ...styles.customWidth }} mt={3}>
      <StyledCard mediaParam={!!window.matchMedia('(max-width: 1024px)').matches}>
        <Box
          sx={{
            gridGap: 50,
            ...styles.graphsContainer,
          }}
        >
          {!loading &&
            scoreGraphData &&
            scoreGraphData.length > 0 &&
            scoreGraphData.map((score: any) => (
              <Box key={score.submissionType}>
                {score?.average > 0 && (
                  <Box>
                    {assignmentOrBenchmark &&
                    assignmentOrBenchmark?.is_common_prompt === true &&
                    speakingPromptLength === 0 &&
                    writingPromptLength === 0 ? (
                      <BackpackPrompt
                        type={score.submissionType}
                        promptText={
                          score.submissionType === SubmissionTypes.Speaking
                            ? assignmentOrBenchmark.__typename === 'BenchmarkPT'
                              ? SPEAKING_DEFAULT_BENCHMARK_PROMPT_TEXT
                              : SPEAKING_DEFAULT_ASSIGNMENT_PROMPT_TEXT
                            : WRITING_DEFAULT_PROMPT_TEXT
                        }
                      />
                    ) : assignmentOrBenchmark &&
                      ((score?.submissionType === SubmissionTypes.Speaking ||
                        assignmentOrBenchmark?.is_common_prompt) &&
                        speakingPromptLength!) > 0 ? (
                      <BackpackPrompt
                        type={score.submissionType}
                        promptText={unescapeSpecialCharacters(assignmentOrBenchmark?.speaking_prompt || '')}
                        promptFileId={assignmentOrBenchmark?.speaking_prompt_file?.id}
                        promptFileURL={assignmentOrBenchmark?.speaking_prompt_file?.url}
                        promptFileType={assignmentOrBenchmark?.speaking_prompt_file?.file_type}
                      />
                    ) : (
                      assignmentOrBenchmark &&
                      score?.submissionType === SubmissionTypes.Writing &&
                      writingPromptLength! > 0 && (
                        <BackpackPrompt
                          type={score.submissionType}
                          promptText={unescapeSpecialCharacters(assignmentOrBenchmark?.writing_prompt)}
                          promptFileId={assignmentOrBenchmark?.writing_prompt_file?.id}
                          promptFileURL={assignmentOrBenchmark?.writing_prompt_file?.url}
                          promptFileType={assignmentOrBenchmark?.writing_prompt_file?.file_type}
                        />
                      )
                    )}
                    <Typography variant="sContentHeader">
                      <Typography variant="sContentHeader">{`${score.submissionType} Scores`}</Typography>
                    </Typography>
                    <Box sx={{ ...styles.customFlex }}>
                      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        {score.donutGraphData?.length > 0 && (
                          <DonutScoreChart
                            data={donutData}
                            dataKey="value"
                            hideTooltip
                            hideGoalsLabel
                            Icon={DonutStickIcon}
                            angle={getScoreAngle(score.average)}
                            innerRadius={60}
                            outerRadius={80}
                            boxWidth={200}
                            chartWidth={200}
                            chartHeight={200}
                            boxHeight={200}
                          />
                        )}
                        <Typography variant="secondaryLabel">Overall Score</Typography>
                        <Typography variant="font22">{score.average}</Typography>
                      </Box>
                      {score.barData && score.barData?.length > 0 && (
                        <BarGraphVertical
                          data={score.barData}
                          yAxisKey="skillLevel"
                          xAxisKey="skillIcon"
                          boxWidth={score.submissionType === 'Speaking' ? '100%' : '70%'}
                          yAxisDomain={[0, maxScoreCount]}
                          boxHeight={200}
                          chartWidth={400}
                          chartHeight={200}
                        />
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          {!loading && scoreGraphData && scoreGraphData.every((score: any) => score?.average === 0) && (
            <NoResults
              description="No scores recorded yet"
              svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
            />
          )}
        </Box>
      </StyledCard>
    </Box>
  );
};

export default Scores;
