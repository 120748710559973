import { palette } from '../../../theme/palette';
import { Box, GlobalStyles, styled } from '@mui/material';
import React from 'react';

export const styles = {
  container: {
    padding: '16px',
    paddingBottom: 0,
    width: '100%',
  },
  main: {
    height: 384,
    position: 'relative',
    paddingBottom: '20px',
  },
  fakeLines: {
    position: 'absolute',
    left: '40px',
    width: 'calc(100% - 40px)',
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 350,
  },
  line: {
    width: '100%',
    borderBottom: `1px solid ${palette.customGrey.darkGrey}`,
    '&:last-child': {
      borderBottom: `2px solid ${palette.customGrey.darkGrey}`,
    },
  },
  fadify: {
    animationName: 'fadify',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
  yAxis: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'absolute',
    marginTop: '-4px',
    left: 0,
    height: 360,
  },
  delay1: {
    animationDelay: '.05s !important',
  },
  delay2: {
    animationDelay: '.1s !important',
  },
  delay3: {
    animationDelay: '.15s !important',
  },
  delay4: {
    animationDelay: '.2s !important',
  },
  delay5: {
    animationDelay: '.25s !important',
  },
  sectionWave: {
    position: 'absolute',
    width: 'calc(100% - 40px)',
    top: '43%',
    transform: 'translate(0%, -50%)',
    height: 170,
    left: '40px',
  },
  dash: {
    animation: 'dash 2s linear infinite',
    stroke: palette.customGrey.darkGrey,
  },
  xAxis: {
    width: '90%',
    position: 'absolute',
    bottom: 0,
    left: '7%',
    justifyContent: 'space-between',
    display: 'flex',
  },
};
export const StyledSpanYTick = styled('span')({
  width: '20px',
  height: '10px',
  borderRadius: '2px',
  animationDuration: '1s',
  background: palette.customGrey.darkGrey,
});
export const StyledSpanXTick = styled('span')({
  width: '30px',
  height: '10px',
  borderRadius: '4px',
  animationDuration: '1s',
  background: palette.customGrey.darkGrey,
});

export const StyledBoxYTicks = styled(Box)({
  width: '100%',
  borderBottom: `1px solid ${palette.customGrey.darkGrey}`,
  '&:last-child': {
    borderBottom: `2px solid ${palette.customGrey.darkGrey}`,
  },
});

export const Global = () => {
  return <GlobalStyles styles={{
    '@keyframes fadify': {
      '0%': { opacity: 1 },
      '100%': {
        opacity: 1,
      },
      '85%': {
        opacity: 0.85,
      },
      '70%': {
        opacity: 0.7,
      },
      '55%': {
        opacity: 0.55,
      },
      '40%': {
        opacity: 0.4,
      },
    },
    '@keyframes dash': {
      '0%': {
        stroke: palette.customGrey.lightGrey,
        transform: 'translateX(-490px)',
      },
      '50%': {
        stroke: palette.customGrey.lighterGrey,
      },
      '100%': {
        stroke: palette.customGrey.silver,
        transform: 'translateX(0px)',
      },
    },
  }}/>;
};
