import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Grid, Checkbox, Button, Typography, TextField, Theme, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { addDays, format, isAfter, isBefore, isValid, max } from 'date-fns';
import ListMenu from '../../../../components/AppFilter/ListMenu';
import {
  CreateAssignmentStylesCss,
} from '../CreateAssignment.styled';
import { StepProp, useCreateAssignment } from '../create-assignment-hook';
import { StyledNextButton } from '../../Assignments.styled';
import FormProgressBar from '../../../../components/FormProgressBar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarIcon from '../../../../components/Icons/CalendarIcon';
import { CreatePtSteps } from '../../../../variables/constant';
import { cleanDate } from '../../../../utils/dateFormat';
import { palette } from '../../../../theme/palette';
import AssignmentNavigationModal from './AssignmentNavigationModal';

export interface DateSelectorProp {
  onChange: (date: Date | null, keyboardString?: string | undefined) => void,
  value: Date | null,
}

const dateIconStyles = {
  customBox: {
    '& .MuiButtonBase-root.MuiIconButton-root': {
      marginRight: '0px !important',
    },
  },
};

const DetailsStep = ({ title }: StepProp) => {
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);
  const today = new Date();

  const {
    routeData,
    assignmentFetched,
    assignmentInput,
    sections,
    submitStep,
    isPastAssignment,
    academicSessionEndDate,
    isAssignmentSubmissionsSatrted,
    openNavigationModal,
    setOpenNavigationModal,
    backToAssignments,
  } = useCreateAssignment();

  const {
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
  } = useFormContext();

  const editOriginalCloseAt = cleanDate(assignmentFetched?.assignment?.close_at, true);
  const startAt = cleanDate(assignmentInput?.start_at);
  const closeAt = cleanDate(assignmentInput?.close_at, true);
  const startAtDatePicker = watch('start_at', startAt);
  const closeAtDatePicker = watch('close_at', closeAt);

  const isBeforeMaxDate = (value: Date) => {
    const maxDate = cleanDate(academicSessionEndDate);
    const inputDate = new Date(value);
    if (isBefore(inputDate, maxDate) || format(inputDate, 'dd MM yy') === format(maxDate, 'dd MM yy')
    ) {
      return true;
    } else
      return false;
  };

  return (
    <>
      <Box sx={styles.formatTopNavBar}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant='secondaryTitle'>{title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormProgressBar
              steps={CreatePtSteps}
              progressValue={routeData?.currentStep! - 1}
            />
          </Grid>
        </Grid>
      </Box>
      <form
        name="assignment-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(submitStep)}
      >
        <Box>
          <Grid container item xs={5}>
            <Grid item xs={12} sx={styles.formItemContainer}>
              <Box m={theme.spacing(2, 0, 1, 0)}>
                <Typography variant='sFormTitle'>Assign To</Typography>
              </Box>
              <Box>
                <Controller
                  name="section_id"
                  defaultValue={assignmentInput?.section_id!}
                  render={({ onChange, value }) => (
                    <ListMenu
                      menuTitle={assignmentInput?.section_id ? '' : 'Select Class or Group'}
                      hideMenuTitle
                      options={sections}
                      keyAttribute="id"
                      labelAttribute="name"
                      childrenAttribute="groups"
                      onChange={onChange}
                      selectedItem={value}
                      defaultValue={assignmentInput?.section_id!}
                      menuValue={assignmentInput?.section_id!}
                      isDisabled={isPastAssignment}
                    />
                  )}
                  rules={{ required: 'Please select the section.' }}
                  control={control}
                />
                <Typography variant="sFormHelper">
                  {errors?.section_id && errors?.section_id?.message}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sx={styles.formItemContainer}>
              <Box m={theme.spacing(2, 0, 1, 0)}>
                <Typography variant='sFormTitle'>Date Range<br /></Typography>
              </Box>
              <Box m={theme.spacing(1, 0, 1, 0)}>
                <Typography variant="sFormHelperText">This is when the assignment will be available to the students.<br /></Typography>
              </Box>
              <Grid container item>
                <Grid item xs={5} sx={{ ...dateIconStyles.customBox }}>
                  <Controller
                    name="start_at"
                    defaultValue={!startAtDatePicker ? today : startAtDatePicker}
                    value={!startAtDatePicker ? today : startAtDatePicker}
                    render={({ onChange, value }: DateSelectorProp) => (
                      <DatePicker
                        views={['day']}
                        PopperProps={{
                          sx: {
                            ' & .Mui-selected': {
                              color: `${palette.customWhite.main}!important`,
                            },
                          },
                        }}
                        renderInput={(params: any) => <TextField {...params} helperText={null} sx={styles.datepickerInput} />}
                        minDate={today}
                        maxDate={cleanDate(academicSessionEndDate)}
                        onChange={onChange}
                        value={cleanDate(value)}
                        disabled={isPastAssignment || isAssignmentSubmissionsSatrted}
                        components={{
                          OpenPickerIcon: CalendarIcon,
                        }}
                      />
                    )}
                    rules={{
                      required: 'Please select the start date.',
                      validate: {
                        validStartDate: (value: Date) => isValid(cleanDate(value)) || 'Please enter valid start date.',
                      },
                    }}
                    control={control}
                  />
                  <Typography variant='sFormHelper'>
                    {errors?.start_at && errors?.start_at?.message}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontSize={18} fontWeight={800} align="center" m={theme.spacing(1.25, 2)}> to </Typography>
                </Grid>
                <Grid item xs={5} sx={{ ...dateIconStyles.customBox }}>
                  <Controller
                    name="close_at"
                    defaultValue={!closeAtDatePicker ? today : closeAtDatePicker}
                    value={!closeAtDatePicker ? today : closeAtDatePicker}
                    render={({ onChange, value }: DateSelectorProp) => (
                      <DatePicker
                        views={['day']}
                        renderInput={(params: any) => <TextField {...params} helperText={null} sx={styles.datepickerInput} />}
                        minDate={isPastAssignment ?
                          max([editOriginalCloseAt!, today]) : (
                            startAtDatePicker ? max([addDays(cleanDate(startAtDatePicker!), 1), today]) : today
                          )}
                        maxDate={cleanDate(academicSessionEndDate)}
                        PopperProps={{
                          sx: {
                            ' & .Mui-selected': {
                              color: `${palette.customWhite.main}!important`,
                            },
                          },
                        }}
                        value={cleanDate(value)}
                        components={{
                          OpenPickerIcon: CalendarIcon,
                        }}
                        onChange={onChange}
                      />
                    )}
                    rules={{
                      required: 'Please select the end date.',
                      validate: {
                        dateAfter: (value: Date) => isAfter(cleanDate(value),
                          cleanDate(getValues('start_at')))
                          || 'Close date should be greater than start date.',
                        validCloseDate: (value: Date) => isValid(cleanDate(value))
                          || 'Please enter valid close date.',
                        maximumDate: (value: Date) => isBeforeMaxDate(cleanDate(value)) || 'Close date should be before 30 days from start date.',
                      },
                    }}
                    control={control}
                  />
                  <Typography variant='sFormHelper'>
                    {errors?.close_at && errors?.close_at?.message}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={styles.formItemContainer}>
              <Box display="flex" justifyContent="flex-start">
                <Controller
                  name="back_navigation_allowed"
                  defaultValue={!assignmentInput?.back_navigation_allowed ? false : assignmentInput?.back_navigation_allowed}
                  value={!assignmentInput?.back_navigation_allowed ? false : assignmentInput?.back_navigation_allowed}
                  render={({ onChange, value }) => (
                    <Checkbox
                      name="back_navigation_allowed"
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled={isPastAssignment}
                      sx={isPastAssignment ? styles.disabled : {}}
                    />
                  )}
                  control={control}
                />
                <Typography variant='sFormHelperText'>
                  Allow students to go back during the assignment.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={styles.formBottomNavBar as SxProps<Theme>}
          >
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => { setOpenNavigationModal(true); }}
              >
                Back to Assignments
              </Button>
            </Box>
            <Box>
              <StyledNextButton
                variant="contained"
                color="primary"
                type="submit"
              >
                Next
              </StyledNextButton>
            </Box>
          </Box>
          <AssignmentNavigationModal openModal={openNavigationModal} handleClose={() => { setOpenNavigationModal(false); }} handleNext={() => { backToAssignments(); }} />
        </Box>
      </form>
    </>
  );
};

export default DetailsStep;
