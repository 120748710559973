import React, { useContext, useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NoResults from '../../../../components/NoResults';
import { AnalyticsIcon } from '../../../../components/Icons';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';
import {
  StyledTableContainer,
  StyledTableSort,
  tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import { DistrictWideComparisonsStylesCSS } from '../DistrictWideComparisons.styled';
import { StyledTableCell2 } from '../../../Admin/admin-tables-styled';
import GradeRow from './GradeRow';
import Flyover from '../Flyover';
import GradeSpecificDetails from '../GradeSpecificDetails';

import { DistrictWideGradeDetail, DistrictWideGradeSortType, OrderBy } from '../../../../generated/graphql';
import { DistrictWideComparisonsContext } from '../district-wide-comparisons-hook';

interface Props {
  tableDomId?: string;
  isTableEmpty: boolean,
  loading: boolean,
  sort: DistrictWideGradeSortType,
  order: OrderBy,
  grades: DistrictWideGradeDetail[] | null,
  handleSort: ((column: DistrictWideGradeSortType) => void) | Function,
}

const List = ({
  tableDomId,
  isTableEmpty,
  loading,
  sort,
  order,
  grades,
  handleSort,
}: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const {
    mySchoolData, myDistrictData, setGradeSpecificDataQueryInput,
  } = useContext(DistrictWideComparisonsContext);

  const [showFlyover, setShowFlyover] = useState(false);
  const toggleFlyover = () => {
    if (showFlyover) setGradeSpecificDataQueryInput?.(undefined);
    setShowFlyover(!showFlyover);
  };
  const [flyoverGrade, setFlyoverGrade] = useState<DistrictWideGradeDetail>();
  const openFlyOver = (grade: DistrictWideGradeDetail) => {
    setFlyoverGrade({
      ...grade,
    });
  };

  useEffect(() => {
    if (!!flyoverGrade) toggleFlyover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyoverGrade]);

  return (
    <Box
      display="grid"
      width="100%"
    >
      <Box>
        <StyledTableContainer sx={{ ...styles.tableContainer }}>
          <Table
            id={tableDomId}
            stickyHeader
            aria-label="District-wide Comparisons Schools Table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData), maxHeight: '60px' }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell2 sx={{ textAlign: 'left', ...styles.width20 }}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideGradeSortType.GradePosition)}
                    active={sort === DistrictWideGradeSortType.GradePosition}
                    IconComponent={ArrowDownIcon}
                  >
                    Grade Level
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width10}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideGradeSortType.TeacherCount)}
                    active={sort === DistrictWideGradeSortType.TeacherCount}
                    IconComponent={ArrowDownIcon}
                  >
                    Teachers
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width10}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideGradeSortType.StudentCount)}
                    active={sort === DistrictWideGradeSortType.StudentCount}
                    IconComponent={ArrowDownIcon}
                  >
                    Students
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width20}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideGradeSortType.TotalGoalsAssigned)}
                    active={sort === DistrictWideGradeSortType.TotalGoalsAssigned}
                    IconComponent={ArrowDownIcon}
                  >
                    Total Goals Assigned
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width20}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideGradeSortType.TotalSubmissionsCompletedCount)}
                    active={sort === DistrictWideGradeSortType.TotalSubmissionsCompletedCount}
                    IconComponent={ArrowDownIcon}
                  >
                    PTs Completed
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width10}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideGradeSortType.SpeakingOverallScore)}
                    active={sort === DistrictWideGradeSortType.SpeakingOverallScore}
                    IconComponent={ArrowDownIcon}
                  >
                    Speaking
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width10}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideGradeSortType.WritingOverallScore)}
                    active={sort === DistrictWideGradeSortType.WritingOverallScore}
                    IconComponent={ArrowDownIcon}
                  >
                    Writing
                  </StyledTableSort>
                </StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && grades?.map((grade, index) => (
                <GradeRow rowIndex={index} grade={grade} key={`${grade?.grade_id}-${index}`} openFlyover={openFlyOver}/>
              ))}
              {!loading && isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="We Need More Data."
                      description=""
                      svgComponent={<AnalyticsIcon />}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Flyover
          open={showFlyover}
          onClose={toggleFlyover}
        >
          <GradeSpecificDetails
            schoolId={mySchoolData?.id}
            districtId={myDistrictData?.id}
            grade={flyoverGrade!}
            toggleDrawer={toggleFlyover}
          />
        </Flyover>
      </Box>
    </Box>
  );
};
export default List;
