import { useContext, useState } from 'react';
import { useCreateCustomGoalMutation } from '../../generated/graphql';
import { NotifierType, Skills } from '../../variables/types';
import { DEFAULT_GOAL_OPTION } from '../../variables/constant';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { ScoreContext } from '../ScoreTask/score-context';

interface Props {
  goalType?: Skills,
}

interface CustomGoal {
  description?: string,
  example?: string,
}

const usePredefinedGoals = ({ goalType } : Props) => {
  const [selectedRubric, selectRubric] = useState<string>('');
  const [selectedGoal, selectGoal] = useState<unknown>(DEFAULT_GOAL_OPTION);
  const [customGoal, setCustomGoal] = useState<CustomGoal>({
    description: '',
    example: '',
  });
  const {
    submission,
    addStudentGoal,
    adding,
    predefinedGoalsData,
    predefinedLoading,
    predefinedRefetch,
  } = useContext(ScoreContext);

  const [creteCustomGoal, { loading: creating }] = useCreateCustomGoalMutation();

  const speakingGoals = predefinedGoalsData?.getPredefinedGoals?.speaking;
  const writingGoals = predefinedGoalsData?.getPredefinedGoals?.writing;

  const goalList = goalType === Skills.Speaking
    ? speakingGoals
    : goalType === Skills.Writing
      ? writingGoals
      : [];

  const rubricsList = goalList?.map((goal) => (goal?.rubric?.title));
  const goalOptions = goalList
    ?.find((goal) => goal?.rubric?.title === selectedRubric)?.predefined_goals ?? [];

  const handleCustomGoal = (type: string, text: string) => {
    setCustomGoal((prevState) => ({
      ...prevState,
      [type]: text,
    }));
  };

  const addCustomGoal = async (submissionId?: string, goalId?: string) => {
    try {
      const response = await creteCustomGoal({
        variables: {
          rubricId: goalList?.find((goal) => goal?.rubric?.title === selectedRubric)?.rubric?.id ?? '',
          description: customGoal?.description ?? '',
          example: customGoal.example,
          submissionId,
          goalId,
        },
      });
      if (!response.data?.createPredefinedGoal?.speaking?.[0]?.rubric?.id) {
        openSnackbar({ message: 'Error in creating goal.' }, NotifierType.Error);
      } else {
        predefinedRefetch?.();
        openSnackbar({ message: 'Goal created successfully' }, NotifierType.Success);
      }
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  return {
    speakingGoals,
    writingGoals,
    loading: predefinedLoading,
    goalList,
    selectRubric,
    selectedGoal,
    selectGoal,
    rubricsList,
    goalOptions,
    selectedRubric,
    customGoal,
    handleCustomGoal,
    addCustomGoal,
    creating,
    submission,
    addStudentGoal,
    adding,
  };
};

export default usePredefinedGoals;
