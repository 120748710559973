import { styled } from '@mui/material/styles';
import { TableContainer } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 300px)',
});

export const StyledContainerRoot =  styled(StyledTableContainer)(({ theme }) => ({
  '&.MuiTableContainer-root': {
    borderColor: theme.palette.primary.main,
  },
}));

export default StyledTableContainer;
