import React from 'react';
import StyledCard from '../../../../components/StyledCard';
import NoResults from '../../../../components/NoResults';
import DonutChartLoader from '../../../../components/Loader/DonutChartLoader';
import EmptyResults from '../../../../components/Icons/EmptyResults';
import { GoalSetGraphData } from '../../../../variables/types';
import AnalyticDonutChart from '../../../../components/Charts/Graph/AnalyticDonutChart';
import { GOAL_SCORES, ACTIVE_GOAL_TOOLTIP_TYPE } from '../../../../variables/constant';
import { palette  } from '../../../../theme/palette';
import AppTooltip from '../../../../components/AppTooltip';

interface Props {
  loading: boolean,
  data: any[] | null,
  totalCount: number,
  title: string,
  buttonTitle?: string,
  handleButtonClick?: () => void,
  hideButton?: boolean,
  handleTooltipClick: (data: GoalSetGraphData | null) => void,
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  enableTooltip?: boolean, // to enable tooltip for this component.
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
}

const ActiveGoal = ({
  loading,
  totalCount,
  data,
  buttonTitle = 'View Report',
  title,
  handleButtonClick,
  hideButton = false,
  handleTooltipClick,
  enableTooltip,
  alignTitle, tooltipTitle,
}: Props) => {
  const hasData = !loading && totalCount > 0;
  const goalData = data?.filter((item) => item.numberOfGoals > 0 && item.skill_key !== GOAL_SCORES.all.skill_key) ?? [];
  return (
    <StyledCard
      title={title}
      buttonTitle={!hideButton && hasData ? buttonTitle : undefined}
      onClick={handleButtonClick}
      isToolTip={enableTooltip}
      titleAlignment={alignTitle ? 'left' : 'center'}
      toolTipComponent={<AppTooltip toolTipText={tooltipTitle}/>}
    >
      <>
        {hasData && data && (
          <AnalyticDonutChart
            data={goalData}
            startAngle={90}
            endAngle={-270}
            boxWidth={500}
            chartWidth={500}
            chartHeight={350}
            boxHeight={350}
            dataKey="numberOfGoals"
            tooltipType={ACTIVE_GOAL_TOOLTIP_TYPE}
            totalCount={totalCount}
            onLinkClick={handleTooltipClick}
            label
          />
        )}
        {!loading && totalCount === 0 && (
          <NoResults
            description="No scores recorded yet"
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {loading && <DonutChartLoader />}
      </>
    </StyledCard>
  );
};

export default React.memo(ActiveGoal);
