import { styled, Tab, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '&.MuiTabs-root': {
    border: theme.spacing(0),
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    marginLeft: theme.spacing(-1),
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    '&.MuiButtonBase-root': {
      backgroundColor: theme.palette.customWhite.main,
      color: theme.palette.fontColors.fontBlack,
      borderRadius: theme.spacing(2, 2, 2, 2),
      border: `2px solid ${theme.palette.customGrey.adminButtonBorder}`,
      minWidth: theme.spacing(30),
      padding: theme.spacing(0, 2, 0, 2),
      margin: theme.spacing(0, 1, 0, 1),
      textTransform: 'none',
    },
    '&.Mui-selected': {
      '&.MuiButtonBase-root': {
        backgroundColor: theme.palette.customBlue.primaryBlue,
        color: theme.palette.customWhite.main,
        border: 'none',
      },
    },
  },
}));
