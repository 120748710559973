import { styled } from '@mui/material';
import StateFilter from '../../../components/StateFilter';
import DistrictFilter from '../../../components/DistrictFilter';

export const StyledStatesFilter = styled(StateFilter)(({ theme }) => ({
  width: 300,
  marginTop: theme.spacing(3),
}));

export const StyledDistrictsFilter = styled(DistrictFilter)(( ) => ({
  width: 300,
}));
