import get from 'lodash.get';

export enum Direction {
  Up = 1,
  Down,
}

export interface Sort {
  name: string;
  direction: Direction
}

export const sortData = (sortBy: Sort | undefined) => (a: any, b: any) => {
  if (sortBy) {
    if (sortBy.direction === Direction.Up) {
      if (get(a, sortBy.name) === null) {
        return 1;
      }
      if (get(b, sortBy.name) === null) {
        return -1;
      }
      if (get(a, sortBy.name) > get(b, sortBy.name)) {
        return 1;
      }
      if (get(a, sortBy.name) < get(b, sortBy.name)) {
        return -1;
      }
      return 0;
    }
    if (sortBy.direction === Direction.Down) {
      if (get(a, sortBy.name) === null) {
        return -1;
      }
      if (get(b, sortBy.name) === null) {
        return 1;
      }
      if (get(a, sortBy.name) > get(b, sortBy.name)) {
        return -1;
      }
      if (get(a, sortBy.name) < get(b, sortBy.name)) {
        return 1;
      }
      return 0;
    }
  }
  return 0;
};

export const sortByDate = (sortBy: Sort | undefined) => (a: any, b: any) => {
  new Date(a?.close_at).getTime()
  if (sortBy) {
    const date1 = new Date(get(a, sortBy.name)).getTime();
    const date2 = new Date(get(b, sortBy.name)).getTime();

    if (sortBy.direction === Direction.Up) {

      if (date1 === null) {
        return 1;
      }
      if (date2 === null) {
        return -1;
      }
      if (date1 > date2) {
        return 1;
      }
      if (date1 < date2) {
        return -1;
      }
      return 0;
    }
    if (sortBy.direction === Direction.Down) {
      if (date1 === null) {
        return -1;
      }
      if (date2 === null) {
        return 1;
      }
      if (date1 > date2) {
        return -1;
      }
      if (date1 < date2) {
        return 1;
      }
      return 0;
    }
  }
  return 0;
}