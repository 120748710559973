import React, { useContext } from 'react';
import ScoringHeader from './ScoringHeader';
import { ScoreContext } from '../score-context';
import {
  SubmissionStatus,
} from '../../../generated/graphql';
import Questionnaire from './Questionnaire';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';

interface Props {
  taskType: string;
}

const ScoringCard = ({ taskType }: Props) => {
  const { submission, selectiveScoreSummaryOptions, handleScoreUpdate, updatingStatus, handleGoalStatus } = useContext(ScoreContext);
  return (
    <StyledFlexColBoxContainer sx={{ position: 'relative' }}>
      <ScoringHeader
        title={`${taskType} Goals`}
        showInfo
        updateAction={updatingStatus}
      />
      <Questionnaire
        goalType={taskType}
        radioOptions={selectiveScoreSummaryOptions}
        goals={submission?.getGoalsForSubmission}
        handleScoreUpdate={handleScoreUpdate}
        handleGoalStatus={handleGoalStatus}
        disableSelection={submission?.status !== SubmissionStatus.Submitted}
      />
    </StyledFlexColBoxContainer>
  );
};

export default ScoringCard;
