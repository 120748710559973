import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import {
  BenchmarkGradedBy,
  OrderBy,
  SubmissionStatus,
  TeacherBenchmarkSubmissionListSortType,
  TeacherSubmissionListInput,
  useAddTypeformUserMappingMutation,
  useGetCsvSurveyTypeformQuery,
  useTeacherBenchmarkGradesListQuery,
  useTeacherBenchmarkGradingQueueQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { BM_VISITS_FOR_SURVEY, PAGE_SIZE } from '../../../variables/constant';
import useBenchmarksQueueHook from '../../ScoreTaskBenchmark/score-benchmark-queue-hook';
import { useAuthProvider } from '../../../core/authContext';

const useTeacherBenchmarksSubmissionList = ( ) => {
  const pagination = usePagination();
  const { getUser } = useAuthProvider();
  const user = getUser();
  const history = useHistory();
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, TeacherBenchmarkSubmissionListSortType.SubmittedAt));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [selectedBenchmarkTypes, setSelectedBenchmarkTypes] = useState<[]>([]);
  const initialSubmissionStatus = [SubmissionStatus.Submitted];
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(SubmissionStatus.Submitted);
  const [selectedGradedBy, setSelectedGradedBy] = useState<string | null>(BenchmarkGradedBy.Teacher);
  const [isDownloadStarted, setIsDownloadStarted] = useState<boolean>(false);
  const initialInputVariables = {
    grade_statuses: initialSubmissionStatus,
    limit: PAGE_SIZE,
    page: pagination.page,
    sort: TeacherBenchmarkSubmissionListSortType.SubmittedAt,
    order_by: order as OrderBy,
    graded_by: [BenchmarkGradedBy.Teacher],
  };
  const [queryVariables, setQueryVariables] = useState<TeacherSubmissionListInput>({
    ...initialInputVariables,
  });
  const { initializeSubmissionQueue } = useBenchmarksQueueHook({});

  const createQueue = (submissionQueueIDs: string[], submissionID: string) => {
    initializeSubmissionQueue(submissionQueueIDs, submissionID);
  };

  const { data: csvSurvey, loading: csvSurveyLoading  } = useGetCsvSurveyTypeformQuery({
    fetchPolicy: 'network-only',
  });


  const { data, loading } = useTeacherBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });

  const getBMSurveyKey = () => {
    return `bm-gradedCount-survey-${user?.id}`;
  };


  const { data: submissionQueueData, loading: submissionQueueDataLoading } = useTeacherBenchmarkGradingQueueQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...queryVariables,
      limit: undefined,
      page: undefined,
    },
  });

  const [AddTypeformUserMapping, { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();

  const benchmarkSubmissionList = data?.teacherBenchmarkSubmissionList?.nodes ?? [];
  const totalCount = data?.teacherBenchmarkSubmissionList?.node_count ?? 0;

  const onSortChange = (sortBy: TeacherBenchmarkSubmissionListSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
    setQueryVariables({
      ...queryVariables,
      sort: sortBy,
      order_by: orderBy,
    });
  };

  const handleSort = (column: TeacherBenchmarkSubmissionListSortType) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const handleRedirection = (submissionQueueIDs: string[], submissionID: string) => async () => {
    createQueue(submissionQueueIDs, submissionID);
    if (submissionID) history.push(`/tasks/${submissionID}/benchmark/score`);
  };

  const handleApplyFilters = () => {
    pagination.setPage(1);
    setQueryVariables({
      limit: PAGE_SIZE,
      page: 1,
      sort: sort as TeacherBenchmarkSubmissionListSortType,
      order_by: order as OrderBy,
      ...(selectedGradedBy !== 'all' ? { graded_by: [selectedGradedBy as BenchmarkGradedBy] } : { graded_by: [BenchmarkGradedBy.Teacher, BenchmarkGradedBy.Flashlight] }),
      ...(selectedBenchmarkTypes.length ? { benchmark_types: selectedBenchmarkTypes } : {}),
      ...(selectedStatus !== 'all' ? { grade_statuses: [selectedStatus as SubmissionStatus] } : { grade_statuses: [SubmissionStatus.Submitted, SubmissionStatus.Reviewed] }),
    });
  };

  const handleClearFilters = async () => {
    pagination.setPage(1);
    setSelectedBenchmarkTypes([]);
    setSelectedStatus(SubmissionStatus.Submitted);
    setSelectedGradedBy(BenchmarkGradedBy.Teacher as BenchmarkGradedBy);
    setQueryVariables({ ...initialInputVariables, benchmark_types: undefined });
  };

  const handlePagination = (value: React.SetStateAction<number>) => {
    pagination.setPage(value as number);
    setQueryVariables({
      ...queryVariables,
      page: value as number,
    });
  };

  const onTypeformReady = async () => {
    try {
      await AddTypeformUserMapping({
        variables: {
          typeformId: csvSurvey?.getCSVSurveyTypeform.id ?? '',
        },
      });
      setIsDownloadStarted(false);
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  const onTypeformSubmit = async () => {
    try {
      await AddTypeformUserMapping({
        variables: {
          typeformId: csvSurvey?.getCSVSurveyTypeform.id ?? '',
          submitted: true,
        },
      });
      setIsDownloadStarted(false);
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  const getSurveyStatus = ()=> {
    const value = localStorage.getItem(getBMSurveyKey());
    return value ? JSON.parse(value).length >= BM_VISITS_FOR_SURVEY : false;
  };

  return {
    loading: loading || submissionQueueDataLoading,
    pagination,
    sort,
    order: order as OrderBy,
    onSortChange,
    handleSort,
    handleRedirection, handlePagination,
    selectedBenchmarkTypes, setSelectedBenchmarkTypes,
    selectedStatus, setSelectedStatus,
    totalCount,
    benchmarkSubmissionList,
    handleApplyFilters,
    handleClearFilters,
    submissionQueueData: submissionQueueData?.teacherBenchmarkGradingQueue ?? [],
    createQueue,
    selectedGradedBy,
    setSelectedGradedBy,
    typeformData: csvSurvey?.getCSVSurveyTypeform ?? null,
    csvSurveyLoading,
    onTypeformReady,
    updateUserLoading,
    onTypeformSubmit,
    getSurveyStatus,
    userId: user?.id ?? '',
    openDownloadModal,
    setOpenDownloadModal,
    isDownloadStarted,
    setIsDownloadStarted,
  };
};

export default useTeacherBenchmarksSubmissionList;
