import {
  useTeacherBenchmarksDashboardContext,
} from '../../../../TeacherBenchmarksDashboard/teacherbenchmarks-dashboard-hook';
import { useEffect, useState } from 'react';
import {
  AdminBenchmarkIndicatorScoresResult,
  AdminBenchmarkInput,
  BenchmarkType,
  useAdminBenchmarkIndicatorScoresQuery,
} from '../../../../../generated/graphql';
import { openSnackbar } from '../../../../../components/Notifier';
import getErrorMessage from '../../../../../utils/getErrorMessage';
import { NotifierType } from '../../../../../variables/types';
import { DashboardWidgetsDataStatusProps } from '../../../../AdminAnalytics/admin-analytics-hook';
import { AdminBenchmarkReportWidgets, RubricTitle } from '../../../../../variables/constant';
import useUserInfo from '../../../../../utils/useUserInfo';
import useRole from '../../../../../hooks/useRole';

type AdminBenchmarkBarChartData = {
  rubricTitle?: string;
  rubricIcon?: string;
  rubricBoyScoreAverage?: number;
  rubricMoyScoreAverage?: number;
  rubricEoyScoreAverage?: number;
  rubricBoyScoreColor?: number;
  rubricMoyScoreColor?: number;
  rubricEoyScoreColor?: number;
  barBoy?: string;
  barMoy?: string;
  barEoy?: string;
  ytd?: string;
};

const useIndicatorScore = (districtId: string, schoolId: string) => {
  const {
    selectedGrades,
    selectedSchools,
    selectedTeachers,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
  } = useTeacherBenchmarksDashboardContext();
  
  const { isSchoolAdmin } = useRole();
  
  const [ hasData, setHasData ] = useState<boolean>(false);
  const [indicatorScoreData, setIndicatorScoreData] = useState<Array<AdminBenchmarkBarChartData>>([]);
  
  const [isLoadingUserInfo, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const scoreSchemeType = user?.score_scheme?.title_key;
  const legendData = scoreScheme?.map((item: any) => {
    return { skillTitle: item?.title, color: item?.color_code };
  });

  // if schoolAdmin then send schoolId else selected schools
  const schoolFilter = isSchoolAdmin ? [schoolId] : selectedSchools;

  const queryInput: AdminBenchmarkInput = {
    district_id: districtId,
    school_ids: schoolFilter || [],
    grade_ids: selectedGrades || [],
    teacher_ids: selectedTeachers || [],
  };
  
  const {
    data: indicatorScore,
    loading: indicatorScoreLoading,
    error: indicatorScoreErr,
  } = useAdminBenchmarkIndicatorScoresQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: queryInput,
    },
    skip: skipGraphQLRequest(),
  });

  useEffect(() => {
    if (indicatorScoreErr){
      openSnackbar({ message: getErrorMessage(indicatorScoreErr) },
        NotifierType.Error);
    }
    
    const barChartData: AdminBenchmarkBarChartData[] = [];
    if (!indicatorScoreLoading) {
      indicatorScore?.adminBenchmarkIndicatorScores?.forEach((benchmarkIndicatorScore: AdminBenchmarkIndicatorScoresResult) => {
        benchmarkIndicatorScore?.rubric_line_items?.forEach((item: any) => {
          let barData: AdminBenchmarkBarChartData = {
            rubricTitle: item?.rubric_title === RubricTitle.DescriptionAndExplanation.backend ?
              RubricTitle.DescriptionAndExplanation.frontend : item?.rubric_title,
            rubricIcon: item?.rubric_icon_base_64,
            ytd: item?.ytd_formatted,
          };

          item?.rubric_scores_for_benchmark_type?.forEach((score: any) => {
            switch (score?.benchmark_type) {
              case BenchmarkType.Boy:
                barData.rubricBoyScoreAverage = score?.rubric_score_average;
                barData.rubricBoyScoreColor = score?.rubric_score_color;
                barData.barBoy = `${BenchmarkType.Boy.toUpperCase()}-${score?.rubric_score_average}`;
                break;
              case BenchmarkType.Moy:
                barData.rubricMoyScoreAverage = score?.rubric_score_average;
                barData.rubricMoyScoreColor = score?.rubric_score_color;
                barData.barMoy = `${BenchmarkType.Moy.toUpperCase()}-${score?.rubric_score_average}`;
                break;
              case BenchmarkType.Eoy:
                barData.rubricEoyScoreAverage = score?.rubric_score_average;
                barData.rubricEoyScoreColor = score?.rubric_score_color;
                barData.barEoy = `${BenchmarkType.Eoy.toUpperCase()}-${score?.rubric_score_average}`;
                break;
              default:
                break;
            }
          });
          barChartData.push(barData);
        });
      });
    }
    
    setIndicatorScoreData(barChartData);
  }, [indicatorScore, indicatorScoreLoading, indicatorScoreErr]);

  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkIndicatorScores.name,
      loading: indicatorScoreLoading,
      dataMissing: !(indicatorScoreData?.length > 0),
    };
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    setHasData(indicatorScoreData?.length > 0 );
  }, [indicatorScoreData, indicatorScoreLoading]);
  
  return {
    hasData,
    indicatorScoreLoading,
    barChartData: indicatorScoreData.length === 0 ? undefined : indicatorScoreData,
    isLoadingUserInfo,
    legendData,
    scoreSchemeType,
    isSchoolAdmin,
  };
};

export default useIndicatorScore;