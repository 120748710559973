import React from 'react';
import ScoringHeader from './ScoringHeader';
import { SubmissionStatus } from '../../../generated/graphql';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';
import { StyledTeacherNotesInput } from '../ScoreTask.styled';
import {Typography} from "@mui/material";

interface TeacherFeedbackProps {
  feedback?: string,
  setFeedback: Function,
  submissionStatus: string,
  reviewerUserID?: string,
  firstName?: string,
  lastName?: string,
}

const TeacherNotes = ({
  feedback,
  setFeedback,
  submissionStatus,
  reviewerUserID,
  firstName,
  lastName,
}: TeacherFeedbackProps) => {
  let headerAction;

  if (reviewerUserID) {
    headerAction = (
      <div>
        <div>
          {firstName && lastName && <Typography variant='sLabel'>User Name: {firstName?.concat(' ', lastName)}</Typography>}
        </div>
        <div>
          <Typography variant='sLabel'>UserID: {reviewerUserID}</Typography>
        </div>
      </div>
    )
  }


  return (
    <StyledFlexColBoxContainer>
      <ScoringHeader
        title="Teacher Notes"
        subTitle="Notes are private and will not be visible to the student."
        headerAction={headerAction}
        showInfo
      />
      <StyledTeacherNotesInput
        sx={{ padding: 0 }}
        placeholder="Type Here"
        defaultValue={feedback || ''}
        multiline
        rows={10}
        onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFeedback(event.target.value);
        }}
        disabled={submissionStatus === SubmissionStatus.Reviewed}
      />
    </StyledFlexColBoxContainer>
  );
};

export default TeacherNotes;
