import React, { useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { palette } from '../../theme/palette';
import { ManageStudentsStudentFragmentFragment } from '../../generated/graphql';

interface Props {
  student: ManageStudentsStudentFragmentFragment,
  onChange: (event: React.ChangeEvent<HTMLInputElement>, studentId: string) => void,
  isSelected: (studentId: string) => boolean,
}

const styles = {
  tableCell: {
    paddingLeft: '24px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: palette.fontColors.fontBlack,
  },
};

const StudentRow = ({
  student,
  onChange,
  isSelected,
}: Props) => {
  const isSelectedStudent = useMemo(() => isSelected(student?.id!), [isSelected, student]);

  return (
    <TableRow>
      <TableCell sx={{ ...styles.tableCell, width: '65%' }}>
        <FormControlLabel
          sx={{ ...styles.label }}
          control={(
            <Checkbox
              color="primary"
              checked={isSelectedStudent}
              onChange={(e) => onChange(e, student?.id!)}
            />
          )}
          label={`${student?.user?.first_name ?? ''} ${student?.user?.last_name ?? ''}`}
        />
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, width: '5%' }}>
        <Typography variant="font16">{`${student?.grade}`}</Typography>
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, width: '30%', wordBreak: 'break-word' }}>
        <Typography variant="font16">{`${student?.student_number}`}</Typography>
      </TableCell>
    </TableRow>
  );
};
export default StudentRow;
