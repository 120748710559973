import React from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  SxProps,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  OrderBy,
  GoalsScoreWidgetSortType,
  LearningGoalsScoresByIndicatorLineItem,
  SubmissionType,
} from '../../../../generated/graphql';
import { palette } from '../../../../theme/palette';
import { StyledTableCell2 } from '../../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../../components/Common/Common.styled';
import { LearningGoalsStylesCSS } from '../LearningGoals.styled';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';
import NoResults from '../../../../components/NoResults';
import EmptyResults from '../../../../components/Icons/EmptyResults';
import ListRow from './ListRow';
import { AdminDashboards } from '../../../../variables/constant';

interface Props {
  submissionType: SubmissionType,
  isTableEmpty?: boolean,
  loading?: boolean,
  sort?: GoalsScoreWidgetSortType,
  order?: OrderBy,
  dataRows?: LearningGoalsScoresByIndicatorLineItem[],
  handleSort?: ((column: GoalsScoreWidgetSortType) => void) | Function,
}

const List = ({
  submissionType,
  isTableEmpty,
  loading,
  sort,
  order,
  dataRows,
  handleSort,
}: Props) => {
  const theme = useTheme();
  const styles = LearningGoalsStylesCSS(theme);
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  return (
    <TableContainer sx={{ ...styles.tableContainer, minHeight: '310px' } as SxProps}>
      <Table
        stickyHeader
        aria-label="Goals & Scores by indicator"
        sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell2 sx={{ ...styles.width45, textAlign: 'left' }}>
              <StyledTableSort
                direction={orderByIcon}
                IconComponent={ArrowDownIcon}
                onClick={handleSort?.(GoalsScoreWidgetSortType.RubricTitle)}
                active={sort === GoalsScoreWidgetSortType.RubricTitle}
              >
                Indicators
              </StyledTableSort>
            </StyledTableCell2>
            <StyledTableCell2 sx={{ ...styles.width35, textAlign: 'left' }}>
              <StyledTableSort
                direction={orderByIcon}
                IconComponent={ArrowDownIcon}
                onClick={handleSort?.(GoalsScoreWidgetSortType.GoalsCount)}
                active={sort === GoalsScoreWidgetSortType.GoalsCount}
              >
                {`Number of ${submissionType} Goals`}
              </StyledTableSort>
            </StyledTableCell2>
            <StyledTableCell2 sx={{ ...styles.width25, textAlign: 'left' }}>
              <StyledTableSort
                direction={orderByIcon}
                IconComponent={ArrowDownIcon}
                onClick={handleSort?.(GoalsScoreWidgetSortType.AverageScore)}
                active={sort === GoalsScoreWidgetSortType.AverageScore}
              >
                {`${submissionType} Score`}
              </StyledTableSort>
            </StyledTableCell2>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && !isTableEmpty && dataRows?.map((row, index) => (
            <ListRow rowIndex={index} data={row} key={row?.rubric_title} />
          ))}
          {!loading && isTableEmpty && (
            <TableRow>
              <TableCell
                colSpan={3}
                align="center"
              >
                <NoResults
                  description={AdminDashboards.learningGoals.GoalsByIndicatorWidget.insufficientData.description}
                  svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
                />
              </TableCell>
            </TableRow>
          )}
          {loading && (
            <TableRow>
              <TableCell
                colSpan={6}
                align="center"
              >
                <CircularProgress size={28} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default List;
