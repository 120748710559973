import React from 'react';
import { Box, CardMedia, IconButton, SxProps } from '@mui/material';
import FullScreenIcon from '../Icons/FullScreenIcon';
import { palette  } from '../../theme/palette';

const styles = {
  ptImageSmall: {
    width: '100%',
    height: '100%',
  },
  ptImageFull: {
    width: 1000,
    display: 'unset',
  },
  fullScreenClass: {
    background: palette.customWhite.main,
    opacity: '0.8',
    border: `1.19441px solid ${palette.customBackground.disabled}`,
    borderRadius: '50%',
  },
  fullScreenPosition: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
};

interface Props {
  imageSrc?: string,
  setShowPreview: Function,
  showPreview: boolean,
  fullScreen?: boolean,
  containerStyles?: Object,
  fullscreenIconStyles?: Object,
}

const FullscreenImage = ({
  imageSrc,
  setShowPreview,
  showPreview,
  fullScreen,
  containerStyles,
  fullscreenIconStyles = styles.fullScreenPosition,
}: Props) => {

  const handleFullscreen = () => {
    setShowPreview(!showPreview);
  };

  return (
    <Box sx={{ position: 'relative', alignContent: 'center', ...containerStyles! }}>
      <CardMedia
        component="img"
        image={imageSrc}
        sx={{ ...(fullScreen ? styles.ptImageFull : styles.ptImageSmall) }}
      />
      { !fullScreen && (
        <Box sx={{ ...styles.fullScreenClass, ...fullscreenIconStyles } as SxProps}>
          <IconButton onClick={handleFullscreen}>
            <FullScreenIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default FullscreenImage;
