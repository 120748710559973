/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { svgStyles } from '../Common/Common.styled';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { DistrictFilterFragmentFragment } from '../../generated/graphql';
import useDistrictsFilter from './district-filter-hook';
import DistrictsIcon from '../Icons/DistrictsIcon';
import { palette  } from '../../theme/palette';
import { SxProps, Theme } from '@mui/system';
import { List, ListItem } from '@mui/material';

interface Props {
  error?: boolean;
  className?: string;
  value?: DistrictFilterFragmentFragment | null | string;
  onChange?: (data: DistrictFilterFragmentFragment | null | string, district: DistrictFilterFragmentFragment | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  stateIds?: string | [string] | undefined;
  disabled?: boolean;
  readOnly?: boolean;
  source?: string[]
  sx?: SxProps<Theme> | undefined;
}

type DistrictValue = DistrictFilterFragmentFragment | null | undefined;

const DistrictFilter = ({
  className,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  stateIds,
  disabled,
  source,
  readOnly = false,
  sx,
}: Props) => {
  const {
    districts,
    loading,
  } = useDistrictsFilter({ stateIds, source });
  const onSelectChange = (_: any, district: DistrictFilterFragmentFragment | null) => {
    onChange?.(idSelectOnly ? (district?.id ?? '') : district, district);
  };
  const selectedValue: DistrictValue = idSelectOnly ? districts?.find((item) => item.id === value) ?? null : value as DistrictValue;

  return (
    <Autocomplete
      className={className}
      options={districts || []}
      getOptionLabel={(option: any) => option?.name}
      isOptionEqualToValue={(option: any, selected: any) => option?.name === selected?.name}
      onChange={onSelectChange}
      value={selectedValue}
      sx={sx}
      readOnly={readOnly}
      disabled={disabled || loading}
      renderOption={(props, option: any) => (
        <List
          key={option?.id}
          sx={{ p: '0px' }}>
          <ListItem {...props}>
            {option?.name ?? ''}
          </ListItem>
        </List>
      )}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: { ...(sx ? {  backgroundColor: palette.customWhite.whiteSmoke } : { backgroundColor: 'inherit' }), fontWeight: 800, fontSize: '16px' },
            startAdornment: (
              <InputAdornment position="start">
                <DistrictsIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(DistrictFilter);
