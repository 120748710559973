import { useStatesFilterQuery } from '../../generated/graphql';

const useStatesFilter = () => {
  const { data, loading } = useStatesFilterQuery({
    fetchPolicy: 'network-only',
  });

  return {
    states: data?.states ?? [],
    loading,
  };
};

export default useStatesFilter;
