/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  svg?: any;
  path?: any;
}

const SpeakerIcon = (props: Props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
    {...props?.svg}
  >
    <path
      d="M10.0795 3.33027L5.90906 7.4998H1.125C0.503438 7.4998 0 8.00324 0 8.6248V15.3748C0 15.9959 0.503438 16.4998 1.125 16.4998H5.90906L10.0795 20.6693C10.7841 21.3739 12 20.8789 12 19.8739V4.12574C12 3.11981 10.7831 2.62668 10.0795 3.33027ZM22.5 11.9998C22.5 9.02184 20.9972 6.28387 18.4795 4.67606C17.955 4.34137 17.2594 4.49699 16.927 5.02574C16.5947 5.55449 16.7498 6.25434 17.2744 6.58949C19.1377 7.77965 20.25 9.80184 20.25 11.9998C20.25 14.1978 19.1377 16.22 17.2744 17.4101C16.7498 17.7448 16.5947 18.4446 16.927 18.9739C17.2322 19.4595 17.917 19.6836 18.4795 19.3236C20.9972 17.7157 22.5 14.9778 22.5 11.9998ZM15.8545 8.39652C15.3117 8.09981 14.6269 8.29527 14.3259 8.83949C14.0264 9.38371 14.2247 10.0676 14.7689 10.3681C15.3741 10.7004 15.75 11.3262 15.75 11.9998C15.75 12.6739 15.3741 13.2992 14.7694 13.6315C14.2252 13.932 14.0269 14.6159 14.3264 15.1601C14.6278 15.7067 15.3131 15.9007 15.855 15.6031C17.1783 14.8742 18.0005 13.4937 18.0005 11.9993C18.0005 10.505 17.1783 9.12496 15.8545 8.39652Z"
      {...props?.path}
    />
  </SvgIcon>
);

export default SpeakerIcon;
