import { palette  } from '../palette';

const Tooltip = () => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 16,
          fontWeight: 600,
          borderRadius: '12px',
          border: `2px solid ${palette.customBackground.disabled}`,
          padding: '10px 30px',
          lineHeight: '22px',
          letterSpacing: '0.1px',
          color: palette.customBlack.lightBlack,
          backgroundColor: palette.customWhite.main,
        },
        arrow: {
          color: palette.customWhite.main,
          '&::before': {
            backgroundColor: palette.customWhite.main,
            border: `2px solid ${palette.customBackground.disabled}`,
          },
        },
      },
    },
  };
};

export default Tooltip;
