/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { SubmissionFilterFragment, SubmissionStatus } from '../../generated/graphql';
import useSubmissionFilter from './submission-filter-hook';

interface Props {
  error?: boolean;
  className?: string;
  value?: SubmissionFilterFragment | null | string;
  onChange?: (data: SubmissionFilterFragment | null | string) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  status?: [SubmissionStatus] | undefined;
  disabled?: boolean;
}

type SubmissionValue = SubmissionFilterFragment | null | undefined;

const SubmissionsFilter = ({
  className,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  status,
  disabled,
}: Props) => {
  const {
    submissions,
    loading,
  } = useSubmissionFilter({ status });
  const onSelectChange = (_: any, submission: SubmissionFilterFragment | null) => {
    onChange?.(idSelectOnly ? (submission?.submission_id ?? '') : submission);
  };

  const selectedValue: SubmissionValue = idSelectOnly ? submissions?.find((item) => item.submission_id === value) ?? null : value as SubmissionValue;
  return (
    <Autocomplete
      className={className}
      options={submissions || []}
      getOptionLabel={(option: any) => `${option?.title ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.submission_id === selected?.submission_id}
      onChange={onSelectChange}
      value={selectedValue}
      disabled={disabled || loading}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default SubmissionsFilter;
