import React, { useEffect } from 'react';
import {
  GoalStatus,
  LearningGoalSlideOutSortType,
  LearningGoalWidgetSlideOutInput,
  LearningGoalWidgetSlideOutResult,
  OrderBy,
  useLearningGoalsPerformanceWidgetSlideoutQuery,
} from '../../../../../generated/graphql';
import { ModalTypes } from '../../../../../variables/types';
import { PAGE_SIZE } from '../../../../../variables/constant';
import usePagination from '../../../../../utils/usePagination';
import { useDashboardContext } from '../../../admin-analytics-hook';

export interface Props {
  skill: any
  skillLevel: string
  open?: boolean,
  onClose?: any,
  variant?: ModalTypes,
  queryInput?: LearningGoalWidgetSlideOutInput,
  setQueryInput?: Function,
}

interface QueryReqInputProps {
  input: LearningGoalWidgetSlideOutInput,
}

const useFlyoverInfo = ({
  onClose,
  skill, skillLevel,
  queryInput, setQueryInput,
}: Props) => {

  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    analyticsFilters: queryFilters, loading, setLoading,
  } = useDashboardContext();

  const pagination = usePagination('LGPerformanceSliderPage');

  const handleModalOnClose = (
    _: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason && ['backdropClick', 'escapeKeyDown'].includes(reason)) return;
    pagination.unsetPage();
    onClose();
  };

  var queryReqInput: QueryReqInputProps = {
    'input': queryInput ? queryInput : {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort: LearningGoalSlideOutSortType.LearningGoalSlideOutSortByStudentName,
      order_by: OrderBy.Asc,
      filters: queryFilters,
      goal_status: GoalStatus.NoAttempt,
    },
  };
  const updateQueryInput = (queryInputUpdate: any) => {
    queryReqInput.input = {
      ...queryReqInput.input,
      ...queryInputUpdate,
    };
  };
  if (isSchoolAdmin && mySchoolData?.id) updateQueryInput({ school_id: mySchoolData?.id });
  if (isDistrictAdmin && myDistrictData) updateQueryInput({ district_id: myDistrictData?.id });
  if (skill && skillLevel) updateQueryInput({ goal_status: skill?.[`${skillLevel}_description`] });

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    updateQueryInput({
      page: value as number,
    });
  };

  const onSortChange = (sortBy: LearningGoalSlideOutSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    updateQueryInput({
      page: 1,
      sort: sortBy,
      order_by: orderBy,
    });
  };

  const handleSort = (column: LearningGoalSlideOutSortType) => () => {
    if (column === queryReqInput.input.sort) {
      onSortChange(column, queryReqInput.input.order_by === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const skipGraphQLRequest = () => (
    (isSchoolAdmin || isDistrictAdmin)
    && (isSchoolAdmin && !mySchoolData?.id)
    && (isDistrictAdmin && !myDistrictData?.id)
    && !!queryReqInput.input.goal_status
  );

  const { data: performanceWidgetSlideoutResp, loading: performanceWidgetSlideoutDataLoading } = useLearningGoalsPerformanceWidgetSlideoutQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryReqInput },
  });

  const performanceWidgetSlideoutData: LearningGoalWidgetSlideOutResult = performanceWidgetSlideoutResp?.learningGoalWidgetSlideOut!;
  const performanceWidgetSlideoutDataCount = performanceWidgetSlideoutData?.total_count ?? 0;
  const isTableEmpty = !performanceWidgetSlideoutDataLoading && performanceWidgetSlideoutDataCount === 0;

  useEffect(() => {
    setLoading?.(loading && performanceWidgetSlideoutDataLoading);
  }, [performanceWidgetSlideoutDataLoading]);

  useEffect(() => setQueryInput?.(queryReqInput.input), [queryReqInput]);

  return ({
    handleModalOnClose,
    queryReqInput, pagination, handlePageChange, handleSort,
    performanceWidgetSlideoutDataLoading, performanceWidgetSlideoutData,
    performanceWidgetSlideoutDataCount, isTableEmpty,
  });
};

export default useFlyoverInfo;
