import React from 'react';
import {
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LearningGoalsStylesCSS } from '../LearningGoals.styled';
import {
  LearningGoalsScoresByIndicatorLineItem,
} from '../../../../generated/graphql';
import { getSkillTypeIcon } from '../../../../components/AdminGraphs/CommonFunctions';

interface Props {
  rowIndex?: number,
  data?: LearningGoalsScoresByIndicatorLineItem,
}

const ListRow = ({
  rowIndex,
  data,
}: Props) => {
  const theme = useTheme();
  const styles = LearningGoalsStylesCSS(theme);
  return (
    <TableRow key={rowIndex} sx={{
      ...styles.tableRowHover, ...styles.tableRowBorder,
    }}>
      <TableCell sx={{ ...styles.width45, pl: 2 }}>
        <Typography
          variant='secondaryLabel'
        >
          {data?.rubric_title && getSkillTypeIcon(data?.rubric_title)}
          &nbsp;&nbsp;&nbsp;
          {data?.rubric_title}
        </Typography>
      </TableCell>
      <TableCell sx={{ ...styles.width35, textAlign: 'left', pl: 3 }}>
        <Typography
          variant='secondaryLabel'
        >
          {data?.goals_count ?? 0}
        </Typography>
      </TableCell>
      <TableCell sx={{ ...styles.width25, textAlign: 'left', pl: 3 }}>
        <Typography
          variant='secondaryLabel'
        >
          {data?.average_score ?? 0}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ListRow;
