const Link = () => {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: 17,
          lineHeight: 1.2,
        },
      },
    },
  };
};

export default Link;
