import React from 'react';
import  Pagination  from '@mui/material/Pagination';
import { Box } from '@mui/material';

interface Props {
  page: number,
  count: number,
  defaultPage: number,
  onChange: (event: any, page: React.SetStateAction<number>) => void,
  disabled?: boolean,
}

const TablePagination = ({ page, onChange, count, defaultPage, disabled }:Props) => (
  <Box>
    <Pagination
      count={count}
      page={page}
      onChange={onChange}
      defaultPage={defaultPage}
      disabled={disabled}
    />
  </Box>
);
export default React.memo(TablePagination);
