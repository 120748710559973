import React from 'react';
import {
  Box,
  RadioGroup as MUIRadioGroup,
  FormControlLabel,
  Radio, Button, Typography, styled,
} from '@mui/material';
import BarStackGraphHorizontal from '../Charts/Graph/BarStackGraphHorizontal';
import { palette  } from '../../theme/palette';

export interface SelectiveButtonGroupProps {
  id: string,
  label: string,
  value: string | number | null,
  disabled?: boolean,
  score: number
}

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  radioContainerStyles?: string;
  row?: boolean,
  radioGroupAriaLabel?: string,
  radioGroupName?: string,
  radios?: SelectiveButtonGroupProps[],
  value: string | number | null,
  disableSelection?: boolean,
}

const customStyles = {
  custom: {
    border: `2px solid ${palette.customGrey.adminButtonBorder}`,
    height: 35,
    width: 142,
    position: 'relative',
    '&.MuiButtonBase-root.MuiButton-root': {
      borderRadius: '8px',
    },
    '&:not(:first-of-type)': {
      marginTop: 1,
    },
    '& .MuiButton-startIcon': {
      position: 'absolute',
      left: 5,
    },
    '&:hover': {
      backgroundColor: palette.customGrey.opaquePrimary,
      border: `2px solid ${palette.customBlue.primaryBlue}`,
      '& .recharts-wrapper': {
        stroke: `${palette.customGrey.opaquePrimary} !important`,
      },
    },
  },
  customColor: {
    backgroundColor: palette.customGrey.opaquePrimary,
    border: `2px solid ${palette.customBlue.primaryBlue}`,
    '& .recharts-wrapper': {
      stroke: `${palette.customGrey.opaquePrimary} !important`,
    },
    '&:hover': {
      backgroundColor: palette.customGrey.opaquePrimary,
    },
  },
  buttonText: {
    color: palette.fontColors.fontBlack,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19px',
    textTransform: 'none',
    position: 'absolute',
    left: 40,
  },
};

const StyledFormLabel  = styled(FormControlLabel)(() => ({
  '&.MuiFormControlLabel-root':{
    marginRight: '0px',
    width: '142px',
  },
  '& .MuiFormControlLabel-label':{
    display: 'none',
  },
}));

const StyledRadio  = styled(Radio)(() => ({
  '& .MuiSvgIcon-root': {
    fill: 'none',
    opacity: 0.011,
  },
  '&.MuiRadio-root': {
    padding: '5px 40px 16px 90px',
  },
}));

const SelectiveButtons = ({
  handleChange,
  radios,
  radioContainerStyles,
  row,
  radioGroupAriaLabel,
  radioGroupName,
  value,
  disableSelection,
}: Props) => {
  return (
    <Box className={radioContainerStyles}>
      <MUIRadioGroup
        row={row}
        onChange={handleChange}
        aria-label={radioGroupAriaLabel}
        name={radioGroupName}
        value={value}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {
          radios?.map((radio) => (
            <Button
              name={radio?.label}
              disabled={radio.disabled || disableSelection}
              sx={{
                ...customStyles.custom,
                ...(radio?.value === value ? customStyles.customColor : undefined),
              }}
              startIcon={(
                <BarStackGraphHorizontal
                  score={radio.score}
                  height={30}
                  width={35}
                  barSize={35}
                  strokesWidth={2}
                />
              )}
            >
              <StyledFormLabel
                key={radio.id}
                value={radio.value}
                control={<StyledRadio color="primary"  />}
                label={radio.label}
                disabled={radio.disabled || disableSelection}
              />
              <Typography sx={{ ...customStyles.buttonText }}>{radio.label}</Typography>
            </Button>
          ))
        }
      </MUIRadioGroup>
    </Box>
  );
};

export default React.memo(SelectiveButtons);
