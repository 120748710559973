import { useState, useRef } from 'react';
import noop from '../../utils/noop';
import {
  Noop,
  NotifierProps,
  NotifierType,
  OpenSnackbarFunction,
} from '../../variables/types';

export interface State extends NotifierProps {
  open: boolean;
  variant: NotifierType;
}

const useNotifier = () => {
  const openSnackbar = useRef<OpenSnackbarFunction | Noop>(noop);
  const [state, setState] = useState<State>({
    open: false,
    message: '',
    messageTitle: '',
    variant: NotifierType.Info,
    customIcon: null,
    actionButtonText: '',
    onActionButtonClick: noop,
  });
  const [showMessage, setShowMessage] = useState('');

  openSnackbar.current = (
    {
      message,
      customIcon,
      actionButtonText,
      messageTitle,
      onActionButtonClick,
    }: NotifierProps,
    variant: NotifierType,
  ) => {
    // @ts-ignore
    setShowMessage(typeof (message) === 'string' ? message : message.shift());
    setState((prevState) => ({
      ...prevState,
      open: true,
      message,
      variant,
      customIcon,
      messageTitle,
      actionButtonText,
      onActionButtonClick,
    }));
  };

  const handleSnackbarClose = () => {
    let updatedMessages = state.message;
    if (typeof (updatedMessages) === 'object' && updatedMessages.length > 0) {
      setShowMessage(updatedMessages.shift()!);
    } else {
      setState((prevState) => ({
        ...prevState,
        open: false,
        message: '',
        variant: NotifierType.Info,
        customIcon: null,
        messageTitle: '',
        actionButtonText: '',
        onActionButtonClick: noop,
      }));
    }
  };

  return {
    ...state,
    handleSnackbarClose,
    openSnackbar,
    showMessage,
  };
};

export default useNotifier;
