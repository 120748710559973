import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  SxProps,
} from '@mui/material';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { SchoolSort, PaginationProps } from '../../../variables/types';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { SchoolDetails, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import SchoolRow from './SchoolRow';
import { StyledTableCell, StyledTableDataCell } from '../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../components/Common/Common.styled';
import CustomPagination from '../../../components/CustomPagination';
interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: SchoolSort,
  order: OrderBy,
  schools: SchoolDetails[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: SchoolSort) => void) | Function,
  rowsPerPage: number,
  setRowsPerPage: Function,
  marginTop?: number,
  sxProps?: SxProps,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  schools,
  handleSort,
  rowsPerPage,
  setRowsPerPage,
  marginTop = 2, sxProps,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const sortColumn = (title:string, sortEnum: SchoolSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );
  return (
    <Box
      display="grid"
      width="100%"
      mt={marginTop}
    >
      <Box>
        <StyledContainerRoot sx={ sxProps ?? {}}>
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ textAlign: 'left' }}>
                  { sortColumn('School', SchoolSort.Name) }
                </StyledTableCell>
                <StyledTableDataCell>
                  { sortColumn('School ID', SchoolSort.SchoolId) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  Grades
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('District', SchoolSort.District) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('Students', SchoolSort.StudentCount) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('Source', SchoolSort.Source) }
                </StyledTableDataCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && schools?.map((school) => (
                <SchoolRow school={school} key={school?.id} />
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="No Schools"
                      description="There has yet to be a school created."
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      <CustomPagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onChange={handlePageChange}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};
export default List;
