/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SpeakIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    {...props}
  >
    <g id="icon / nav open">
      <path d="M10.0795 0.329969L5.90906 4.4995H1.125C0.503438 4.4995 0 5.00294 0 5.6245V12.3745C0 12.9956 0.503438 13.4995 1.125 13.4995H5.90906L10.0795 17.669C10.7841 18.3736 12 17.8786 12 16.8736V1.12544C12 0.1195 10.7831 -0.373625 10.0795 0.329969ZM22.5 8.9995C22.5 6.02153 20.9972 3.28356 18.4795 1.67575C17.955 1.34106 17.2594 1.49669 16.927 2.02544C16.5947 2.55419 16.7498 3.25403 17.2744 3.58919C19.1377 4.77934 20.25 6.80153 20.25 8.9995C20.25 11.1975 19.1377 13.2197 17.2744 14.4098C16.7498 14.7445 16.5947 15.4443 16.927 15.9736C17.2322 16.4592 17.917 16.6833 18.4795 16.3232C20.9972 14.7154 22.5 11.9775 22.5 8.9995ZM15.8545 5.39622C15.3117 5.0995 14.6269 5.29497 14.3259 5.83919C14.0264 6.38341 14.2247 7.06731 14.7689 7.36778C15.3741 7.70013 15.75 8.32591 15.75 8.9995C15.75 9.67356 15.3741 10.2989 14.7694 10.6312C14.2252 10.9317 14.0269 11.6156 14.3264 12.1598C14.6278 12.7064 15.3131 12.9004 15.855 12.6028C17.1783 11.8739 18.0005 10.4934 18.0005 8.99903C18.0005 7.50466 17.1783 6.12466 15.8545 5.39622Z" fill={props.pathFill} />
    </g>
  </SvgIcon>
);

export default SpeakIcon;
