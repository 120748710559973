import React, { useState } from 'react';
import { Box, Button, IconButton, Modal, SxProps } from '@mui/material';
import UploadFileIcon from '../Icons/UploadFileIcon';
import DeleteIcon from '../Icons/DeleteIcon';
import FullScreenIcon from '../Icons/FullScreenIcon';
import WarningWindow from '../WarningWindow';
import { StyledBrowse, StyledInfo, StyledSpan } from './UploadFile.styled';
import { palette } from '../../theme/palette';

interface FileProps {
  thumbnail?: boolean,
  resolution?: string,
  dropzoneRefKey?: string,
  files: HTMLInputElement[],
  setFiles: Function,
  getRootProps: Function,
  getInputProps: Function,
  deleteImage: Function,
  showWarning?: String,
  setShowWarning: Function,
  header: String,
  subheader: String,
  primaryTitle: string,
  secondaryTitle: string,
  handleOpen: () => void,
  handleClose: () => void,
  open: boolean,
  setOpen?: Function,
  label?: string,
}

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const uploadStyles = {
  dragContainer: {
    border: `2px dotted ${palette.customGrey.dragBorder}`,
    background: palette.customBackground.dragBackground,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '12px',
    width: 400,
    height: 180,
  },
  mainContainer: {
    position: 'relative',
  },
  preview: {
    marginBottom: 8,
    marginRight: 8,
    width: 400,
    height: 180,
    position: 'relative',
  },
  wrapperButton: {
    background: palette.customBackground.disabled,
    borderRadius: '50%',
    width: 50,
    height: 50,
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  deleteButton: {
    color: palette.customBackground.disabled,
    padding: 0,
    minWidth: 0,
    borderRadius: '50%',
    border: 'none',
  },
  fullScreenButton: {
    top: 'unset',
    bottom: '10px',
  },
  previewContainer: {
    display: 'flex',
    border: `2px solid ${palette.customGrey.dragBorder}`,
    borderRadius: '12px',
    width: 440,
    height: 220,
    overflow: 'hidden',
    padding: '18px 15px 15px 18px',
  },
  img: {
    width: 400,
    height: 180,
    display: 'block',
    objectFit: 'cover',
  },
  fullScreenIcon: {
    height: 22,
    width: 22,
  },
  paper: {
    position: 'absolute',
    width: '1000px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullScreenImage: {
    width: '100%',
    height: 'auto',
  },
};

const ImageUpload = ({
  thumbnail = false,
  resolution = '1920*1080',
  dropzoneRefKey = 'ref',
  files,
  getRootProps,
  getInputProps,
  deleteImage,
  setShowWarning,
  showWarning,
  header,
  subheader,
  primaryTitle,
  secondaryTitle,
  handleClose,
  handleOpen,
  open,
  label,
}: FileProps) => {
  const [modalStyle] = useState(getModalStyle);
  const preview = files?.map((file: any) => (
    <Box sx={{ ...uploadStyles.preview }} key={file?.name}>
      <Box
        component="img"
        src={file?.preview}
        sx={{ ...uploadStyles.img }}
      />
      <Box sx={{ ...uploadStyles.wrapperButton } as SxProps}>
        <Button
          sx={{ ...uploadStyles.deleteButton }}
          color="primary"
          onClick={() => setShowWarning(file)}
        >
          <DeleteIcon strokeColor={palette.customBlue.darkIcon} strokeWidth={2} />
        </Button>
      </Box>
      <Box sx={{ ...uploadStyles.wrapperButton, ...uploadStyles.fullScreenButton } as SxProps}>
        <IconButton
          aria-label="Image Preview"
          onClick={handleOpen}
          disableTouchRipple
          disableRipple
        >
          <FullScreenIcon
            pathFill={palette.customBlack.fontBlack}
            sx={{ ...uploadStyles.fullScreenIcon }}
          />
        </IconButton>
      </Box>
    </Box>
  ));

  const fullScreenPreview = (
    <Box style={modalStyle} sx={{ ...uploadStyles.paper }}>
      {files?.map((file: any) => (
        <Box
          component="img"
          src={file?.preview}
          sx={{ ...uploadStyles.fullScreenImage }}
          key={file?.url}
        />
      ))}
    </Box>
  );

  return (
    <Box sx={{ ...uploadStyles.mainContainer }}>
      <Box
        {...getRootProps({ className: 'dropzone', refkey: dropzoneRefKey })}
        title="New Image"
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent="center"
      >
        {files.length < 1 && (
          <Box sx={{ ...uploadStyles.dragContainer as SxProps }}>
            <UploadFileIcon fillColor={palette.customGrey.iconGrade} />
            <input {...getInputProps()} id={label} />
            <Box display="flex" justifyContent="center">
              <StyledBrowse>
                Drag and drop a file or
                {' '}
                <StyledSpan>browse your file</StyledSpan>
              </StyledBrowse>
              {!thumbnail && (<StyledInfo>(Please upload an image of size {resolution})</StyledInfo>)}
            </Box>
          </Box>
        )}
        {files.length >= 1 && (
          <Box sx={{ ...uploadStyles.previewContainer as SxProps }} >
            {preview}
          </Box>
        )}
      </Box>
      <WarningWindow
        openDialog={showWarning}
        closeDialog={() => setShowWarning('')}
        header={header}
        subheader={subheader}
        actions={[{
          title: primaryTitle,
          event: () => setShowWarning(''),
          classname: 'keepButton',
        }, {
          title: secondaryTitle,
          event: deleteImage,
          classname: 'deleteButton',
        }]}
      />
      <Modal
        open={open}
        onClose={handleClose}
      >
        {fullScreenPreview}
      </Modal>
    </Box>
  );
};

export default ImageUpload;
