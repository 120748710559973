import { useEffect, useState } from 'react';
import {
  BenchmarkType,
  OverallPerformanceLevelInput,
  OverallPerformanceLevelScoreLineItem,
  useOverallPerformanceLevelQuery,
} from '../../../../generated/graphql';

import { useTeacherBenchmarksDashboardContext } from '../../teacherbenchmarks-dashboard-hook';
import { TeacherBenchmarkDashBoard } from '../../../../variables/constant';
import { DashboardWidgetsDataStatusProps } from '../../../AdminAnalytics/admin-analytics-hook';
import useUserInfo from '../../../../utils/useUserInfo';
import { trackEvent } from '../../../../utils/trackevent';
import { openSnackbar } from '../../../../components/Notifier';
import { NotifierType } from '../../../../variables/types';
import getErrorMessage from '../../../../utils/getErrorMessage';


type OverallPerformanceProps = {
  selectedBenchmarkType?: BenchmarkType;
};

export type pieChartData = {
  studentCount: number;
  color: string;
  skillTitle: string;
  scoreLevelId?: string;
  scoreLevelTitle?: string;
  scoreLevelAbbr?: string;
};
const useOverallPerformanceLevel = ({ selectedBenchmarkType }: OverallPerformanceProps) => {
  const {
    selectedGrades, selectedClasses, selectedSchools, skipGraphQLRequest, updateDashboardWidgetsDataStatus, legendDataOverallPerformanceWidget,
    setLegendDataOverallPerformanceWidget,
  } = useTeacherBenchmarksDashboardContext();
  const [speakingAvgScore, setSpeakingAvgScore] = useState<number>(0);
  const [writingAvgScore, setWritingAvgScore] = useState<number>(0);
  const [hasData, setHasData] = useState<boolean>(false);
  const [speakingPieChartData, setSpeakingPieChartData] = useState<any>();
  const [writingPieChartData, setWritingPieChartData] = useState<any>();
  const [showFlyover, setShowFlyover] = useState(false);
  const [flyOverTitle, setFlyOverTitle] = useState<string>('');
  const [flyOverData, setFlyOverData] = useState<pieChartData>();
  const [isLoadingUserInfo, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const legendData = scoreScheme?.map((item: any) => {
    return { skillTitle: item?.title, color: item?.color_code };
  });

  const toggleFlyover = () => {

    setShowFlyover(!showFlyover);
  };
  const openFlyover = (data: any, title: string) => {
    setFlyOverTitle(title);
    setFlyOverData(data);
    if (!showFlyover) {
      trackEvent(`${title.toLowerCase()}_pl_${data?.skillTitle ? data?.skillTitle.toLowerCase() : 'all_st'}`, `${title.toLowerCase()}_performace_level_${data?.skillTitle ? data?.skillTitle.toLowerCase() : 'all_students'}_slide_out`);
    }
    setShowFlyover(!showFlyover);
  };
  const queryInput: OverallPerformanceLevelInput = {
    benchmark_type: selectedBenchmarkType!,
    section_ids: selectedClasses ? selectedClasses : [],
    school_ids: selectedSchools ? selectedSchools : [],
    grade_ids: selectedGrades ? selectedGrades : [],
  };
  const { data: overallPerformancelevelData, loading: overallPerformancelevelDataLoading, error : overallPerformanceLevelDataErr } = useOverallPerformanceLevelQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });

  useEffect(() => {
    if (overallPerformanceLevelDataErr) {
      openSnackbar({ message: getErrorMessage(overallPerformanceLevelDataErr) }, NotifierType.Error);
    }
    const speakingData = overallPerformancelevelData?.overallPerformanceLevel?.filter((item) => item?.submission_type === TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.submissionTypes.Speaking)[0]?.score_line_items;
    const pieChartDataSpeaking: pieChartData[] = [];
    speakingData?.forEach((item: OverallPerformanceLevelScoreLineItem) => {
      pieChartDataSpeaking.push({ studentCount: item.student_count, color: item.score_level_color, skillTitle: item.score_level_title, scoreLevelId: item.score_level_id, scoreLevelAbbr: item.score_level_abbr, scoreLevelTitle: item.score_level_title });
    });
    setSpeakingPieChartData(pieChartDataSpeaking);
    const writingData = overallPerformancelevelData?.overallPerformanceLevel?.filter((item) => item?.submission_type === TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.submissionTypes.Writing)[0]?.score_line_items;
    const pieChartWriting: pieChartData[] = [];
    writingData?.forEach((item: OverallPerformanceLevelScoreLineItem) => {
      pieChartWriting.push({ studentCount: item.student_count, color: item.score_level_color, skillTitle: item.score_level_title, scoreLevelId: item.score_level_id, scoreLevelAbbr: item.score_level_abbr, scoreLevelTitle: item.score_level_title });
    });
    setWritingPieChartData(pieChartWriting);
    setSpeakingAvgScore(overallPerformancelevelData?.overallPerformanceLevel?.filter((item) => item?.submission_type === TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.submissionTypes.Speaking)[0]?.average_score!);
    setWritingAvgScore(overallPerformancelevelData?.overallPerformanceLevel?.filter((item) => item?.submission_type === TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.submissionTypes.Writing)[0]?.average_score!);
  }, [overallPerformancelevelData, overallPerformancelevelDataLoading, overallPerformanceLevelDataErr]);

  useEffect(() => {
    const hasChartData = (speakingPieChartData?.length ?? 0) > 0 || (writingPieChartData?.length ?? 0) > 0;
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.name,
      loading: overallPerformancelevelDataLoading,
      dataMissing: !hasChartData,
    };
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    setHasData(hasChartData);
    const legendData: pieChartData[] | undefined = speakingPieChartData?.concat(writingPieChartData?.filter(item2 => !speakingPieChartData?.some(item1 => item1?.skillTitle === item2?.skillTitle))!) ?? [];
    setLegendDataOverallPerformanceWidget?.(legendData);
  }, [speakingPieChartData, writingPieChartData, overallPerformancelevelDataLoading]);

  return {
    overallPerformancelevelData,
    speakingPieChartData,
    writingPieChartData,
    overallPerformancelevelDataLoading,
    hasData,
    speakingAvgScore,
    writingAvgScore,
    showFlyover,
    toggleFlyover,
    flyOverTitle,
    flyOverData,
    openFlyover,
    setLegendDataOverallPerformanceWidget,
    legendData,
  };
};
export default useOverallPerformanceLevel;
