import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { DistrictWideGradeDetail, DistrictWideGradeSortType } from '../../../../generated/graphql';
import List from './List';
import { DistrictWideComparisonsContext } from '../district-wide-comparisons-hook';
import useSchools from './grades-hook';

interface Props {
  districtId?: string,
  schoolId?: string,
}

const Grades = ({ districtId, schoolId }: Props) => {

  const {
    widgetLoading,
  } = useContext(DistrictWideComparisonsContext);

  const {
    queryInput, handleSort,
    districtWideGradesData, isTableEmpty,
  } = useSchools({ districtId: districtId, schoolId: schoolId });

  return (
    <Box>
      <List
        isTableEmpty={isTableEmpty}
        loading={widgetLoading!}
        sort={queryInput.sort! as DistrictWideGradeSortType}
        order={queryInput.orderBy!}
        grades={districtWideGradesData as DistrictWideGradeDetail[]}
        handleSort={handleSort}
      />
    </Box>
  );
};

export default Grades;
