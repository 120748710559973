import React from 'react';
import { Box, Typography } from '@mui/material';
import { palette  } from '../../theme/palette';

interface Props {
  label?: string
  description?:string,
  svgComponent?:object,
}

const NoResults = ({ label, description, svgComponent } : Props) => (
  <Box
    sx={{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      width:'100%',
      height:'100%',
      minHeight:'300px',
      minWidth:'300px',
      flexDirection:'column',
    }}
  >
    <Box>{svgComponent}</Box>
    {label && <Typography variant='secondaryTitle' sx={{ textTransform: 'none' }}>{label}</Typography>}
    {description && <Typography variant='secondaryLabel' sx={{ color: palette.customBlack.lightBlack, marginTop: '8px' }}>{description}</Typography>}
  </Box>
);

export default NoResults;
