/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WavingLadyFrame = (props: any) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="134"
      height="200"
      viewBox="0 0 134 200"
      fill="none"
      sx={{ fontSize: 185 }}
      {...props}
    >
      <g clipPath="url(#clip0)">
        <g clipPath="url(#clip1)">
          <path d="M24.9001 76.7003C23.0001 74.6003 21.3001 72.0003 22.3001 69.0003C23.2001 66.3003 25.6001 64.5003 27.5001 62.6003C29.6001 60.5003 31.7001 57.7003 31.2001 54.5003C30.5001 50.2003 25.9001 48.6003 24.0001 45.0003C21.3001 39.7003 28.6001 39.9003 28.8001 35.5003C28.9001 32.8003 22.2001 31.1003 20.2001 30.8003C16.5001 30.1003 12.7001 30.7003 9.50007 32.7003C4.40007 35.9003 2.10007 42.8003 5.20007 48.1003C6.00007 49.5003 7.20007 50.6003 8.10007 51.9003C10.0001 54.8003 9.60007 57.8003 8.10007 60.7003C6.10007 64.5003 2.70007 67.3003 0.900067 71.2003C-1.69993 76.9003 1.10007 81.1003 5.60007 84.4003C8.00007 86.2003 10.9001 87.8003 12.5001 90.5003C15.2001 95.0003 12.5001 100.2 13.0001 105C13.4001 109.2 18.2001 114.7 22.4001 110.8C25.2001 108.3 24.0001 104.1 24.8001 100.9C25.7001 97.3003 28.4001 94.7003 30.1001 91.5003C33.8001 85.0003 29.2001 81.3003 24.9001 76.7003Z" fill="black" />
          <path d="M30.3003 88.1003C29.7003 91.1003 27.5003 93.5003 25.9003 95.9003C24.7003 97.8003 23.8003 99.7003 23.5003 101.9C23.2003 103.9 23.6003 106 22.9003 107.9C21.0003 112.9 15.7003 110.2 14.7003 106.4C13.1003 100.4 17.3003 94.2003 12.9003 88.8003C9.90027 85.0003 4.40027 83.4003 2.10027 79.1003C-1.79973 72.0003 7.60027 65.8003 10.0003 59.8003C11.1003 57.1003 11.1003 54.3003 9.60027 51.7003C7.20027 47.9003 4.30027 45.4003 5.50027 40.4003C7.90027 30.1003 20.4003 30.5003 27.5003 35.2003C27.8003 37.5003 24.5003 38.1003 23.3003 39.3003C22.7003 40.0003 22.3003 40.9003 22.2003 41.8003C21.9003 43.9003 22.9003 45.8003 24.2003 47.3003C26.7003 50.2003 31.2003 52.6003 29.7003 57.1003C28.5003 60.8003 24.7003 62.8003 22.5003 65.8003C19.3003 70.3003 21.1003 74.5003 24.7003 78.0003C27.4003 81.0003 31.1003 83.7003 30.3003 88.1003Z" fill="black" />
          <path d="M30.3998 31.9005C30.2998 31.5005 29.8998 31.4005 29.5998 31.5005C29.5998 31.5005 29.4998 31.5005 29.4998 31.4005C23.6998 29.8005 18.7998 36.3005 22.5998 41.3005C24.0998 43.2005 27.0998 44.4005 28.8998 42.3005C31.0998 39.8005 30.8998 35.0005 30.3998 31.9005Z" fill="black" />
          <path d="M28.8 39.7007C28.4 40.8007 28 42.1007 26.4 42.2007C25.1 42.3007 23.8 41.1007 23.2 40.1007C20.8 36.0007 24.7 31.5007 29.1 32.7007C29.1 32.7007 29.1 32.7007 29.2 32.7007C29.6 35.0007 29.5 37.4007 28.8 39.7007Z" fill="#DFDFDF" />
          <path d="M116.7 28.8004C115.6 24.7004 112 16.3004 106.4 20.5004C106.2 20.7004 106 20.9004 106.1 21.2004C111.1 34.3004 102.4 46.9004 91.2001 53.1004C85.3001 56.4004 78.9001 58.5004 72.4001 60.0004C69.2001 60.7004 65.9001 61.3004 62.6001 61.9004C59.6001 62.4004 56.7001 62.8004 53.8001 64.0004C50.7001 65.3004 47.8001 67.0004 45.0001 68.9004C33.0001 77.0004 22.3001 87.4004 14.8001 99.8004C12.7001 103.2 10.8001 107 11.2001 111C11.5001 114.6 13.7001 117.9 16.6001 120.1C19.5001 122.3 23.0001 123.5 26.6001 124.2C30.6001 125 34.8001 125.2 38.9001 124.8C41.0001 124.6 43.1001 124.2 44.9001 123.1C46.7001 122 48.1001 120.1 48.1001 118C48.1001 117.7 48.0001 117.3 47.8001 117.1C47.5001 116.9 47.2001 116.9 46.9001 117C42.0001 117.6 36.6001 117.2 32.8001 114C31.8001 113.2 30.9001 112 31.0001 110.8C31.1001 109.1 32.7001 108 34.2001 107.2C39.4001 104.6 45.1001 103.4 50.3001 101C50.6001 100.9 55.6001 113.4 56.0001 114.5C56.8001 116.8 57.6001 119.2 58.3001 121.6C58.6001 122.8 59.0001 124.1 59.3001 125.3C59.7001 126.8 59.9001 127.8 61.3001 128.7C62.6001 129.6 64.1001 130.2 65.5001 130.8C67.5001 131.8 69.5001 132.5 71.7001 133.1C75.9001 134.2 80.3001 134.4 84.6001 133.6C87.5001 133.1 90.4001 132.1 92.9001 130.5C94.2001 129.7 95.3001 128.9 96.4001 127.8C97.0001 127.3 97.5001 126.7 98.0001 126.1C98.3001 125.8 99.4001 124.8 99.4001 124.3C104.3 117.2 101.3 107.5 99.0001 99.9004C96.8001 92.7004 93.6001 85.8004 89.8001 79.3004C102.8 74.9004 113.1 64.4004 116.8 51.1004C118.7 44.0004 118.7 36.1004 116.7 28.8004Z" fill="black" />
          <path d="M113.1 57.3006C108.4 67.3006 99.3999 74.9006 88.9999 78.4006C88.1999 77.2006 87.4999 76.0006 86.6999 74.8006C86.1999 74.1006 85.0999 74.8006 85.5999 75.4006C90.0999 82.0006 93.7999 89.2006 96.4999 96.7006C98.9999 103.801 102.5 113.401 99.7999 120.801C97.0999 128.001 88.7999 131.801 81.5999 132.601C74.1999 133.401 66.7999 131.201 60.9999 126.501C58.2999 115.101 53.9999 104.201 48.1999 94.0006C47.7999 93.3006 46.6999 93.9006 47.0999 94.6006C48.0999 96.3006 48.9999 98.0006 49.8999 99.8006C44.5999 101.401 39.4999 103.301 34.3999 105.501C32.1999 106.501 29.6999 107.901 29.4999 110.301C29.1999 112.801 31.3999 114.801 33.5999 115.901C37.5999 118.001 42.2999 118.801 46.7999 118.001C46.8999 119.601 45.7999 121.001 44.4999 121.801C43.1999 122.601 41.5999 122.901 39.9999 123.101C36.1999 123.501 32.2999 123.501 28.4999 123.001C24.6999 122.501 20.8999 121.501 17.7999 119.301C14.6999 117.101 12.3999 113.501 12.3999 109.701C12.3999 106.901 13.7999 104.201 15.1999 101.701C23.5999 87.0006 36.3999 76.7006 50.0999 67.2006C57.1999 62.3006 65.9999 62.6006 74.0999 60.7006C80.7999 59.1006 87.5999 57.0006 93.4999 53.4006C98.2999 50.4006 102.5 46.4006 105.3 41.5006C107.3 37.9006 108.6 33.9006 108.8 29.8006C108.9 27.8006 108.7 25.7006 108.2 23.7006C107.8 22.1006 107.1 21.1006 109.3 20.9006C111.4 20.7006 112.9 22.7006 113.8 24.4006C118.9 34.3006 117.7 47.6006 113.1 57.3006Z" fill="#DFDFDF" />
          <path d="M125.2 7.70012C125.2 6.60012 124.3 5.80012 123.3 5.70012C121.8 5.50012 120 6.30012 118.6 6.90012C119.1 6.50012 119.6 6.00012 120.1 5.60012C120.9 4.90012 122 4.20012 122.4 3.20012C122.9 1.90012 122.2 0.80012 120.9 0.70012C120.1 0.60012 119.1 0.90012 118.2 1.30012C118.2 1.30012 118.2 1.30012 118.2 1.20012C113.4 -3.49988 106.1 8.70012 104.6 12.0001C104.6 12.1001 104.5 12.2001 104.5 12.3001C104.1 11.8001 103.8 11.3001 103.4 10.8001C103 10.3001 102.6 9.80012 101.9 9.70012C100.4 9.40012 99.8003 10.9001 99.9003 12.1001C100.2 15.3001 102.4 18.2001 104.7 20.3001C105.9 21.4001 107.1 22.4001 108.5 23.3001C109 23.6001 109.4 23.3001 109.8 23.1001C110.2 22.9001 110.6 22.6001 110.9 22.4001C111.7 21.9001 112.4 21.4001 113.1 20.8001C114.5 19.7001 115.9 18.5001 117.3 17.4001C119 16.0001 120.6 14.6001 122.3 13.2001C122.9 12.7001 123.9 12.1001 124 11.2001C124 10.7001 123.9 10.3001 123.7 10.1001C124.4 9.40012 125.1 8.80012 125.2 7.70012Z" fill="black" />
          <path d="M122 9.50029C121.2 9.90029 120.2 10.0003 119.4 10.4003C118.7 10.7003 117.9 11.1003 117.2 11.5003C116.1 12.1003 115 12.8003 114 13.6003C113.8 13.7003 113.6 13.9003 113.4 14.0003C112.8 14.5003 113.7 15.3003 114.2 14.8003C115.8 13.5003 117.6 12.3003 119.5 11.4003C120.1 11.1003 120.6 10.7003 121.3 10.6003C121.5 10.6003 121.6 10.6003 121.8 10.6003C122.1 10.7003 122.2 11.0003 122.2 11.3003C122.2 11.7003 121.8 12.0003 121.5 12.2003C121.1 12.5003 120.7 12.9003 120.3 13.2003C119.8 13.6003 119.3 13.9003 118.8 14.3003C117.2 15.6003 115.7 16.9003 114.2 18.2003C113.4 18.9003 112.5 19.6003 111.6 20.2003C111 20.6003 110.4 21.0003 109.9 21.4003C109.6 21.6003 109.3 21.8003 108.9 21.8003C108.3 21.8003 107.8 21.3003 107.3 20.9003C106.8 20.5003 106.2 20.0003 105.7 19.5003C105.3 19.1003 104.8 18.7003 104.4 18.2003C104.3 18.1003 104.2 17.9003 104.1 17.8003C103.4 17.0003 100.7 13.5003 101.1 12.3003C101.9 9.70029 102.5 11.7003 102.9 12.3003C103.3 12.9003 103.8 13.4003 104.3 13.8003C105.2 14.4003 106.1 14.7003 107.2 14.8003C108 14.9003 107.9 13.7003 107.2 13.6003C106.1 13.5003 104.9 13.1003 105.4 12.2003C106.7 10.0003 107.7 7.70029 109.4 5.80029C110.7 4.30029 114.5 -0.399711 116.9 1.40029C115.9 2.00029 115 2.70029 114.3 3.20029C112.4 4.70029 110.9 6.60029 109.8 8.80029C109.4 9.50029 110.5 10.1003 110.8 9.40029C112.3 6.60029 114.4 4.30029 117.2 2.70029C117.8 2.40029 119.1 1.30029 119.7 1.50029C121.1 1.40029 121.3 2.10029 120.5 3.10029C120 3.50029 119.6 3.90029 119.1 4.30029C117.3 5.90029 115.6 7.80029 113.5 9.10029C113 9.40029 112.5 9.80029 112 10.2003C111.4 10.7003 112 11.7003 112.6 11.2003C114.1 10.1003 115.6 9.10029 117.3 8.30029C118.6 7.70029 120 7.10029 121.4 6.70029C122 6.50029 123.5 6.50029 123.4 7.50029C123.4 7.80029 123.2 8.10029 123 8.30029C122.7 9.10029 122.4 9.30029 122 9.50029Z" fill="white" />
          <path d="M103.3 125C103.2 121.2 103.2 117.2 102.7 113.4C102.7 113.2 102.6 113.1 102.6 112.9C102.6 112.8 102.6 112.7 102.5 112.6C102.4 112.4 102 112.4 101.8 112.5C88.6003 118.8 74.4003 122.3 59.8003 122.9C59.5003 122.9 57.9003 123.2 58.1003 123.9C59.3003 128.8 60.2003 133.8 60.7003 138.9C61.2003 144 61.4003 149 61.2003 154.1C61.0003 159.2 60.5003 164.2 59.6003 169.2C58.7003 174.2 57.5003 179.1 55.9003 183.9C54.3003 188.7 52.4003 193.4 50.2003 198C48.0003 202.6 45.4003 207 42.6003 211.2C41.7003 212.5 40.9003 213.7 39.9003 214.9C39.4003 215.5 39.0003 216.1 38.5003 216.7C38.0003 217.3 37.5003 217.8 37.1003 218.5C36.6003 219.4 37.3003 219.8 37.9003 220.3C38.5003 220.8 39.1003 221.4 39.6003 221.9C40.2003 222.5 40.8003 223.2 41.5003 223.6C42.3003 224.1 42.8003 223.4 43.4003 222.9C43.9003 222.5 44.4003 222.1 44.9003 221.7C45.9003 220.9 46.9003 220.1 47.9003 219.3C49.9003 217.6 51.8003 215.9 53.6003 214.1C57.3003 210.5 60.6003 206.7 63.8003 202.7C66.9003 198.7 69.7003 194.4 72.2003 190C74.7003 185.6 76.9003 180.9 78.7003 176.2C78.8003 176 78.8003 175.9 78.9003 175.7C78.6003 176.4 78.7003 177.5 78.7003 178.3C78.6003 179.2 78.5003 180.1 78.4003 181C78.2003 182.8 78.0003 184.5 77.7003 186.3C77.2003 189.8 76.6003 193.3 76.0003 196.8C74.7003 203.8 73.0003 210.7 71.0003 217.5C70.1003 220.5 69.1003 223.5 68.1003 226.4C67.1003 229.2 65.9003 232 65.1003 234.9C65.1003 235 65.0003 235.1 65.0003 235.2C65.1003 235.8 66.7003 236.1 67.2003 236.3C68.2003 236.7 69.2003 236.9 70.2003 237C71.7003 237.1 74.2003 237.6 75.4003 236.4C75.7003 236.1 75.8003 235.7 76.0003 235.4C77.0003 233 78.4003 230.9 79.6003 228.6C80.9003 226.1 82.1003 223.7 83.2003 221.2C85.5003 216.2 87.6003 211.1 89.6003 205.9C91.5003 200.7 93.2003 195.5 94.8003 190.2C96.3003 184.9 97.6003 179.6 98.7003 174.2C99.8003 168.8 100.7 163.4 101.4 157.9C102.1 152.4 102.6 146.9 102.8 141.4C103.4 136 103.5 130.5 103.3 125Z" fill="black" />
          <path d="M74.8005 235.6C72.0005 236.3 69.3005 236 66.7005 234.7C74.7005 214.4 79.4005 193.1 80.8005 171.3C83.2005 164.2 84.8005 156.9 85.6005 149.4C86.9005 148.6 88.3005 147.7 89.6005 146.9C90.3005 146.5 89.7005 145.4 89.0005 145.8C88.1005 146.3 87.2005 146.9 86.4005 147.4C85.7005 147.8 84.8005 148.1 84.5005 148.9C84.2005 149.5 84.3005 150.4 84.2005 151.1C84.1005 152 84.0005 152.9 83.8005 153.8C79.8005 179.5 66.2005 203.4 46.0005 219.7C44.8005 220.7 43.6005 221.6 42.4005 222.5C41.1005 221.3 39.8005 220.1 38.5005 218.9C38.5005 218.9 43.1005 212.7 43.5005 212.2C45.1005 209.9 46.6005 207.5 48.0005 205C50.8005 200.1 53.2005 195 55.2005 189.8C57.2005 184.6 58.8005 179.2 60.0005 173.7C61.2005 168.2 62.0005 162.7 62.4005 157.1C62.8005 151.5 62.7005 145.9 62.2005 140.3C62.0005 137.6 61.6005 134.9 61.2005 132.2C61.0005 130.9 60.7005 129.5 60.5005 128.2C60.3005 127.2 60.0005 126.1 59.8005 125.1C59.7005 124.6 59.5005 124.3 60.1005 124.1C61.4005 123.8 62.9005 124 64.2005 123.9C65.8005 123.8 67.4005 123.6 69.0005 123.4C72.2005 123 75.3005 122.5 78.4005 121.8C84.6005 120.5 90.8005 118.6 96.7005 116.2C97.5005 115.9 98.4005 115.5 99.2005 115.2C99.4005 115.1 101.6 114.3 101.6 114.1C104.9 156.1 95.6005 198.8 74.8005 235.6Z" fill="white" />
          <path d="M89.7002 146.9C89.7002 147 89.7002 147 89.7002 146.9C88.3002 147.8 87.0002 148.6 85.6002 149.4C84.8002 156.9 83.1002 164.2 80.8002 171.3C80.4002 177.8 79.7002 184.2 78.7002 190.6C85.0002 181.9 89.0002 171.7 90.4002 161C91.0002 156.8 91.7002 151.1 89.7002 146.9Z" fill="#D3DEE3" />
          <path d="M87.1003 139.4C87.1003 139.5 87.1003 139.6 87.1003 139.7C87.0003 142.3 86.8003 144.9 86.4003 147.5V147.6C86.6003 147.5 86.8003 147.3 87.0003 147.2C87.3003 144.8 87.5003 142.3 87.6003 139.9C91.7003 139.1 92.4003 134.5 92.1003 131C91.8003 126.8 91.1003 122.6 90.1003 118.6C89.9003 118.7 89.7003 118.7 89.5003 118.8C90.3003 122.1 90.9003 125.3 91.2003 128.7C91.6003 132.3 92.1003 138.3 87.6003 139.3C87.8003 132.8 87.2003 126.3 85.9003 120C85.7003 120.1 85.5003 120.1 85.3003 120.2C86.8003 126.4 87.3003 132.9 87.1003 139.4Z" fill="black" />
          <path d="M65.6001 144.3C65.9001 137.4 65.9001 130.6 65.8001 123.7C65.6001 123.7 65.4001 123.7 65.2001 123.8C65.4001 149.9 64.6001 177 52.2001 200.5C48.9001 206.9 44.7001 212.8 39.8001 218C39.7001 217.9 39.6001 217.7 39.5001 217.6C39.4001 217.8 39.2001 218 39.1001 218.1C40.2001 219.6 41.5001 220.9 43.1001 222C43.3001 221.9 43.5001 221.7 43.6001 221.6C42.3001 220.7 41.2001 219.7 40.2001 218.5C59.0001 198.7 64.6001 170.7 65.6001 144.3Z" fill="black" />
          <path d="M65.4 183.1C65.5 183.1 65.6 183.1 65.7 183C66.2 182.9 66.8 182.8 67.2 182.5C68.1 181.9 68 180.8 68.2 179.9C69 176.9 69.7 174 70.3 170.9C71.5 164.9 72.3 158.8 72.7 152.7C73.5 140.5 72.6 128.2 70.1 116.3C67.7 104.3 63.6 92.6997 58.1 81.7997C56.6 78.6997 54.9 75.6997 53.1 72.7997C52.2 71.2997 51.3 69.7997 50.3 68.3997C49.8 67.5997 49.1 65.3997 47.8 65.5997C47.5 65.6997 47.3 65.7997 47.1 65.9997C45.2 67.4997 43.3 68.9997 41.4 70.3997C40.4 71.0997 39.5 71.8997 38.5 72.5997C38.1 72.8997 35.7 74.3997 35.7 74.8997C33.3 76.9997 32.4 79.2997 33.6 82.2997C35.2 86.2997 38.3 89.8997 40.6 93.3997L49 106.1C56.9 129.6 53.7 156.2 40.2 177.2C40 177.3 39.9 177.4 39.9 177.7C39.8 177.9 39.9 178.1 40 178.3C41.7 187.6 53.9 185.4 60.5 184.1C62.2 183.9 63.8 183.4 65.4 183.1Z" fill="black" />
          <path d="M41.3999 178.1C54.9999 157.1 58.2999 130.3 50.5999 106.7C50.6999 106.5 50.6999 106.3 50.5999 106.1C50.4999 106 50.3999 105.8 50.2999 105.7C49.4999 103.2 48.4999 100.7 47.3999 98.2997C47.0999 97.5997 45.9999 98.1997 46.2999 98.8997C46.5999 99.5997 46.8999 100.3 47.1999 101C43.7999 95.7997 40.2999 90.6997 36.8999 85.4997C35.7999 83.8997 34.3999 81.9997 34.3999 79.9997C34.3999 77.3997 36.5999 75.9997 38.2999 74.6997C41.4999 72.1997 44.6999 69.6997 47.8999 67.1997C70.6999 100.4 77.5999 143.2 66.2999 181.9C59.8999 182.8 42.9999 188.3 41.3999 178.1Z" fill="white" />
          <path d="M59.8 107.4C56.3 108 53.1 109.9 50.5 112.3C49.3 113.4 48.2 114.7 47.2 116C46.3 117.1 45 118.5 44.7 119.9C44.4 121.4 45.2 122.8 46.4 123.6C48.5 125 51.3 124.7 53.5 123.7V123.8C54.1 125.9 56.8 126.1 58 124.3C58.5 123.6 58.6 122.9 58.5 122.2C59.8 121.9 60.6 120.7 60.5 119.3C62.4 118.4 62.2 115.9 61.1 114.3C60.5 113.4 59.6 112.7 58.6 112.3C60.1 111.5 62.7 111.6 63 109.5C63.2 107.6 61.3 107.2 59.8 107.4Z" fill="black" />
          <path d="M59.9 110.5C59.2 110.8 57.7 111.2 57 111.9C56.3 111.8 55.5 111.9 54.8 112.1C54 112.3 54.3 113.5 55.1 113.3C56.6 112.9 58.1 113.1 59.3 114.1C60.2 114.9 61.4 117.1 60.2 117.9C59.6 116.5 58 115.5 56.7 114.8C56 114.4 55.3 115.5 56.1 115.9C57.1 116.4 58.2 117 58.8 117.9C59.4 118.9 59.5 120.6 58.2 120.9C57.7 119.8 56.6 118.8 55.6 118.2C54.9 117.8 54.3 118.9 55 119.3C56.1 120 57.2 120.9 57.3 122.2C57.4 123.6 55.3 125.2 54.8 123.3C54.8 123.1 54.7 123 54.6 123C54.9 122.8 55.2 122.6 55.4 122.4C56 121.9 55.4 120.8 54.8 121.3C53.4 122.4 51.9 123 50.1 123C49.3 123 48.5 122.9 47.8 122.6C46.1 121.9 45.4 120.3 46.6 118.8C47.9 117.2 49 115.5 50.4 114.1C51.8 112.6 53.5 111.4 55.2 110.4C56.1 109.9 57 109.4 58 109.1C60 108.6 63.8 108.7 59.9 110.5Z" fill="white" />
          <path d="M133.6 154.5C133.8 153.6 133.6 152.4 133.6 151.5C133.5 150.3 133.4 149.1 133.3 147.9C133 145.6 132.5 143.2 131.9 141C130.7 136.5 128.8 132.2 126.7 128.1C122.2 119.6 116.3 112.1 110.4 104.6C107.5 100.9 104.5 97.1999 101.7 93.3999C100.3 91.4999 98.9002 89.4999 97.5002 87.4999C96.8002 86.4999 96.2001 85.4999 95.6002 84.4999C95.2001 83.8999 93.9002 82.4999 94.0002 81.6999C94.0002 81.4999 94.1002 81.3999 94.1002 81.1999C94.3002 80.3999 93.9002 79.0999 93.7001 78.3999C93.5001 77.3999 93.3002 76.3999 93.0002 75.3999C92.4002 73.2999 91.7002 71.2999 90.9002 69.3999C89.3002 65.6999 87.0002 62.0999 83.8002 59.4999C81.4002 57.5999 78.8002 56.9999 75.8002 57.3999C73.0002 57.6999 70.2002 58.2999 67.4002 58.9999C66.0002 59.2999 64.6001 59.6999 63.1001 59.9999C61.8001 60.2999 59.1002 60.6999 61.0002 62.4999C61.7002 63.1999 62.7002 63.4999 63.5002 63.9999C64.6002 64.6999 65.7001 65.4999 66.6001 66.3999C68.5001 68.0999 70.1002 70.0999 71.5002 72.2999C74.3002 76.5999 76.3002 81.4999 78.3002 86.2999C80.2002 90.7999 82.1002 95.3999 85.0002 99.3999C87.6002 103.1 90.7001 106.4 93.7001 109.7C100.7 117.2 106.6 125.1 110.2 134.7C112 139.5 113.3 144.5 114 149.6C114.3 152.2 114.5 154.8 114.6 157.3C114.6 159.5 113.6 162.1 114.5 164.2C114.6 164.5 114.8 164.7 115.1 164.8C115.3 164.9 115.5 164.8 115.8 164.8C117.4 164.5 119.1 163.8 120.7 163.3C122.9 162.5 125 161.5 127 160.3C127.5 160 132.7 156.5 132.6 156.4C132.6 156.4 133.4 155.5 133.4 155.4C133.5 154.9 133.6 154.7 133.6 154.5Z" fill="black" />
          <path d="M115.7 163.2C117.1 143.2 109.6 124.7 96.1003 110.1C92.4003 106.1 88.5003 102.2 85.5003 97.5997C82.3003 92.7997 80.3003 87.2997 78.1003 81.9997C74.7003 73.7997 70.3003 65.3997 61.8003 61.3997C65.3003 60.5997 68.9003 59.8997 72.4003 59.0997C76.9003 58.0997 80.8003 57.8997 84.3003 61.4997C88.9003 66.1997 91.5003 72.8997 92.7003 79.3997C92.1003 78.3997 91.6003 77.2997 91.1003 76.1997C90.7003 75.4997 89.7003 76.0997 90.0003 76.7997C102.6 103.1 132.7 123 132.5 154.5C127.5 158.7 121.9 161.6 115.7 163.2Z" fill="white" />
          <path d="M44.9 56.2C42.8 54.7 39.6 55.9 38.6 58.2C37.6 60.4 38.6 63.2 40.8 64.2C43 65.2 45.6 64.3 46.7 62.2C47.7 60.1 46.7 57.5 44.9 56.2Z" fill="black" />
          <path d="M45.6 61.4999C44.8 63.1999 42.7 63.6999 41.1 62.8999C39.6 62.0999 39.1 59.9999 39.9 58.4999C41.8 54.9999 47.2 58.0999 45.6 61.4999Z" fill="white" />
          <path d="M68.0997 41.9002C65.9997 40.4002 62.7997 41.6002 61.7997 43.9002C60.7997 46.1002 61.7997 48.9002 63.9997 49.9002C66.1997 50.9002 68.7997 50.0002 69.8997 47.9002C70.8997 45.9002 69.8997 43.2002 68.0997 41.9002Z" fill="black" />
          <path d="M68.7997 47.3003C67.9997 49.0003 65.8997 49.5003 64.2997 48.7003C62.7997 47.9003 62.2997 45.8003 63.0997 44.3003C65.0997 40.7003 70.4997 43.8003 68.7997 47.3003Z" fill="white" />
          <path d="M62.5999 63.9003C61.5999 62.6003 61.1999 60.9003 61.4999 59.2003C66.2999 54.5003 66.9999 47.4003 64.4999 41.4003C62.9999 37.7003 60.5999 34.5003 57.7999 31.7003C56.3999 30.3003 54.8999 29.0003 53.3999 27.7003C52.4999 27.0003 51.5999 26.1003 50.5999 25.5003C48.2999 24.2003 45.4999 23.5003 42.8999 23.7003C41.2999 23.8003 39.6999 24.2003 38.2999 24.9003C35.3999 26.3003 32.9999 28.8003 31.4999 31.7003C30.4999 33.6003 29.7999 35.9003 29.7999 38.0003C29.0999 49.5003 38.7999 57.8003 48.2999 62.2003C48.6999 64.0003 49.0999 65.7003 49.4999 67.5003C49.7999 68.8003 49.8999 70.5003 50.6999 71.6003C51.9999 73.4003 54.2999 73.4003 56.2999 73.1003C58.9999 72.8003 61.9999 71.9003 63.4999 69.4003C64.5999 67.5003 64.1999 65.4003 62.6999 63.9003H62.5999Z" fill="black" />
          <path d="M53.3001 71.9002C50.9001 70.7002 50.6001 67.8002 50.4001 65.4002C50.3001 64.1002 50.2001 62.5002 49.3001 61.4002C48.5001 60.4002 46.9001 60.4002 45.8001 59.8002C44.8001 59.2002 43.8001 58.5002 42.8001 57.8002C41.0001 56.6002 39.2001 55.2002 37.6001 53.7002C34.4001 50.7002 32.0001 46.9002 31.2001 42.5002C31.2001 42.3002 31.1001 42.0002 31.1001 41.8002C30.6001 38.6002 31.1001 35.2002 32.6001 32.3002C34.0001 29.5002 36.3001 27.1002 39.1001 25.9002C40.3001 25.4002 41.6001 25.1002 42.9001 25.0002C44.7001 24.9002 46.6001 25.2002 48.3001 25.9002C51.9001 27.3002 54.5001 30.3002 57.1001 33.0002C59.1001 35.0002 60.8001 37.2002 62.1001 39.7002C64.1001 43.4002 65.0001 47.8002 64.0001 52.0002C63.5001 54.1002 62.5001 56.0002 61.2001 57.6002C60.3001 58.6002 59.9001 58.8002 60.0001 60.3002C60.1001 62.1002 60.6001 63.9002 61.8001 65.2002C65.7001 69.4002 56.9001 73.7002 53.3001 71.9002Z" fill="white" />
          <path d="M61.8997 65.1004C60.6997 63.7004 60.0997 62.0004 60.0997 60.2004C60.0997 59.6004 60.0997 59.2004 60.2997 58.9004C59.0997 59.3004 57.8997 59.6004 56.6997 59.7004C55.4997 59.8004 54.1997 59.8004 53.1997 60.7004C50.9997 62.9004 53.6997 65.4004 55.5997 66.5004C57.6997 67.7004 60.4997 68.7004 62.7997 67.6004C62.8997 66.7004 62.5997 65.9004 61.8997 65.1004Z" fill="#D3DEE3" />
          <path d="M57.2998 32.9004C54.6998 30.2004 51.9998 27.2004 48.4998 25.8004C46.7998 25.1004 44.8998 24.9004 43.0998 24.9004C41.7998 25.0004 40.4998 25.3004 39.2998 25.8004C36.3998 27.0004 34.1998 29.5004 32.7998 32.2004C31.2998 35.1004 30.7998 38.5004 31.2998 41.7004C31.2998 41.9004 31.3998 42.2004 31.3998 42.4004C32.2998 46.7004 34.6998 50.5004 37.7998 53.6004C39.0998 54.9004 40.4998 56.0004 41.9998 57.0004C42.4998 56.8004 42.3998 56.2004 41.9998 55.9004C43.0998 50.3004 42.9998 44.6004 41.7998 39.0004C47.7998 38.9004 53.4998 35.6004 59.3998 34.9004C58.5998 34.3004 57.9998 33.6004 57.2998 32.9004Z" fill="#D3DEE3" />
          <path d="M37.2 38.9C35.2 35.6 30.2 30.3 26.5 34.8C23.5 38.4 25.5 44.5 27.5 48.1C29.1 50.9 31.3 53.3 33.9 55.1C35.1 55.9 40.6 59.6 41.7 57.5C42.2 56.6 41.7 54.2 41.7 53.2C41.6 51.6 41.3 49.9 41 48.4C40.3 45.2 39.1 42.1 37.4 39.3C37.3 39.2 37.2 39.1 37.2 38.9Z" fill="black" />
          <path d="M26.4002 38.3002C27.1002 33.4002 32.0002 34.2002 34.5002 37.2002C35.9002 38.9002 37.0002 40.9002 37.9002 42.9002C39.9002 47.3002 40.7002 52.0002 40.5002 56.8002C32.9002 54.7002 25.1002 46.7002 26.4002 38.3002Z" fill="black" />
          <path d="M44.8998 16.3002C35.9998 17.3002 24.7998 24.9002 28.1998 35.1002C28.2998 35.4002 28.4998 35.5002 28.6998 35.6002C28.7998 35.6002 28.7998 35.7002 28.8998 35.7002C40.6998 41.0002 51.3998 28.4002 63.1998 32.5002C63.6998 32.7002 64.1998 32.2002 64.0998 31.8002C63.9998 21.7002 54.4998 15.2002 44.8998 16.3002Z" fill="black" />
          <path d="M29.6999 34.5H29.5999C26.5999 25 37.9999 18.2 46.0999 17.8C54.1999 17.4 61.8999 22.7 62.5999 30.9C51.2999 27.7 40.7999 39.4 29.6999 34.5Z" fill="black" />
          <path d="M50.9 44.6001C50.8 44.3001 50.7 44.1001 50.5 43.9001C49.3 42.9001 48.5 44.8001 49 45.8001C49.2 46.2001 49.6 46.6001 50 46.6001C50.9 46.5001 51.1 45.4001 50.9 44.6001Z" fill="black" />
          <path d="M59.1002 41.1001C59.0002 40.8001 58.9002 40.6001 58.7002 40.4001C57.5002 39.4001 56.7002 41.3001 57.2002 42.3001C57.4002 42.7001 57.8002 43.1001 58.2002 43.1001C59.1002 43.0001 59.3002 41.9001 59.1002 41.1001Z" fill="black" />
          <path d="M57.9999 44.9002C57.9999 44.5002 57.6999 44.3002 57.3999 44.3002C55.9999 44.2002 55.2999 43.1002 55.3999 41.8002C55.4999 41.0002 54.1999 41.0002 54.0999 41.8002C53.8999 43.6002 55.0999 45.1002 56.6999 45.5002C56.6999 46.1002 56.7999 46.6002 56.7999 47.2002C56.7999 48.0002 58.0999 48.0002 58.0999 47.2002C57.9999 46.4002 57.9999 45.6002 57.9999 44.9002Z" fill="black" />
          <path d="M56.7001 48.5003C56.7001 48.0003 56.3001 47.8003 55.8001 47.9003C54.9001 47.9003 54.0001 47.9003 53.2001 47.8003C52.6001 47.8003 52.0001 47.8003 51.7001 48.3003C51.0001 49.3003 51.7001 51.1003 52.4001 51.9003C53.2001 52.9003 54.5001 53.0003 55.5001 52.2003C56.6001 51.4003 56.7001 49.9003 56.7001 48.5003Z" fill="black" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="133.7" height="200" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect width="133.7" height="243.2" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default WavingLadyFrame;
