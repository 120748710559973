import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DownloadAllIcon = () => {
  return (
    <SvgIcon fontSize="large" width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="41" height="81" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5002 6.1499C21.3493 6.1499 22.0377 6.83826 22.0377 7.6874L22.0377 25.5006L26.0755 21.4627C26.6759 20.8623 27.6494 20.8623 28.2499 21.4627C28.8503 22.0632 28.8503 23.0366 28.2499 23.6371L21.5874 30.2996C20.9869 30.9 20.0134 30.9 19.413 30.2996L12.7505 23.6371C12.1501 23.0366 12.1501 22.0632 12.7505 21.4627C13.3509 20.8623 14.3244 20.8623 14.9249 21.4627L18.9627 25.5005L18.9627 7.6874C18.9627 6.83826 19.6511 6.1499 20.5002 6.1499ZM8.2002 33.3124C8.2002 32.4633 8.88856 31.7749 9.7377 31.7749H31.2627C32.1118 31.7749 32.8002 32.4633 32.8002 33.3124C32.8002 34.1615 32.1118 34.8499 31.2627 34.8499H9.7377C8.88856 34.8499 8.2002 34.1615 8.2002 33.3124Z" fill="#2A2A8B" />
    </SvgIcon>
  )
}
export default DownloadAllIcon;