import React from 'react';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DistrictFilterFragmentFragment } from '../../generated/graphql';
import { svgStyles } from '../Common/Common.styled';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Checkbox, CheckboxProps, Chip, Divider, InputAdornment } from '@mui/material';
import { palette } from '../../theme/palette';
import DistrictsIcon from '../Icons/DistrictsIcon';
import useDistrictsFilter from '../DistrictFilter/district-filter-hook';
import { SxProps } from '@mui/material';
import Loader from '../Loader/loader';

interface Props {
  error?: boolean;
  className?: string;
  selectedValue?: string | string[] | undefined;
  onChange?: (data: string[]) => void;
  disabled?: boolean;
  limitTag?: number;
}

const styles = {
  textField:{
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    '& input.MuiInputBase-input.Mui-disabled' : {
      backgroundColor: palette.customWhite.inputDisabled,
    },
  },
  box: {
    backgroundColor: palette.customWhite.main,
    height: '45px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

type DistrictValue = DistrictFilterFragmentFragment | null | undefined;

const DistrictMultiSelect = ({
  className,
  onChange,
  selectedValue,
  disabled,
  limitTag = 10,
}: Props) => {
  const {
    districts,
    loading,
  } = useDistrictsFilter({});
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [checkAll, setCheckAll] = React.useState(false);

  const onSelectChange = (districtList:(any)[], reason: AutocompleteChangeReason) => {
    if (reason === 'clear' || reason === 'removeOption') {
      setCheckAll(false);
    } else if (reason === 'selectOption' && districtList.length === districts.length) {
      setCheckAll(true);
    }
    onChange?.(districtList?.map((district:any) => district?.id));
  };

  const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      onChange?.(districts?.map((district: any) => district?.id));
    } else {
      onChange?.([]);
    }
  };

  const allDistrictsOption: DistrictFilterFragmentFragment = {
    id: 'all',
    name: ' All Districts',
    district_id: '',
    state_id: '',
    source: '',
  };

  const districtOptions = districts?.length ? [allDistrictsOption].concat(districts) : [];

  return (
    <>
      <Loader open={loading} />
      <Autocomplete
        multiple
        limitTags={limitTag}
        className={className}
        disableCloseOnSelect
        options={districtOptions}
        getOptionLabel={(option: DistrictValue) => `${`${option?.name ?? ''}`}`}
        isOptionEqualToValue={(option: DistrictValue, selected: any) => option?.id === selected?.id}
        disabled={loading || disabled}
        onChange={(_, value, reason) => onSelectChange(value, reason)}
        value={districts?.filter((district) => selectedValue?.includes(district?.id)) ?? []}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={`${ option?.name && option?.name?.length > 40 ? option?.name.substring(0, 40) + '...' : option?.name  ?? ''}`} />
          ));
        }}
        renderOption={(props, option: DistrictValue, { selected }) => {
          return option?.id === 'all' ? (
            <>
              <Box sx={{ ...styles.box } as SxProps}>
                <Checkbox
                  checked={checkAll}
                  onChange={checkAllChange}
                  id="check-all"
                  onMouseDown={(e) => e.preventDefault()}
                />
                All Districts
              </Box>
              <Divider />
            </>
          ) : (
            <Box component="li" {...props} key={option?.id}>
              <Checkbox
                color={'primary' as CheckboxProps['color']}
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
                sx={{ mr: '8px' }}
              />
              {`${option?.name ?? ''}`}
            </Box >
          );
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              sx={{ ...styles.textField }}
              placeholder={params.InputProps.startAdornment ? undefined : 'Select District/s'}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style:{ height: 'auto', overflow: 'auto', maxHeight: '500px', fontSize: '16px' },
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <DistrictsIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          </>
        )}
      />
    </>
  );
};

export default DistrictMultiSelect;
