/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckboxCheckedIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    {...props}
  >
    <path
      d="M18.75 21H2.25C1.00734 21 0 19.9927 0 18.75V2.25C0 1.00734 1.00734 0 2.25 0H18.75C19.9927 0 21 1.00734 21 2.25V18.75C21 19.9927 19.9927 21 18.75 21ZM9.15534 16.4035L17.7803 7.77848C18.0732 7.48561 18.0732 7.01072 17.7803 6.71784L16.7197 5.6572C16.4268 5.36433 15.9519 5.36428 15.659 5.6572L8.625 12.6912L5.34098 9.40716C5.04811 9.11428 4.57322 9.11428 4.2803 9.40716L3.21966 10.4678C2.92678 10.7607 2.92678 11.2356 3.21966 11.5284L8.09466 16.4034C8.38758 16.6964 8.86242 16.6964 9.15534 16.4035Z"
      fill={props.pathFill || 'white'}
    />
  </SvgIcon>
);

export default CheckboxCheckedIcon;
