import { Box, styled, Typography } from '@mui/material';
import { ScoreType } from '../../variables/types';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

export const StyledFlyoverBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  right: 0,
  backgroundColor: theme.palette.customWhite.main,
  boxShadow: theme.palette.shadow.main,
  outline: 0,
}));

export const StyledTitleTypogrpahy = styled(Typography)<{ type: string }>(({ theme ,type }) => ({
  fontSize: '21px',
  lineHeight: '29px',
  fontWeight: 800,
  textTransform: 'uppercase',
  color: type === ScoreType.emerging ? theme.palette.fontColors.fontBlack : theme.palette.customWhite.main,
}));

export const StyledSubTitleTypogrpahy = styled(Typography)<{ type: string }>(({ theme, type }) => ({
  fontSize: '16px',
  lineHeight: '22px',
  color: type === ScoreType.emerging ?theme.palette.fontColors.fontBlack : theme.palette.customWhite.main,
}));

export const StyledCloseIcon = styled(CloseIcon)<{ type: string }>(({ theme, type }) => ({
  cursor: 'pointer',
  marginRight: 0,
  marginTop: 0,
  marginLeft: 'auto',
  color: type === ScoreType.emerging ?theme.palette.fontColors.fontBlack : theme.palette.customWhite.main,
  fontSize: 28,
  transition: useTheme().transitions.create('color', {
    easing: useTheme().transitions.easing.easeIn,
    duration: useTheme().transitions.duration.shortest,
  }),
  '&:hover': {
    color: theme.palette.customBlack.lightBlack,
  },
}));

export const StyledHeaderBox = styled(Box)<{ color: string }>(({ theme, color }) => ({
  backgroundColor: color ?? 'inherit',
  padding: theme.spacing(3),
}));


