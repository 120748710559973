import { Theme } from '@mui/system';
import { palette  } from '../palette';

const Autocomplete = (theme: Theme) => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '& fieldset': {
            border: 'none',
          },
          background: palette.customWhite.main,
          borderRadius: theme.spacing(1.5),
          border: `2px solid ${palette.customBackground.disabled}`,
          borderBottomWidth: theme.spacing(0.5),
          maxHeight: theme.spacing(6),
          padding: 0,
        },
        input: {
          paddingLeft: '14px !important',
          border: 'none',
          background: palette.customWhite.main,
        },
        root:{
          '& .MuiFormControl-root .MuiInputBase-root.Mui-disabled':{
            background: palette.customWhite.whiteSmoke,
          },
          input:{
            '&.MuiInputBase-input.Mui-disabled':{
              background: palette.customWhite.main,
            },
          },
        },
      },
    },
  };
};

export default Autocomplete;

