import React from 'react';
import { Box, Typography } from '@mui/material';
import { palette } from '../../../theme/palette';

interface Props {
  label: string | React.ReactNode;
  Icon: any;
  values?: any;
}

const iconStyle = {
  icon: {
    '&.MuiSvgIcon-root': {
      height: '24px',
      width: '24px',
      marginRight: '8px',
      fill: palette.customBlue.main,
      '& path': {
        fill: palette.customBlue.primaryBlue,
      },
    },
  },
};

const AssignedToItems = ({ label, Icon, values }: Props) => {
  return (
    <>
      <Box display='flex'>
        <Icon sx={{ ...iconStyle.icon }} />
        <Box sx={{ marginLeft: '10px' }}>
          <Typography variant='sFormTitle'>{label}</Typography>
          <Typography sx={{ textTransform: 'capitalize' }}>
            {values}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AssignedToItems;
