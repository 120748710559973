import groupBy from '../../../utils/groupby';
import { ALPHABETICAL_REGEX, NUMERICAL_REGEX } from '../../../variables/constant';

const gradeSort = (data: any) => {
  const staticResourcesByGrade = groupBy(
    ['grade'],
  )(data?.staticResources ?? []);

  const getFilteredData = (regex: any) => Object.keys(staticResourcesByGrade).sort()
    .filter((key) => key.match(regex))
    .reduce((obj: any, key) => ({
      ...obj,
      [key]: staticResourcesByGrade[key],
    }), {});

  const alphabeticallyFiltered = getFilteredData(ALPHABETICAL_REGEX);
  const numericallyFiltered = getFilteredData(NUMERICAL_REGEX);
  return Object.assign(alphabeticallyFiltered, numericallyFiltered);
};

export default gradeSort;
