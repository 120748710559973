import React from 'react';
import { Box, Typography, Theme, useTheme } from '@mui/material';
import StyledCard from '../../../components/StyledCard';
import DonutChart from '../../../components/Charts/Graph/DonutChart';
import BarGraphVertical from '../../../components/Charts/Graph/BarGraphVertical';
import { ACTIVE_GOAL_TOOLTIP_TYPE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import EmptyResults from '../../../components/Icons/EmptyResults';

interface Props {
  stickyHeader?: boolean;
  loading?: boolean;
  title: string;
  maxScoreCount: number;
  studentData: any;
}

const StylesCSS = (theme: Theme) => ({
  customFlex: {
    width: '100%',
  },
  text: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
});

const Scorecard = ({
  title,
  loading,
  maxScoreCount,
  studentData,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  return (
    <StyledCard title={title} titleAlignment="left">
      {studentData?.average > 0 && !loading && (
        <Box
          width="100%"
          sx={{ ...styles.customFlex, gridGap: '8px' }}
          display="grid"
          gridTemplateColumns="1fr 3fr"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {studentData?.donutGraphData?.length && (
              <DonutChart
                data={studentData.donutGraphData}
                startAngle={90}
                endAngle={-270}
                boxWidth={200}
                chartWidth={200}
                chartHeight={200}
                boxHeight={200}
                innerRadius={70}
                outerRadius={90}
                dataKey="average"
                tooltipType={ACTIVE_GOAL_TOOLTIP_TYPE}
                totalCount={studentData.average}
                hideTooltip
                hideGoalsLabel
              />
            )}
            <Typography sx={styles.text}>Overall Score</Typography>
          </Box>
          {studentData?.barData?.length && (
            <BarGraphVertical
              data={studentData.barData}
              yAxisKey="skillLevel"
              xAxisKey="skillIcon"
              boxWidth="100%"
              yAxisDomain={[0, maxScoreCount]}
              boxHeight={300}
            />
          )}
        </Box>
      )}
      {!loading && !studentData && (
        <NoResults
          description="No scores recorded yet"
          svgComponent={<EmptyResults fillColor={theme.palette.customBackground.disabled} />}
        />
      )}
    </StyledCard>
  );
};

export default Scorecard;
