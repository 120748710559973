import React from 'react';
import { Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, BarChart, CartesianGrid } from 'recharts';
import VerticalBarLoaders from '../../Loader/VerticalBarLoaders';
import { palette } from '../../../theme/palette';
import { Box, Typography } from '@mui/material';
import {
  LearningGoalsCompletedPTsMostRecentGoalsPerMonth,
  PerformanceWideStudentCompletionRateBar,
} from '../../../generated/graphql';
import SubmissionsIcon from '../../Icons/SubmissionsIcon';
import CompletedIcon from '../../Icons/CompletedIcon';
import { getMonth } from '../CommonFunctions';
import NoResults from '../../NoResults';
import { AdminDashboards } from '../../../variables/constant';
import EmptyResults from '../../Icons/EmptyResults';

interface Props {
  loading: boolean,
  primaryLegend?: string,
  secondaryLegend?: string,
  tertiaryLegend?: string,
  barData: PerformanceWideStudentCompletionRateBar[] | LearningGoalsCompletedPTsMostRecentGoalsPerMonth[] | undefined,
  showData?: boolean,
  greyBar:string,
  blueBar:string,
  averageLine?:string
  toolTipComponent?: React.ReactElement
}

const SimpleBarChart = ({
  loading,
  primaryLegend,
  secondaryLegend,
  barData,
  showData,
  greyBar,
  blueBar,
  toolTipComponent,
}: Props) => {

  return (
    <>
      <Box height={!showData ? '400px' : 'auto'}>
        {loading && (<VerticalBarLoaders />)}
        {!loading && !showData && (
          <NoResults
            description={AdminDashboards.performance.overallPerformance.insufficientData.description}
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {/*After loading the react cycle checks for availability of data from hasData var*/}
        {showData &&
          (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: -20,
                }}
                data={barData}
              >
                <CartesianGrid strokeDasharray="0" vertical={false} />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  style={{ fontWeight: 600, fontSize: '14px', color: palette.customBlack.fontBlack }}/>
                <XAxis
                  dataKey={getMonth}
                  tickLine={false}
                  style={{ fontWeight: 600, fontSize: '14px', color: palette.customBlack.fontBlack }} />
                <Tooltip
                  content={toolTipComponent}
                  cursor={{ fill: 'transparent' }}
                  wrapperStyle={{ outline: 'none' }}
                  position={{ y:-50 }}
                />
                <Bar
                  dataKey={greyBar}
                  fill={palette.customGrey.submissionGrey}
                  radius={[4, 4, 0, 0]}
                  isAnimationActive={false}
                />
                <Bar
                  dataKey={blueBar}
                  fill={palette.customBlue.primaryBlue}
                  radius={[4, 4, 0, 0]}
                >
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )
        }
        {
          showData && !loading && (
            <Box display='flex' mt='20px' justifyContent='center'>
              <Box display='flex' mr='30px'>
                <SubmissionsIcon/>
                <Typography variant='sFormHelperText' sx={{ ml: '10px', mt: 0 }}>{primaryLegend}</Typography>
              </Box>
              <Box display='flex' mr='30px'>
                <CompletedIcon/>
                <Typography variant='sFormHelperText' sx={{ ml: '10px', mt: 0 }}>{secondaryLegend}</Typography>
              </Box>
            </Box>
          )}
      </Box>
    </>
  );
};

export default SimpleBarChart;
