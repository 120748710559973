import React from 'react';
import { Box, Typography } from '@mui/material';
import usePermission from '../../../hooks/usePermission';
import { UserPermissions } from '../../../generated/graphql';
import FileUploadEdit from './FileUploadEdit';
import ImageLibrary from '../UploadImages/ImageLibrary';


export interface ImageSelectorProp {
  value?: string,
}

const ManageImages = ({ value }: ImageSelectorProp) => {
  const { hasPermission } = usePermission(UserPermissions.UploadImage);
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      {hasPermission && (
        <>
          <Box>
            <Typography variant="pageTitle">
              Manage Images
            </Typography>
          </Box>
          <Box mt={3} sx={{
            minWidth: 350,
          }}>
            <FileUploadEdit />
          </Box>
          <Box mt={3} >
            <ImageLibrary selected={value} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ManageImages;
