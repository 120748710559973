import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { palette } from '../../../theme/palette';
import List from './List';
import MultipleClasses from './MultipleClasses';
import useRemoveStudentFromClass from './remove-student-hook';
import Loader from '../../../components/Loader/loader';
import { PrimarySectionsFilterFragment } from '../../../generated/graphql';

interface Props {
  openDialog: boolean;
  closeDialog?: (type?: string | undefined, isForm?: boolean | undefined) => void;
  studentIDs?: string[];
  refetch: () => void;
}

const RemoveStudentModal = ({ openDialog, closeDialog, studentIDs, refetch }: Props) => {
  const {
    loading,
    students,
    onSelectClass,
    selectedClasses,
    onRemoveStudent,
    removeStudentFromClass,
    sectionId,
    teacher,
  } = useRemoveStudentFromClass({ studentIDs, refetch, closeDialog });
  const singleClassName = students?.find((stu) => stu)?.section?.find((sec) => sec.id === sectionId)?.name;
  const isDisabled = !sectionId ? selectedClasses?.length : students?.length;
  const studentOrS = `${students && students?.length === 1 ? 'student' : 'students'}`;
  const isTeacherPrimary = (sections: PrimarySectionsFilterFragment[]) => sections?.some((section) => {
    return section?.teachers?.find((teacherInfo) => {
      return teacherInfo?.id === teacher?.id && teacherInfo?.primary_teacher === true;
    });
  });
  return (
    <Dialog maxWidth='md' open={openDialog} onClose={closeDialog}>
      <DialogTitle>
        <Typography sx={{ fontSize: '32px', fontWeight: 800, color: palette.customBlack.fontBlack }}>
          {!sectionId
            ? `The following ${studentOrS} ${students?.length > 1 ? 'are' : 'is'} enrolled in multiple classes.`
            : `Remove ${studentOrS} from ${singleClassName}?`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {!sectionId &&  'Please select which class(es) they should be removed from.'}
          <Typography> You will not be able to undo this action.</Typography>
        </DialogContentText>
        {!sectionId ? (
          <MultipleClasses onSelectClass={onSelectClass} students={students} selectedStudentClasses={selectedClasses} teacherID={teacher?.id} />
        ) : (
          <List onRemoveStudent={onRemoveStudent} students={students} />
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => closeDialog?.()}>
            Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={!isDisabled || (!isTeacherPrimary(students?.[0]?.section) && singleClassName)}
          onClick={() => removeStudentFromClass(sectionId!)}
        >
          Remove Student(s)
        </Button>
      </DialogActions>
      <Loader open={loading} />
    </Dialog>
  );
};

export default RemoveStudentModal;
