/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ConversationIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    {...props}
  >
    <path d="M0 2C0 0.895431 0.895431 0 2 0H9C10.1046 0 11 0.895431 11 2V6C11 7.10457 10.1046 8 9 8H7L4 11V8H2C0.895431 8 0 7.10457 0 6V2Z" fill={props.pathFill} />
    <path d="M13 4V6C13 8.20914 11.2091 10 9 10H7.82843L6.06173 11.7667C6.34154 11.9156 6.66091 12 7 12H9L12 15V12H14C15.1046 12 16 11.1046 16 10V6C16 4.89543 15.1046 4 14 4H13Z" fill={props.pathFill} />
  </SvgIcon>
);

export default React.memo(ConversationIcon);
