/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const InfoIcon = ({ pathFill, ...remainingProps } : any) => (
  <SvgIcon
    fontSize="small"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...remainingProps}
  >
    <g id="icon / info icon">
      <path d="M12 1.875C17.5604 1.875 22.125 6.37852 22.125 12C22.125 17.5918 17.5964 22.125 12 22.125C6.41044 22.125 1.875 17.5987 1.875 12C1.875 6.41236 6.40322 1.875 12 1.875ZM12 0.375C5.58014 0.375 0.375 5.58202 0.375 12C0.375 18.4217 5.58014 23.625 12 23.625C18.4199 23.625 23.625 18.4217 23.625 12C23.625 5.58202 18.4199 0.375 12 0.375ZM10.3125 16.5H10.875V10.875H10.3125C10.0019 10.875 9.75 10.6231 9.75 10.3125V9.9375C9.75 9.62686 10.0019 9.375 10.3125 9.375H12.5625C12.8731 9.375 13.125 9.62686 13.125 9.9375V16.5H13.6875C13.9981 16.5 14.25 16.7519 14.25 17.0625V17.4375C14.25 17.7481 13.9981 18 13.6875 18H10.3125C10.0019 18 9.75 17.7481 9.75 17.4375V17.0625C9.75 16.7519 10.0019 16.5 10.3125 16.5ZM12 5.25C11.1716 5.25 10.5 5.92158 10.5 6.75C10.5 7.57842 11.1716 8.25 12 8.25C12.8284 8.25 13.5 7.57842 13.5 6.75C13.5 5.92158 12.8284 5.25 12 5.25Z" fill={pathFill} />
    </g>
  </SvgIcon>
);

export default InfoIcon;
