import React, { useEffect } from 'react';
import { Box, Button, Slider, Typography } from '@mui/material';
import useAudioPlayer from '../../utils/useAudioPlayer';
import { StyledMicIcon, StyledLabelTypography } from './Player.styled';
import PauseIcon from '../Icons/PauseIcon';
import PlayIcon from '../Icons/PlayIcon';
import { SpeakIcon } from '../Icons';
import Loader from '../Loader/loader';
import AudioControls from '../AudioControls';
import AudioSliderWithSmallText, { sliderStyles } from '../AudioSliderWithSmallText';
import ArrowRight from '../Icons/ArrowRight';
import ArrowLeft from '../Icons/ArrowLeft';
import SpeakerIcon from '../Icons/SpeakerIcon';
import TransparentButtonStyled from '../TransparentButton/TransparentButton.styled';
import { palette } from '../../theme/palette';

const styles = {
  pauseButton: {
    color: palette.customPink.main,
  },
  buttonSpeak: {
    padding: 12,
    /*
     * Removed default minHeight & minWidth set on material-ui button.
     * https://stackoverflow.com/a/47191093/1682304
     */
    minHeight: 0,
    minWidth: 0,
    borderRadius: '50%',
    '&::after': {
      borderRadius: '50%',
    },
  },
  buttonSpeakDisabled: {
    backgroundColor: palette.customBackground.disabled,
    color: palette.customBackground.disabled,
    '&::after': {
      borderColor: palette.customBackground.disabled,
    },
  },
  recordingCarouselArrow: {
    '&.MuiSvgIcon-root': {
      height: '.7rem',
      minWidth: '100%',
    },
  },
  recordingCarouselTextContainer: {
    alignItems: 'baseline',
    gridTemplateColumns: '10% 20% 85px 20% 10%',
    justifyItems: 'center',
  },
  recordingCarouselIconContainer: {
    minWidth: '64px',
  },
};

interface Props {
  url?: string;
  fileType?: string;
  showLabel?: boolean;
  label?: string;
  setIsPlaying?: Function;
  disablePlay?: boolean;
  buttonOnly?: boolean;
  flexStructure?: boolean;
  onPlaying?: (id: string) => void;
  playerId?: string;
  active?: boolean;
  singlePlayer?: boolean;
  backpackPlayer?: boolean;
  customStyle?: boolean;
  recordingCount: number;
  currentRecordingFileIndex: number;
  handleCurrentRecordingChange: Function;
}

const AudioPlayerWithVolumeControl = ({
  url,
  showLabel = false,
  label,
  setIsPlaying,
  disablePlay = false,
  buttonOnly = false,
  flexStructure = false,
  onPlaying,
  playerId,
  active,
  singlePlayer,
  backpackPlayer,
  customStyle,
  recordingCount,
  currentRecordingFileIndex,
  handleCurrentRecordingChange,
}: Props) => {
  const { playing, togglePlaying, duration, currentTime, setTime, isLoading, currentVolume, setCurrentVolume } =
    useAudioPlayer({ url, onPlaying, active, playerId });

  // TODO - check with the slider debounce effect
  const handleAudioSliderChange = (event: React.SyntheticEvent | Event, time: number | Array<number>) => {
    setTime(time as number);
  };

  const handleVolumeChange = (event: React.SyntheticEvent | Event, time: number | number[]) => {
    setCurrentVolume((time as number) / 10);
  };

  const handleCarouselToggle = (value: number) => {
    if (playing) {
      togglePlaying?.();
    }
    handleCurrentRecordingChange(currentRecordingFileIndex + value);
    setTime(0);
  };

  useEffect(() => {
    setIsPlaying?.(playing);
  }, [playing, setIsPlaying]);

  return (
    <Box>
      <Loader open={isLoading} />
      {!buttonOnly && !flexStructure && !singlePlayer && !backpackPlayer && (
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box display="grid" gridTemplateColumns="8% 40% 10% 1fr" width="100%" ml={1}>
            {/* Volume Control */}
            <Box>
              <SpeakerIcon path={{ fill: palette.customBlue.primaryBlue }} />
            </Box>
            <Box>
              <Slider
                value={(currentVolume ?? 0) * 10}
                min={0}
                max={10}
                onChangeCommitted={handleVolumeChange}
                sx={{ ...sliderStyles.sliderControls }}
                aria-label="Volume"
              />
            </Box>
            <Box gridColumn="4">
              <AudioControls disabled={disablePlay} onClick={togglePlaying} playing={playing} />
            </Box>
          </Box>

          <Box width="100%">
            <AudioSliderWithSmallText
              currentTime={currentTime}
              customStyle={customStyle}
              onChangeCommitted={handleAudioSliderChange}
              max={duration}
            />
          </Box>

          <Box display="grid" justifyContent="center" width="100%" sx={{ ...styles.recordingCarouselTextContainer }}>
            <Box gridColumn="2" sx={{ ...styles.recordingCarouselIconContainer }}>
              {currentRecordingFileIndex > 0 && (
                <TransparentButtonStyled aria-label="Previous Recording" onClick={() => handleCarouselToggle(-1)}>
                  <ArrowLeft
                    sx={{ ...styles.recordingCarouselArrow }}
                    path={{ fill: palette.customGrey.lightShedGrey }}
                  />
                </TransparentButtonStyled>
              )}
            </Box>
            <Box>
              <Typography variant="sliderTimer" sx={{ textAlign: 'center', fontSize: '15px' }}>
                Recording {currentRecordingFileIndex + 1}
              </Typography>
            </Box>
            <Box sx={{ ...styles.recordingCarouselIconContainer }}>
              {currentRecordingFileIndex + 1 < recordingCount && (
                <TransparentButtonStyled aria-label="Next Recording" onClick={() => handleCarouselToggle(1)}>
                  <ArrowRight
                    sx={{ ...styles.recordingCarouselArrow }}
                    path={{ fill: palette.customGrey.lightShedGrey }}
                  />
                </TransparentButtonStyled>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {buttonOnly && (
        <Button
          sx={{ ...styles.buttonSpeak, ...(playing && styles.buttonSpeakDisabled) }}
          variant="outlined"
          color="secondary"
          disabled={playing}
          onClick={togglePlaying}
        >
          <SpeakIcon pathFill={playing ? palette.customBackground.disabled : palette.customYellow.secondaryDark} />
        </Button>
      )}
      {flexStructure && (
        <>
          <Box>
            {showLabel && <StyledLabelTypography>{`${label ?? 'Recording'}`}</StyledLabelTypography>}
            <Box width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <AudioControls disabled={disablePlay} onClick={togglePlaying} playing={playing} />
              {playing && (
                <AudioSliderWithSmallText
                  currentTime={currentTime}
                  onChangeCommitted={handleAudioSliderChange}
                  max={duration}
                />
              )}
            </Box>
          </Box>
        </>
      )}
      {singlePlayer && (
        <Box display="flex" flexDirection="column">
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            {showLabel && <StyledLabelTypography>{`${label ?? 'Recording'}`}</StyledLabelTypography>}

            <StyledMicIcon variant="contained" color="primary" disabled={disablePlay} onClick={togglePlaying}>
              {playing ? <PauseIcon /> : <PlayIcon />}
            </StyledMicIcon>
          </Box>
        </Box>
      )}
      {singlePlayer && (
        <AudioSliderWithSmallText
          currentTime={currentTime}
          onChangeCommitted={handleAudioSliderChange}
          max={duration}
        />
      )}
      {backpackPlayer && (
        <Box width="100%" display="flex" justifyContent="space-between" flexDirection="column">
          {showLabel && <StyledLabelTypography>{`${label ?? 'Recording'}`}</StyledLabelTypography>}
          <Box
            display="grid"
            gridTemplateColumns={customStyle ? '0.1fr 6fr' : '1fr 6fr'}
            sx={{
              gridGap: 8,
            }}
            alignItems="center"
            alignContent="center"
            width="100%"
            mt={customStyle ? 2 : 3}
          >
            <AudioControls disabled={disablePlay} onClick={togglePlaying} playing={playing} />
            <AudioSliderWithSmallText
              customStyle={customStyle}
              currentTime={currentTime}
              onChangeCommitted={handleAudioSliderChange}
              max={duration}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default React.memo(AudioPlayerWithVolumeControl);
