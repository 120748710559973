import { styled, Tabs, Tab, Theme } from '@mui/material';
import zIndex from '../../theme/z-index';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '&.MuiTabs-root': {
    border: theme.spacing(0),
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const StyledGoalsTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    '&.MuiButtonBase-root': {
      marginRight: theme.spacing(2),
      border: `2px solid ${theme.palette.customBackground.disabled}`,
      borderBottom: 0,
      borderRadius: theme.spacing(1.25, 1.25, 0, 0),
      color: theme.palette.fontColors.fontBlack,
      textTransform: 'none',
      backgroundColor: theme.palette.customGrey.main,
    },
    '& .tag': {
      borderRadius: '45%',
      color: theme.palette.fontColors.fontBlack,
      width: theme.spacing(4.375),
      display: 'inline-block',
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.customWhite.main,
    },
    '&.vocab_and_grammar': {
      '& .tag': {
        backgroundColor: theme.palette.customBlue.activeGoalVocabsAndGrammar,
        color: theme.palette.fontColors.fontBlack,
      },
    },
    '&.points_and_reasons': {
      '& .tag': {
        backgroundColor: theme.palette.customPink.activeGoalPointsAndReason,
        color: theme.palette.fontColors.fontBlack,
      },
    },
    '&.description_and_explanation': {
      '& .tag': {
        backgroundColor: theme.palette.customBlue.activeGoalDescriptionAndExplanation,
        color: theme.palette.customWhite.main,
      },
    },
    '&.grammar': {
      '& .tag': {
        backgroundColor: theme.palette.customGreen.activeGoalGrammar,
        color: theme.palette.fontColors.fontBlack,
      },
    },
    '&.vocabulary': {
      '& .tag': {
        backgroundColor: theme.palette.customBlue.activeGoalVocabulary,
        color: theme.palette.customWhite.main,
      },
    },
    '&.pronunciation': {
      '& .tag': {
        backgroundColor: theme.palette.customGrey.lightVinyl,
        color: theme.palette.fontColors.fontBlack,
      },
    },
    '&.interpretation': {
      '& .tag': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.customWhite.main,
      },
    },
    '&.fluency': {
      '& .tag': {
        backgroundColor: theme.palette.customGreen.activeGoalFluency,
      },
    },
    '&.all': {
      '& .tag': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.customWhite.main,
      },
    },
    '&.Mui-selected': {
      '&.MuiButtonBase-root': {
        color: theme.palette.customWhite.main,
      },
      '& .tag': {
        backgroundColor: `${theme.palette.customWhite.main} !important`,
        color: `${theme.palette.fontColors.fontBlack} !important`,
      },
      '&.vocab_and_grammar': {
        borderColor: theme.palette.customBlue.activeGoalVocabsAndGrammar,
        backgroundColor: theme.palette.customBlue.activeGoalVocabsAndGrammar,
        color: theme.palette.fontColors.fontBlack,
      },
      '&.points_and_reasons': {
        borderColor: theme.palette.customPink.activeGoalPointsAndReason,
        backgroundColor: theme.palette.customPink.activeGoalPointsAndReason,
        color: theme.palette.fontColors.fontBlack,
      },
      '&.description_and_explanation': {
        borderColor: theme.palette.customBlue.activeGoalDescriptionAndExplanation,
        backgroundColor: theme.palette.customBlue.activeGoalDescriptionAndExplanation,
      },
      '&.grammar': {
        borderColor: theme.palette.customGreen.activeGoalGrammar,
        backgroundColor: theme.palette.customGreen.activeGoalGrammar,
        color: theme.palette.fontColors.fontBlack,
      },
      '&.vocabulary': {
        borderColor: theme.palette.customBlue.activeGoalVocabulary,
        backgroundColor: theme.palette.customBlue.activeGoalVocabulary,
      },

      '&.pronunciation': {
        borderColor: theme.palette.customGrey.lightVinyl,
        backgroundColor: theme.palette.customGrey.lightVinyl,
        color: theme.palette.fontColors.fontBlack,
      },
      '&.interpretation': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
      '&.fluency': {
        borderColor: theme.palette.customGreen.activeGoalFluency,
        backgroundColor: theme.palette.customGreen.activeGoalFluency,
      },
      '&.all': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export const StyledPerformanceDetailsTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    '&.MuiButtonBase-root': {
      marginRight: theme.spacing(2),
      border: `2px solid ${theme.palette.customBackground.disabled}`,
      borderBottom: 0,
      borderRadius: theme.spacing(1.25, 1.25, 0, 0),
      color: theme.palette.fontColors.fontBlack,
      textTransform: 'none',
    },
    '& .tag': {
      borderRadius: '45%',
      color: theme.palette.customWhite.main,
      width: theme.spacing(4.375),
      display: 'inline-block',
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
    },
    '&.limited, &.beginning': {
      '& .tag': {
        backgroundColor: theme.palette.customRed.buttonFontRed,
      },
    },
    '&.emerging, &.intermediate': {
      '& .tag': {
        backgroundColor: theme.palette.customYellow.main,
        color: theme.palette.fontColors.fontBlack,
      },
    },
    '&.expanding, &.texas_advanced': {
      '& .tag': {
        backgroundColor: theme.palette.fontColors.buttonFontGreen,
      },
    },
    '&.bridging, &.advanced_high': {
      '& .tag': {
        backgroundColor: theme.palette.customBlue.aquaBlue,
      },
    },
    '&.advanced': {
      '& .tag': {
        backgroundColor: theme.palette.customBlue.cornflowerBlue,
      },
    },
    '&.Mui-selected': {
      '&.MuiButtonBase-root': {
        color: theme.palette.customWhite.main,
      },
      '& .tag': {
        backgroundColor: `${theme.palette.customWhite.main} !important`,
        color: `${theme.palette.fontColors.fontBlack} !important`,
      },
      '&.limited, &.beginning': {
        borderColor: theme.palette.customRed.buttonFontRed,
        backgroundColor: theme.palette.customRed.buttonFontRed,
      },
      '&.emerging, &.intermediate': {
        borderColor: theme.palette.customYellow.main,
        backgroundColor: theme.palette.customYellow.main,
        color: theme.palette.fontColors.fontBlack,
      },
      '&.expanding, &.texas_advanced': {
        borderColor: theme.palette.fontColors.buttonFontGreen,
        backgroundColor: theme.palette.fontColors.buttonFontGreen,
      },
      '&.bridging, &.advanced_high': {
        borderColor: theme.palette.customBlue.aquaBlue,
        backgroundColor: theme.palette.customBlue.aquaBlue,
      },
      '&.advanced': {
        borderColor: theme.palette.overlays.cornflowerBlue,
        backgroundColor: theme.palette.overlays.cornflowerBlue,
      },
    },
  },
}));

export const AnalyticsStylesCSS = (theme: Theme) => ({
  stickyHeader: {
    position: 'sticky',
    backgroundColor: theme.palette.customWhite.main,
    width: '100%',
    marginTop: theme.spacing(-2),
    top: 0,
    paddingTop: theme.spacing(2),
    zIndex: zIndex.zIndex9,
    borderBottom: `1 solid ${theme.palette.customBackground.disabled}`
  },
  container: {
    width: '100%',
  },
  dropContent: {
    backgroundColor: theme.palette.customWhite.main,
    minHeight: theme.spacing(25),
    maxHeight: theme.spacing(100),
    minWidth: theme.spacing(100),
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(5),
  },
  tabStyle: { px: 3.25, py: 1.5 },
});
