import React from 'react';
import { jsPDF } from 'jspdf';
import { Stack } from '@mui/material';
import { StyledIconButton } from '../../Assignments/Assignments.styled';
import DownloadFileIcon from '../../../components/Icons/DownloadFileIcon';
import PrintIcon from '../../../components/Icons/PrintIcon';
import { palette } from '../../../theme/palette';
import { colors, extractTitle, extractWidget, getPdfTextWidth, renderChips } from '../../../utils/pdf';
import { openSnackbar } from '../../../components/Notifier';
import { sentryErrorLog } from '../../../utils/sentry';
import {
  PDF_GENERATING_ERROR,
  StudentBenchmarkDashBoard,
  TeacherBenchmarkStudentGrowthReportLegendData,
} from '../../../variables/constant';
import { NotifierType } from '../../../variables/types';
import { useIndividualStudentBenchmarksDashboardContext } from './individual-student-dashboard-hook';
import { trackEvent } from '../../../utils/trackevent';

interface ExportToPdfProps {
  benchmarkType: string,
  studentName: string,
  lastSnowflakeUpdateTimeData: any
}

const ExportToPdf = ({
  benchmarkType,
  studentName,
  lastSnowflakeUpdateTimeData,
}: ExportToPdfProps) => {
  let pdfName = '';
  let dashboard = 'Individual Student Dashboard';
  const { areWidgetsLoading, isDataMissingInAllWidgets, loading, setLoading } = useIndividualStudentBenchmarksDashboardContext();
  
  const renderFilterChips = (pdf: jsPDF, posX: number, posY: number, pdfPadding: number) => {
    let fontSize = 8;
    let height = 15;
    let defaultChipProps = {
      pdf: pdf, fontSize,
      x: posX, y: posY,
      rx: 8, ry: 8,
      height,
      backgroundColor: colors.bgGrey,
      textColor: colors.fntBlack,
    };
    const chips = [(benchmarkType as string).toUpperCase()];
    renderChips(pdf, posX, posY, pdfPadding, chips, defaultChipProps, fontSize);
  };


  const renderLegend = (pdf: jsPDF, posX: number, posY: number, legendData: any[]) => {
    let textWidth = 0;
    legendData.forEach(item => {
      const fontSize = 6;
      const circleRadius = 2.5;
      textWidth = getPdfTextWidth(item.skillTitle, pdf, fontSize);
      pdf.setFillColor(item.color);
      pdf.circle(posX + circleRadius * 2, posY - circleRadius, circleRadius, 'F');
      pdf.setFontSize(fontSize);
      pdf.setTextColor('#333');
      pdf.text(item.skillTitle, posX + circleRadius * 4, posY - 1);
      posX += textWidth + circleRadius * 6;
    });
  };

  const generatePdf = async (print: boolean = false) => {
    try {
      setLoading?.(true);
      const pdfPadding = 10;
      const pdfMargin = 20;
      const adjustHeight = 50;
      let posX = pdfMargin;
      let posY = pdfPadding + 5;
      let elHeight = 0;
      let elWidth = 0;
      const pdfDoc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = pdfDoc.internal.pageSize.getWidth();
      let headerText = 'Individual Student Data Dashboard';
      let dashboardTitle = studentName;
      let dateString1: string = '';
      [posX, posY, dateString1] = await extractTitle(pdfDoc, posX, posY, pdfPadding, pdfMargin, headerText, dashboardTitle, lastSnowflakeUpdateTimeData);

      posY += 2 * pdfPadding + 5;
      posX = pdfMargin + 5;
      [posX, posY, elHeight, elWidth] = await extractWidget(
        pdfDoc, posX, posY, pdfPadding, 
        StudentBenchmarkDashBoard.StudentGrowthReportWidget.name, 
        (2 * pageWidth) / 3.4, pdfMargin, adjustHeight, -5, ['#student-growth-report-legend']);
      const posYforNextWidget = posY;
      posX = pdfMargin + elWidth / 11 - 12; // Default positioning for legend
      posY += elHeight + 2 * pdfMargin - 8 ;
      renderLegend(pdfDoc, posX, posY, TeacherBenchmarkStudentGrowthReportLegendData as any[]);

      posX = elWidth + pdfMargin + 15;
      posY = posYforNextWidget;
      [posX, posY, elHeight, elWidth] = await extractWidget(
        pdfDoc, posX, posY, pdfPadding,
        StudentBenchmarkDashBoard.BenchmarkScoresWidget.name, 
        pageWidth / 2.4, pdfMargin, adjustHeight, pdfMargin);

      posX = pdfMargin + 5;
      posY += elHeight + adjustHeight + 15;
      renderFilterChips(pdfDoc, posX, posY, pdfPadding);

      posX = pdfMargin + 5;
      posY += pdfMargin + 5;
      [posX, posY, elHeight, elWidth] = await extractWidget(
        pdfDoc, posX, posY, pdfPadding, 
        StudentBenchmarkDashBoard.SpeakingBenchmarkIndicatorPerformaneWidget.name,
        pageWidth, pdfMargin, pdfMargin + 20, -10);

      posX = pdfMargin + 5;
      posY += elHeight + 55;
      [posX, posY, elHeight, elWidth] = await extractWidget(
        pdfDoc, posX, posY, pdfPadding, 
        StudentBenchmarkDashBoard.WritingBenchmarkIndicatorPerformaneWidget.name,
        pageWidth, pdfMargin, pdfMargin + 20, -10);

      pdfName = `${headerText}-${studentName}-${dateString1}`;
      pdfDoc.setProperties({
        title: pdfName,
      });
      if (print) {
        pdfDoc.autoPrint({ variant: 'non-conform' });
        window.open(pdfDoc.output('bloburl'), '_blank');
      } else {
        pdfDoc.save(pdfName);
      }

      setLoading?.(false);
    } catch (e) {

      setLoading?.(false);
      sentryErrorLog(e, [
        { label: 'pdfErr', value: `Error Generating PDF for ${dashboard}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  };
  const generatePdfCustomEvent = (print: boolean) => {

    if (print) {
      trackEvent('tbm_in_st_print', 'individual_student_dashboard_pdf_print');
    } else {
      trackEvent('tbm_in_st_download', 'individual_student_dashboard_pdf_download');
    }

  };
  const isDisabled = areWidgetsLoading || isDataMissingInAllWidgets || loading;
  return (
    <Stack direction="row" justifyContent="end" marginRight={-1}  >
      <StyledIconButton
        id='download-pdf-button-individual-student-benchmark-data-dashboard'
        color="primary"
        onClick={() => {
          generatePdfCustomEvent(false);
          generatePdf(false);
        }}
        className="print-ignore"
        disabled={isDisabled}
      >
        <DownloadFileIcon id='download-pdf-button-individual-student-benchmark-data-dashboard-icon' fillColor={isDisabled ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
      <StyledIconButton
        id='print-pdf-button-individual-student-benchmark-data-dashboard'
        color="primary"
        onClick={() => {
          generatePdfCustomEvent(true);
          generatePdf(true);
        }}
        className="print-ignore"
        disabled={isDisabled}
      >
        <PrintIcon id='print-pdf-button-individual-student-benchmark-data-dashboard-icon' fillColor={isDisabled ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
    </Stack >

  );
};

export default ExportToPdf;