import { useEffect, useState } from 'react';
import { pieChartData } from '../overallperformance.hooks';
import { useTeacherBenchmarksDashboardContext } from '../../../teacherbenchmarks-dashboard-hook';
import {
  BenchmarkType,
  OrderBy,
  OverallPerformanceLevelSlideOutInput,
  OverallPerformanceLevelSlideOutLineItem,
  OverallPerformanceLevelSlideOutSortType,
  SubmissionType,
  useOverallPerformanceLevelSlideOutQuery,
} from '../../../../../generated/graphql';
import { trackEvent } from '../../../../../utils/trackevent';
import { SLIDEOUT_ROWS } from '../../../../../variables/constant';


type Props = {
  data: pieChartData | undefined,
  benchmarkType?: BenchmarkType,
  submissionType?: string,
};
const usePerformanceLevelSlideOut = ({ data, benchmarkType, submissionType }: Props) => {
  const [selectedOption, setSelectedOption] = useState(data?.scoreLevelId || 'all');
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(OrderBy.Asc);
  const [sort, setSort] = useState<OverallPerformanceLevelSlideOutSortType>(OverallPerformanceLevelSlideOutSortType.StudentFullName);
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const handleChange = (event: any) => {
    const label = (event.target.labels[0].innerText).toLowerCase();
    trackEvent(`rb_${submissionType?.toLowerCase()}_pl_${label}`, `radio_buttons_${submissionType?.toLowerCase()}_performace_level_${label}_click`);
    setSelectedOption(event.target.value); 
  };
  const [slideOutData, setSlideOutData] = useState<OverallPerformanceLevelSlideOutLineItem[] | null>();
  const { selectedGrades, selectedClasses, selectedSchools, skipGraphQLRequest } = useTeacherBenchmarksDashboardContext();
  const [totalCount, setTotalCount] = useState<number>(0);
  var queryInput: OverallPerformanceLevelSlideOutInput = {
    benchmark_type: benchmarkType!,
    section_ids: selectedClasses ? selectedClasses : [],
    school_ids: selectedSchools ? selectedSchools : [],
    grade_ids: selectedGrades ? selectedGrades : [],
    score_level_id: selectedOption === 'all' ? undefined : selectedOption,
    sort_by: sort,
    order_by: order as OrderBy,
    submission_type: submissionType as SubmissionType,
    limit: SLIDEOUT_ROWS,
    page: page,
  };
  const { data: overallPerformanceLevelSlideOutData, loading: overallPerformanceLevelSlideOutDataLoading, refetch: refetchOverallPerfomanceLevelSlideOutData } = useOverallPerformanceLevelSlideOutQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });
  useEffect(() => {
    setSlideOutData([]);
    setPage(1);
    refetchOverallPerfomanceLevelSlideOutData();
  }, [selectedOption, order, sort]);

  useEffect(() => {
    if (!overallPerformanceLevelSlideOutDataLoading && overallPerformanceLevelSlideOutData) {
      setTotalCount(overallPerformanceLevelSlideOutData?.overallPerformanceLevelSlideOut?.total_count || 0);
      if (slideOutData && slideOutData?.length > 0) {
        setSlideOutData([...slideOutData, ...overallPerformanceLevelSlideOutData?.overallPerformanceLevelSlideOut?.slideout_line_items]);
      } else {
        setSlideOutData(overallPerformanceLevelSlideOutData?.overallPerformanceLevelSlideOut?.slideout_line_items);
      }
    }
    setIsTableEmpty(overallPerformanceLevelSlideOutData?.overallPerformanceLevelSlideOut?.total_count === 0);
  }, [overallPerformanceLevelSlideOutData, overallPerformanceLevelSlideOutDataLoading]);


  const onSortChange = (sortBy: OverallPerformanceLevelSlideOutSortType, orderBy: OrderBy) => {
    setSlideOutData([]);
    setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handleSort = (column: OverallPerformanceLevelSlideOutSortType) => () => {
    if (column === queryInput.sort_by) {
      onSortChange(column, queryInput.order_by === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  return {
    selectedOption,
    setSelectedOption,
    handleChange,
    order, setOrder,
    overallPerformanceLevelSlideOutData,
    overallPerformanceLevelSlideOutDataLoading,
    slideOutData: slideOutData || [],
    isTableEmpty,
    sort,
    handleSort,
    totalCount,
    page,
    setPage,
  };
};
export default usePerformanceLevelSlideOut;