import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../components/Layout';
import DistrictSyncTable from './DistrictSyncTable';
import RoutingPaths from '../RoutingPath';

const DistrictSync = () => (
  <Layout>
    <Route
      path={RoutingPaths.DistrictSync}
      component={DistrictSyncTable}
      exact
    />
  </Layout>
);

export default DistrictSync;
