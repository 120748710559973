/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const ScoreIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    {...props}
  >
    <path d="M0.507937 11H2.53968C2.82032 11 3.04762 11.2238 3.04762 11.5V15.5C3.04762 15.7762 2.82032 16 2.53968 16H0.507937C0.227302 16 0 15.7762 0 15.5V11.5C0 11.2238 0.227302 11 0.507937 11Z" fill={palette.customWhite.main} />
    <path d="M15.746 6H17.7778C18.0584 6 18.2857 6.22375 18.2857 6.5V15.5C18.2857 15.7762 18.0584 16 17.7778 16H15.746C15.4654 16 15.2381 15.7762 15.2381 15.5V6.5C15.2381 6.22375 15.4654 6 15.746 6Z" fill={palette.customWhite.main} />
    <path d="M16.7619 3C16.467 3 16.1943 2.91375 15.961 2.77125L13.1165 5.01094C13.1708 5.165 13.2063 5.32781 13.2063 5.5C13.2063 6.32844 12.5241 7 11.6825 7C10.841 7 10.1587 6.32844 10.1587 5.5C10.1587 5.32781 10.1943 5.165 10.2486 5.01094L7.40413 2.77094C7.17079 2.91375 6.8981 3 6.60317 3C6.36825 3 6.14857 2.94344 5.94984 2.85L2.89524 5.85687C2.99016 6.0525 3.04762 6.26875 3.04762 6.5C3.04762 7.32844 2.3654 8 1.52381 8C0.682222 8 0 7.32844 0 6.5C0 5.67156 0.682222 5 1.52381 5C1.75873 5 1.97841 5.05656 2.17714 5.15L5.23175 2.14313C5.13683 1.9475 5.07937 1.73125 5.07937 1.5C5.07937 0.671562 5.76159 0 6.60317 0C7.44476 0 8.12698 0.671562 8.12698 1.5C8.12698 1.67219 8.09143 1.835 8.03714 1.98875L10.8816 4.22875C11.1149 4.08594 11.3876 3.99969 11.6825 3.99969C11.9775 3.99969 12.2502 4.08594 12.4835 4.22875L15.3279 1.98875C15.2737 1.83469 15.2381 1.67219 15.2381 1.5C15.2381 0.671562 15.9203 0 16.7619 0C17.6035 0 18.2857 0.671562 18.2857 1.5C18.2857 2.32844 17.6035 3 16.7619 3Z" fill={palette.customWhite.main} />
    <path d="M5.5873 6H7.61905C7.89968 6 8.12698 6.22375 8.12698 6.5V15.5C8.12698 15.7762 7.89968 16 7.61905 16H5.5873C5.30667 16 5.07937 15.7762 5.07937 15.5V6.5C5.07937 6.22375 5.30667 6 5.5873 6Z" fill={palette.customWhite.main} />
    <path d="M10.6667 10H12.6984C12.979 10 13.2063 10.2237 13.2063 10.5V15.5C13.2063 15.7762 12.979 16 12.6984 16H10.6667C10.386 16 10.1587 15.7762 10.1587 15.5V10.5C10.1587 10.2237 10.386 10 10.6667 10Z" fill={palette.customWhite.main} />
  </SvgIcon>
);

export default React.memo(ScoreIcon);
