import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import useAudioPlayer from '../../utils/useAudioPlayer';
import { StyledMicIcon } from './Player.styled';
import PauseIcon from '../Icons/PauseIcon';
import PlayIcon from '../Icons/PlayIcon';
import Loader from '../Loader/loader';
import DeleteIcon, { DeleteIconNoBorder } from '../Icons/DeleteIcon';
import { palette  } from '../../theme/palette';

interface Props {
  url?: string;
  fileType?: string;
  setIsPlaying?: Function;
  disablePlay?: boolean;
  disableDelete?: boolean;
  onPlaying?: (id: string) => void;
  playerId?: string;
  active?: boolean;
  onDelete?: () => Promise<void>;
  buttonHeight?: string,
  buttonWidth?: string,
  deleteIconNoBorder?: boolean,
}

const styles = {
  deleteButton: {
    backgroundColor: palette.customPink.main,
    color: palette.customWhite.main,
    '&:hover': {
      backgroundColor: palette.customPink.darkPink,
      color: palette.customWhite.main,
    },
    '&.MuiButton-root::after':{
      backgroundColor: palette.customPink.main,
    },
  },
};

const MiniPlayerWithDeleteButton = ({
  url,
  setIsPlaying,
  disablePlay = false,
  disableDelete = false,
  onPlaying,
  playerId,
  active,
  onDelete,
  buttonHeight = '55px',
  buttonWidth = '55px',
  deleteIconNoBorder = false,
}: Props) => {
  const { playing, togglePlaying, isLoading } = useAudioPlayer({ url, onPlaying, active, playerId });

  useEffect(() => {
    setIsPlaying?.(playing);
  }, [playing, setIsPlaying]);

  return (
    <Box display="flex">
      <Loader open={isLoading} />
      <Box>
        <StyledMicIcon
          variant="contained"
          sx={{
            buttonWidth: { buttonWidth },
            buttonHeight: { buttonHeight },
          }}
          color="primary"
          disabled={disablePlay}
          onClick={togglePlaying}
        >
          {playing ? (<PauseIcon />) : <PlayIcon />}
        </StyledMicIcon>
      </Box>
      {
        !disableDelete && (
          <Box ml={2.5}>
            <StyledMicIcon
              variant="contained"
              sx = {{ 
                ...styles.deleteButton,
                buttonWidth: { buttonWidth },
                buttonHeight: { buttonHeight },
              }}
              onClick={onDelete}
            >
              {!deleteIconNoBorder ? <DeleteIcon /> : <DeleteIconNoBorder />}
            </StyledMicIcon>
          </Box>
        )
      }
    </Box>
  );
};
export default React.memo(MiniPlayerWithDeleteButton);
