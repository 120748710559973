import React from 'react';
import { Box, Typography } from '@mui/material';
import { palette } from '../../../../theme/palette';

import { ACTIVE_GOAL_TOOLTIP_TYPE, TeacherBenchmarkDashBoard } from '../../../../variables/constant';
import StyledCard from '../../../../components/StyledCard';
import AppTooltip from '../../../../components/AppTooltip';
import DonutChartLoader from '../../../../components/Loader/DonutChartLoader';
import NoResults from '../../../../components/NoResults';
import useOverallPerformanceLevel, { pieChartData } from './overallperformance.hooks';
import { BenchmarkType } from '../../../../generated/graphql';
import OverallPerformanceDonutChart from '../../../../components/Charts/Graph/OverallPerformanceDonutChart';
import EmptyBenchmarkResults from '../../../../components/Icons/EmptyBenchmarksResults';
import MyLegend from './Legend';
import FlyoverDrawer from '../../../../components/FlyoverDrawer';
import FlyoverHeader from '../../../../components/FlyoverHeader';
import FlyoverContainer from '../../../../components/FlyoverContainer';
import PerformanceSlideOut from './PerformanceSlideOut';

interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  benchmarkType?: BenchmarkType,
}

const OverallPerformanceWidget = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
  benchmarkType,
}: Props) => {
  const title = 'Overall Performance Level';
  const {
    speakingPieChartData,
    overallPerformancelevelDataLoading,
    hasData,
    speakingAvgScore,
    writingAvgScore,
    writingPieChartData,
    showFlyover,
    toggleFlyover,
    openFlyover,
    flyOverTitle,
    flyOverData, 
    legendData,
  } = useOverallPerformanceLevel({ selectedBenchmarkType: benchmarkType });

  return (
    <Box id={TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.name} sx={{ height: '100%', width: '100%' }}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        fullHeight
        marginBottom='0px'
      >
        <Box
          width="100%"
          sx={{ gridRowGap: '8px', gridColumnGap: '32px', paddingLeft: '6px', paddingRight: '6px' }}
          display="grid"
          gridTemplateColumns="1fr 1fr"
        >
          {hasData && !overallPerformancelevelDataLoading && speakingPieChartData?.length && (<Box display="flex" alignItems="end" paddingTop="24px">
            <Typography align="left" sx={{
              fontSize: '18px',
              fontWeight: 600,
              color: palette.customBlack.fontBlack,
            }} >
              {'Speaking'}
            </Typography>
          </Box>)}

          {hasData && !overallPerformancelevelDataLoading && writingPieChartData?.length && <Box display="flex" alignItems="end" paddingTop="24px">
            <Typography align="left" sx={{
              fontSize: '18px',
              fontWeight: 600,
              color: palette.customBlack.fontBlack,
            }}>
              {'Writing'}
            </Typography>
          </Box>}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"

          >
            {!overallPerformancelevelDataLoading && hasData && speakingPieChartData?.length && (
              <>
                <StyledCard overridePadding={16}>
                  <OverallPerformanceDonutChart
                    data={speakingPieChartData!}
                    startAngle={90}
                    endAngle={-270}
                    boxWidth={400}
                    chartWidth={400}
                    chartHeight={350}
                    boxHeight={350}
                    dataKey="studentCount"
                    totalCount={speakingAvgScore}
                    label
                    innerLabel='Overall Score'
                    sectorLabel='Students'
                    skillType='Speaking'
                    onLinkClick={openFlyover}
                    showPointerCursor={true}
                    paddingAngle={1.5}
                  />
                </StyledCard>
              </>
            )}
          </Box>
          {!overallPerformancelevelDataLoading && hasData && writingPieChartData?.length && (
            <>
              <StyledCard overridePadding={16}>
                <OverallPerformanceDonutChart
                  data={writingPieChartData!}
                  startAngle={90}
                  endAngle={-270}
                  boxWidth={400}
                  chartWidth={400}
                  chartHeight={350}
                  boxHeight={350}
                  dataKey="studentCount"
                  tooltipType={ACTIVE_GOAL_TOOLTIP_TYPE}
                  totalCount={writingAvgScore}
                  label
                  innerLabel='Overall Score'
                  sectorLabel='Students'
                  skillType='Writing'
                  onLinkClick={openFlyover}
                  showPointerCursor={true}
                  paddingAngle={1.5}
                />

              </StyledCard>
            </>
          )}

        </Box>
        {hasData && !overallPerformancelevelDataLoading && !!legendData && (<Box width="100%" marginBottom='20px' marginTop='24px'  ><MyLegend data={legendData} id={'overall-performace-level-legend'} /></Box>)}
        {!overallPerformancelevelDataLoading && !hasData && (
          <NoResults
            description={TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.insufficientData.description}
            svgComponent={<EmptyBenchmarkResults />}
          />
        )}
        {overallPerformancelevelDataLoading && <DonutChartLoader />}
      </StyledCard>
      <FlyoverDrawer open={showFlyover} size={'small'} onClose={toggleFlyover}>
        <FlyoverHeader
          title={flyOverTitle}
          onClose={toggleFlyover}
          subtitle={'Overall Performance Scores'}
        />
        <FlyoverContainer>
          <PerformanceSlideOut data={flyOverData} benchmarkType={benchmarkType} submissionType={flyOverTitle} />
        </FlyoverContainer>
      </FlyoverDrawer>

    </Box>
  );
};

export default OverallPerformanceWidget;
