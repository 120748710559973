import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryParam } from 'use-query-params';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';
import StudentsIcon from '../../../components/Icons/StudentsIcon';
import {
  usePredefinedGradesListQuery,
  useStudentEditFormQuery,
  PredefinedGradeFragmentFragment,
  Maybe,
  SchoolPredefinedGradeMappings,
  useDistrictDetailsQuery,
  useSchoolPredefinedGradesMappingQuery,
  SchoolFilterFragmentFragment,
  useCreateStudentFormMutation,
  useUpdateStudentFormMutation,
} from '../../../generated/graphql';
import sortGradeMapping from '../../../utils/sortGradeMapping';
import {
  HERITAGE_LANGUAGE_FREQUENT_OPTIONS,
  HERITAGE_LANGUAGE_OTHER_VALUE,
} from '../../Teachers/TeacherForm/constants';

type StudentFormData = {
  id: string
  state_id: string
  district_id: string
  school_id: string
  section_ids: string[]
  predefined_grade_id: string
  first_name: string
  last_name: string
  student_id: string
  password: string
  email: string
  heritage_language: string
  language_level: string
  heritage_other_language: string
  demographic_info: string
};

interface RouteProp {
  id: string
}

const useStudentForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
    clearErrors,
  } = useForm<StudentFormData>();
  const [districtParam] = useQueryParam('districtId');
  const [stateParam] = useQueryParam('stateId');
  const [schoolParam] = useQueryParam('schoolId');

  const [createStudentForm, { loading: createLoading } ] = useCreateStudentFormMutation();
  const [updateStudentForm, { loading: updateLoading } ] = useUpdateStudentFormMutation();
  const history = useHistory();

  const handleCase = (elemName: string) => (event: any) => {
    const content = event.target.value.toString().trim();

    if (content !== ''){
      setValue(elemName, content.toLowerCase());
    }
  };

  const [checked, setChecked] = useState(false);
  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setChecked(event.target.checked);
  };

  const [addClass, setAddClass] = useState(false);
  const addClassNode = () => {
    setAddClass(!addClass);
  };

  const [editPassword, setEditPassword] = useState(false);
  const editPasswordToggle = () => {
    setEditPassword(!editPassword);
  };

  const heritageLanguageOptions = useMemo(() => HERITAGE_LANGUAGE_FREQUENT_OPTIONS, []);

  const onStateChange = () => {
    setValue('district_id', null);
    setValue('school_id', null);
    setValue('section_ids', null);
  };

  const onDistrictChange = (stateId?: string | null) => {
    setValue('school_id', null);
    setValue('section_ids', null);
    if (stateId) {
      setValue('state_id', stateId);
      clearErrors('state_id');
    }
  };

  const onSchoolChange = (schoolFragment?: SchoolFilterFragmentFragment | null) => {
    setValue('section_ids', null);
    setValue('predefined_grade_id', null);
    if (schoolFragment?.district_id) {
      setValue('district_id', schoolFragment.district_id);
      clearErrors('district_id');
    }
    if (schoolFragment?.district_state_id) {
      setValue('state_id', schoolFragment.district_state_id);
      clearErrors('state_id');
    }
  };

  const { id } = useParams<RouteProp>();

  if (id) {
    register('id', { required: true });
  }

  const { data: preDefinedGrades, loading: preDefinedGradesLoading } = usePredefinedGradesListQuery({
    fetchPolicy: 'network-only',
  });

  const { data: studentDetails, loading: studentDetailsLoading } = useStudentEditFormQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id!,
    },
    skip: !id,
  });

  useEffect(() => {
    if (stateParam) {
      setValue('state_id', stateParam);
    }
  }, [setValue, stateParam]);

  useEffect(() => {
    if (districtParam) {
      setValue('district_id', districtParam);
    }
  }, [setValue, districtParam]);

  useEffect(() => {
    if (schoolParam) {
      setValue('school_id', schoolParam);
    }
  }, [setValue, schoolParam]);

  useEffect(() => {
    if (studentDetails) {
      const student = studentDetails?.viewStudent;
      const heritageLanguageValue = heritageLanguageOptions.find((item) => item.value === student?.heritage_language);

      reset({
        id: student?.id,
        district_id: student?.district_id,
        state_id: student?.state_id ?? '',
        school_id: student?.school_id,
        section_ids: student?.sections.map((section) => section.id),
        predefined_grade_id: student && student?.grade
          ? preDefinedGrades?.allPredefinedGrades?.find((preGrade: PredefinedGradeFragmentFragment) => preGrade?.grade === student?.grade)?.id as string : '',
        first_name: student?.first_name,
        last_name: student?.last_name,
        student_id: student?.student_id,
        email: student?.email || undefined,
        heritage_language: (student?.heritage_language) ? ((!heritageLanguageValue) ? HERITAGE_LANGUAGE_OTHER_VALUE : student?.heritage_language) : undefined,
        heritage_other_language: (!heritageLanguageValue) ? (student?.heritage_language || '') : '',
        demographic_info: student?.demographic_info || undefined,
        language_level: student?.language_level || undefined,
      });
    }
  }, [getValues, heritageLanguageOptions, id, preDefinedGrades, reset, studentDetails]);
  const create = async (student: StudentFormData) => {
    const studentName = `${student.first_name} ${student.last_name}`;
    const successMessage = `${studentName} student was ${student.id ? 'updated' : 'added'}!`;
    const editPasswordMessage = 'New password has been created.';
    const inputData = {
      id: student.id,
      district_id: student.district_id,
      school_id: student.school_id,
      section_ids: student.section_ids,
      predefined_grade_id: student.predefined_grade_id,
      demographic_info: student.demographic_info,
      language_level: student.language_level,
      first_name: student.first_name.trim(),
      last_name: student.last_name.trim(),
      student_id: student.student_id?.trim()?.toLowerCase(),
      email: student.email?.trim()?.toLowerCase(),
      heritage_language: (student.heritage_language === HERITAGE_LANGUAGE_OTHER_VALUE) ? student.heritage_other_language : student.heritage_language,
      password: student.password,
    };
    try {
      let response;
      if (inputData.id != null) {
        response = await updateStudentForm({
          variables: {
            input: inputData,
          },
        });
      } else {
        response = await createStudentForm({
          variables: {
            input: inputData,
          },
        });
      }
      const responseData = response.data;
      const studentData = inputData.id ? responseData?.updateStudent : responseData?.createStudent;
      const studentDetailsPath = `/admin-students/${studentData}`;
      if (checked) {
        reset({
          id: '',
          state_id: student.state_id,
          district_id: student.district_id,
          school_id: student.school_id,
          section_ids: student.section_ids,
          predefined_grade_id: '',
          demographic_info: '',
          language_level: '',
          first_name: '',
          last_name: '',
          student_id: '',
          password: '',
          email: '',
          heritage_language: '',
          heritage_other_language: '',
        });
      } else {
        history.push(studentDetailsPath);
      }
      openSnackbar({
        message: successMessage,
        customIcon: StudentsIcon,
        actionButtonText: 'View',
        onActionButtonClick: () => { history.push(studentDetailsPath); },
      }, NotifierType.Success);
      if (editPassword) {
        openSnackbar({
          message: editPasswordMessage,
          customIcon: StudentsIcon,
        }, NotifierType.Success);
        editPasswordToggle();
      }
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const selectedStateId = watch('state_id');
  const selectedDistrictId = watch('district_id');
  const selectedSchoolId = watch('school_id');
  const selectedClassIds = watch('section_ids');
  const heritageLanguage = watch('heritage_language');

  const { data: schoolsPredefinedGrades, loading: schoolPredefinedGradesLoading } = useSchoolPredefinedGradesMappingQuery({
    fetchPolicy: 'network-only',
    variables: {
      schoolID: selectedSchoolId!,
    },
    skip: !selectedSchoolId,
  });

  // Replaced useSchoolEditDetailsQuery with useDistrictDetailsQuery to get district source
  const { data: sourceDetails, loading: sourceDetailsLoading } = useDistrictDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: selectedDistrictId!,
    },
    skip: !selectedDistrictId,
  });

  const getPredefinedGradesForSchool = useCallback(() => sortGradeMapping([...schoolsPredefinedGrades?.schoolPredefinedGrades ?? []] as Maybe<SchoolPredefinedGradeMappings>[])?.map((schoolPredefinedGrade) => schoolPredefinedGrade?.predefined_grades), [schoolsPredefinedGrades]);

  return {
    loading: createLoading
      || updateLoading
      || studentDetailsLoading
      || preDefinedGradesLoading
      || schoolPredefinedGradesLoading
      || sourceDetailsLoading,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    createStudent: create,
    checked,
    handleChange,
    onStateChange,
    onDistrictChange,
    onSchoolChange,
    selectedStateId,
    selectedDistrictId,
    selectedSchoolId,
    selectedClassIds,
    preDefinedGrades: getPredefinedGradesForSchool(),
    addClassNode,
    addClass,
    heritageLanguageOptions,
    heritageLanguage,
    editPasswordToggle,
    editPassword,
    source : sourceDetails?.districtDetails?.source,
    customSource: studentDetails?.viewStudent?.source,
    handleCase,
    studentID: id,
  };
};

export default useStudentForm;
