import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Grid, Checkbox, TextField, Typography, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { CreateAssignmentStylesCss } from '../../../../Assignments/CreateAssignment/CreateAssignment.styled';
import { StepProp } from '../../../../Assignments/CreateAssignment/create-assignment-hook';
import useCreateBenchmark from '../../create-benchmark-hook';
import { CreateBenchmarkSteps } from '../../../../../variables/constant';
import FormProgressBar from '../../../../../components/FormProgressBar';
import PromptInput from '../../../../Assignments/CreateAssignment/Steps/PromptInput';
import { RecordingTracker } from '../../../../Assignments/CreateAssignment/create-assignment-hook';
import { StyledDangerButton, StyledNavigationButtonOne, StyledNextButton } from '../../../../Assignments/Assignments.styled';
import AssignmentNavigationModal from '../../../../Assignments/CreateAssignment/Steps/AssignmentNavigationModal';

export type UploadPromptReponse = {
  response: any,
  error: boolean,
  message: string,
};

const InstructionsStep = ({ title }: StepProp) => {
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);
  const [deleteWritingPrompt, setDeleteWritingPrompt] = useState<boolean>(false);

  const {
    routeData,
    benchmarkInput,
    isPastBenchmark,
    backToBenchmarks,
    handlePrev,
    staticResourceImgSelected,
    recordingTracker,
    setRecordingTracker,
    updateBenchmarkContext,
    titleState,
    setTitleState,
    speakingPrompt,
    setSpeakingPrompt,
    writingPrompt,
    setWritingPrompt,
    submitStep,
    setWritingPromptFileBM,
    setSpeakingPromptFileBM,
    openNavigationModal,
    setOpenNavigationModal,
  } = useCreateBenchmark();

  const {
    handleSubmit,
    errors,
    control,
    watch,
    getValues,
    clearErrors,
  } = useFormContext();

  const isCommonPrompt = watch('is_common_prompt', benchmarkInput ? benchmarkInput.is_common_prompt : true);

  const isRecordingEnabled = () => recordingTracker?.some((record: RecordingTracker) => record.isRecording);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.formatTopNavBar}>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Typography variant='secondaryTitle'>{title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormProgressBar steps={ CreateBenchmarkSteps } progressValue={routeData?.currentStep!} />
          </Grid>
        </Grid>
      </Box>
      <form
        name="benchmark-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(submitStep)}
      >
        <Box>
          <Grid container item xs={12}>
            <Grid item xs={5}>
              <Box sx={styles.formItemContainer}>
                <Box m={theme.spacing(2, 0, 1, 0)}>
                  <Typography variant='sFormTitle'>Title</Typography>
                </Box>
                <Controller
                  name="title"
                  defaultValue={benchmarkInput?.title}
                  value={benchmarkInput?.title}
                  render={({ onChange }) => (
                    <TextField
                      id="benchmark-title"
                      aria-describedby="benchmark-title"
                      name="title"
                      fullWidth
                      sx={{ ...styles.inputInput }}
                      defaultValue={titleState}
                      value={titleState}
                      onChange={(e) => {
                        clearErrors('title');
                        onChange(e);
                        setTitleState(e.target.value);
                      }}
                    />
                  )}
                  rules={{
                    validate: (value) => (value.trim().length > 0 || titleState.trim().length > 0) || 'Please enter the benchmark title',
                  }}
                  control={control}
                />
                <Typography variant='sFormHelper'>{errors.title && errors.title.message}</Typography>
              </Box>
              <Box sx={styles.formItemContainer}>
                <Box m={theme.spacing(0, 0, 1, 0)}>
                  <Typography variant="sFormTitle">{isCommonPrompt ? 'Speaking and Writing Prompt' : 'Speaking Prompt'}</Typography>
                </Box>
                <Box m={theme.spacing(1, 0, 1, 0)}>
                  <Typography variant="sFormHelperText">
                    Students will see this prompt during the
                    {' '}
                    {isCommonPrompt ? 'Speaking and Writing Prompt' : 'Speaking Prompt'}
                    {' '}
                    portions of the benchmark.
                  </Typography>
                </Box>
                <PromptInput
                  promptType="speaking"
                  isCommonPrompt={isCommonPrompt}
                  promptState={speakingPrompt}
                  setPromptState={setSpeakingPrompt}
                  isPastPT={isPastBenchmark!}
                  contextInput={benchmarkInput!}
                  updateContextInput={updateBenchmarkContext!}
                  setSpeakingPromptFile={setSpeakingPromptFileBM}
                  setWritingPromptFile={setWritingPromptFileBM}
                  recordingTracker={recordingTracker!}
                  setRecordingTracker={setRecordingTracker!}
                />
              </Box>
              {!isCommonPrompt && (
                <>
                  <Box sx={styles.formItemContainer}>
                    <Box m={theme.spacing(2, 0, 1, 0)}>
                      <Typography variant="sFormTitle">Writing Prompt</Typography>
                    </Box>
                    <Box m={theme.spacing(1, 0, 1, 0)}>
                      <Typography variant="sFormHelperText">
                        Students will see this prompt during the Writing Prompt portions of the benchmark.
                      </Typography>
                    </Box>
                    <PromptInput
                      promptType="writing"
                      isCommonPrompt={isCommonPrompt}
                      promptState={writingPrompt}
                      setPromptState={setWritingPrompt}
                      isPastPT={isPastBenchmark!}
                      contextInput={benchmarkInput!}
                      updateContextInput={updateBenchmarkContext!}
                      setSpeakingPromptFile={setSpeakingPromptFileBM}
                      setWritingPromptFile={setWritingPromptFileBM}
                      recordingTracker={recordingTracker!}
                      setRecordingTracker={setRecordingTracker!}
                    />
                  </Box>
                </>
              )}
              <Box display="flex" sx={{ ...styles.formItemContainer, display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <Controller
                    name="is_common_prompt"
                    defaultValue={!isCommonPrompt}
                    value={!isCommonPrompt}
                    render={({ onChange, value }) => (
                      <Checkbox
                        name="is_common_prompt"
                        checked={!value}
                        onChange={(e) => {
                          onChange(!e.target.checked);
                          setDeleteWritingPrompt(false);
                          updateBenchmarkContext({
                            is_common_prompt: !e.target.checked,
                          });
                        }}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={isRecordingEnabled() }
                        sx={{ ml: -1 }}
                      />
                    )}
                    rules={{
                      validate: ((value: boolean) => {
                        if (value && (benchmarkInput?.writing_prompt || benchmarkInput?.writing_prompt_url) && !deleteWritingPrompt) {
                          setDeleteWritingPrompt(true);
                          return 'Are you sure you do not want a separate writing prompt for your student? Your writing prompt will not be saved!';
                        }
                        return true;
                      }),
                    }}
                    control={control}
                  />
                  <Typography variant='sFormHelperText'>
                    Create a separate writing prompt.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='sFormHelper'>
                    {errors.is_common_prompt && errors.is_common_prompt.message}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box sx={styles.formImageContainer}>
                <CardMedia
                  image={staticResourceImgSelected?.file?.url!}
                  component="img"
                  sx={{ borderRadius: 4 }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={styles.benchmarkFormBottomNavBar as SxProps}>
            <Box>
              <StyledNavigationButtonOne
                sx={styles.saveButton}
                variant="outlined"
                color="primary"
                disabled={isRecordingEnabled()}
                onClick={() => { handlePrev(getValues()); }}
              >
                Back
              </StyledNavigationButtonOne>
            </Box>
            <Box>
              <StyledDangerButton
                variant="outlined"
                color="primary"
                sx={{ mr: 2 }}
                disabled={isRecordingEnabled()}
                onClick={() => setOpenNavigationModal(true)}
              >
                Cancel
              </StyledDangerButton>
              <StyledNextButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={isRecordingEnabled()}
              >
                  Next
              </StyledNextButton>
            </Box>
          </Box>
        </Box>
      </form>
      <AssignmentNavigationModal openModal={openNavigationModal} handleClose={() => { setOpenNavigationModal(false); }} handleNext={() => { backToBenchmarks(); }} isBenchmarksPage={true} />
    </Box>
  );
};

export default InstructionsStep;
