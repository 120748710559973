import React from 'react';
import { Box, Typography } from '@mui/material';
import { StudentBenchmarkDashBoard } from '../../../../variables/constant';
import StyledCard from '../../../../components/StyledCard';
import AppTooltip from '../../../../components/AppTooltip';
import DonutChartLoader from '../../../../components/Loader/DonutChartLoader';
import NoResults from '../../../../components/NoResults';
import EmptyBenchmarkResults from '../../../../components/Icons/EmptyBenchmarksResults';
import useBenchmarkIndicatorPerformanceWidget from './benchmark-indicator-performance-hook';
import ScoringRubric from '../../../../components/ScoringRubricChart';
import { palette } from '../../../../theme/palette';
import BenchmarkIndicatorPerformanceChart
  from '../../../../components/BenchmarkIndicatorPerformanceGraph/BenchmarkIndicatorPerformace';
import { BenchmarkType, SubmissionType } from '../../../../generated/graphql';

interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  benchmarkType: BenchmarkType,
}

const WritingIndicatorPerformanceWidget = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
  benchmarkType,
}: Props) => {
  const title = 'Benchmark Indicator Performance: Individual vs. Grade Level';
  const {
    barChartData,
    stackedBarData,
    stackedBarDataColor,
    scoreSchemes,
    yAxisDomain,
    yAxisTicks,
    scoreSchemeType,
    individualVsGradeLevelInfoDataLoader,
  } = useBenchmarkIndicatorPerformanceWidget(SubmissionType.Writing, benchmarkType);


  return (
    <Box id={StudentBenchmarkDashBoard.WritingBenchmarkIndicatorPerformaneWidget.name} sx={{ height: '100%', width: '100%' }}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        fullHeight
        marginBottom='0px'
      >
        {barChartData && !individualVsGradeLevelInfoDataLoader && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <Box>
              <BenchmarkIndicatorPerformanceChart
                submissionType={SubmissionType.Writing}
                barData={barChartData}
                xAxisKey='rubricTitle'
                barKey1='rubricScoreAverage'
                barKey2='rubricDistrictGradeScoreAverage'
                barWidth1={500}
                barWidth2={650}
                barHeight={350}
                barSize1={60}
                barSize2={45}
                yAxisDomain={yAxisDomain}
                barRadius={[4, 4, 0, 0]}
                yAxisTicks={yAxisTicks} />
            </Box>
            <Box sx={{ marginLeft: '0px', display: 'flex', flexDirection: 'column' }}>
              <Typography
                paddingBottom={2}
                paddingTop={2}
                padding={2}
                sx={{
                  fontSize: '18px',
                  fontWeight: 600,
                  color: palette.customBlack.fontBlack,
                }}>Scoring Rubric</Typography>
              <ScoringRubric
                stackedBarData={stackedBarData}
                stackedBarDataColor={stackedBarDataColor}
                scoreSchemes={scoreSchemes}
                yAxisDomain={yAxisDomain}
                scoreSchemeType={scoreSchemeType}
              />
            </Box>
          </Box>
        )}
        {!individualVsGradeLevelInfoDataLoader && !barChartData && (
          <NoResults
            description={StudentBenchmarkDashBoard.SpeakingBenchmarkIndicatorPerformaneWidget.insufficientData.description}
            svgComponent={<EmptyBenchmarkResults />}
          />
        )}
        {individualVsGradeLevelInfoDataLoader && <DonutChartLoader />}
      </StyledCard>

    </Box>
  );
};

export default WritingIndicatorPerformanceWidget;
