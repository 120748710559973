import React, { useContext } from 'react';
import {
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledTableDataColumn } from '../../../Admin/admin-tables-styled';
import { DistrictWideSchoolSpecificDetails } from '../../../../generated/graphql';
import { DistrictWideComparisonsContext } from '../district-wide-comparisons-hook';
import { DistrictWideComparisonsStylesCSS } from '../DistrictWideComparisons.styled';
import toRound from '../../../../utils/toRound';

interface Props {
  rowIndex: number,
  teacher: DistrictWideSchoolSpecificDetails,
}

const SchoolSpecificDetailRow = ({
  rowIndex,
  teacher,
}: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);

  const {
    getTagStyleClass,
  } = useContext(DistrictWideComparisonsContext);

  const avgSpeakingScore = toRound(teacher?.avg_speaking_score, 1);
  const avgWritingScore = toRound(teacher?.avg_writing_score, 1);

  return (
    <TableRow key={rowIndex} sx={{
      ...styles.tableRowHover, ...styles.tableRowBorder,
    }}>
      <TableCell sx={{ ...styles.width35, textAlign: 'left' }}>
        <Typography
          variant='secondaryLabel'
        >
          {teacher?.teacher}
        </Typography>
      </TableCell>
      <StyledTableDataColumn sx={styles.width10}>
        <Typography
          variant='secondaryLabel'
        >
          {teacher?.grade}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width35}>
        <Typography
          variant='secondaryLabel'
        >
          {teacher?.section}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width10}>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getTagStyleClass?.(avgSpeakingScore) }}
        >
          {avgSpeakingScore}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width10}>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getTagStyleClass?.(avgWritingScore) }}
        >
          {avgWritingScore}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default SchoolSpecificDetailRow;
