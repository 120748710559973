import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckmarkHollowIcon from '../../../components/Icons/CheckmarkHollowIcon';
import { StyledContainer5, StyledStudentViewButton, StylesCss } from '../ScoreTask.styled';
import Loader from '../../../components/Loader/loader';
import CreateGoal from '../../Goals';
import { StudentGoalListNode } from '../../../generated/graphql';
import { Skills } from '../../../variables/types';
import AddIcon from '../../../components/Icons/AddIcon';

interface IGoalsRowButtonProps {
  buttonTitle: string,
  buttonStyles?: string,
  isDisabled?: boolean,
}
interface IGoalsRowProps {
  title: string,
  titleStyles?: Object,
  subTitle?: string,
  subTitleStyles?: Object,
  containerStyles?: Object,
  buttonProps: IGoalsRowButtonProps,
  goal?: StudentGoalListNode[],
  loading: boolean,
  onSuccess?: Function,
}

const GoalsRow = ({
  title,
  titleStyles,
  subTitle,
  subTitleStyles,
  containerStyles,
  buttonProps: {
    buttonTitle,
    buttonStyles,
    isDisabled,
  },
  goal,
  loading,
  onSuccess,
}: IGoalsRowProps) => {
  const theme = useTheme();
  const Styles = StylesCss(theme);
  const titleStylesCSS = (goal: string | any[]) => ({
    marginRight: (goal && goal?.length > 0) ? theme.spacing(1.25) : 0,
    display: 'flex',
  });

  const [selectedType, setSelectedType] = useState<Skills | undefined>();

  const handleSuccess = () => {
    setSelectedType(undefined);
    onSuccess?.();
  };

  return (
    <StyledContainer5 sx={containerStyles}>
      {!!selectedType && (
        <CreateGoal
          openDialog={!!selectedType}
          closeDialog={() => setSelectedType(undefined)}
          header={`New ${title ?? ''}`}
          goalType={selectedType}
          onSuccess={handleSuccess}
          activeGoal={goal && goal.length > 0 ? goal : undefined}
        />
      )}
      <Loader open={loading} />
      <Box>
        <Box sx={Styles.titleContainer}>
          {goal && goal?.length < 1 && (
            <Typography
              variant='sContentHeader'
              sx={{ ...titleStylesCSS(goal), ...titleStyles }}
            >
              <AddIcon />
              <Typography
                variant='sContentHeader'
                sx={{ marginLeft: theme.spacing(1.5), marginTop: '-5px' }}
              >
                {`${goal && goal?.length < 1 ? 'Create New Goal ' : ''}`}
              </Typography>
            </Typography>
          )}
          {
            goal && goal?.length > 0
            && (
              <Box sx={Styles.checkMarkIconContainer}>
                <Typography variant='sContentHeader'>
                  {title}
                </Typography>
                <CheckmarkHollowIcon sx={Styles.checkmarkHollowStyles} />
              </Box>
            )
          }
        </Box>
        {
          subTitle && (
            <Typography
              variant='sLabel'
              sx={{ ...Styles.scoringSubHeader, ...subTitleStyles }}
              component='p'
            >
              {subTitle}
            </Typography>
          )
        }
        {
          goal && goal?.length > 0 && (
            <>
              <Typography variant='sLabel' sx={Styles.exampleStyle} component='p'>
                {goal?.[0]?.predefined_goal_description}
              </Typography>
              <Divider />
              <Typography variant='sLabel' sx={Styles.exampleStyle} component='p'>
                {goal?.[0]?.predefined_goal_example}
              </Typography>
            </>
          )
        }
      </Box>
      <Box>
        <StyledStudentViewButton
          size="large"
          variant="contained"
          color="primary"
          className={buttonStyles}
          disabled={isDisabled}
          onClick={() => setSelectedType(title === 'Speaking Goal' ? Skills.Speaking : Skills.Writing)}
        >
          {buttonTitle}
        </StyledStudentViewButton>
      </Box>
    </StyledContainer5>
  );
};

export default React.memo(GoalsRow);
