import React from 'react';
import { Box } from '@mui/material';
import { GoalContainer } from './GoalSection.styled';
import ScoringCard from '../../pages/ScoreTask/Common/ScoringCard';
import GoalsCard from '../../pages/ScoreTask/Goals/GoalsCard';

interface Props {
  goalTitle: string
}
const GoalSection = ({ goalTitle }: Props) => (
  <Box>
    <GoalContainer>
      <Box>
        <ScoringCard taskType={goalTitle} />
        <GoalsCard taskType={goalTitle} />
      </Box>
    </GoalContainer>
  </Box>
);

export default GoalSection;
