import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AnalyticsTabs,
  GoalSetGraphData,
  Skills,
  SpeakingSkills,
  WritingSkills,
} from '../../../variables/types';
import useRedirect from '../../../hooks/useRedirect';
import { ALL_SKILLS } from '../../../variables/constant';
import useOverview, { GoalGraphArray } from '../Overview/overview-hook';

interface RouteProp {
  skillKey?: SpeakingSkills | WritingSkills;
}

interface Props {
  section: string;
}

const getIndexBySection = (skillKey?: SpeakingSkills | WritingSkills) => {
  switch (skillKey) {
    case SpeakingSkills.Pronunciation:
    case WritingSkills.VocabAndGrammar:
      return 1;
    case SpeakingSkills.Interpretation:
    case WritingSkills.PointsAndReason:
      return 2;
    case SpeakingSkills.Vocabulary:
    case WritingSkills.DescriptionAndExplanation:
      return 3;
    case SpeakingSkills.Grammar:
      return 4;
    case SpeakingSkills.Fluency:
      return 5;
    default:
      return 0;
  }
};

const useGoals = ({ section }: Props) => {
  const { skillKey } = useParams<RouteProp>();
  const [tab, setTab] = useState(getIndexBySection(skillKey));
  const { navigateTo, search } = useRedirect();

  const {
    activeWritingGoalData,
    activeSpeakingGoalData,
    activeSpeakingGoalCount,
    activeWritingGoalCount,
    goalLoading,
  } = useOverview();

  useEffect(() => {
    setTab(getIndexBySection(skillKey));
  }, [skillKey]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    if (section === AnalyticsTabs.SpeakingGoals) {
      switch (newValue) {
        case 1:
          navigateTo(`/analytics/${section}/${SpeakingSkills.Pronunciation}`);
          return;
        case 2:
          navigateTo(`/analytics/${section}/${SpeakingSkills.Interpretation}`);
          return;
        case 3:
          navigateTo(`/analytics/${section}/${SpeakingSkills.Vocabulary}`);
          return;
        case 4:
          navigateTo(`/analytics/${section}/${SpeakingSkills.Grammar}`);
          return;
        case 5:
          navigateTo(`/analytics/${section}/${SpeakingSkills.Fluency}`);
          return;
        case 0:
        default:
          navigateTo(`/analytics/${section}/${ALL_SKILLS}`);
      }
    } else if (section === AnalyticsTabs.WritingGoals) {
      switch (newValue) {
        case 1:
          navigateTo(`/analytics/${section}/${WritingSkills.VocabAndGrammar}`);
          return;
        case 2:
          navigateTo(`/analytics/${section}/${WritingSkills.PointsAndReason}`);
          return;
        case 3:
          navigateTo(`/analytics/${section}/${WritingSkills.DescriptionAndExplanation}`);
          return;
        case 0:
        default:
          navigateTo(`/analytics/${section}/${ALL_SKILLS}`);
      }
    }
  };

  const navigateToTab = (data: GoalSetGraphData | null) => {
    navigateTo(`/analytics/${section}/${data?.linkText ?? ''}`);
  };

  activeSpeakingGoalData?.sort((first:GoalGraphArray, second:GoalGraphArray) => getIndexBySection(first?.skill_key as SpeakingSkills) - getIndexBySection(second?.skill_key as SpeakingSkills));

  activeWritingGoalData?.sort((first:GoalGraphArray, second:GoalGraphArray) => getIndexBySection(first?.skill_key as WritingSkills) - getIndexBySection(second?.skill_key as WritingSkills));
  return ({
    tab,
    setTab,
    activeGoalData: section === AnalyticsTabs.SpeakingGoals ? activeSpeakingGoalData : activeWritingGoalData,
    activeGoalCount: section === AnalyticsTabs.SpeakingGoals ? activeSpeakingGoalCount : activeWritingGoalCount,
    loading: goalLoading,
    skill: section === AnalyticsTabs.SpeakingGoals ? Skills.Speaking : Skills.Writing,
    handleChange,
    search,
    navigateToTab,
  });
};

export default useGoals;
