import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import zIndex from '../../../theme/z-index';

export const FixedHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  backgroundColor: theme.palette.customWhite,
  width: '100%',
  marginTop: -theme.spacing(2),
  top: 0,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  zIndex: zIndex.zIndex99,
}));
