import React from 'react';
import { Box } from '@mui/material';
import { Global, StyledBoxYTicks, StyledSpanXTick, StyledSpanYTick, styles } from './CommonStyles';

const LineChartLoader = () => {
  return (
    <Box sx={{ ...styles.container }}>
      <Global/>
      <Box
        sx={{ ...styles.main }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            sx={{ ...styles.fakeLines }}
          >
            <StyledBoxYTicks/>
            <StyledBoxYTicks/>
            <StyledBoxYTicks/>
            <StyledBoxYTicks/>
            <StyledBoxYTicks/>
            <StyledBoxYTicks/>
            <StyledBoxYTicks/>
          </Box>
          <Box sx={{ ...styles.yAxis, ...styles.fadify }}>
            <StyledSpanYTick sx={{ ...styles.delay1 }}/>
            <StyledSpanYTick sx={{ ...styles.delay2 }}/>
            <StyledSpanYTick sx={{ ...styles.delay3 }}/>
            <StyledSpanYTick sx={{ ...styles.delay4 }}/>
            <StyledSpanYTick sx={{ ...styles.delay5 }}/>
            <StyledSpanYTick sx={{ ...styles.delay5 }}/>
            <StyledSpanYTick sx={{ ...styles.delay5 }}/>
          </Box>
        </Box>
        <Box sx={{ ...styles.sectionWave }}>
          <svg
            width="100%"
            height="100%"
            viewBox="100 0 1000 100"
            version="1.1"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
            >
              <path
                style={{ ...styles.dash }}
                d="M16,16 C76.235,16 77.932,77 138.167,77 C198.402,77 198.402,16 260.333,16 C320.568,16 322.265,77 382.5,77 C442.735,77 442.735,16 504.667,16 C564.902,16 566.598,77 626.833,77 C687.068,77 687.068,16 749,16 C809.235,16 810.932,77 871.167,77 C931.402,77 931.402,16 993.333,16 C1053.568,16 1055.265,77 1115.5,77 C1175.735,77 1175.735,16 1237.667,16 C1297.902,16 1299.598,77 1359.833,77 C1420.068,77 1420.068,16 1482,16 C1542.235,16 1543.932,77 1605.864,77 C1666.099,77 1666.099,16 1728.031,16 C1788.266,16 1789.963,77 1851.895,77 C1912.13,77 1912.13,16 1974.062,16 C2034.297,16"
                id="wave"
                strokeWidth="2"
              />
            </g>
          </svg>
        </Box>
        <Box sx={{ ...styles.fadify, ...styles.xAxis }}>
          <StyledSpanXTick sx={{ ...styles.delay1 }}/>
          <StyledSpanXTick sx={{ ...styles.delay2 }}/>
          <StyledSpanXTick sx={{ ...styles.delay3 }}/>
          <StyledSpanXTick sx={{ ...styles.delay4 }}/>
          <StyledSpanXTick sx={{ ...styles.delay5 }}/>
          <StyledSpanXTick sx={{ ...styles.delay1 }}/>
          <StyledSpanXTick sx={{ ...styles.delay2 }}/>
          <StyledSpanXTick sx={{ ...styles.delay3 }}/>
          <StyledSpanXTick sx={{ ...styles.delay4 }}/>
          <StyledSpanXTick sx={{ ...styles.delay5 }}/>
          <StyledSpanXTick sx={{ ...styles.delay1 }}/>
          <StyledSpanXTick sx={{ ...styles.delay2 }}/>
          <StyledSpanXTick sx={{ ...styles.delay3 }}/>
        </Box>
      </Box>
    </Box>
  );
};
export default LineChartLoader;
