import { styled, TableCell } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    borderRight: `4px solid ${theme.palette.customBackground.disabled}`,
    borderLeft: `4px solid ${theme.palette.customBackground.disabled}`,
    width: '250px',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.1px',
    verticalAlign: 'top',
    paddingTop: theme.spacing(4),
    whiteSpace: 'pre-wrap',
  },
}));
