import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, ServerError, Operation } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Cache } from 'aws-amplify';
import { deleteToken, getJWTToken, isExpired } from '../utils/auth';
import meQuery from '../utils/meQuery';
import { PUBLIC } from '../variables/constant';
import { openSnackbar } from '../components/Notifier';
import { NotifierType } from '../variables/types';

const isPrivateRequest = (op: Operation) => op.getContext().clientName !== PUBLIC;

const requestLink = (imitateUserId?: string) => setContext(async () => {
  const token = await getJWTToken();
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      ...(
        imitateUserId && token ? {
          ImpersonatorId: imitateUserId,
        } : {}
      ),
    },
  };
});

const client = (imitateUserId?: string) => new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ));
      }
      if ((networkError as ServerError)?.statusCode === 401) {
        openSnackbar({ message: 'Unauthorized Access' }, NotifierType.Error);
        deleteToken();
      }
    }),
    ApolloLink.split((op) => isPrivateRequest(op),
      requestLink(imitateUserId).concat(new HttpLink({
        uri: `${import.meta.env.REACT_APP_API_URL}/graphql`,
      })), new HttpLink({
        uri: `${import.meta.env.REACT_APP_API_URL}/public/graphql`,
      })),
  ]),
  cache: new InMemoryCache(),
});

export const refreshAuthentication = async (imitateUserId?: string) => {
  try {
    const token = await getJWTToken();
    if (isExpired(token)) {
      Cache.removeItem('session');
      Cache.removeItem('user');
      return;
    }
    const { data: userInfo } = await client(imitateUserId).query({ query: meQuery });
    const user = userInfo?.me;
    Cache.setItem('user', user);
  } catch (error) {
    Cache.removeItem('user');
  }
};
export default client;
