import React from 'react';
import StyledCard from '../../../../components/StyledCard';
import NoResults from '../../../../components/NoResults';
import EmptyResults from '../../../../components/Icons/EmptyResults';
import DonutChartLoader from '../../../../components/Loader/DonutChartLoader';
import AnalyticDonutChart from '../../../../components/Charts/Graph/AnalyticDonutChart';
import { palette  } from '../../../../theme/palette';
import AppTooltip from '../../../../components/AppTooltip';
import useGoalsByIndicatorWidget, { Props } from './goals-by-indicator-widget-hook';
import { ACTIVE_GOAL_TOOLTIP_TYPE, AdminDashboards } from '../../../../variables/constant';
import { Box } from '@mui/system';

const GoalsByIndicatorWidget = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
}: Props) => {
  const {
    totalCount, hasData,
    barGraphData,
    goalsByIndicatorWidgetLoading,
    title,
  } = useGoalsByIndicatorWidget();

  return (
    <Box id={AdminDashboards.learningGoals.GoalsByIndicatorWidget.name} sx={{ height: '100%' }}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle}/>}
        fullHeight
        marginBottom='0px'
      >
        {!goalsByIndicatorWidgetLoading && hasData && !!barGraphData && (
          <AnalyticDonutChart
            data={barGraphData!}
            startAngle={90}
            endAngle={-270}
            boxWidth={500}
            chartWidth={500}
            chartHeight={350}
            boxHeight={350}
            dataKey="numberOfGoals"
            tooltipType={ACTIVE_GOAL_TOOLTIP_TYPE}
            totalCount={totalCount}
            label
            styledTooltipLink={false}
            tooltipLabelSuffix="Goals"
          />
        )}
        {!goalsByIndicatorWidgetLoading && !hasData && (
          <NoResults
            description={AdminDashboards.learningGoals.GoalsByIndicatorWidget.insufficientData.description}
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {goalsByIndicatorWidgetLoading && <DonutChartLoader />}
      </StyledCard>
    </Box>
  );
};

export default React.memo(GoalsByIndicatorWidget);
