import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DialogTitleTypography, StyledDialogBox } from '../../../TeacherBenchmarks/Grade/BenchmarkDownload.styled';
import WarningIcon from '../../../../components/Icons/WarningIcon';
import { palette } from '../../../../theme/palette';
type Props = {
  openModal: boolean,
  handleClose: () => void,
  handleNext: () => void,
  isBenchmarksPage?: boolean,
};
const AssignmentNavigationModal = ({ openModal, handleClose, handleNext, isBenchmarksPage = false }: Props) => {
  const handleModalClose = () => {
    handleClose();
  };
  return (
    <Dialog open={openModal} onClose={handleModalClose} sx={{ '.MuiPaper-root.MuiDialog-paper': { overflow: 'hidden ', margin: '32px', maxWidth: '1000px', width: '720px' } }}>
      <DialogTitle sx={{}}>
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <WarningIcon colorFill={palette.customBlue.primaryBlue} fontSize="large" />
        </Box >
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" sx={{ marginTop: '8px' }}>
          <DialogTitleTypography>Are you sure you want to go?</DialogTitleTypography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{
          color: palette.customBlue.chipDark,
          fontSize: '18px',
          fontWeight: 800,
          lineHeight: 'normal',
        }}>Changes made while creating your {isBenchmarksPage ? 'benchmark' : 'assignment'} will not be saved if you navigate back to your {isBenchmarksPage ? 'benchmarks page' : 'assignments page'}. Are you sure you want to leave? </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'end', gap: '12px' }} >
        <Button onClick={handleNext} variant="outlined" sx={{
          fontWeight: 800,
          backgroundColor: palette.customPink.main,
          color: palette.customRed.deleteButton,
          '&::after': {
            borderColor: palette.customRed.deleteButton,
          },
        }}  >
                    Yes, leave page
        </Button>
        <Button onClick={handleModalClose} variant="contained" sx={{ fontWeight: 800 }}  >
                    No, stay on page
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AssignmentNavigationModal;
