import React from 'react';
import AudioPlayer from '../Player';
import { Skills } from '../../variables/types';
import { StyledTableCell } from './anchorTable-styled';


interface Props {
  data?: string
  url?: string,
  fileType?: string
  anchorType?: string
  disable?: boolean
  active: boolean,
  id?: string,
  onPlaying?: (playerId: string) => void,
}

const AnchorTableCell = ({
  data,
  url,
  fileType,
  anchorType,
  disable,
  active = false,
  id,
  onPlaying,
}: Props) => {
  return (
    <>
      <StyledTableCell
        align="left"
      >
        { anchorType === Skills.Writing ? data : ''}
        { anchorType === Skills.Speaking
          ? (
            <AudioPlayer
              url={url}
              fileType={fileType}
              disablePlay={disable}
              active={active}
              flexStructure
              playerId={id}
              onPlaying={onPlaying}
            />
          ) : ''}
      </StyledTableCell>
    </>
  );
};

export default AnchorTableCell;
