import React from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import StyledBoxWrapper from './StyledCard.styled';
import noop from '../../utils/noop';
import { palette  } from '../../theme/palette';

type Alignment = 'inherit' | 'left' | 'center' | 'right' | 'justify';

interface Props {
  description?: string,
  title?: string,
  buttonTitle?: string,
  onClick?: () => void
  titleAlignment?: Alignment
  contentWrapperMinHeightAuto?: boolean
  Icon: any;
}

const styledCardStyles = {
  contentWrapper: {
    minWidth: '250px',
    justifyContent: 'center',
    display: 'flex',
    padding: '8px',
    minHeight: '150px',
  },
  cardTitle: {
    minWidth: '250px',
    justifyContent: 'start',
    alignItems: 'center',
    display: 'flex',
    padding: '8px',
  },
  viewBtn: {
    width: '132px',
    height: '44px',
  },
  btnBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
};

const StyledCardWithIcons = ({
  description,
  title,
  buttonTitle,
  onClick,
  titleAlignment = 'center',
  Icon,
}: Props) => {
  return (
    <StyledBoxWrapper width="100%">
      <Box sx={{ ...styledCardStyles.cardTitle }}>
        <Icon
          fillColor={palette.customBlue.primaryBlue}
        />
        <Typography align={titleAlignment} variant='sTitle'>
          {title}
        </Typography>
      </Box>
      <Box sx={{ ...styledCardStyles.contentWrapper }}>
        <Typography>
          {description}
        </Typography>
      </Box>
      <Box sx={{ ...styledCardStyles.btnBox }}>
        {buttonTitle && (
          <Button
            onClick={onClick || noop}
            variant="contained"
            color="primary"
            sx={{
              width: '132px',
              height: '44px',
            }}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>
    </StyledBoxWrapper>
  );
};

export default StyledCardWithIcons;
