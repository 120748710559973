import { styled, Box, Button, InputBase, Modal } from '@mui/material';
import { StyledFlexColBoxContainer } from '../../components/Common/Common.styled';
import { Theme } from '@mui/material';

export const StyledTeacherNotesInput = styled(InputBase)(({ theme }) => ({
  '.MuiInputBase-root': {
    width: '100%',
    color: theme.palette.customBlack.lightBlack,
    padding: theme.spacing(0),
  },
  '.MuiInputBase-input': {
    borderRadius: theme.spacing(0, 0, 1.5, 1.5),
    border: `2px solid ${theme.palette.customBackground.disabled}`,
    backgroundColor: theme.palette.customWhite.main,
    padding: theme.spacing(2, 3),
  },
  '.MuiInputBase-inputMultiline': {
    borderTop: 'none',
    padding: theme.spacing(2, 3, 0, 3),
  },
  '.MuiInputBase-multiline': {
    padding: theme.spacing(0),
  },
}));

export const StyledStudentFeedbackInput = styled(InputBase)(({ theme }) => ({
  '.MuiInputBase-root': {
    paddingTop: 0,
    width: '100%',
    color: theme.palette.customBlack.lightBlack,
  },
  '.MuiInputBase-multiline':{
    padding: theme.spacing(0.75, 0, 0.9),
  },
  '.MuiInputBase-input': {
    borderRadius: theme.spacing(0.625),
    border: `2px solid ${theme.palette.customBackground.disabled}`,
    backgroundColor: theme.palette.customWhite.main,
    minHeight: theme.spacing(25),
  },
  '.MuiInputBase-inputMultiline':{
    height: 'auto !important',
    resize: 'none',
    padding: theme.spacing(1),
  },
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  border: `2px solid ${theme.palette.customBackground.disabled}`,
}));

export const StyledContainer1 = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0, 0, 1.5, 1.5),
  border: '2px',
  borderTop: 'none',
  borderStyle: 'solid',
  borderColor: theme.palette.customBackground.disabled,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.customWhite.main,
}));

// TODO - Replace StyledContainer with StyledContainer2 at the time of integration
export const StyledContainer2 = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  backgroundColor: theme.palette.customWhite.main,
}));

export const StyledContainer3 = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0, 0, 1.5, 1.5),
  borderTop: 'none',
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  backgroundColor: theme.palette.customWhite.main,
}));

export const StyledContainer4 = styled(StyledFlexColBoxContainer)(({ theme }) => ({
  overflowY: 'auto',
  /* TODO: This height value can be changed as per requirement */
  height: theme.spacing(31.25),
  minWidth: theme.spacing(40),
  maxWidth: theme.spacing(81.25),
  overflowWrap: 'break-word',
}));

export const StyledContainer5 = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderRadius: theme.spacing(0, 0, 1.5, 1.5),
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  padding: theme.spacing(3),
  borderTop: 'none',
  backgroundColor: theme.palette.customWhite.main,
}));

export const StyledContainer6 = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5, 1.5, 0, 0),
  padding: theme.spacing(3),
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  backgroundColor: theme.palette.customWhite.main,
}));

export const StyledContainer7 = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const StyledContainer8 = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledContainer9 = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderTop: 'none',
  margin: 0,
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  backgroundColor: theme.palette.customWhite.main,
}));

export const StyledContainer10 = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowY: 'auto',
  minWidth: theme.spacing(40),
  maxWidth: theme.spacing(81.25),
  overflowWrap: 'break-word',
  borderRadius: theme.spacing(0, 0, 1.5, 1.5),
  borderTop: 'none',
  backgroundColor: theme.palette.customWhite.main,
}));

export const StyledContainer11 = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
}));

export const StyledContainer12 = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const StyledContainer13 = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledContainer14 = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  padding: theme.spacing(2, 3, 3, 3),
  backgroundColor: theme.palette.customWhite.main,
}));

export const StyledContainer15 = styled(Box)(({ theme }) => ({
  borderRadius: '5px',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.customWhite.whiteSmoke,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
}));

export const StyledRecordingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  minHeight: theme.spacing(16.5),
}));

export const StyledRubricContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  '&:nth-child(even)': {
    background: theme.palette.customGrey.lighterGrey,
  },
}));

export const StyledRubrictile = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '36%',
  marginLeft: theme.spacing(1),
  wordBreak: 'break-word',
}));

export const StyledScoringRubrics = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'space-between',
}));

export const StyledQuestionContainer = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginLeft: theme.spacing(1),
  verticalAlign: 'middle',
}));

export const StyledStudentViewButton = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    fontWeight: 800,
    fontSize: theme.spacing(2.25),
    padding: theme.spacing(1.5, 5),
  },
}));

export const StyledFlexModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1),
}));

export const StyledDisabledSubmitButton = styled(Button)(({ theme }) =>({
  backgroundColor: `${theme.palette.customBackground.disabled}`,
  color: theme.palette.customBlack.lightBlack,
  '&::after': {
    backgroundColor: `${theme.palette.customBackground.disabled}`,
    color: theme.palette.customBlack.lightBlack,
  },
}));

export const StylesCss = (theme: Theme) => ({
  layoutMain: {
    backgroundColor: theme.palette.customBackground.dragBackground,
  },
  whiteBackground: {
    backgroundColor: theme.palette.customWhite.main,
  },
  WritingBodyTitle: {
    letterSpacing: '1px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
  },
  recordingNoData: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  noRecordingText: {
    color: theme.palette.customBlack.lightBlack,
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: theme.spacing(2.75),
    letterSpacing: '0.1px',
    marginLeft: theme.spacing(2),
  },
  goalsRowContainerStyles: {
    marginBottom: theme.spacing(3),
  },
  optionalSubtitle: {
    color: theme.palette.customBlack.lightBlack,
  },
  optionalButtonStyles: {
    padding: theme.spacing(1.5, 5.6),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  checkMarkIconContainer: {
    display: 'flex',
    width: theme.spacing(30),
  },
  scoringSubHeader: {
    color: theme.palette.customYellow.secondaryDark,
    minHeight: theme.spacing(2.5),
    maxHeight: theme.spacing(18.75),
    overflow: 'scroll',
  },
  checkmarkHollowStyles: {
    fontSize: theme.spacing(3.75),
    marginLeft: theme.spacing(1),
  },
  exampleStyle: {
    fontStyle: 'italic',
    height: theme.spacing(18.75),
    overflow: 'scroll',
    margin: theme.spacing(1.25, 0),
  },
  tooltip: {
    '&.MuiTooltip-tooltip': {
      maxWidth: 'none',
    },
  },
});
