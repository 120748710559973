import React from 'react';
import {
  Typography,
  Button, Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { palette  } from '../../theme/palette';

interface Props {
  redirectionLink?: string;
  Icon: any;
  label: string;
  count?: number | string;
}


const tileStyle = {
  iconWithCount: {
    height: '18px',
    width: '18px',
    marginRight: '8px',
  },
  icon: {
    height: '50px',
    width: '50px',
    marginBottom: '8px',
  },
  actionButton:{
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px',
    padding: '20px',
  },
};

const TileButton = ({ redirectionLink, Icon, label, count }: Props) => {
  const hasCount = !!count || count === 0;

  const buttonContent = (
    <Box
      component="span"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {hasCount && (
        <Box mb={1} px={3}>
          <NumberFormat
            displayType="text"
            value={count ?? '-'}
            thousandSeparator
            style={{
              fontWeight: 900,
              fontSize: '50px',
              color: palette.fontColors.fontBlack,
            }}
          />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection={hasCount ? 'row' : 'column'}
        justifyContent="center"
        alignItems="center"
      >
        <Icon
          sx={{
            ...(hasCount && {
              ...tileStyle.iconWithCount,
            }),
            ...(!hasCount && {
              ...tileStyle.icon,
            }),
          }}
        />
        <Typography sx={{
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '24px',
          color: palette.fontColors.fontBlack,
          textTransform: 'capitalize',
        }}>
          {label}
        </Typography>
      </Box>
    </Box>
  );

  if (redirectionLink) {
    return (
      <Button
        variant="outlined"
        color="inherit"
        component={Link}
        to={redirectionLink}
        sx={{ ...tileStyle.actionButton, lineHeight: '20px', ...(count && { lineHeight: '40px' }) }}
      >
        {buttonContent}
      </Button>
    );
  }
  return (
    <Button
      variant="outlined"
      color="inherit"
      sx={{ ...tileStyle.actionButton, lineHeight: '20px', ...(count && { lineHeight: '40px' }) }}
    >
      {buttonContent}
    </Button>
  );
};
export default React.memo(TileButton);
