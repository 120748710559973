import React, { createContext, useState, useContext } from 'react';
import { Cache } from 'aws-amplify';
import { User } from '../variables/types';

// @ts-ignore
const AuthContext = createContext();

export default (props: any) => {
  const [user, setNewUser] = useState<User | null >(Cache.getItem('user'));

  const value = React.useMemo(() => ({
    user,
    setNewUser,
  }), [user]);

  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuthProvider = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthProvider() has to be used within a child of the Auth Provider');
  }

  const {
    setNewUser,
  }:any = context;

  const getUser = () => Cache.getItem('user');

  const setUser = (newUser: any) => {
    setNewUser(newUser);
    Cache.setItem('user', newUser);
  };

  return {
    getUser,
    setUser,
  };
};
