import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
interface Props {
    message?: string;
}
const MessageBox = ({ message }: Props) => {
    const boxStyle = {
        maxWidth: '600px',
        width: 'auto', // Default width is auto (message width)
    };
    const mediaQuery = window.matchMedia('(min-width: 601px)');
    if (mediaQuery.matches) {
        boxStyle.width = '100%'; // Set full width when message width exceeds 600px
    }
    return (
        <Box sx={boxStyle}>
            <Typography variant="subtitle2" sx={{ wordBreak: 'break-word', }}>
                {message}
            </Typography>
        </Box>
    );
};

export default MessageBox;
