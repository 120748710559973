import React from 'react';
import { Box, Chip, styled, TableCell, Typography } from '@mui/material';
import { palette  } from '../../theme/palette';


const StyledTypographyChip = styled(Typography)({
  width: '200px',
  height: '58px',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '0.1px',
  textAlign: 'left',
  marginTop: '12px',
});

const StyledBoxChip = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledTableLine = styled(TableCell)(({ theme }) => ({
  borderRight: `4px solid ${theme.palette.customBackground.disabled}`,
  borderLeft: `4px solid ${theme.palette.customBackground.disabled}`,
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '0.1px',
  verticalAlign: 'top',
}));

const chipStyles = {
  chipLabelWhite: {
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
    color: palette.customWhite.main,
  },
  chipLabelBlack: {
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
    color: palette.fontColors.fontBlack,
  },
};

type Props = {
  fill: string,
  textColor: string,
  label: string,
  information: string
};

const TableHeaderChip = ({
  fill,
  textColor,
  label,
  information,
}: Props) => {
  return (
    <>
      <StyledTableLine align="center">
        <StyledBoxChip>
          <Chip
            label={label}
            color="primary"
            sx={{
              width: '100%',
              '&.MuiChip-colorPrimary': {
                backgroundColor: fill,
              },
              '& .MuiChip-label': {
                ...chipStyles.chipLabelBlack, ...(textColor === palette.customWhite.main && { ...chipStyles.chipLabelWhite }),
              },
            }}
          />
          <StyledTypographyChip>
            {information}
          </StyledTypographyChip>
        </StyledBoxChip>
      </StyledTableLine>
    </>
  );
};

export default TableHeaderChip;
