/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HumanDeskFrame = (props: any) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="219"
      height="200"
      viewBox="0 0 219 200"
      fill="none"
      sx={{ fontSize: '185px' }}
      {...props}
    >
      <g>
        <path d="M199.341 89.9134C195.818 91.1525 192.772 93.3448 190.583 96.3948C186.87 101.542 186.394 108.881 187.251 114.981C187.536 116.792 188.107 119.08 189.821 120.128C192.677 121.844 195.723 118.603 197.627 116.983C201.816 113.456 205.909 109.739 209.527 105.64C212.573 102.304 216.666 98.0152 218.19 93.726C221.426 84.6711 203.148 88.579 199.341 89.9134Z" fill="black" />
        <path d="M214.668 96.7759C210.86 102.304 206.291 107.165 201.34 111.74C199.151 113.742 196.961 115.743 194.582 117.554C193.154 118.603 191.345 119.365 190.107 117.745C188.775 115.934 188.489 113.646 188.489 111.454C188.489 109.262 188.394 106.879 188.679 104.687C188.775 103.734 188.965 102.876 189.251 101.923C189.346 101.446 189.536 101.065 189.727 100.588C189.822 100.302 190.393 99.6353 190.393 99.254C192.107 96.0134 194.962 93.5352 198.294 92.0102C198.77 91.8196 199.246 91.629 199.722 91.4384C203.244 90.1993 207.147 89.7227 210.86 89.6274C212.193 89.6274 214.097 89.3415 215.239 89.9133C219.047 91.629 215.905 94.9649 214.668 96.7759Z" fill="black" />
        <path d="M152.79 135.572C152.79 135.191 152.6 135 152.314 134.905C153.647 131.569 152.41 127.47 148.316 125.183C143.937 122.705 138.035 122.991 133.18 122.991C129.087 122.895 124.898 122.991 120.804 123.277C116.901 123.562 113.094 124.039 109.19 124.039C106.715 124.039 104.335 124.23 101.956 124.802C97.1957 125.945 93.0071 128.805 92.9119 134.142C92.9119 135.763 93.1975 137.002 93.8638 138.05C93.8638 138.146 93.7686 138.146 93.7686 138.241C92.8167 142.53 93.3878 146.819 93.7686 151.204L95.1014 165.12C95.5774 169.885 95.863 174.556 96.3389 179.322C96.8149 183.23 95.7678 189.711 100.052 191.808C101.765 192.666 103.764 192.57 105.668 192.761C107.763 192.952 109.857 193.238 111.951 193.333C116.711 193.714 121.661 194 126.516 193.809C131.181 193.619 135.846 193.047 140.51 192.284C142.795 191.903 145.08 191.427 147.364 190.855C150.696 190.092 152.029 188.853 151.934 185.326C151.743 179.512 152.505 173.603 152.695 167.884C153.171 157.113 153.266 146.343 152.79 135.572Z" fill="black" />
        <path d="M148.981 187.706C147.458 188.755 145.84 189.898 143.555 190.28C141.651 190.566 139.842 190.852 137.938 191.138C133.75 191.805 129.466 192.186 125.182 192.377C120.708 192.567 116.329 192.377 111.854 191.995C109.57 191.805 107.38 191.519 105.096 191.233C103.001 190.947 100.145 190.852 99.0029 188.659C97.4798 185.8 98.051 181.606 97.6702 178.461C97.1942 174.076 96.9086 169.596 96.5278 165.117L95.3855 152.726C95.0047 148.341 94.3383 143.957 95.0047 139.572C99.0029 143.099 107.666 142.146 112.426 142.241C118.613 142.336 124.801 142.146 130.989 141.86C136.225 141.574 142.413 141.764 147.363 139.763C149.076 139.096 150.409 138.047 151.361 136.808C150.79 137.666 151.551 141.669 151.551 142.813C151.647 145.005 151.647 147.293 151.647 149.485C151.647 153.965 151.647 158.349 151.551 162.829C151.361 169.024 151.647 185.895 148.981 187.706Z" fill="white" />
        <path d="M150.315 184.659C136.131 187.804 119.472 189.997 109.571 176.748C102.146 166.74 101.86 154.158 102.812 142.149C99.7661 141.863 96.9102 141.195 95.1015 139.575C94.3399 143.959 95.0063 148.344 95.4823 152.728L96.6246 165.119C97.0054 169.504 97.291 173.984 97.767 178.463C98.1478 181.609 97.5766 185.803 99.0997 188.662C100.242 190.854 103.098 190.95 105.192 191.236C107.477 191.522 109.667 191.808 111.951 191.998C116.33 192.379 120.805 192.57 125.279 192.379C129.563 192.189 133.751 191.808 138.035 191.14C139.939 190.854 141.748 190.568 143.652 190.282C145.936 189.901 147.555 188.757 149.078 187.709C149.554 187.328 150.03 186.184 150.315 184.659Z" fill="#D3DEE3" />
        <path d="M149.744 136.426C146.031 140.144 138.796 139.762 133.941 140.048C123.851 140.716 113.665 140.906 103.574 140.334C101.099 140.239 97.7667 140.334 95.8627 138.333C93.7684 136.141 94.054 132.519 95.5772 130.231C97.7667 126.99 101.289 125.942 105.002 125.656C116.996 124.703 129.277 123.464 141.271 124.703C146.412 125.37 155.932 130.231 149.744 136.426Z" fill="#DFDFDF" />
        <path d="M172.973 49.5024C172.973 46.1664 172.211 42.7351 171.45 39.4944C170.498 35.6818 169.07 32.0598 167.261 28.6285L167.166 28.5332C166.975 28.3426 168.023 25.1972 168.023 24.6253C168.118 23.3862 167.737 22.0518 168.308 21.0033C169.355 19.1923 168.784 16.6188 167.451 15.0938C166.69 14.236 165.452 13.95 164.405 14.5219C164.31 14.3313 164.215 14.0453 164.024 13.8547C163.358 12.8063 161.93 12.425 160.883 13.0922C160.597 11.7578 160.026 10.4234 158.789 9.75618C157.456 9.08898 156.028 9.56555 155.457 10.8046C155.076 10.3281 154.505 10.0421 153.933 9.94681C151.934 9.66086 150.887 11.4718 151.363 13.0922C151.268 14.1407 151.173 15.3798 151.554 16.4282C149.174 16.047 146.794 15.761 144.319 15.3798C144.223 15.1891 144.033 15.0938 143.747 15.0938C142.51 15.1891 141.272 15.3798 139.94 15.4751C139.178 15.5704 138.892 16.4282 139.559 16.9048C140.511 17.572 141.368 18.2392 142.32 18.9064C142.605 19.097 142.795 19.097 142.986 19.0017C144.795 19.383 146.603 19.6689 148.317 20.0502C147.27 21.5752 148.031 23.3862 148.888 24.8159C150.03 26.9128 151.458 28.7238 153.077 30.5348C154.219 36.0631 154.885 43.6882 149.554 46.1664C149.364 46.1664 149.078 46.2617 148.983 46.4524C148.126 46.7383 147.079 46.9289 145.842 46.9289C140.225 47.0243 134.418 45.8805 128.897 45.3086C128.611 45.118 128.23 45.118 127.945 45.2133C127.564 45.3086 127.374 45.7852 127.564 46.1664C125.755 53.601 124.042 62.9418 130.515 68.756C133.181 71.1389 136.703 71.7108 140.13 71.7108C144.795 71.6155 149.745 71.0436 154.409 70.0904C158.408 69.2326 162.406 67.6122 165.643 65.0387C168.403 62.8465 170.688 59.7011 171.926 56.2698C172.687 53.8869 172.973 51.6947 172.973 49.5024Z" fill="black" />
        <path d="M142.604 17.0925C142.414 16.9972 142.223 16.8066 142.033 16.7113C142.223 16.7113 142.509 16.616 142.699 16.616C142.699 16.8066 142.604 16.9972 142.604 17.0925Z" fill="white" />
        <path d="M164.882 15.9489C164.882 15.9489 164.977 15.9489 164.882 15.9489C166.119 14.6145 167.071 17.5692 167.261 18.2364C167.452 18.9989 167.357 19.9521 166.785 20.6192C166.119 21.3817 164.786 21.0958 164.977 19.952C164.977 19.7614 164.977 19.5708 164.786 19.4755C164.882 19.2849 164.882 19.1895 164.977 18.9989C165.167 18.1411 165.167 16.9973 164.882 15.9489Z" fill="white" />
        <path d="M161.168 14.7077C161.263 14.7077 161.454 14.6123 161.549 14.517C162.882 12.8967 163.643 17.2811 163.643 17.9483C163.643 19.0921 162.691 20.2358 161.644 19.1874C160.978 18.6155 161.168 17.2811 161.168 16.5186C161.168 16.328 161.168 16.2327 161.073 16.1374C161.168 15.6608 161.168 15.1842 161.168 14.7077Z" fill="white" />
        <path d="M156.693 11.5666C157.264 9.46974 158.977 11.8526 159.263 12.4245C159.453 12.901 159.549 13.3776 159.549 13.8542C159.644 14.5213 159.739 15.3792 159.549 16.0464C158.882 17.8573 157.359 16.6182 156.883 15.4745C156.883 15.1885 156.883 14.9979 156.883 14.712C156.788 13.9495 156.693 12.901 156.312 12.0432C156.502 11.9479 156.693 11.8526 156.693 11.5666Z" fill="white" />
        <path d="M156.121 17.2856C156.502 17.7622 156.978 18.2387 157.454 18.4294C157.359 18.62 157.359 18.8106 157.454 19.0966C157.835 19.7638 156.788 19.8591 155.741 19.7638C155.741 19.0966 155.741 18.4294 155.645 17.7622C155.931 17.5716 156.026 17.3809 156.121 17.2856Z" fill="white" />
        <path d="M152.791 12.5207C152.981 12.0441 153.076 11.6629 153.267 11.2816C153.552 11.3769 153.838 11.4723 154.219 11.5676C154.599 11.8535 154.79 12.3301 154.885 12.8066C155.171 13.4738 155.361 14.3316 155.361 14.9988C155.266 15.1895 155.171 15.3801 155.266 15.5707C155.075 16.1426 154.695 16.6192 154.123 16.7145C154.028 16.6192 153.933 16.6192 153.838 16.6192C153.743 16.6192 153.647 16.6192 153.552 16.6192C153.457 16.5239 153.362 16.5239 153.267 16.4285C152.41 15.5707 152.695 14.141 152.886 13.0926C152.886 12.9019 152.886 12.7113 152.791 12.5207Z" fill="white" />
        <path d="M144.032 17.5691C144.127 17.2832 144.223 17.0926 144.223 16.8066C147.078 17.1879 150.03 17.6645 152.885 18.0457C153.076 18.141 153.266 18.2363 153.457 18.2363C153.742 18.3316 153.933 18.3316 154.218 18.3316C154.218 18.7129 154.218 19.0942 154.218 19.4754C153.837 19.3801 153.457 19.3801 153.457 19.3801C152.314 19.1895 151.172 18.9035 150.03 18.7129C148.126 18.3316 146.031 17.9504 144.032 17.5691Z" fill="white" />
        <path d="M144.318 69.6134C140.606 69.9947 136.512 70.3759 133.181 68.4697C125.375 64.0852 127.183 53.6961 128.992 46.4523C133.561 46.9289 138.226 47.5007 142.795 47.882C144.794 48.0726 146.793 48.1679 148.697 47.7867C148.317 55.0305 149.554 61.9884 152.505 68.565C149.744 69.1368 147.079 69.4228 144.318 69.6134Z" fill="#DFDFDF" />
        <path d="M171.259 52.3612C169.736 60.177 164.31 65.324 156.79 67.6115C155.838 67.8975 154.886 68.0881 153.934 68.374C150.887 61.702 149.65 54.6487 150.221 47.4048C150.697 47.2142 151.173 46.9282 151.649 46.6423C156.885 42.7344 155.838 35.4904 154.6 29.9622C154.6 29.8669 154.6 29.8669 154.505 29.7716C154.505 29.7716 154.505 29.7716 154.505 29.6762C154.505 29.6762 154.505 29.5809 154.41 29.5809C154.41 29.5809 154.41 29.4856 154.314 29.4856C152.791 27.8653 151.459 26.1496 150.411 24.2433C150.126 23.7667 149.745 23.0995 149.555 22.5276C149.364 21.7651 149.459 20.526 150.507 20.3354C150.602 20.3354 150.697 20.3354 150.792 20.3354C150.887 20.3354 151.078 20.4307 151.173 20.4307C152.22 20.812 152.886 22.5276 153.172 23.3855C153.267 23.7667 153.553 23.862 153.838 23.862C153.934 23.862 154.029 23.862 154.219 23.862C155.076 23.6714 155.838 23.862 156.504 24.4339C157.266 25.0058 158.027 23.6714 157.266 23.0995C156.409 22.4323 155.362 22.2417 154.314 22.337C154.124 21.8604 153.934 21.3839 153.648 21.0026C154.505 21.1932 155.457 21.3839 156.314 21.3839C158.122 21.3839 159.645 20.2401 158.789 18.5244H158.884C159.074 18.4291 159.265 18.3338 159.455 18.2385C159.645 19.4776 160.121 20.6213 161.359 21.0979C162.025 21.3839 162.692 21.1932 163.263 20.9073C163.548 21.9557 164.596 22.623 165.738 22.623C165.928 22.623 166.119 22.623 166.214 22.5276C166.404 24.4339 166.119 26.4355 165.357 28.2465C165.167 28.6278 165.357 28.9137 165.548 29.1044C165.548 29.1997 165.548 29.1997 165.643 29.295C169.355 36.3483 171.259 43.8781 171.164 51.7893C171.355 51.9799 171.259 52.1705 171.259 52.3612Z" fill="white" />
        <path d="M143.938 107.927C142.7 103.924 140.701 100.016 139.178 96.2033C135.085 86.0046 133.847 74.7575 135.37 63.8916C136.037 59.4118 137.084 54.5507 134.989 50.4522C133.562 47.4974 130.61 45.4958 127.564 44.5427C124.518 43.5895 121.186 43.3989 117.854 43.3036C112.904 43.3036 107.668 43.5895 102.908 45.2099C98.8148 46.6396 94.9117 49.2131 92.5318 52.9304C89.9615 56.9336 87.9624 61.2228 86.7248 65.7979C84.5353 73.8043 84.1545 82.2873 84.8209 90.5797C85.3921 97.8236 86.7248 105.068 89.4855 111.74C92.2462 118.412 96.6253 124.607 102.623 128.706C109.572 133.567 118.52 135.473 126.898 133.853C131.182 132.995 135.37 131.279 138.702 128.42C142.034 125.56 144.414 121.652 145.08 117.268C145.461 114.218 144.89 111.072 143.938 107.927Z" fill="black" />
        <path d="M142.891 120.033C137.369 136.141 114.427 135.76 103.003 127.277C90.1518 117.745 86.5343 100.875 86.2488 85.8149C86.0584 76.76 86.4392 67.2285 90.4374 58.9362C92.2461 55.1236 95.0068 51.7875 98.4339 49.3094C102.051 46.7359 106.43 45.878 110.714 45.4015C115.379 44.9249 120.234 44.2577 124.899 45.2108C127.374 45.6874 129.658 46.8312 131.467 48.6422C132.514 49.5953 133.276 50.8344 133.847 52.0735C135.656 56.172 134.323 60.7471 133.752 64.941C133.276 68.4676 133.085 72.0896 133.276 75.6162C133.561 82.8602 135.18 90.1041 137.94 96.8714C140.796 103.925 145.651 112.026 142.891 120.033Z" fill="#DFDFDF" />
        <path d="M105.764 36.8212C105.384 34.3431 103.48 31.9602 100.719 32.3415C97.8631 32.7227 96.1496 35.4868 96.7208 38.2509C97.2919 40.9197 99.8622 42.9213 102.623 42.3494C105.193 41.8728 106.145 39.204 105.764 36.8212Z" fill="black" />
        <path d="M101.672 40.7286C99.9581 40.7286 98.6253 39.3942 98.3397 37.7738C97.959 35.8676 99.4821 34.1519 101.386 34.1519C104.813 34.2473 105.384 40.6332 101.672 40.7286Z" fill="white" />
        <path d="M139.367 31.7712C138.987 29.293 137.083 26.9102 134.322 27.2914C131.466 27.6727 129.753 30.4368 130.324 33.2009C130.895 35.8696 133.465 37.8712 136.226 37.2993C138.891 36.7274 139.748 34.154 139.367 31.7712Z" fill="black" />
        <path d="M135.368 35.6785C133.654 35.6785 132.321 34.3441 132.036 32.7238C131.655 30.8175 133.178 29.1019 135.082 29.1019C138.509 29.1972 139.08 35.5832 135.368 35.6785Z" fill="white" />
        <path d="M121.092 43.8761C117.665 39.9683 112.239 39.587 107.574 41.5886C107.193 41.7793 107.098 42.1605 107.289 42.4464C107.289 42.5418 107.193 42.5418 107.193 42.6371C105.861 47.5934 105.575 53.5981 109.859 57.22C113.381 60.1747 118.807 59.5075 121.663 55.8856C124.519 52.1684 124.043 47.2121 121.092 43.8761Z" fill="black" />
        <path d="M120.52 54.8402C117.569 58.4621 111.953 58.2714 109.383 54.2683C107.193 50.837 107.669 46.6432 108.716 42.926C108.716 42.8307 108.716 42.7354 108.716 42.6401C112.334 41.2104 116.522 41.401 119.473 44.2604C122.329 47.0245 123.091 51.5995 120.52 54.8402Z" fill="#D3DEE3" />
        <path d="M132.991 16.2341C129.564 8.79956 120.901 5.46354 113.285 8.22767C108.716 9.94334 105.098 13.47 103.099 17.8545C101.767 20.9045 101.291 24.2406 101.195 27.5766C101.1 30.722 101.005 34.1533 101.671 37.2034C102.814 42.6363 106.336 47.4021 111.477 49.7849C118.807 53.1209 127.946 50.4521 131.753 43.2082C133.753 39.4909 134.229 35.1064 134.609 31.0079C134.99 26.0515 135.085 20.9045 132.991 16.2341Z" fill="black" />
        <path d="M133.276 28.2425C132.896 37.488 130.611 48.8304 119.378 49.7836C114.332 50.2602 109.668 47.9726 106.621 43.9694C102.528 38.4411 101.766 29.6722 103.099 23.0955C103.575 20.7126 104.432 18.425 105.669 16.4234C108.24 12.1343 112.619 9.27481 117.569 8.89355C129.088 7.84509 133.657 18.425 133.276 28.2425Z" fill="white" />
        <path d="M133.276 28.2425C132.896 37.488 130.611 48.8304 119.378 49.7836C114.332 50.2602 109.668 47.9726 106.621 43.9694C102.528 38.4411 101.766 29.6722 103.099 23.0955C103.575 20.7126 104.432 18.425 105.669 16.4234C108.24 12.1343 112.619 9.27481 117.569 8.89355C129.088 7.84509 133.657 18.425 133.276 28.2425Z" fill="white" />
        <path d="M117.664 8.8024C112.618 9.18366 108.334 12.0431 105.764 16.3323C104.527 18.4292 103.67 20.7167 103.194 23.0043C102.527 26.4356 102.337 30.4389 103.003 34.2514C106.526 32.0592 109.382 29.1044 111.666 25.5778C113.285 28.1513 115.569 30.9154 118.901 30.3435C120.9 30.0576 122.138 28.5326 122.233 26.7216C125.47 29.1044 129.182 31.2014 133.276 30.6295C133.371 29.7717 133.371 29.0091 133.466 28.1513C133.657 18.4292 129.087 7.84925 117.664 8.8024Z" fill="#D3DEE3" />
        <path d="M138.321 11.6602C135.37 6.41786 128.04 5.08346 122.328 4.22562C123.09 3.55842 123.661 2.7959 123.47 1.65212C123.09 -0.254173 121.376 -0.158858 119.853 0.222401C114.141 1.55681 109.286 5.3694 106.049 10.2305C102.527 10.707 99.8616 15.2821 98.8145 18.1416C96.9105 23.1933 97.8625 28.6262 100.814 33.0107C101.004 33.2966 101.194 33.392 101.48 33.392C101.575 33.8685 102.242 34.2498 102.718 33.7732C106.43 29.5794 109.001 24.623 110.048 18.9994C110.048 18.9041 110.048 18.8088 110.143 18.7135C111.38 22.1448 113.665 24.9089 116.807 27.0059C117.283 27.2918 117.949 26.9105 117.949 26.3387C117.949 24.337 117.568 22.5261 116.997 20.7151C121.757 24.2417 127.088 26.5293 132.99 27.6731C133.276 27.959 133.656 27.959 133.942 27.5777C137.274 23.2886 141.558 17.2838 138.321 11.6602Z" fill="black" />
        <path d="M107.189 23.3858C106.047 26.6265 104.238 29.4859 102.049 32.1546C100.145 29.1999 99.0978 25.9593 99.3834 22.4327C99.5738 20.3358 100.24 18.3342 101.287 16.5233C101.954 15.2842 106.809 8.1357 108.998 14.1404C109.093 14.2357 109.093 14.4264 109.093 14.617C109.093 14.8076 109.188 14.9983 109.188 15.1889C109.379 17.667 107.951 21.4796 107.189 23.3858Z" fill="black" />
        <path d="M133.084 26.1476C126.516 24.9085 120.709 22.1444 115.663 17.7599C114.997 17.188 114.045 17.8552 114.521 18.7131C115.568 20.7147 116.139 22.621 116.33 24.8132C113.093 22.2397 111.189 18.7131 110.618 14.5192C110.618 14.4239 110.523 14.2333 110.523 14.1379C110.428 12.327 109.761 10.8019 107.953 10.3254C109.666 7.94248 111.856 5.84556 114.331 4.32052C115.663 3.46269 117.091 2.79549 118.519 2.31891C118.9 2.12828 119.947 1.65171 120.423 1.74702C121.375 1.93765 121.185 3.46269 120.138 3.93926C119.662 4.12989 119.662 4.60647 119.947 4.98773C119.947 5.27367 120.138 5.4643 120.518 5.55961C125.183 6.22682 130.324 6.89402 134.322 9.65815C140.891 14.1379 136.892 21.1912 133.084 26.1476Z" fill="black" />
        <path d="M116.14 37.2033C115.379 37.6799 114.427 37.8705 113.475 37.6799C112.523 37.4893 112.142 38.919 113.094 39.1096C114.427 39.3955 115.759 39.2049 116.902 38.4424C117.758 37.9658 116.997 36.6314 116.14 37.2033Z" fill="black" />
        <path d="M128.516 34.6293C127.945 35.1059 127.183 35.2012 126.421 35.0106C125.47 34.7247 125.089 36.1543 126.041 36.4403C127.278 36.9169 128.611 36.5356 129.563 35.6778C130.324 35.1059 129.277 34.0575 128.516 34.6293Z" fill="black" />
        <path d="M122.899 34.8213C122.423 33.9635 121.09 34.726 121.566 35.5838C122.423 37.0135 122.613 38.5385 122.232 40.1588C122.042 41.112 123.47 41.4932 123.66 40.5401C124.231 38.5385 123.946 36.6323 122.899 34.8213Z" fill="black" />
        <path d="M110.712 85.8149C110.331 74.7583 108.523 63.8925 106.048 53.1219C105.667 51.3109 105.286 49.4046 104.81 47.5936C104.715 47.1171 104.524 46.6405 104.334 46.2592C103.572 45.0201 101.764 44.8295 100.621 44.2576C99.0031 43.5904 97.3847 43.0185 95.6712 42.8279C92.3393 42.3513 89.1026 43.3998 87.1987 46.3545C86.9131 46.8311 86.6275 47.403 86.3419 47.9749C84.5332 52.0734 84.438 57.2204 84.438 61.6049C84.438 66.9425 85.1044 72.2802 86.3419 77.5225C88.1507 85.0523 91.3873 96.5854 100.241 98.3964C108.142 99.8261 110.998 92.3916 110.712 85.8149Z" fill="black" />
        <path d="M108.235 92.9626C104.999 100.016 96.7167 96.4892 93.3848 91.5329C87.8634 83.4311 86.0546 71.8027 85.8642 62.1759C85.769 57.5055 85.6739 51.6913 88.0538 47.4974C91.576 41.3973 98.335 44.352 103.095 47.0208V47.1162C105.856 57.8867 107.759 68.7526 108.807 79.8091C109.187 84.003 110.044 89.0547 108.235 92.9626Z" fill="#DFDFDF" />
        <path d="M114.998 45.0194C115.093 44.2569 114.712 43.3991 113.76 43.0178C113.57 42.9225 113.379 42.9225 113.189 42.9225C113.379 41.9693 112.903 41.0162 111.475 40.8256C110.809 40.7302 110.142 40.7302 109.476 40.9209C109.857 40.1584 109.857 39.3005 109.095 38.6333C107.477 37.1083 105.192 38.9193 104.05 40.063C100.908 43.4944 100.718 48.2601 102.812 52.0727C99.7661 63.4152 91.1032 73.1373 91.3888 85.2423C91.2936 85.3376 91.2936 85.5282 91.2936 85.7188C91.6744 90.2939 93.5783 94.9644 97.9573 97.1566C102.051 99.2535 107.001 98.3957 110.047 94.869C113.57 90.5799 113.76 84.2891 114.236 78.9515C114.902 71.1357 114.998 63.3199 114.522 55.504C115.664 54.9321 115.949 53.5977 116.045 52.2633C116.14 51.2149 116.045 50.643 116.806 49.6898C117.092 49.4039 117.377 49.1179 117.663 48.7367C118.044 48.1648 118.425 47.4976 118.329 46.7351C117.949 45.1147 116.33 44.7335 114.998 45.0194Z" fill="black" />
        <path d="M116.235 47.88C116.045 48.1659 115.855 48.3565 115.569 48.5472C115.093 49.0238 114.712 49.5956 114.522 50.2628C114.236 51.4066 114.807 53.8848 113.284 54.1707C112.808 54.2661 112.713 54.7426 112.808 55.1239C112.808 55.1239 112.808 55.1239 112.808 55.2192C113.475 65.132 113.284 75.14 111.856 84.9574C111.095 89.9138 109.286 96.2046 103.289 96.6812C97.2913 97.1577 93.5786 92.106 92.7219 86.7684C92.7219 86.6731 92.7219 86.5778 92.7219 86.4825C91.8651 74.3775 100.338 64.6554 103.765 53.5989C104.145 54.0754 104.526 54.4567 105.002 54.9333C105.764 55.6005 106.811 54.552 106.049 53.8848C103.765 51.8832 102.432 49.3097 102.717 46.1643C102.813 44.8299 103.193 43.5908 103.955 42.447C104.336 41.7798 104.717 41.2079 105.288 40.7314C108.144 38.7298 108.524 40.7314 106.811 41.7798C106.145 42.1611 105.478 42.6377 105.002 43.1142C104.336 43.7814 105.383 44.8299 106.049 44.1627C107.001 43.3049 108.048 42.733 109.191 42.447C109.667 42.3517 110.428 42.1611 110.904 42.2564C111.476 42.1611 111.666 42.447 111.476 43.0189C111.38 43.1142 111.19 43.2095 111.095 43.4002C111 43.4002 111 43.4955 110.904 43.4955C109.667 44.1627 108.429 45.1158 107.192 45.9737C106.43 46.5456 107.097 47.7847 107.953 47.3081C108.81 46.7362 109.762 46.069 110.619 45.4971C111 45.3065 111.285 45.0205 111.666 44.8299C111.952 44.6393 112.332 44.544 112.618 44.4486C113.094 44.3533 114.331 44.544 112.808 45.9737C111.571 46.7362 110.428 47.7847 109.476 48.8331C108.81 49.5956 109.952 50.6441 110.524 49.8816C111.38 48.9284 112.237 48.0706 113.284 47.4034C113.665 47.1175 114.427 46.5456 114.903 46.5456C116.521 46.8315 116.807 47.3081 116.235 47.88Z" fill="white" />
        <path d="M201.531 174.65C195.534 172.267 188.775 171.79 182.397 173.315C179.826 173.983 177.256 175.031 175.638 177.319C173.924 179.702 172.211 182.275 172.115 185.23C172.115 185.897 172.211 186.564 172.306 187.231C173.067 190.567 175.923 192.283 179.16 192.569C185.157 193.141 191.726 191.521 197.628 190.853C201.626 190.377 207.624 190.853 211.146 188.471C218.381 183.419 205.339 176.175 201.531 174.65Z" fill="black" />
        <path d="M209.337 187.704C206.481 189.229 202.197 188.943 199.056 189.229C195.248 189.61 191.535 190.086 187.727 190.468C184.491 190.849 180.493 191.802 177.256 190.754C175.257 190.086 173.638 188.657 173.543 186.369C173.448 184.272 174.4 182.271 175.352 180.555C176.304 178.839 177.161 176.838 178.969 175.98C181.064 174.931 183.92 174.455 186.204 174.264C189.251 173.883 192.297 174.074 195.248 174.645C198.39 175.217 201.436 176.266 204.196 177.791C206.672 178.935 215.334 184.463 209.337 187.704Z" fill="black" />
        <path d="M200.96 173.412C200.96 173.412 201.055 173.412 200.96 173.412C198.389 161.974 195.629 150.631 192.583 139.384C189.631 128.709 186.966 117.748 182.777 107.454C179.636 99.924 174.876 92.5847 167.355 88.8675C160.692 85.5315 152.695 86.008 146.983 90.8691C146.698 90.7738 146.317 90.7738 146.031 91.155C139.177 102.402 134.417 114.698 131.847 127.565C131.752 127.851 131.942 128.137 132.133 128.328C132.037 128.9 132.799 129.472 133.37 128.995C138.416 124.611 145.936 123.562 151.934 126.707C157.836 129.758 160.501 136.334 162.691 142.244C167.355 155.111 170.878 168.36 173.258 181.799C173.258 181.895 173.353 182.085 173.448 182.085C173.543 182.371 173.734 182.657 174.114 182.562C183.634 181.895 192.678 179.131 200.769 174.174C201.055 173.984 201.055 173.698 200.96 173.412Z" fill="black" />
        <path d="M168.593 155.872C166.404 148.532 164.31 140.621 160.597 133.854C155.076 123.846 142.7 120.033 133.371 126.991C135.941 114.696 140.51 103.067 146.984 92.2966C147.174 92.392 147.46 92.2966 147.65 92.106C155.171 85.5293 165.928 88.1028 172.592 94.4889C179.16 100.78 182.111 109.549 184.777 118.032C190.393 136.141 195.248 154.537 199.342 173.124C191.726 177.699 183.349 180.368 174.4 181.035C172.972 172.552 171.069 164.164 168.593 155.872Z" fill="white" />
        <path d="M168.593 155.872C166.404 148.532 164.31 140.621 160.597 133.854C155.076 123.846 142.7 120.033 133.371 126.991C135.941 114.696 140.51 103.067 146.984 92.2966C147.174 92.392 147.46 92.2966 147.65 92.106C155.171 85.5293 165.928 88.1028 172.592 94.4889C179.16 100.78 182.111 109.549 184.777 118.032C190.393 136.141 195.248 154.537 199.342 173.124C191.726 177.699 183.349 180.368 174.4 181.035C172.972 172.552 171.069 164.164 168.593 155.872Z" fill="white" />
        <path d="M185.062 118.797C184.967 118.606 184.967 118.32 184.872 118.13C182.302 109.647 179.255 100.878 172.687 94.5869C166.023 88.1055 155.266 85.6273 147.745 92.204C147.555 92.3947 147.269 92.3947 147.079 92.3947C140.606 103.165 136.036 114.794 133.466 127.089C141.938 120.798 152.981 123.372 159.074 131.474C158.788 126.517 160.787 121.466 165.166 118.511C171.354 114.126 178.875 115.175 185.062 118.797Z" fill="#D3DEE3" />
        <path d="M198.483 168.932C190.582 173.031 182.11 175.509 173.161 176.366C172.21 176.462 172.21 177.987 173.161 177.892C182.3 177.034 191.058 174.46 199.15 170.171C200.102 169.79 199.34 168.455 198.483 168.932Z" fill="black" />
        <path d="M199.913 88.6766C192.964 73.2356 174.781 67.8027 159.169 71.4246C145.461 74.57 134.513 83.053 124.708 92.8704C124.613 92.8704 124.517 92.8704 124.422 92.8704C111.666 97.3502 100.338 104.88 91.2939 114.888C91.2939 114.888 91.2939 114.888 91.2939 114.983C91.0083 115.365 91.1035 115.746 91.3891 115.937C97.5769 133.856 118.235 140.623 134.228 130.901C143.176 125.468 149.84 116.794 158.883 111.552C167.737 106.31 182.492 105.166 187.157 116.794C187.157 116.89 187.252 116.985 187.347 117.08C187.538 117.557 188.109 117.843 188.49 117.366C195.153 109.455 198.961 99.9237 199.818 89.5344C199.913 89.2485 200.008 88.9625 199.913 88.6766Z" fill="black" />
        <path d="M188.108 115.363C185.062 109.072 178.208 105.832 171.354 106.022C162.12 106.213 154.218 112.313 147.65 118.127C139.177 125.562 130.419 134.14 118.329 134.14C106.62 134.14 96.5292 126.515 92.8165 115.554C101.003 106.594 111.094 99.6361 122.423 95.2516C119.091 98.7782 115.854 102.305 112.713 105.927C112.046 106.689 113.093 107.738 113.76 106.975C125.659 93.6312 138.416 79.4293 156.027 73.9011C171.734 68.9447 190.964 73.1385 198.485 89.0561C198.485 89.1514 198.389 89.1514 198.389 89.2467C197.628 98.7782 194.106 107.833 188.108 115.363Z" fill="white" />
        <path d="M194.391 83.4297C193.535 91.0549 191.345 98.2035 187.918 104.971C181.159 101.063 173.163 99.8239 165.452 101.73C157.074 103.827 150.411 109.737 144.128 115.36C136.607 122.032 127.944 129.562 117.187 128.99C106.811 128.418 101.384 119.173 97.0054 110.88C96.5294 110.023 95.2919 110.785 95.6726 111.643C99.2901 118.41 103.384 125.845 110.714 128.99C118.52 132.326 127.373 129.657 134.227 125.273C142.414 120.031 148.697 112.215 156.884 107.068C166.308 101.254 177.542 100.777 187.157 106.305C186.3 107.926 185.253 109.641 184.205 111.166C183.634 111.929 184.967 112.691 185.538 111.929C191.25 103.351 194.677 93.6284 195.819 83.4297C196.01 82.4766 194.487 82.4766 194.391 83.4297Z" fill="black" />
        <path d="M63.1149 192.09V101.065C63.1149 98.777 65.0188 96.8707 67.3035 96.8707C69.5882 96.8707 71.4922 98.777 71.4922 101.065V192.09C71.4922 194.378 69.5882 196.284 67.3035 196.284C64.9236 196.284 63.1149 194.378 63.1149 192.09Z" fill="white" />
        <path d="M71.3968 101.065C71.3968 100.302 71.2064 99.6352 70.8256 98.968C68.4457 98.8726 65.9707 98.8726 63.5908 98.7773C63.21 99.4445 62.9244 100.207 62.9244 100.97V115.457C65.7803 115.171 68.5409 114.79 71.3016 114.218L71.3968 101.065Z" fill="#D3DEE3" />
        <path d="M67.3034 198.284C66.0659 198.284 64.9235 197.807 63.9715 196.949C63.0196 196.091 62.6388 194.852 62.6388 193.613V102.587C62.6388 101.348 63.1148 100.205 63.9715 99.2514C64.8283 98.2983 66.0659 97.917 67.3034 97.917C68.541 97.917 69.6833 98.3936 70.6353 99.2514C71.5873 100.109 71.968 101.348 71.968 102.587V193.613C71.968 194.852 71.4921 195.996 70.6353 196.949C69.6833 197.807 68.541 198.284 67.3034 198.284ZM67.2082 98.9655C66.2563 98.9655 65.3043 99.3468 64.5427 100.014C63.7812 100.681 63.4956 101.634 63.4956 102.683V193.708C63.4956 194.662 63.8763 195.615 64.5427 196.377C65.2091 197.044 66.1611 197.426 67.2082 197.426C68.1602 197.426 69.1122 197.044 69.8737 196.377C70.6353 195.71 70.9209 194.757 70.9209 193.708V102.683C70.9209 101.73 70.5401 100.776 69.8737 100.014C69.2074 99.3468 68.2554 98.9655 67.2082 98.9655Z" fill="black" />
        <path d="M119.281 105.351H15.2311C12.9464 105.351 11.0425 103.445 11.0425 101.158C11.0425 98.87 12.9464 96.9637 15.2311 96.9637H119.186C121.47 96.9637 123.374 98.87 123.374 101.158C123.47 103.445 121.566 105.351 119.281 105.351Z" fill="black" />
        <path d="M119.281 105.83H15.2312C12.6609 105.83 10.5665 103.733 10.5665 101.16C10.5665 98.5863 12.6609 96.4894 15.2312 96.4894H119.186C121.756 96.4894 123.85 98.5863 123.85 101.16C123.946 103.733 121.851 105.83 119.281 105.83ZM15.2312 97.3472C13.1368 97.3472 11.5185 99.0629 11.5185 101.064C11.5185 103.066 13.232 104.782 15.2312 104.782H119.186C121.28 104.782 122.898 103.066 122.898 101.064C122.898 99.0629 121.185 97.3472 119.186 97.3472H15.2312Z" fill="black" />
        <path d="M67.2086 192.854C44.2662 192.854 25.7029 195.809 25.7029 199.526H108.619C108.714 195.904 90.1509 192.854 67.2086 192.854Z" fill="black" />
        <path d="M109.19 200H25.322V199.523C25.322 194.853 46.9316 192.375 67.3037 192.375C87.6758 192.375 109.285 194.853 109.285 199.523V200H109.19ZM26.3692 199.047H108.048C106.334 196.283 90.0557 193.328 67.2085 193.328C44.3613 193.328 28.0827 196.283 26.3692 199.047Z" fill="black" />
        <path d="M89.8655 95.1562L89.1039 91.2483C89.1039 91.0576 88.9135 90.9623 88.8183 90.867C88.6279 90.6764 88.4375 90.6764 88.2471 90.5811C87.9615 90.4857 87.4856 90.3904 87.0096 90.2951C85.5816 90.1045 83.2969 89.9139 80.8218 89.9139H21.5144C19.5152 89.9139 17.7065 90.0092 16.3738 90.1998C15.7074 90.2951 15.1362 90.3904 14.7554 90.4857C14.565 90.5811 14.3746 90.5811 14.1842 90.6764C14.089 90.7717 13.9938 90.7717 13.8986 90.867C13.8034 90.9623 13.7083 91.1529 13.7083 91.3436V91.4389L14.4698 95.3468C14.4698 95.5374 14.6602 95.6327 14.7554 95.7281C14.9458 95.9187 15.1362 95.9187 15.3266 96.014C15.6122 96.1093 16.0882 96.2046 16.5641 96.2999C17.9921 96.4906 20.2768 96.6812 22.7519 96.6812H82.1546C84.1537 96.6812 85.9624 96.5859 87.2952 96.3953C87.9615 96.2999 88.5327 96.2046 88.9135 96.1093C89.1039 96.014 89.2943 96.014 89.4847 95.9187C89.5799 95.8234 89.6751 95.8234 89.7703 95.7281C89.8655 95.6327 89.9607 95.4421 89.9607 95.2515L89.8655 95.1562Z" fill="black" />
        <path d="M82.0597 95.4423H22.657C20.6579 95.4423 18.8492 95.347 17.6116 95.1564C16.9452 95.0611 16.4692 94.9657 16.0885 94.8704C15.9933 94.8704 15.9933 94.8704 15.8981 94.8704L15.2317 91.5344C15.3269 91.5344 15.5173 91.4391 15.7077 91.4391C16.85 91.2485 19.0396 91.0578 21.5147 91.0578H80.9173C82.9164 91.0578 84.7252 91.1532 85.9627 91.3438C86.6291 91.4391 87.1051 91.5344 87.4859 91.6297C87.5811 91.6297 87.5811 91.6297 87.6762 91.6297L88.3426 94.9657C88.2474 94.9657 88.057 95.061 87.8666 95.061C86.7243 95.2517 84.5348 95.4423 82.0597 95.4423Z" fill="#D3DEE3" />
        <path d="M72.8253 86.2895L64.5433 55.7887C63.9721 53.7871 62.7345 52.0715 61.2114 50.8324C59.6882 49.5933 57.7843 48.7355 55.8804 48.7355H5.4262C3.80786 48.7355 2.47511 49.3073 1.52314 50.3558C0.571179 51.309 0 52.7387 0 54.2637C0 54.9309 0.0951965 55.5981 0.28559 56.2653L8.56769 86.7661C9.13887 88.7677 10.3764 90.4833 11.8996 91.7224C13.4227 92.9615 15.3266 93.8194 17.2306 93.8194H67.7799C69.3983 93.8194 70.731 93.2475 71.683 92.199C72.635 91.2458 73.2061 89.8161 73.2061 88.2911C73.1109 87.6239 73.0157 86.9567 72.8253 86.2895Z" fill="black" />
        <path d="M70.5408 91.1508C69.8744 91.818 68.9225 92.2946 67.6849 92.2946H17.2307C15.7076 92.2946 14.1845 91.6274 12.8517 90.5789C11.519 89.5305 10.4718 88.0054 9.99581 86.3851L1.71371 55.8843C1.52332 55.3124 1.52332 54.8359 1.52332 54.264C1.52332 53.0249 1.9041 52.0717 2.57048 51.4045C3.23685 50.7373 4.18882 50.2608 5.42637 50.2608H55.9757C57.4989 50.2608 59.022 50.928 60.3548 51.9764C61.6875 53.0249 62.7347 54.5499 63.2107 56.1703L71.4928 86.671C71.6832 87.2429 71.6832 87.7195 71.6832 88.2914C71.6832 89.4352 71.2072 90.4836 70.5408 91.1508Z" fill="white" />
        <path d="M67.3994 91.2488C66.733 91.916 65.8763 92.2973 64.7339 92.2973H16.8501C15.4221 92.2973 13.899 91.7254 12.6614 90.6769C11.4238 89.6285 10.4719 88.1034 9.9959 86.5784L2.18978 57.7933C2.09459 57.2214 1.99939 56.7449 1.99939 56.2683C1.99939 55.1245 2.38018 54.1714 3.04655 53.5042C3.71293 52.837 4.5697 52.4557 5.71205 52.4557H53.5959C55.0239 52.4557 56.547 53.0276 57.7846 54.076C59.0221 55.1245 60.0693 56.5542 60.4501 58.0793L68.2562 86.8643C68.4466 87.4362 68.4466 87.9128 68.4466 88.3894C68.4466 89.6285 68.0658 90.5816 67.3994 91.2488Z" fill="#D3DEE3" />
      </g>
    </SvgIcon>
  );
};

export default HumanDeskFrame;
