import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Link } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Loader from '../Loader/loader';
import DownloadIcon from '../Icons/DownloadIcon';
import { StyledDownloadIcon } from '../../pages/TeacherToolBox/TeacherToolbox.styled';
import { palette  } from '../../theme/palette';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  downloadFile: boolean;
  pdfLink:string;
  downloadLink?: string;
  renderAnnotationLayer?: boolean;
  renderInteractiveForms?: boolean;
  renderTextLayer?: boolean;
}

const styles = {
  viewPdf: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 6.25,
    '& .react-pdf__Page__textContent': {
      border: `1px solid ${palette.customBackground.disabled}`,
      boxShadow: `5px 5px 5px 1px ${palette.customBackground.disabled}`,
      borderRadius: 5,
    },
    '& .react-pdf__Page': {
      marginBottom: 2.5,
    },
  },
  downloadIcon: {
    position: 'fixed',
    right: 100,
    bottom: 30,
    height: '50px',
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const PDFViewer = ({
  downloadFile,
  pdfLink,
  downloadLink,
  renderAnnotationLayer = true,
  renderInteractiveForms = false,
  renderTextLayer = false,
}:Props) => {
  const [numPages, setNumPages] = useState(null);
  const [loadingFile, setLoading] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setLoading(false);
  };

  return (
    <>
      {
        downloadFile
      && (
        <Box sx={{ ...styles.downloadIcon }}>
          <Link
            href={pdfLink}
            download={downloadLink}
            type="application/pdf"
          >
            <StyledDownloadIcon
              variant="contained"
              color="primary"
            >
              <DownloadIcon />
            </StyledDownloadIcon>
          </Link>
        </Box>
      )
      }
      <Box sx={{ ...styles.viewPdf }}>
        <Document
          file={pdfLink}
          loading=""
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {
            Array.from(Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={1.5}
                renderAnnotationLayer={renderAnnotationLayer}
                renderInteractiveForms={renderInteractiveForms}
                renderTextLayer={renderTextLayer}
              />
            ))
          }
        </Document>
        <Loader open={loadingFile} />
      </Box>
    </>
  );
};

export default React.memo(PDFViewer);
