import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  TableCell,
  CircularProgress,
  Typography,
  TableHead,
} from '@mui/material';
import {
  StyledTableCellFlyover,
  StyledTableContainerFlyover, tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import { SubmissionStatus } from '../../../../generated/graphql';
import NoResults from '../../../../components/NoResults';
import WavingLadyFrame from '../../../../components/Icons/WavingLadyFrame';
import ProgressBar from '../../../../components/ProgressBar';
import useAdminStudentSlideout from './admin-student-slideout-hook';
import { MAX_CHARS_INPROGRESS_STUDENT_NAME, SubmissionProgress } from '../../../../variables/constant';
import TruncateStudentName from './TruncateStudentName';

interface Props {
  benchmarkId: string,
  setStudentCount: Function,
}

export const progressMilestoneList = [
  'Labeling',
  'Speaking',
  'Writing',
];

const InProgress = ({ benchmarkId, setStudentCount }: Props) => {
  const { totalCount, slideoutStudents, loading } = useAdminStudentSlideout({
    benchmarkId: benchmarkId,
    statusList: [
      SubmissionStatus.Started,
      SubmissionStatus.AnnotationCompleted,
      SubmissionStatus.SpeakingCompleted,
    ],
  });
  setStudentCount(totalCount);

  const isTableEmpty = !loading && slideoutStudents?.length === 0;

  return (
    <Box>
      <StyledTableContainerFlyover>
        <Table
          stickyHeader
          aria-label="students submissions table"
          sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
        >
          {(!loading && !isTableEmpty) && (
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align='right' sx={{ paddingLeft: "12px !important", paddingRight: "6px !important" }}>
                  <Box display="grid"
                    gridTemplateColumns="repeat(3, 1fr)"
                    gap='12px'
                    alignItems="center">
                    {progressMilestoneList.map((label) => (
                      <Typography key={label} variant='secondaryLabel' fontWeight='bold' sx={{ textAlign: 'left ' }}>
                        {label}
                      </Typography>
                    ))}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {slideoutStudents && slideoutStudents?.map((student) => (
              <TableRow key={student.student_number}>
                <StyledTableCellFlyover>
                  <TruncateStudentName student={student} maxChars={MAX_CHARS_INPROGRESS_STUDENT_NAME} />
                </StyledTableCellFlyover>
                <StyledTableCellFlyover sx={{ textAlign: 'right', padding: "8px!important" }}>
                  <ProgressBar steps={progressMilestoneList} hiddenStepLabel progressValue={SubmissionProgress[student.submission_status]} />
                </StyledTableCellFlyover>
              </TableRow>
            ))}
            {isTableEmpty && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                >
                  <NoResults
                    label="No Students"
                    description="No students at this step yet"
                    svgComponent={<WavingLadyFrame />}
                  />
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                >
                  <CircularProgress size={28} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainerFlyover>
    </Box>
  );
};

export default InProgress;
