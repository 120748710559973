import React from 'react';
import { Bar, BarChart, ResponsiveContainer, YAxis } from 'recharts';
import { Box } from '@mui/material';

interface Props {
  barData: any,
  barWidth: number,
  barHeight: number,
  barSize: number,
  barDataColor: any,
  yAxisDomain: Array<number>,
}

const ScoringStackedBar = ({
  barData,
  barWidth,
  barHeight,
  barDataColor,
  yAxisDomain,
}: Props) => {

  return (
    <Box sx={{ height: '300px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <Box sx={{ width: '100%' }}>
          <BarChart
            width={barWidth}
            height={barHeight}
            data={[barData]}
            margin={{
              top: 5,
              bottom: 20,
              right: 5,
            }}
          >
            <YAxis tick={false} axisLine={false} domain={yAxisDomain} allowDataOverflow={true}/>
            {
              Object.keys(barData).map((key: string) => {
                return (
                  <Bar
                    key={key}
                    dataKey={key}
                    stackId="a"
                    fill={barDataColor[key]}
                    barSize={10}
                    shape={<rect rx={5} ry={5} />}
                  />
                );
              })
            }
          </BarChart>
        </Box>
      </ResponsiveContainer>

    </Box>
  );
};

export default ScoringStackedBar;
