import { useContext, useState } from 'react';
import { GraphSetData, WritingSkills } from '../../../variables/types';
import { WRITING_SKILL_TYPE_TEXT, SKILL_LINK_TEXT } from '../../../variables/constant';
import { useWritingScoreSummaryQuery } from '../../../generated/graphql';
import { AnalyticsContext } from '../analytics-context';

const useWriting = () => {
  const [currentSkill, setCurrentSkill] = useState<GraphSetData | null>(null);
  const { selectedSection, assignment, selectedSchool } = useContext(AnalyticsContext);
  const [tableData, setTableData] = useState<GraphSetData | null>(null);
  const [showPreview, setShowPreview] = useState(false);

  const togglePreview = () => setShowPreview(!showPreview);

  const { data, loading } = useWritingScoreSummaryQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...(selectedSection ? { sectionIDs: [selectedSection as string] } : {}),
      ...(assignment ? { assignmentIDs: [assignment] } : {}),
      ...(selectedSchool ? { schoolIDs: [selectedSchool as string] } : {}),
    },
  });

  const getData = (skill: WritingSkills) => {
    let totalCount = 0;
    let graphData = null;
    const currentSkillData = data?.writingScoreSummary?.filter((item) => item?.skill_key === skill ?? []);
    const image = currentSkillData?.[0]?.icon_base64;
    if (currentSkillData) {
      graphData = currentSkillData?.[0]?.scores?.reduce((prevValue:any, currentValue:any) => {
        const count = currentValue?.total_count;
        totalCount += count;
        if (currentValue?.skill_label) {
          return [...prevValue, {
            ...(currentValue),
            numberOfStudents: count,
            skillType: WRITING_SKILL_TYPE_TEXT[skill],
            students: currentValue?.students,
            skillKey: currentValue?.skill_label,
            skillLinkText: SKILL_LINK_TEXT,
          }];
        }
        return prevValue;
      }, []);
    }
    return {
      totalCount, graphData, image,
    };
  };

  const { graphData: descriptionData, totalCount: descriptionCount, image: descriptionImage } = getData(WritingSkills.DescriptionAndExplanation);
  const { graphData: vocabData, totalCount: vocabCount, image: vocabImage } = getData(WritingSkills.VocabAndGrammar);
  const { graphData: pointsAndReasonData, totalCount: pointsAndReasonCount, image: pointsAndReasonImage } = getData(WritingSkills.PointsAndReason);

  return ({
    vocabData,
    vocabCount,
    vocabImage,
    pointsAndReasonData,
    pointsAndReasonCount,
    pointsAndReasonImage,
    descriptionData,
    descriptionCount,
    descriptionImage,
    loading,
    currentSkill,
    setCurrentSkill,
    getData,
    tableData,
    setTableData,
    togglePreview,
    showPreview,
  });
};

export default useWriting;
