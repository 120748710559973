import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../../../components/Layout';
import useRedirect from '../../../hooks/useRedirect';
import useSpeakingWritingAnchors, { SpeakingWritingCard } from './speaking-writing-anchors-list-hook';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import IconCard from '../../../components/IconCard';
import { StyledStickyBreadcrumbBox } from '../TeacherToolbox.styled';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';

const breadcrumb = [{
  label: 'Toolbox',
  route: '/toolbox',
},
{
  label: 'SPEAKING AND WRITING ANCHORS',
}];

const SpeakingWritingAnchors = () => {
  const { navigateTo: navigateToPath } = useRedirect();
  const {
    speakingWritingAnchorsList,
  } = useSpeakingWritingAnchors();
  const navigateTo = (route: string) => () => {
    navigateToPath(route);
  };
  return (
    <Layout>
      <StyledFlexColBoxContainer>
        <StyledStickyBreadcrumbBox>
          <Typography variant="pageTitle">SPEAKING AND WRITING ANCHORS</Typography>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
        </StyledStickyBreadcrumbBox>
        <Box
          mt={3}
          sx={{
            display:'grid',
            gridTemplateColumns:'repeat(3, 360px)',
            gridGap: 32,
          }}
        >
          {
            speakingWritingAnchorsList.map((tileProp: SpeakingWritingCard) => (
              <IconCard
                key={tileProp.title}
                title={tileProp.title}
                Icon={tileProp.Icon}
                buttonTitle={tileProp.buttonTitle}
                onClick={navigateTo(tileProp.redirectionLink)}
              />
            ))
          }
        </Box>
      </StyledFlexColBoxContainer>
    </Layout>
  );
};

export default React.memo(SpeakingWritingAnchors);
