import {
  BenchmarkType,
  OverallBenchmarkIndicatorInput,
  OverallBenchmarkIndicatorResult,
  OverallBenchmarkIndicatorRubricLineItem,
  useOverallBenchmarkIndicatorScoresQuery,
} from '../../../../generated/graphql';
import { useTeacherBenchmarksDashboardContext } from '../../teacherbenchmarks-dashboard-hook';
import { useEffect, useState } from 'react';
import { RubricTitle, TeacherBenchmarkDashBoard } from '../../../../variables/constant';
import useUserInfo from '../../../../utils/useUserInfo';
import { DashboardWidgetsDataStatusProps } from '../../../AdminAnalytics/admin-analytics-hook';
import { openSnackbar } from '../../../../components/Notifier';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { NotifierType } from '../../../../variables/types';

type OverallBenchmarkIndicatorScoreProps = {
  selectedBenchmarkType?: BenchmarkType;
};

type BarChartData = {
  rubricId: string;
  rubricTitle: string;
  rubricScoreAverage: number;
  rubricIcon: string;
  fill: string;
};

const useOverallBenchmarkIndicatorScore = ({ selectedBenchmarkType } : OverallBenchmarkIndicatorScoreProps) => {
  const {
    selectedGrades,
    selectedClasses,
    selectedSchools,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
  } = useTeacherBenchmarksDashboardContext();
  const [benchmarkIndicatorBarChartData, setBenchmarkIndicatorBarChartData] = useState<any>();

  const [ hasData, setHasData ] = useState<boolean>(false);
  const [stackedBarData, setStackedBarData] = useState<any>();
  const [stackedBarDataColor, setStackedBarDataColor] = useState<any>();
  const [scoreSchemes, setScoreSchemes] = useState<any>(0);
  const [yAxisDomain, setYAxisDomain] = useState<Array<number>>([]);
  const [yAxisTicks, setYAxisTicks] = useState<Array<number>>([]);
  const queryInput: OverallBenchmarkIndicatorInput = {
    benchmark_type: selectedBenchmarkType!,
    section_ids: selectedClasses || [],
    school_ids: selectedSchools || [],
    grade_ids: selectedGrades || [],
  };
  const [isLoadingUserInfo, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const scoreSchemeType = user?.score_scheme?.title_key;

  const {
    data: benchmarkIndicatorScores,
    loading: benchmarkIndicatorScoreLoading,
    error: benchmarkIndicatorScoreErr,
  } = useOverallBenchmarkIndicatorScoresQuery(
    {
      fetchPolicy: 'network-only',
      skip: skipGraphQLRequest(),
      variables: {
        input: queryInput,
      },
    });

  useEffect(() => {
    if (benchmarkIndicatorScoreErr){
      openSnackbar({ message: getErrorMessage(benchmarkIndicatorScoreErr) }, NotifierType.Error);
    }

    const BarChartData: BarChartData[] = [];
    benchmarkIndicatorScores?.overallBenchmarkIndicatorScores?.forEach((benchmarkIndicatorScore: OverallBenchmarkIndicatorResult) => {
      benchmarkIndicatorScore?.rubric_line_items?.forEach((item: OverallBenchmarkIndicatorRubricLineItem) => {
        BarChartData.push({
          rubricId: item?.rubric_id,
          rubricTitle: item?.rubric_title === RubricTitle.DescriptionAndExplanation.backend ?
            RubricTitle.DescriptionAndExplanation.frontend : item?.rubric_title,
          rubricScoreAverage: item?.rubric_score_average,
          rubricIcon: item?.rubric_icon,
          fill: item?.rubric_score_color,
        });
      });
    });

    const scores : any = {};
    const colors : any = {};
    let yAxisMaxDomain: number = 0;
    scoreScheme?.forEach((score: any, index: number) => {
      scores[score?.title_key] = scoreScheme?.[index - 1] ?
        score?.range_end - scoreScheme?.[index - 1].range_end :
        score?.range_end;
      colors[score?.title_key] = score?.color_code;
      yAxisMaxDomain = Math.max(yAxisMaxDomain, score?.score_value);
    });
    setStackedBarData(scores);
    setStackedBarDataColor(colors);
    setBenchmarkIndicatorBarChartData(BarChartData);
    setScoreSchemes(scoreScheme);
    setYAxisDomain([1, yAxisMaxDomain]);
    setYAxisTicks([...Array(yAxisMaxDomain).keys()].map((i) => i + 1));
  }, [benchmarkIndicatorScores, scoreScheme, benchmarkIndicatorScoreErr]);

  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: TeacherBenchmarkDashBoard.BenchmarkSummary.OverallBenchmarkIndicatorWidget.name,
      loading: benchmarkIndicatorScoreLoading,
      dataMissing: !(benchmarkIndicatorBarChartData?.length > 0),
    };
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    setHasData(benchmarkIndicatorBarChartData?.length > 0 );
  }, [benchmarkIndicatorBarChartData]);
  return {
    benchmarkIndicatorScoreLoading,
    hasData,
    benchmarkIndicatorBarChartData,
    stackedBarData,
    stackedBarDataColor,
    isLoadingUserInfo,
    scoreSchemes,
    yAxisDomain,
    yAxisTicks,
    scoreSchemeType,
  };
};

export default useOverallBenchmarkIndicatorScore;
