import { useTeacherListForTeacherSchoolQuery, useTeachersFilterQuery } from '../../generated/graphql';
import useRole from '../../hooks/useRole';

const teacherVariables = (stateId?: string, districtId?: string, schoolId?: string) => {
  let variables = {};
  if (stateId) {
    variables = { ...variables, stateID: stateId as string };
  }
  if (districtId) {
    variables = { ...variables, districtID: districtId as string };
  }
  if (schoolId) {
    variables = { ...variables, schoolID: schoolId as string };
  }
  return variables;
};

interface Props {
  stateId?: string
  districtId?: string
  schoolId?: string
}

const useTeachersFilter = ({ stateId, districtId, schoolId }: Props) => {
  const { isTeacher } = useRole();
  const { data, loading } = useTeachersFilterQuery({
    fetchPolicy: 'network-only',
    skip: isTeacher,
    variables: {
      ...(teacherVariables(stateId, districtId, schoolId)),
    },
  });
  const { data: teacherListForTeacherSchool, loading: teacherListForTeacherSchoolLoading } = useTeacherListForTeacherSchoolQuery({
    fetchPolicy: 'network-only',
    skip: !isTeacher || !schoolId,
    variables: {
      ...(teacherVariables(stateId, districtId, schoolId)),
    },
  });

  return {
    teachers: (!isTeacher ? data?.teacherList?.nodes : teacherListForTeacherSchool?.teacherListForTeacherSchool?.nodes) ?? [],
    loading: loading || teacherListForTeacherSchoolLoading,
  };
};

export default useTeachersFilter;
