/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StateIcon = (props: any) => (
  <SvgIcon
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    {...props}
  >
    <g id="icon-state">
      <path d="M9.4 2L9 0H0V17H2V10H7.6L8 12H15V2H9.4Z" fill={props?.pathfill} />
    </g>
  </SvgIcon>
);

export default StateIcon;
