import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  TableCell,
  CircularProgress,
  TableHead, Typography,
} from '@mui/material';
import {
  StyledTableCellFlyover,
  StyledTableContainerFlyover, tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import NoResults from '../../../../components/NoResults';
import WavingLadyFrame from '../../../../components/Icons/WavingLadyFrame';
import BenchmarkStatus from '../../../../components/BenchmarkStatus';
import MarkCompleteIcon from '../../../../components/Icons/MarkCompleteIcon';
import { BenchmarkSubmissionTypes, MAX_CHARS_SUBMITTED_STUDENT_NAME } from '../../../../variables/constant';
import { useHistory } from 'react-router-dom';
import { SubmissionStatus } from '../../../../generated/graphql';
import useTeacherBMStudentSlideout from './teacher-student-slideout-hook';
import useBenchmarksQueueHook from '../../../ScoreTaskBenchmark/score-benchmark-queue-hook';
import TruncateStudentName from '../../../AdminBenchmarks/List/StudentListSlideout/TruncateStudentName';


interface Props {
  benchmarkId: string,
  benchmarkGradedBy?: string,
  setStudentCount: Function,
}

const Submitted = ({ benchmarkId, benchmarkGradedBy, setStudentCount }: Props) => {
  const history = useHistory();
  const { totalCount, slideoutStudents, loading } = useTeacherBMStudentSlideout({
    benchmarkId: benchmarkId,
    statusList: [
      SubmissionStatus.Submitted,
      SubmissionStatus.Reviewed,
    ],
  });
  setStudentCount(totalCount);

  const isTableEmpty = !loading && slideoutStudents?.length === 0;

  const { initializeSubmissionQueue } = useBenchmarksQueueHook({});

  const handleRedirection = (submissionID: string, status: any) =>  () => {
    const submissionQueueIDs: any[] = slideoutStudents?.filter(
      (student)=> student.submission_status === status)?.map(
      (student) => student.submission_id) ?? [];
    initializeSubmissionQueue(submissionQueueIDs, submissionID);
    if (submissionID) history.push({
      pathname: `/tasks/${submissionID}/benchmark/score/Speaking`,
      state: 'bmList',
    });
  };

  return (
    <Box>
      <StyledTableContainerFlyover>
        <Table
          stickyHeader
          aria-label="students submissions table"
          sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
        >
          <TableHead>
            <TableRow>
              <TableCell align='right' colSpan={2}>
                <Typography variant='secondaryLabel' fontWeight='bold'>
                    Benchmark Graded
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slideoutStudents && slideoutStudents?.map((student) => {
              const isHyperlink = benchmarkGradedBy === 'teacher' || student.submission_status === BenchmarkSubmissionTypes.Reviewed;
              return (
                <TableRow key={student.student_number}>
                  <StyledTableCellFlyover>
                    <TruncateStudentName
                      student={student}
                      maxChars={MAX_CHARS_SUBMITTED_STUDENT_NAME}
                      typographySx={{ color: isHyperlink ? 'primary.main' : 'customBlack.lightBlack', cursor: isHyperlink ? 'pointer' : 'default' }}
                      handleRedirection={benchmarkGradedBy === 'teacher' || student.submission_status === BenchmarkSubmissionTypes.Reviewed ? handleRedirection : () => { }}
                    />
                  </StyledTableCellFlyover>
                  <StyledTableCellFlyover sx={{ textAlign: 'right' }}>
                    {student.submission_status === BenchmarkSubmissionTypes.Reviewed ? (
                      <BenchmarkStatus iconPresent abbreviation={<MarkCompleteIcon />} tileStatus={BenchmarkSubmissionTypes.Reviewed}/>
                    ) : benchmarkGradedBy === 'flashlight' && <Typography variant='secondaryLabel' fontWeight='bold'>
                      Grade Pending
                    </Typography>}
                  </StyledTableCellFlyover>
                </TableRow>
              );
            })}
            {isTableEmpty && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                >
                  <NoResults
                    label="No Students"
                    description="No students at this step yet"
                    svgComponent={<WavingLadyFrame />}
                  />
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                >
                  <CircularProgress size={28} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainerFlyover>
    </Box>
  );
};

export default Submitted;
