import {
  useBenchmarkDetailQuery, useBenchmarkEditFormMutation, useGetAcademicSessionQuery,
} from '../../../generated/graphql';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { cleanDate, toUTC, toUTCEOD } from '../../../utils/dateFormat';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import getErrorMessage from '../../../utils/getErrorMessage';
import {
  BENCHMARK_DOES_NOT_EXIST_ERROR, BENCHMARK_DOES_NOT_EXIST_MESSAGE,
} from '../../../variables/constant';
import useRedirect from '../../../hooks/useRedirect';
import { useState } from 'react';

export interface RouteParamProp {
  id?: string,
}

export type BenchmarkEditInput = {
  id?: string,
  start_at: Date,
  close_at: Date,
};

const useBenchmarksDetail = ( ) => {
  const { id } = useParams<RouteParamProp>();
  const [openNavigationModal, setOpenNavigationModal] = useState(false);
  const { navigateTo } = useRedirect();
  const [editBenchmark, { loading: editBenchmarkInProgress }] = useBenchmarkEditFormMutation();
  const {
    handleSubmit,
    errors,
    control,
    watch,
    getValues,
  } = useForm<BenchmarkEditInput>();

  const { data, loading: gettingBenchmarksLoader } = useBenchmarkDetailQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id!,
    },
  });

  const { data: academicSession, loading: academicSessionLoading } = useGetAcademicSessionQuery({
    fetchPolicy: 'network-only',
  });

  const benchmarks = data?.benchmark;
  const benchmarkID = benchmarks?.id;

  const edit = async (benchmark: BenchmarkEditInput) =>{
    try {
      await editBenchmark( {
        variables:{
          input:{
            id: benchmarkID!,
            start_at : toUTC(cleanDate(benchmark?.start_at) as Date),
            close_at: toUTCEOD(cleanDate(benchmark?.close_at) as Date),
          },
        },
      });
      navigateTo('/benchmarks');
      openSnackbar({
        message: 'The benchmark has been updated and newly added students will also receive this benchmark',
      }, NotifierType.Success);
    } catch (err) {
      if (getErrorMessage(err) === BENCHMARK_DOES_NOT_EXIST_ERROR) {
        openSnackbar({
          message: BENCHMARK_DOES_NOT_EXIST_MESSAGE,
        }, NotifierType.Error);
      } else {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
  };

  return {
    loading: gettingBenchmarksLoader || editBenchmarkInProgress || academicSessionLoading,
    benchmarks,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    edit,
    navigateTo,
    academicSessionEndDate: academicSession?.getAcademicSession?.session_end_date,
    openNavigationModal,
    setOpenNavigationModal,
  };
};

export default useBenchmarksDetail;
