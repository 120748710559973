import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import List from './List';
import { StyledFlexColBoxContainer } from '../../../../components/Common/Common.styled';
import { DistrictWideComparisonsStylesCSS } from '../DistrictWideComparisons.styled';

import useSchoolSpecificDetails from './school-specific-details-hook';
import { DistrictWideSchoolDetails, DistrictWideSchoolSpecificDetailsSortType } from '../../../../generated/graphql';

interface Props {
  school: DistrictWideSchoolDetails,
  toggleDrawer: (event: React.MouseEvent) => void,
}

const SchoolSpecificDetails = ({ school, toggleDrawer }: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);

  const schoolId = school?.school_id;
  const schoolName = school?.school_name;

  const {
    loading,
    queryInput, handleSort,
    pagination, handlePageChange,
    districtWideSchoolSpecificDataCount, districtWideSchoolSpecificData: teachers, isTableEmpty,
  } = useSchoolSpecificDetails({ schoolId: schoolId });

  const closeFlyover = (event: React.MouseEvent) => {
    pagination.unsetPage();
    toggleDrawer(event);
  };

  return (
    <StyledFlexColBoxContainer role="presentation">
      <Box sx={styles.drawerHeader}>
        <Grid container>
          <Grid item xs={11}>
            <Box>
              <Box>
                <Typography
                  variant='font22'
                  sx={{ color: theme.palette.customWhite.main }}
                >
                  School
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='font16'
                >
                  {schoolName}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} container justifyContent="flex-end">
            <IconButton
              aria-label="close school specific drawer" component="button" size="large" edge="end"
              sx={{ ...styles.drawerHeaderIcon, paddingRight: 2 }}
              onClick={closeFlyover}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading!}
          totalCount={districtWideSchoolSpecificDataCount}
          pagination={pagination}
          limit={queryInput.limit!}
          sort={queryInput.sort! as DistrictWideSchoolSpecificDetailsSortType}
          order={queryInput.orderBy!}
          teachers={teachers}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
        />
      </Box>
    </StyledFlexColBoxContainer>
  );
};

export default SchoolSpecificDetails;
