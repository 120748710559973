import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LearningGoalsStylesCSS } from './LearningGoals.styled';
import { ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR, AdminDashboards } from '../../../variables/constant';
import { DashboardContext, DashboardContextInit, DashboardProps } from '../admin-analytics-hook';
import InsufficientDataBackdrop from '../InsufficientDataBackdrop';
import AnalyticsFilters from '../AnalyticsFilters';
import CompletedAssignmentLearningGoalsWidget from './CompletedAssignmentLearningGoals';
import GoalPerformanceWidget from './GoalPerformanceWidget';
import GoalsByIndicatorWidget from './GoalsByIndicatorWidget';
import GoalsAndScoresByIndicatorWidget from './GoalsAndScoresByIndicatorWidget';
import GoalAchievementGraph from './GoalAchievementGraph';
import Loader from '../../../components/Loader/loader';
import { toReadableFormat } from '../../../utils/dateFormat';

const LearningGoals = ({
  isDistrictAdmin,
  myDistrictDataLoading,
  myDistrictData,
  isSchoolAdmin,
  mySchoolDataLoading,
  mySchoolData,
  academicSessionLoading,
  academicSession,
  lastSnowflakeUpdateTimeData,
  getLastSnowflakeUpdateTimeLoading,
}: DashboardProps) => {
  const theme = useTheme();
  const styles = LearningGoalsStylesCSS(theme);
  const {
    loading, setLoading,
    getLocalStorageDashboardFilters, setLocalStorageDashboardFilters, localDashboardFilters,
    dashboardFilters,
    currentFilters, setCurrentFilters,
    analyticsFilters, setAnalyticsFilters,
    startDateInit,
    startDateSelected, setStartDateSelected,
    endDateSelected, setEndDateSelected,
    dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
    areWidgetsLoading, setAreWidgetsLoading,
  } = DashboardContextInit({
    dashboardWidgets: AdminDashboards.learningGoals,
    isDistrictAdmin,
    myDistrictDataLoading,
    myDistrictData,
    isSchoolAdmin,
    mySchoolDataLoading,
    mySchoolData,
    academicSessionLoading,
    academicSession,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
  });

  return (
    <DashboardContext.Provider value={{
      loading, setLoading,
      isSchoolAdmin, mySchoolData,
      isDistrictAdmin, myDistrictData,
      dashboardFilters,
      currentFilters, setCurrentFilters,
      analyticsFilters, setAnalyticsFilters,
      academicSessionLoading, academicSession, startDateInit,
      startDateSelected, setStartDateSelected,
      endDateSelected, setEndDateSelected,
      dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
      isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
      areWidgetsLoading, setAreWidgetsLoading,
      getLastSnowflakeUpdateTimeLoading, lastSnowflakeUpdateTimeData,
      getLocalStorageDashboardFilters, setLocalStorageDashboardFilters, localDashboardFilters,
    }}>
      <Loader open={loading || !academicSession || !analyticsFilters || getLastSnowflakeUpdateTimeLoading} />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={styles.dashBoardContainer}
        id="learning-goals-dashboard"
      >
        <Box sx={{ marginBottom: 2 }} id='learning-goals-dashboard-header'>
          <Typography variant="pageTitle">
            Learning Goals Dashboard
          </Typography>
          {!!lastSnowflakeUpdateTimeData && <Box>
            <Typography variant="toolTipGreyText">
              Last Updated: {toReadableFormat(lastSnowflakeUpdateTimeData, ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR, 'en-US').replace('at', '')} UTC
            </Typography>
          </Box>}
        </Box>
        {!!academicSession && !!analyticsFilters && (
          <Box>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} p={2}>
              <AnalyticsFilters />
            </Grid>
            <Grid container spacing={2} sx={{ position: 'relative' }}>
              <InsufficientDataBackdrop dataMissing={!loading! && isDataMissingInAllWidgets!} />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <GoalsAndScoresByIndicatorWidget
                  enableTooltip
                  alignTitle
                  tooltipTitle={AdminDashboards.learningGoals.GoalsAndScoresByIndicatorWidget.tooltipText}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <GoalAchievementGraph />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CompletedAssignmentLearningGoalsWidget />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <GoalPerformanceWidget
                  enableTooltip
                  alignTitle
                  tooltipTitle={AdminDashboards.learningGoals.GoalPerformanceWidget.tooltipText}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <GoalsByIndicatorWidget
                  enableTooltip
                  alignTitle
                  tooltipTitle={AdminDashboards.learningGoals.GoalsByIndicatorWidget.tooltipText}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </DashboardContext.Provider>
  );
};

export default LearningGoals;
