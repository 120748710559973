import { useTheme } from '@mui/material';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';

const DATA = [
  {
    name: 'Performance',
    one: 1,
    two: 1,
    three: 1,
    four: 1,
  },
];

interface Props {
  score: number;
  height?: number;
  width?: number;
  barSize?: number;
  strokesWidth?: number;
}

const BarStackGraphHorizontal = ({ score, height, width, barSize, strokesWidth }: Props) => {
  const { palette } = useTheme()
  const getColor = (barNumber: number) => {
    let color = null;
    switch (score) {
      case 1:
        color = palette.fontColors.buttonFontRed;
        break;
      case 2:
        color = palette.customYellow.main;
        break;
      case 3:
        color = palette.fontColors.buttonFontGreen;
        break;
      case 4:
        color =  palette.customBlue.aquaBlue;
        break;
      default:
        color = palette.customBackground.disabled;
    }

    return score >= barNumber ? color : '#e5e5e5';
  };

  return (
    <BarChart
      width={width || 70}
      height={height || 70}
      data={DATA}
      layout="vertical"
      style={{ stroke: `${palette.customWhite.main}`, strokeWidth: strokesWidth || 3 }}
    >
      <XAxis hide type="number" />
      <YAxis
        type="category"
        hide
        dataKey="name"
        stroke={palette.customWhite.main}
        fontSize="5"
      />
      <Bar barSize={barSize || 42} dataKey="one" stackId="a" fill={getColor(1)} />
      <Bar barSize={barSize || 42} dataKey="two" stackId="a" fill={getColor(2)} />
      <Bar barSize={barSize || 42} dataKey="three" stackId="a" fill={getColor(3)} />
      <Bar barSize={barSize || 42} dataKey="four" stackId="a" fill={getColor(4)} />
    </BarChart>
  );
};

export default BarStackGraphHorizontal;
