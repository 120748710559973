import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Slider from '@mui/material/Slider';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.customWhite.main,
  fontSize: 12,
  padding: 10,
}));

export const ControlledSlider = styled(Slider)(({ theme }) => ({
  root: {
    height: 4,
    width: '90%',
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: theme.palette.customBlue.primaryBlue,
    border: `2px solid ${theme.palette.fontColors.fontBlack}`,
    marginTop: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    background: theme.palette.customRed.progressPrimary,
  },
})) ;
