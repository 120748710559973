import React from 'react';
import {
  Typography,
  Box,
  Button, styled,
} from '@mui/material';
import PlayIcon from '../Icons/PlayIcon';
import { palette  } from '../../theme/palette';

interface Props {
  title: string;
  description: string;
  thumbnailURL: string;
  onClick: () => void;
}

const videoTileStyles = {
  actionButton: {
    width: '360px',
    padding: 0,
    textAlign: 'left',
    minHeight: '440px',
    border:`2px solid ${palette.customBackground.disabled}`,
    cursor: 'pointer',
    borderRadius: '16px',
    borderBottomWidth: '4px',
  },
  playIconButton: {
    width: '60px',
    height: '60px',
    position: 'absolute',
    opacity: 0.9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: palette.gradients.controlGradient,
    borderRadius: '50%',
    top: '32%',
    left: '40%',
  },
  image: {
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '14px 14px 2px 2px',
  },
};

export const StyledTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: '20px',
  color: theme.palette.fontColors.fontBlack,
  marginTop: theme.spacing(1),
  textTransform: 'capitalize',
  lineHeight: '27px',
  letterSpacing: '0.1px',
}));

const VideoTileBox = ({
  title,
  description,
  thumbnailURL,
  onClick,
}: Props) => {
  const buttonContent = (
    <Box onClick={onClick}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ ...videoTileStyles.image }} src={thumbnailURL} alt={title} component='img'/>
        <Box sx={{ ...videoTileStyles.playIconButton }}>
          <PlayIcon fontSize="large" />
        </Box>
      </Box>
      <Box sx={{ p: '22px', minHeight: '202px' }}>
        <StyledTypo>
          {title}
        </StyledTypo>
        <Typography component='p' variant='sFormHelperText' sx={{  mt: '8px', textTransform: 'none', lineHeight: '22px' }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ ...videoTileStyles.actionButton }}>
      {buttonContent}
    </Box>
  );
};
export default React.memo(VideoTileBox);
