import React, { useEffect } from 'react';
import { Box, CheckboxProps, Divider, InputAdornment, SxProps, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SchoolsIcon from '../Icons/SchoolsIcon';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import useSectionsFilter from '../SectionsFilter/sections-filter-hook';
import { SectionFilterFragmentFragment } from '../../generated/graphql';

interface Props {
  error?: boolean;
  value?: any[] | null;
  onChange?: (data: SectionFilterFragmentFragment[] | string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: any;
  limitTags?: number;
  isStartAdornment?: boolean;
  hideGroups?: boolean,
  schoolId?: string[] | null,
  zIndexPopper?: number;
}

const styles = {
  textField: {
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    '& input.MuiInputBase-input.Mui-disabled': {
      backgroundColor: palette.customWhite.inputDisabled,
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '30px !important',
      zIndex: 2, // Ensuring the input field is above other elements
    },
  },
  box: {
    backgroundColor: palette.customWhite.main,
    height: '45px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

const MultiSelectTeacherSectionFilter = ({
  placeholder = '',
  onChange,
  value,
  errorMessage,
  disabled,
  limitTags = 2,
  isStartAdornment = true,
  hideGroups = false,
  schoolId,
  zIndexPopper = 1300,
}: Props) => {

  const allSectionsOption: SectionFilterFragmentFragment = {
    id: 'all',
    name: 'Select All',
    course: '',
    subject: '',
  };
  const {
    sections,
    loading,
  } = useSectionsFilter({ hideGroups, schoolId });

  const sectionOptions = sections?.length ? [allSectionsOption, ...sections] : sections;
  const [checkAll, setCheckAll] = React.useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onSelectChange = (_: any, sectionList: SectionFilterFragmentFragment[] | undefined = []) => {
    const sectionListIds = sectionList?.map((section: SectionFilterFragmentFragment) => section?.id);
    onChange?.(sectionListIds as string[] || []);
  };
  const filterIdsWithSections = (selectedValue: any[] | undefined | null) => {
    const sectionIds = sections?.map((section: any) => section.id) ?? [];
    const filteredIds = selectedValue?.filter((id) => sectionIds.includes(id)) ?? [];
    const sectionList = sections?.filter((section) => filteredIds.includes(section?.id));
    if (selectedValue?.length !== filteredIds.length && sections) onChange?.(sectionList?.map((section: any) => section?.id) ?? []);
    if (selectedValue?.length === 0 || selectedValue?.length !== sections?.length) {
      setCheckAll(false);
    } else if (selectedValue?.length === sections?.length) {
      setCheckAll(true);
    }
  };
  useEffect(() => {
    filterIdsWithSections(value);
  }, [value]);

  const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      onChange?.(sections?.map((section: any) => section?.id) as string[]);
    } else {
      onChange?.([]);
    }
  };
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={limitTags}
      options={sectionOptions ?? []}
      slotProps={{ popper: { sx: { zIndex: zIndexPopper } } }}
      getOptionLabel={(option: SectionFilterFragmentFragment) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={disabled}
      onChange={onSelectChange}
      value={sections?.filter((section: SectionFilterFragmentFragment) => value?.includes(section?.id!))}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Box sx={{ maxWidth: '120px' }}>
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.name ?? ''}</Typography>}
              {...getTagProps({ index })}
            />
          </Box>
        ))
      }
      renderOption={(props, option: any, { selected }) => (
        option?.id === 'all' ? (
          <>
            <Box sx={{ ...styles.box } as SxProps} key={option?.id}>
              <Checkbox
                checked={checkAll}
                onChange={checkAllChange}
                id="check-all"
                onMouseDown={(e) => e.preventDefault()}
              />
              Select All
            </Box>
            <Divider />
          </>
        ) : <Box component="li" {...props} key={option?.id}>
          <Checkbox
            color={'primary' as CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
          />
          {`${option?.name ?? ''}`}
        </Box>
      )}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            sx={{ ...styles.textField }}
            placeholder={params.InputProps.startAdornment ? undefined : placeholder.length > 1 ? placeholder : 'Select Classes'}
            error={!!value}
            helperText={value?.length === 0 ? errorMessage?.schoolHelperText : ''}
            required={value?.length === 0}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '300px', overflow: 'scroll', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: isStartAdornment ? (
                <>
                  <InputAdornment position="start">
                    <SchoolsIcon
                      pathfill={palette.customBlue.primaryBlue}
                      sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ) : <>{params.InputProps.startAdornment}</>,
            }}
          />
        </>
      )}
    />
  );
};

export default MultiSelectTeacherSectionFilter;
