import React, { useState } from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Box,
  IconButton, Modal, SxProps,
} from '@mui/material';
import FullScreenIcon from '../Icons/FullScreenIcon';
import { palette  } from '../../theme/palette';
import PencilIcon from '../Icons/PencilIcon';

interface Props {
  imageSrc : string,
  cardTitle: string,
  selected?: string,
  editable?: boolean,
  handleEdit?: () => void,
  thumbnailImageSrc:string,
}

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const styles = {
  root: {
    width: '295px',
    height: '210px',
    position: 'relative',
    marginBottom: '28px',
    borderRadius: '17px',
    marginRight: '16px',
  },
  cardContentContainer: {
    width: '100%',
    opacity: 0.8,
    bottom: '-1px',
    position: 'absolute',
  },
  cardContentText: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    width: '100%',
    color: palette.customWhite.main,
    backgroundColor: palette.customBlack.fontBlack,
    letterSpacing: 1,
    paddingLeft: '16px',
  },
  wrapperButton: {
    background: palette.customWhite.main,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  fullScreenButton: {
    top: 'unset',
    bottom: '30px',
  },
  fullScreenIcon: {
    height: '20px',
    width: '20px',
  },
  paper: {
    position: 'absolute',
    width: '1000px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullScreenImage: {
    width: '100%',
    height: 'auto',
  },
  editButton: {
    top: '10px',
    bottom: 'unset',
  },
};


const ImageCard = ({
  imageSrc,
  cardTitle,
  selected,
  editable = false,
  handleEdit,
  thumbnailImageSrc,
}: Props) => {
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fullScreenPreview = (
    <Box style={modalStyle} sx={{ ...styles.paper }}>
      <Box
        component={'img'}
        src={imageSrc}
        sx={{ ...styles.fullScreenImage }}
      />
    </Box>
  );

  return (
    <>
      <Card sx={{ ...styles.root,  borderRadius: '17px' }}>
        <CardActionArea disableRipple>
          <CardMedia
            image={thumbnailImageSrc ? thumbnailImageSrc : imageSrc}
            component="img"
            sx={{ height: (selected ? 202 : 210), width: '100%' }}
          />
          <Box sx={{ ...styles.cardContentContainer }}>
            <Typography sx={{ ...styles.cardContentText }}>
              {cardTitle}
            </Typography>
          </Box>
        </CardActionArea>
        <>
          {editable && <Box sx={{ ...styles.wrapperButton, ...styles.editButton } as SxProps}>
            <IconButton
              aria-label="Image Edit"
              onClick={handleEdit}
              disableTouchRipple
              disableRipple
            >
              <PencilIcon
                pathFill={palette.customBlack.fontBlack}
                sx={{ ...styles.fullScreenIcon }}
              />
            </IconButton>
          </Box>}
          <Box sx={{ ...styles.wrapperButton, ...styles.fullScreenButton } as SxProps}>
            <IconButton
              aria-label="Image Preview"
              onClick={handleOpen}
              disableTouchRipple
              disableRipple
            >
              <FullScreenIcon
                pathFill={palette.customBlack.fontBlack}
                sx={{ ...styles.fullScreenIcon }}
              />
            </IconButton>
          </Box>
        </>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
      >
        {fullScreenPreview}
      </Modal>
    </>
  );
};
export default React.memo(ImageCard);
