import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { CUSTOM_TOOLTIP_HEIGHT, CUSTOM_TOOLTIP_WIDTH } from '../../../variables/constant';
import { StyledPerformanceLink } from './tooltip-styled';
import { palette  } from '../../../theme/palette';
import { SxProps } from '@mui/system';

interface Props {
  data: any
  onLinkClick?: (data?: any) => void
  tooltipType?: string
  iconString?: string
}


const tooltipStyles = {
  boxStyles: {
    display:'flex',
    flexDirection:'column',
    padding: '16px',
    borderRadius: '12px',
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customBackground.disabled}`,
    minHeight: CUSTOM_TOOLTIP_HEIGHT,
    width: CUSTOM_TOOLTIP_WIDTH,
    alignItems: 'left',
  },
  title: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 800,
    marginBottom: '8px',
  },
  description: {
    fontSize: '14px',
    lineHeight: '19px',
    marginBottom: '8px',
  },
};

const PerformanceTooltip = ({ data, onLinkClick = () => {}, iconString }: Props) => {
  const handleClick = () => {
    const number = data?.numberOfStudents;
    const dataWithImage = data;
    dataWithImage.iconString = iconString;
    if (number > 0) {
      onLinkClick(dataWithImage);
    }
  };

  const toolTipContent = (
    <>
      <Typography sx={{
        ...tooltipStyles.title,
      }}>
        {`Level ${data?.skill_level}: ${data?.skill_label} ${data?.skillType}`}
      </Typography>
      <Typography sx={{
        ...tooltipStyles.description,
      }}>
        {data?.skill_description}
      </Typography>
      <StyledPerformanceLink
        color={data?.color ?? ''}
        numberOfStudents={data?.numberOfStudents ?? 0}
        onClick={handleClick}>
        {`${data?.numberOfStudents} ${data?.numberOfStudents > 1 ? `${data?.skillLinkText}s` : data?.skillLinkText}`}
        {' '}
        {data?.numberOfStudents > 0 && <span>&rarr;</span>}
      </StyledPerformanceLink>
    </>
  );

  return (
    <Box sx={{ ...tooltipStyles.boxStyles as SxProps }}>
      {toolTipContent}
    </Box>
  );
};

export default PerformanceTooltip;
