import React from 'react';
import { Box, Checkbox, CheckboxProps, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import CheckboxCheckedIcon from '../../../components/Icons/CheckboxCheckedIcon';
import { palette } from '../../../theme/palette';
import { PrimarySectionsFilterFragment, StudentsSectionFragFragment } from '../../../generated/graphql';
import { SelectedStudentClass } from './remove-student-hook';

interface Props {
  students?: StudentsSectionFragFragment[],
  onSelectClass: (studentId: string, sectionId: string) => void,
  selectedStudentClasses?: SelectedStudentClass[],
  teacherID?: string,
}

const MultipleClasses = ({ students, onSelectClass, selectedStudentClasses, teacherID }: Props) => {
  const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />;
  const checkedIcon = <CheckboxCheckedIcon pathFill={palette.customBlue.primaryBlue} fontSize="small" />;
  const selected =  (sId: string, secId:string) => selectedStudentClasses?.find((stu) => stu?.student == sId)?.sections.includes(secId) ?? undefined;
  const isTeacherPrimary = (section?: PrimarySectionsFilterFragment) =>  section?.teachers?.find((teacherInfo) => {
    return teacherInfo?.id === teacherID && teacherInfo?.primary_teacher === true;
  });
  return (
    <Box>
      <Table>
        <TableBody>
          {students && students?.map((student) =>
            <TableRow key={student.id} >
              <TableCell align="left">{`${student?.user?.first_name} ${student?.user?.last_name}`}</TableCell>
              <TableCell align="left" sx={{ right: '50px' }} >
                <Box>
                  {student?.section?.map((sect) =>
                    <Box key={sect.id} sx={{ marginLeft: '150px' }} >
                      <Checkbox
                        color={'primary' as CheckboxProps['color']}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected(student.id, sect.id)}
                        onClick={() => onSelectClass(student.id, sect.id)}
                        disabled={!isTeacherPrimary(sect!)}
                      />
                      {sect.name}
                    </Box>,
                  )}
                </Box>
              </TableCell>
            </TableRow>,
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MultipleClasses;
