import { useTeacherBmStudentsSlideoutQuery } from '../../../../generated/graphql';

const useTeacherBMStudentSlideout = ({ benchmarkId, statusList }:any) => {

  const { data, loading } = useTeacherBmStudentsSlideoutQuery({
    fetchPolicy: 'network-only',
    variables: {
      benchmarkId: benchmarkId,
      status: statusList,
    },
  });

  return {
    totalCount: data?.teacherBenchmarkSlideOut?.total_count ?? 0,
    slideoutStudents: data?.teacherBenchmarkSlideOut?.nodes ?? [],
    loading,
  };
};

export default useTeacherBMStudentSlideout;
