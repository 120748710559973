import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const TransparentButtonStyled = styled(Button)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export default TransparentButtonStyled;
