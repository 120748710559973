import { useState } from 'react';
import {
  useCreateGroupMutation,
  useSectionsFilterQuery, useTeacherSchoolFilterQuery,
} from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import getErrorMessage from '../../../utils/getErrorMessage';

const useCreateGroup = () => {
  const [createGroup, { loading: adding }] = useCreateGroupMutation();
  const [groupName, setGroupName] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');

  const { data, loading: sectionsLoading } = useSectionsFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      hideGroups: true,
      ...(selectedSchool ? { schoolIDs: [selectedSchool] } : {}),
    },
  });

  const { data: dataSchools, loading: schoolsLoading } = useTeacherSchoolFilterQuery({
    fetchPolicy: 'network-only',
  });

  const addGroup = async (classId: string) => {
    try {
      const response = await createGroup({
        variables: {
          input: {
            name: groupName,
            parent_section_id: classId,
          },
        },
      });
      if (!response?.data?.upsertGroup?.id) {
        openSnackbar({ message: 'Error in creating group.' }, NotifierType.Error);
      } else {
        openSnackbar({ message: 'Group created successfully.' }, NotifierType.Success);
      }
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  const handleGroupNameChange = (name: string) => {
    if (name === '') {
      setGroupName('');
    } else {
      setGroupName(name);
    }
  };

  const sections = data?.sections ?? [];
  const schools = dataSchools?.teacherSchools ?? [];

  return {
    addGroup,
    adding,
    groupName,
    handleGroupNameChange,
    sections,
    sectionsLoading,
    selectedSection,
    setSelectedSection,
    schools,
    schoolsLoading,
    setSelectedSchool,
  };
};

export default useCreateGroup;
