import React from 'react';


const CustomCursor = ({ points, width, height, payload }: any) => {
  // Check if the active payload corresponds to a Line data point
  const isLinePoint = payload && payload.length > 0 && payload[0].value !== undefined;
  if (!isLinePoint) {
    return null; // Do not render anything if not a Line point
  }
  const { x } = points[0];
  return (
    <svg>
      <line x1={x} x2={x} y1={20} y2={height + 20} stroke="#333" strokeDasharray="3 3" />
    </svg>
  );
};

export default CustomCursor;