import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  OrderBy,
  SyncStatus,
  useCleverSyncJobDetailsQuery,
  useCleverSyncListQuery,
  useGetDistrictsToSyncQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { DistrictSyncSort } from '../../../variables/types';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import { SearchContext } from '../../../core/searchContext';

interface Props {
  districtID?: string;
}

const useSyncJobList = ({ districtID }: Partial<Props> = {}) => {
  const pagination = usePagination();
  const { searchTerm } = useContext(SearchContext);
  const [syncDetailId, setSyncDetailId] = useState('');
  const [viewSyncDistrictDetails, setViewSyncDistrictDetails] = useState(false);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, DistrictSyncSort.CreatedAt));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [status, setStatus] = useState<SyncStatus | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  useEffect(() => { pagination.setPage(1); }, [rowsPerPage]);
  const { loading, data: syncJobList, refetch: fetchSyncList } = useCleverSyncListQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: rowsPerPage,
      page: pagination.page,
      sort,
      orderBy: order as OrderBy,
      ...(searchTerm && searchTerm.length > 2 ? { search: searchTerm } : {}),
      ...(status ? { status: [status] } : {}),
      ...(districtID ? { districtID: districtID } : {}),
    },
  });
  const { refetch: DistrictsToSyncReFetch } = useGetDistrictsToSyncQuery({
    fetchPolicy: 'network-only',
  });

  const { data: syncDetails, loading: loadingDetails } = useCleverSyncJobDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      Id: syncDetailId,
    },
    skip: !syncDetailId,
  });

  const onSortChange = (sortBy: DistrictSyncSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: DistrictSyncSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const getSelectedStatus = (value:string | undefined) => {
    switch (value) {
      case 'Success': return SyncStatus.Success;
      case 'Failed': return SyncStatus.Failed;
      case 'InProgress': return SyncStatus.InProgress;
      case 'SuccessWithWarning': return SyncStatus.SuccessWithWarning;
    }
    return null;
  };

  const handleStatusChange = (ev: ChangeEvent<{}>, statusOption: { label: string, value:string } | null) => {
    const selectedStatus = getSelectedStatus(statusOption?.value);
    setStatus(selectedStatus);
    DistrictsToSyncReFetch();
    pagination.setPage(1);
  };

  const handleViewButton = (id: string) => {
    setSyncDetailId(id);
    setViewSyncDistrictDetails(true);
  };

  return {
    loading,
    totalCount: syncJobList?.listCleverSyncs?.totalCount,
    syncJobList: syncJobList?.listCleverSyncs?.nodes,
    syncEnabled: syncJobList?.listCleverSyncs?.syncEnabled,
    syncDetails: syncDetails?.cleverSyncDetails,
    pagination,
    onSortChange,
    sort: sort as DistrictSyncSort,
    order: order as OrderBy,
    isTableEmpty: syncJobList?.listCleverSyncs?.totalCount === 0 || syncJobList?.listCleverSyncs?.nodes?.length === 0,
    handlePageChange,
    handleSort,
    handleViewButton,
    setViewSyncDistrictDetails,
    setSyncDetailId,
    loadingDetails,
    status,
    handleStatusChange,
    viewSyncDistrictDetails,
    fetchSyncList,
    rowsPerPage,
    setRowsPerPage,
  };
};

export default useSyncJobList;
