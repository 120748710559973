import { useParams } from 'react-router-dom';
import { useAdminStudentDetailsQuery, UserPermissions } from '../../../generated/graphql';
import usePermission from '../../../hooks/usePermission';
import useRole from '../../../hooks/useRole';
import { useImitationProvider } from '../../../core/imitationContext';

interface Props {
  studentId?: string;
}

interface RouteProp {
  id: string
}

const useStudent = ({ studentId }: Props) => {
  const { id } = useParams<RouteProp>();
  const { data, loading } = useAdminStudentDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: studentId || id,
    },
  });

  const { isSchoolAdmin } = useRole();
  const { hasPermission: editStudentPermission } = usePermission(UserPermissions.EditStudents);
  const { hasPermission: imitateUserPermission } = usePermission(UserPermissions.ImitateUser);
  const { handleImitationLogin } = useImitationProvider();

  return {
    loading,
    student: data?.viewStudent,
    editStudentPermission,
    isSchoolAdmin, studentID: id,
    imitateUserPermission,
    handleImitationLogin,
  };
};

export default useStudent;
