import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { DistrictWideSchoolDetailsSortType } from '../../../../generated/graphql';
import List from './List';
import { DistrictWideComparisonsContext } from '../district-wide-comparisons-hook';
import useSchools from './schools-hook';

interface Props {
  districtId?: string,
  schoolId?: string,
}

const Schools = ({ districtId, schoolId }: Props) => {

  const {
    widgetLoading,
    schoolsDataCount,
  } = useContext(DistrictWideComparisonsContext);

  const {
    queryInput, handleSort,
    pagination, handlePageChange,
    districtWideSchoolsData, isTableEmpty,
  } = useSchools({ districtId: districtId, schoolId: schoolId });

  return (
    <Box>
      <List
        isTableEmpty={isTableEmpty}
        loading={widgetLoading!}
        totalCount={schoolsDataCount as number}
        pagination={pagination}
        limit={queryInput.limit!}
        sort={queryInput.sort! as DistrictWideSchoolDetailsSortType}
        order={queryInput.orderBy!}
        schools={districtWideSchoolsData}
        handlePageChange={handlePageChange}
        handleSort={handleSort}
      />
    </Box>
  );
};

export default Schools;
