import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface Props{
  pathFill?: string
}
const LegendsIcon = ({ pathFill }: Props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="13"
      viewBox="0 0 26 13"
      fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13 3.25C11.2051 3.25 9.75 4.70507 9.75 6.5C9.75 8.29493 11.2051 9.75 13 9.75C14.7949 9.75 16.25 8.29493 16.25 6.5C16.25 4.70507 14.7949 3.25 13 3.25ZM19.2952 4.875C18.5737 2.07151 16.0287 0 13 0C9.97126 0 7.42635 2.07151 6.70478 4.875H1.625C0.727537 4.875 0 5.60254 0 6.5C0 7.39746 0.727537 8.125 1.625 8.125H6.70478C7.42635 10.9285 9.97126 13 13 13C16.0287 13 18.5737 10.9285 19.2952 8.125H24.375C25.2725 8.125 26 7.39746 26 6.5C26 5.60254 25.2725 4.875 24.375 4.875H19.2952Z"
        fill={pathFill}/>
    </SvgIcon>
  );
};

export default LegendsIcon;
