import React from 'react';
import { InputAdornment, SelectChangeEvent } from '@mui/material';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import GradeSelectionIcon from '../Icons/GradeSelectionIcon';
import { SubmissionStatus } from '../../generated/graphql';
import SelectDropDown from '../SelectDropDown';

type Option = {
  label: string;
  value: SubmissionStatus | undefined
};

type GradeStatusFilterProps = {
  onChange: (newStatus: SubmissionStatus | undefined) => void;
  status: SubmissionStatus | undefined;
  options?: Option[];
  placeholder?: string;
  textInputPlaceholder?: string;
};

const GradeStatusFilter = ({ onChange, status, options, placeholder, textInputPlaceholder }: GradeStatusFilterProps) => {

  const handleOptionChange = (event: SelectChangeEvent<string[]>) => {
    onChange(event.target.value as SubmissionStatus);
  };

  return (
    <SelectDropDown
      onChange={handleOptionChange}
      selectedItem={status}
      options={options ?? []}
      startAdornment={<>
        <InputAdornment position="start">
          <GradeSelectionIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />
        </InputAdornment>

      </>}
    />)
};

export default GradeStatusFilter;
