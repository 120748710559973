/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const EmptyResults = (props: any) => {
  const { fillColor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="156"
      height="136"
      viewBox="0 0 156 136"
      fill="none"
      sx={{ fontSize: '185px' }}
    >
      <path d="M4.31746 93.5H21.5873C23.9727 93.5 25.9048 95.4019 25.9048 97.75V131.75C25.9048 134.098 23.9727 136 21.5873 136H4.31746C1.93206 136 0 134.098 0 131.75V97.75C0 95.4019 1.93206 93.5 4.31746 93.5Z" fill={fillColor || palette.customGrey.iconGrade} />
      <path d="M133.841 51H151.111C153.497 51 155.429 52.9019 155.429 55.25V131.75C155.429 134.098 153.497 136 151.111 136H133.841C131.456 136 129.524 134.098 129.524 131.75V55.25C129.524 52.9019 131.456 51 133.841 51Z" fill={fillColor || palette.customGrey.iconGrade} />
      <path d="M142.476 25.5C139.969 25.5 137.651 24.7669 135.668 23.5556L111.49 42.593C111.952 43.9025 112.254 45.2864 112.254 46.75C112.254 53.7917 106.455 59.5 99.3016 59.5C92.1481 59.5 86.3492 53.7917 86.3492 46.75C86.3492 45.2864 86.6514 43.9025 87.1129 42.593L62.9351 23.553C60.9517 24.7669 58.6338 25.5 56.127 25.5C54.1302 25.5 52.2629 25.0192 50.5737 24.225L24.6095 49.7834C25.4163 51.4462 25.9048 53.2844 25.9048 55.25C25.9048 62.2917 20.1059 68 12.9524 68C5.79889 68 0 62.2917 0 55.25C0 48.2083 5.79889 42.5 12.9524 42.5C14.9492 42.5 16.8165 42.9808 18.5057 43.775L44.4698 18.2166C43.663 16.5538 43.1746 14.7156 43.1746 12.75C43.1746 5.70828 48.9735 0 56.127 0C63.2805 0 69.0794 5.70828 69.0794 12.75C69.0794 14.2136 68.7771 15.5975 68.3157 16.9044L92.4935 35.9444C94.4768 34.7305 96.7948 33.9973 99.3016 33.9973C101.808 33.9973 104.126 34.7305 106.11 35.9444L130.287 16.9044C129.826 15.5948 129.524 14.2136 129.524 12.75C129.524 5.70828 135.323 0 142.476 0C149.63 0 155.429 5.70828 155.429 12.75C155.429 19.7917 149.63 25.5 142.476 25.5Z" fill={fillColor || palette.customGrey.iconGrade} />
      <path d="M47.4921 51H64.7619C67.1473 51 69.0794 52.9019 69.0794 55.25V131.75C69.0794 134.098 67.1473 136 64.7619 136H47.4921C45.1067 136 43.1746 134.098 43.1746 131.75V55.25C43.1746 52.9019 45.1067 51 47.4921 51Z" fill={fillColor || palette.customGrey.iconGrade} />
      <path d="M90.6667 85H107.937C110.322 85 112.254 86.9019 112.254 89.25V131.75C112.254 134.098 110.322 136 107.937 136H90.6667C88.2813 136 86.3492 134.098 86.3492 131.75V89.25C86.3492 86.9019 88.2813 85 90.6667 85Z" fill={fillColor || palette.customGrey.iconGrade} />
    </SvgIcon>
  );
};

export default EmptyResults;
