import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  PropTypes,
  Box,
  Theme, useTheme,
} from '@mui/material';
import Lottie from 'lottie-react';
import {
  StyledDialogContentText,
  StyledDialogTitle,
  StyledDangerButton,
} from './SubmitTask.styled';
import { SpeakIcon } from '../../../components/Icons';
import { ButtonVariant, ButtonType } from '../../../variables/types';
import { GOOGLE_VOICE, SAMANTHA_VOICE } from '../../../variables/constant';
import { SubmitTaskContext } from '../submit-task-context';
import AudioPlayer from '../../../components/Player';

type ButtonColor =  'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;

interface Action {
  title: string,
  event: any,
  buttonVariant?: ButtonVariant,
  buttonColor?: PropTypes.Color,
  btnType?: ButtonType,
}

interface Props {
  openDialog : any,
  closeDialog?:any,
  actions?: Array<Action>,
  dialogTitle: string,
  dialogContent: string | React.ReactNode,
  dialogImage?: string | React.ReactNode,
  dialogAudioText?: string,
  isSuccessModal?: boolean,
  dialogAudio?: string,
  titleIcon?: React.ReactNode,
  isLottie?: boolean,
  lottieData?: object
}

const StylesCSS = (theme: Theme) => ({
  root: {
    position: 'relative',
    minHeight: 550,
    minWidth: 600,
    overflow: 'hidden',
  },
  title: {
    paddingBottom: 0,
  },
  subTitle: {
    paddingTop: 0,
    marginBottom: theme.spacing(3),
  },
  displayNewLine: {
    whiteSpace: 'pre-line',
  },
  image: {
    maxHeight: '300px',
  },
  button: {
    width: '200px',
  },
  dangerButton: {
    width: '150px',
    color: theme.palette.customRed.main,
  },
  buttonSpeak: {
    '&.MuiButtonBase-root.MuiButton-root': {
      padding: 1.5,
      /*
       * Removed default minHeight & minWidth set on material-ui button.
       * https://stackoverflow.com/a/47191093/1682304
      */
      minHeight: 0,
      minWidth: 0,
      borderRadius: '50%',
      '&::after': {
        borderRadius: '50%',
      },
    },
  },
  buttonSpeakDisabled: {
    backgroundColor: theme.palette.customBackground.disabled,
    color: theme.palette.customBackground.disabled,
    '&::after': {
      borderColor: theme.palette.customBackground.disabled,
    },
  },
  actionRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  successImage: {
    position: 'absolute',
    bottom: '-10px',
    right: '-25px',
  },
});

const HelpModal = ({
  openDialog,
  closeDialog = () => {},
  isSuccessModal,
  actions,
  dialogTitle,
  dialogContent,
  dialogImage,
  dialogAudioText,
  dialogAudio,
  titleIcon,
  isLottie = false,
  lottieData = {},
}: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const {
    speak,
    speaking,
    voices,
    playing,
    setPlaying,
  } = useContext(SubmitTaskContext);
  const [voice, setVoice] = useState<SpeechSynthesisVoice | null>(null);

  useEffect(() => {
    if (voices?.length! > 0 && !voice) {
      const targetVoice = voices?.reduce((currentVoice: SpeechSynthesisVoice | null, voiceItem: SpeechSynthesisVoice) => {
        if (voiceItem.name === GOOGLE_VOICE) {
          return voiceItem;
        } if (voiceItem.name === SAMANTHA_VOICE && !currentVoice) {
          return voiceItem;
        }
        return currentVoice;
      }, null) || null;
      setVoice(targetVoice);
    }
  }, [voice, voices]);

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: isSuccessModal ? styles.root : {},
      }}
      onClose={!speaking && !playing && closeDialog && closeDialog}
    >
      {(dialogImage || lottieData) && (
        <DialogContent
          sx={ isSuccessModal ? styles.successImage : {} }
        >
          <Box display="flex" justifyContent="center">
            {isLottie ? (
              <Box>
                <Lottie animationData={lottieData} loop autoPlay/>
              </Box>
            ) : (
              <>
                {typeof dialogImage === 'string'
                  && (
                    <img
                      src={dialogImage}
                      alt="task"
                      style={styles.image}
                    />
                  )}
                {typeof dialogImage !== 'string' && dialogImage}
              </>
            )}
          </Box>
        </DialogContent>
      )}
      <StyledDialogTitle
        sx={styles.title}
      >
        {titleIcon}
        {dialogTitle}
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText sx={{
          ...styles.displayNewLine, ...styles.subTitle,
        }}
        >
          {dialogContent}
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions sx={styles.actionRoot}>
        <Box
          display="grid"
          gridTemplateColumns="60px 1fr 60px"
          sx={{
            gridGap: 16,
          }}
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box>
            {!dialogAudio && dialogAudioText && (
              <Button
                sx={{
                  ...styles.buttonSpeak,
                  ...(speaking ? styles.buttonSpeakDisabled : {}),
                }}
                variant="outlined"
                color="secondary"
                disabled={speaking}
                onClick={() => speak?.({ text: dialogAudioText || '', voice })}
              >
                <SpeakIcon
                  pathFill={
                    speaking
                      ? theme.palette.customBackground.disabled
                      : theme.palette.customYellow.secondaryDark
                  }
                />
              </Button>
            )}
            {dialogAudio && (
              <AudioPlayer buttonOnly flexStructure={false} url={dialogAudio} fileType="audio/mpeg" setIsPlaying={setPlaying} />
            )}
          </Box>
          <Box justifyContent="center" display="flex">
            {actions?.map((action, index) => (action.btnType === 'danger'
              ? (
                <StyledDangerButton
                  key={index}
                  sx={styles.dangerButton}
                  variant={action?.buttonVariant || 'contained'}
                  color={action?.buttonColor as ButtonColor}
                  onClick={action.event}
                >
                  {action.title}
                </StyledDangerButton>
              )
              : (
                <Button
                  key={index}
                  sx={styles.button}
                  variant={action?.buttonVariant || 'contained'}
                  color={action?.buttonColor as ButtonColor}
                  onClick={action.event}
                >
                  {action.title}
                </Button>
              )))}
          </Box>
          <Box />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default HelpModal;
