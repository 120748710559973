import { useParams } from 'react-router-dom';
import { useGetSuperAdminDetailsQuery } from '../../../../generated/graphql';
interface RouteProp {
  id: string;
}
const useSuperAdminDetails = () => {
  const { id } = useParams<RouteProp>();
  const { data, loading } = useGetSuperAdminDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      Id: id,
    },
  });

  return {
    loading: loading,
    superAdminDetails: data?.superAdminDetails,
    id,
  };
};

export default useSuperAdminDetails;
