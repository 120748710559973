import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../../../components/Layout';
import TileBox from '../../../components/TileBox';
import useToolBoxList from './toolBoxList-hook';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';

const ToolBox = () => {
  const toolBoxData = useToolBoxList();
  return (
    <Layout>
      <StyledFlexColBoxContainer>
        <Box>
          <Typography variant="pageTitle">
            Toolbox
          </Typography>
        </Box>
        <Box
          mt={3}
          display="grid"
          gridTemplateColumns="repeat(3, 360px)"
          sx={{
            gridGap: 32,
          }}
        >
          {
            toolBoxData.map((tileProp: any) => (
              <TileBox
                key={tileProp.label}
                label={tileProp.label}
                Icon={tileProp.Icon}
                redirectionLink={tileProp.redirectionLink}
                primaryBackground={tileProp.primaryBackground}
                secondaryBackground={tileProp.secondaryBackground}
                description={tileProp.description}
              />
            ))
          }
        </Box>
      </StyledFlexColBoxContainer>
    </Layout>
  );
};

export default React.memo(ToolBox);
