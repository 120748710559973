import React from 'react';
import { Route, Switch } from 'react-router-dom';
import School from './School';
import SchoolsTable from './SchoolsTable';
import Layout from '../../components/Layout';
import RoutingPaths from '../RoutingPath';

const Schools = () => (
  <Layout>
    <Switch>
      <Route
        path={RoutingPaths.Schools}
        component={SchoolsTable}
        exact
      />
      <Route
        path={[`${RoutingPaths.Schools}/:id`, `${RoutingPaths.Schools}/:id/:sectionType`]}
        component={School}
        exact
      />
    </Switch>
  </Layout>
);

export default React.memo(Schools);
