/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const InterpretationIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="43"
    height="56"
    viewBox="0 0 43 56"
    fill="none"
    {...props}
  >
    <g>
      <path d="M21.6255 10.3596C15.9127 10.3596 11.266 15.0063 11.266 20.7192C11.266 24.5285 14.3652 27.6256 18.1724 27.6256C20.0781 27.6256 21.6255 29.1752 21.6255 31.0788C21.6255 32.9823 20.0781 34.532 18.1724 34.532H15.5825C15.1055 34.532 14.7192 34.9183 14.7192 35.3953V37.1219C14.7192 37.5988 15.1055 37.9852 15.5825 37.9852H18.1724C21.9795 37.9852 25.0787 34.8881 25.0787 31.0788C25.0787 27.2695 21.9795 24.1724 18.1724 24.1724C16.2666 24.1724 14.7192 22.6228 14.7192 20.7192C14.7192 16.9099 17.8184 13.8128 21.6255 13.8128C25.4327 13.8128 28.5319 16.9099 28.5319 20.7192V23.3091C28.5319 23.786 28.9183 24.1724 29.3952 24.1724H31.1218C31.5988 24.1724 31.9851 23.786 31.9851 23.3091V20.7192C31.9851 15.0063 27.3384 10.3596 21.6255 10.3596ZM21.6255 0C10.1825 0 0.906372 9.27615 0.906372 20.7192V39.7118C0.906372 48.294 7.86348 55.2511 16.4458 55.2511C25.028 55.2511 31.9851 48.294 31.9851 39.7118V38.6434C38.1718 35.0597 42.3447 28.3831 42.3447 20.7192C42.3447 9.27615 33.0686 0 21.6255 0ZM30.2542 35.6553L28.5319 36.6535V39.7118C28.5319 46.3764 23.1104 51.7979 16.4458 51.7979C9.78109 51.7979 4.35957 46.3764 4.35957 39.7118V20.7192C4.35957 11.1991 12.1055 3.4532 21.6255 3.4532C31.1456 3.4532 38.8915 11.1991 38.8915 20.7192C38.8915 26.8464 35.5819 32.569 30.2542 35.6553Z" fill={palette.fontColors.fontBlack} />
    </g>
  </SvgIcon>
);

export default React.memo(InterpretationIcon);
