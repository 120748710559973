/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Link, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink, Redirect, Route, Switch } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import usePerformanceDetails from './performance-details-hook';
import ScoreTable from './ScoreTable';
import useRedirect from '../../../hooks/useRedirect';
import PerformanceGraph from './PerformanceGraph';
import { GraphSetData, ScoreType, ScoreSchemeTitleKeys } from '../../../variables/types';
import { StyledPerformanceDetailsTab, StyledTabs } from '../Analytics.styled';

const StylesCSS = (theme: Theme) => ({
  container: {
    width: '100%',
  },
  link: {
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(1.75),
    fontWeight: 700,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    textDecoration: 'none !important',
    color: theme.palette.customBlack.lightBlack,
    display: 'inline-flex',
    alignItems: 'center',
  },
  tabText: {
    fontSize: theme.spacing(2.25),
    lineHeight: '25px',
    fontWeight: 600,
  },
  tabTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const PerformanceDetails = () => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const { search } = useRedirect();
  const {
    title,
    loading,
    graphData,
    totalCount,
    navigateToTab,
    section,
    skillType,
    redirectionLinkText,
    tab,
    image,
    user,
    handleChange,
    handleChangeForTexas,
  } = usePerformanceDetails();
  const scoreScheme = user?.score_scheme.title_key === ScoreSchemeTitleKeys.Scheme1;

  return (
    <Box sx={styles.container}>
      <Link
        component={RouterLink}
        to={{
          pathname: `/analytics/${section}`,
          search,
        }}
        sx={styles.link}
      >
        <ChevronLeft />
        {redirectionLinkText}
      </Link>
      <Box
        width="100%"
      >
        <PerformanceGraph
          data={graphData}
          onLinkClick={navigateToTab(`/analytics/${section}/details/${skillType}`)}
          title={title}
          totalCount={totalCount}
          loading={loading}
          iconString={image}
          clickToRedirect={false}
        />
      </Box>
      <Box
        width="100%"
        mt={4}
      >
        <StyledTabs
          value={tab}
          onChange={scoreScheme ? handleChangeForTexas : handleChange}
          textColor="primary"
          scrollButtons={false}
        >
          {graphData?.map((element: GraphSetData) => (
            <StyledPerformanceDetailsTab
              key={element.skill_level}
              classes={{
                root: `${element.skill_key}`,
              }}
              label={(
                <Typography sx={styles.tabTitle}>
                  <Typography
                    component="span"
                    className="tag"
                  >
                    {element.numberOfStudents}
                  </Typography>
                  <Typography component="span" sx={styles.tabText}>
                    {element.skill_label}
                  </Typography>
                </Typography>
              )}
              disableRipple
            />
          ))}
        </StyledTabs>
        <Box display="flex">
          <Switch>
            <Route
              path={[
                `/analytics/${section}/details/${skillType}/${ScoreType.limited}`,
                `/analytics/${section}/details/${skillType}/${ScoreType.emerging}`,
                `/analytics/${section}/details/${skillType}/${ScoreType.expanding}`,
                `/analytics/${section}/details/${skillType}/${ScoreType.bridging}`,
                `/analytics/${section}/details/${skillType}/${ScoreType.advanced}`,
                `/analytics/${section}/details/${skillType}/${ScoreType.beginner}`,
                `/analytics/${section}/details/${skillType}/${ScoreType.intermediate}`,
                `/analytics/${section}/details/${skillType}/${ScoreType.texasAdvanced}`,
                `/analytics/${section}/details/${skillType}/${ScoreType.advancedHigh}`,
              ]}
              render={(props) => (
                <ScoreTable
                  data={graphData?.[tab]}
                  loading={loading}
                  section={section}
                  {...props}
                  hoverData={false}
                />
              )}
              exact
            />
            <Redirect
              from={`/analytics/${section}/details/${skillType}`}
              exact
              to={{
                pathname: `/analytics/${section}/details/${skillType}/${scoreScheme ? ScoreType.beginner : ScoreType.limited}`,
                search,
              }}
            />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(PerformanceDetails);
