import React, { useRef, useState } from 'react';
import { Box, IconButton, Slider, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import FullScreenIcon from '@mui/icons-material/Fullscreen';
import Popover from '@mui/material/Popover';
import { ControlledSlider, StyledTypography } from './PlayerControl.styled';
import zIndex from '../../theme/z-index';
import { palette  } from '../../theme/palette';

interface Props {
  videoPlaying?: boolean
  muted?: boolean
  volume?: any
  played?: any
  handlePlayPause?: () => void;
  handleClickFullscreen?: () => void;
  handleMuteToggle?: () => void;
  onVolumeChange?: any;
  onVolumeSeekUp?: any;
  onSeek?:any;
  onMouseDownSeek?: any;
  onMouseUpSeek?: any;
  elapsedTime?: any;
  totalDuration?: any;
  fullscreen?:boolean;
  playbackRate: number;
  onPlaybackRateChange: (rate: number) => void;
}

const controlStyles = {
  bottomIcons: {
    color: palette.customWhite.main,
    '&:hover': {
      color: palette.customBlue.primaryBlue,
    },
  },
  blueBackground: {
    '&.MuiButtonBase-root': {
      background: palette.customBlue.primaryBlue,
      color: palette.customWhite.main,
      borderRadius: '50%',
      '&:hover': {
        background: palette.customBlue.primaryBlue,
        color: palette.customWhite.main,
      },
    },
  },
  controlsWrapper: {
    position: 'absolute',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
    width: '100%',
    backgroundColor: palette.customRed.scoreDisabled,
  },
};

const PlayerControls = ({
  videoPlaying,
  handlePlayPause,
  handleClickFullscreen,
  handleMuteToggle,
  muted,
  onVolumeChange,
  onVolumeSeekUp,
  volume,
  played,
  onSeek,
  onMouseDownSeek,
  onMouseUpSeek,
  elapsedTime,
  totalDuration,
  fullscreen,
  playbackRate,
  onPlaybackRateChange,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const handlePlaybackRatePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'playbackrate-popover' : undefined;

  return (
    <>
      <Box sx={{
        ...controlStyles.controlsWrapper,
        ...((fullscreen &&  window.matchMedia('(max-height: 668px)').matches) ? { bottom: '160px' } : { bottom: '10px' }),
        ...((!fullscreen &&  window.matchMedia('(max-height: 668px)').matches) ? { bottom: '75px' } : { bottom: '10px' }),
      }}>
        <Box
          display="grid"
          gridTemplateColumns="80px 5fr 1fr"
          justifyContent="space-between"
          alignItems="center"
          py={1}
          px={2}
        >
          <Box alignItems="center" flexDirection="row">
            <IconButton sx={{ ...controlStyles.blueBackground }} onClick={handlePlayPause}>
              {videoPlaying ? <PauseIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" /> }
            </IconButton>
          </Box>
          <Box alignItems="center" flexDirection="row" display="flex" sx={{ flexWrap: 'nowrap' }}>
            <StyledTypography>{elapsedTime}</StyledTypography>
            <ControlledSlider
              min={0}
              max={100}
              value={played * 100}
              onChange={onSeek}
              onMouseDown={onMouseDownSeek}
              onChangeCommitted={onMouseUpSeek}
            />
            <StyledTypography>{totalDuration}</StyledTypography>
          </Box>
          <Box alignItems="center" flexDirection="row" display="flex" justifyContent="center">
            <IconButton sx={{ ...controlStyles.bottomIcons }} onClick={handleMuteToggle}>
              {muted ? <VolumeOffIcon sx={{
                height: '24px',
                width: '22.5px',
              }} /> : <VolumeUpIcon  sx={{
                height: '24px',
                width: '22.5px',
              }} />}
            </IconButton>
            <Slider
              min={0}
              max={100}
              value={volume * 100}
              style={{ width: '100px' }}
              onChange={onVolumeChange}
              onChangeCommitted={onVolumeSeekUp}
            />
            <Box
              display="flex"
              width="60px"
              justifyContent="center"
              marginLeft="15px"
            >
              {anchorEl && (
                <div ref={container} style={{ minWidth: '60px' }}>
                  <IconButton sx={{ ...controlStyles.bottomIcons }} onClick={handlePlaybackRatePopover}>
                    <StyledTypography>
                      {`${playbackRate}x`}
                    </StyledTypography>
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    container={container?.current ? container.current : undefined}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    style={{ zIndex: zIndex.zIndex99999 }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column-reverse"
                    >
                      {[0.25, 0.50, 0.75, 1].map((rate) => (
                        <IconButton
                          onClick={() => onPlaybackRateChange(rate)}
                          key={rate}
                        >
                          <Typography color={rate === playbackRate ? 'primary' : 'textSecondary'}>{rate}</Typography>
                        </IconButton>
                      ))}
                    </Box>
                  </Popover>
                </div>
              )}
            </Box>
            <IconButton sx={{ ...controlStyles.bottomIcons }} onClick={handleClickFullscreen}>
              <FullScreenIcon sx={{
                height: '24px',
                width: '21px',
              }}/>
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PlayerControls;
