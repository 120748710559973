import React from 'react';
import { Box } from '@mui/material';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { palette } from '../../theme/palette';
import YaxisCustomizeTick from '../OverallBenchmarkIndicatorGraph/YaxisCustomizeTick';
import CustomLegend from '../BenchmarkStudentGrowthReportChart/CustomLegend';
import { TeacherBenchmarkStudentGrowthReportLegendData } from '../../variables/constant';

interface Props {
  data: any,
  lineKey1: string,
  lineKey2: string,
  xAxisKey: string,
  chartWidth: number,
  chartHeight: number,
  yAxisDomain: Array<number>,
  yAxisTicks: Array<number>,
}

const StylesCSS = () => ({
  lineOverride: {
    height: '400px',
    width: '642px',
    '& .recharts-responsive-container .recharts-wrapper .recharts-surface .recharts-cartesian-grid .recharts-cartesian-grid-vertical': {
      strokeWidth: '3px',
    },
  },
});

const AdminBenchmarkGrowthReportChart = (
  {
    data, lineKey1, lineKey2, xAxisKey,
    chartWidth, chartHeight, yAxisDomain, yAxisTicks,
  }: Props) => {

  const styles = StylesCSS();

  return (
    <Box sx={styles.lineOverride} >
      <ResponsiveContainer width="100%" height="100%" >
        <LineChart
          width={chartWidth}
          margin={{ top: 24, left: -25, bottom: 5 }}
          height={chartHeight}  data={data} >
          <CartesianGrid verticalCoordinatesGenerator={() => [240, 450, 640]} />
          <Line
            type="monotone"
            dataKey={lineKey2}
            strokeWidth={3}
            stroke={palette.customBlue.lightSky}
            dot={{ r: 6, fill: palette.customBlue.lightSky }}
            activeDot={{ r: 8, fill: palette.customBlue.lightSky }}
            isAnimationActive={false}
          />
          <Line type="monotone"
            dataKey={lineKey1}
            strokeWidth={3}
            stroke={palette.customBlue.oldTheme}
            dot={{ r: 6, fill: palette.customBlue.oldTheme }}
            activeDot={{ r: 8, fill: palette.customBlue.oldTheme }}
            isAnimationActive={false}
          />

          <XAxis
            dataKey={xAxisKey}
            scale='auto'
            interval={0}
            axisLine={false}
            tickLine={false}
            allowDuplicatedCategory={false}
            padding={{  left: 90, right: 90 }}
            dy={10}
            style={{ fontSize: '14px', fontWeight: 600, color: palette.customBlack.fontBlack, textTransform: 'uppercase' }}
          />
          <YAxis
            tickLine={false}
            axisLine={true}
            stroke={palette.customGrey.lineGrey}
            strokeWidth={3}
            tick={<YaxisCustomizeTick />}
            domain={yAxisDomain}
            ticks={yAxisTicks}
          />
          <Legend content={<CustomLegend data={TeacherBenchmarkStudentGrowthReportLegendData} id={'student-growth-report-legend'} />} />
        </LineChart>
      </ResponsiveContainer >
    </Box >
  );
};

export default AdminBenchmarkGrowthReportChart;

