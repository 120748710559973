import React from "react";

const useDropdownButton = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onToggle = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return {
        onToggle,
        handleClose,
        open,
        anchorEl,
    }
}

export default useDropdownButton;

