import React, { useContext, useState } from 'react';
import {
  Box,
  CardActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  Card,
  CardContent,
  Zoom, styled,
} from '@mui/material';
import { ScoreDetail, SubmissionTypeRubricScoreLineItem } from '../../generated/graphql';
import { RadioGroupProps } from '../Radio';
import { getOverlayAnchorInfo } from '../../pages/TeacherToolBox/Anchors/anchors-hook';
import AudioPlayer from '../Player';
import { Skills, SpeakingSkills, WritingSkills } from '../../variables/types';
import { ScoreContext } from '../../pages/ScoreTask/score-context';
import { palette  } from '../../theme/palette';
import { SxProps } from '@mui/system';

interface Props {
  score?: SubmissionTypeRubricScoreLineItem;
  radioOptions: RadioGroupProps[];
  handleScoreUpdate?: Function;
  disableSelection: boolean;
  skillType: string;
}

interface AnchorProps {
  scoreValue: string;
  grade?: string | null | undefined;
  speakingSkill: SpeakingSkills | null | any;
  writingSkill: WritingSkills | null | any;
  taskType: string;
  description: string | undefined;
  title: string | undefined;
  setIsAnchorPlaying?: Function;
}

const styles = {
  rubricContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    justifyContent: 'space-around',
  },
  radioLabel: {

  },
  radioChecked: {
    '&.MuiFormControlLabel-root': {
      backgroundColor: palette.customBlue.primaryBlue,
      color: palette.customWhite.main,
      border: 'none',
      padding: '4px 12px',
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: palette.customRed.scoreDisabled,
      },
    },
  },
  customTooltip: {
    padding: 0,
  },
  tooltip: {
    '&.MuiTooltip-tooltip': {
      padding: 0,
    },
  },
};

const anchorStyles = {
  anchorContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: palette.customGrey.main,
    paddingBottom: 2,
    maxHeight: '250px',
    overflowY: 'scroll',
  },
  bold: {
    fontWeight: 'bold',
  },
};

const StyledFormControlLabel = styled(FormControlLabel)<{ scoreSchemeValue: number }>(({ theme, scoreSchemeValue })=>({
  '&.MuiFormControlLabel-root': {
    border: `2px solid ${palette.customGrey.sgiLightGray}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.25, 1.25),
    marginLeft:  5 - scoreSchemeValue * 0.5,
    '& .MuiRadio-root': {
      display: 'none',
    },
    '& .MuiTypography-body1': {
      fontWeight: '700',
    },
  },
}));

const ScoreAnchor = ({ scoreValue, grade, speakingSkill, writingSkill, taskType, description, title, setIsAnchorPlaying }: AnchorProps) => {
  const anchor = getOverlayAnchorInfo(grade, writingSkill, speakingSkill, Number(scoreValue));
  return (
    <Card>
      <CardContent>
        <Typography>
          <span style={{ ...anchorStyles.bold }}>
            {scoreValue}
            {'. '}
            {title}
            {': '}
          </span>
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ ...anchorStyles.anchorContainer as SxProps }}>
        <Box>
          <Typography sx={{ ...anchorStyles.bold }}>Anchor</Typography>
        </Box>
        {taskType === Skills.Writing && <Typography>{anchor}</Typography>}
        {taskType === Skills.Speaking && (
          <AudioPlayer
            url={anchor}
            fileType="audio/wav"
            backpackPlayer
            customStyle
            setIsPlaying={setIsAnchorPlaying}
          />
        )}
      </CardActions>
    </Card>
  );
};

const ScoringRubrics = ({
  handleScoreUpdate,
  score,
  radioOptions,
  disableSelection,
  skillType,
}:Props) => {
  const { submission, anchorEnabled, isAnchorPlaying, setIsAnchorPlaying } = useContext(ScoreContext);

  return (
    <Box sx={{ ...styles.rubricContainer }}>
      <RadioGroup
        row
        onChange={
          (event: React.ChangeEvent<HTMLInputElement>) => handleScoreUpdate?.(score?.grade_id, event.target.value)
        }
        aria-label="Score"
        name="Submission Scoring"
        value={score?.score_detail_id ?? null}
      >
        {
          radioOptions?.map((radio:RadioGroupProps) => (
            <Tooltip
              disableFocusListener={isAnchorPlaying}
              disableHoverListener={isAnchorPlaying}
              componentsProps={{
                tooltip: {
                  sx: { padding: 0 },
                },
              }}
              key={radio.value}
              arrow
              placement="bottom"
              TransitionComponent={Zoom}
              title={(anchorEnabled ? (
                <ScoreAnchor
                  scoreValue={radio.label}
                  grade={submission?.student?.student?.grade}
                  taskType={skillType.toLowerCase()}
                  writingSkill={skillType.toLowerCase() === Skills.Writing ? score?.rubric_title_key : null}
                  speakingSkill={skillType.toLowerCase() === Skills.Speaking ? score?.rubric_title_key : null}
                  description={submission?.scoring_details?.find((scoreObj: ScoreDetail) => scoreObj.score_value === Number(radio.label))?.description}
                  title={submission?.scoring_details?.find((scoreObj: ScoreDetail) => scoreObj.score_value === Number(radio.label))?.title}
                  setIsAnchorPlaying={setIsAnchorPlaying}
                />
              ) : '')}
            >
              <StyledFormControlLabel
                value={radio.value}
                control={<Radio color="primary" icon={undefined} checkedIcon={undefined} />}
                label={radio.label}
                sx={{ ...(score?.score_detail_id === radio.value && styles.radioChecked) }}
                disabled={radio.disabled || disableSelection}
                id={radio.label + radio.value}
                scoreSchemeValue={radioOptions.length}
              />
            </Tooltip>
          ))
        }
      </RadioGroup>
    </Box>
  );
};

export default ScoringRubrics;
