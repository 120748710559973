/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MicDisabledIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="32"
    viewBox="0 0 40 32"
    fill="none"
    {...props}
  >
    <path
      d="M39.6146 28.6313L29.7521 21.0088C30.539 19.5081 31.0009 17.8131 31.0009 16V13C31.0009 12.4475 30.5534 12 30.0009 12H29.0009C28.4484 12 28.0009 12.4475 28.0009 13V16C28.0009 17.12 27.7534 18.175 27.3309 19.1375L25.6715 17.855C25.8652 17.2675 26.0015 16.6538 26.0015 16.0006V6C26.0015 2.68625 23.3152 0 20.0015 0C16.6877 0 14.0015 2.68625 14.0015 6V8.835L2.84274 0.210625C2.40649 -0.128125 1.77773 -0.05 1.43898 0.38625L0.211485 1.96563C-0.127265 2.40125 -0.0491399 3.02938 0.38711 3.36875L37.1596 31.7894C37.5959 32.1287 38.224 32.05 38.5634 31.6138L39.7909 30.0344C40.129 29.5987 40.0509 28.9706 39.6146 28.6313ZM25.0009 29H21.5009V26.8894C22.2296 26.7894 22.929 26.6056 23.6052 26.37L20.4734 23.9494C20.054 23.9744 19.6352 24.0038 19.2015 23.9619C15.7109 23.6213 13.0302 20.9225 12.2527 17.5963L9.00086 15.0819V15.51C9.00086 21.1125 12.999 26.1069 18.5009 26.8656V29H15.0009C14.4484 29 14.0009 29.4475 14.0009 30V31C14.0009 31.5525 14.4484 32 15.0009 32H25.0009C25.5534 32 26.0009 31.5525 26.0009 31V30C26.0009 29.4475 25.5534 29 25.0009 29Z"
      fill={props.pathFill || 'white'}
    />
  </SvgIcon>
);

export default React.memo(MicDisabledIcon);
