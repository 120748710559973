import { TableContainer, Theme, styled } from '@mui/material';
import {
  Button,
} from '@mui/material';

export const StyledNextButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(20),
}));

export const StyledNavigationButtonOne = styled(Button)(({ theme }) => ({
  border: `solid 3px ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.customWhite.main,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.customWhite.main,
  },
}));

export const StyledEditButton = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    background: theme.palette.customRed.progressPrimary,
    border: `2px solid ${theme.palette.customRed.progressPrimary}`,
    padding: theme.spacing(1, 1.25),
    fontSize: theme.spacing(2),
    fontWeight: '800',
    '& span': {
      fontSize: theme.spacing(2.25),
      fontWeight: '800',
      color:  theme.palette.customBlue.primaryPurple,
      lineHeight: theme.spacing(2),
    },
    '&::after': {
      background: theme.palette.customWhite.main,
      bottom: theme.spacing(0.25),
    },
  },
}));

export const StyledIconButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  '&.MuiButtonBase-root.MuiButton-root': {
    background: theme.palette.customRed.progressPrimary,
    border: `1px solid ${theme.palette.customRed.progressPrimary}`,
    padding: theme.spacing(1, 1),
    fontSize: theme.spacing(2),
    fontWeight: '600',
    '& span': {
      fontSize: theme.spacing(2.25),
      fontWeight: '600',
      color:  theme.palette.customBlue.primaryPurple,
      lineHeight: theme.spacing(2),
    },
    '&::after': {
      background: theme.palette.customWhite.main,
      bottom: theme.spacing(0.25),
    },
  },
}));

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    color: theme.palette.customWhite.main,
    background: theme.palette.customRed.progressPrimary,
    padding: theme.spacing(1.5, 2.25),
    border: `2px solid ${theme.palette.customRed.progressPrimary}`,
    '&::after': {
      background: theme.palette.customWhite.main,
      bottom: theme.spacing(0.25),
    },
  },
}));

export const StyledDangerButton = styled(Button)(({ theme }) => ({
  width: '150px',
  marginRight: 2,
  backgroundColor: theme.palette.customPink.main,
  color: theme.palette.customPink.main,
  '&::after': {
    borderColor: theme.palette.customPink.main,
  },
}));

export const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 350px)',
});

export const StylesCss = (theme: Theme) => ({
  width10: {
    width: '10%',
  },
  width15: {
    width: '15%',
  },
  width20: {
    width: '20%',
  },
  width25: {
    width: '25%',
  },
  width30: {
    width: '30%',
  },
  gridContentContainer: {
    display: 'grid',
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  PTContainer: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  PTListContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(0.7),
  },
  tableHeadContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  countContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  tableWithNoData: {
    height: '100%',
    '& tbody tr:hover': {
      backgroundColor: `${theme.palette.transparent.main} !important`,
      cursor: 'initial !important',
    },
  },
  countCell: {
    fontSize: theme.spacing(2),
    padding: theme.spacing(2.125, 1.5),
  },
  assignmentNameCell: {
    '&.MuiTableCell-root': {
      color: theme.palette.customBlack.lightBlack,
      fontSize: theme.spacing(2.25),
    },
  },
  dateRangeCell: {
    '&.MuiTableCell-root': {
      color: theme.palette.customBlack.lightBlack,
      fontSize: theme.spacing(2),
    },
  },
  iconSortLabel1: {
    icon: {
      marginLeft: theme.spacing(1.25),
    },
  },
  iconSortLabel2: {
    icon: {
      marginLeft: theme.spacing(1),
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  tooltip: {
    maxWidth: 'none',
    fontWeight: 'bold',
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1.5),
    minWidth: theme.spacing(5),
    minHeight: theme.spacing(5),
  },
  submitted: {
    backgroundColor: theme.palette.customGreen.countBackgroundGreen,
    color: theme.palette.fontColors.buttonFontGreen,
  },
  inProgress: {
    backgroundColor: theme.palette.customOrange.countBackgroundOrange,
    color: theme.palette.fontColors.buttonFontOrange,
  },
  notStarted: {
    backgroundColor: theme.palette.customRed.countBackgroundRed,
    color: theme.palette.fontColors.buttonFontRed,
  },
  flexDesign: {
    display: 'flex',
  },
  assignmentImage: {
    width: '5%',
    height: '3%',
  },
});
