import { SectionCategory, useClassesFilterQuery } from '../../generated/graphql';

interface Props {
  stateId?: string,
  districtId?: string,
  schoolId?: string | string[],
  teacherId?: string | string[],
  classTypes?: SectionCategory[],
}

const classVariables = ({ stateId, districtId, schoolId, teacherId, classTypes }: Props) => {
  let variables = {};
  if (stateId) {
    variables = { ...variables, stateID: stateId as string };
  }
  if (districtId) {
    variables = { ...variables, districtID: districtId as string };
  }
  if (schoolId) {
    variables = {
      ...variables,
      schoolIDs: typeof (schoolId) === 'string' ? [schoolId] as [string] : schoolId,
    };
  }
  if (teacherId) {
    variables = {
      ...variables,
      teacherID: typeof (teacherId) === 'string' ? [teacherId] as [string] : teacherId
    };
  }
  if (classTypes) {
    variables = { ...variables, classTypes };
  }
  return variables;
};

const useClassesFilter = ({ stateId, districtId, schoolId, teacherId, classTypes }: Props) => {
  const { data, loading } = useClassesFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(classVariables({
        stateId, districtId, schoolId, teacherId, classTypes,
      })),
    },
  });

  return {
    sections: data?.sectionsFilterList ?? [],
    loading,
  };
};

export default useClassesFilter;
