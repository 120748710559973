import React from 'react';
import {
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { SubmissionTypeGroupFragmentFragment } from '../../../generated/graphql';
import { RadioGroupProps } from '../../../components/Radio';
import ScoringRubrics from '../../../components/ScoringRubrics';
import {
  StyledContainer3,
  StyledRubricContainer,
  StyledRubrictile,
  StyledScoringRubrics,
} from '../ScoreTask.styled';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';

interface Props {
  submissionScores?: SubmissionTypeGroupFragmentFragment;
  radioOptions: RadioGroupProps[];
  handleScoreUpdate?: Function;
  disableSelection: boolean,
  skillType: string,
}

const ScoringSection = ({ submissionScores, radioOptions, handleScoreUpdate, disableSelection, skillType }: Props) => {
  const theme = useTheme();

  return (
    <StyledContainer3 m={0}>
      <StyledFlexColBoxContainer mb={theme.spacing(1.5)} sx={{ overflowY: 'auto' }}>
        {
          submissionScores && submissionScores?.rubric_line_items.map((score:any) => (
            <StyledRubricContainer key={score.grade_id}>
              <StyledRubrictile>
                <Typography variant="sTaskTitle">
                  {score?.rubric_title}
                </Typography>
                <Tooltip
                  title={(
                    <>
                      <Typography variant='breadcrumbs'>{score.rubric_title}</Typography>
                      <br/><br/>
                      <Typography variant="sLabel" sx={{ lineHeight: '24px', marginTop: 12 }}>
                        {score?.rubric_content}
                      </Typography>
                    </>
                  )}
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={15000}
                >
                  <InfoRoundedIcon
                    fontSize="small"
                    color="action"
                    sx={{
                      margin: theme.spacing(0.25, 0, 0, 1),
                      color: theme.palette.customGrey.darkGrey,
                    }}
                  />
                </Tooltip>
              </StyledRubrictile>
              <StyledScoringRubrics>
                <ScoringRubrics
                  score={score}
                  radioOptions={radioOptions}
                  handleScoreUpdate={handleScoreUpdate}
                  disableSelection={disableSelection}
                  skillType={skillType}
                />
              </StyledScoringRubrics>
            </StyledRubricContainer>
          ))
        }
      </StyledFlexColBoxContainer>
    </StyledContainer3>
  );
};

export default ScoringSection;
