import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import DownloadAllIcon from '../../../components/Icons/DownloadAllIcon';
import useTeacherBenchmarksDownloadData from './benchmarks-download-hooks';
import MultipleSelectChips from '../../../components/MultiSelectChips';
import MultiSelectTeacherSchoolFilter from '../../../components/MultiSelectTeacherSchoolFilter';
import Loader from '../../../components/Loader/loader';
import {
  DialogTitleTypography,
  StyledBoxMultiSelectTeacherSchoolFilter,
  StyledDialog,
  StyledDialogBox,
  StyledDialogContentBox,
} from './BenchmarkDownload.styled';

const BenchmarkDataDownload = ({ openModal, handleClose, handleIsCsvDownloaded }: any) => {
  const {
    selectedSchools,
    onSchoolChange,
    benchmarkOptions,
    selectedBenchmarkTypes,
    handleSelectedBenchmarkTypes,
    selectedBenchmarkTypeErrorMessage,
    selectedSchoolErrorMessage,
    downloadBenchmarks,
    setSelectedSchoolErrorMessage,
    handleCancel,
    isDownloadFailed,
    downloadAttemptCount,
    loadingBenchmarks,
    schools,
  } = useTeacherBenchmarksDownloadData({ handleClose, handleIsCsvDownloaded, openModal });

  const handleEmailClick = () => {
    window.location.href = 'mailto:support@flashlight360.com';
  };

  return (
    <StyledDialog open={openModal} onClose={handleClose}>
      <DialogTitle>
        <StyledDialogBox>
          <DownloadAllIcon />
          <DialogTitleTypography variant="title">Benchmark Data Download</DialogTitleTypography></StyledDialogBox></DialogTitle>
      <DialogContent  >
        {loadingBenchmarks ?
          (<Box sx={{ minHeight: '200px' }}><Loader open={loadingBenchmarks} /></Box>)
          :
          (<Box >
            <StyledDialogContentBox>
              <Typography variant='secondaryLabel'>Filter By School</Typography>
              <StyledBoxMultiSelectTeacherSchoolFilter sx={{ marginTop: '4px', marginBottom: '16px', width: '403px' }}>
                <MultiSelectTeacherSchoolFilter
                  schools={schools}
                  value={selectedSchools}
                  onChange={(data) => {
                    onSchoolChange(data);
                    setSelectedSchoolErrorMessage('');
                  }}
                  errorMessage={'Please select at least 1 School'}
                />
                <Typography variant='sFormHelper' component='p'>
                  {selectedSchoolErrorMessage.length > 0 && selectedSchoolErrorMessage}
                </Typography>
              </StyledBoxMultiSelectTeacherSchoolFilter>
              <Typography variant='secondaryLabel'>Filter By Benchmark Type</Typography>
              <Box sx={{ marginTop: '4px' }}>
                <MultipleSelectChips
                  value={selectedBenchmarkTypes}
                  onChange={handleSelectedBenchmarkTypes}
                  options={benchmarkOptions}
                  isBold={true}
                />
                <Typography variant='sFormHelper' component='p'>
                  {selectedBenchmarkTypeErrorMessage.length > 0 && selectedBenchmarkTypeErrorMessage}
                </Typography>
              </Box>
            </StyledDialogContentBox>
            <Typography variant="sFormHelper" component='p' >
              {isDownloadFailed && (
                downloadAttemptCount > 2 ? (
                  <Typography>
                    Download could not be completed. Please reach out to{' '}
                    <span style={{
                      cursor: 'pointer', textDecoration: 'underline'
                    }} onClick={handleEmailClick}>
                      support@flashlight360.com
                    </span>{' '}
                    for help.
                  </Typography>
                ) : (
                  <Typography  >
                    Download could not be completed. Please press "Download Data" again.
                  </Typography>
                )
              )}
            </Typography>
          </Box>)}


      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }} >
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
        <Button onClick={downloadBenchmarks} variant="contained" >
          Download Data
        </Button>

      </DialogActions>
    </StyledDialog>
  );
};
export default BenchmarkDataDownload;