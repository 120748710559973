/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SelectDropdownIcon = (props: any) => {
  const { pathFill, className, sx } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="5px"
      viewBox="0 0 14 10"
      fill="none"
      className={className}
      sx={sx}
    >
      <path
        d="M6.46966 8.9213L0.396438 2.84805C0.103531 2.55514 0.103531 2.08027 0.396438 1.78739L1.10478 1.07905C1.39719 0.786641 1.87109 0.786079 2.16419 1.0778L7 5.89095L11.8358 1.0778C12.1289 0.786079 12.6028 0.786641 12.8952 1.07905L13.6035 1.78739C13.8964 2.0803 13.8964 2.55517 13.6035 2.84805L7.53034 8.9213C7.23744 9.21417 6.76256 9.21417 6.46966 8.9213Z"
        fill={pathFill}
      />
    </SvgIcon>
  );
};

export default SelectDropdownIcon;
