import React, { useState } from 'react';
import {
  Theme,
  Box,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  SxProps,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CalendarIcon from '../Icons/CalendarIcon';
import { AdminAnalyticsDateFilterOptions } from '../../variables/types';
import { StyledListItemText } from '../AppFilter/AppFilter.styled';
import { add, sub } from 'date-fns';

interface Props {
  sessionStartDate?: any,
  dateRangeSelected?: string;
  handleDateRangeChange?: (
    event: React.ChangeEvent<HTMLInputElement>, value: string
  ) => void;
  startDateSelected?: Date;
  setStartDateSelected?: Function;
  endDateSelected?: Date;
  setEndDateSelected?: Function;
}

export const AdminAnalyticsDateFilterCSS = (theme: Theme) => ({
  dateRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    '&.MuiListItem-root': {
      border: `2px solid ${theme.palette.customBackground.disabled} !important`,
      borderBottom: `5px solid ${theme.palette.customBackground.disabled} !important`,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(0.75, 2),
    },
  },
  datepickerInput: {
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      background: 'none',
      fontWeight: 800,
    },
    '& .MuiIconButton-root': {
      marginLeft: theme.spacing(-5),
    },
  },
});

const AdminAnalyticsDateFilter = ({
  sessionStartDate,
  dateRangeSelected,
  handleDateRangeChange,
  startDateSelected,
  setStartDateSelected,
  endDateSelected,
  setEndDateSelected,
}: Props) => {
  const theme = useTheme();
  const styles = AdminAnalyticsDateFilterCSS(theme);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const renderDateFiltersRadioButtons = () => Object.entries(AdminAnalyticsDateFilterOptions).map((value) => (
    <FormControlLabel value={value[1]} control={<Radio />} label={value[1]} />
  ));

  const today = new Date();
  const getMinStartDate = () => {
    if (sessionStartDate) return sessionStartDate;
    return sub(today, {
      days: 1,
    });
  };
  const getMaxStartDate = () => {
    if (endDateSelected) {
      return sub(endDateSelected, {
        days: 1,
      });
    }
    return sub(today, {
      days: 1,
    });
  };
  const getMinEndDate = () => {
    if (startDateSelected) {
      return add(startDateSelected, {
        days: 1,
      });
    }
    return add(sessionStartDate, {
      days: 1,
    });
  };

  return (
    <>
      <Box>
        <ListItem
          key="AdminAnalyticsDateRangeType"
          onClick={handleClick}
          secondaryAction={openPopover ? <ArrowDropUpIcon fontSize="medium" /> : <ArrowDropDownIcon fontSize="medium" />}
          sx={styles.dateRangeContainer as SxProps}
        >
          <ListItemIcon>
            <CalendarIcon color="primary" fontSize="medium" />
          </ListItemIcon>
          <StyledListItemText primary={dateRangeSelected}/>
        </ListItem>
        <Popover
          id={id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <RadioGroup
            aria-labelledby="admin-analytics-date-filter-radio-group"
            name="controlled-dmin-analytics-date-filter-radio-group"
            value={dateRangeSelected}
            onChange={handleDateRangeChange!}
            sx={{
              p: 4,
            }}
          >
            {renderDateFiltersRadioButtons()}
            {dateRangeSelected === AdminAnalyticsDateFilterOptions.CustomDateRange && (
              <Grid container spacing={2}>
                <Grid item>
                  <DatePicker
                    minDate={getMinStartDate()}
                    maxDate={getMaxStartDate()}
                    views={['day']}
                    renderInput={(params: any) => <TextField {...params} helperText={null} sx={styles.datepickerInput} />}
                    onChange={(value: Date | null) => {
                      setStartDateSelected?.(value!);
                    }}
                    value={startDateSelected}
                    components={{
                      OpenPickerIcon: CalendarIcon,
                    }}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    maxDate={today}
                    minDate={getMinEndDate()}
                    views={['day']}
                    renderInput={(params: any) => <TextField {...params} helperText={null} sx={styles.datepickerInput as SxProps} />}
                    onChange={(value: Date | null) => {
                      setEndDateSelected?.(value!);
                    }}
                    value={endDateSelected}
                    components={{
                      OpenPickerIcon: CalendarIcon,
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </RadioGroup>
        </Popover>
      </Box>
    </>
  );
};

export default AdminAnalyticsDateFilter;
