/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useState, useContext, useCallback } from 'react';
import { NavigationType } from '../variables/types';

// @ts-ignore
const NavigationContext = createContext();

export default (props: any) => {
  const { defaultNavigation } = props;
  const [navigationType, setNewNavigationType] = useState<NavigationType>(defaultNavigation);

  const value = React.useMemo(() => ({
    navigationType,
    setNewNavigationType,
  }), [navigationType]);

  return <NavigationContext.Provider value={value} {...props} />;
};

export const useNavigationProvider = () => {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('useNavigationProvider() has to be used within a child of the Navigation Provider');
  }

  const {
    navigationType, setNewNavigationType,
  }:any = context;

  const getNavigationType = useCallback(() => navigationType, [navigationType]);

  // eslint-disable-next-line no-unused-vars
  const setNavigationType = useCallback((navType: NavigationType) => {
    setNewNavigationType(navType);
  }, [setNewNavigationType]);

  return {
    getNavigationType,
    setNavigationType,
  };
};
