import Amplify from 'aws-amplify';

const initAWSAmplify = () => {
  const {
    REACT_APP_REGION,
    REACT_APP_USER_POOL_ID,
    REACT_APP_USER_WEB_POOL_ID,
    REACT_APP_COGNITO_DOMAIN,
    REACT_APP_URL,
    // REACT_APP_USER_IDENTITY_ID,
  } = import.meta.env;

  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: REACT_APP_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: REACT_APP_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: REACT_APP_USER_WEB_POOL_ID,

      // // // OPTIONAL - User Identity Pool ID
      // identityPoolId: REACT_APP_USER_IDENTITY_ID,

      oauth: {
        domain: REACT_APP_COGNITO_DOMAIN,
        redirectSignIn: REACT_APP_URL,
        redirectSignOut: `${REACT_APP_URL}/login`,
        // TODO: Update the scope to ['openid email'] when clever is integrated
        scope: ['email'],
        responseType: 'code',
      },
    },
  });
};

export default initAWSAmplify;
