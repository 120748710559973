import React from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Box, Typography } from '@mui/material';
import XaxisCustomizeTick from './XaxisCustomizeTick';
import YaxisCustomizeTick from './YaxisCustomizeTick';
import { palette } from '../../theme/palette';

interface Props {
  barData: any,
  xAxisKey: string,
  barKey: string,
  barWidth?: number,
  barHeight: number,
  barSize: number,
  yAxisDomain: Array<number>,
  barRadius: any,
  yAxisTicks: Array<number>,
}

const OverallBenchmarkIndicatorScoreChart = ({
  barData,
  xAxisKey,
  barKey,
  barHeight,
  barSize,
  yAxisDomain,
  barRadius,
  yAxisTicks,
}: Props) => {
  const barData1 = barData.filter((data: any, index: number) => index <= 4);
  const barData2 = barData.filter((data: any, index: number) => index > 4);

  const overrideContent = ({ value, width, height, x, y, ...rest }: any) => {
    const containsDecimal = value.toString().includes('.');
    const labelColor =  (rest?.fill !== palette.customYellow.main) ?
      value >= 1.5 ? palette.customWhite.main : palette.customBlack.main : palette.customBlack.main;
    return <text {...rest} x={containsDecimal ? x + width / 3 : (x + width / 2) - 2} y={y + height - 10} fill={labelColor}>{value}</text>;
  };
  
  return (

    <Box sx={{ height: '300px', minWidth: '616px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box sx={{ display: 'flex',  flexDirection: 'column' }}>
            <Typography
              paddingLeft={7}
              paddingBottom={2}
              paddingTop={2}
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: palette.customBlack.fontBlack,
              }}>Speaking</Typography>
            <BarChart
              width={690}
              height={barHeight}
              data={barData1}
              margin={{
                top: 5,
                bottom: 40,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={xAxisKey}
                tickLine={false}
                minTickGap={1}
                tickMargin={1}
                interval={0}
                axisLine={false}
                scale="point"
                padding={{ left: 30, right: 60 }}
                tick={<XaxisCustomizeTick barData={barData}
                />} />
              <YAxis tickLine={false} axisLine={false}  tick={<YaxisCustomizeTick />} domain={yAxisDomain} ticks={yAxisTicks}/>
              <Bar dataKey={barKey} fill="fill" barSize={barSize} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                <LabelList
                  dataKey={barKey}
                  position="insideBottom"
                  fontSize={16}
                  fontWeight={800}
                  content={overrideContent}
                />
              </Bar>
            </BarChart>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column'  }}>
            <Typography
              paddingBottom={2}
              paddingTop={2}
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: palette.customBlack.fontBlack,
              }}>Writing</Typography>
            <BarChart
              width={425}
              height={barHeight}
              data={barData2}
              margin={{
                top: 5,
                bottom: 40,
                left: -58,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={xAxisKey}
                tickLine={false}
                minTickGap={1}
                tickMargin={1}
                interval={0}
                axisLine={false}
                domain={[0, 5]}
                tick={<XaxisCustomizeTick barData={barData2} />} />
              <YAxis tickLine={false} axisLine={true} tick={{ fill: 'white' }}  domain={yAxisDomain} ticks={yAxisTicks}/>
              <Bar dataKey={barKey} fill="fill" barSize={barSize} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                <LabelList
                  dataKey={barKey}
                  position="insideBottom"
                  fontSize={16}
                  fontWeight={800}
                  content={overrideContent}
                />
              </Bar>
            </BarChart>
          </Box>
        </Box>
      </ResponsiveContainer>
    </Box>
  );
};

export default OverallBenchmarkIndicatorScoreChart;

