import { Chip, styled } from '@mui/material';

export const StyledChipBox = styled(Chip)<{ backgroundColor?: string, hascolor?: string }>(({ theme, backgroundColor, hascolor }) => ({
  backgroundColor: backgroundColor ? backgroundColor : theme.palette.customBackground.dragBackground,
  fontSize: '13px',
  lineHeight: '20px',
  color: hascolor ? hascolor : theme.palette.customBlack.lightBlack,
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  justifySelf: 'flex-end',
}));
