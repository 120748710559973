import React from 'react';
import { Box, Button, Checkbox, CheckboxProps, Divider, SxProps, TextField, Typography } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import useSyncDistricts from './syncDistricts-hook';
import Chip from '@mui/material/Chip';
import { StyledAutocomplete } from './sync-district.styled';
import { palette } from '../../../theme/palette';
import { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { DistrictItem } from '../../../generated/graphql';

interface SelectProps {
  districts: DistrictItem[] | undefined,
  loading: boolean,
  setSelectedDistricts: Function,
  selectedVal: DistrictItem[] | undefined
  checkAll?: boolean,
  setCheckAll?: Function
}

const styles = {
  box: {
    backgroundColor: palette.customWhite.main,
    height: '45px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  textField: {
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    '& input.MuiInputBase-input.Mui-disabled': {
      background: `${palette.customWhite.inputDisabled} !important`,
    },
  },
};

const MultiSelectDistrict = ({ districts = [], loading, setSelectedDistricts, selectedVal, checkAll, setCheckAll }:SelectProps) => {
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  const allDistrictsOption: DistrictItem = {
    id: 'all',
    name: 'Select All',
    sync_status: '',
  };

  const districtOptions = districts?.length ? [allDistrictsOption].concat(districts) : [];

  const onSelectChange = (_:any, districtList:(any)[], reason: AutocompleteChangeReason) => {
    if (reason === 'clear' || reason === 'removeOption') {
      setCheckAll?.(false);
    } else if (reason === 'selectOption' && districtList.length === districts.length) {
      setCheckAll?.(true);
    }
    setSelectedDistricts?.(districtList);
  };

  const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll?.(event.target.checked);
    if (event.target.checked) {
      setSelectedDistricts?.(districts?.filter(district => district?.sync_status === null));
    } else {
      setSelectedDistricts?.([]);
    }
  };

  return (
    <StyledAutocomplete
      multiple
      limitTags={1}
      disableCloseOnSelect
      options={districtOptions || []}
      getOptionDisabled={(option: any) => option?.sync_status !== null}
      getOptionLabel={(option: any) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={loading}
      onChange={onSelectChange}
      value={selectedVal}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={<Typography sx={{ fontSize: '13px', fontWeight: 800 }} component='span'>{option?.name ?? ''}</Typography>}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option: any, { selected }) => {
        return option?.id === 'all' ? (
          <>
            <Box sx={{ ...styles.box } as SxProps}>
              <Checkbox
                checked={checkAll}
                onChange={checkAllChange}
                id="check-all"
                onMouseDown={(e) => e.preventDefault()}
              />
              Select All
            </Box>
            <Divider />
          </>
        ) : (
          <Box component = "li" {...props} key={option?.id}>
            <Checkbox
              color={'primary' as CheckboxProps['color']}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
              sx={{ mr: '8px' }}
            />
            {`${option?.name ?? ''}`}
          </Box >
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ ...styles.textField }}
          placeholder={params.InputProps.startAdornment && districts?.length ? '' : 'Select district(s) to sync'}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: { height: 'auto', overflow: 'auto', maxHeight: '300px' },
          }}
        />
      )}
    />
  );
};

const SyncDistrict = () => {
  const { syncDistrictList, loading, runSyncForSelectedDistricts, selectedDistricts, selectDistricts, checkAll, setCheckAll } = useSyncDistricts();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
    >
      <Box mr={2}>
        <MultiSelectDistrict
          districts={syncDistrictList}
          loading={loading}
          setSelectedDistricts={selectDistricts}
          selectedVal={selectedDistricts}
          checkAll={checkAll}
          setCheckAll={setCheckAll}
        />
      </Box>
      <Button
        onClick={runSyncForSelectedDistricts}
        disabled={!selectedDistricts.length || loading}
        color="primary"
        variant="contained"
        sx={{
          fontSize: 18,
          maxHeight: 55,
        }}
      >
        SYNC
      </Button>
    </Box>
  );
};

export default SyncDistrict;
