import React from 'react';

import { Box, Typography } from '@mui/material';

import { format } from 'date-fns';
import { palette } from '../../theme/palette';
import CircleIcon from '@mui/icons-material/Circle';

interface Props {
  active?: string,
  payload?: any
}

const style = {
  padding: 10,
  backgroundColor: palette.customWhite.main,
  border: `2px solid ${palette.customRed.progressPrimary}`,
  width: '200px',
  height: 'auto',
  borderRadius: '8px',
  boxShadow: `0 3px 5px 1px ${palette.customBackground.disabled}`,
};


const CustomStudentGrowthReportTooltip = ({ active, payload }: Props) => {
  let calendarDate = new Date(payload[0]?.payload?.timestamp);
  let benchmarkType = payload[0]?.payload?.benchmark_type;
  if (active && benchmarkType !== undefined) {
    return (
      <Box className="line-chart-tooltip" style={style}>
        <Typography
          component='p'
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            color: palette.customGrey.calendarGrey,
            mb: '5px',
            textTransform: 'uppercase',
          }}
        >
          {benchmarkType}
        </Typography>
        <Typography
          component='p'
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            color: palette.customGrey.calendarGrey,
            mb: '5px',
          }}
        >
                    Submitted {format(new Date(calendarDate), 'M/d/yy')} 

        </Typography>

        {payload?.map((item: any) => {
          return (
            <Box key={item?.color}>

              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box
                  display='flex'
                  alignItems='center'
                >
                  <CircleIcon sx={{ color: item?.color, fontSize: '16px' }} />
                  <Typography
                    variant="toolTipValue"
                    sx={{
                      ml: '10px',
                      textAlign: 'right',
                    }}
                  >
                    {item.value ? item.value : '-'}
                  </Typography>

                </Box>
              </Box>
            </Box>
          );
        },

        )}
      </Box>
    );
  }

  return null;
};

export default CustomStudentGrowthReportTooltip;
