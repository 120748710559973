import React, { useRef, useState } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell, LabelList,
} from 'recharts';
import { Box } from '@mui/material';
import CustomTooltip from '../Tooltips/StudentsToolTip';
import { CUSTOM_TOOLTIP_HEIGHT, CUSTOM_TOOLTIP_WIDTH } from '../../../variables/constant';
import { AxisDomain, EventData, TooltipState } from '../../../variables/types';
import IconBase64 from '../Label/IconBase64';
import zIndex from '../../../theme/z-index';
import { palette  } from '../../../theme/palette';

interface BarProps {
  data: unknown[],
  xAxisId?: string,
  yAxisId?: string,
  boxHeight?: number | string,
  boxWidth?: number | string,
  chartHeight?: number,
  chartWidth?: number,
  xAxisKey: string,
  yAxisKey: string,
  xAxisDomain?: AxisDomain;
  yAxisDomain?: AxisDomain;
}

const BarGraphVertical = ({
  data,
  xAxisId = 'xaxis',
  yAxisId = 'yaxis',
  boxHeight = 300,
  boxWidth = '100%',
  chartHeight = 250,
  chartWidth = 300,
  xAxisKey,
  yAxisKey,
  xAxisDomain,
  yAxisDomain,
}: BarProps) => {
  const [state, setState] = useState<TooltipState>({
    showTooltip: false,
    event: {
      pageX: 0,
      pageY: 0,
    },
    tooltipData: {},
  });
  const { showTooltip, event, tooltipData } = state;
  // Allow tooltip to stay open for timeout to make links clickable
  const tooltipTimeout = useRef<any>();
  const bodyWidth = document.querySelector('body')?.getBoundingClientRect().width ?? 0;
  const bodyHeight = document.querySelector('body')?.getBoundingClientRect().height ?? 0;

  /**
   * Track the hover on bar and capture the tooltip data
   * @param item - is data for an individual bar.
   * @param active - if the tooltip/hover is active.
   * @param eventObject: {EventData} - Captures the pageX and pageY to render the tooltip.
   * @param actionCreator - specifies if the current hove event is on tooltip or on the bar/cell.
   * */
  const handleHover = (
    item: any,
    active: boolean,
    eventObject: EventData | null,
    actionCreator: string,
  ) => {
    if (active) {
      if (actionCreator === 'tooltip') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        setState((prevState) => ({
          ...prevState,
          showTooltip: true,
        }));
      } else if (actionCreator === 'cell') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        setState((prevState) => ({
          ...prevState,
          showTooltip: true,
          tooltipData: item?.data ?? item,
          event: eventObject,
        }));
      }
    } else {
      if (actionCreator === 'cell') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        tooltipTimeout.current = setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            showTooltip: false,
            event: eventObject,
          }));
        }, 200);
      }
      if (actionCreator === 'tooltip') {
        setState((prevState) => ({
          ...prevState,
          showTooltip: false,
          event: eventObject,
          tooltipData: {},
        }));
      }
    }
  };

  return (
    <Box width={boxWidth} height={boxHeight}>
      {/* Rendering custom tooltip, The rechart library doesn't provide a way to make tooltip data clickable. */}
      {showTooltip && (
        <div
          className="custom-tooltip-container"
          id="graph-tooltip"
          style={{
            zIndex: zIndex.zIndex1,
            position: 'fixed',
            left:
              event ? (event?.pageX < (bodyWidth - CUSTOM_TOOLTIP_WIDTH)
                ? event?.pageX + 5
                : event?.pageX - CUSTOM_TOOLTIP_WIDTH) : 0,
            top:
              event ? (event?.pageY < bodyHeight - CUSTOM_TOOLTIP_HEIGHT
                ? event?.pageY - 50
                : event?.pageY - CUSTOM_TOOLTIP_HEIGHT / 2) : 0,
          }}
          onMouseEnter={() => {
            handleHover({}, true, event, 'tooltip');
          }}
          onMouseLeave={() => {
            handleHover({}, false, null, 'tooltip');
          }}
        >
          <CustomTooltip
            data={tooltipData}
          />
        </div>
      )}
      <ResponsiveContainer width="96%" height="96%">
        <BarChart
          data={data}
          width={chartWidth}
          height={chartHeight}
          barSize={30}
          layout="horizontal"
          margin={{ top: 5, right: 5, bottom: 5, left: 50 }}
        >
          <XAxis
            type="category"
            dataKey={xAxisKey}
            xAxisId={xAxisId}
            domain={xAxisDomain}
            tickLine={false}
            interval={0}
            axisLine={false}
            tick={<IconBase64 />}
          />
          <YAxis
            type="number"
            tick={{ fontSize: 16, fontWeight: 'bold' }}
            yAxisId={yAxisId}
            hide
            dataKey={yAxisKey}
            domain={yAxisDomain}
          />
          <Bar
            dataKey={yAxisKey}
            background={{ fill: palette.customBackground.graphBackgroundColor, radius: 4 }}
            xAxisId={xAxisId}
            yAxisId={yAxisId}
            radius={4}
            onMouseEnter={(item: any, _, e: React.MouseEvent<SVGElement>) => {
              handleHover(
                item,
                true,
                { pageX: e?.pageX, pageY: e?.pageY },
                'cell',
              );
            }}
            onMouseLeave={() => {
              handleHover({}, false, null, 'cell');
            }}
          >
            <>
              <LabelList
                dataKey={yAxisKey}
                position="insideBottom"
                fill={palette.customWhite.main}
                fontSize={22}
                fontWeight="bold"
              />
              {data?.map((item: any) => (
                <Cell
                  key={item?.[xAxisKey]}
                  fill={item.color}
                />
              ))}
            </>
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarGraphVertical;
