import React, { useContext } from 'react';
import {
  OrderBy,
  DistrictWideSchoolSpecificDetailsSortType,
  useDistrictWideSchoolSpecificDataQuery,
} from '../../../../generated/graphql';
import { PAGE_SIZE } from '../../../../variables/constant';
import usePagination from '../../../../utils/usePagination';
import {
  DistrictWideSchoolSpecificQueryInput,
  DistrictWideComparisonsContext,
} from '../district-wide-comparisons-hook';

interface Props {
  schoolId: string,
}

const useSchoolSpecificDetails = ({ schoolId }: Props) => {
  const {
    schoolSpecificDataQueryInput, setSchoolSpecificDataQueryInput,
  } = useContext(DistrictWideComparisonsContext);

  const updateSchoolSpecificDataQueryInput = (data: Object) => {
    setSchoolSpecificDataQueryInput?.({
      ...schoolSpecificDataQueryInput,
      ...data,
    });
  };

  const pagination = usePagination('schoolSpecificPage');

  var queryInput: DistrictWideSchoolSpecificQueryInput = schoolSpecificDataQueryInput ? schoolSpecificDataQueryInput : {
    school_id: schoolId,
    limit: PAGE_SIZE,
    page: pagination.page,
    sort: DistrictWideSchoolSpecificDetailsSortType.Teacher,
    orderBy: OrderBy.Asc,
  };

  const updateQueryInput = (queryInputUpdate: DistrictWideSchoolSpecificQueryInput) => {
    queryInput = {
      ...queryInput,
      ...queryInputUpdate,
    };
    updateSchoolSpecificDataQueryInput(queryInput);
  };

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    updateQueryInput({
      school_id: schoolId,
      page: value as number,
    });
  };

  const onSortChange = (sortBy: DistrictWideSchoolSpecificDetailsSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    updateQueryInput({
      school_id: schoolId,
      page: 1,
      sort: sortBy,
      orderBy: orderBy,
    });
  };

  const handleSort = (column: DistrictWideSchoolSpecificDetailsSortType) => () => {
    if (column === queryInput.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const { data, loading: districtWideSchoolSpecificDataLoading } = useDistrictWideSchoolSpecificDataQuery({
    fetchPolicy: 'network-only',
    variables: { ...queryInput },
    skip: !schoolId,
  });

  const districtWideSchoolSpecificData = data?.districtWideSchoolSpecificData?.districtWideSchoolSpecificDetails ?? [];
  const districtWideSchoolSpecificDataCount = data?.districtWideSchoolSpecificData?.DistrictWideSchoolSpecificDetailsCount ?? 0;
  const isTableEmpty = !districtWideSchoolSpecificDataLoading && districtWideSchoolSpecificDataCount === 0;

  return ({
    loading: districtWideSchoolSpecificDataLoading,
    queryInput, handleSort,
    pagination, handlePageChange,
    districtWideSchoolSpecificDataCount, districtWideSchoolSpecificData, isTableEmpty,
  });
};

export default useSchoolSpecificDetails;
