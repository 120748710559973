/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SchoolsIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <g id="icon-schools">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.925415 16.8099H6.97163V14.6569C6.97163 13.6098 7.82051 12.7609 8.86766 12.7609C9.9148 12.7609 10.7637 13.6098 10.7637 14.6569V16.8099H16.8099V13.8265C16.8099 12.7219 15.9145 11.8265 14.8099 11.8265H13.7552V9.98801C13.7552 9.3785 13.4773 8.80226 13.0003 8.42282L9.44826 5.59729V3.10561H12.2278V0.925385H8.36373V5.59729L4.74853 8.4228C4.26355 8.80184 3.98012 9.38308 3.98012 9.99861V11.8265H2.92541C1.82084 11.8265 0.925415 12.7219 0.925415 13.8265V16.8099ZM8.86766 10.237C9.32143 10.237 9.68927 9.86913 9.68927 9.41537C9.68927 8.9616 9.32143 8.59375 8.86766 8.59375C8.4139 8.59375 8.04605 8.9616 8.04605 9.41537C8.04605 9.86913 8.4139 10.237 8.86766 10.237Z"
        fill={props?.pathfill}
      />
    </g>
  </SvgIcon>
);

export default SchoolsIcon;
