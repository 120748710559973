import * as React from 'react';
import useAddNewMenu, { Props } from './useAddNewMenu';
import DropdownButton from '../DropdownButton';

const AddNewEntity = ({ entity, districtId, schoolId, stateId, teacherId, disabled = false }: Props) => {
  const { actionItems, onClick } = useAddNewMenu({ entity, districtId, schoolId, stateId, teacherId });

  return (
    <DropdownButton
      label={'Add New'}
      actionItems={actionItems}
      handleClick={onClick}
      disabled={disabled}
    />
  );
};

export default AddNewEntity;