import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Slide, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import useInitialiseDistrict from './initialiseDistricts-hook';
import { AutoCompleteTextField } from '../../../components/Autocomplete/Autocomplete.styled';
import ScoreSchemeRadioGroup from '../../../components/ScoreSchemeRadioGroup';
import Loader from '../../../components/Loader/loader';
import { CleverDistrict } from '../../../generated/graphql';


const styles = {
  radioContainer: {
    margin: '24px 0',
    '& .formLabel': {
      fontSize: 16,
      fontWeight: 600,
    },
  },

  selectInput: {
    width: 340,
    marginLeft: '4px 8px',
  },
};

interface DialogProps {
  openScoreScheme: boolean,
  district: CleverDistrict | null,
  handleClose: () => void | Function
}

const ScoreSchemeConfirmation = ({ openScoreScheme, district, handleClose }: DialogProps) => {
  const { scoreSchemeOptions, scoreSchemesDetailsLoading, control, updateDistrict, loading, errors, handleSubmit } = useInitialiseDistrict();
  return (
    <Dialog open={openScoreScheme} onClose={handleClose} TransitionComponent={Slide}>
      <form onSubmit={handleSubmit(data => updateDistrict(data, district))}>
        <DialogContent>
          <Typography variant='secondaryTitle'>
            {'Select a score scheme for '}
            {district?.name}
          </Typography>
          <Controller
            name="score_scheme_id"
            render={({ onChange, value }) => (
              <ScoreSchemeRadioGroup
                value={value}
                onChange={onChange}
                radioContainerStyles={{ ...styles.radioContainer }}
                scoreSchemeOptions={scoreSchemeOptions}
              />
            )}
            rules={{ required: 'Please select score scheme.' }}
            control={control}
            defaultValue={null}

          />
          <Typography variant='sFormHelper' component='p'>
            {errors.score_scheme_id && errors.score_scheme_id.message}
          </Typography>
          <Loader open={scoreSchemesDetailsLoading || loading} />
        </DialogContent>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <DialogActions>
            <Button onClick={() => handleClose()} color="primary" variant="outlined" disabled={loading}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button type="submit" color="primary" variant="contained" disabled={loading}>
                Set Scheme
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};

const InitialiseDistrict = () => {
  const { districtList, onDistrictChange, loading, schemeDialogOpen, setSchemeDialog, selectedDistrict } = useInitialiseDistrict();
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        width="100%"
      >
        <Box mr={2}>
          <Autocomplete
            sx={{ ...styles.selectInput }}
            options={districtList || []}
            getOptionLabel={(option: any) => `${option?.name} - (${option?.source})`}
            isOptionEqualToValue={(option: any, selected: any) => option?.name === selected?.name}
            onChange={onDistrictChange}
            disabled={!districtList?.length || loading}
            renderInput={(params) => (
              <AutoCompleteTextField
                {...params}
                placeholder={!districtList?.length ? 'No districts' : 'Select a district ready for import'}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Box>
        <Box>
          {' '}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!districtList?.length || loading || !selectedDistrict}
            onClick={() => setSchemeDialog(true)}
          >
            INITIALIZE
          </Button>
        </Box>
      </Box>
      <ScoreSchemeConfirmation openScoreScheme={schemeDialogOpen} district={selectedDistrict} handleClose={() => setSchemeDialog(false)} />
    </>
  );
};

export default InitialiseDistrict;
