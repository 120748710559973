import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { isIpad } from '../../variables/constant';

export const StyledTypography = styled(Typography)({
  fontWeight: 800,
  fontSize: isIpad ? '20px' : '24px',
  lineHeight: '25px',
});

export const StyledDateTypography = styled(Typography)({
  fontSize: 16,
  fontWeight: 'normal',
});

export const StyledBodyTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  color: theme.palette.fontColors.fontBlack,
  fontSize: isIpad ? '14px' : '18px',
}));


export const StyledBox = styled(StyledBodyTypography)(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  maxHeight: 70,
  height: 70,
  overflowY: 'scroll',
}));
