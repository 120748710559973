/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const NoAudioIcon = (props: any) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)"><path d="M28.7594 23.8945l-6.1641-4.764c.4918-.9379.7805-1.9973.7805-3.1305v-1.875c0-.3453-.2797-.625-.625-.625h-.625c-.3453 0-.625.2797-.625.625V16c0 .7-.1547 1.3594-.4187 1.9609l-1.0371-.8015c.1211-.3672.2062-.7508.2062-1.159V9.75c0-2.07109-1.6789-3.75-3.75-3.75s-3.75 1.67891-3.75 3.75v1.7719L5.77699 6.13164c-.27265-.21172-.66562-.16289-.87734.10977l-.76719.98711c-.21172.27226-.16289.66484.10977.87695L27.225 25.8684c.2727.2121.6653.1628.8774-.1098l.7672-.9871c.2113-.2723.1625-.6649-.1102-.877zm-9.1336.2305h-2.1875v-1.3191c.4555-.0625.8926-.1774 1.3153-.3246l-1.9575-1.5129c-.2621.0156-.5238.0339-.7949.0078-2.1816-.2129-3.857-1.8996-4.343-3.9785l-2.03238-1.5715v.2676c0 3.5015 2.49878 6.623 5.93748 7.0972v1.334h-2.1875c-.3453 0-.625.2797-.625.625v.625c0 .3453.2797.625.625.625h6.25c.3453 0 .625-.2797.625-.625v-.625c0-.3453-.2797-.625-.625-.625z" fill={palette.customPink.main} /></g>
      <circle cx="16" cy="16" r="15" stroke={palette.customPink.main} strokeWidth="2" />
      <defs><clipPath id="clip0"><path fill={palette.customWhite.main} transform="translate(4 6)" d="M0 0h25v20H0z" /></clipPath></defs>
    </svg>
  </SvgIcon>
);

export default NoAudioIcon;
