import React from 'react';
import { createPortal } from 'react-dom';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer, styled,
} from '@mui/material';
import useDevtool from './dev-tool-hook';
import Flag from './Flag';
import useFeature from '../../hooks/useFeature';
import zIndex from '../../theme/z-index';
import { palette  } from '../../theme/palette';

const styles = {
  devButton: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    height: '40px',
    minWidth: '180px',
    zIndex: zIndex.zIndex99999,
  },
  tool: {
    width: '100%',
    bottom: '0',
    left: '0',
    position: 'fixed',
    zIndex: zIndex.zIndex99999,
    borderTop: `1px solid ${palette.customBackground.disabled}`,
    height: '40vh',
    backgroundColor: palette.customWhite.main,
  },
  tableContainer: {
    maxHeight: '30vh',
    borderRadius: 0,
  },
  title: {
    color: palette.fontColors.fontBlack,
    fontSize: '32px',
    fontWeight: 800,
    lineHeight: '44px',
    letterSpacing: '0.1px',
  },
  dangerButton: {

  },
};

const StyledButton = styled(Button)({
  backgroundColor: palette.customRed.main,
  color: palette.customWhite.main,
  '&::after': {
    backgroundColor: palette.customRed.main,
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
});

const DevTools = () => {
  const { isEnabled: isClearDataEnabled } = useFeature('CLEAR_DATA');
  const {
    showDevtools,
    showPanel,
    setShowPanel,
    featureFlags,
    featureFlagDispatch,
    clearData,
  } = useDevtool();

  if (!showDevtools) return null;

  const featureFlagKeys = Object.keys(featureFlags ?? {}) || [];

  return (
    <>
      {createPortal(
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowPanel((prev) => !prev)}
          sx={{ ...styles.devButton }}
        >
          Dev tool
        </Button>,
        document.body,
      )}
      {showPanel
      && createPortal(
        <Box
          sx={{ ...styles.tool }}
        >
          <Box display="flex" justifyContent="space-between" p={2}>
            <Typography sx={{ ...styles.title }}>
              Devtool
            </Typography>
            {import.meta.env.REACT_APP_ENV !== 'prod' && isClearDataEnabled
            && (
              <Box
                justifyContent="flex-end"
                display="flex"
                flexDirection="column"
              >
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={clearData}
                >
                  Clear Data
                </StyledButton>
                <Typography variant="body2">Caution: Clicking on clear data will delete all the data of this platform</Typography>
              </Box>
            )}
          </Box>
          <Box>
            <TableContainer sx={{ ...styles.tableContainer }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Flag
                    </TableCell>
                    <TableCell>
                      Enabled
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {featureFlagKeys?.length > 0 ? featureFlagKeys.map((flag) => (
                    <Flag
                      key={flag}
                      flag={flag}
                      checked={Boolean(featureFlags?.[flag])}
                      featureFlagDispatch={featureFlagDispatch || (() => {})}
                    />
                  )) : (
                    <TableRow>
                      <TableCell>No feature flags available.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>,
        document.body,
      )}
    </>
  );
};

export default DevTools;
