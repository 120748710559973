import React from 'react';
import { Box, CircularProgress, SxProps, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PaginationProps } from '../../../../../variables/types';
import {
  OrderBy,
  PerformanceAndLearningGoalWidgetSlideOutLineItem,
  PerformanceSlideOutSortType,
} from '../../../../../generated/graphql';
import { StyledTableCell2 } from '../../../../Admin/admin-tables-styled';
import {
  StyledTableContainer,
  StyledTableSort2,
  tableWithNoDataStyles,
} from '../../../../../components/Common/Common.styled';
import { PerformanceStylesCSS } from '../../Performance.styled';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import AnalyticsIcon from '../../../../../components/Icons/AnalyticsIcon';
import NoResults from '../../../../../components/NoResults';
import ListRow from './ListRow';
import TablePagination from '../../../../../components/TablePagination';

interface Props {
  isTableEmpty?: boolean,
  pagination?: PaginationProps,
  loading?: boolean,
  totalCount?: number,
  limit?: number,
  sort?: PerformanceSlideOutSortType,
  order?: OrderBy,
  students?: PerformanceAndLearningGoalWidgetSlideOutLineItem[],
  handlePageChange?: (_: React.ChangeEvent, value: React.SetStateAction<number>) => void | undefined,
  handleSort?: ((column: PerformanceSlideOutSortType) => void) | Function,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  limit,
  sort,
  order,
  students,
  handlePageChange,
  handleSort,
}: Props) => {
  const theme = useTheme();
  const styles = PerformanceStylesCSS(theme);
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  return (
    <Box
      display="grid"
      width="100%"
      height="100%"
    >
      <Box>
        <StyledTableContainer sx={{ ...styles.tableContainer, ...styles.sliderTableContainer } as SxProps}>
          <Table
            stickyHeader
            aria-label="District-wide Comparisons School Specific Table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell2 sx={{ ...styles.width20, ...styles.drawerTableColumn, textAlign: 'left' }}>
                  <StyledTableSort2
                    direction={orderByIcon}
                    IconComponent={ArrowDownIcon}
                    onClick={handleSort?.(PerformanceSlideOutSortType.PerformanceSlideOutSortByStudentName)}
                    active={sort === PerformanceSlideOutSortType.PerformanceSlideOutSortByStudentName}
                  >
                    Student
                  </StyledTableSort2>
                </StyledTableCell2>
                <StyledTableCell2 sx={{ ...styles.width5, ...styles.drawerTableColumn }}>
                  <StyledTableSort2
                    direction={orderByIcon}
                    IconComponent={ArrowDownIcon}
                    onClick={handleSort?.(PerformanceSlideOutSortType.PerformanceSlideOutSortByStudentGrade)}
                    active={sort === PerformanceSlideOutSortType.PerformanceSlideOutSortByStudentGrade}
                  >
                    Grade
                  </StyledTableSort2>
                </StyledTableCell2>
                <StyledTableCell2 sx={{ ...styles.width25, ...styles.drawerTableColumn }}>
                  <StyledTableSort2
                    direction={orderByIcon}
                    IconComponent={ArrowDownIcon}
                    onClick={handleSort?.(PerformanceSlideOutSortType.PerformanceSlideOutSortByStudentSchool)}
                    active={sort === PerformanceSlideOutSortType.PerformanceSlideOutSortByStudentSchool}
                  >
                    School
                  </StyledTableSort2>
                </StyledTableCell2>
                <StyledTableCell2 sx={{ ...styles.width20, ...styles.drawerTableColumn }}>
                    Teacher
                </StyledTableCell2>
                <StyledTableCell2 sx={{ ...styles.width30, ...styles.drawerTableColumn }}>
                    Class
                </StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && students?.map((student, index) => (
                <ListRow rowIndex={index} student={student} key={student?.student_id} />
              ))}
              {!loading && isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="We Need More Data."
                      description=""
                      svgComponent={<AnalyticsIcon />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        paddingTop="20px"
      >
        {totalCount! > limit! && (<TablePagination
          count={Math.ceil(totalCount! / limit!) || 0}
          page={pagination!.page}
          onChange={handlePageChange!}
          defaultPage={pagination!.page}
          disabled={totalCount! < limit!}
        />)}
      </Box>
    </Box>
  );
};
export default List;
