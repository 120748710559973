import { useEffect, useState } from 'react';
import {
  AdminDashboardFilterInput,
  LearningGoalsCompletedPTsMostRecentGoalsPerMonth,
  useCompletedAssignmentsLearningGoalsWidgetQuery,
} from '../../../../generated/graphql';
import { useDashboardContext } from '../../admin-analytics-hook';
import { AdminDashboards } from '../../../../variables/constant';

interface QueryInputProps {
  district_id?: string,
  school_id?: string,
  input?: AdminDashboardFilterInput,
}

const useCompletedAssignmentLearningGoals = () => {
  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
    analyticsFilters: queryFilters,
    sessionStartDate, sessionEndDate,
    startDateSelected, endDateSelected,
  } = useDashboardContext();

  const [hasData, setHasData] = useState<any>();

  const queryInput: QueryInputProps = {
    input: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.school_id = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData) queryInput.district_id = myDistrictData?.id;

  const { data, loading: assignmentLearningGoalsCompletedLoading } = useCompletedAssignmentsLearningGoalsWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryInput },
  });

  useEffect(() => {
    let monthDataAvailable: boolean[] = [];
    data?.learningGoalsCompletedPTsMostRecentGoalsData?.forEach((monthData: LearningGoalsCompletedPTsMostRecentGoalsPerMonth) => {
      if (monthData?.unique_student_completed_pts_count > 0 || monthData?.most_recent_goals_count > 0) {
        monthDataAvailable.push(true);
      } else  {
        monthDataAvailable.push(false);
      }
    });
    if (monthDataAvailable.every(element => element === false)) { setHasData(0); } else { setHasData(1); }
    updateDashboardWidgetsDataStatus?.({
      widget: AdminDashboards.learningGoals.CompletedAssignmentsGoalsWidget.name,
      loading: assignmentLearningGoalsCompletedLoading,
      dataMissing: !hasData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentLearningGoalsCompletedLoading, data]);

  return ({
    assignmentLearningGoalsCompletedLoading,
    completeData: data?.learningGoalsCompletedPTsMostRecentGoalsData,
    title: 'Completed Assignments with Learning Goals',
    sessionStartDate, sessionEndDate,
    currStartDate: startDateSelected, currEndDate: endDateSelected,
    hasData,
  });
};

export default useCompletedAssignmentLearningGoals;
