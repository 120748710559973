/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext } from 'react';
import useLocalStorageState from '../hooks/useLocalStorageState';
import { ImitatationUserType } from '../variables/types';
import { clearLocalStorageAnalyticsFilters } from '../utils/useLocalStorage';

// @ts-ignore
const ImitationContext = createContext();

const ImitationContextInit = (props: any) => {
  const [imitationUserId, setImitationUserId] = useLocalStorageState('ImitationUser', '');
  const [imitationUserType, setImitationUserType] = useLocalStorageState('ImitationUserType', '');
  const [imitationId, setImitationId] = useLocalStorageState('ImitationId', '');

  const value = React.useMemo(() => ({
    imitationUserId,
    setImitationUserId,
    imitationUserType,
    setImitationUserType,
    imitationId,
    setImitationId,
  }), [
    imitationUserId,
    setImitationUserId,
    imitationUserType,
    setImitationUserType,
    imitationId,
    setImitationId,
  ]);

  return <ImitationContext.Provider value={value} {...props} />;
};

export default ImitationContextInit;

export const useImitationProvider = () => {
  const context = useContext(ImitationContext);
  if (!context) {
    throw new Error('useImitationProvider() has to be used within a child of the Imitation Provider');
  }

  const {
    imitationUserId,
    setImitationUserId,
    imitationUserType,
    setImitationUserType,
    imitationId,
    setImitationId,
  }:any = context;

  // getter Callbacks for imitation context
  const getImitationUserId = () => imitationUserId;
  const getImitationUserType = () => imitationUserType;
  const getImitationId = () => imitationId;

  // setter Callbacks for imitation context
  const setNewImitationUserId = (imitation: string) => {
    setImitationUserId(imitation);
  };
  const setNewImitationUserType = (imitation: string) => {
    setImitationUserType(imitation);
  };
  const setNewImitationId = (imitation: string) => {
    setImitationId(imitation);
  };

  const handleImitationLogin = (userId: string, userType: string, imitationIdT: string) => {
    setNewImitationUserId(userId);
    setNewImitationUserType(userType);
    setNewImitationId(imitationIdT);
    window.location.href = '/';
  };

  const handleImitationLogout = () => {
    setNewImitationUserId('');
    setNewImitationUserType('');
    setNewImitationId('');
    clearLocalStorageAnalyticsFilters();
    // Using window object to update all individual components
    switch (imitationUserType) {
      case ImitatationUserType.Teacher:
        window.location.href = `/teachers/${imitationId}`;
        break;
      case ImitatationUserType.Student:
        window.location.href = `/admin-students/${imitationId}`;
        break;
      case ImitatationUserType.DistrictAdmin:
        window.location.href = `/admins/district/${imitationId}`;
        break;
      case ImitatationUserType.SchoolAdmin:
        window.location.href = `/admins/school/${imitationId}`;
        break;

      default:
        break;
    }
  };

  return {
    imitationUserId,
    getImitationUserId,
    getImitationUserType,
    getImitationId,
    setNewImitationUserId,
    setNewImitationUserType,
    setNewImitationId,
    handleImitationLogin,
    handleImitationLogout,
  };
};
