import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useWriting from './writing-hook';
import { AnalyticsTabs, GraphSetData, WritingSkills } from '../../../variables/types';
import useRedirect from '../../../hooks/useRedirect';
import BackdropModal from '../../../components/BackdropModal/BackdropModal';
import ScoreTable from '../PerformanceDetails/ScoreTable';
import PerformanceGraph from '../PerformanceDetails/PerformanceGraph';
import { AnalyticsStylesCSS } from '../Analytics.styled';

const Writing = () => {
  const theme = useTheme();
  const styles = AnalyticsStylesCSS(theme);
  const { navigateTo: navigateToPath } = useRedirect();
  const {
    vocabData,
    vocabCount,
    vocabImage,
    pointsAndReasonData,
    pointsAndReasonCount,
    pointsAndReasonImage,
    descriptionData,
    descriptionCount,
    descriptionImage,
    loading,
    tableData,
    setTableData,
    togglePreview,
    showPreview,
  } = useWriting();

  const navigateTo = (route: string) => () => {
    navigateToPath(route);
  };

  const handleModal = (data: GraphSetData | null) => {
    setTableData(data);
    togglePreview();
  };

  return (
    <Box
      display="grid"
      sx={{
        ...styles.container,
        gridGap: 24,
      }}
      my={4}
      gridTemplateColumns="repeat(3, 1fr)"
    >
      <PerformanceGraph
        data={vocabData}
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Writing}/details/${WritingSkills.VocabAndGrammar}`)}
        onLinkClick={handleModal}
        title="Vocabulary & Grammar"
        totalCount={vocabCount}
        loading={loading}
        iconString={vocabImage}
        clickToRedirect
      />
      <PerformanceGraph
        data={pointsAndReasonData}
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Writing}/details/${WritingSkills.PointsAndReason}`)}
        onLinkClick={handleModal}
        title="Points & Reasons"
        totalCount={pointsAndReasonCount}
        loading={loading}
        iconString={pointsAndReasonImage}
        clickToRedirect
      />
      <PerformanceGraph
        data={descriptionData}
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Writing}/details/${WritingSkills.DescriptionAndExplanation}`)}
        onLinkClick={handleModal}
        title="Description & Explanation"
        totalCount={descriptionCount}
        loading={loading}
        iconString={descriptionImage}
        clickToRedirect
      />
      <BackdropModal
        open={showPreview}
        onClose={togglePreview}
      >
        <Box sx={styles.dropContent}>
          <ScoreTable data={tableData} onClose={togglePreview} hoverData />
        </Box>
      </BackdropModal>
    </Box>
  );
};

export default React.memo(Writing);
