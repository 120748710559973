import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledContainer8, StyledContainer9 } from '../ScoreTask.styled';
import {
  SubmissionGoalFragment,
  SubmissionTypeGroupFragmentFragment,
} from '../../../generated/graphql';
import capitalize from '../../../utils/capitalize';
import SelectiveButtons, { SelectiveButtonGroupProps } from '../../../components/SelectiveButtons';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';
import { goalScoreOptions } from '../../../variables/types';

interface Props {
  questions?: SubmissionTypeGroupFragmentFragment;
  radioOptions?: SelectiveButtonGroupProps[];
  goals?: SubmissionGoalFragment;
  handleScoreUpdate?: Function;
  handleGoalStatus?: Function;
  disableSelection: boolean;
  goalType: string;
}

const NoGoals = () => (
  <Typography variant="sLabel" fontWeight={800}>
    No goals were assigned to this performance task.
  </Typography>
);

const Questionnaire = ({ goals, handleGoalStatus, disableSelection, goalType }: Props) => {
  const theme = useTheme();
  const renderGoals = () => {
    if (!goals || (goals?.speaking?.length < 1 && goalType === 'Speaking')) {
      return (
        <NoGoals />
      );
    }
    if (!goals || (goals?.writing?.length < 1 && goalType === 'Writing')) {
      return (
        <NoGoals />
      );
    }
    const goalsList = (Object.keys(goals) as Array<keyof SubmissionGoalFragment>) || [];
    return goalsList.reduce((acc: Array<ReactNode>, item) => {
      const goalsOptions = [...acc];
      if ((item === goalType.toLocaleLowerCase() && item !== '__typename') && goals?.[item]?.length > 0) { // here we need to change title to  w.r.t  speaking or writing
        goalsOptions.push(
          <StyledContainer8 key={`${item}-goal`}>
            <Box>
              <Typography variant='sTaskTitle' component='p'>
                {`${capitalize(item) ?? ''} Goal (${goals?.[item][0]?.goal_details?.rubric?.title ?? ''})`}
              </Typography>
              <Typography variant='sLabel' sx={{ marginTop: theme.spacing(2), maxWidth: theme.spacing(63.75) }} component='p'>
                {goals?.[item][0]?.goal_details?.predefined_goals[0]?.goal_list[0]?.description}
              </Typography>
              <Typography variant='sLabel' sx={{ marginTop: theme.spacing(2), fontStyle: 'italic' }}>
                {goals?.[item][0]?.goal_details?.predefined_goals[0]?.goal_list[0]?.example}
              </Typography>
            </Box>
            <SelectiveButtons
              handleChange={
                (event: React.ChangeEvent<HTMLInputElement>) => handleGoalStatus?.(goals?.[item][0]?.id, event.target.value)
              }
              radios={goalScoreOptions}
              row
              value={
                goals?.[item][0]?.goal_status ?? null
              }
              disableSelection={disableSelection}
            />
          </StyledContainer8>,
        );
      }
      return goalsOptions;
    }, []);
  };

  return (
    <StyledContainer9>
      <StyledFlexColBoxContainer
        sx={{
          overflowY: 'auto',
          /* TODO: This height value can be changed as per requirement */
          height: '250px',
        }}
      >
        { renderGoals()}
      </StyledFlexColBoxContainer>
    </StyledContainer9>
  );
};

export default React.memo(Questionnaire);
