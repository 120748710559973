import React, { useContext, useEffect, useState, MouseEvent } from 'react';
import { Box, Typography } from '@mui/material';
import { isIOS, isSafari } from 'react-device-detect';
import { TAG_WIDTH, TAG_HEIGHT } from '../../../variables/constant';
import { CrossIcon } from '../../../components/Icons';
import { AnnotationInterface } from '../../../variables/types';
import { SubmitTaskContext } from '../submit-task-context';
import { palette  } from '../../../theme/palette';
import { SxProps } from '@mui/system';

interface Props {
  tag: AnnotationInterface,
  index: number,
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  maxWidth: number,
  maxHeight: number,
  disableEdit: boolean;
}

const styles = {
  tag: {
    backgroundColor: palette.customBackground.tagBackground,
    borderBottom: `2px solid ${palette.fontColors.fontBlack}`,
    borderRadius: '12px',
    color: palette.customWhite.main,
    padding: 1,
    cursor: 'move',
    minHeight: TAG_HEIGHT,
    wordBreak: 'break-word',
    position: (isIOS || isSafari) ? 'fixed' : 'relative',
  },
  title: {
    fontSize: '24px',
    lineHeight: '26px',
    margin: 0,
    fontWeight: 800,
  },
  deleteIconWrapper: {
    backgroundColor: palette.customPink.main,
    padding: 0.5,
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
    right: -5,
    top: -15,
  },
  deleteIcon: {
    height: '18px',
    width: '18px',
  },
  safariTags: {
    width: 100,
    height: 40,
  },
};

const Tag = ({
  tag,
  index,
  svgRef,
  maxHeight,
  maxWidth,
  disableEdit,
}: Props) => {
  const {
    deleteAnnotation,
    handleAnnotationUpdate,
  } = useContext(SubmitTaskContext);
  const [offset, setOffset] = useState({
    x: 0,
    y: 0,
  });

  const [position, setPosition] = useState({
    x: tag.x_position,
    y: tag.y_position,
  });

  useEffect(() => {
    setPosition({
      x: tag.x_position, y: tag.y_position,
    });
  }, [tag.x_position, tag.y_position]);

  const startDrag = (event: MouseEvent) => {
    if (disableEdit) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    const tempPosition = {
      ...position,
    };
    const point = svgRef?.current?.createSVGPoint();
    if (point) {
      point.x = event.clientX;
      point.y = event.clientY;
      const svgInverse = svgRef?.current?.getScreenCTM()?.inverse();
      if (svgInverse) {
        const svgP = point?.matrixTransform(svgInverse);
        setOffset({
          ...offset,
          x: svgP.x - position.x,
          y: svgP.y - position.y,
        });
      }
    }
    // TODO: Find typescript MouseEvent for SVG Drag. The MouseEvent is not working
    const drag = (es: any) => {
      es.stopPropagation();
      es.preventDefault();

      if (point) {
        point.x = es.clientX;
        point.y = es.clientY;
        const svgInverse = svgRef?.current?.getScreenCTM()?.inverse();
        if (svgInverse) {
          const svgP = point?.matrixTransform(svgInverse);
          const x = svgP.x - offset.x;
          const y = svgP.y - offset.y;
          if (x < 0 || x > (maxWidth - TAG_WIDTH) || y < 0 || y > (maxHeight - TAG_HEIGHT)) {
            return;
          }
          if (!isSafari) {
            setPosition({
              x: svgP.x - offset.x,
              y: svgP.y - offset.y,
            });
          }
          tempPosition.x = svgP.x - offset.x;
          tempPosition.y = svgP.y - offset.y;
        }
      }
    };
    // TODO: Find typescript MouseEvent for SVG Drag. The MouseEvent is not working
    const endDrag = (ed: any) => {
      ed.stopPropagation();
      ed.preventDefault();
      handleAnnotationUpdate?.(index, {
        x_position: tempPosition.x,
        y_position: tempPosition.y,
      });
      svgRef?.current?.removeEventListener('mousemove', drag);
      svgRef?.current?.removeEventListener('mouseup', endDrag);
    };

    svgRef?.current?.addEventListener('mousemove', drag);
    svgRef?.current?.addEventListener('mouseup', endDrag);
  };

  const startTouchDrag = (event: React.TouchEvent<SVGGElement>) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (disableEdit) {
      return;
    }
    event.stopPropagation();
    const tempPosition = {
      ...position,
    };
    const point = svgRef?.current?.createSVGPoint();
    if (point) {
      point.x = event.touches?.[0]?.clientX;
      point.y = event.touches?.[0]?.clientY;
      const svgInverse = svgRef?.current?.getScreenCTM()?.inverse();
      if (svgInverse) {
        const svgP = point?.matrixTransform(svgInverse);
        setOffset({
          ...offset,
          x: svgP.x - position.x,
          y: svgP.y - position.y,
        });
      }
    }
    const drag = (dragEvent: TouchEvent) => {
      dragEvent.stopPropagation();
      dragEvent.preventDefault();

      if (point) {
        point.x = dragEvent.touches?.[0]?.clientX;
        point.y = dragEvent.touches?.[0]?.clientY;
        const svgInverse = svgRef?.current?.getScreenCTM()?.inverse();
        if (svgInverse) {
          const svgP = point?.matrixTransform(svgInverse);
          const x = svgP.x - offset.x;
          const y = svgP.y - offset.y;
          if (x < 0 || x > (maxWidth - TAG_WIDTH) || y < 0 || y > (maxHeight - TAG_HEIGHT)) {
            return;
          }
          if (!isSafari) {
            setPosition({
              x: svgP.x - offset.x,
              y: svgP.y - offset.y,
            });
          }
          tempPosition.x = svgP.x - offset.x;
          tempPosition.y = svgP.y - offset.y;
        }
      }
    };
    const endDrag = (endDragEvent: TouchEvent) => {
      endDragEvent.stopPropagation();
      endDragEvent.preventDefault();
      handleAnnotationUpdate?.(index, {
        x_position: tempPosition.x,
        y_position: tempPosition.y,
      });
      svgRef?.current?.removeEventListener('touchmove', drag);
      svgRef?.current?.removeEventListener('touchend', endDrag);
    };

    svgRef?.current?.addEventListener('touchmove', drag);
    svgRef?.current?.addEventListener('touchend', endDrag);
  };

  const handleDelete = (event: MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    deleteAnnotation?.(index);
  };

  const preventDrag = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <g
      x={position.x}
      y={position.y}
      onMouseDown={startDrag}
      onTouchStart={startTouchDrag}
      key={tag.id}
    >
      <foreignObject
        x={position.x}
        y={position.y}
        style={{ overflow: 'visible', position: 'relative' }}
        height={20}
        width={TAG_WIDTH}
      >
        <Box
          data-xmlns="http://www.w3.org/1999/xhtml"
          sx={{ ...styles.tag as SxProps }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{ ...styles.title }}
          >
            {tag?.title}
          </Typography>
          {!disableEdit && (
            <Box
              sx={{ ...styles.deleteIconWrapper as SxProps }}
              display="flex"
              ml={1}
              onClick={handleDelete}
              onMouseDown={preventDrag}
              onTouchStart={(event: React.TouchEvent<HTMLElement>) => {
                event.stopPropagation();
              }}
            >
              <CrossIcon
                sx={{ ...styles.deleteIcon }}
              />
            </Box>
          )}
        </Box>
      </foreignObject>
    </g>
  );
};

export default Tag;
