import React from 'react';
import { StyledBox } from './AppFilter.styled';


interface Props {
  children: React.ReactNode
}

// USAGE OF APP FILTER
// The component could be called as a box with filter label and reset button, or individual filters could also be processed as
// <AppFilter>
//   <ListMenu menuTitle="GRADES" inputValue={taskName} changeTask={handleChange} taskArray={tasks} />
//   <CheckBoxListMenu menuTitle="KEYWORDS" inputValue={multiTaskName} changeTask={handleChangeMulti} taskArray={tasks} />
//   <CalenderPicker
//     selectedDate={selectedDate}
//     onDateChange={(date) => changeDate(date)}
//     minDate={new Date()}
//     maxDate={new Date()}
//     label="Date"
//   />
//   <PrimaryButton handleClick={resetFilters} label="Reset" />
// </AppFilter>
// const [taskName, setTaskName] = React.useState(''); // for single selection
// const [multiTaskName, setMultiTaskName] = React.useState<string[]>([]);
//  const [selectedDate, handleDateChange] = useState(new Date());
// const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
//   setTaskName(event.target.value as string);
// };
// const handleChangeMulti = (event: React.ChangeEvent<{ value: unknown }>) => {
//   setMultiTaskName(event.target.value as string[]);
// };
// const resetFilters = () => {
//   setMultiTaskName([]);
//   setTaskName('');
//   handleDateChange(new Date());
// };
// all this could be done in calling component

const AppFilter = ({ children }: Props) => {
  return (
    <StyledBox>
      {children}
    </StyledBox>
  );
};

export default AppFilter;
