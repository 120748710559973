import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { ChipTypes } from '../../variables/types';
import { palette } from '../../theme/palette';

const chipStyles = {
  chip: {
    margin: '8px',
    padding: '8px',
    color: palette.fontColors.fontBlack,
    border: `1px solid ${palette.customBackground.disabled}`,
  },
  selectedChip: {
    '&.MuiChip-colorPrimary': {
      color: `${palette.customWhite.main} !important`,
    },
  },
};

interface ChipsProps {
  value?: string[],
  onChange: (selectedItems: string[] | null) => void,
  options: Object[],
  isBold?: boolean,
}

const MultipleSelectChips = ({
  value,
  onChange,
  options,
  isBold,
} : ChipsProps) => {

  const handleClick = (clickedValue: string) => {
    if (value?.find((event : any) => event === clickedValue)) {
      const index = value?.findIndex((event: any) => event === clickedValue);
      const arr = value ? [...value] : [];
      arr.splice(index, 1);
      onChange([...arr]);
    } else {
      onChange([...(value || []), clickedValue]);
    }
  };

  return (
    <Box sx={{ textAlign: 'left' }}>
      {options && options.length
        ? options.map((option:any) => (
          <Chip
            sx={{
              ...chipStyles.chip,
              ...(value && value?.find((event: any) => event === option?.value) && {
                ...chipStyles.selectedChip,
              }),
            }}
            key={option?.value}
            color="primary"
            variant={value && value?.find((event: any) => event === option?.value)
              ? undefined : ChipTypes.outlined}
            label={
              <Typography variant="body2" sx={{ ...(isBold ? { fontWeight: '800' } : {}), }}>{`${option?.label}`}</Typography>
            }
            clickable
            onClick={() => handleClick(option?.value)}
          />
        ))
        : null}
    </Box>
  );
};

export default MultipleSelectChips;
