import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Loader from '../../../../components/Loader/loader';
import PencilIcon from '../../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../../components/StyledCard/StyledCard.styled';
import ResourceDetailHeader from '../../../../components/ResourceDetailHeader';
import ResourceDetail from '../../../Districts/District/resource-detail';
import AppBreadcrumbs, { BreadcrumbItem } from '../../../../components/AppBreadcrumbs';
import useSuperAdminDetails from './superadmindetail-hook';
import SuperAdminIcon from '../../../../components/Icons/SuperAdminIcon';
import MessageIcon from '../../../../components/Icons/MessageIcon';
import { adminToolsRoute, superAdminRoute } from '../../../../variables/staticRoutes';
import { palette } from '../../../../theme/palette';

const styles = {
  marginRight: {
    marginRight: '4px',
  },
  link: {
    fontWeight: 700,
    color: palette.customBlue.primaryBlue,
    textDecoration: 'none',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
};
const SuperAdmin = () => {

  const { id, loading, superAdminDetails } = useSuperAdminDetails();

  const breadcrumb: BreadcrumbItem[] = [
    adminToolsRoute,
    superAdminRoute,
    {
      label: `${superAdminDetails?.first_name ?? ''} ${superAdminDetails?.middle_name ?? ''} ${superAdminDetails?.last_name ?? ''}`,
      route: `${superAdminRoute.route}/${superAdminDetails?.id}`,
    },
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <AppBreadcrumbs breadcrumb={loading ? [] : breadcrumb} />
      <ResourceDetailHeader
        title={`${superAdminDetails?.first_name ?? ''} ${superAdminDetails?.middle_name ?? ''} ${superAdminDetails?.last_name ?? ''}`}
        primaryBtnRedirectionLink={`/settings/super-admin/create/${superAdminDetails?.id}`}
        PrimaryBtnIcon={PencilIcon}
        primaryBtnLabel={'Edit'}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          {superAdminDetails?.title && <ResourceDetail label={(
            <Box display="flex">
              <Typography sx={{ ...styles.marginRight, ...styles.capitalize }}>{`${superAdminDetails?.title ?? ''}`}</Typography>
              <Typography sx={{ ...styles.marginRight }}>at </Typography>
              <Box>
                <Typography
                  sx={{ ...styles.link, ...styles.marginRight }}
                  component={Link}
                  to={'/'}
                >
                  Flashlight 360
                </Typography>
              </Box>
            </Box>
          )} Icon={SuperAdminIcon} />}
          <ResourceDetail label={`Admin ID: ${superAdminDetails?.admin_id || '-'}`} Icon={SuperAdminIcon} />
          <ResourceDetail label={` ${superAdminDetails?.email || '-'}`} Icon={MessageIcon} />
        </StyledBoxWrapper>
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default SuperAdmin;
