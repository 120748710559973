import { Box, MenuItem, styled } from '@mui/material';
import StateFilter from '../../../components/StateFilter';
import DistrictFilter from '../../../components/DistrictFilter';
import SelectGrade from '../../../components/SelectGrade';
import SchoolFilter from '../../../components/SchoolFilter';
import SectionsMultiSelectFilter from '../../../components/SectionsMultiSelectFilter';

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiButtonBase-root.MuiMenuItem-root': {
    border: 0,
  },
}));

export const StyledBox = styled(Box)(( ) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledStatesFilter = styled(StateFilter)(({ theme }) => ({
  width: 300,
  marginTop: theme.spacing(3),
}));

export const StyledDistrictFilter = styled(DistrictFilter)(({ theme }) => ({
  width: 300,
  marginTop: theme.spacing(3),
}));

export const StyledSectionBox = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.customBackground.dragBackground,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3, 0),
  width: '300px',
}));

// StyledSelectGrade dropdown used in the student form
export const StyledSelectGrade = styled(SelectGrade)(({
  width: 220,
}));

export const StyledSchoolFilter = styled(SchoolFilter)(({ theme }) => ({
  width: 300,
  marginTop: theme.spacing(3),
}));

// StyledSectionsSelect autocomplete dropdown used in the student form
export const StyledSectionsSelectMultiple = styled(SectionsMultiSelectFilter)(({ theme }) =>({
  width: 600,
  marginTop: theme.spacing(3),
}));
