import React, { createContext, useContext, useState, useEffect } from 'react';
import { BreadcrumbItem } from '../components/AppBreadcrumbs';

interface Props {
  children: React.ReactNode
}

export type BreadcrumbContextType = {
  breadcrumbs: BreadcrumbItem[],
  addToBreadcrumb: (crumbItem: BreadcrumbItem[]) => void
};

const BreadcrumbContext = createContext<BreadcrumbContextType | null>(null);

export const useBreadcrumbContext = () => useContext(BreadcrumbContext);

export const BreadcrumbProvider = ({ children }: Props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const addToBreadcrumb = (crumbItem: BreadcrumbItem[]) => {
    if (crumbItem.length > 0) {
      // check the length of the crumbItem, if the length is more than 1 then just make it the breadcrumb
      if (crumbItem.length > 1) {
        setBreadcrumbs([...crumbItem]);
      } else {
        // if the length is 1 then check if the breadcrumb already exists
        const index = breadcrumbs.findIndex((item: BreadcrumbItem) => item.label === crumbItem[0].label);
        if (index === -1) {
          setBreadcrumbs(breadcrumbs.concat(crumbItem));
        } else {
          setBreadcrumbs(breadcrumbs.slice(0, index).concat(crumbItem));
        }
      }
    }
  };

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs, addToBreadcrumb }}>
      { children }
    </BreadcrumbContext.Provider>
  );
};

interface BreadcrumbProps {
  breadcrumbItems: BreadcrumbItem[]
}

export const useBreadcrumb = ({ breadcrumbItems }:BreadcrumbProps) => {
  const { breadcrumbs, addToBreadcrumb } : any = useContext(BreadcrumbContext);

  useEffect(() => {
    addToBreadcrumb(breadcrumbItems);
  }, [breadcrumbItems]);
  return {
    breadcrumbs,
  };
};