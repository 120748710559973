import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { palette } from '../../theme/palette';

export const StyledInfo = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  fontSize: 12,
  color: theme.palette.customBackground.fontBlack,
  bottom: 10,
})) ;

export const StyledBrowse = styled(Typography)({
  fontSize: 16,
  width: 155,
  lineHeight: '18px',
  fontWeight: 'normal',
});

export const StyledSpan = styled('span')({
  cursor: 'pointer',
  color: palette.customBlue.primaryBlue,
});
