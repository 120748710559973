/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React, { useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { svgStyles } from '../Common/Common.styled';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { TeacherFilterFragmentFragment } from '../../generated/graphql';
import useTeachersFilter from './teachers-filter-hook';
import TeachersIcon from '../Icons/TeachersIcon';
import { palette } from '../../theme/palette';
import { List, ListItem } from '@mui/material';

interface Props {
  error?: boolean;
  className?: string;
  value?: TeacherFilterFragmentFragment | null | string;
  onChange?: (data: TeacherFilterFragmentFragment | null | string) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  stateId?: string | undefined;
  districtId?: string | undefined;
  schoolId?: string | undefined;
  disabled?: boolean;
  teacherIcon?: boolean;
  onSelectedTeacherChange?: (data: TeacherFilterFragmentFragment | null | string) => void;
  updateLoader?: Function;
}

type TeacherValue = TeacherFilterFragmentFragment | null | undefined;

const TeacherFilter = ({
  className,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  stateId,
  districtId,
  schoolId,
  disabled,
  teacherIcon = true,
  onSelectedTeacherChange,
  updateLoader,
}: Props) => {
  const {
    teachers,
    loading,
  } = useTeachersFilter({ stateId, districtId, schoolId });
  useEffect(() => {
    updateLoader?.(loading);
    if (!loading && teachers.length > 0) {
      onSelectChange('', teachers.find(teacher => idSelectOnly ? (teacher.id === value) : teacher === value)!);
    }
  }, [loading]);
  const onSelectChange = (_: any, teacher: TeacherFilterFragmentFragment | null) => {
    onChange?.(idSelectOnly ? (teacher?.id ?? '') : teacher);
    onSelectedTeacherChange?.(teacher);
  };

  const selectedValue: TeacherValue = idSelectOnly ? teachers?.find((item) => item.id === value) ?? null : value as TeacherValue;
  return (
    <Autocomplete
      className={className}
      options={teachers || []}
      getOptionLabel={(option: any) => `${option?.first_name ?? ''} ${option?.last_name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      onChange={onSelectChange}
      value={selectedValue}
      disabled={disabled || loading}
      renderOption={(props, option: any) => (
        <List
          key={option?.id}
          sx={{ p: '0px' }}>
          <ListItem {...props}>
            {option?.first_name ?? ''} {option?.last_name ?? ''}
          </ListItem>
        </List>
      )}
      renderInput={(params) => (
        <>
          <AutoCompleteTextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style:{ fontWeight: 800, fontSize: '16px' },
              startAdornment: (
                <InputAdornment position="start">
                  {teacherIcon && <TeachersIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />}
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default React.memo(TeacherFilter);
