import { usePtStudentsSlideoutQuery } from '../../../../generated/graphql';

const usePTStudentSlideout = ({ assignId, statusList }: any) => {

  const { data, loading } = usePtStudentsSlideoutQuery({
    fetchPolicy: 'network-only',
    variables: {
      assignId: assignId,
      status: statusList,
    },
  });

  return {
    totalCount: data?.teacherPTSlideOut?.total_count ?? 0,
    slideoutStudents: data?.teacherPTSlideOut?.nodes ?? [],
    loading,
  };
};

export default usePTStudentSlideout;
