import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Teacher from './Teacher';
import TeachersTable from './TeachersTable';
import Layout from '../../components/Layout';
import RoutingPaths from '../RoutingPath';

const Teachers = () => (
  <Layout>
    <Switch>
      <Route
        path={RoutingPaths.Teachers}
        component={TeachersTable}
        exact
      />
      <Route
        path={[`${RoutingPaths.Teachers}/:id`, `${RoutingPaths.Teachers}/:id/:sectionType`]}
        component={Teacher}
        exact
      />
    </Switch>
  </Layout>
);

export default React.memo(Teachers);
