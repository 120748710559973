import { Button, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { tableWithNoDataStyles } from '../../../components/Common/Common.styled';
import { CrossIcon } from '../../../components/Icons';
import { StudentsSectionFragFragment } from '../../../generated/graphql';
import { palette } from '../../../theme/palette';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.customWhite.main,
  },
}));

interface Props {
  loading?: boolean;
  students?: StudentsSectionFragFragment[];
  onRemoveStudent: (id: string) => void
}

const List = ({ loading = false, students, onRemoveStudent }: Props) => {
  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="districts table" sx={{ ...(loading && tableWithNoDataStyles.noData) }}>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ textAlign: 'left' }}>Student Name</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {students &&
            students?.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography sx={{ fontSize: '16px' }}>{`${row?.user?.first_name} ${row?.user?.last_name}`}</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Button variant="text" onClick={()=> onRemoveStudent(row.id)} ><CrossIcon pathFill={palette.customBlue.primaryBlue} /></Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
