import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const TreeMapSelectedIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    fontSize='small'>
    <circle cx="4" cy="4" r="4" fill={palette.customBlue.primaryBlue}/>
  </SvgIcon>
);

export default TreeMapSelectedIcon;
