import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Grid, Typography, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loader from '../../../../components/Loader/loader';
import TeacherSchoolFilter from '../../../../components/SchoolFilterTeacher';
import TeacherFilter from '../../../../components/TeacherFilter';
import TeacherSelectMultiple from '../../../../components/TeacherSelectMultiple';
import { CreateClassStylesCss } from '../CreateClass.styled';

import useCreateClass from '../create-class-hook';

const Details = () => {
  const theme = useTheme();
  const styles = CreateClassStylesCss(theme);

  const limitMultiSelectTagsTo = 2;
  const schoolHelperText = 'Please select a school.';
  const teacherHelperText = 'Please select a teacher';
  // const secondaryHelperText = 'Please select secondary teacher different from primary';
  const classNameHelperText = 'Please Assign a Class Name.';
  const periodHelperText = 'Please enter a appropriate value for period.';

  const {
    loading,
    routeData,
    teacherDetails,
    teacherSchools,
    createClassInput,
    selectedSchool, onSchoolChange,
    onPrimaryteacherChange,
    updateSecondaryTeachers,
  } = useCreateClass();

  const {
    control,
    errors,
    clearErrors,
  } = useFormContext();

  const [isShoolFilterLoading, setIsSchoolFilterLoading] = useState<boolean>(true);
  const [isTeacherFilter1Loading, setIsTeacherFilter1Loading] = useState<boolean>(true);
  const [isTeacherFilter2Loading, setIsTeacherFilter2Loading] = useState<boolean>(true);

  return (
    <>
      <Loader open={loading || isShoolFilterLoading || isTeacherFilter1Loading || isTeacherFilter2Loading} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} xl={12} container>
          <Grid item xs={12} sm={6} md={4} xl={4}>
            <Typography variant='sFormTitle' component='p'>School</Typography>
            <Controller
              name="school_id"
              control={control}
              defaultValue={createClassInput?.school_id!}
              render={({ onChange, value }) => (
                <TeacherSchoolFilter
                  idSelectOnly
                  value={value}
                  onChange={onChange}
                  onSchoolChange={onSchoolChange}
                  placeholder="All Schools"
                  showStartAdorement
                  updateLoader={setIsSchoolFilterLoading}
                  disabled={(teacherSchools ? teacherSchools.length === 1 : false) || routeData?.action === 'edit'}
                />
              )}
              rules={{
                required: schoolHelperText,
              }}
            />
            <Typography variant='sFormHelper'>{errors.school_id && errors.school_id.message}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} container>
          <Grid item xs={12} sm={6} md={4} xl={4}>
            <Typography variant='sFormTitle' component='p'>Primary Teacher</Typography>
            <Controller
              name="teacher_id"
              control={control}
              defaultValue={createClassInput?.teacher_id!}
              render={({ onChange, value }) => (
                <TeacherFilter
                  value={value}
                  onChange={onChange}
                  onSelectedTeacherChange={onPrimaryteacherChange}
                  districtId={selectedSchool?.district_id! ?? createClassInput?.district_id!}
                  schoolId={selectedSchool?.id! ?? createClassInput?.school_id!}
                  idSelectOnly
                  placeholder="Select A Teacher"
                  disabled={!!teacherDetails || routeData?.action === 'edit'}
                  updateLoader={setIsTeacherFilter1Loading}
                />
              )}
              rules={{
                required: teacherHelperText,
              }}
            />
            <Typography variant='sFormHelper'>{errors.teacher_id && errors.teacher_id.message}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} container>
          <Grid item xs={12} sm={6} md={4} xl={4}>
            <Typography variant='sFormTitle' component='p'>Secondary Teacher (Optional)</Typography>
            <Controller
              name="secondary_teacher_ids"
              control={control}
              defaultValue={createClassInput?.secondary_teacher_ids!}
              render={({ onChange, value }) => (
                <TeacherSelectMultiple
                  filterLabel=""
                  limitTags={limitMultiSelectTagsTo}
                  textInputPlaceholder="Select A Teacher"
                  value={value}
                  onChange={onChange}
                  onSelectedTeachersChange={updateSecondaryTeachers}
                  districtId={selectedSchool?.district_id! ?? createClassInput?.district_id!}
                  schoolId={selectedSchool?.id! ?? createClassInput?.school_id!}
                  updateLoader={setIsTeacherFilter2Loading}
                  skipteachers={[createClassInput?.teacher_id!]}
                  fixedTeacherOptions={routeData?.action === 'edit' ? createClassInput?.secondary_teacher_ids : []}
                />
              )}
              rules={{
                required: false,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} container>
          <Grid item xs={12} sm={6} md={12} xl={12}>
            <Typography variant='sFormTitle' component='p'>Class Name</Typography>
            <Controller
              name="name"
              defaultValue={createClassInput?.name}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  id="class-name"
                  aria-describedby="class-name"
                  name="name"
                  fullWidth
                  defaultValue={value}
                  value={value}
                  sx={{ ...styles.inputInput }}
                  onChange={(e) => {
                    clearErrors('name');
                    onChange(e);
                  }}
                />
              )}
              rules={{
                required: classNameHelperText,
              }}
            />
            <Typography variant='sFormHelper'>{errors.name && errors.name.message}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} container>
          <Grid item xs={12} sm={6} md={12} xl={12}>
            <Typography variant='sFormTitle' component='p'>Period (Optional)</Typography>
            <Controller
              name="period"
              defaultValue={(createClassInput?.period && createClassInput?.period !== 0) ? createClassInput?.period : ''}
              render={({ onChange, value }) => (
                <TextField
                  id="period"
                  aria-describedby="period"
                  name="period"
                  fullWidth
                  defaultValue={!value ? '' : value}
                  value={!value ? '' : value}
                  sx={{ ...styles.inputInput }}
                  onChange={(e) => {
                    clearErrors('name');
                    onChange(e);
                  }}
                  type="number"
                />
              )}
              rules={{
                required: false,
                validate: (value) => (value ? value > 0 : true) || periodHelperText,
              }}
              control={control}
            />
            <Typography variant='sFormHelper'>{errors.period && errors.period.message}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

};

export default Details;