import React from 'react';
import { Box, Typography } from '@mui/material';
import usePermission from '../../../hooks/usePermission';
import { UserPermissions } from '../../../generated/graphql';
import FileUploadEdit from '../../Settings/ManageImages/FileUploadEdit';
import ImageLibrary from '../../Settings/UploadImages/ImageLibrary';
import { ImageSelectorProp } from '../../Settings/ManageImages/ManageImages';
import Layout from '../../../components/Layout';

const ManageImages = ({ value }: ImageSelectorProp) => {
  const { hasPermission } = usePermission(UserPermissions.UploadImage);
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        {hasPermission && (
          <>
            <Box>
              <Typography variant="pageTitle">
                Manage Images
              </Typography>
            </Box>
            <Box mt={3} sx={{
              minWidth: 350,
            }}>
              <FileUploadEdit />
            </Box>
            <Box mt={3} >
              <ImageLibrary selected={value} />
            </Box>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default ManageImages;
