import { OrderBy, useAssignmentsFilterQuery, useStudentAssignmentsFilterQuery } from '../../generated/graphql';
import { AssignmentSort } from '../../variables/types';

interface Props {
  sectionId?: string
  studentId?: string
  schoolId?: string
}

const useAssignmentsFilter = ({ sectionId, studentId, schoolId }: Props) => {
  const { data, loading } = useAssignmentsFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(sectionId ? { sectionId: sectionId as string } : {}),
      ...(schoolId ? { schoolId: schoolId as string } : {}),
      orderBy: OrderBy.Asc,
      sort: AssignmentSort.Title,
    },
    skip: !!studentId,
  });

  const { data: studentAssignments, loading: loadingAssignments } = useStudentAssignmentsFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      studentId: studentId ?? '',
      ...(sectionId ? { sectionId: sectionId as string } : {}),
      orderBy: OrderBy.Asc,
      sort: AssignmentSort.Title,
    },
    skip: !studentId,
  });

  const assignments = studentId
    ? studentAssignments?.getAllAssignmentsForStudentByID?.nodes ?? []
    : data?.getAllAssignmentsForTeacher?.nodes ?? [];

  return {
    assignments,
    loading: loading || loadingAssignments,
  };
};

export default useAssignmentsFilter;
