/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { LoginDistrictDetails } from '../../generated/graphql';
import { TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

interface Props {
  error?: boolean;
  sx?: SxProps<Theme> | undefined;
  className?: string;
  inputValue: LoginDistrictDetails;
  onChange: (data: LoginDistrictDetails | null) => void;
  placeholderValue?: string;
  data: LoginDistrictDetails[] | [];
  isFontVariable?: boolean
}

const AutocompleteBox = ({
  sx,
  placeholderValue,
  onChange,
  inputValue,
  data,
  isFontVariable,
}: Props) => {
  const [searchString, setSearchString] = useState('');
  const selectedDistrict = inputValue;
  const districtJSON = data;
  const onSelectChange = (_: any, districtData: LoginDistrictDetails | null) => {
    onChange?.(districtData);
  };
  return (
    <Autocomplete
      autoSelect
      options={searchString && searchString?.length >= 3 ? districtJSON : []}
      getOptionLabel={(option: any) => option?.name || ''}
      open={!!searchString}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      // getOptionSelected={(option: any, value: any) => option?.name === value?.name}
      onChange={onSelectChange}
      openOnFocus={false}
      value={selectedDistrict}
      onInputChange={(event: any) => setSearchString(event?.target?.value)}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ margin: 0, padding: 0, border: 'none' }}
          placeholder={placeholderValue ?  placeholderValue : 'undefined'}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style:{ ...(isFontVariable) && { fontSize: '16px' } },
          }}
        />
      )}
    />
  );
};

export default React.memo(AutocompleteBox);
