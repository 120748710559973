import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import useSyncJobList from './synclog-list-hook';
import SyncDistrict from '../SyncDistrict';
import InitialiseDistrict from '../InitialiseDistrict';
import List from './List';
import { AutoCompleteTextField } from '../../../components/Autocomplete/Autocomplete.styled';
import SyncDetails from './SyncDetails';
import { palette } from '../../../theme/palette';
import { CleverSyncDetails } from '../../../generated/graphql';

export const statusOptions = [
  { label: 'Success', value: 'Success' },
  { label: 'Failed', value: 'Failed' },
  { label: 'In Progress', value: 'InProgress' },
  { label: 'Success With Warning(s)', value: 'SuccessWithWarning' },
];

const styles = {
  selectInput: {
    width: '200px',
  },

  backToDistrict: {
    textDecorationLine: 'none',
  },

  backIcon: {
    color: palette.customBlue.primaryBlue,
  },
};

const DistrictSyncTable = () => {
  const {
    syncJobList,
    totalCount,
    isTableEmpty,
    loading,
    sort,
    order,
    pagination,
    handlePageChange,
    handleSort,
    handleViewButton,
    setViewSyncDistrictDetails,
    handleStatusChange,
    syncDetails,
    loadingDetails,
    setSyncDetailId,
    syncEnabled,
    viewSyncDistrictDetails,
    rowsPerPage,
    setRowsPerPage,
  } = useSyncJobList();
  return (
    <>
      <Typography variant='secondaryTitle'>Sync Districts</Typography>
      <Box display="flex" flexDirection="row" alignItems="flex-start">
        <KeyboardBackspaceOutlinedIcon sx={{ ...styles.backIcon }}/>
        <Typography
          component={Link}
          color="primary"
          variant="subtitle1"
          to="/districts"
          sx={{ ...styles.backToDistrict }}
        >
          {' '}
          Back to Districts
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={3}
      >
        <SyncDistrict />
        <InitialiseDistrict />
      </Box>
      <Box mt={2} display="flex" flexDirection="row" justifyContent="flex-end">
        <Autocomplete
          sx={{ ...styles.selectInput }}
          getOptionLabel={(option) => option.label}
          options={statusOptions}
          isOptionEqualToValue={(option: any, selected: any) => option?.value === selected?.value}
          onChange={handleStatusChange}
          renderInput={(params) => (
            <AutoCompleteTextField
              {...params}
              placeholder="All Statuses"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
      </Box>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          pagination={pagination}
          loading={loading}
          totalCount={totalCount || 0}
          sort={sort}
          order={order}
          syncJobList={syncJobList}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          handleViewButton={handleViewButton}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
      <SyncDetails
        openDialog={!!viewSyncDistrictDetails}
        syncDetails={syncDetails as CleverSyncDetails}
        loading={loadingDetails}
        handleClose={() => { setViewSyncDistrictDetails(false); setSyncDetailId(''); }}
      />
    </>
  );
};

export default DistrictSyncTable;
