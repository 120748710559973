export const errorMessages = {
    validEmailText: 'Please enter the correct email address.',
    requiredEmail: '*Email is required',
    passwordRequired: '*Password is required',
    passwordConfirm: '*Confirm Password is required',
  };
  
export const labels = {
  createPasswordLabel: 'Create new password',
  resetLinkLabel: 'Send password reset link',
  toolTipLabel: 'We only allow 5 password reset attempts per day',  
};