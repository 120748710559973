import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup as MUIRadioGroup, SxProps, Theme } from '@mui/material';
import { palette } from '../../theme/palette';

export interface RadioGroupProps {
  id?: string,
  label?: string,
  value?: string | number | null,
  disabled?: boolean,
}

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  radioContainerStyles?: SxProps<Theme> | undefined;
  row?: boolean,
  radioGroupAriaLabel?: string,
  radioGroupName?: string,
  radios?: RadioGroupProps[],
  value: string | number | null,
  disableSelection?: boolean,
}

const styles = {
  formLabel: {
    width: 'fit-content',
    '&.MuiTypography-root': {
      color: palette.fontColors.fontBlack,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '25px',
      letterSpacing: '0.1px',
    },
  },
};

/*
 * NOTE: RadioGroup is not using Formcontrol as it con be re-used inside
 * another Form component.
 */

const RadioGroup = ({
  handleChange,
  radios,
  radioContainerStyles,
  row,
  radioGroupAriaLabel,
  radioGroupName,
  value,
  disableSelection,
}: Props) => {
  return (
    <Box sx={{ ...radioContainerStyles  }}>
      <MUIRadioGroup
        row={row}
        onChange={handleChange}
        aria-label={radioGroupAriaLabel}
        name={radioGroupName}
        value={value}
      >
        {
          radios?.map((radio) => (
            <FormControlLabel
              key={radio?.id || radio.label}
              sx={{ ...styles.formLabel }}
              value={radio.value}
              control={<Radio color="primary" />}
              label={radio.label}
              disabled={radio.disabled || disableSelection}
            />
          ))
        }
      </MUIRadioGroup>
    </Box>
  );
};

export default React.memo(RadioGroup);
