/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ToolBoxIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <path d="M18.5547 15.2244L16.0798 17.6992L23.0754 24.6948L25.5503 22.2199L18.5547 15.2244Z" fill={props.pathFill} />
    <path d="M20.4167 11.6656C22.6683 11.6656 24.5 9.83396 24.5 7.58229C24.5 6.90562 24.3133 6.27562 24.0217 5.71563L20.8717 8.86562L19.1333 7.12729L22.2833 3.97729C21.7233 3.68563 21.0933 3.49896 20.4167 3.49896C18.165 3.49896 16.3333 5.33063 16.3333 7.58229C16.3333 8.06063 16.4267 8.51562 16.5783 8.93562L14.42 11.094L12.3433 9.01729L13.1717 8.18896L11.5267 6.54396L14 4.07062C12.635 2.70562 10.4183 2.70562 9.05335 4.07062L4.92335 8.20062L6.56835 9.84562H3.27835L2.45001 10.674L6.58001 14.804L7.40835 13.9756V10.674L9.05335 12.319L9.88168 11.4906L11.9583 13.5673L3.31335 22.2123L5.78668 24.6856L19.0633 11.4206C19.4833 11.5723 19.9383 11.6656 20.4167 11.6656Z" fill={props.pathFill} />
  </SvgIcon>
);

export default ToolBoxIcon;
