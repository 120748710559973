import React from 'react';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ScoreScheme } from '../../../variables/types';
import StyledCard from '../../../components/StyledCard';
import {
  AssignmentSubmissionsForTeacherSortType,
  OrderBy,
  StudentAssignment,
  StudentBenchmark,
  SubmissionsForStudentSortType,
  TeacherAssignmentSubmissionNode,
} from '../../../generated/graphql';
import { StyledTableCell, StyledTableDataContainer } from '../../Admin/admin-tables-styled';
import SubmissionsRow from './SubmissionRow';
import { StyledTableSort } from '../../../components/Common/Common.styled';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';

interface Props {
  stickyHeader?: boolean;
  assignments?: TeacherAssignmentSubmissionNode[];
  studentAssignments?: StudentAssignment[];
  benchmarks?: StudentBenchmark[];
  loading: boolean;
  title: string;
  isStudent?: boolean;
  scoreScheme : ScoreScheme[];
  order?: string;
  sort?: string;
  handleSort?: ((column: AssignmentSubmissionsForTeacherSortType | SubmissionsForStudentSortType) => void) | Function;
}


const scoreStyles = (scoreScheme: ScoreScheme | any) => {
  const styleSet = scoreScheme.length ? scoreScheme.reduce((classObj: any, score: ScoreScheme) => {
    Object.assign(classObj, {
      [`score${Math.floor(score.score_value)}`]: {
        background: score.color_code,
        borderColor: score.color_code,
      },
    });
    return classObj;
  }, {}) : {};
  return styleSet;
};

const Submissions = ({
  stickyHeader = true,
  assignments,
  studentAssignments,
  benchmarks,
  loading,
  title,
  isStudent,
  scoreScheme,
  order = 'desc',
  sort,
  handleSort,
}: Props) => {
  const styles = scoreStyles(scoreScheme);
  const getScoreClass = (score: number) => {
    const scoreRound = Math.floor(score);
    return styles[`score${scoreRound}`] ?? null;
  };
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const sortByColumn = isStudent ? SubmissionsForStudentSortType.SubmittedAt : AssignmentSubmissionsForTeacherSortType.SubmissionSubmittedAt;
  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box mt={3}>
        <StyledCard title={title} titleAlignment="left" contentWrapperMinHeightAuto>
          <StyledTableDataContainer>
            <Table
              /*
          *  Due to 'stickyHeader' prop, backgroundColor on TableHead or TableRow
          *  was not working. So added global styles under theme.ts file for
          *  MuiTableCell & MuiTableHead
          */
              stickyHeader={stickyHeader}
              aria-label={title}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ textAlign: 'left' }}>
                    Assignment
                  </StyledTableCell>
                  <StyledTableCell>
                    Status
                  </StyledTableCell>
                  <StyledTableCell>
                    Speaking Score
                  </StyledTableCell>
                  <StyledTableCell>
                    Writing Score
                  </StyledTableCell>
                  <StyledTableCell>
                    Assigned
                  </StyledTableCell>

                  <StyledTableCell>
                    <StyledTableSort
                      direction={orderByIcon}
                      onClick={handleSort?.(sortByColumn)}
                      active={sort === sortByColumn}
                      IconComponent={ArrowDownIcon}
                    >
                      Submitted
                    </StyledTableSort>
                  </StyledTableCell>
                  {
                    isStudent && (
                      <StyledTableCell/>
                    )
                  }

                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && benchmarks?.map((row: StudentBenchmark) => (
                  <SubmissionsRow
                    key={row.id}
                    title={row.title}
                    startAt={row.start_at}
                    submissionId={row.submission_id}
                    submissionStatus={row.submission_status}
                    submissionSpeakingAverageScore={row.submission_speaking_average_score}
                    submissionWritingAverageScore={row.submission_writing_average_score}
                    isStudent={isStudent}
                    submittedAt={row.submitted_at?.toString()}
                    getScoreClass={getScoreClass}
                  />
                ))}
                {!loading && assignments?.map((row: TeacherAssignmentSubmissionNode) => (
                  <SubmissionsRow
                    key={row.assignment_id}
                    title={row.assignment_title}
                    startAt={row.assignment_assigned_at}
                    submissionId={row.submission_id}
                    submissionStatus={row.submission_status}
                    submissionSpeakingAverageScore={row.submission_speaking_average_score}
                    submissionWritingAverageScore={row.submission_writing_average_score}
                    isStudent={isStudent}
                    submittedAt={row.submission_submitted_at}
                    getScoreClass={getScoreClass}
                  />
                ))}
                {!loading && studentAssignments?.map((row: StudentAssignment) => (
                  <SubmissionsRow
                    key={row.id}
                    title={row.title}
                    startAt={row.start_at}
                    submissionId={row.submission_id}
                    submissionStatus={row.submission_status}
                    submissionSpeakingAverageScore={row.submission_speaking_average_score}
                    submissionWritingAverageScore={row.submission_writing_average_score}
                    isStudent={isStudent}
                    submittedAt={row.submitted_at?.toString()}
                    getScoreClass={getScoreClass}
                  />
                ))}
                {!loading && ((!assignments || assignments?.length === 0)
                  && (!studentAssignments || studentAssignments.length === 0)
                  && (!benchmarks || benchmarks.length === 0)) && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                    >
                      <Typography variant='sTitle'>
                        No Performance Task Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow sx={{ fontWeight: 800, fontSize: '20px' }}>
                    <TableCell
                      colSpan={6}
                      align="center"
                    >
                      <CircularProgress size={28} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableDataContainer>
        </StyledCard>
      </Box>
    </Box>
  );
};
export default Submissions;
