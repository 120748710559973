import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ScoreDetail } from '../../../generated/graphql';
import { StyledContainer11 } from '../ScoreTask.styled';

interface Props {
  scoringDetails: ScoreDetail[];
}

const GradingRubric = ({ scoringDetails }: Props) => {
  const theme = useTheme();
  return (
    <StyledContainer11>
      <Typography variant='sContentHeader' mb={theme.spacing(1.5)}>
        Scoring Rubric
      </Typography>
      { scoringDetails.map((scoringDetail) => (
        <Box sx={{ display: 'flex', flexDirection: 'row' }} key={scoringDetail.id}>
          <Typography variant='rubricOrder'>
            {scoringDetail.score_value}
            .
          </Typography>
          <Typography variant="rubricOrderContent">
            {scoringDetail.description}
          </Typography>
        </Box>
      ))}
    </StyledContainer11>
  );
};

export default React.memo(GradingRubric);
