import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayerControls from './PlayerControls';
import BackdropModal from '../BackdropModal/BackdropModal';
import secondsToMinute from '../../utils/secondsToMinutes';
import CrossIcon from '../Icons/CrossIcon';
import { palette  } from '../../theme/palette';

interface Props {
  open: boolean,
  onClose: () => void,
  url: string,
  title?: string,
  showCloseButton?: boolean,
}

const actionButtons = {
  styleButton:{
    '&.MuiButtonBase-root': {
      background: palette.gradients.controlGradient,
      color: palette.customWhite.main,
      borderRadius: '50%',
      '&:hover': {
        background: palette.gradients.controlGradient,
        color: palette.customWhite.main,
      },
      visibility: 'hidden',
    },
  },
  videoModalTitleBar: {
    zIndex: 1,
    position: 'absolute',
    top: '0px',
    display: 'flex',
    width: '100%',
    padding: '30px',
    color: 'white',
  },
};

const VideoModal = ({ open, onClose, url, title, showCloseButton = false }: Props) => {
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);

  const [state, setState] = useState({
    playing: false,
    muted: false,
    volume: 1,
    played: 0,
    seeking: false,
    fullScreen: false,
    playbackRate: 1.0,
  });

  const { playing, muted, volume, played, fullScreen, playbackRate } = state;

  const preventDefault = (
    event: React.MouseEvent<Element, MouseEvent> |
    React.KeyboardEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleClickFullscreen = () => {
    setState((prevState) => ({
      ...prevState,
      fullScreen: !fullScreen,
    }));
  };

  useEffect(() => {
    if (playerContainerRef?.current) {
      if (screenfull?.isEnabled && screenfull.isFullscreen !== fullScreen) {
        // @ts-ignore
        screenfull?.toggle(playerContainerRef.current);
      }
    }
  }, [fullScreen]);

  const handleMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const handleVolumeChange = (e: any, newValue: number) => {
    setState({
      ...state,
      volume: parseFloat(String(newValue / 100)),
      muted: newValue === 0,
    });
  };

  const handleVolumeSeekDown = (e: any, newValue: number) => {
    setState({
      ...state,
      seeking: false,
      volume: parseFloat(String(newValue / 100)),
    });
  };

  const handleProgress = (changeState: any) => {
    if (!state.seeking) {
      setState({ ...state, ...changeState });
    }
  };

  const handleSeekChange = (e: any, newValue: number) => {
    setState({ ...state, played: parseFloat(String(newValue / 100)) });
  };

  const handleMouseDownSeek = () => {
    setState({ ...state, seeking: true });
  };

  const handleMouseUpSeek = (e: any, newValue: number) => {
    setState({ ...state, seeking: false });
    // @ts-ignore
    playerRef.current.seekTo(newValue / 100);
  };

  const handlePlaybackRateChange = (rate: number) => {
    setState({ ...state, playbackRate: rate });
  };

  const stop = () => {
    setState({ ...state, playing: false, played: 0 });
  };

  const handleClose = () => {
    stop();
    onClose();
  };

  // @ts-ignore
  const currentTime = playerRef && playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
  // @ts-ignore
  const duration = playerRef && playerRef.current ? playerRef.current.getDuration() : '00:00';

  const elapsedTime = isNaN(currentTime) ? currentTime : secondsToMinute(currentTime);
  const totalDuration = isNaN(duration) ? duration : secondsToMinute(duration);

  return (
    <BackdropModal
      open={open}
      onClose={handleClose}
    >
      <Box
        onClick={preventDefault}
        onKeyDown={preventDefault}
        ref={playerContainerRef}
        sx={{
          position: 'relative',
          background: palette.customRed.scoreDisabled,
          paddingTop: '56.25%',
          height: '80%',
          ...(fullScreen && { height: '100%' }),
          ...((window?.matchMedia('(max-height: 668px)').matches && fullScreen) && { width: '65%' }),
          ...((!window?.matchMedia('(max-height: 668px)').matches && !fullScreen) && { width: '80%' }),
        }}
      >
        <Box sx={{
          '&:hover .play-pause-icon': {
            visibility: 'visible',
          },
        }}>
          <Box
            sx={{ ...actionButtons.videoModalTitleBar }}
            justifyContent={title ? 'space-between' : 'flex-end'}
          >
            { title && (
              <Typography variant="h5" color="inherit">{title}</Typography>
            )}
            { showCloseButton && (
              <IconButton
                color="inherit"
                className={'play-pause-icon'}
                onClick={handleClose}
                sx={{ ...actionButtons.styleButton }}
              >
                <CrossIcon />
              </IconButton>
            )}
          </Box>
          <Box
            alignItems="center"
            flexDirection="row"
            position="absolute"
            top="45%"
            left="50%"
            zIndex={1}
          >
            <IconButton
              className={'play-pause-icon'}
              sx={{ ...actionButtons.styleButton }}
              onClick={handlePlayPause}
            >
              {
                playing
                  ? <PauseIcon fontSize="large" />
                  : <PlayArrowIcon fontSize="large" />
              }
            </IconButton>
          </Box>
          <ReactPlayer
            ref={playerRef}
            url={url}
            controls={false}
            width="100%"
            height={
              fullScreen
                ? '90vh'
                : window.matchMedia('(max-height: 668px)').matches ? '65%' : '85%'
            }
            playing={playing}
            onPause={stop}
            onEnded={stop}
            muted={muted}
            volume={volume}
            onProgress={handleProgress}
            playbackRate={playbackRate}
            style={{
              position: 'absolute',
              ...((window?.matchMedia('(max-height: 668px)').matches && !fullScreen) && { top: '15%' }),
              ...((!window?.matchMedia('(max-height: 668px)').matches && !fullScreen) && { top: '7%' }),
              ...((!window?.matchMedia('(max-height: 668px)').matches && fullScreen) && { top: '0px' }),
              left: '0px',
            }}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload', disablePictureInPicture: true,
                },
              },
            }}
          />
        </Box>
        <PlayerControls
          videoPlaying={playing}
          handlePlayPause={handlePlayPause}
          handleClickFullscreen={handleClickFullscreen}
          muted={muted}
          handleMuteToggle={handleMute}
          volume={volume}
          played={played}
          onVolumeChange={handleVolumeChange}
          onVolumeSeekUp={handleVolumeSeekDown}
          onSeek={handleSeekChange}
          onMouseDownSeek={handleMouseDownSeek}
          onMouseUpSeek={handleMouseUpSeek}
          fullscreen={fullScreen}
          elapsedTime={elapsedTime}
          totalDuration={totalDuration}
          playbackRate={playbackRate}
          onPlaybackRateChange={handlePlaybackRateChange}
        />
      </Box>
    </BackdropModal>
  );
};

export default VideoModal;
