import help from '../Images/help.jpg';

const data = {
  v: '5.7.6',
  fr: 60,
  ip: 0,
  op: 420,
  w: 1920,
  h: 1080,
  nm: '1920x1080 - Writing',
  ddd: 0,
  assets: [{ id: 'image_0', w: 2572, h: 1446, u: '', p: help, e: 0 }, {
    id: 'comp_0',
    layers: [{
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Cursor',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [{
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 41,
            s: [618, 479, 0],
            to: [0, 0, 0],
            ti: [0, 0, 0],
          }, {
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 49,
            s: [621, 480, 0],
            to: [0, 0, 0],
            ti: [171.833, 152.5, 0],
          }, { t: 70, s: [172, 338, 0] }],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 98,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 108,
            s: [150, 150, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 116,
            s: [204, 204, 100],
          }, { t: 124, s: [200, 200, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.311, -0.141], [0, 0], [0, 0], [0.144, 0.334], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.208, 0.093], [0, 0.274], [0, 0], [-0.306, -0.341], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.526, 0]],
              o: [[0, 0], [0, 0], [0, 0], [0, 0], [0.139, 0.336], [0, 0], [0, 0], [-0.297, 0.13], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.202, 0.208], [-0.218, -0.098], [0, 0], [0, -0.619], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.392, 0.382], [0, 0]],
              v: [[2.257, 4.068], [1.512, 4.068], [1.795, 4.757], [5.284, 13.257], [5.285, 13.258], [4.954, 14.11], [1.884, 15.448], [1.884, 15.448], [1.087, 15.106], [-2.228, 7.038], [-2.518, 6.333], [-3.049, 6.88], [-8.465, 12.451], [-8.465, 12.451], [-9.119, 12.585], [-9.499, 11.996], [-9.499, -14.859], [-8.479, -15.329], [-8.472, -15.321], [-8.465, -15.314], [9.31, 2.97], [9.31, 2.97], [9.32, 2.979], [8.888, 4.068]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 1, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 420,
      st: -18,
      bm: 0,
    }, {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Text Cursor',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ t: 108, s: [100], h: 1 }, { t: 128, s: [0], h: 1 }, { t: 149, s: [0], h: 1 }, {
            t: 300,
            s: [100],
            h: 1,
          }, { t: 330, s: [0], h: 1 }, { t: 360, s: [100], h: 1 }, { t: 390, s: [0], h: 1 }, { t: 420, s: [100], h: 1 }],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [{
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 149,
            s: [102.023, 276.763, 0],
            to: [117.875, 0, 0],
            ti: [-117.875, 0, 0],
          }, { t: 300, s: [809.273, 276.763, 0] }],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [-370.852, 6.607, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ty: 'rc',
          d: 1,
          s: { a: 0, k: [2, 28], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          r: { a: 0, k: 0, ix: 4 },
          nm: 'Rectangle Path 1',
          mn: 'ADBE Vector Shape - Rect',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.119706000534, 0.119706000534, 0.119706000534, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [-370.852, 6.607], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Rectangle 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 108,
      op: 420,
      st: 108,
      bm: 0,
    }, {
      ddd: 0,
      ind: 3,
      ty: 3,
      nm: 'Null 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474, 352, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: { a: 0, k: [105, 105, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 420,
      st: -18,
      bm: 0,
    }, {
      ddd: 0,
      ind: 4,
      ty: 5,
      nm: 'Text 2',
      parent: 3,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 107, s: [100] }, { t: 108, s: [50] }],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [49.06, 49.631, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [125, 125, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [{
            s: {
              sz: [566.203308105469, 133.145523071289],
              ps: [-283.101654052734, -66.5727615356445],
              s: 24,
              f: 'Nunito-Bold',
              t: 'Type here',
              ca: 0,
              j: 0,
              tr: 0,
              lh: 28.8,
              ls: 0,
              fc: [0.2, 0.2, 0.2],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 148,
      st: -18,
      bm: 0,
    }, {
      ddd: 0,
      ind: 5,
      ty: 5,
      nm: 'Text',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [49.06, 49.631, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [125, 125, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [{
            s: {
              sz: [566.203308105469, 133.145523071289],
              ps: [-283.101654052734, -66.5727615356445],
              s: 24,
              f: 'Nunito-Bold',
              t: 'There are a lot of different animals in the world.',
              ca: 0,
              j: 0,
              tr: 0,
              lh: 28.8,
              ls: 0,
              fc: [0.022, 0.022, 0.022],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [{
          nm: 'Animator 1',
          s: {
            t: 0,
            xe: { a: 0, k: 0, ix: 7 },
            ne: { a: 0, k: 0, ix: 8 },
            a: { a: 0, k: 100, ix: 4 },
            b: 1,
            rn: 0,
            sh: 1,
            s: {
              a: 1,
              k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 147, s: [0] }, { t: 300, s: [100] }],
              ix: 1,
            },
            r: 1,
          },
          a: { o: { a: 0, k: 0, ix: 9 } },
        }],
      },
      ip: 127,
      op: 420,
      st: 11,
      bm: 0,
    }, {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Text Frame',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [125, 125, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-6.075, 0], [0, 0], [0, -6.075], [0, 0], [6.075, 0], [0, 0], [0, 6.075], [0, 0]],
              o: [[0, 0], [6.075, 0], [0, 0], [0, 6.075], [0, 0], [-6.075, 0], [0, 0], [0, -6.075]],
              v: [[-288, -84], [288, -84], [299, -73], [299, 73], [288, 84], [-288, 84], [-299, 73], [-299, -73]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [0.898039221764, 0.898039221764, 0.898039221764, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.96862745285, 0.96862745285, 0.96862745285, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 420,
      st: -18,
      bm: 0,
    }, {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [386.504, 161.445, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ty: 'rc',
          d: 1,
          s: { a: 0, k: [960, 200], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          r: { a: 0, k: 0, ix: 4 },
          nm: 'Rectangle Path 1',
          mn: 'ADBE Vector Shape - Rect',
          hd: false,
        }, {
          ty: 'op',
          nm: 'Offset Paths 1',
          a: {
            a: 0,
            k: 10,
            ix: 1,
            x: "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Stroke 1').strokeWidth, 2);",
          },
          lj: 1,
          ml: { a: 0, k: 4, ix: 3 },
          ix: 2,
          mn: 'ADBE Vector Filter - Offset',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 10, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [93.496, 278.555], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Rectangle 1',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 420,
      st: -18,
      bm: 0,
    }, {
      ddd: 0,
      ind: 15,
      ty: 0,
      nm: 'Image',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [523, 129, 0], ix: 2, l: 2 },
        a: { a: 0, k: [715, 481.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [125, 125, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1430,
      h: 963,
      ip: -18,
      op: 582,
      st: -18,
      bm: 0,
    }],
  }, {
    id: 'comp_1',
    layers: [{
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Map.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [574.81, 481, 0], ix: 2, l: 2 },
        a: { a: 0, k: [1286, 723, 0], ix: 1, l: 2 },
        s: { a: 0, k: [66.5, 66.5, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }],
  }],
  fonts: { list: [{ fName: 'Nunito-Bold', fFamily: 'Nunito', fStyle: 'Bold', ascent: 72.0997436903417 }] },
  layers: [{
    ddd: 0,
    ind: 1,
    ty: 0,
    nm: '3 - Writing',
    refId: 'comp_0',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
      a: { a: 0, k: [480, 270, 0], ix: 1, l: 2 },
      s: { a: 0, k: [200, 200, 100], ix: 6, l: 2 },
    },
    ao: 0,
    w: 960,
    h: 540,
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }],
  markers: [],
  chars: [{
    ch: 'T',
    size: 24,
    style: 'Bold',
    w: 63.4,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 4.4], [0, 0], [0, 0], [0, 3.6], [3.666, 0], [0, 0], [0, -3.533], [-3.667, 0], [0, 0], [0, 0], [-4.267, 0]],
              o: [[0, 0], [0, 0], [3.666, 0], [0, -3.533], [0, 0], [-3.667, 0], [0, 3.6], [0, 0], [0, 0], [0, 4.4], [4.266, 0]],
              v: [[37.5, -5.8], [37.5, -59.8], [57, -59.8], [62.5, -65.2], [57, -70.5], [5.2, -70.5], [-0.3, -65.2], [5.2, -59.8], [24.7, -59.8], [24.7, -5.8], [31.1, 0.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'T',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'T',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'h',
    size: 24,
    style: 'Bold',
    w: 58.5,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 4.267], [0, 0], [-2.167, 2.3], [-3.667, 0], [-1.434, -1.733], [0, -3.8], [0, 0], [-4.2, 0], [0, 4.267], [0, 0], [11.533, 0], [2.8, -1.366], [1.666, -2.666], [0, 0], [4.2, 0], [0, -4.2], [0, 0], [-4.134, 0]],
              o: [[0, 0], [0, -3.8], [2.166, -2.3], [3, 0], [1.433, 1.734], [0, 0], [0, 4.267], [4.133, 0], [0, 0], [0, -13.4], [-3.4, 0], [-2.8, 1.367], [0, 0], [0, -4.2], [-4.134, 0], [0, 0], [0, 4.267], [4.2, 0]],
              v: [[19, -5.6], [19, -27.5], [22.25, -36.65], [31, -40.1], [37.65, -37.5], [39.8, -29.2], [39.8, -5.6], [46.1, 0.8], [52.3, -5.6], [52.3, -29.8], [35, -49.9], [25.7, -47.85], [19, -41.8], [19, -65], [12.7, -71.3], [6.5, -65], [6.5, -5.6], [12.7, 0.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'h',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'h',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'e',
    size: 24,
    style: 'Bold',
    w: 54.2,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-3.034, 0.7], [-2.334, 1.467], [-0.367, 1.3], [0.5, 1.067], [1.2, 0.367], [1.733, -1], [2.166, -0.433], [1.733, 0], [0.933, 8.734], [0, 0], [0, 2.467], [4.033, 4.4], [6.933, 0], [3.7, -2.166], [2.1, -3.8], [0, -5.066], [-2.167, -3.8], [-4, -2.066], [-5.534, 0]],
              o: [[3.033, -0.7], [1.533, -0.933], [0.366, -1.3], [-0.5, -1.066], [-1.2, -0.366], [-1.934, 1.067], [-2.167, 0.434], [-9.067, 0], [0, 0], [3.2, 0], [0, -7.6], [-4.034, -4.4], [-4.734, 0], [-3.7, 2.167], [-2.1, 3.8], [0, 5.2], [2.166, 3.8], [4, 2.067], [2.6, 0]],
              v: [[39.05, -0.05], [47.1, -3.3], [49.95, -6.65], [49.75, -10.2], [47.2, -12.35], [42.8, -11.4], [36.65, -9.15], [30.8, -8.5], [15.8, -21.6], [46, -21.6], [50.8, -25.3], [44.75, -43.3], [28.3, -49.9], [15.65, -46.65], [6.95, -37.7], [3.8, -24.4], [7.05, -10.9], [16.3, -2.1], [30.6, 1]],
              c: true,
            },
            ix: 2,
          },
          nm: 'e',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[-1.967, -2.166], [-0.267, -4.066], [0, 0], [-2.267, 2.267], [-3.667, 0]],
              o: [[1.966, 2.167], [0, 0], [0.466, -3.866], [2.266, -2.266], [3.533, 0]],
              v: [[37.05, -38.15], [40.4, -28.8], [15.8, -28.8], [19.9, -38], [28.8, -41.4]],
              c: true,
            },
            ix: 2,
          },
          nm: 'e',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'e',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'r',
    size: 24,
    style: 'Bold',
    w: 39.2,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 4.267], [0, 0], [-8.2, 0.8], [0, 0], [0.333, 3.534], [2.866, -0.333], [2.4, -5.733], [0, 0], [4.066, 0], [0, -4.2], [0, 0], [-4.267, 0]],
              o: [[0, 0], [0, -7.6], [0, 0], [3.8, -0.4], [-0.334, -3.6], [-7.867, 0.667], [0, 0], [0, -4.2], [-4.067, 0], [0, 0], [0, 4.267], [4.2, 0]],
              v: [[19.2, -5.6], [19.2, -26.3], [31.5, -38.9], [33.7, -39.1], [38.9, -45], [34.1, -49.9], [18.7, -40.3], [18.7, -43.4], [12.6, -49.7], [6.5, -43.4], [6.5, -5.6], [12.9, 0.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'r',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'r',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, { ch: ' ', size: 24, style: 'Bold', w: 27.1, data: {}, fFamily: 'Nunito' }, {
    ch: 'a',
    size: 24,
    style: 'Bold',
    w: 54.7,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-2.467, 1.566], [-1.134, 2.734], [0, 0], [-3.934, 0], [0, 4.267], [0, 0], [3.466, 3.3], [7.133, 0], [2.833, -0.533], [3.066, -1.266], [0.5, -1.266], [-0.367, -1.233], [-1.067, -0.566], [-1.6, 0.734], [-2.234, 0.467], [-1.934, 0], [-1.467, -1.466], [0, -3.333], [0, 0], [0, 0], [4.2, -0.933], [1.866, -2.1], [0, -3.6], [-1.567, -2.333], [-2.767, -1.366], [-3.534, 0]],
              o: [[2.466, -1.566], [0, 0], [0, 4.267], [3.866, 0], [0, 0], [0, -6.933], [-3.467, -3.3], [-2.334, 0], [-2.834, 0.534], [-1.534, 0.6], [-0.5, 1.267], [0.366, 1.234], [1.066, 0.567], [2.466, -1.133], [2.233, -0.466], [3.466, 0], [1.466, 1.467], [0, 0], [0, 0], [-7.067, 0], [-4.2, 0.934], [-1.867, 2.1], [0, 2.934], [1.566, 2.334], [2.766, 1.366], [3.4, 0]],
              v: [[31.4, -1.35], [36.8, -7.8], [36.8, -5.6], [42.7, 0.8], [48.5, -5.6], [48.5, -29.6], [43.3, -44.95], [27.4, -49.9], [19.65, -49.1], [10.8, -46.4], [7.75, -43.6], [7.55, -39.85], [9.7, -37.15], [13.7, -37.4], [20.75, -39.8], [27, -40.5], [34.4, -38.3], [36.6, -31.1], [36.6, -29], [33.1, -29], [16.2, -27.6], [7.1, -23.05], [4.3, -14.5], [6.65, -6.6], [13.15, -1.05], [22.6, 1]],
              c: true,
            },
            ix: 2,
          },
          nm: 'a',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[1.533, 1.4], [0, 2.2], [-2.434, 0.967], [-6.267, 0], [0, 0], [0, 0], [2.166, -2.266], [3.266, 0]],
              o: [[-1.534, -1.4], [0, -2.533], [2.433, -0.966], [0, 0], [0, 0], [0, 3.467], [-2.167, 2.267], [-2.734, 0]],
              v: [[18.8, -9.7], [16.5, -15.1], [20.15, -20.35], [33.2, -21.8], [36.6, -21.8], [36.6, -19.6], [33.35, -11], [25.2, -7.6]],
              c: true,
            },
            ix: 2,
          },
          nm: 'a',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'a',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'l',
    size: 24,
    style: 'Bold',
    w: 31.9,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-1.4, 0.2], [-0.667, 0.934], [0, 1.667], [0.333, 0.634], [0.933, -0.133], [0.466, -0.033], [0.533, 0], [0, 6], [0, 0], [4.2, 0], [0, -4.2], [0, 0], [-11.134, 0]],
              o: [[1.533, -0.2], [0.666, -0.933], [0, -1.933], [-0.334, -0.633], [-0.467, 0.067], [-0.467, 0.034], [-5, 0], [0, 0], [0, -4.2], [-4.134, 0], [0, 0], [0, 12.467], [1.466, 0]],
              v: [[27.5, 0.7], [30.8, -1], [31.8, -4.9], [31.3, -8.75], [29.4, -9.5], [28, -9.35], [26.5, -9.3], [19, -18.3], [19, -65], [12.7, -71.3], [6.5, -65], [6.5, -17.7], [23.2, 1]],
              c: true,
            },
            ix: 2,
          },
          nm: 'l',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'l',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'o',
    size: 24,
    style: 'Bold',
    w: 57.6,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-3.734, 2.067], [-2.067, 3.834], [0, 5.2], [2.066, 3.8], [3.733, 2.067], [5.066, 0], [3.733, -2.066], [2.066, -3.8], [0, -5.2], [-2.067, -3.833], [-3.734, -2.066], [-5.067, 0]],
              o: [[3.733, -2.066], [2.066, -3.833], [0, -5.2], [-2.067, -3.8], [-3.734, -2.066], [-5.067, 0], [-3.734, 2.067], [-2.067, 3.8], [0, 5.2], [2.066, 3.834], [3.733, 2.067], [5.066, 0]],
              v: [[42, -2.1], [50.7, -10.95], [53.8, -24.5], [50.7, -38], [42, -46.8], [28.8, -49.9], [15.6, -46.8], [6.9, -38], [3.8, -24.5], [6.9, -10.95], [15.6, -2.1], [28.8, 1]],
              c: true,
            },
            ix: 2,
          },
          nm: 'o',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[2.266, 2.7], [0, 5.267], [-2.267, 2.634], [-3.734, 0], [-2.267, -2.633], [0, -5.333], [2.266, -2.7], [3.733, 0]],
              o: [[-2.267, -2.7], [0, -5.333], [2.266, -2.633], [3.733, 0], [2.266, 2.634], [0, 5.267], [-2.267, 2.7], [-3.734, 0]],
              v: [[19.8, -12.55], [16.4, -24.5], [19.8, -36.45], [28.8, -40.4], [37.8, -36.45], [41.2, -24.5], [37.8, -12.55], [28.8, -8.5]],
              c: true,
            },
            ix: 2,
          },
          nm: 'o',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'o',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 't',
    size: 24,
    style: 'Bold',
    w: 35.6,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-1.4, 0.233], [-0.867, 0.266], [-0.434, 1.034], [0, 1.334], [0.533, 0.567], [0.8, -0.066], [0.866, -0.2], [1.133, 0], [1.4, 1.6], [0, 3.2], [0, 0], [0, 0], [0, 3.134], [3.333, 0], [0, 0], [0, 0], [4.133, 0], [0, -4.2], [0, 0], [0, 0], [0, -3.133], [-3.334, 0], [0, 0], [0, 0], [-12.934, 0]],
              o: [[1.4, -0.234], [1.133, -0.4], [0.433, -1.033], [0, -1.733], [-0.534, -0.566], [-0.667, 0.067], [-0.867, 0.2], [-3.134, 0], [-1.4, -1.6], [0, 0], [0, 0], [3.333, 0], [0, -3.133], [0, 0], [0, 0], [0, -4.2], [-4.2, 0], [0, 0], [0, 0], [-3.334, 0], [0, 3.134], [0, 0], [0, 0], [0, 12.8], [1.066, 0]],
              v: [[32.6, 0.65], [36, -0.1], [38.35, -2.25], [39, -5.8], [38.2, -9.25], [36.2, -10], [33.9, -9.6], [30.9, -9.3], [24.1, -11.7], [22, -18.9], [22, -39.5], [32.6, -39.5], [37.6, -44.2], [32.6, -48.9], [22, -48.9], [22, -58], [15.8, -64.3], [9.5, -58], [9.5, -48.9], [4.3, -48.9], [-0.7, -44.2], [4.3, -39.5], [9.5, -39.5], [9.5, -18.2], [28.9, 1]],
              c: true,
            },
            ix: 2,
          },
          nm: 't',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 't',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'f',
    size: 24,
    style: 'Bold',
    w: 34.1,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 4.267], [0, 0], [0, 0], [0, 3.134], [3.333, 0], [0, 0], [0, 0], [-1.734, 1.667], [-3.8, 0.267], [0, 0], [-0.667, 0.967], [0.033, 1.234], [0.8, 0.867], [1.733, -0.133], [0, 0], [3.466, -3.433], [0, -6.866], [0, 0], [0, 0], [0, -3.133], [-3.334, 0], [0, 0], [0, 0], [-4.2, 0]],
              o: [[0, 0], [0, 0], [3.333, 0], [0, -3.133], [0, 0], [0, 0], [0, -3.4], [1.733, -1.666], [0, 0], [1.266, -0.133], [0.666, -0.966], [-0.034, -1.233], [-0.8, -0.866], [0, 0], [-6.534, 0.6], [-3.467, 3.434], [0, 0], [0, 0], [-3.334, 0], [0, 3.134], [0, 0], [0, 0], [0, 4.267], [4.133, 0]],
              v: [[22.3, -5.6], [22.3, -39.5], [30.8, -39.5], [35.8, -44.2], [30.8, -48.9], [22.3, -48.9], [22.3, -51], [24.9, -58.6], [33.2, -61.5], [34.6, -61.6], [37.5, -63.25], [38.45, -66.55], [37.2, -69.7], [33.4, -70.8], [30, -70.5], [15, -64.45], [9.8, -49], [9.8, -48.9], [5.2, -48.9], [0.2, -44.2], [5.2, -39.5], [9.8, -39.5], [9.8, -5.6], [16.1, 0.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'f',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'f',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'd',
    size: 24,
    style: 'Bold',
    w: 60,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-2.8, 1.566], [-1.4, 2.667], [0, 0], [-4.134, 0], [0, 4.267], [0, 0], [4.2, 0], [0, -4.2], [0, 0], [2.8, 1.534], [3.466, 0], [3.233, -2.066], [1.8, -3.766], [0, -5.266], [-1.8, -3.833], [-3.234, -2.066], [-4.267, 0]],
              o: [[2.8, -1.566], [0, 0], [0, 4.267], [4.133, 0], [0, 0], [0, -4.2], [-4.134, 0], [0, 0], [-1.4, -2.533], [-2.8, -1.533], [-4.267, 0], [-3.234, 2.067], [-1.8, 3.767], [0, 5.2], [1.8, 3.834], [3.233, 2.067], [3.533, 0]],
              v: [[34.8, -1.35], [41.1, -7.7], [41.1, -5.6], [47.3, 0.8], [53.5, -5.6], [53.5, -65], [47.2, -71.3], [41, -65], [41, -41.5], [34.7, -47.6], [25.3, -49.9], [14.05, -46.8], [6.5, -38.05], [3.8, -24.5], [6.5, -10.95], [14.05, -2.1], [25.3, 1]],
              c: true,
            },
            ix: 2,
          },
          nm: 'd',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[2.266, 2.7], [0, 5.267], [-2.267, 2.634], [-3.734, 0], [-2.267, -2.633], [0, -5.333], [2.266, -2.7], [3.733, 0]],
              o: [[-2.267, -2.7], [0, -5.333], [2.266, -2.633], [3.733, 0], [2.266, 2.634], [0, 5.267], [-2.267, 2.7], [-3.734, 0]],
              v: [[19.8, -12.55], [16.4, -24.5], [19.8, -36.45], [28.8, -40.4], [37.8, -36.45], [41.2, -24.5], [37.8, -12.55], [28.8, -8.5]],
              c: true,
            },
            ix: 2,
          },
          nm: 'd',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'd',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'i',
    size: 24,
    style: 'Bold',
    w: 25.5,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 4.4], [4.933, 0], [1.3, -1.133], [0, -2.133], [-1.3, -1.166], [-2.334, 0]],
              o: [[0, -4.4], [-2.334, 0], [-1.3, 1.134], [0, 2.067], [1.3, 1.167], [4.933, 0]],
              v: [[20.1, -65.5], [12.7, -72.1], [7.25, -70.4], [5.3, -65.5], [7.25, -60.65], [12.7, -58.9]],
              c: true,
            },
            ix: 2,
          },
          nm: 'i',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[-1.067, 1.2], [0, 2.134], [0, 0], [4.2, 0], [0, -4.533], [0, 0], [-1.067, -1.2], [-2, 0]],
              o: [[1.066, -1.2], [0, 0], [0, -4.533], [-4.134, 0], [0, 0], [0, 2.134], [1.066, 1.2], [2.066, 0]],
              v: [[17.4, -1.1], [19, -6.1], [19, -42.8], [12.7, -49.6], [6.5, -42.8], [6.5, -6.1], [8.1, -1.1], [12.7, 0.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'i',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'i',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'n',
    size: 24,
    style: 'Bold',
    w: 58.5,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 4.267], [0, 0], [-2.167, 2.3], [-3.667, 0], [-1.434, -1.733], [0, -3.8], [0, 0], [-4.2, 0], [0, 4.267], [0, 0], [11.533, 0], [2.833, -1.466], [1.666, -2.8], [0, 0], [4.066, 0], [0, -4.2], [0, 0], [-4.134, 0]],
              o: [[0, 0], [0, -3.8], [2.166, -2.3], [3, 0], [1.433, 1.734], [0, 0], [0, 4.267], [4.133, 0], [0, 0], [0, -13.4], [-3.534, 0], [-2.834, 1.467], [0, 0], [0, -4.2], [-4.067, 0], [0, 0], [0, 4.267], [4.2, 0]],
              v: [[19, -5.6], [19, -27.5], [22.25, -36.65], [31, -40.1], [37.65, -37.5], [39.8, -29.2], [39.8, -5.6], [46.1, 0.8], [52.3, -5.6], [52.3, -29.8], [35, -49.9], [25.45, -47.7], [18.7, -41.3], [18.7, -43.4], [12.6, -49.7], [6.5, -43.4], [6.5, -5.6], [12.7, 0.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'n',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'n',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'm',
    size: 24,
    style: 'Bold',
    w: 87.7,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 4.267], [0, 0], [-1.867, 2.3], [-3.267, 0], [-1.234, -1.733], [0, -3.733], [0, 0], [-4.2, 0], [0, 4.267], [0, 0], [-1.867, 2.3], [-3.267, 0], [-1.234, -1.733], [0, -3.733], [0, 0], [-4.134, 0], [0, 4.267], [0, 0], [11, 0], [2.866, -1.7], [1.533, -3], [7.333, 0], [2.6, -1.5], [1.466, -2.666], [0, 0], [4.066, 0], [0, -4.2], [0, 0], [-4.134, 0]],
              o: [[0, 0], [0, -4.066], [1.866, -2.3], [2.866, 0], [1.233, 1.734], [0, 0], [0, 4.267], [4.133, 0], [0, 0], [0, -4.066], [1.866, -2.3], [2.933, 0], [1.233, 1.734], [0, 0], [0, 4.267], [4.2, 0], [0, 0], [0, -13.4], [-3.667, 0], [-2.867, 1.7], [-2.267, -6.4], [-3.4, 0], [-2.6, 1.5], [0, 0], [0, -4.2], [-4.067, 0], [0, 0], [0, 4.267], [4.2, 0]],
              v: [[19.2, -5.6], [19.2, -27.1], [22, -36.65], [29.7, -40.1], [35.85, -37.5], [37.7, -29.3], [37.7, -5.6], [44, 0.8], [50.2, -5.6], [50.2, -27.1], [53, -36.65], [60.7, -40.1], [66.95, -37.5], [68.8, -29.3], [68.8, -5.6], [75, 0.8], [81.3, -5.6], [81.3, -29.8], [64.8, -49.9], [55, -47.35], [48.4, -40.3], [34, -49.9], [25, -47.65], [18.9, -41.4], [18.9, -43.4], [12.8, -49.7], [6.7, -43.4], [6.7, -5.6], [12.9, 0.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'm',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'm',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 's',
    size: 24,
    style: 'Bold',
    w: 48.8,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-3.8, 2.767], [0, 4.867], [2.233, 2.134], [4.333, 0.8], [0, 0], [0, 2.934], [-1.567, 1.134], [-3.267, 0], [-1.9, -0.466], [-2.067, -1.066], [-1.067, 0.434], [-0.5, 1.034], [0.233, 1.167], [1.266, 0.667], [5.866, 0], [3.133, -1.266], [1.766, -2.333], [0, -3.2], [-8.467, -1.6], [0, 0], [0, -2.8], [1.566, -1.1], [3.2, 0], [2.333, 0.5], [2.666, 1.267], [1.033, -0.466], [0.433, -1.033], [-0.334, -1.166], [-1.4, -0.8], [-3.2, -0.6], [-2.867, 0]],
              o: [[3.8, -2.766], [0, -3.6], [-2.234, -2.133], [0, 0], [-3.8, -0.666], [0, -1.866], [1.566, -1.133], [1.733, 0], [1.9, 0.467], [1.4, 0.667], [1.066, -0.433], [0.5, -1.033], [-0.234, -1.166], [-4.6, -2.6], [-4.067, 0], [-3.134, 1.267], [-1.767, 2.334], [0, 7.2], [0, 0], [4.133, 0.8], [0, 1.8], [-1.567, 1.1], [-2.267, 0], [-2.334, -0.5], [-1.4, -0.666], [-1.034, 0.467], [-0.434, 1.034], [0.333, 1.167], [2.533, 1.467], [3.2, 0.6], [6.333, 0]],
              v: [[39.6, -3.15], [45.3, -14.6], [41.95, -23.2], [32.1, -27.6], [22.1, -29.5], [16.4, -34.9], [18.75, -39.4], [26, -41.1], [31.45, -40.4], [37.4, -38.1], [41.1, -37.75], [43.45, -39.95], [43.85, -43.25], [41.6, -46], [25.9, -49.9], [15.1, -48], [7.75, -42.6], [5.1, -34.3], [17.8, -21.1], [27.8, -19.2], [34, -13.8], [31.65, -9.45], [24.5, -7.8], [17.6, -8.55], [10.1, -11.2], [6.45, -11.5], [4.25, -9.25], [4.1, -5.95], [6.7, -3], [15.3, 0.1], [24.4, 1]],
              c: true,
            },
            ix: 2,
          },
          nm: 's',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 's',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'w',
    size: 24,
    style: 'Bold',
    w: 89.3,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-1.267, 3.534], [0, 0], [0, 0], [-3.534, 0], [-1.4, 3.467], [0, 0], [0.733, 1.7], [2.4, 0], [1.033, -0.666], [0.666, -1.8], [0, 0], [0, 0], [2.8, 0], [1.066, -2.8], [0, 0], [0, 0], [0.933, 0.7], [1.466, 0], [0.966, -1.6], [-0.867, -2.333], [0, 0], [-3.467, 0]],
              o: [[0, 0], [0, 0], [1.266, 3.467], [3.4, 0], [0, 0], [0.866, -2.133], [-0.734, -1.7], [-1.267, 0], [-1.034, 0.667], [0, 0], [0, 0], [-1, -2.8], [-2.734, 0], [0, 0], [0, 0], [-0.667, -1.933], [-0.934, -0.7], [-2.467, 0], [-0.967, 1.6], [0, 0], [1.333, 3.467], [3.533, 0]],
              v: [[32.6, -4.5], [42.6, -31.5], [52.8, -4.4], [60, 0.8], [67.2, -4.4], [81.6, -41.4], [81.8, -47.15], [77.1, -49.7], [73.65, -48.7], [71.1, -45], [60, -14.3], [48.7, -45.5], [43, -49.7], [37.3, -45.5], [26, -14.6], [15, -44.7], [12.6, -48.65], [9, -49.7], [3.85, -47.3], [3.7, -41.4], [18.2, -4.4], [25.4, 0.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'w',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'w',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: '.',
    size: 24,
    style: 'Bold',
    w: 24.8,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-1.3, 1.4], [0, 2.2], [1.3, 1.367], [2.333, 0], [1.366, -1.366], [0, -2.133], [-1.367, -1.4], [-2.267, 0]],
              o: [[1.3, -1.4], [0, -2.133], [-1.3, -1.366], [-2.267, 0], [-1.367, 1.367], [0, 2.2], [1.366, 1.4], [2.333, 0]],
              v: [[17.95, -1.6], [19.9, -7], [17.95, -12.25], [12.5, -14.3], [7.05, -12.25], [5, -7], [7.05, -1.6], [12.5, 0.5]],
              c: true,
            },
            ix: 2,
          },
          nm: '.',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: '.',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'y',
    size: 24,
    style: 'Bold',
    w: 56.6,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-1, 0.666], [-0.867, 1.866], [0, 0], [0.866, 1.667], [2.133, 0], [1, -0.7], [0.733, -1.866], [0, 0], [0, 0], [0.933, 0.7], [1.533, 0], [0.933, -1.666], [-0.934, -2.2], [0, 0], [0, 0], [-0.867, -1.667], [-2.267, 0]],
              o: [[1, -0.667], [0, 0], [0.933, -2.133], [-0.867, -1.666], [-1.667, 0], [-1, 0.7], [0, 0], [0, 0], [-0.734, -1.933], [-0.934, -0.7], [-2.734, 0], [-0.934, 1.667], [0, 0], [0, 0], [-0.934, 2.133], [0.866, 1.666], [1.6, 0]],
              v: [[22.8, 17.8], [25.6, 14], [49.9, -41.5], [50, -47.2], [45.5, -49.7], [41.5, -48.65], [38.9, -44.8], [26.6, -14.7], [14.6, -44.7], [12.1, -48.65], [8.4, -49.7], [2.9, -47.2], [2.9, -41.4], [20, -2], [14.3, 10.6], [14.2, 16.3], [18.9, 18.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'y',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'y',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'p',
    size: 24,
    style: 'Bold',
    w: 60,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 4.2], [0, 0], [-2.767, -1.5], [-3.467, 0], [-3.2, 2.067], [-1.8, 3.834], [0, 5.2], [1.8, 3.767], [3.233, 2.067], [4.266, 0], [2.8, -1.566], [1.4, -2.6], [0, 0], [4.133, 0], [0, -4.2], [0, 0], [-4.134, 0]],
              o: [[0, 0], [1.466, 2.534], [2.766, 1.5], [4.333, 0], [3.2, -2.066], [1.8, -3.833], [0, -5.266], [-1.8, -3.766], [-3.234, -2.066], [-3.6, 0], [-2.8, 1.567], [0, 0], [0, -4.2], [-4.067, 0], [0, 0], [0, 4.2], [4.2, 0]],
              v: [[19, 12.5], [19, -7.3], [25.35, -1.25], [34.7, 1], [46, -2.1], [53.5, -10.95], [56.2, -24.5], [53.5, -38.05], [45.95, -46.8], [34.7, -49.9], [25.1, -47.55], [18.8, -41.3], [18.8, -43.4], [12.6, -49.7], [6.5, -43.4], [6.5, 12.5], [12.7, 18.8]],
              c: true,
            },
            ix: 2,
          },
          nm: 'p',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[2.266, 2.7], [0, 5.267], [-2.267, 2.634], [-3.734, 0], [-2.267, -2.633], [0, -5.333], [2.266, -2.7], [3.733, 0]],
              o: [[-2.267, -2.7], [0, -5.333], [2.266, -2.633], [3.733, 0], [2.266, 2.634], [0, 5.267], [-2.267, 2.7], [-3.734, 0]],
              v: [[22.2, -12.55], [18.8, -24.5], [22.2, -36.45], [31.2, -40.4], [40.2, -36.45], [43.6, -24.5], [40.2, -12.55], [31.2, -8.5]],
              c: true,
            },
            ix: 2,
          },
          nm: 'p',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'p',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }],
};

export default data;
