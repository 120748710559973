import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const AssignmentSubmissionTypes = {
  Completed: true,
  NotStarted: false,
};

interface Props {
  tileStatus: boolean;
  name: string;
  abbreviation: string | React.ReactNode;
  iconPresent?: boolean;
}

const AssignmentStatus = ({ tileStatus, name, abbreviation, iconPresent }: Props) => {
  const theme = useTheme();
  return (
    <Box display="flex">
      <Tooltip
        title={name}
        sx={{ maxWidth: 'none', fontWeight: 'bold' }}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={5000}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            minWidth: '40px',
            minHeight: '40px',
            marginRight: theme.spacing(1.5),
            ...(AssignmentSubmissionTypes.Completed === tileStatus && {
              backgroundColor: theme.palette.customGreen.submitted,
              color: theme.palette.customWhite.main,
              '&.MuiSvgIcon-root': {
                fill: theme.palette.customWhite.main,
              },
            }),
            ...(AssignmentSubmissionTypes.NotStarted === tileStatus && {
              backgroundColor: theme.palette.customRed.notSubmitted,
              color: theme.palette.customRed.darkFont,
              '&.MuiSvgIcon-root': {
                fill: theme.palette.customRed.darkFont,
              },
            }),
          }}
        >
          <Typography sx={{
            fontSize: 16,
            fontWeight: 800,
            ...(iconPresent === true && {
              marginTop: '6px',
            }),
          }}
          >
            {abbreviation}
          </Typography>

        </Box>
      </Tooltip>
    </Box>
  );
};

export default AssignmentStatus;
