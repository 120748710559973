import React, { useState } from 'react';
import {
  DistrictItem,
  useGetDistrictsToSyncQuery,
  useSyncImportedDistrictsMutation,
} from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import getErrorMessage from '../../../utils/getErrorMessage';
import useSyncJobList from '../DistrictSyncTable/synclog-list-hook';
import { StringParam, useQueryParam } from 'use-query-params';


const useSyncDistricts = () => {
  const [districtId, setDistrict] = useQueryParam('districtId', StringParam);
  const { loading: listLoading, fetchSyncList } = useSyncJobList();
  const [selectedDistricts, setSelectedDistricts] = useState<DistrictItem[]>([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const { data, loading, refetch: DistrictsToSyncReFetch } = useGetDistrictsToSyncQuery({
    fetchPolicy: 'network-only',
  });

  const [syncImportedDistricts, { loading: syncLoading }] = useSyncImportedDistrictsMutation();

  const runSyncForSelectedDistricts = async () => {
    const districtsList = getSelectedDistricts();
    const districtIds = districtsList.map((district:DistrictItem) => district?.id);
    try {
      await syncImportedDistricts({
        fetchPolicy: 'network-only',
        variables: {
          districts: districtIds,
        },
      });
      setSelectedDistricts([]);
      setCheckAll(false);
      await fetchSyncList();
      await DistrictsToSyncReFetch();
      openSnackbar({ message: 'Sync started. Please refresh page till status shows Success/Failed' }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const selectDistricts = (districtList: DistrictItem[]) => {
    if (districtList) {
      setDistrict(undefined);
    }
    setSelectedDistricts(districtList);
  };

  const getSelectedDistricts = () => {
    const districts = districtId ? data?.syncDistrictsList?.filter((district) => district.id === districtId) as DistrictItem[] : [];
    const districtsList = districts ? [...districts, ...selectedDistricts] : [...selectedDistricts];
    return districtsList;
  };

  return {
    runSyncForSelectedDistricts,
    syncDistrictList: data?.syncDistrictsList,
    loading: syncLoading || loading || listLoading,
    selectedDistricts: getSelectedDistricts(),
    selectDistricts,
    checkAll, setCheckAll,
  };
};

export default useSyncDistricts;
