import { styled, Box } from '@mui/material';
import SelectStudentGrades from "./GradeSelect";
import SectionsMultiSelect from './SectionsMultiSelect';

export const StyledFlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
}));

// StyledSectionsSelect autocomplete dropdown used in the student form
export const StyledSectionsSelectMultiple = styled(SectionsMultiSelect)(({ theme }) =>({
    width: 500,
}));

export const StyledSelectStudentGrades = styled(SelectStudentGrades)({
  marginTop: '8px',
  width: 300,
});

export const formStyles = {
  titleInput: {
    '& .MuiInputBase-input': {
      background: 'none',
    },
  },
  title: {
    '& .MuiSelect-icon': {
      height: '1em',
      width: '1em',
      top: 'auto',
    },
  },
  textFieldAdmin:{
    width: 298,
    '& .MuiInputBase-input': {
      background: 'none',
    },
  },
  lowercaseTextField: {
    '& .MuiInputBase-input': {
      textTransform: 'lowercase',
    },
  },
};

export const studentFormStyles = {
  flexBoxes:{
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
  },
  customMargin30:{
    marginTop: '30px',
  },
  saveButton:{
    width: '200px',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 800,
    marginTop: '32px',
    marginBottom: '32px',
  },
  removeButton: {
    width: '300px',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 800,
    marginRight: "20px",
    marginBottom: '32px',
  }
};
