import React from 'react';
import { Box, Slider, Typography } from '@mui/material';
import secondsToMinute from '../../utils/secondsToMinutes';

interface Props {
  currentTime?: number;
  onChangeCommitted: (event: React.SyntheticEvent | Event, value: number | Array<number>) => void;
  max?: number;
  customStyle?: boolean;
  speakingStyle?: boolean;
  isDisabled?: boolean;
}

export const sliderStyles = {
  sliderControls: {
    '&.MuiSlider-root': {
      height: '2px',
    },
    '& .MuiSlider-thumb': {
      height: '12px',
      width: '12px',
    },
  },
};

const AudioSliderWithSmallText = ({
  currentTime,
  onChangeCommitted,
  max,
  customStyle,
  speakingStyle = false,
  isDisabled = false,
}: Props) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={speakingStyle ? '30% 70% 30%' : customStyle ? '0.5fr 7fr 0.5fr' : '10% 70% 10%'}
      sx={{
        gridGap: 8,
        alignItems: 'end',
      }}
      width={speakingStyle ? '25%' : '100%'}
    >
      <Typography variant="sliderTimer" sx={{ textAlign: 'right' }}>
        {secondsToMinute(currentTime ?? 0)}
      </Typography>
      <Slider
        onChangeCommitted={onChangeCommitted}
        value={currentTime || 0}
        min={0}
        max={max}
        sx={{ ...sliderStyles.sliderControls }}
        disabled={isDisabled}
        aria-label="Playback"
      />
      <Typography variant="sliderTimer">{max ? secondsToMinute(max) : '--:--'}</Typography>
    </Box>
  );
};

export default AudioSliderWithSmallText;
