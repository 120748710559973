import { createContext } from 'react';
import { StudentSubmissionFragmentFragment, SubmissionTypeGoalFragment } from '../../generated/graphql';
import { AnnotationInterface } from '../../variables/types';

export interface SubmitTaskContextValue {
  submission?: StudentSubmissionFragmentFragment,
  loading: boolean,
  status?: string,
  id?: string,
  showHelp?: boolean,
  setShowHelp?: Function,
  updateStatus?: Function,
  showSuccess?: boolean,
  setShowSuccess?: Function,
  handleCreateAnnotations?: Function,
  annotations?: AnnotationInterface[],
  handleAnnotationUpdate?: (index: number, toBeUpdated: Partial<AnnotationInterface>) => void,
  deleteAnnotation?: (index:number) => void,
  addAnnotation?: (tag: AnnotationInterface) => void,
  disableEdit: boolean,
  showConfirmation?: boolean,
  setShowConfirmation?: Function,
  speak?: Function,
  speaking?: boolean,
  voices?: SpeechSynthesisVoice[],
  playing?: boolean,
  setPlaying?: Function,
  setRecording?: Function,
  recording?: boolean,
  showGoals?: boolean,
  setShowGoals?: Function,
  currentGoal?: SubmissionTypeGoalFragment | null,
  showQuitConfirmation?: Boolean,
  setShowQuitConfirmation?: Function
  isSpeakingWritingPromptPlaying?: boolean,
  setIsSpeakingWritingPromptPlaying?: Function,
}

export const SubmitTaskContext = createContext<SubmitTaskContextValue>({
  loading: false,
  disableEdit: false,
});
