import { Box, Dialog, FormControlLabel, styled, Typography } from '@mui/material';


export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root.MuiDialog-paper': { overflow: 'hidden ', margin: '32px', maxWidth: '1000px' },
}));

export const StyledDialogBox = styled(Box)(({ theme }) => ({
  width: '600px',
  maxWidth: '600px',
  display: 'flex',
  alignItems: 'center',
}));

export const DialogTitleTypography = styled(Typography)(({ theme }) => ({
  color: '#333',
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: '800',
  fontSize: '32px',
  lineHeight: '28px',
  marginLeft: '4px',
}));

export const StyledDialogContentBox = styled(Box)(({ theme }) => ({
  borderRadius: '9px',
  border: '2px solid #E5E5E5',
  padding: '24px',
  paddingBottom: '10px',
}));

export const StyledBoxMultiSelectTeacherSchoolFilter = styled(Box)(({ theme }) => ({
  marginTop: '4px', marginBottom: '16px', width: '400px',
}));

export const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24.55px',
    textAlign: 'left',
  },
}));
