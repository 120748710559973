import { useDistrictsFilterQuery } from '../../generated/graphql';

interface Props {
  stateIds?: string | string[]
  source?: string[]
}

const useDistrictsFilter = ({ stateIds, source }: Props) => {
  let stateIdList;
  if (stateIds) {
    stateIdList = typeof (stateIds) === 'string' ? [stateIds] as string[] : stateIds;
  }

  const { data, loading } = useDistrictsFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(stateIdList ? { stateIDs: stateIdList as string[] } : {}),
      ...(source ? { source: source as string[] } : {}),
      excludeDeleted: true,
    },
  });

  return {
    districts: data?.districtList?.nodes ?? [],
    loading,
  };
};

export default useDistrictsFilter;
