import { useParams } from 'react-router-dom';
import {
  useDistrictAdminQuery,
  UserPermissions,
  useSchoolAdminQuery,
} from '../../../generated/graphql';
import { AdminType } from '../../../variables/types';
import usePermission from '../../../hooks/usePermission';
import { useImitationProvider } from '../../../core/imitationContext';

interface RouteProp {
  id: string
  type: AdminType;
}

interface Props {
  adminId?: string;
}

const useAdmin = ({ adminId }: Props) => {
  const { id, type } = useParams<RouteProp>();
  const isSchoolAdmin = type === AdminType.School;
  const isDistrictAdmin = type === AdminType.District;
  const { data: districtAdminData, loading: districtAdminLoading } = useDistrictAdminQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: adminId || id,
    },
    skip: !isDistrictAdmin,
  });

  const { data: schoolAdminData, loading: schoolAdminLoading } = useSchoolAdminQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
    skip: !isSchoolAdmin,
  });

  const { hasPermission: editSchoolAdminPermission } = usePermission(UserPermissions.EditSchoolAdmins);
  const { hasPermission: editDistrictAdminPermission } = usePermission(UserPermissions.EditDistrictAdmins);
  const { hasPermission: imitateUserPermission } = usePermission(UserPermissions.ImitateUser);
  const { handleImitationLogin } = useImitationProvider();

  return {
    loading: districtAdminLoading || schoolAdminLoading,
    admin: isDistrictAdmin ? districtAdminData?.districtAdmin : (isSchoolAdmin ? schoolAdminData?.schoolAdmin : null),
    isSchoolAdmin,
    isDistrictAdmin,
    type,
    editSchoolAdminPermission,
    editDistrictAdminPermission,
    imitateUserPermission,
    handleImitationLogin,
  };
};

export default useAdmin;
