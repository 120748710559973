import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import GradesTable from './GradesTable';
import Layout from '../../../components/Layout';
import useTeacherBenchmarksSubmissionList from '../Grade/grades-list-hook';
import TypeformEmbed from '../../../components/TypeformEmbed';
import { useImitationProvider } from '../../../core/imitationContext';
import { palette } from '../../../theme/palette';
import BenchmarkDownload from './BenchmarkDownload';
import DownloadAllIcon from '../../../components/Icons/DownloadAllIcon';

const Grade = () => {
  const {
    benchmarkSubmissionList,
    totalCount,
    loading,
    pagination,
    sort,
    order,
    handleSort,
    handleRedirection, handlePagination,
    selectedBenchmarkTypes, setSelectedBenchmarkTypes,
    selectedStatus, setSelectedStatus,
    handleApplyFilters,
    handleClearFilters,
    submissionQueueData,
    selectedGradedBy,
    setSelectedGradedBy,
    typeformData,
    onTypeformReady,
    onTypeformSubmit,
    userId,
    setOpenDownloadModal,
    openDownloadModal,
    isDownloadStarted,
    setIsDownloadStarted,
  } = useTeacherBenchmarksSubmissionList();

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();
  const buttonStyles = {
    icon: {
      marginRight: '16px',
      fill: palette.customBlue.primaryBlue,
      '& path': {
        fill: palette.customBlue.primaryBlue,
      },
    },
    button: {
      fontWeight: 800,
      width: '100%',
      minWidth: '220px',
    },
  };
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >

        <Box
          display="grid"
          gridTemplateColumns="1fr 220px"
          gap='32px'
          alignItems="center"
        >
          <Box>
            <Typography variant="pageTitle" justifyContent={'left'}>
          Grade
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={(() => { setOpenDownloadModal(true); })}
            startIcon={<DownloadAllIcon />}

          >
            Download Data
          </Button>
        </Box>
        <Box display="flex" mt={2}>
          <GradesTable
            benchmarkSubmissionList={benchmarkSubmissionList}
            totalCount={totalCount}
            loading={loading}
            pagination={pagination}
            sort={sort}
            order={order}
            handleSort={handleSort}
            handleRedirection={handleRedirection}
            selectedBenchmarkTypes={selectedBenchmarkTypes}
            setSelectedBenchmarkTypes={setSelectedBenchmarkTypes}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            handleApplyFilters={handleApplyFilters}
            handleClearFilters={handleClearFilters}
            handlePagination={handlePagination}
            submissionQueueData={submissionQueueData}
            selectedGradedBy={selectedGradedBy}
            setSelectedGradedBy={setSelectedGradedBy}
          />
        </Box>
      </Box>
      {
        isNotImitated && isDownloadStarted && typeformData?.show_typeform && <TypeformEmbed
          formId={typeformData?.typeform_form_id}
          typeformLayout='popover'
          onCloseHandler={onTypeformReady}
          onFormSubmittedHandler={onTypeformSubmit}
          hiddenButton={true}
          buttonText='Feedback Form'
          hiddenFields={{ user_id: userId }}
        />
      }

      <BenchmarkDownload
        openModal={openDownloadModal}
        handleClose={() => { setOpenDownloadModal(false); }}
        handleIsCsvDownloaded={() => { setIsDownloadStarted(true); }}
      />

    </Layout>
  );
};

export default Grade;
