import React, { useState } from 'react';
import {
  Box, Button,
  CircularProgress,
  Popover,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StyledContainerRoot } from '../Assignments/AssignmentsTable/List.styled';
import { ClassesSort, PaginationProps } from '../../variables/types';
import CustomPagination from '../../components/CustomPagination';
import NoResults from '../../components/NoResults';
import WavingLadyFrame from '../../components/Icons/WavingLadyFrame';
import { OrderBy, SectionCategory, SectionDetailsFragmentFragment, TeacherInfo } from '../../generated/graphql';
import ArrowDownIcon from '../../components/Icons/ArrowDownIcon';
import SuccessIcon from '../../components/Icons/SuccessIcon';
import {
  StyledTableCell,
  StyledTableCellOverFlowContent,
  StyledTableDataCell,
  StyledTableDataCellWidth10,
  StyledTableDataColumn,
} from '../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../components/Common/Common.styled';
import { palette  } from '../../theme/palette';
import PencilIcon from '../../components/Icons/PencilIcon';
import useRedirect from '../../hooks/useRedirect';
import { PROVIDER_TYPE } from '../../variables/constant';
import RoutingPaths from '../RoutingPath';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: ClassesSort,
  order: OrderBy,
  classes?: SectionDetailsFragmentFragment[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: ClassesSort) => void) | Function,
  marginTop?: number,
  sxProps?: SxProps,
  rowsPerPage: number,
  setRowsPerPage: Function,
  teacherDetails: any,
}

const successIconStyles = {
  customClassIcon: {
    color: palette.customBlue.cornflowerBlue,
    '& circle': {
      fill: palette.customBlue.cornflowerBlue,
    },
  },
};

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  classes: classesData,
  handleSort,
  marginTop = 2, sxProps,
  rowsPerPage,
  setRowsPerPage,
  teacherDetails,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({});

  const { navigateTo } = useRedirect();

  const handlePopoverOpen = (event: React.MouseEvent, rowId: string) => {
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [rowId]: event.currentTarget as HTMLElement,
    }));
  };

  const handlePopoverClose = (rowId: string) => {
    setAnchorEls((prevAnchorEls) => {
      const updatedAnchorEls = { ...prevAnchorEls };
      delete updatedAnchorEls[rowId];
      return updatedAnchorEls;
    });
  };

  const getTeacher = (teachers?: Array<TeacherInfo | undefined | null>) => {
    teachers?.sort((t1, t2) => Number(!t1?.primary_teacher) - Number(!t2?.primary_teacher));
    return (teachers && teachers?.length > 0 ? `${teachers[0]?.primary_teacher ? '*' : ''}${teachers?.[0]?.teacher_name} ${teachers?.length > 1 ? `+${teachers?.length - 1}` : ''}` : '-');
  };

  const sortColumn = (title:string, sortEnum: ClassesSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  const getEditDisabled = (row: SectionDetailsFragmentFragment) => {
    if (row && row.teachers && row.teachers.length){
      if (row.category === SectionCategory.CustomClass){
        return !row.teachers?.find( (item) => item?.primary_teacher && item.id === teacherDetails?.id);
      }
      return !(row.teachers?.find( (item) => item?.primary_teacher && item.id === teacherDetails?.id) && row.source === PROVIDER_TYPE.FLASHLIGHT);
    }
    return false;
  };


  return (
    <Box
      display="grid"
      width="100%"
      mt={marginTop}
    >
      <Box>
        <StyledContainerRoot sx={ sxProps ?? {} }>
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ textAlign: 'left', width: '25%' }}>
                  { sortColumn('Class Name', ClassesSort.Name) }
                </StyledTableCell>
                <StyledTableDataCellWidth10>
                    Teachers
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                  { sortColumn('Period', ClassesSort.Periods) }
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                  Grades
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                  Student Count
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                  Custom Class
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                    Active PT
                </StyledTableDataCellWidth10>
                <StyledTableDataCell>
                  { sortColumn('School', ClassesSort.School) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  Edit Class
                </StyledTableDataCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && classesData?.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <StyledTableCellOverFlowContent>
                      {row.name}
                    </StyledTableCellOverFlowContent >
                    {row.teachers && row?.teachers?.length > 1 ?
                      <StyledTableDataColumn >
                        <Box  >
                          <Typography component="span" variant='redirectionLinks' onMouseOver={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => handlePopoverOpen(event, row?.id)} >
                            {getTeacher(row?.teachers)}
                          </Typography>
                          <Popover
                            anchorEl={anchorEls[row?.id]}
                            open={Boolean(anchorEls[row?.id])}
                            onClose={() => handlePopoverClose(row?.id)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            sx={{
                              '&.MuiPopover-root .MuiPopover-paper': {
                                borderRadius: '8px',
                                border: '2px solid',
                                borderColor: palette.customGrey.adminButtonBorder,
                                padding: '8px',
                              },
                            }}
                          >
                            <Box  >
                              {row?.teachers?.map((teacher) =>
                                <Box padding={'8px'}   >
                                  <Typography
                                    key={teacher?.id}
                                    component={'span'}
                                    variant={'body1'}
                                    sx={{ mb: '6px' }}
                                    onClick={() => handlePopoverClose(row?.id)}
                                  >
                                    {teacher?.primary_teacher && '*'}
                                    {teacher?.teacher_name}
                                  </Typography>
                                </Box>)}
                            </Box>
                          </Popover>
                        </Box>
                      </StyledTableDataColumn>
                      :
                      <StyledTableDataColumn>
                        <Typography
                          component={'span'}
                          variant='body1'
                        >
                          {getTeacher(row?.teachers)}
                        </Typography>
                      </StyledTableDataColumn>
                    }
                    <StyledTableDataColumn>
                      {row?.period || '-'}
                    </StyledTableDataColumn>
                    <StyledTableDataColumn>
                      {row?.grades?.join() || '-'}
                    </StyledTableDataColumn>
                    <StyledTableDataColumn>
                      {row?.student_ids?.length || '-'}
                    </StyledTableDataColumn>
                    <StyledTableDataColumn>
                      {row?.category === SectionCategory.CustomClass
                        ? <SuccessIcon sx={{ ...successIconStyles.customClassIcon }} /> : '-'}
                    </StyledTableDataColumn>
                    <StyledTableDataColumn>
                      {row?.active_pt ? <SuccessIcon /> : '-'}
                    </StyledTableDataColumn>
                    <StyledTableDataColumn>
                      {row?.school || '-'}
                    </StyledTableDataColumn>
                    <StyledTableDataColumn sx={{ fontWeight: 800 }}>
                      <Button
                        variant="outlined"
                        onClick={() => navigateTo(`${RoutingPaths.Classes}/${row.id}/edit`)}
                        disabled={getEditDisabled(row)}
                      >
                        <PencilIcon fillColor={getEditDisabled(row) ? palette.customBackground.disabled : palette.customBlue.primaryBlue} />
                      </Button>
                    </StyledTableDataColumn>
                  </TableRow>
                );
              },
              )}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                  >
                    <NoResults
                      label="No Classes"
                      description="There has yet to be a class created."
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      <CustomPagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onChange={handlePageChange}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};
export default List;
