import { useEffect, useState } from 'react';
import { GoalAchievementGraphInput, useGoalsAchievementGraphWidgetQuery } from '../../../../generated/graphql';
import { useDashboardContext } from '../../admin-analytics-hook';
import { AdminDashboards } from '../../../../variables/constant';


const useGoalAchievementInfo = () => {
  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
    analyticsFilters: queryFilters,
    sessionStartDate, sessionEndDate,
    startDateSelected, endDateSelected,
  } = useDashboardContext();

  const [hasData, setData] = useState();

  const queryInput: GoalAchievementGraphInput = {
    filters: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.school_id = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData) queryInput.district_id = myDistrictData?.id;

  const { data, loading: achievementGraphLoading } = useGoalsAchievementGraphWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });

  useEffect(() => {
    const goalAchievementDataMissing = data?.goalAchievementGraph?.goal_achievement_by_category?.map((rateData) => rateData?.data?.map((fixedData)=> fixedData?.value ))
      .reduce((total: any, num: any) => total + num, 0)!;
    setData(goalAchievementDataMissing);
    updateDashboardWidgetsDataStatus?.({
      widget: AdminDashboards.learningGoals.GoalAchievementWidget.name,
      loading: achievementGraphLoading,
      dataMissing: !goalAchievementDataMissing,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achievementGraphLoading, data]);

  return ({
    achievementGraphLoading,
    title: `${queryInput.filters?.submission_score_type} Goal Achievement`,
    sessionStartDate, sessionEndDate,
    maxScore: data?.goalAchievementGraph?.max_count,
    categoricalData: data?.goalAchievementGraph?.goal_achievement_by_category,
    hasData,
    currStartDate: startDateSelected, currEndDate: endDateSelected,
  });
};

export default useGoalAchievementInfo;
