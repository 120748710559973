import React from 'react';
import { Box, Typography } from '@mui/material';
import useRubricIndicatorsStandard from './rubricIndicatorStandardsList-hook';
import useRedirect from '../../../hooks/useRedirect';
import Layout from '../../../components/Layout';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import StyledCardWithIcons from '../../../components/StyleCardWithIcons';
import { StyledStickyBreadcrumbBox } from '../TeacherToolbox.styled';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';

const breadcrumb = [{
  label: 'Toolbox',
  route: '/toolbox',
},
{
  label: 'Rubric, Indicators, and Standards',
}];


const RubricIndicatorsStandards = () => {
  const {
    rubricIndicatorStandardsList,
  } = useRubricIndicatorsStandard();

  const { navigateTo: navigateToPath } = useRedirect();
  const navigateTo = (route: string) => () => {
    navigateToPath(route);
  };
  return (
    <Layout>
      <StyledFlexColBoxContainer>
        <StyledStickyBreadcrumbBox>
          <Typography variant="pageTitle">Rubric, Indicators, and Standards</Typography>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
        </StyledStickyBreadcrumbBox>
        <Box
          mt={3}
          display="grid"
          gridTemplateColumns="repeat(3, 360px)"
          sx={{
            gridGap: 32,
          }}
        >
          {
            rubricIndicatorStandardsList.map((tileProp: any) => (
              <StyledCardWithIcons
                key={tileProp.title}
                title={tileProp.title}
                Icon={tileProp.Icon}
                buttonTitle={tileProp.buttonTitle}
                onClick={navigateTo(tileProp.redirectionLink)}
                description={tileProp.description}
              />
            ))
          }
        </Box>
      </StyledFlexColBoxContainer>
    </Layout>
  );
};

export default React.memo(RubricIndicatorsStandards);
