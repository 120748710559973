import { styled } from '@mui/material/styles';
import { Button, Fab, Typography } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import zIndex from '../../theme/z-index';

export const TimeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.fontColors.fontBlack,
}));

export const StyledMicIcon = styled(Button)({
  width: 72,
  height: 72,
  borderRadius: '50%',
  '&::after': {
    borderRadius: '50%',
  },
});

export const StyledMicOffIcon = styled(StyledMicIcon)(({ theme }) => ({
  backgroundColor: theme.palette.customBlack.lightBlack,
  '&::after': {
    backgroundColor: theme.palette.customRed.progressPrimary,
  },
  '&:hover': {
    backgroundColor: theme.palette.customBlack.lightBlack,
  },
  '&:active': {
    backgroundColor: theme.palette.customBlack.lightBlack,
    width: 72,
    height: 72,
    borderRadius: '50%',
    borderWidth: 0,
  },
}));

export const StyledStopIcon = styled(StopIcon)(({ theme }) => ({
  color: theme.palette.customWhite.main,
}));

export const StyledPauseIcon = styled(PauseIcon)(({ theme }) => ({
  color: theme.palette.customWhite.main,
}));

export const StopButton = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.customPink.main,
  zIndex: zIndex.zIndexAuto,
  '&:hover': {
    backgroundColor: theme.palette.customPink.darkPink,
  },
}));

export const StyledPlayArrowIcon = styled(PlayArrowIcon)(({ theme }) => ({
  color: theme.palette.customWhite.main,
}));
