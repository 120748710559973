import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import useGoals from './goals-hook';
import {
  AnalyticsTabs,
  GoalSetGraphData,
  Skills,
  SpeakingSkills,
  WritingSkills,
} from '../../../variables/types';
import GoalsTable from './GoalsTable';
import { ALL_SKILLS } from '../../../variables/constant';
import ActiveGoal from '../Overview/ActiveGoal';
import { StyledTabs, StyledGoalsTab } from '../Analytics.styled';

const StylesCSS = (theme: Theme) => ({
  container: {
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(4),
    width: '100%',
  },
});

interface Props {
  section: string;
  skillSection: Skills;
}

const Goals = ({ section, skillSection }: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const {
    tab,
    loading,
    activeGoalCount,
    activeGoalData,
    skill,
    handleChange,
    search,
    navigateToTab,
  } = useGoals({ section });

  return (
    <Box sx={styles.container}>
      <Box
        width="100%"
      >
        <ActiveGoal
          loading={loading}
          totalCount={activeGoalCount}
          data={activeGoalData}
          title={`Active ${skill} Goals`}
          hideButton
          handleTooltipClick={navigateToTab}
        />
      </Box>
      <Box
        width="100%"
        mt={4}
      >
        <StyledTabs
          value={tab}
          onChange={handleChange}
          textColor="primary"
          scrollButtons={false}
        >
          {activeGoalData?.map((element: GoalSetGraphData) => (
            <StyledGoalsTab
              key={element.skill_label}
              classes={{
                root: `${element.skill_key}`,
              }}
              label={(
                <Typography>
                  <Typography
                    component="span"
                    className="tag"
                  >
                    {element.numberOfGoals}
                  </Typography>
                  <Typography component="span">
                    {element.skillLabel}
                  </Typography>
                </Typography>
              )}
              disableRipple
            />
          ))}
        </StyledTabs>
        <Box display="flex">
          <Switch>
            <Route
              path={[
                `/analytics/${section}/${SpeakingSkills.Pronunciation}`,
                `/analytics/${section}/${SpeakingSkills.Fluency}`,
                `/analytics/${section}/${SpeakingSkills.Vocabulary}`,
                `/analytics/${section}/${SpeakingSkills.Interpretation}`,
                `/analytics/${section}/${SpeakingSkills.Grammar}`,
                `/analytics/${section}/${WritingSkills.PointsAndReason}`,
                `/analytics/${section}/${WritingSkills.VocabAndGrammar}`,
                `/analytics/${section}/${WritingSkills.DescriptionAndExplanation}`,
                `/analytics/${section}/${ALL_SKILLS}`,
              ]}
              render={() => (
                <GoalsTable
                  data={activeGoalData?.[tab]}
                  section={skillSection}
                  loading={loading}
                />
              )}
              exact
            />
            <Redirect
              from={`/analytics/${AnalyticsTabs.SpeakingGoals}`}
              exact
              to={{
                pathname: `/analytics/${AnalyticsTabs.SpeakingGoals}/${ALL_SKILLS}`,
                search,
              }}
            />
            <Redirect
              from={`/analytics/${AnalyticsTabs.WritingGoals}`}
              exact
              to={{
                pathname: `/analytics/${AnalyticsTabs.WritingGoals}/${ALL_SKILLS}`,
                search,
              }}
            />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Goals);
