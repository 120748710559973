import { useContext, useState } from 'react';
import {
  GoalAnalyticsData, useActiveGoalSummaryQuery,
  useScoreSummaryQuery,
} from '../../../generated/graphql';
import { AnalyticsContext } from '../analytics-context';
import { GOAL_SCORES, SEE_GOALS, SKILL_LINK_TEXT } from '../../../variables/constant';
import { GraphSetData, SubmissionTypes } from '../../../variables/types';

export interface GoalGraphArray {
  color: string
  id: string
  skill_description: string
  skill_label: string
  skill_level: number
  skill_key: string
  total_count: number
  students:GoalAnalyticsData & { current_goal_skill: string };
  numberOfGoals: number,
  skillType: string,
  skillLinkText: string,
  linkText: string,
  skillLabel: string,
}

const useOverview = () => {
  const [currentSkill, setCurrentSkill] = useState<GraphSetData | null>(null);
  const [skillFetch, setSkillFetch] = useState();
  const { selectedSection, assignment, selectedSchool } = useContext(AnalyticsContext);

  const { data: scoreData, loading: scoreLoading } = useScoreSummaryQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...(selectedSection ? { sectionIDs: [selectedSection as string] } : {}),
      ...(assignment ? { assignmentIDs: [assignment] } : {}),
      ...(selectedSchool ? { schoolIDs: [selectedSchool as string] } : {}),
    },
  });

  const { data: goalData, loading: goalLoading } = useActiveGoalSummaryQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...(selectedSection ? { sectionIDs: [selectedSection as string] } : {}),
      ...(assignment ? { assignmentIDs: [assignment] } : {}),
      ...(selectedSchool ? { schoolID: selectedSchool as string } : {}),
    },
  });
  const getData = (skillType:SubmissionTypes) => {
    let totalCount = 0;
    let graphData = null;
    const currentSubmissionData = scoreData?.scoreSummary?.filter((item) => item?.submission_type === skillType ?? []);
    const currentSubmissionType = currentSubmissionData?.[0]?.submission_type;
    if (currentSubmissionData) {
      graphData = currentSubmissionData?.[0]?.score_distribution?.reduce((prevValue:any, currentValue:any) => {
        const count = currentValue?.total_count;
        totalCount += count;
        if (currentValue?.skill_label) {
          return [...prevValue, {
            ...(currentValue),
            numberOfStudents: count,
            skillType: currentSubmissionType,
            skillLinkText: SKILL_LINK_TEXT,
          }];
        }
        return prevValue;
      }, []);
    }
    return {
      totalCount, graphData, currentSubmissionType,
    };
  };

  const getGoalData = (filter:SubmissionTypes) => {
    const allGoals: GoalGraphArray[] | undefined = [];
    let goalTotalCount = 0;
    let goalGraphData = null;
    const currentGoalsData = goalData?.activeGoalsSummary?.filter((item) => item?.submission_type === filter ?? []);
    const currentSubmissionType = currentGoalsData?.[0]?.submission_type;
    const all = {
      ...GOAL_SCORES.all,
      skillLabel: `All ${currentSubmissionType} Goals`,
      numberOfGoals: 0,
      goals: undefined,
    };
    if (currentGoalsData) {
      goalGraphData = currentGoalsData?.[0]?.score_distribution?.reduce((prevValue:any, currentValue:any) => {
        const count = currentValue?.total_count;
        goalTotalCount += count;
        if (currentValue?.skill_label) {
          return [...prevValue, {
            ...(currentValue),
            numberOfGoals: count,
            skillType: currentSubmissionType,
            skillLinkText: SEE_GOALS,
            linkText: currentValue?.skill_key,
            skillLabel: currentValue?.skill_label === 'Description/Explanation' ? 'Description and Explanation' : currentValue?.skill_label,
          }];
        }
        return prevValue;
      }, []);
    }
    goalGraphData && allGoals.push(...goalGraphData?.filter((item:GoalGraphArray) => item?.total_count > 0));
    goalGraphData = goalGraphData && [{
      ...all,
      numberOfGoals: goalTotalCount,
      goals: allGoals,
    }, ...goalGraphData];
    return {
      goalTotalCount, goalGraphData,
    };
  };

  const { graphData: speakingData, totalCount: speakingCount, currentSubmissionType: speakingType } = getData(SubmissionTypes.Speaking);
  const { graphData: writingData, totalCount: writingCount, currentSubmissionType: writingType } = getData(SubmissionTypes.Writing);
  const { goalGraphData: activeSpeakingGoalData, goalTotalCount: activeSpeakingGoalCount } = getGoalData(SubmissionTypes.Speaking);
  const { goalGraphData: activeWritingGoalData, goalTotalCount: activeWritingGoalCount } = getGoalData(SubmissionTypes.Writing);

  return ({
    currentSkill,
    setCurrentSkill,
    writingCount,
    speakingCount,
    writingData,
    speakingData,
    scoreLoading,
    speakingType,
    writingType,
    goalLoading,
    skillFetch,
    setSkillFetch,
    activeSpeakingGoalCount,
    activeSpeakingGoalData,
    activeWritingGoalCount,
    activeWritingGoalData,
  });
};

export default useOverview;
