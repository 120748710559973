import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PAGE_SIZE } from '../../../variables/constant';
import {
  ClassFilterFragmentFragment, OrderBy,
  PredefinedGrades, StudentCustomClassGroupSortType,
  TeacherFilterFragmentFragment,
  useCustomClassStudentListQuery,
  useEnrollStudentsInCustomSectionMutation,
  useRemoveInProgressSubmissionsOfSectionMutation,
  useSectionDetailQuery,
  useStudentsWithInProgressSubmissionQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import getErrorMessage from '../../../utils/getErrorMessage';

interface RouteProp {
  id: string
}

const useManageClass = () => {
  const pagination = usePagination('availablePage');
  const paginationAssignedStudents = usePagination('allocatedPage');
  const { id } = useParams<RouteProp>();
  const { data, loading } = useSectionDetailQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });
  const [selectedGrade, setSelectedGrade] = useQueryParam('grade', StringParam);
  const [selectedTeacher, setSelectedTeacher] = useQueryParam('teacher', StringParam);
  const [selectedClass, setSelectedClass] = useQueryParam('class', StringParam);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [selectedAllocatedStudents, setSelectedAllocatedStudents] = useState<string[]>([]);
  const [selectedAvailableStudents, setSelectedAvailableStudents] = useState<string[]>([]);
  const [selectAllAvailable, setSelectAllAvailable] = useState(false);
  const [selectAllAllocated, setSelectAllAllocated] = useState(false);
  const [showRemoveSelectedConfirmation, setShowRemoveSelectedConfirmation] = useState(false);
  const [sortAllocated, setSortAllocated] = useQueryParam('sortAllocated', withDefault(StringParam, StudentCustomClassGroupSortType.Name));
  const [sortAvailable, setSortAvailable] = useQueryParam('sortAllocated', withDefault(StringParam, StudentCustomClassGroupSortType.Name));
  const [orderByAllocated, setOrderAllocated] = useQueryParam('orderAllocated', withDefault(StringParam, OrderBy.Asc));
  const [orderByAvailable, setOrderAvailable] = useQueryParam('orderAvailable', withDefault(StringParam, OrderBy.Asc));

  const { data: students, loading: studentsLoading, refetch } = useCustomClassStudentListQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      input: {
        selected_section_id: id,
        ...(selectedClass ? { section_id: selectedClass } : {}),
        ...(selectedGrade ? { grade_id: selectedGrade } : {}),
        ...(selectedTeacher ? { teacher_id: selectedTeacher } : {}),
        ...(search ? { search } : {}),
        available_limit: PAGE_SIZE,
        available_page: pagination.page,
        allocated_limit: PAGE_SIZE,
        allocated_page: paginationAssignedStudents.page,
        allocated_sort: sortAllocated as StudentCustomClassGroupSortType,
        available_sort: sortAvailable as StudentCustomClassGroupSortType,
        allocated_orderBy: orderByAllocated as OrderBy,
        available_orderBy: orderByAvailable as OrderBy,
      },
    },
  });

  const [updateStudents, { loading: updateStudentsLoader }] = useEnrollStudentsInCustomSectionMutation();

  const { data: studentsWithSubmission, loading: studentsWithSubmissionLoader } = useStudentsWithInProgressSubmissionQuery({
    fetchPolicy: 'no-cache',
    canonizeResults: false,
    variables: {
      sectionID: id,
      studentIDs: Array.from(new Set(selectedAllocatedStudents)),
    },
    skip: !showRemoveSelectedConfirmation,
  });

  const [removeStudentsAssignments, { loading: removeStudentsAssignmentsLoader }] = useRemoveInProgressSubmissionsOfSectionMutation();

  const getStudents = useMemo(() => ({
    allocatedStudents: students?.customClassStudentList?.allocated_students?.nodes ?? [],
    allocatedTotalCount: students?.customClassStudentList?.allocated_students?.totalCount ?? 0,
    availableStudents: students?.customClassStudentList?.available_students?.nodes,
    availableTotalCount: students?.customClassStudentList?.available_students?.totalCount,
    availableStudentIDs: students?.customClassStudentList?.available_student_ids ?? [],
    allocatedStudentIDs: students?.customClassStudentList?.allocated_student_ids ?? [],
  }), [students]);

  const {
    allocatedStudents,
    allocatedTotalCount,
    availableStudents,
    availableTotalCount,
    availableStudentIDs,
    allocatedStudentIDs,
  } = getStudents;

  const onChangeAllocated = (studentId: string) => {
    if (selectAllAllocated) {
      setSelectAllAllocated(false);
    }
    if (selectedAllocatedStudents.includes(studentId)) {
      setSelectedAllocatedStudents(selectedAllocatedStudents.filter((item) => item !== studentId));
    } else {
      setSelectedAllocatedStudents([...selectedAllocatedStudents, studentId]);
    }
  };

  const onChangeAvailable = (studentId: string) => {
    if (selectAllAvailable) {
      setSelectAllAvailable(false);
    }
    if (selectedAvailableStudents.includes(studentId)) {
      setSelectedAvailableStudents(selectedAvailableStudents.filter((item) => item !== studentId));
    } else {
      setSelectedAvailableStudents([...selectedAvailableStudents, studentId]);
    }
  };

  const isAllocatedSelected = (studentId: string) => selectedAllocatedStudents.includes(studentId);

  const isAvailableSelected = (studentId: string) => selectedAvailableStudents.includes(studentId);

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleAssignedStudentsPageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    paginationAssignedStudents.setPage(value as number);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination.setPage(1);
    paginationAssignedStudents.setPage(1);
    if (event.target.value === '') {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
    setSelectAllAllocated(false);
    setSelectAllAvailable(false);
  };

  const onGradeChange = (grade: string | PredefinedGrades | null) => {
    pagination.setPage(1);
    paginationAssignedStudents.setPage(1);
    setSelectedGrade(grade as string);
    setSelectAllAvailable(false);
  };

  const onClassChange = (section: string | ClassFilterFragmentFragment | null) => {
    pagination.setPage(1);
    paginationAssignedStudents.setPage(1);
    setSelectedClass(section as string);
    setSelectAllAvailable(false);
  };

  const onTeacherChange = (teacher: string | TeacherFilterFragmentFragment | null) => {
    pagination.setPage(1);
    paginationAssignedStudents.setPage(1);
    setSelectedTeacher(teacher as string);
    setSelectAllAvailable(false);
  };

  const handleAddSelected = async () => {
    await update(selectedAvailableStudents, []);
  };

  const handleRemoveSelected = async () => {
    setShowRemoveSelectedConfirmation(true);
  };

  const handleRemoveSelectedConfirm = async () => {
    setShowRemoveSelectedConfirmation(false);
    if (studentsWithSubmission?.studentsWithInProgressSubmission && studentsWithSubmission?.studentsWithInProgressSubmission?.length > 0) {
      try {
        const response = await removeStudentsAssignments({
          variables: {
            sectionID: id,
            studentIDs: Array.from(new Set(selectedAllocatedStudents)),
          },
        });
        if (response?.data?.removeInProgressSubmissionsOfSection) {
          await update([], selectedAllocatedStudents);
        } else {
          openSnackbar({ message: 'Error in removing in progress PTs.' }, NotifierType.Success);
        }
      } catch (error) {
        openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
      }
    } else {
      await update([], selectedAllocatedStudents);
    }
  };

  const cancelRemoveSelected = () => {
    setShowRemoveSelectedConfirmation(false);
  };

  const handleSelectAllAvailable = () => {
    if (selectAllAvailable) {
      setSelectedAvailableStudents([]);
    } else {
      setSelectedAvailableStudents([...selectedAvailableStudents, ...availableStudentIDs]);
    }
    setSelectAllAvailable(!selectAllAvailable);
  };

  const handleSelectAllAllocated = () => {
    if (selectAllAllocated) {
      setSelectedAllocatedStudents([]);
    } else {
      setSelectedAllocatedStudents([...selectedAllocatedStudents, ...allocatedStudentIDs]);
    }
    setSelectAllAllocated(!selectAllAllocated);
  };

  const update = async (studentIDsToAdd: string[], studentIDsToRemove: string[]) => {
    try {
      const response = await updateStudents({
        variables: {
          input: {
            selected_section_id: id,
            ...(studentIDsToAdd?.length > 0 ? { student_ids_to_add: Array.from(new Set(studentIDsToAdd)) } : {}),
            ...(studentIDsToRemove?.length > 0 ? { student_ids_to_remove: Array.from(new Set(studentIDsToRemove)) } : {}),
          },
        },
      });
      paginationAssignedStudents.setPage(1);
      pagination.setPage(1);
      if (response?.data?.enrollStudentsInCustomSection) {
        openSnackbar({ message: 'Class updated successfully.' }, NotifierType.Success);
        await refetch();
        setSelectAllAllocated(false);
        setSelectAllAvailable(false);
        if (studentIDsToAdd?.length > 0) {
          setSelectedAvailableStudents([]);
        } else if (studentIDsToRemove?.length > 0) {
          setSelectedAllocatedStudents([]);
        }
      } else {
        openSnackbar({ message: 'Error updating the class.' }, NotifierType.Error);
      }
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  const confirmationMessage = useMemo(() => {
    const studentSubmissions = studentsWithSubmission?.studentsWithInProgressSubmission ?? [];
    return studentSubmissions?.length > 0 ? `${studentSubmissions?.reduce((prevValue, current, index) => {
      if (index === 0) {
        return `${current?.first_name} ${current?.last_name}`;
      } if (index === studentSubmissions.length - 1) {
        return `${prevValue} and ${current?.first_name} ${current?.last_name}`;
      }
      return `, ${current?.first_name} ${current?.last_name}`;
    }, '')} have PTs in progress. If you proceed, their progress will be lost.` : 'Are you sure you want to remove the selected students?';
  }, [studentsWithSubmission]);

  const handleAllocatedSort = (column: string) => () => {
    if (column === sortAllocated) {
      setOrderAllocated(orderByAllocated === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      setSortAllocated(column);
      setOrderAllocated(OrderBy.Asc);
    }
  };

  const handleAvailableSort = (column: string) => () => {
    if (column === sortAvailable) {
      setOrderAvailable(orderByAvailable === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      setSortAvailable(column);
      setOrderAvailable(OrderBy.Asc);
    }
  };

  return {
    loading: loading || studentsLoading || updateStudentsLoader,
    pageLoading: studentsWithSubmissionLoader || removeStudentsAssignmentsLoader,
    pagination,
    handlePageChange,
    selectedClass,
    onClassChange,
    selectedTeacher,
    onTeacherChange,
    selectedGrade,
    onGradeChange,
    search,
    onSearch,
    paginationAssignedStudents,
    handleAssignedStudentsPageChange,
    allocatedStudents,
    allocatedTotalCount,
    availableStudents,
    availableTotalCount,
    section: data?.sectionDetails,
    onChangeAllocated,
    onChangeAvailable,
    isAllocatedSelected,
    isAvailableSelected,
    handleAddSelected,
    handleRemoveSelected,
    selectedAllocatedStudents,
    selectedAvailableStudents,
    selectAllAvailable,
    selectAllAllocated,
    handleSelectAllAvailable,
    handleSelectAllAllocated,
    handleRemoveSelectedConfirm,
    showRemoveSelectedConfirmation,
    cancelRemoveSelected,
    confirmationMessage,
    handleAllocatedSort,
    handleAvailableSort,
    sortAllocated,
    orderByAllocated,
    sortAvailable,
    orderByAvailable,
  };
};

export default useManageClass;
