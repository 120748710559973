import React from 'react';
import { SvgIcon } from "@mui/material";

const ListenIcon = (props: any) => (


    <SvgIcon width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" fontSize='large'>
        <g clip-path="url(#clip0_37991_24158)">
            <path d="M21.8763 8.75017C17.3267 8.75017 13.6261 12.4507 13.6261 17.0003C13.6261 20.034 16.0943 22.5005 19.1263 22.5005C20.6439 22.5005 21.8763 23.7345 21.8763 25.2505C21.8763 26.7665 20.6439 28.0006 19.1263 28.0006H17.0637C16.6839 28.0006 16.3762 28.3082 16.3762 28.6881V30.0631C16.3762 30.443 16.6839 30.7506 17.0637 30.7506H19.1263C22.1582 30.7506 24.6264 28.2842 24.6264 25.2505C24.6264 22.2169 22.1582 19.7504 19.1263 19.7504C17.6086 19.7504 16.3762 18.5163 16.3762 17.0003C16.3762 13.9667 18.8444 11.5002 21.8763 11.5002C24.9083 11.5002 27.3764 13.9667 27.3764 17.0003V19.0629C27.3764 19.4427 27.6841 19.7504 28.0639 19.7504H29.439C29.8188 19.7504 30.1265 19.4427 30.1265 19.0629V17.0003C30.1265 12.4507 26.4259 8.75017 21.8763 8.75017ZM21.8763 0.5C12.7633 0.5 5.37598 7.88734 5.37598 17.0003V32.1257C5.37598 38.9604 10.9165 44.5009 17.7512 44.5009C24.586 44.5009 30.1265 38.9604 30.1265 32.1257V31.2749C35.0534 28.4208 38.3767 23.1037 38.3767 17.0003C38.3767 7.88734 30.9893 0.5 21.8763 0.5ZM28.748 28.8952L27.3764 29.6901V32.1257C27.3764 37.4333 23.0588 41.7509 17.7512 41.7509C12.4436 41.7509 8.12603 37.4333 8.12603 32.1257V17.0003C8.12603 9.41878 14.2948 3.25006 21.8763 3.25006C29.4579 3.25006 35.6266 9.41878 35.6266 17.0003C35.6266 21.88 32.9908 26.4373 28.748 28.8952Z" fill="#19A940" />
        </g>
        <defs>
            <clipPath id="clip0_37991_24158">
                <rect width="33.0007" height="44.0009" fill="white" transform="translate(5.37598 0.5)" />
            </clipPath>
        </defs>
    </SvgIcon>)

export default ListenIcon