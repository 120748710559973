import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { DialogTitleTypography } from '../../TeacherBenchmarks/Grade/BenchmarkDownload.styled';
import EditIcon from '../../../components/Icons/EditIcon';

type Props = {
  openModal: boolean,
  handleClose: () => void,
  handleNext: () => void,
};

const WritingModal = ({ openModal, handleClose, handleNext }: Props) => {

  const handleCloseModal = () => {
    handleClose();
  };
  return (
    <Dialog open={openModal} onClose={handleClose} sx={{ '.MuiPaper-root.MuiDialog-paper': { overflow: 'hidden ', margin: '32px', maxWidth: '1000px', width: '720px' } }}>
      <DialogTitle sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <EditIcon />
        </Box >
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" sx={{ marginTop: '8px' }}>
          <DialogTitleTypography>Did you fix any writing mistakes?</DialogTitleTypography>
        </Box>
      </DialogTitle>

      <DialogActions style={{ justifyContent: 'end', marginTop: 2 }} >
        <Box >
          <Button onClick={handleCloseModal} variant={'outlined'} >
            Go Back
          </Button>
          <Button onClick={handleNext} variant="contained" sx={{ marginLeft: '8px' }}>
            Submit!
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default WritingModal;
