import { styled } from '@mui/material/styles';
import { Typography, Button, Box } from '@mui/material';

export const NavBarStyledNavigationButton = styled(Button)({
  borderWidth: 0,
  borderRadius: 0,
  marginTop: 5,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    borderWidth: 0,
    borderRadius: 0,
  },
  '&::after': {
    display: 'none',
  },
});

export const NavBarNameStyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.fontColors.fontBlack,
  fontSize: '16px',
  fontWeight: 800,
  lineHeight: '22px',
}) );

export const NavBarSchoolStyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.customBlack.lightBlack,
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '17px',
})) ;

export const StyledAddBtn = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root.MuiButton-root': {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    height: 38,
  },
})) as typeof Button;

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

