import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { UserRole } from '../../generated/graphql';
import { palette } from '../../theme/palette';

interface DecoupleWarningModalProps {
  toggleDecoupleWarningOpen: () => void;
  warningOpen: boolean;
  cognitoUsers: any;
}

const DecoupleWarningModal = ({ toggleDecoupleWarningOpen, warningOpen, cognitoUsers  }: DecoupleWarningModalProps) => {
  const teachers = cognitoUsers?.filter(
    (user: any) => user.role === UserRole.Teacher).map(
    (user: any) => user.first_name + ' ' + user.last_name + ' [' + user.external_id + ']',
  ).join(', ');

  const students = cognitoUsers?.filter(
    (user: any) => user.role === UserRole.Student).map(
    (user: any) => user.first_name + ' ' + user.last_name + ' [' + user.external_id + ']',
  ).join(', ');

  const handleDecoupleWarningClose = () => {
    toggleDecoupleWarningOpen();
  };

  return (
    <Dialog
      open={warningOpen}
      onClose={toggleDecoupleWarningOpen}
      PaperProps={{
        sx: {
          borderTop: `12px solid ${palette.customBlue.primaryBlue}`,
        },
      }}
    >
      <DialogTitle sx={{ paddingTop: 0, textAlign: 'center' }} >
        <Box sx={{ marginTop: 2 }}>
          <Typography variant='tileTitle' sx={{ textTransform: 'inherit', color: palette.customBlack.fontBlack }}>
            Resolve issues with Rosterstream before decoupling!
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Box width="100%" textAlign='left'>
            <Typography variant='body2' sx={{ marginTop:'10px' }}>There was error syncing with the following information:</Typography>
            <Typography variant='body2' sx={{ marginTop:'15px' }}><Box fontWeight='fontWeightBold' display='inline'>Teachers</Box>: {teachers} </Typography>
            <Typography variant='body2' sx={{ marginTop:'15px' }}><Box fontWeight='fontWeightBold' display='inline'>Students</Box>: {students}</Typography>
          </Box>
          <Typography variant='tableTitleData' sx={{ fontWeight: 600, marginTop: 4 }}>
            Please resolve this within Rosterstream to match records. Until the issues are resolved, the decouple cannot be initiated.
          </Typography>
          <DialogActions sx={{ paddingX: 0, marginLeft: 'auto'  }} >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDecoupleWarningClose}
            >
              Close
            </Button>
          </DialogActions>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DecoupleWarningModal;
