import React, { FormEvent, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Dialog, TextField, Theme, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import usePredefinedGoals from './predefined-goals-hook';
import { Skills, SpeakingSkills } from '../../variables/types';
import { CUSTOM_GOAL_CATEGORY, CUSTOM_GOAL_OPTION, DEFAULT_GOAL_OPTION } from '../../variables/constant';
import { StudentGoalFragment } from '../../generated/graphql';

interface Props {
  openDialog: boolean,
  closeDialog?: any,
  header: String,
  goalType?: Skills,
  onSuccess?: Function,
  activeGoal?: StudentGoalFragment[],
}

const StylesCSS = (theme: Theme) => ({
  chips: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  selectedChip: {
    color: theme.palette.customWhite.main,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  helpText: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    '.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
      maxHeight: '30vh',
      maxWidth: '30vw',
      ' .MuiMenuItem-root': {
        whiteSpace: 'normal',
        height: 'auto',
        minHeight: theme.spacing(6.25),
      },
    },
  },
  inputStyle: {
    '& .MuiInputBase-input': {
      background: 'none',
      fontWeight: 'normal',
    },
  },
  disabled: {
    '&.MuiButtonBase-root': {
      background: theme.palette.customWhite.whiteSmoke,
      marginTop: theme.spacing(3),
      borderRadius: theme.spacing(1.5),
      '.MuiInputBase-input': {
        background: theme.palette.customWhite.whiteSmoke,
        border: 0,
        cursor: 'not-allowed',
      },
    },
  },
  saveButton: {
    '&.MuiButtonBase-root': {
      marginTop: theme.spacing(3),
      width: theme.spacing(25),
      fontSize: theme.spacing(2.25),
      lineHeight: theme.spacing(3),
      fontWeight: 800,
    },
  },
});

const CreateGoal = (
  {
    openDialog,
    closeDialog,
    header,
    goalType,
    onSuccess,
    activeGoal,
  }: Props,
) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const {
    selectRubric,
    selectedGoal,
    selectGoal,
    rubricsList,
    goalOptions,
    selectedRubric,
    customGoal,
    handleCustomGoal,
    addCustomGoal,
    creating,
    submission,
    addStudentGoal,
    adding,
  } = usePredefinedGoals({ goalType });

  useEffect(() => {
    if (openDialog && activeGoal && activeGoal.length > 0) {
      selectRubric(activeGoal[0].predefined_goal_rubric_title!);
      selectGoal(activeGoal[0].predefined_goal_id);
    }
  }, [activeGoal, selectGoal, selectRubric, openDialog]);

  const activeGoalId = activeGoal && activeGoal[0].id;
  const getSelectOptions = () => {
    let optionsArray: JSX.Element[] = [
      <MenuItem
        key="select-option"
        value={DEFAULT_GOAL_OPTION}
      >
        Select a goal
      </MenuItem>,
    ];
    let customOptionsArray: JSX.Element[] = [];
    goalOptions.forEach((option) => {
      const listEntry = [(
        <ListSubheader
          key={option?.category}
          disableSticky
        >
          {option?.category}
        </ListSubheader>
      )];
      option?.goal_list?.forEach((goalItem) => {
        listEntry.push(
          <MenuItem
            key={goalItem?.id}
            value={goalItem?.id}
            sx={{ fontWeight: 'bold' }}
          >
            <Box>
              {goalItem?.description}
              {
                goalItem?.example && (
                  <Typography variant='optionsItalic' component='p'>
                    {goalItem.example}
                  </Typography>
                )
              }
            </Box>
          </MenuItem>,
        );
      });
      if (option?.category === CUSTOM_GOAL_CATEGORY) {
        customOptionsArray = [...customOptionsArray, ...listEntry];
      } else {
        optionsArray = [...optionsArray, ...listEntry];
      }
    });
    optionsArray = [...optionsArray, ...customOptionsArray];
    optionsArray.push(
      <MenuItem
        key="custom-option"
        value={CUSTOM_GOAL_OPTION}
      >
        {`+ Create a custom ${selectedRubric} goal`}
      </MenuItem>,
    );
    return optionsArray;
  };
  const enabledSubmit = selectedGoal!!
    && selectedGoal !== DEFAULT_GOAL_OPTION
    && (!activeGoal || activeGoal[0].predefined_goal_id != selectedGoal)
   && (selectedGoal !== CUSTOM_GOAL_OPTION || !!customGoal?.description?.trim());

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedGoal === CUSTOM_GOAL_OPTION) {
      addCustomGoal(submission?.id, activeGoalId)
        .then(() => {
          onSuccess?.();
        });
    } else {
      addStudentGoal?.(selectedGoal as string, activeGoalId)
        .then(() => {
          onSuccess?.();
        });
    }
  };

  return (
    <Dialog
      aria-describedby={`${goalType}-dialog`}
      aria-labelledby={`${goalType}-dialog-desc`}
      open={openDialog}
      onClose={() => {
        selectRubric('');
        closeDialog();
      }}
    >
      <Typography variant="pageTitle" component='p'>{header}</Typography>
      <form
        id="goal-form"
        autoComplete="off"
        onSubmit={(e) => handleFormSubmit(e)}
        noValidate
      >
        <Typography variant='sFormTitle' component='p'>Select a goal focus</Typography>
        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
        >
          {
            rubricsList?.map((rubric) => (
              <Chip
                key={`${rubric}-chip`}
                label={rubric}
                variant={rubric === selectedRubric ? undefined : 'outlined'}
                sx={{
                  ...styles.chips,
                  ...(rubric === selectedRubric && {
                    ...styles.selectedChip,
                  }),
                }}
                onClick={() => {
                  selectRubric(rubric);
                  selectGoal(DEFAULT_GOAL_OPTION);
                }}
              />
            ))
          }
        </Box>
        {
          goalOptions?.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant='sFormTitle'>
                {`Select ${selectedRubric?.toLowerCase() === SpeakingSkills.Interpretation ? 'an' : 'a'} ${selectedRubric?.toLowerCase()} goal`}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Select
                  defaultValue={DEFAULT_GOAL_OPTION}
                  id="grouped-select"
                  variant="outlined"
                  value={selectedGoal}
                  onChange={(event: SelectChangeEvent<any>) => selectGoal(event.target.value)}
                  MenuProps={{ sx: styles.menuPaper }}
                  sx={{ backgroundColor: 'none' }}
                >
                  {getSelectOptions()}
                </Select>
              </Box>
              <Box sx={styles.helpText}>
                You can select a goal from one of our predetermined goals or create a custom goal to best suit the students needs.
              </Box>
              {
                selectedGoal === CUSTOM_GOAL_OPTION && (
                  <>
                    <Typography variant='sFormTitle' sx={{ mt: 2 }}>{`Custom ${selectedRubric?.toLowerCase()} goal`}</Typography>
                    <TextField
                      id="custom-goal-description"
                      sx={{ ...styles.inputStyle, my: 1 }}
                      fullWidth
                      value={customGoal?.description}
                      onChange={((event: any) => handleCustomGoal('description', event.target.value))}
                    />
                    <Typography variant='sFormTitle' sx={{ mt: 2 }}>Example (to help demonstrate the goal)</Typography>
                    <TextField
                      id="custom-goal-example"
                      sx={{ ...styles.inputStyle, my: 1 }}
                      fullWidth
                      value={customGoal?.example}
                      onChange={((event: any) => handleCustomGoal('example', event.target.value))}
                    />
                  </>
                )
              }
            </Box>
          )
        }
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!enabledSubmit || adding || creating}
            sx={enabledSubmit ? styles.saveButton : styles.disabled}
          >
            {adding || creating ? 'Adding...' : 'Add Goal'}
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};

export default CreateGoal;
