import React from 'react';
import { Box, Typography } from '@mui/material';
import StyledCard from '../../../../../components/StyledCard';
import AppTooltip from '../../../../../components/AppTooltip';
import { AdminBenchmarkReportWidgets } from '../../../../../variables/constant';
import useIndicatorScore from './indicator-score-hook';
import MyLegend from '../../../../TeacherBenchmarksDashboard/BenchmarkSummary/OverallPerformanceWidget/Legend';
import NoResults from '../../../../../components/NoResults';
import EmptyBenchmarkResults from '../../../../../components/Icons/EmptyBenchmarksResults';
import DonutChartLoader from '../../../../../components/Loader/DonutChartLoader';
import AdminBenchmarkIndicatorScore
  from '../../../../../components/AdminBenchmarkIndicatorScoreWidget/AdminBenchmarkIndicatorScore';
import { AdminBenchmarkReportStylesCSS } from '../../adminBenchmarkReport.styled';
import { useTheme } from '@mui/material/styles';

interface Props {
  alignTitle?: boolean, 
  enableTooltip?: boolean, 
  tooltipTitle?: string, 
  districtId: string,
  yAxisDomain: number[],
  yAxisTicks: number[],
  schoolId: string,
}

const IndicatorScoreReport = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
  districtId,
  yAxisDomain,
  yAxisTicks,
  schoolId,
}: Props) => {
  const {
    hasData,
    indicatorScoreLoading,
    barChartData,
    legendData,
    isSchoolAdmin,
  } = useIndicatorScore(districtId, schoolId);

  const theme = useTheme();
  const styles = AdminBenchmarkReportStylesCSS(theme);

  const title: string =  'Benchmark Indicator Scores';
  return (
    <Box id={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkIndicatorScores.name} sx={{ height: '100%', width: '100%' }}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        fullHeight
        marginBottom='0px'

      >
        {barChartData && !indicatorScoreLoading && hasData && (
          <Box 
            display='flex'
            flexDirection='column' 
            alignItems='flex-start'
            justifyContent='space-between'
          >
            <Box id='indicator-score-legend-data'>
              <MyLegend
                data={legendData} 
                wrapperStyle={{ textAlign: 'left', top: '65px', left: '25px'  }}
              />
            </Box>
            <Box>
              <AdminBenchmarkIndicatorScore
                barData={barChartData}
                xAxisKey='rubricTitle'
                barKey1='rubricBoyScoreAverage'
                barKey2='rubricMoyScoreAverage'
                barKey3='rubricEoyScoreAverage'
                barWidth1={850}
                barWidth2={500}
                barHeight={300}
                barSize1={38}
                barSize2={38}
                barSize3={38}
                yAxisDomain={yAxisDomain}
                barRadius={[4, 4, 4, 4]}
                yAxisTicks={yAxisTicks}
              />
            </Box>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Box display='flex' flexDirection='column' alignItems='center' >
                <Typography sx={{ ...styles.ytdText }}>YTD</Typography>
                <Typography sx={{ ...styles.ytdText }}>Change:</Typography>
              </Box>
              {
                barChartData.map((data: any, index: number) => (
                  <Box key={index} paddingLeft={ data?.ytd ? (index === 0 ? 4 : 17.6) : (index === 0 ? 5 : 20.6)} >
                    <Typography sx={{ ...styles.ytdText }}>{data?.ytd || '-'}</Typography>
                  </Box>
                ))
              }
            </Box>
          </Box>
        )}

        {!indicatorScoreLoading && !barChartData && (
          <NoResults
            description={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkIndicatorScores.insufficientData.description}
            svgComponent={<EmptyBenchmarkResults />}
          />
        )}
        {indicatorScoreLoading && <DonutChartLoader />}
      </StyledCard>
    </Box>

  );
};

export default IndicatorScoreReport;