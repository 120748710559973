import { Theme } from '@mui/system';
import { palette  } from '../palette';

const Checkbox = (theme: Theme) => {
  return {
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.palette.grey[500],
          },
          '&:hover':{
            backgroundColor: palette.customBackground.iconButtonBackground,
          },
        },
      },
    },
  };
};

export default Checkbox;
