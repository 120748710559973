import React from 'react';
import { format } from 'date-fns';
import { palette } from '../../../theme/palette';
import { Box, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  active?: string,
  payload?: any
}

const StylesCss = (theme: Theme) => ({
  dialogBox: {
    padding: theme.spacing(1, 2),
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customRed.progressPrimary}`,
    width: 'auto',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: `0 3px 5px 1px ${palette.customBackground.disabled}`,
  },
});

const CustomTooltip = ({ active, payload }:Props) => {
  const theme = useTheme();
  const styles = StylesCss(theme);
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <Box className="custom-tooltip" style={styles.dialogBox}>
        <Typography component='p' sx={{
          // textAlign: 'center',
          fontSize: '16px',
          fontWeight: 600,
          color: palette.customGrey.calendarGrey,
          mb: '5px',
        }}
        >
          {currData ? format(new Date(currData.date), 'MMMM dd, yyyy') : ' -- '}
        </Typography>
        <Box
          display='flex'
          justifyContent= 'space-between'
        >
          <Typography variant="toolTipText">
            Average Score : 
          </Typography>
          <Typography variant="toolTipValue" sx={{ paddingLeft: '18px' }}>
            {currData ? currData.avgScore : ' -- '}
          </Typography>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Typography variant="toolTipText">
            Assignments Scored : 
          </Typography>
          <Typography variant="toolTipValue" sx={{ paddingLeft: '18px' }}>
            {currData ? currData.totalPtScored : ' -- '}
          </Typography>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Typography variant="toolTipText">
            Number of Students : 
          </Typography>
          <Typography variant="toolTipValue" sx={{ paddingLeft: '18px' }}>
            {currData ? currData.uniqueStudentCount : ' -- '}
          </Typography>
        </Box>
      </Box>
    );
  }
  return null;
};

export default CustomTooltip;
