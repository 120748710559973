import React from 'react';
import { Box, GlobalStyles } from '@mui/material';
import { StyledSpanXBars } from './Loader.styled';

const styles = {
  container: {
    padding: '16px',
    paddingBottom: 0,
    width: '100%',
  },
  main: {
    height: 384,
    position: 'relative',
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fadify: {
    animationName: 'fadify',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },

  delay1: {
    animationDelay: '.05s !important',
  },
  delay2: {
    animationDelay: '.1s !important',
  },
  delay3: {
    animationDelay: '.15s !important',
  },
  delay4: {
    animationDelay: '.2s !important',
  },
  delay5: {
    animationDelay: '.25s !important',
  },
  xAxis: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
  },
};

const VerticalBarLoaders = () => {
  return (
    <Box sx={{ ...styles.container }}>
      <GlobalStyles styles={{
        '@keyframes fadify': {
          '0%': { opacity: 1 },
          '100%': {
            opacity: 1,
          },
          '85%': {
            opacity: 0.85,
          },
          '70%': {
            opacity: 0.7,
          },
          '55%': {
            opacity: 0.55,
          },
          '40%': {
            opacity: 0.4,
          },
        },
      }} />
      <Box
        sx={{ ...styles.main }}
      >
        <Box sx={{ ...styles.fadify, ...styles.xAxis }}>
          <StyledSpanXBars sx={{ ...styles.delay1 }}/>
          <StyledSpanXBars sx={{ ...styles.delay2 }}/>
          <StyledSpanXBars sx={{ ...styles.delay3 }}/>
          <StyledSpanXBars sx={{ ...styles.delay4 }}/>
          <StyledSpanXBars sx={{ ...styles.delay5 }}/>
          <StyledSpanXBars sx={{ ...styles.delay1 }}/>
          <StyledSpanXBars sx={{ ...styles.delay2 }}/>
          <StyledSpanXBars sx={{ ...styles.delay3 }}/>
          <StyledSpanXBars sx={{ ...styles.delay4 }}/>
          <StyledSpanXBars sx={{ ...styles.delay5 }}/>
          <StyledSpanXBars sx={{ ...styles.delay1 }}/>
          <StyledSpanXBars sx={{ ...styles.delay2 }}/>
        </Box>
      </Box>
    </Box>
  );
};
export default VerticalBarLoaders;
