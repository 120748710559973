/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const styles = {
  writingAnchorFontSize: {
    fontSize: '4rem',
  },
};

const WritingAnchorIcon = (props: any) => {
  return (
    <SvgIcon
      fontSize="small"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      classes={{ fontSizeLarge: styles.writingAnchorFontSize }}
      {...props}
    >

      <g clipPath="url(#clip0_7019:355)">
        <path
          d="M30.8289 3.5147L28.4858 1.17157C26.9237 -0.390492 24.3911 -0.390555 22.8289 1.17157L0.802828 23.1976L0.00989083 30.3341C-0.0962342 31.2894 0.711141 32.0968 1.66639 31.9906L8.80289 31.1976L30.829 9.17157C32.391 7.60945 32.391 5.07676 30.8289 3.5147ZM24.8791 12.2928L10.8755 26.2965V24.1249H7.87545V21.1249H5.70389L19.7076 7.12126L24.8791 12.2928ZM7.88464 29.2873L4.19745 29.697L2.30339 27.8029L2.71308 24.1158L3.95389 22.8749H6.12545V25.8749H9.12545V28.0465L7.88464 29.2873ZM29.4146 7.75732L26.2933 10.8786L21.1218 5.70707L24.2431 2.58576C25.0246 1.8042 26.2898 1.80407 27.0715 2.58576L29.4146 4.92888C30.1963 5.71051 30.1963 6.97557 29.4146 7.75732Z"
          fill={props.fillColor || palette.fontColors.fontBlack}
        />
      </g>
      <defs>
        <clipPath id="clip0_7019:355">
          <rect width="32" height="32" fill={palette.customWhite.main} />
        </clipPath>
      </defs>

    </SvgIcon>
  );
};

export default WritingAnchorIcon;
