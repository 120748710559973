import {
  OrderBy,
  SectionCategory,
  SectionsListSortType,
  useAddTypeformUserMappingMutation,
  useGetCreateClassesSurveyTypeformQuery,
  useGetTeacherByUserQuery,
  useSectionsListQuery,
} from '../../generated/graphql';
import usePagination from '../../utils/usePagination';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { ROWS_PER_PAGE } from '../../variables/constant';
import { ClassesSort, NotifierType } from '../../variables/types';
import { useAuthProvider } from '../../core/authContext';
import useRole from '../../hooks/useRole';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';

const useTeacherClassesList = () => {
  const pagination = usePagination();
  const [ searchTerm, setSearchTerm ] = useState('');
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [order, setOrder] = useQueryParam('order', StringParam);
  const [selectedSchool, setSelectedSchool] = useQueryParam('school', StringParam);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const { getUser } = useAuthProvider();
  const user = getUser();
  const { isTeacher } = useRole();

  const classCreatedCount = localStorage?.getItem('class-created-count') || 0;

  const { data: typeformDetails, loading: typeformDetailsLoading } = useGetCreateClassesSurveyTypeformQuery({
    fetchPolicy: 'network-only',
    variables: {},
  });

  const [addTypeformUserMapping, { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();


  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage]);

  const { data, loading } = useSectionsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: rowsPerPage,
      page: pagination.page,
      sort: sort as SectionsListSortType,
      orderBy: order as OrderBy,
      ...(searchTerm && searchTerm.length > 2 ? { searchByName: searchTerm } : {}),
      ...(selectedSchool ? { schoolId:selectedSchool } : {}),
      classTypes : [SectionCategory.Class, SectionCategory.CustomClass],
    },
  });

  const { data: teacherDetails, loading: teacherDetailsLoading } = useGetTeacherByUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      userID: user.id,
    },
    skip: !isTeacher || !user.id,
  });


  const onSortChange = (sortBy: ClassesSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const classes = data?.sectionsList.nodes ?? [];
  const totalCount = data?.sectionsList.totalCount ?? 0;

  const isTableEmpty = !loading && classes?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: ClassesSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onSchoolChange = (school: string | null) => {
    pagination.setPage(1);
    setSelectedSchool(school as string);
  };

  const onTypeformReady = async () => {
    try {
      localStorage?.setItem('class-created-count', '0');
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetails?.getCreateClassesSurveyTypeform?.id ?? '',
          submitted: false,
        },
      });
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const onTypeformSubmit = async () => {
    try {
      localStorage?.setItem('class-created-count', '0');
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetails?.getCreateClassesSurveyTypeform?.id ?? '',
          submitted: false,
        },
      });
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    loading: loading || typeformDetailsLoading,
    teacherDetailsLoading,
    totalCount,
    classes,
    pagination,
    onSortChange,
    sort: sort as ClassesSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedSchool,
    onSchoolChange,
    rowsPerPage,
    setRowsPerPage,
    searchTerm, setSearchTerm,
    teacherDetails: teacherDetails?.teacherByUser ?? {},
    classCreatedCount,
    onTypeformReady,
    onTypeformSubmit,
    updateUserLoading,
    typeformData: typeformDetails?.getCreateClassesSurveyTypeform ?? null,
    userId: user?.id,
  };
};

export default useTeacherClassesList;
