/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const DonutStickIcon = (props: any) => (
  <SvgIcon
    width="37"
    height="24"
    viewBox="0 0 37 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M35.9814 2.86074C37.4513 5.40674 36.3716 8.6651 33.6722 9.82952L2.03347 23.4771C1.62054 23.6552 1.13965 23.4959 0.914795 23.1064V23.1064C0.689937 22.717 0.79238 22.2208 1.15311 21.9523L28.7916 1.37619C31.1497 -0.379379 34.5114 0.314748 35.9814 2.86074V2.86074Z" fill={palette.customGrey.lightShedGrey} />
  </SvgIcon>
);

export default React.memo(DonutStickIcon);
