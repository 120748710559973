import { styled, TableCell, Button } from '@mui/material';

export const StyledGroupNameTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2.5),
    width: '25%',
  },
}));

export const StyledStudentCountTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.customBlack.lightBlack,
  fontSize: theme.spacing(2),
  width: '15%',
}));

export const StyledClassNameTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.customBlack.lightBlack,
  fontSize: theme.spacing(2),
  width: '25%',
}));

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    color: theme.palette.customWhite.main,
    background: theme.palette.customRed.progressPrimary,
    padding: theme.spacing(1.5, 2.25),
    border: `2px solid ${theme.palette.customRed.progressPrimary}`,
    '&::after': {
      background: theme.palette.customWhite.main,
      bottom: theme.spacing(0.25),
    },
  },
}));

export const StyledStudentViewButton = styled(Button)(({ theme }) => ({
  fontWeight: 800,
  fontSize: theme.spacing(2.25),
  padding: theme.spacing(1.5, 5),
}));
