import React from 'react';
import { Box, Typography } from '@mui/material';
import { palette  } from '../../theme/palette';

interface Props {
  label: string
  description?:string,
  svgComponent?:object,
  customPadding?: boolean,
}

const NoResultsFirstPage = ({ label, description, svgComponent, customPadding } : Props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100%"
    border={`2px solid ${palette.customBackground.disabled}`}
    borderRadius="12px"
  >
    <Box display="grid" gridTemplateColumns="80% 20%" width="100%">
      <Box
        paddingY={9}
        paddingX={5}
      >
        <Typography variant='secondaryTitle' component='p'>{label}</Typography>
        <Typography variant='secondaryLabel'>{description}</Typography>
      </Box>
      <Box sx={{ display:'flex', justifyContent:'flex-end', ...(customPadding ? { pt: 4 } : { p:'12px 0 4px 0' }) }}>
        {svgComponent}
      </Box>
    </Box>
  </Box>
);

export default NoResultsFirstPage;
