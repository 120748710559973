/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CalendarIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    {...props}
  >
    <g>
      <path d="M0.46875 7.5H17.0312C17.2891 7.5 17.5 7.71094 17.5 7.96875V18.125C17.5 19.1602 16.6602 20 15.625 20H1.875C0.839844 20 0 19.1602 0 18.125V7.96875C0 7.71094 0.210938 7.5 0.46875 7.5ZM17.5 5.78125V4.375C17.5 3.33984 16.6602 2.5 15.625 2.5H13.75V0.46875C13.75 0.210938 13.5391 0 13.2812 0H11.7188C11.4609 0 11.25 0.210938 11.25 0.46875V2.5H6.25V0.46875C6.25 0.210938 6.03906 0 5.78125 0H4.21875C3.96094 0 3.75 0.210938 3.75 0.46875V2.5H1.875C0.839844 2.5 0 3.33984 0 4.375V5.78125C0 6.03906 0.210938 6.25 0.46875 6.25H17.0312C17.2891 6.25 17.5 6.03906 17.5 5.78125Z" fill={props.pathFill} />
    </g>

  </SvgIcon>
);

export default CalendarIcon;
