import React, { useContext } from 'react';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ScoringHeader from './ScoringHeader';
import GradingRubric from './GradingRubric';
import QuestionIcon from '../../../components/Icons/QuestionIcon';
import { ScoreContext } from '../score-context';
import {
  SubmissionStatus,
} from '../../../generated/graphql';
import ScoringSection from './ScoringSection';
import { StyledQuestionContainer, StylesCss } from '../ScoreTask.styled';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';

interface Props {
  taskType: string
}

const GradingRubricToolTip = () => {
  const { submission } = useContext(ScoreContext);
  const theme = useTheme();
  const Styles = StylesCss(theme);
  return (
    <StyledQuestionContainer>
      <Tooltip
        title={<GradingRubric scoringDetails={submission ? submission.scoring_details : []} />}
        sx={Styles.tooltip}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={15000}
      >
        <StyledQuestionContainer>
          <QuestionIcon />
        </StyledQuestionContainer>
      </Tooltip>
    </StyledQuestionContainer>
  );
};

const ScoringCardWithTooltip = ({ taskType }: Props) => {
  const { submission, scoreSummaryOptions, handleScoreUpdate, updating, anchorEnabled, handleAnchorToggleUpdate, updateToggle } = useContext(ScoreContext);
  return (
    <StyledFlexColBoxContainer sx={{ position: 'relative' }}>
      <ScoringHeader
        title={`${taskType} Scores`}
        showInfo
        headerAction={<GradingRubricToolTip />}
        anchorToggle={handleAnchorToggleUpdate}
        anchorEnabled={anchorEnabled}
        updateAction={updating || updateToggle}
      />
      <ScoringSection
        submissionScores={submission?.submission_type_scores?.find((item) => item?.submission_type === taskType)}
        radioOptions={scoreSummaryOptions}
        handleScoreUpdate={handleScoreUpdate}
        disableSelection={submission?.status !== SubmissionStatus.Submitted}
        skillType={taskType}
      />
    </StyledFlexColBoxContainer>
  );
};

export default ScoringCardWithTooltip;
