import React, { useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Typography } from '@mui/material';
import { CustomFormControlLabel, DialogTitleTypography } from '../TeacherBenchmarks/Grade/BenchmarkDownload.styled';
import WarningIcon from '../../components/Icons/WarningIcon';
import { palette } from '../../theme/palette';

type Props = {
  openModal: boolean,
  handleClose: () => void,
  handleNext: () => void,
  isBenchmarksPage?: boolean,
  setTimerActive: Function,
};
const ConfirmationScoreTaskModal = ({ openModal, handleClose, handleNext, isBenchmarksPage = false, setTimerActive }: Props) => {

  const [checked, setChecked] = useState(false);
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const handleModalClose = () => {
    if (checked)
      setTimerActive();

    handleClose();
  };
  return (
    <Dialog open={openModal} onClose={handleModalClose} sx={{ '.MuiPaper-root.MuiDialog-paper': { overflow: 'hidden ', margin: '32px', maxWidth: '1000px', width: '720px' } }}>
      <DialogTitle sx={{}}>
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <WarningIcon colorFill={palette.customBlue.primaryBlue} fontSize="large" />
        </Box >
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt={3}>
          <DialogTitleTypography>Are you ready to submit?</DialogTitleTypography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{
          color: palette.customBlue.chipDark,
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: 'normal',
        }}>You will not be able to edit your scores or feedback after submitting. Are you sure you are ready to submit your scores?</Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }} >
        <Box>
          <FormGroup>
            <CustomFormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  name="customCheckbox"
                  sx={{ color: palette.customGrey.iconGrade }}
                />
              }
              label="Snooze for 15 minutes"
            />
          </FormGroup>
        </Box>
        <Box>
          <Button
            variant="outlined"
            onClick={() =>{
              handleModalClose();
              handleNext();
            }}
          >
            Yes, Submit
          </Button>
          <Button sx={{ marginLeft: 1 }} onClick={handleModalClose} variant="contained" >
            Stay on page
          </Button>
        </Box>
      </DialogActions>
    </Dialog >
  );
};
export default ConfirmationScoreTaskModal;
