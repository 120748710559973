import { useContext, useEffect, useState } from 'react';
import {
  ClassResourcesCount,
  DistrictResourcesCount,
  SchoolResourcesCount,
  StudentResourcesCount,
  TeacherResourcesCount,
  useGetResourceCountByDistrictQuery,
  useGetResourceCountBySchoolQuery,
  useGetResourceCountBySectionQuery,
  useGetResourceCountByStudentQuery,
  useGetResourceCountByTeacherQuery,
  useMySchoolQuery,
} from '../../generated/graphql';
import { TabRouteEntities, TabsHeadings } from '../../variables/types';
import useRole from '../../hooks/useRole';
import { SearchContext } from '../../core/searchContext';
import usePagination from '../../utils/usePagination';


interface Props {
  sectionType: string;
  isExternalSource?: boolean;
  schoolID?: string;
  districtID?: string;
  isMyDistrict?: boolean;
  isMySchool?: boolean;
  id?: string;
  entityResources?: DistrictResourcesCount | TeacherResourcesCount | StudentResourcesCount | ClassResourcesCount | SchoolResourcesCount;
}
interface TabLabels {
  tab : number,
  label: TabsHeadings,
  section?: string | null,
  partOf?: string[],
  count?: number,
}

const tabsLabel: TabLabels[] = [
  {
    tab: 0,
    label: TabsHeadings.Schools,
    partOf: [ TabRouteEntities.Districts, TabRouteEntities.DistrictAdmins ],
    section: 'schools',
  },
  {
    tab: 1,
    label: TabsHeadings.Classes,
    section: 'classes',
    partOf: [
      TabRouteEntities.Districts,
      TabRouteEntities.DistrictAdmins,
      TabRouteEntities.SchoolAdmins,
      TabRouteEntities.Schools,
      TabRouteEntities.Teachers, TabRouteEntities.Students,
    ],
  },
  {
    tab: 2,
    label: TabsHeadings.Teachers,
    partOf: [
      TabRouteEntities.Districts,
      TabRouteEntities.DistrictAdmins,
      TabRouteEntities.SchoolAdmins,
      TabRouteEntities.Schools,
    ],
    section: 'teachers',
  },
  {
    tab: 3,
    label: TabsHeadings.Students,
    partOf: [
      TabRouteEntities.Districts,
      TabRouteEntities.DistrictAdmins,
      TabRouteEntities.SchoolAdmins,
      TabRouteEntities.Schools,
      TabRouteEntities.Classes,
      TabRouteEntities.Teachers,
    ],
    section: 'students',
  },
  {
    tab: 4,
    label: TabsHeadings.Groups,
    partOf: [ TabRouteEntities.Classes ],
    section: 'groups',
  },
  {
    tab: 5,
    label: TabsHeadings.SchoolAdmins,
    section: 'school-admin',
    partOf: [TabRouteEntities.Districts, TabRouteEntities.Schools, TabRouteEntities.DistrictAdmins],
  },
  {
    tab: 6,
    label: TabsHeadings.DistrictAdmins,
    section: 'district-admin',
    partOf: [TabRouteEntities.Districts],
  },
];


const useTabs = ({ sectionType, id, schoolID, districtID, isExternalSource, isMyDistrict, isMySchool, entityResources }: Props) => {
  const { isSchoolAdmin } = useRole();
  const pagination = usePagination();
  const selectedSectionType = isMyDistrict && isSchoolAdmin || isMySchool ? TabRouteEntities.Schools : sectionType;
  const selectedTabItem = tabsLabel.filter(t => t.partOf?.includes(selectedSectionType))[0];
  const [tab, setTab] = useState(selectedTabItem?.tab ?? 0);
  const [allTabs] = useState(tabsLabel?.filter(tt => tt.partOf?.includes(sectionType)));
  const [selectedTabType, setSelectedTabType] = useState<TabsHeadings>(selectedTabItem.label ?? TabsHeadings.Schools);
  const { setTabSection } = useContext(SearchContext);
  const { data: schoolDetails, loading: schoolDetailsLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: !isSchoolAdmin,
  });

  if (sectionType === TabRouteEntities.Districts && isExternalSource) {
    const index = allTabs.findIndex(at => at.label === TabsHeadings.Syncs);
    if (index === -1) {
      allTabs.push({
        tab: 7,
        label: TabsHeadings.Syncs,
        section: 'syncs',
        partOf: [ TabRouteEntities.Districts ],
      });
    }
  }

  if (isMyDistrict || isMySchool) {
    const schoolAdminIndex = allTabs.findIndex(at => at.label === TabsHeadings.DistrictAdmins);
    if (schoolAdminIndex !== -1) {
      allTabs.splice(schoolAdminIndex, 1);
    }
  }

  if (isMyDistrict && isSchoolAdmin || isMySchool) {

    const schoolAdminIndex2 = allTabs.findIndex(at => at.label === TabsHeadings.SchoolAdmins);
    if (schoolAdminIndex2 !== -1) {
      allTabs.splice(schoolAdminIndex2, 1);
    }
    const schoolAdminIndex3 = allTabs.findIndex(at => at.label === TabsHeadings.Schools);
    if (schoolAdminIndex3 !== -1) {
      allTabs.splice(schoolAdminIndex3, 1);
    }
  }


  const setSearchQueryValue = () => {
    const getTab = allTabs.find(t => t.tab === tab);
    const paramValue = getTab?.section;
    setTabSection?.(paramValue ?? '');
  };

  useEffect(() => {
    setSearchQueryValue();
  }, [tab]);

  const handleTabChange = (_: React.ChangeEvent<{}>, newTab: number) => {
    const getTab = allTabs.find(t => t.tab === newTab);
    setTab(getTab?.tab ?? 0);
    setSelectedTabType(getTab?.label ?? TabsHeadings.Schools);
    setSearchQueryValue();
    pagination.resetPage();
  };

  const { data: districtResources } = useGetResourceCountByDistrictQuery({
    fetchPolicy: 'network-only',
    variables: {
      districtId: districtID ?? id!,
    },
    skip: (
      sectionType !== TabRouteEntities.Districts &&
      sectionType !== TabRouteEntities.DistrictAdmins
    ) || (!districtID || !id) || isSchoolAdmin || !!entityResources,
  });

  const { data: teacherResources } = useGetResourceCountByTeacherQuery({
    fetchPolicy: 'network-only',
    variables: {
      teacherId: id!,
      schoolId: (isSchoolAdmin && !schoolDetailsLoading) ? schoolDetails?.mySchool?.id : undefined,
    },
    skip: sectionType !== TabRouteEntities.Teachers || !!entityResources,
  });

  const { data: studentResources } = useGetResourceCountByStudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      studentId: id!,
    },
    skip: sectionType !== TabRouteEntities.Students || !!entityResources,
  });

  const { data: sectionResource } = useGetResourceCountBySectionQuery({
    fetchPolicy: 'network-only',
    variables: {
      sectionId: id!,
    },
    skip: sectionType !== TabRouteEntities.Classes || !!entityResources,
  });

  const { data: schoolResource } = useGetResourceCountBySchoolQuery({
    fetchPolicy: 'network-only',
    variables: {
      schoolId: schoolID ?? id!,
    },
    skip: (
      sectionType !== TabRouteEntities.Schools &&
      sectionType !== TabRouteEntities.SchoolAdmins &&
      (sectionType !== TabRouteEntities.Districts && !isSchoolAdmin)
    ) || (!schoolID || !id) || !!entityResources,
  });

  const resources: any = !!entityResources ? entityResources : (
    districtResources?.resourceCountByDistrict
    || teacherResources?.resourceCountByTeacher
    || studentResources?.resourceCountByStudent
    || sectionResource?.resourceCountByClass
    || schoolResource?.resourceCountBySchool
  );
  allTabs.forEach(tb => {
    if (tb.label === TabsHeadings.Schools) {
      tb.count = resources?.school_count;
    } else if (tb.label === TabsHeadings.Classes) {
      tb.count = resources?.class_count;
    } else if (tb.label === TabsHeadings.Teachers) {
      tb.count = resources?.teacher_count;
    } else if (tb.label === TabsHeadings.SchoolAdmins) {
      tb.count = resources?.school_admin_count;
    } else if (tb.label === TabsHeadings.DistrictAdmins) {
      tb.count = resources?.district_admin_count;
    } else if (tb.label === TabsHeadings.Students) {
      tb.count = resources?.student_count;
    } else if (tb.label === TabsHeadings.Syncs) {
      tb.count = resources?.sync_count;
    } else if (tb.label === TabsHeadings.Groups) {
      tb.count = resources?.group_count;
    }
  });

  return ({
    tab, handleTabChange, allTabs, selectedTabType, selectedTabLabel: selectedTabItem.section,
  });
};

export default useTabs;