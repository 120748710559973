/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SliderNavIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    {...props}
  >
    <path d="M10.9017 9.41268L3.31012 17.0042C2.94399 17.3703 2.35039 17.3703 1.9843 17.0042L1.09887 16.1188C0.733363 15.7533 0.732659 15.1609 1.09731 14.7945L7.11375 8.74975L1.09731 2.70503C0.732659 2.33866 0.733362 1.74628 1.09887 1.38077L1.9843 0.49534C2.35043 0.129208 2.94403 0.129208 3.31012 0.49534L10.9017 8.08682C11.2678 8.45296 11.2678 9.04655 10.9017 9.41268Z" fill={props.pathFill} />
  </SvgIcon>
);

export default SliderNavIcon;
