/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const VocabularyAndGrammarIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="48"
    height="55"
    viewBox="0 0 48 55"
    fill="none"
    {...props}
  >
    <g>
      <path d="M46.2829 51.3423H46.6359C47.3419 51.3423 47.9195 51.9199 47.9195 52.6258V53.4816C47.9195 54.1875 47.3419 54.7651 46.6359 54.7651H8.55705C3.82928 54.7651 0 50.9358 0 46.2081V8.55705C0 3.82928 3.82928 0 8.55705 0H45.3524C46.775 0 47.9195 1.14451 47.9195 2.56711V41.9295C47.9195 42.9992 47.2563 43.9191 46.3257 44.3041C45.9407 46.0262 45.8551 49.1816 46.2829 51.3423ZM13.6913 41.0738H44.4967V3.42282H13.6913V41.0738ZM3.42282 42.7852C4.85612 41.7156 6.63171 41.0738 8.55705 41.0738H10.2685V3.42282H8.55705C5.72253 3.42282 3.42282 5.72253 3.42282 8.55705V42.7852ZM43.2452 51.3423C42.9136 49.1602 42.935 46.5076 43.2452 44.4967H8.55705C1.71141 44.4967 1.71141 51.3423 8.55705 51.3423H43.2452Z" fill={palette.fontColors.fontBlack} />
      <path d="M25.2038 27.9052L19.9428 22.7774C19.5638 22.4079 19.556 21.8012 19.9254 21.4221L21.0051 20.3145C21.3751 19.9349 21.9829 19.9277 22.3617 20.2984L25.1423 23.0195C25.5204 23.3896 26.127 23.3832 26.4972 23.0052L34.9273 14.3995C35.2971 14.0221 35.9025 14.0151 36.2809 14.3839L37.3589 15.4346C37.7379 15.804 37.7457 16.4108 37.3762 16.7898L26.559 27.8879C26.1896 28.2669 25.5828 28.2747 25.2038 27.9052Z" fill={palette.fontColors.fontBlack} />
    </g>
  </SvgIcon>
);

export default React.memo(VocabularyAndGrammarIcon);
