import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import getBrowserImageDimensions from 'browser-image-dimensions';
import {
  CreateAdminResourceInput,
  StaticResourceType,
  useAdminResourceMutation,
  useStaticResourcesQuery,
  useSubjectAreasQuery,
} from '../../../generated/graphql';
import { useUploader } from '../../../utils/multipart-uploader';
import { getToken } from '../../../utils/auth';
import getErrorMessage from '../../../utils/getErrorMessage';
import {
  FILE_REQUIRED_ERROR,
  IMAGE_RES_ERROR,
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  THUMBNAIL_MAX_FILE_SIZE_ERROR,
  THUMBNAIL_MAX_FILE_SIZE_KB,
  IMAGE_ERROR,
  UPLOADING_IMAGE_FAILED,
} from '../../../variables/constant';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType, SizeMetrics } from '../../../variables/types';
import gradeSort from '../../Assignments/CreateAssignment/grade-sort';
import UploadImageIcon from '../../../components/Icons/UploadImageIcon';
import { checkFileSize } from '../../../utils/files';
import useThumbnailUtil, { ThumbnailGenInputType } from '../../../utils/useThumbnailUtil';
import { sentryErrorLog } from '../../../utils/sentry';

interface ImageUploadFormData {
  title: string,
  file: HTMLInputElement,
  thumbnailFile?: HTMLInputElement,
  grades: string[],
  subject_areas: string[],
  task_types: string[],
  keywords: string[],
}

const useUpload = () => {
  const [imageFile, setImageFile] = useState([]);
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [gradeValue, setGradeValue] = useState([]);
  const [showWarning, setShowWarning] = useState('');
  const [showThumbnailWarning, setShowThumbnailWarning] = useState('');
  const [open, setOpen] = useState(false);
  const [openThumbnail, setOpenThumbnail] = useState(false);
  const [updateResource, { loading }] = useAdminResourceMutation();
  const [createUpdateLoader, setCreateUpdateLoader] = useState(false);
  const { data: subjectAreas, loading: subjectsLoading } = useSubjectAreasQuery({
    fetchPolicy: 'network-only',
  });
  const routePath = window.location.href.indexOf('benchmarks') > -1;
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
  } = useForm<ImageUploadFormData>();

  const { data: staticResourcesImageData, refetch, loading: imagesLoading } = useStaticResourcesQuery({
    fetchPolicy: 'network-only',
    variables:  {
      resourceType: routePath ?  StaticResourceType.Benchmark : StaticResourceType.Regular,
    },
  });

  const setGrades = gradeSort(staticResourcesImageData);
  // Image Handlers
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles:any) => {
      setImageFile(acceptedFiles.map((file: any) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
    },
  });
  const remove = (file: any) => {
    const newFiles = [...imageFile];
    newFiles.splice(file, 1);
    setImageFile(newFiles);
    setShowWarning('');
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Thumbnail Handlers
  const { getRootProps: getThumbnailRootProps, getInputProps: getThumbnailInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      setThumbnailFile(acceptedFiles.map((file: any) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
    },
  });
  const removeThumbnail = (file: any) => {
    const newFiles = [...thumbnailFile];
    newFiles.splice(file, 1);
    setThumbnailFile(newFiles);
    setShowThumbnailWarning('');
  };
  const handleOpenThumbnail = () => {
    setOpenThumbnail(!openThumbnail);
  };
  // Thumbnail Generator
  const { thumbnailGenAsync } = useThumbnailUtil({
    // @ts-ignore
    thumbnailUrl: `${import.meta.env.REACT_APP_API_URL}/file/thumbnail`,
    accessToken: getToken()?.idToken?.jwtToken as string,
  });

  // File Uploader
  const { uploadAsync, isLoading } = useUploader({
    // @ts-ignore
    uploadUrl: `${import.meta.env.REACT_APP_API_URL}/file/upload`,
    accessToken: getToken()?.idToken?.jwtToken,
  });

  const uploadResource = async (data : ImageUploadFormData) => {
    setCreateUpdateLoader(true);
    const imageErrors: string[] = [];
    try {
      const imageDetails: any = await getBrowserImageDimensions(imageFile?.[0]);
      if (imageDetails && imageDetails?.height !== IMAGE_HEIGHT && imageDetails?.width !== IMAGE_WIDTH) {
        imageErrors.push(IMAGE_RES_ERROR);
      }

      let thumbnailDetails: any;
      // validate thumbnail image.
      if (thumbnailFile.length){
        if (!checkFileSize(SizeMetrics.KB, THUMBNAIL_MAX_FILE_SIZE_KB, thumbnailFile?.[0] as File)) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          imageErrors.push(THUMBNAIL_MAX_FILE_SIZE_ERROR);
        } else {
          thumbnailDetails = await getBrowserImageDimensions(thumbnailFile?.[0]);
        }
      }
      if (imageErrors.length || !imageFile) throw new Error(IMAGE_ERROR);
      const dataResult = await uploadAsync(imageFile?.[0] as File);
      const staticResourceInput: CreateAdminResourceInput = {
        title: data?.title as string,
        grades: data?.grades,
        image_height: IMAGE_HEIGHT,
        image_width: IMAGE_WIDTH,
        file: {
          url: dataResult?.url,
          // @ts-ignore
          file_type: imageFile?.[0]?.type,
          file_key: dataResult?.key,
        },
        subject_areas: data?.subject_areas,
        task_types: data?.task_types,
        keywords: data?.keywords,
        resourceType: routePath ?  StaticResourceType.Benchmark : StaticResourceType.Regular,
      };
      // thumbnail image
      if (thumbnailFile.length) {
        if (thumbnailDetails) {
          staticResourceInput.thumbnail_height = thumbnailDetails?.height as number ?? 0;
          staticResourceInput.thumbnail_width = thumbnailDetails?.width as number ?? 0;
        }
        const thumbnailResult = await uploadAsync(thumbnailFile?.[0] as File);
        staticResourceInput.file.thumbnail_url = thumbnailResult?.url;
        staticResourceInput.file.thumbnail_file_key = thumbnailResult?.key;
        // @ts-ignore
        staticResourceInput.file.thumbnail_file_type = thumbnailFile?.[0]?.type ?? '';
      } else if (staticResourceInput.file.url) {
        const thumbnailGeneratorInput: ThumbnailGenInputType = {
          url: staticResourceInput.file.url,
          file_name: staticResourceInput.file.file_key,
          file_type: staticResourceInput.file.file_type,
        };
        const thumbnailResult = await thumbnailGenAsync(thumbnailGeneratorInput);
        staticResourceInput.file.thumbnail_url = thumbnailResult?.url;
        staticResourceInput.file.thumbnail_file_key = thumbnailResult?.file_key;
        staticResourceInput.file.thumbnail_file_type = thumbnailResult?.file_type;
      }
      const updateResponse = await updateResource({
        variables: {
          input: { ...staticResourceInput },
        },
      });
      if (updateResponse?.data) {
        openSnackbar({
          message: `The ${data?.title} image was added!`,
          customIcon: UploadImageIcon,
        }, NotifierType.Success);
        reset?.();
        remove(imageFile);
        removeThumbnail(thumbnailFile);
        await refetch();
      }
    } catch (err) {
      sentryErrorLog(err);
      const errMSG = getErrorMessage(err);
      if (errMSG === IMAGE_ERROR) {
        openSnackbar({ message: imageErrors }, NotifierType.Error);
      } else {
        openSnackbar({ message: imageFile.length ? UPLOADING_IMAGE_FAILED : FILE_REQUIRED_ERROR }, NotifierType.Error);
      }
    }
    setCreateUpdateLoader(false);
  };

  return {
    imageFile,
    setImageFile,
    thumbnailFile,
    setThumbnailFile,
    gradeValue,
    setGradeValue,
    getRootProps,
    getInputProps,
    getThumbnailRootProps,
    getThumbnailInputProps,
    remove,
    removeThumbnail,
    showWarning,
    setShowWarning,
    showThumbnailWarning,
    setShowThumbnailWarning,
    handleOpen,
    handleClose,
    handleOpenThumbnail,
    open,
    setOpen,
    openThumbnail,
    setOpenThumbnail,
    setGrades,
    uploadImage: uploadResource,
    loading: loading || isLoading || imagesLoading || createUpdateLoader,
    register,
    errors,
    handleSubmit,
    control,
    getValues,
    watch,
    subjectAreas: subjectAreas?.subjectArea,
    subjectsLoading,
  };
};

export default useUpload;
