import { palette  } from '../palette';

const Form = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: palette.customRed.main,
        fontSize: 16,
        fontWeight: 600,
        marginLeft: 0,
        marginTop: 10,
        lineHeight: '20px',
      },
    },
  },
};

export default Form;
