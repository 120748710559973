import { useEffect, useState } from 'react';

import { useTeacherBenchmarksDashboardContext } from '../../../teacherbenchmarks-dashboard-hook';
import {
  BenchmarkType,
  OrderBy,
  SubmissionStatus,
  SubmissionStatusSlideoutDataInput,
  SubmissionStatusSlideoutLineItem,
  SubmissionStatusSlideoutSortType,
  useSubmissionStatusSlideoutQuery,
} from '../../../../../generated/graphql';
import { pieChartData } from '../../OverallPerformanceWidget/overallperformance.hooks';
import { trackEvent } from '../../../../../utils/trackevent';
import { SLIDEOUT_ROWS } from '../../../../../variables/constant';


type Props = {
  data: pieChartData | undefined,
  benchmarkType?: BenchmarkType,
  setTotalSubmissionsCountByOption?: Function,
};

const useSubmissionSlideOut = ({ data, benchmarkType, setTotalSubmissionsCountByOption }: Props) => {
  const [selectedOption, setSelectedOption] = useState('all');
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(OrderBy.Asc);
  const [sort, setSort] = useState<SubmissionStatusSlideoutSortType>(SubmissionStatusSlideoutSortType.StudentFullName);
  const handleChange = (event: any) => {
    const eventValue = event.target.value;
    trackEvent(`rb_sub_${eventValue.toLowerCase()}`, `radio_buttons_submission_status_${eventValue.toLowerCase()}_click`);
    setSelectedOption(event.target.value);
  };
  const [slideOutData, setSlideOutData] = useState<SubmissionStatusSlideoutLineItem[] | null>();
  const { selectedGrades, selectedClasses, selectedSchools, skipGraphQLRequest } = useTeacherBenchmarksDashboardContext();
  const [totalCount, setTotalCount] = useState<number>(0);
  var queryInput: SubmissionStatusSlideoutDataInput = {
    benchmark_type: benchmarkType!,
    section_ids: selectedClasses ? selectedClasses : [],
    school_ids: selectedSchools ? selectedSchools : [],
    grade_ids: selectedGrades ? selectedGrades : [],
    submission_status: selectedOption === 'all' ? undefined : selectedOption as SubmissionStatus,
    sort_by: sort,
    order_by: order,
    page: page,
    limit: SLIDEOUT_ROWS,
  };

  const { data: submissionStatusSlideOutData, loading: submissionStatusSlideOutDataLoading, refetch: refetchsubmissionStatusSlideOutData } = useSubmissionStatusSlideoutQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });
  useEffect(() => {
    setSlideOutData([]);
    setPage(1);
    refetchsubmissionStatusSlideOutData();
  }, [selectedOption, order, sort]);

  useEffect(() => {
    if (!submissionStatusSlideOutDataLoading && submissionStatusSlideOutData) {
      setTotalSubmissionsCountByOption?.(submissionStatusSlideOutData?.submissionStatusSlideout?.total_count || 0);
      setTotalCount(submissionStatusSlideOutData?.submissionStatusSlideout?.total_count);
      if (slideOutData && slideOutData?.length > 0) {
        setSlideOutData([...slideOutData, ...submissionStatusSlideOutData?.submissionStatusSlideout?.slideout_line_items]);
      } else {
        setSlideOutData(submissionStatusSlideOutData?.submissionStatusSlideout?.slideout_line_items);
      }
    }
  }, [submissionStatusSlideOutData, submissionStatusSlideOutDataLoading]);


  const onSortChange = (sortBy: SubmissionStatusSlideoutSortType, orderBy: OrderBy) => {
    setSlideOutData([]);
    setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handleSort = (column: SubmissionStatusSlideoutSortType) => () => {
    if (column === queryInput?.sort_by) {
      onSortChange(column, queryInput?.order_by === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };


  return {
    selectedOption,
    setSelectedOption,
    handleChange,
    order, setOrder,
    submissionStatusSlideOutData,
    submissionStatusSlideOutDataLoading,
    slideOutData : slideOutData || [],
    sort,
    handleSort,
    page,
    setPage,
    totalCount,
  };
};
export default useSubmissionSlideOut;