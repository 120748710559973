import {
  useDashboardDataQuery,
  UserPermissions,
} from '../../generated/graphql';
import usePermission from '../../hooks/usePermission';

const useDashboard = () => {
  const { data, loading } = useDashboardDataQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { hasPermission: districtPermission } = usePermission([
    UserPermissions.ListDistricts,
    UserPermissions.ViewDistricts,
  ]);

  const { hasPermission: schoolPermission } = usePermission([
    UserPermissions.ListSchools,
    UserPermissions.ViewSchools,
  ]);

  const { hasPermission: classPermission } = usePermission([
    UserPermissions.ListClasses,
    UserPermissions.ViewClasses,
  ]);

  const { hasPermission: studentPermission } = usePermission([
    UserPermissions.ListStudents,
    UserPermissions.ViewStudents,
  ]);

  const { hasPermission: teacherPermission } = usePermission([
    UserPermissions.ListTeachers,
    UserPermissions.ViewTeachers,
  ]);

  const { hasPermission: myDistrictPermission } = usePermission([
    UserPermissions.MyDistrict,
  ]);

  const { hasPermission: mySchoolPermission } = usePermission([
    UserPermissions.MySchool,
  ]);

  const { hasPermission: schoolAdminPermission } = usePermission([
    UserPermissions.ListSchoolAdmins,
    UserPermissions.ViewSchoolAdmins,
  ]);

  const { hasPermission: districtAdminPermission } = usePermission([
    UserPermissions.ListDistrictAdmins,
    UserPermissions.ViewDistrictAdmins,
  ]);

  return {
    dataLoading: loading,
    dashboardData: data?.dashboardData,
    districtPermission,
    districtAdminPermission,
    schoolPermission,
    schoolAdminPermission,
    teacherPermission,
    classPermission,
    studentPermission,
    myDistrictPermission,
    mySchoolPermission,
  };
};

export default useDashboard;
