import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import List from './List';
import useSuperAdmins from './superadmin-hook';
import { adminToolsRoute, superAdminRoute } from '../../../../variables/staticRoutes';
import AppBreadcrumbs, { BreadcrumbItem } from '../../../../components/AppBreadcrumbs';
const SuperAdminTable = () => {
  const {
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    superAdmins,
    rowsPerPage,
    setRowsPerPage, 
  } = useSuperAdmins();

  const breadcrumb: BreadcrumbItem[] = [
    adminToolsRoute,
    superAdminRoute,
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <AppBreadcrumbs breadcrumb={loading ? [] : breadcrumb} />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="pageTitle">
            Super Admins
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            disableRipple={true}
            component={Link}
            to="/settings/super-admin/create"
          >
            Add Super Admin
          </Button>
        </Box>
      </Box>

      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading}
          totalCount={totalCount}
          order={order}
          pagination={pagination}
          sort={sort}
          superAdmins={superAdmins}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
    </Box>
  );
};
export default SuperAdminTable;