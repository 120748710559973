import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledTableDataColumn } from '../../../../Admin/admin-tables-styled';
import { LearningGoalsStylesCSS } from '../../LearningGoals.styled';
import { PerformanceAndLearningGoalWidgetSlideOutLineItem } from '../../../../../generated/graphql';

interface Props {
  rowIndex?: number,
  student?: PerformanceAndLearningGoalWidgetSlideOutLineItem,
}

const ListRow = ({
  rowIndex,
  student,
}: Props) => {
  const theme = useTheme();
  const styles = LearningGoalsStylesCSS(theme);
  return (
    <TableRow key={rowIndex} sx={{
      ...styles.tableRowHover, ...styles.tableRowBorder,
    }}>
      <TableCell sx={{ ...styles.width20 }}>
        <Typography
          variant='secondaryLabel'
        >
          {student?.first_name} {student?.last_name}
        </Typography>
      </TableCell>
      <StyledTableDataColumn sx={styles.width5}>
        <Typography
          variant='secondaryLabel'
        >
          {student?.grade}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width25}>
        <Typography
          variant='secondaryLabel'
        >
          {student?.school_name}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width20}>
        <Typography
          component="span"
        >
          {student?.teacher_id === 'multiple' ? 'Multiple' : `${student?.teacher_first_name} ${student?.teacher_last_name}`}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width30}>
        <Typography
          component="span"
        >
          {student?.section_id === 'multiple' ? 'Multiple' : `${student?.section_name}`}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default ListRow;
