import React from 'react';

import { Typography, SxProps, TableContainer, Table, Paper, TableBody, TableRow, TableCell, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CreateClassStylesCss } from '../CreateClass.styled';

import useCreateClass from '../create-class-hook';

const Summary = () => {
  const theme = useTheme();
  const styles = CreateClassStylesCss(theme);

  const {
    routeData,
    createClassInput,
    selectedSchool,
    classDataFetched,
    teacherSchools,
  } = useCreateClass();

  return (
    <>
      {routeData?.id && (
        <Box sx={{ paddingX: 2 } as SxProps}>
          <Typography component='p'>
            Note: If you are adding students to an existing class, only active assignments and benchmarks will be reflected in the student’s dashboard.
            If you are removing students, this will also remove their assignments tied to their account.
          </Typography>
        </Box>
      )}
      <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
        <Table aria-label="class summary">
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } } as SxProps}
            >
              <TableCell component="th" scope="row">
                <Box display="flex">
                  <Typography variant='redirectionLinks'>
                    School:
                  </Typography>
                  <Typography sx={{ marginLeft: 1 } as SxProps}>
                    {selectedSchool?.name ?? teacherSchools?.find(school => school.id === createClassInput?.school_id)?.name}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={styles.summaryTableRow as SxProps}
            >
              <TableCell component="th" scope="row">
                <Box display="flex">
                  <Typography
                    variant='redirectionLinks'
                  >
                    Class Name:
                  </Typography>
                  <Typography sx={{ marginLeft: 1 } as SxProps}>
                    {createClassInput?.name}
                  </Typography>
                  {(classDataFetched && classDataFetched?.name !== createClassInput?.name) && 
                    <Typography variant='sFormHelper' sx={styles.summaryTableRowHelpText as SxProps}>(changed from {classDataFetched?.name})</Typography>}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={styles.summaryTableRow as SxProps}
            >
              <TableCell component="th" scope="row">
                <Box display="flex">
                  <Typography
                    variant='redirectionLinks'
                  >
                    Period:
                  </Typography>
                  <Typography sx={{ marginLeft: 1 } as SxProps}>
                    {createClassInput?.period === 0 ? 'none' : createClassInput?.period}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={styles.summaryTableRow as SxProps}
            >
              <TableCell component="th" scope="row">
                <Box display="flex">
                  <Typography
                    variant='redirectionLinks'
                  >
                    Grade(s):
                  </Typography>
                  <Typography sx={{ marginLeft: 1 } as SxProps}>
                    {createClassInput?.grades?.join(', ') ?? '-'}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={styles.summaryTableRow as SxProps}
            >
              <TableCell component="th" scope="row">
                <Box display="flex">
                  <Typography
                    variant='redirectionLinks'
                  >
                    Number Of Students:
                  </Typography>
                  <Typography sx={{ marginLeft: 1 } as SxProps}>
                    {createClassInput?.student_ids_to_add?.length ?? 0}
                  </Typography>
                  {(createClassInput?.student_ids_to_remove?.length! > 0) && 
                  <Typography variant='sFormHelper' sx={styles.summaryTableRowHelpText as SxProps}>({createClassInput?.student_ids_to_remove?.length} students removed)</Typography>}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );

};

export default Summary;