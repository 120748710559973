import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import React, { useContext, useEffect, useState } from 'react';
import { ROWS_PER_PAGE } from '../../../../variables/constant';
import { OrderBy, useListSuperAdminQuery } from '../../../../generated/graphql';
import usePagination from '../../../../utils/usePagination';
import { SuperAdminSort } from '../../../../variables/types';
import { SearchContext } from '../../../../core/searchContext';

const useSuperAdmins = () => {
  const pagination = usePagination();
  const { searchTerm } = useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, 'first_name'));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Asc));
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage]);

  const { data, loading } = useListSuperAdminQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: rowsPerPage,
      page: pagination.page,
      sort: sort,
      orderBy: order as OrderBy,
      ...(searchTerm && searchTerm.length > 2 ? { searchByName: searchTerm } : {}),

    },
  });

  const onSortChange = (sortBy: string, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const superAdmins = data?.superAdminList.nodes ?? [];
  const totalCount = data?.superAdminList.totalCount ?? 0;

  const isTableEmpty = !loading && superAdmins?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: SuperAdminSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  return {
    loading,
    totalCount,
    superAdmins,
    pagination,
    onSortChange,
    sort: sort as SuperAdminSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    rowsPerPage,
    data,
    setRowsPerPage,
  };
};

export default useSuperAdmins;
