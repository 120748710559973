import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDistrictQuery } from '../../../generated/graphql';
import { PUBLIC } from '../../../variables/constant';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import useUserInfo from '../../../utils/useUserInfo';

const useDistrictsList = () => {
  const [stateLoading, setLoading] = useState(false);
  const [isLoadingUserInfo] = useUserInfo();
  const history = useHistory();
  const { handleSubmit } = useForm();
  const [district, setDistrict] = useLocalStorageState('school-district', {});
  const { data, loading } = useDistrictQuery({
    context: { clientName: PUBLIC },
  });

  const districtList = data?.district ?? [];
  
  const handleRedirection = (id: string) => {
    if (id) {
      history.push(`/login/${id}`);
    } else {
      history.push('/login');
      setDistrict({});
    }
  };

  useEffect(() => {
    if (!loading) {
      // check if district stored in local storage available in loaded districts, if not clear the text field
      const isDistrictAvailable = districtList?.find(districtJson => districtJson?.name === district?.name);
      if (!isDistrictAvailable) {
        setDistrict({});
      }
    }
  }, [loading]);

  return {
    loading: loading || isLoadingUserInfo || stateLoading,
    districtList,
    handleRedirection,
    handleSubmit,
    district,
    setDistrict,
    setLoading,
  };
};

export default useDistrictsList;
