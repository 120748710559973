import React from 'react';
import { Box, Typography } from '@mui/material';

import OverallBenchmarkIndicatorScoreChart
  from '../../../../components/OverallBenchmarkIndicatorGraph/OverallBenchmarkIndicatorScore';
import useOverallBenchmarkIndicatorScore from './overall-benchmark-indicator-hook';
import { BenchmarkType } from '../../../../generated/graphql';
import { TeacherBenchmarkDashBoard } from '../../../../variables/constant';
import AppTooltip from '../../../../components/AppTooltip';
import StyledCard from '../../../../components/StyledCard';
import NoResults from '../../../../components/NoResults';
import EmptyBenchmarkResults from '../../../../components/Icons/EmptyBenchmarksResults';
import DonutChartLoader from '../../../../components/Loader/DonutChartLoader';
import { palette } from '../../../../theme/palette';
import ScoringRubric from '../../../../components/ScoringRubricChart';

interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  benchmarkType?: BenchmarkType,
}

const OverallBenchmarkIndicatorScores = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
  benchmarkType,
}: Props) => {
  const {
    benchmarkIndicatorScoreLoading,
    hasData,
    benchmarkIndicatorBarChartData,
    stackedBarDataColor,
    stackedBarData,
    scoreSchemes,
    yAxisDomain,
    yAxisTicks,
    scoreSchemeType,
  } = useOverallBenchmarkIndicatorScore({ selectedBenchmarkType: benchmarkType });
  const title = 'Overall Benchmark Indicator Scores';
  return (
    <>
      <Box id={TeacherBenchmarkDashBoard.BenchmarkSummary.OverallBenchmarkIndicatorWidget.name} sx={{ height: '100%', width: '100%' }}>
        <StyledCard
          title={title}
          columnSetting
          isToolTip={enableTooltip}
          titleAlignment={alignTitle ? 'left' : 'center'}
          overridePadding={16}
          toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        >
          {!benchmarkIndicatorScoreLoading && hasData && benchmarkIndicatorBarChartData?.length > 0 && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
            }}>
              <Box>
                <OverallBenchmarkIndicatorScoreChart
                  barData={benchmarkIndicatorBarChartData}
                  xAxisKey='rubricTitle'
                  barKey='rubricScoreAverage'
                  barWidth={500}
                  barHeight={350}
                  barSize={60}
                  yAxisDomain={yAxisDomain}
                  barRadius={[4, 4, 0, 0]}
                  yAxisTicks={yAxisTicks}
                />
              </Box>
              <Box sx={{ marginLeft: '0px', display: 'flex', flexDirection: 'column' }}>
                <Typography
                  paddingBottom={2}
                  paddingTop={2}
                  padding={2}
                  sx={{
                    fontSize: '18px',
                    fontWeight: 600,
                    color: palette.customBlack.fontBlack,
                  }}>Scoring Rubric</Typography>
                <ScoringRubric 
                  stackedBarData={stackedBarData} 
                  stackedBarDataColor={stackedBarDataColor}
                  scoreSchemes={scoreSchemes} 
                  yAxisDomain={yAxisDomain}
                  scoreSchemeType={scoreSchemeType}
                />
              </Box>
            </Box>
          )}

          {!benchmarkIndicatorScoreLoading && !hasData && (
            <NoResults
              description={TeacherBenchmarkDashBoard.BenchmarkSummary.OverallBenchmarkIndicatorWidget.insufficientData.description}
              svgComponent={<EmptyBenchmarkResults />}
            />
          )}
          {benchmarkIndicatorScoreLoading && <DonutChartLoader />}
        </StyledCard>
      </Box>
    </>
  );
};

export default OverallBenchmarkIndicatorScores;
