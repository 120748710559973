import { Box } from '@mui/material';
import React from 'react';
import useProgressScores from './progress-scores-hooks';
import List from './List';
import StyledCard from '../../../components/StyledCard';
import { TeacherBenchmarkDashBoard } from '../../../variables/constant';
import AppTooltip from '../../../components/AppTooltip';
import DebouncedSearchBar from '../../../components/DebounceSearchBar';
import TypeformEmbed from '../../../components/TypeformEmbed';
import { useImitationProvider } from '../../../core/imitationContext';


const ProgressScores = () => {
  const {
    progressScores, progressScoreDataLoading, 
    handlePageChange, handleSort, totalCount,
    handleSearch, order, sort,
    pagination, rowsPerPage,
    setRowsPerPage, searchTerm,
    typeformDetailsLoading,
    typeformData,
    onTypeformReady,
    onTypeformSubmit,
    userId,
    dismissTypeform,
    isFilterSelected,
  } = useProgressScores();

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();


  return (
    <Box sx={{ height: '100%', width: '100%', p: 4, backgroundColor: '#F7F7F7' }}>
      <StyledCard
        title={''}
        columnSetting
        isToolTip={true}
        titleAlignment={'left'}
        toolTipComponent={<AppTooltip toolTipText={TeacherBenchmarkDashBoard.ProgressScore.tooltipText} />}
        marginBottom='0px'
        overridePadding={'24px 24px 0 24px'}
      >
        <Box sx={{ width: '449px', alignSelf: 'flex-start', marginTop: '-16px' }}>
          <DebouncedSearchBar
            handleChange={handleSearch}
            searchTerm={searchTerm}
          />
        </Box>
        <Box sx={{ alignSelf: 'flex-start', width: '100%', marginTop: '-16px' }}>
          <List
            isTableEmpty={progressScores?.length === 0}
            loading={progressScoreDataLoading}
            totalCount={totalCount}
            order={order}
            pagination={pagination}
            sort={sort}
            data={progressScores}
            handlePageChange={handlePageChange}
            handleSort={handleSort}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
        {
          isNotImitated &&
          !typeformDetailsLoading &&
          progressScores &&
          isFilterSelected &&
          !progressScoreDataLoading &&
          !dismissTypeform &&
          typeformData?.show_typeform && <TypeformEmbed
            formId={typeformData?.typeform_form_id}
            typeformLayout='popover'
            onCloseHandler={onTypeformReady}
            onFormSubmittedHandler={onTypeformSubmit}
            hiddenButton={true}
            delay={2000}
            buttonText='Feedback Form'
            hiddenFields={{ user_id: userId }}
          />
        }

      </StyledCard >
    </Box>
  );
};
export default ProgressScores;