import { Theme, styled, Chip } from '@mui/material';

export const StyledGradeChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.fontColors.fontBlack,
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  '.MuiChip-label': {
    fontWeight: 600,
    fontSize: theme.spacing(2),
  },
  '&.MuiChip-colorPrimary': {
    '&.MuiChip-root': {
      color: theme.palette.customWhite.main,
    },
  },
}));

export const CreateAssignmentStylesCss = (theme: Theme) => ({
  saveButton: {
    width: 200,
    fontSize: 18,
    lineHeight: theme.spacing(3),
    fontWeight: 800,
  },
  inputInput: {
    '& .MuiInputBase-input': {
      background: 'none',
      border: 0,
    },
  },
  titleInputError: {
    color: theme.palette.customRed.main,
    fontSize: theme.spacing(2),
    fontWeight: 600,
  },
  fixedActionSection: {
    position: 'fixed',
    bottom: 0,
    background: theme.palette.customWhite.main,
    width: 'calc(100% - 242px)',
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1.5),
    border: `2px solid ${theme.palette.customBackground.disabled}`,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 140px)',
    margin: theme.spacing(-2),
    padding: theme.spacing(4, 0, 0, 2),
    backgroundColor: theme.palette.customGrey.lightGrey,
    overflowY: 'scroll',
  },
  formItemContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formLabelContainer: {
    padding: theme.spacing(4, 4, 0, 0),
  },
  formFieldContainer: {
    padding: theme.spacing(0, 4, 2, 0),
  },
  formImageContainer: {
    padding: theme.spacing(8, 2, 0, 8),
  },
  formatTopNavBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formBottomNavBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    width: 'calc(100% - 216px)',
    height: theme.spacing(10),
    marginLeft: theme.spacing(-4),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    background: theme.palette.customWhite.main,
    borderTop: `1px solid ${theme.palette.customBackground.disabled}`,
  },
  benchmarkFormBottomNavBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    width: 'calc(100% - 216px)',
    height: theme.spacing(10),
    marginLeft: theme.spacing(-4),
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(4),
    background: theme.palette.customWhite.main,
    borderTop: `1px solid ${theme.palette.customBackground.disabled}`,
  },
  backNavigationCheckbox: {
    paddingTop: theme.spacing(2),
  },
  checkboxLabel: {
    padding: theme.spacing(1),
  },
  btnBackToDashboard: {
    width: theme.spacing(30),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
  },
  disabled: {
    background: theme.palette.customWhite.whiteSmoke,
    borderRadius: theme.spacing(1.5),
    '& .MuiInputBase-input': {
      background: theme.palette.customWhite.whiteSmoke,
      border: 0,
      cursor: 'not-allowed',
      color: theme.palette.customBackground.disabledInputColor,
    },
  },
  datepickerInput:{
    '& .MuiInputBase-input.MuiOutlinedInput-input':{
      background: 'none',
      fontWeight: 800,
    },
  },
  editFormNavBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    width: 'calc(100% - 320px)',
    height: theme.spacing(10),
    marginLeft: theme.spacing(-4),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    background: theme.palette.customWhite.main,
    borderTop: `1px solid ${theme.palette.customBackground.disabled}`,
  },
});
