import React from 'react';
import { Alert, Box, Link, SxProps } from '@mui/material';
import useDistrict from './district-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import StateIcon from '../../../components/Icons/StateIcon';
import ResourceDetailHeader from '../../../components/ResourceDetailHeader';
import ResourceDetail from './resource-detail';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import {
  DistrictDeleteDistrictStatus,
  DistrictDeleteMessages,
  MY_DISTRICT,
  MY_SCHOOL,
  PROVIDER_TYPE,
} from '../../../variables/constant';
import { Entity } from '../../../variables/types';
import { getMountainTimeZone } from '../../../utils/timezone';
import AddNewEntity from '../../../components/AddNewEntity';
import useSyncJobList from '../../DistrictSync/DistrictSyncTable/synclog-list-hook';
import { DecoupleLogStatusType, SyncStatus } from '../../../generated/graphql';
import ListWithTabs from '../../InfoTables';
import CalendarIcon from '../../../components/Icons/CalendarIcon';
import AppBreadcrumbs, { BreadcrumbItem } from '../../../components/AppBreadcrumbs';
import { districtsRoute } from '../../../variables/staticRoutes';
import useSchool from '../../Schools/School/school-hook';
import DeleteDistrictBackdrop from './DeleteDistrictBackdrop';
import ArrowRight from '../../../components/Icons/ArrowRight';
import { FlashlightOn } from '@mui/icons-material';
import DecoupleViewDetailsModal from '../../../components/DecoupleViewDetailsModal';
import { palette } from '../../../theme/palette';

const District = () => {
  const {
    isSuperAdmin, isSchoolAdmin,
    loading,
    district, resourceCountByDistrict,
    editDistrictPermission,
    id,
    submitDeleteDistrictRequest,
    initiateDecoupleDistrict,
  } = useDistrict();

  const {
    school,
  } = useSchool();

  const [viewDecoupleDetails, setViewDecoupleDetails] = React.useState(false);

  const isDecoupleInProgress: boolean = !!district?.decouple_log?.status && district?.decouple_log?.status.toLowerCase().indexOf('progress') > 0;
  const isSyncInProgress: boolean = district?.sync_status === SyncStatus.InProgress;

  const { syncEnabled } = useSyncJobList();
  const breadcrumb: BreadcrumbItem[] = [
    districtsRoute,
    {
      label: district?.name ?? '',
      route: `${districtsRoute.route}/${district?.id}`,
    },
  ];
  var deleteDistrictMessage = DistrictDeleteMessages.Sucess;
  if (district?.delete_district_status === DistrictDeleteDistrictStatus.InProgress) {
    deleteDistrictMessage = DistrictDeleteMessages.InProgress;
  } else if (district?.delete_district_status === DistrictDeleteDistrictStatus.Failed) {
    if (district?.is_district_deleted) {
      deleteDistrictMessage = DistrictDeleteMessages.Failed;
    } else {
      deleteDistrictMessage = DistrictDeleteMessages.Error;
    }
  }


  const renderDeleteDistrictMessage = () => {
    return (
      <>
        {district?.delete_district_status && <Box my={2}>
          {district?.delete_district_status === DistrictDeleteDistrictStatus.InProgress &&
            <Alert severity="info">{deleteDistrictMessage}</Alert>}
          {district?.delete_district_status === DistrictDeleteDistrictStatus.Success &&
            <Alert severity="success">{deleteDistrictMessage}</Alert>}
          {district?.delete_district_status === DistrictDeleteDistrictStatus.Failed && (
            district?.is_district_deleted ?
              <Alert severity="error">{deleteDistrictMessage}</Alert> :
              <Alert severity="warning">{deleteDistrictMessage}</Alert>
          )}
        </Box>}
      </>
    );
  };

  const renderDecoupleMessage = () => {
    return (
      <>
        {isDecoupleInProgress && <Box my={2}>
          <Alert severity="info">Decoupling for the district is in progress. Current Status: {district?.decouple_log?.status}</Alert>
        </Box>}
      </>
    );
  };

  const handleViewDecoupleDetails = () => {
    setViewDecoupleDetails(true);
  };

  const closeViewDecoupleDetails = () => {
    setViewDecoupleDetails(false);
  };

  const ViewDetailsLink = ({ decoupledAt, status }: any) => {
    return (
      <>
        Decoupled At:  {getMountainTimeZone(decoupledAt, '-')}
        {' '}
        <Link color={status === DecoupleLogStatusType.Failed ? palette.customRed.deleteButton : palette.customBlue.primaryBlue} fontWeight='bold' underline='none' href={'#'} onClick={handleViewDecoupleDetails}>
          View Details
        </Link>
      </>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      {isSuperAdmin && <AppBreadcrumbs breadcrumb={loading ? [] : breadcrumb} />}
      <ResourceDetailHeader
        title={district?.name ?? ''}
        addNewEntity={editDistrictPermission ? (
          <AddNewEntity
            entity={district?.source !== PROVIDER_TYPE.FLASHLIGHT ? Entity.CleverDistrictNoSchool : Entity.District}
            districtId={district?.id}
            stateId={district?.state?.id}
            disabled={(district?.is_district_deleted || isDecoupleInProgress) ?? false}
          />
        ) : undefined
        }
        primaryBtnRedirectionLink={`/admin/create/district/${district?.id}`}
        syncNowRedirectionLink={district?.source !== PROVIDER_TYPE.FLASHLIGHT ? `/syncDistricts?districtId=${district?.id}` : undefined}
        syncNowEnabled={district?.is_disabled || syncEnabled || isSyncInProgress}
        syncNowShow={!(loading || district?.is_district_deleted || district?.is_disabled)}
        PrimaryBtnIcon={PencilIcon}
        primaryBtnLabel={editDistrictPermission ? 'Edit' : undefined}
        districtID={district?.id}
        resourceCountByDistrict={resourceCountByDistrict!}
        deleteDistrictShow
        deleteDistrictRequestHandler={submitDeleteDistrictRequest}
        decoupleDistrictHandler={initiateDecoupleDistrict}
        decoupleDistrictShow={district?.source === PROVIDER_TYPE.ROSTERSTREAM } //(district?.decouple_log && district?.decouple_log?.status === DecoupleLogStatusType.Failed)
        disableMoreOptions={isDecoupleInProgress || isSyncInProgress || district?.delete_district_status === DistrictDeleteDistrictStatus.InProgress}
        cognitoUsers={district?.cognito_pending_users}
        isDistrictDeleted={district?.is_district_deleted}
      />
      <Box my={2}>
        {renderDeleteDistrictMessage()}
        {renderDecoupleMessage()}
        <StyledBoxWrapper width="35%">
          <ResourceDetail label={district?.state?.name || '-'} Icon={StateIcon} />
          <ResourceDetail label={`District ID: ${district?.district_id || '-'}`} Icon={DistrictsIcon} />
          {(district?.source !== PROVIDER_TYPE.FLASHLIGHT || (district?.source_id && (district.decouple_log?.status === DecoupleLogStatusType.Failed || isDecoupleInProgress))) &&
            <ResourceDetail label={`Clever ID: ${district?.source_id || '-'}`} Icon={DistrictsIcon} />
          }
          <ResourceDetail label={`District NCES ID: ${district?.nces_id || '-'}`} Icon={DistrictsIcon} />
          <ResourceDetail label={`MDR Number: ${district?.mdr_number || '-'}`} Icon={DistrictsIcon} />
          {(district?.initialized_at || !!district?.source_id) && <>
            <ResourceDetail label={`Initialized At: ${getMountainTimeZone(district?.initialized_at, '-')}`} Icon={ArrowRight} />
            <ResourceDetail label={`Updated At: ${getMountainTimeZone(district?.updated_at, '-')}`} Icon={CalendarIcon} />
          </>
          }
          {
            (district?.decouple_log && !isDecoupleInProgress) && <>
              {
                district?.decouple_log?.status === DecoupleLogStatusType.Failed && <ResourceDetail
                  colorText={palette.customRed.deleteButton}
                  label={<ViewDetailsLink decoupledAt={district.decouple_log?.updated_at} status={district?.decouple_log?.status}/>}
                  Icon={FlashlightOn}/>
              }
              {
                district?.decouple_log?.status === DecoupleLogStatusType.Success && <ResourceDetail
                  label={<ViewDetailsLink decoupledAt={district.decouple_log?.updated_at}/>}
                  Icon={FlashlightOn}/>
              }
            </>
          }
        </StyledBoxWrapper>
        <Box sx={{ position: 'relative' } as SxProps}>
          <DeleteDistrictBackdrop
            enable={(district?.is_district_deleted || isDecoupleInProgress) ?? false}
            description={isDecoupleInProgress ? 'Decouple for the district is in progress...' : deleteDistrictMessage} />
          <ListWithTabs
            sectionType={'districts'}
            districtID={district?.id}
            isExternalSource={!loading && Boolean(district?.source !== PROVIDER_TYPE.FLASHLIGHT)}
            isMyDistrict={id === MY_DISTRICT}
            isMySchool={!(id !== MY_SCHOOL)}
            schoolID={isSchoolAdmin ? school?.id : undefined}
            entityResources={resourceCountByDistrict!}
          />
        </Box>
      </Box>
      <Loader open={loading} />
      <DecoupleViewDetailsModal
        openDialog={viewDecoupleDetails}
        handleClose={closeViewDecoupleDetails}
        decoupleLog={district?.decouple_log}/>
    </Box>
  );
};

export default React.memo(District);
