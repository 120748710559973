import { palette  } from '../palette';

const Input =  {
  MuiInput: {
    styleOverrides: {
      root: {
        fontSize: 18,
        fontWeight: 800,
        letterSpacing: 0.1,
        color: palette.fontColors.fontBlack,
        width: '100%',
        height: 48,
        border: `2px solid ${palette.customRed.progressPrimary}`,
        borderWidth: '2px 2px 4px',
        borderRadius: 12,
      },
      underline: {
        '&::before, &::after': {
          border: 'none !important',
        },
        '&:hover': {
          '&:not(.Mui-disabled)': {
            '&::before': {
              border: 'none',
            },
          },
        },
      },
      input: {
        color: palette.fontColors.fontBlack,
        fontSize: 16,
        fontWeight: 800,
        letterSpacing: 0.1,
        paddingLeft: 14,
      },
      formControl: {
        'label + &': {
          marginTop: '0',
        },
      },
    },
  },
};

export default Input;
