import React from 'react';
import { Box } from '@mui/material';
import { palette } from '../../theme/palette';
import { Global, StyledBoxYTicks, StyledSpanXTick, StyledSpanYTick, styles } from './CommonStyles';

const GoalAchievementLoader = () => {
  return (
    <Box sx={{ ...styles.container }}>
      <Global/>
      <Box
        sx={{ ...styles.main }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            sx={{ ...styles.fakeLines }}
          >
            <StyledBoxYTicks />
            <StyledBoxYTicks />
            <StyledBoxYTicks />
            <StyledBoxYTicks />
            <StyledBoxYTicks />
            <StyledBoxYTicks />
            <StyledBoxYTicks />
          </Box>
          <Box sx={{ ...styles.yAxis, ...styles.fadify }}>
            <StyledSpanYTick sx={{ ...styles.delay1 }}/>
            <StyledSpanYTick sx={{ ...styles.delay2 }}/>
            <StyledSpanYTick sx={{ ...styles.delay3 }}/>
            <StyledSpanYTick sx={{ ...styles.delay4 }}/>
            <StyledSpanYTick sx={{ ...styles.delay5 }}/>
            <StyledSpanYTick sx={{ ...styles.delay5 }}/>
            <StyledSpanYTick sx={{ ...styles.delay5 }}/>
          </Box>
        </Box>
        <Box sx={{ ...styles.sectionWave }}>
          {Array(4).fill(true).map((_, i) =>  <svg key={i} xmlns="http://www.w3.org/2000/svg" width="1035" height="38" viewBox="0 0 1035 38" fill="none">
            <path style={{ ...styles.fadify }}  d="M2.22406 35.5L347.208 2L690.029 35.5L1032.85" stroke={palette.customGrey.silver} strokeWidth="3"/>
          </svg>)}
        </Box>
        <Box sx={{ ...styles.fadify, ...styles.xAxis }}>
          <StyledSpanXTick sx={{ ...styles.delay1 }}/>
          <StyledSpanXTick sx={{ ...styles.delay2 }}/>
          <StyledSpanXTick sx={{ ...styles.delay3 }}/>
          <StyledSpanXTick sx={{ ...styles.delay4 }}/>
          <StyledSpanXTick sx={{ ...styles.delay5 }}/>
          <StyledSpanXTick sx={{ ...styles.delay1 }}/>
          <StyledSpanXTick sx={{ ...styles.delay2 }}/>
          <StyledSpanXTick sx={{ ...styles.delay3 }}/>
          <StyledSpanXTick sx={{ ...styles.delay4 }}/>
          <StyledSpanXTick sx={{ ...styles.delay5 }}/>
          <StyledSpanXTick sx={{ ...styles.delay1 }}/>
          <StyledSpanXTick sx={{ ...styles.delay2 }}/>
          <StyledSpanXTick sx={{ ...styles.delay3 }}/>
        </Box>
      </Box>
    </Box>
  );
};
export default GoalAchievementLoader;
