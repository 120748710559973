import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import useMediaRecorder, { Status } from './useMediaRecorder';
import { StopButton, StyledMicIcon, StyledMicOffIcon, StyledStopIcon, TimeLabel } from './Recorder.styled';
import MicIcon from '../Icons/MicIcon';
import MicDisabledIcon from '../Icons/MicDisabledIcon';
import { palette } from '../../theme/palette';

interface Props {
  onRecord: (blob: File) => void;
  delayTime?: number;
  disable: boolean;
  setIsRecording?: Function;
  showElapsedTime?: boolean;
  recordButtonHeight?: number;
  recordButtonWidth?: number;
  maxRecordingTime?: number;
  setHitTimeLimit?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Recorder = ({
  onRecord,
  delayTime,
  disable,
  setIsRecording,
  showElapsedTime = true,
  recordButtonHeight = 72,
  recordButtonWidth = 72,
  maxRecordingTime,
  setHitTimeLimit,
}: Props) => {
  const { elapsedTime, startRecording, stopRecording, status } = useMediaRecorder({
    onError: (err) => console.log(err),
    onStop: (blob) => {
      if (blob) {
        const currentTime = new Date();
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        blob.lastModifiedDate = currentTime;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        blob.name = currentTime.getTime().toString();
        onRecord(blob as File);
      }
    },
    mediaStreamConstraints: { audio: true, video: false },
  });

  const onStart = () => {
    startRecording(0);
    setIsRecording?.(true);
  };

  const onStop = (hitMaxTimeLimit: boolean) => {
    stopRecording();
    setIsRecording?.(false);
    setHitTimeLimit?.(hitMaxTimeLimit);
  };

  const isRecording = [Status.Recording, Status.Stopping, Status.Paused].includes(status);
  const canRecord = [Status.Idle, Status.AcquiringMedia, Status.Ready, Status.Failed, Status.Stopped].includes(status);

  useEffect(() => {
    if (status === Status.Recording && maxRecordingTime && elapsedTime >= maxRecordingTime) {
      onStop(true);
    }
  }, [maxRecordingTime, status, elapsedTime, onStop]);

  const secsToTimeFormat = () => {
    const mins = Math.floor(elapsedTime / 60);
    const seconds = Math.floor(elapsedTime % 60);
    return `${mins.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Box>
      {showElapsedTime && (
        <Box marginBottom={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <TimeLabel role="timer" aria-label="Elapsed Recording Time">{`${secsToTimeFormat()}`}</TimeLabel>
        </Box>
      )}
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        {disable ? (
          <Box marginLeft={4} marginRight={4}>
            <StyledMicOffIcon
              variant="contained"
              disabled
              aria-label="Recording Disabled"
              sx={{ width: recordButtonWidth, height: recordButtonHeight }}
            >
              <MicDisabledIcon pathFill={palette.customBlack.lightBlack} />
            </StyledMicOffIcon>
          </Box>
        ) : (
          <>
            {canRecord && (
              <Box marginLeft={4} marginRight={4}>
                <StyledMicIcon
                  variant="contained"
                  color="primary"
                  sx={{ width: recordButtonWidth, height: recordButtonHeight }}
                  aria-label="Start Recording"
                  onClick={onStart}
                >
                  <MicIcon pathFill={palette.customWhite.main} />
                </StyledMicIcon>
              </Box>
            )}
            {isRecording && (
              <Box marginLeft={4} marginRight={4}>
                <StopButton
                  size="large"
                  color="primary"
                  sx={{ width: recordButtonWidth, height: recordButtonHeight }}
                  aria-label="Stop Recording"
                  disabled={!!delayTime && isRecording && delayTime > elapsedTime}
                  onClick={() => onStop(false)}
                >
                  <StyledStopIcon viewBox="3 3 18 18" />
                </StopButton>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
export default React.memo(Recorder);
