import React, { useEffect, useState } from 'react';
import { useTeacherBenchmarksDashboardContext } from '../teacherbenchmarks-dashboard-hook';
import {
  BenchmarkProgressScoreInput,
  BenchmarkProgressScoreSortType,
  OrderBy,
  useAddTypeformUserMappingMutation,
  useBenchmarkProgressScoreQuery,
  useGetTeacherDashboardIndividualStudentSurveyTypeformQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';
import { useAuthProvider } from '../../../core/authContext';

const useProgressScores = () => {
  const {
    selectedGrades, selectedClasses,
    selectedSchools, isFilterSelected,
    skipGraphQLRequest, updateProgressData,
    progressScoreQueryInput,
    setProgressScoreQueryInput,
    setProgressScoreDataCount,
  } = useTeacherBenchmarksDashboardContext();
  const pagination = usePagination();
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState(BenchmarkProgressScoreSortType.StudentName);
  const [order, setOrder] = useState(OrderBy.Asc);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [dismissTypeform, setDismissTypeform] = useState<boolean>(false);
  const { getUser } = useAuthProvider();
  const user = getUser();
  const { data: typeformDetails, loading: typeformDetailsLoading, refetch: refetchTypeform } = useGetTeacherDashboardIndividualStudentSurveyTypeformQuery({
    fetchPolicy: 'network-only',
    variables: {},
  });

  const [addTypeformUserMapping, { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();

  var queryInput: BenchmarkProgressScoreInput = {
    section_ids: selectedClasses ? selectedClasses : [],
    school_ids: selectedSchools ? selectedSchools : [],
    grade_ids: selectedGrades ? selectedGrades : [],
    sort_by: sort,
    order_by: order,
    limit: rowsPerPage,
    page: pagination.page,
    ...(searchTerm && searchTerm.length ? { search_by_name: searchTerm.trim() } : {}),

  };
  const updateProgressScoreQueryInput = (data: Object) => {
    setProgressScoreQueryInput?.({
      ...progressScoreQueryInput,
      ...data,
    });
  };


  const updateQueryInput = (data: any) => {
    queryInput = {
      ...queryInput,
      ...data,
    };
    updateProgressScoreQueryInput(queryInput);
  };
  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage, selectedSchools, selectedGrades, selectedClasses]);

  useEffect(() => {
    updateQueryInput({
      section_ids: selectedClasses ? selectedClasses : [],
      school_ids: selectedSchools ? selectedSchools : [],
      grade_ids: selectedGrades ? selectedGrades : [],
      search_by_name: searchTerm.trim(),
    });
  }, [selectedSchools, selectedGrades, selectedClasses, searchTerm]);


  const { data: progressScoreData, loading: progressScoreDataLoading, refetch: refetchProgressScores, error: progressDataErr } = useBenchmarkProgressScoreQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });

  const onSortChange = (sortBy: BenchmarkProgressScoreSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
    updateQueryInput({ sort_by: sortBy, order_by: orderBy });
  };


  useEffect(() => {
    refetchProgressScores();
  }, [queryInput]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination.setPage(1);
    if (event.target.value === '') {
      setSearchTerm('');
    } else {
      setSearchTerm(event.target.value);
    }
  };
  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);

  };

  const handleSort = (column: BenchmarkProgressScoreSortType) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const totalCount = progressScoreData?.benchmarkProgressScore.total_count ?? 0;
  const progressScores = progressScoreData?.benchmarkProgressScore.nodes ?? [];
  useEffect(() => {
    if (progressDataErr){
      openSnackbar({ message: getErrorMessage(progressDataErr) }, NotifierType.Error);
    }
    updateProgressData(progressScoreData, progressScoreDataLoading);

  }, [progressScoreData, progressScoreDataLoading, progressDataErr]);

  useEffect(() => {
    if (!progressScoreDataLoading) setProgressScoreDataCount?.(progressScoreData?.benchmarkProgressScore.total_count ?? 0);
  }, [progressScoreDataLoading]);

  // call mutation to add typeform user mapping
  const onTypeformReady = async () => {
    setDismissTypeform(true);
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetails?.getTeacherDashboardIndividualStudentSurveyTypeform?.id ?? '',
          submitted: false,
        },
      });
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
    refetchTypeform();
  };

  const onTypeformSubmit = async () => {
    setDismissTypeform(true);
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetails?.getTeacherDashboardIndividualStudentSurveyTypeform?.id ?? '',
          submitted: true,
        },
      });
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
    refetchTypeform();
  };

  return {
    selectedGrades, selectedClasses, selectedSchools, isFilterSelected,
    progressScoreData, progressScoreDataLoading, handlePageChange, handleSort,
    totalCount, progressScores, handleSearch, sort, order, pagination,
    rowsPerPage, setRowsPerPage, searchTerm,
    typeformDetailsLoading,
    typeformData: typeformDetails?.getTeacherDashboardIndividualStudentSurveyTypeform || null,
    updateUserLoading,
    onTypeformReady,
    onTypeformSubmit,
    userId: user?.id ?? '',
    dismissTypeform,
  };
};
export default useProgressScores;
