import React from 'react';
import { Box } from '@mui/material';

import { StudentBenchmarkDashBoard } from '../../../../variables/constant';
import StyledCard from '../../../../components/StyledCard';
import AppTooltip from '../../../../components/AppTooltip';
import DonutChartLoader from '../../../../components/Loader/DonutChartLoader';
import NoResults from '../../../../components/NoResults';

import EmptyBenchmarkResults from '../../../../components/Icons/EmptyBenchmarksResults';
import useStudentGrowthReportWidget from './student-growth-report-widget-hook';
import BenchmarkStudentGrowthReportChart from '../../../../components/BenchmarkStudentGrowthReportChart';


interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.

}

const StudentGrowthReportWidget = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,

}: Props) => {
  const title = 'Benchmark Student Growth Report';
  const { growthReportData, studentGrowthReportLoading, yAxisDomain, yAxisTicks, academicSessionLoading, sessionEndDate, sessionStartDate, hasData } = useStudentGrowthReportWidget();


  return (
    <Box id={StudentBenchmarkDashBoard.StudentGrowthReportWidget.name} sx={{ height: '100%', width: '100%' }}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        marginBottom='0px'
        contentAlignment

      >
        {studentGrowthReportLoading && <Box sx={{ display: 'flex', justifyContent: 'center' }}><DonutChartLoader /></Box>}

        {
          !studentGrowthReportLoading && !academicSessionLoading && hasData && (
            <Box sx={{ padding: '0px' }} >
              <BenchmarkStudentGrowthReportChart
                data={growthReportData}
                lineKey1='submission_speaking_score_average'
                lineKey2='submission_writing_score_average'
                xAxisKey='submission_submitted_at_date'
                chartWidth={600}
                chartHeight={400}
                yAxisDomain={yAxisDomain}
                yAxisTicks={yAxisTicks}
                sessionStartDate={sessionStartDate!}
                sessionEndDate={sessionEndDate!}

              />
            </Box>
          )
        }
        {
          !studentGrowthReportLoading && !hasData && (
            <NoResults
              description={StudentBenchmarkDashBoard.StudentGrowthReportWidget.insufficientData.description}
              svgComponent={<EmptyBenchmarkResults />}
            />
          )}

      </StyledCard>

    </Box>
  );
};

export default StudentGrowthReportWidget;
