import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import React from 'react';
import { ScoreTabs } from '../../variables/types';
import SpeakingBM from './SpeakingBM';
import WritingBM from './WritingBM';

interface RouteProp {
  id: string;
  section?: string;
}

const ScoreTaskBenchmark = () => {
  const { id } = useParams<RouteProp>();

  return (
    <Switch>
      <Route
        path={`/tasks/:id/benchmark/score/${ScoreTabs.Speaking}`}
        component={SpeakingBM}
        exact
      />
      <Route
        path={`/tasks/:id/benchmark/score/${ScoreTabs.Writing}`}
        component={WritingBM}
        exact
      />
      <Redirect
        from="/tasks/:id/benchmark/score"
        to={`/tasks/${id}/benchmark/score/${ScoreTabs.Speaking}`}
      />
    </Switch>
  );
};

export default ScoreTaskBenchmark;
