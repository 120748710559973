import { useContext, useEffect, useState } from 'react';
import {
  BENCHMARK_DOES_NOT_EXIST_ERROR,
  BENCHMARK_DOES_NOT_EXIST_MESSAGE,
  ROWS_PER_PAGE,
} from '../../../variables/constant';
import {
  BenchmarkSuperAdminDtoSortType,
  OrderBy,
  useBenchmarksListQuery,
  useDeleteBenchmarkMutation,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { NotifierType } from '../../../variables/types';
import getErrorMessage from '../../../utils/getErrorMessage';
import { openSnackbar } from '../../../components/Notifier';
import { SearchContext } from '../../../core/searchContext';


const useBenchmarksList = ( ) => {
  const pagination = usePagination();
  const { searchTerm } = useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, BenchmarkSuperAdminDtoSortType.StartDate));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [deleteBenchmark, { loading: deleteLoading }] = useDeleteBenchmarkMutation();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage]);
  const { data, loading, refetch: benchmarksListRefetch } = useBenchmarksListQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    variables: {
      ...(searchTerm && searchTerm.length > 2 ? { search: searchTerm } : {}),
      limit: rowsPerPage,
      page: pagination.page,
      sort: sort as BenchmarkSuperAdminDtoSortType,
      orderBy: order as OrderBy,
      ...(searchTerm && searchTerm.length > 2 ? { search: searchTerm } : {}),
    },
  });

  const onSortChange = (sortBy: BenchmarkSuperAdminDtoSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const onDeleteBenchmarkHandle = async (id: string) => {
    try {
      const response = await deleteBenchmark({
        variables: {
          id: id,
        },
      });
      openSnackbar({
        message: 'Benchmark deleted successfully',
      }, NotifierType.Success);
      if (response.data?.deleteBenchmark) benchmarksListRefetch();
    } catch (err) {
      if (getErrorMessage(err) === BENCHMARK_DOES_NOT_EXIST_ERROR) {
        openSnackbar({
          message: BENCHMARK_DOES_NOT_EXIST_MESSAGE,
        }, NotifierType.Error);
      } else {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
  };

  const benchmarks = data?.getAllBenchmarksForSuperAdmin?.nodes;
  const totalCount = data?.getAllBenchmarksForSuperAdmin?.totalCount;

  return {
    loading: loading || deleteLoading,
    benchmarksListRefetch,
    benchmarks,
    pagination,
    totalCount,
    onSortChange,
    onDeleteBenchmarkHandle,
    sort,
    order: order as OrderBy,
    rowsPerPage,
    setRowsPerPage,
  };
};

export default useBenchmarksList;
