import { Box, Typography } from '@mui/material';
import React from 'react';
import { PAGE_SIZE } from '../../variables/constant';
import { palette } from '../../theme/palette';


interface CountProps {
  pageNumber: number
  recordLength: number
  totalRecords: number
}

const RecordCount = ({ pageNumber, recordLength, totalRecords }: CountProps) => {
  const recordsCountFrom = totalRecords > 0 ? (pageNumber - 1) * recordLength + 1 : 0;
  const recordsCountTo =  totalRecords > 0 ? recordsCountFrom + recordLength - 1 : 0;
  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
      fontSize="16px"
      lineHeight="24px"
      padding={1}
    >
      <Typography sx={{ fontWeight: '800', color: palette.customBlack.fontBlack }}>Showing {`${recordsCountFrom} - ${recordsCountTo > totalRecords ? totalRecords : recordsCountTo} of ${totalRecords}`}</Typography> 
    </Box>
  );
};

export default RecordCount;
