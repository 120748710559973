import React from 'react';
import { StyledLoaderContainer, StyledLoaderElement } from './Loader.styled';

const DoughnutGraphLoader = () => {
  return (
    <StyledLoaderContainer>
      <StyledLoaderElement />
      <StyledLoaderElement />
      <StyledLoaderElement />
      <StyledLoaderElement />
    </StyledLoaderContainer>
  );
};

export default DoughnutGraphLoader;
