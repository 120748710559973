import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import Edit from './Edit';
import UploadImages from './UploadImages';
import ManageImage from './UploadImages/ManageImage';
import Grade from './Grade';
import Create from './Create';

const AdminBenchmarks = () => (
  <Switch>
    <Route
      path="/benchmarks"
      component={List}
      exact
    />
    <Route
      path="/benchmarks/create"
      component={Create}
      exact
    />
    <Route
      path="/benchmarks/create/:step"
      component={Create}
      exact
    />
    <Route
      path="/benchmarks/:id/edit"
      component={Edit}
      exact
    />
    <Route
      path="/benchmarks/grade"
      component={Grade}
      exact
    />
    <Route
      path="/benchmarks/upload"
      component={UploadImages}
      exact
    />
    <Route
      path="/benchmarks/manage-images/:id"
      component={ManageImage}
      exact
    />
  </Switch>
);

export default AdminBenchmarks;
