import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { StudentsIcon } from '../Icons';
import ToolBoxIcon from '../Icons/ToolBoxIcon';
import SolidPhotograph from '../Icons/SolidPhotograph';
import Loader from '../Loader/loader';
import useSidebarHook from './sidebar-hook';
import TreeViewNavigation from './TreeViewNavigation';
import BenchmarkIcon from '../Icons/BenchmarkIcon';
import TreeMapSelectedIcon from '../Icons/TreeMapSelectedIcon';
import NavigationChip from '../NavigationChip';
import RoutingPaths from '../../pages/RoutingPath';
import useLocalStorageState from '../../hooks/useLocalStorageState';
import ClassesIcon from '../Icons/ClassesIcon';
import { StyledChipBox } from '../Chip/ChipsCount.styled';
import { palette } from '../../theme/palette';
import NumberFormat from 'react-number-format';
import { superAdminOptionStyles } from './SuperAdminOptions';
import TypeformEmbed from '../TypeformEmbed';
import { useImitationProvider } from '../../core/imitationContext';
import TypeformFlashlightIcon from '../Icons/TypeformFlashlightIcon';

export const StyledNavigationButton = styled(Button)({
  borderWidth: 0,
  borderRadius: 0,
  padding: '6px 8px',
  marginTop: 5,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    borderWidth: 0,
    borderRadius: 0,
  },
  '&::after': {
    display: 'none',
  },
});

export const StyledNavigationButtonBox = styled(Box)<{ active?: string }>(({ theme, active }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  border: '2px solid transparent',
  borderRadius: 12,
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 600,
  textTransform: 'capitalize',
  color:  active === 'true' ? theme.palette.customWhite.main : theme.palette.customBlack.lightBlack,
  boxSizing: active === 'true' ? 'border-box' : undefined,
  backgroundColor: active === 'true' ? theme.palette.primary.main : 'inherit',
}));

const StylesCSS = () => ({
  typeformSideTabStyle: {
    fontSize: 20,
    transform: 'rotate(-90deg)',
    position: 'fixed',
    left: '-128px',
    bottom: '160px',
    background: palette.customBlue.primaryBlue,
    color: palette.customWhite.main,
    fontWeight: '800',
    height: '45px',
    width: '300px',
    borderRadius: '0 0 10px 10px',
    border: 'none',
    textAlign: 'center',
    cursor: 'pointer',
  },
});


const TeacherOptions = () => {
  const history = useHistory();

  const {
    loading,
    submittedSubmissionCount,
    sidenavPTSubmissionCountRefetch,
    sidenavBenchmarkGradeTileCount,
    teacherBenchmarksDataRefetch,
    sectionCount,
    typeformData,
    onTypeformReady,
    onTypeformSubmit,
  } = useSidebarHook();

  const navigateTo = (path: string) => () => {
    sidenavPTSubmissionCountRefetch();
    teacherBenchmarksDataRefetch();
    history.push(path);
  };

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();

  const styles = StylesCSS();
  const [tbdTypeformSurvey, setTbdTypeformSurvey] = useLocalStorageState('TBD-typeform-survey');
  const isBenchmarkList = useRouteMatch({ path: [RoutingPaths.TeacherBenchMarks, RoutingPaths.ScoreTaskBenchmarks, `${RoutingPaths.ScoreTaskBenchmarks}/:section`, RoutingPaths.StudentBenchmarkDashboard], exact: false });
  const isBenchmarkGrade = useRouteMatch({ path: [RoutingPaths.TeacherGradeBenchMarks, RoutingPaths.ScoreTaskBenchmarks, `${RoutingPaths.ScoreTaskBenchmarks}/:section`], exact: false });
  const isBenchmarkData = useRouteMatch({ path: [RoutingPaths.TeacherBenchMarksDashboard, RoutingPaths.StudentBenchmarkDashboard], exact: false });
  const isAssignmentList = useRouteMatch({ path: [RoutingPaths.Assignments, RoutingPaths.Analytics, RoutingPaths.Grade, RoutingPaths.ScoreTask], exact: false });
  const isAssignmentData = useRouteMatch(   { path: RoutingPaths.Analytics, exact: false });
  const isAssignmentGrade = useRouteMatch({ path: [RoutingPaths.Grade, RoutingPaths.ScoreTask], exact: false });
  const updateLocalStorage = () => () => {
    const path = history?.location?.pathname;
    if (path && path === RoutingPaths.TeacherBenchMarksDashboard) {
      setTbdTypeformSurvey(tbdTypeformSurvey as number + 1);
    }
  };

  const benchmarkSidebar = {
    mainCategory: {
      indexId: '1',
      label: 'Benchmarks',
      linkToRedirect: RoutingPaths.TeacherBenchMarks,
      routeMatches: isBenchmarkList,
      icon: BenchmarkIcon,
    },
    subItems: [
      {
        indexId: '1.a',
        label: 'Grade',
        linkToRedirect: RoutingPaths.TeacherGradeBenchMarks,
        routeMatches: isBenchmarkGrade,
        icon: TreeMapSelectedIcon,
        counterChips: <NavigationChip dataCount={sidenavBenchmarkGradeTileCount} />,
      },
      {
        indexId: '1.b',
        label: 'Benchmark Data',
        linkToRedirect: RoutingPaths.TeacherBenchMarksDashboard,
        routeMatches: isBenchmarkData,
        icon: TreeMapSelectedIcon,
      },
    ],
  };
  const assignmentSidebar = {
    mainCategory: {
      indexId: '1',
      label: 'Assignments',
      linkToRedirect: RoutingPaths.Assignments,
      routeMatches: isAssignmentList,
      icon: SolidPhotograph,
    },
    subItems: [
      {
        indexId: '1.a',
        label: 'Grade',
        linkToRedirect: RoutingPaths.Grade,
        routeMatches: isAssignmentGrade,
        icon: TreeMapSelectedIcon,
        counterChips: <NavigationChip dataCount={submittedSubmissionCount} />,
      },
      {
        indexId: '1.a',
        label: 'Assignment Data',
        linkToRedirect: RoutingPaths.AnalyticsOverview,
        routeMatches: isAssignmentData,
        icon: TreeMapSelectedIcon,
      },
    ],
  };

  return (
    <Box width="100%">
      <Loader open={loading} />
      <StyledNavigationButton
        fullWidth
        onClick={() => sidenavPTSubmissionCountRefetch()}
      >
        <TreeViewNavigation
          mainCategoryItem={assignmentSidebar.mainCategory}
          subCategoryItems={assignmentSidebar.subItems}
        />
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={updateLocalStorage()}
      >
        <TreeViewNavigation
          mainCategoryItem={benchmarkSidebar.mainCategory}
          subCategoryItems={benchmarkSidebar.subItems}
        />
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo(RoutingPaths.TeacherClasses)}
      >
        <StyledNavigationButtonBox
          sx={{ ...superAdminOptionStyles.buttonBox }}
          active={(!!useRouteMatch({ path: [RoutingPaths.CreateClass, RoutingPaths.TeacherClasses], exact: false }))?.toString()}
        >
          <ClassesIcon sx={{ mr: '12px' }} />
          Classes
          <StyledChipBox
            sx={{ backgroundColor: palette.customBackground.dragBackground }}
            hascolor={palette.customBlack.lightBlack}
            label={(
              <NumberFormat
                displayType="text"
                value={sectionCount ?? '-'}
                thousandSeparator
              />
            )}
          />
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo(RoutingPaths.Students)}
      >
        <StyledNavigationButtonBox
          active={(!!useRouteMatch({ path: [RoutingPaths.Students, RoutingPaths.Groups], exact: false }))?.toString()}
        >
          <StudentsIcon
            sx={{ mr: '5px' }}
          />
          Students
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo(RoutingPaths.ToolBox)}
      >
        <StyledNavigationButtonBox
          active={(!!useRouteMatch({ path: RoutingPaths.ToolBox, exact: false }))?.toString()}
        >
          <ToolBoxIcon
            sx={{ mr: '5px' }}
          />
          Toolbox
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      {
        !loading &&
          isNotImitated &&
          typeformData?.show_typeform && 
          <TypeformEmbed
            formId={typeformData?.typeform_form_id}
            typeformLayout='slider'
            onCloseHandler={() => onTypeformReady()}
            onFormSubmittedHandler={() => onTypeformSubmit()}
            hiddenButton={false}
            autoTrigger={false}
            position={'left'}
            customIcon={<TypeformFlashlightIcon />}
            styleClass={styles.typeformSideTabStyle}
            buttonText='Back To School Updates!'/>
      }
    </Box>
  );
};

export default TeacherOptions;
