/* eslint-disable react/prop-types,react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import useDropdownButton from './dropdown-button-hook';
import { palette } from '../../theme/palette';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 12,
    marginTop: theme.spacing(1),
    minWidth: 200,
    '& .MuiMenuItem-root': {
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: 800,
      '&:active': {
        backgroundColor: theme.palette.transparent.main,
      },
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiButtonBase-root.MuiMenuItem-root': {
    border: 0,
  },
}));

const buttonStyles = {
  button: {
    minWidth: '20vh',
    maxHeight: '50px',
    fontWeight: 800,
  },
};

export interface Action {
  label: string;
  value: string;
  disabled?: boolean;
  color?: any | undefined;
}

interface Props {
  label: string,
  actionItems: Array<Action>;
  handleClick: (action: Action) => void;
  closeOnSelect?: boolean;
  variant?: 'contained'
  | 'outlined'
  | 'text';
  color?: undefined
  | 'inherit'
  | 'primary'
  | 'secondary';
  disabled?: boolean;
}

const DropdownButton = ({ label, actionItems, handleClick, color, variant, disabled, closeOnSelect = false }: Props) => {
  const { open, anchorEl, onToggle, handleClose  } = useDropdownButton();
  return (
    <>
      <Button
        variant={variant || 'outlined'}
        color={color || 'primary'}
        onClick={onToggle}
        sx={{ ...buttonStyles.button, py: 1.5, mx: 1 }}
        endIcon={<ChevronDownIcon sx={{ pt: '6px' }} fillColor={disabled ? palette.customBackground.disabled : ''} />}
        disabled={disabled}
      >
        {label}
      </Button>
      <StyledMenu
        id="add-new-menu-list"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        autoFocus={false}
      >
        {actionItems.map((option, _) => (
          <StyledMenuItem
            key={option?.value}
            onClick={() => {
              handleClick(option);
              if (closeOnSelect) handleClose();
            }}
          >
            <Typography variant='sFormTitle' sx={!!option?.color ? { color:  option?.color } : { color: palette.customBlue.primaryBlue } } >{option?.label}</Typography>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default DropdownButton;
