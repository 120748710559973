import React from 'react';
import {
  Box,
  CircularProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { PaginationProps } from '../../../variables/types';
import { TeacherBenchmarkDashBoard } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import { BenchmarkProgressScoreSortType, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import { StyledTableCell2, StyledTableCell3, StyledTableCell5 } from '../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../components/Common/Common.styled';
import CustomPagination from '../../../components/CustomPagination';
import ProgressRow from './ProgressRow';
import { TeacherBenchmarkDashboardStylesCSS } from '../teacherbenchmarkdashboard.styled';
import EmptyBenchmarkResults from '../../../components/Icons/EmptyBenchmarksResults';
import { palette } from '../../../theme/palette';
import { Legend } from 'recharts';
import useUserInfo from '../../../utils/useUserInfo';


interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: BenchmarkProgressScoreSortType,
  order: OrderBy,
  data: any[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: BenchmarkProgressScoreSortType) => void) | Function,
  rowsPerPage: number,
  setRowsPerPage: Function,
  marginTop?: number,
  sxProps?: SxProps,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  data,
  handleSort,
  rowsPerPage,
  setRowsPerPage,
  marginTop = 2, sxProps,
}: Props) => {
  const theme = useTheme();
  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const [isLoadingUserInfo, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const legendData: any[] = [];
  const datalegend = scoreScheme?.map((item: any) => {
    legendData.push({ skillTitle: item?.title, color: item?.color_code });
  });
  legendData.push({ skillTitle: 'Incomplete / No Score', color: palette.customGrey.iconGrade });

  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const sortColumn = (title: string, sortEnum: BenchmarkProgressScoreSortType) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      {title}
    </StyledTableSort>
  );
  return (
    <Box
      display="grid"
      width="100%"
      mt={marginTop}
      id={TeacherBenchmarkDashBoard.ProgressScore.name}
      paddingBottom={2}
    >
      <Box>
        <StyledContainerRoot>
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead >
              <TableRow >
                <StyledTableCell2 colSpan={2} sx={{ textAlign: 'left', border: 'none', height: '52px' }}>
                </StyledTableCell2>
                <StyledTableCell2 colSpan={6} sx={{ textAlign: 'center', border: 'none', height: '52px' }}>
                  Speaking
                </StyledTableCell2>
                <StyledTableCell2 colSpan={6} sx={{ textAlign: 'center', border: 'none', height: '52px' }}>
                  Writing
                </StyledTableCell2>
              </TableRow>
              <TableRow >
                <StyledTableCell5>
                  {sortColumn('Name', BenchmarkProgressScoreSortType.StudentName)}
                </StyledTableCell5>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== BenchmarkProgressScoreSortType.StudentGrade && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('Grade', BenchmarkProgressScoreSortType.StudentGrade)}
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== BenchmarkProgressScoreSortType.SpBoy && { padding: '8px 8px 8px 24px' }) }}>
                  {sortColumn('BOY', BenchmarkProgressScoreSortType.SpBoy)}
                </StyledTableCell3>
                <StyledTableCell3 >
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== BenchmarkProgressScoreSortType.SpMoy && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('MOY', BenchmarkProgressScoreSortType.SpMoy)}
                </StyledTableCell3>
                <StyledTableCell3 >
                </StyledTableCell3 >
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== BenchmarkProgressScoreSortType.SpEoy && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('EOY', BenchmarkProgressScoreSortType.SpEoy)}
                </StyledTableCell3>
                <StyledTableCell3 sx={{ ...(sort !== BenchmarkProgressScoreSortType.SpYtd && { padding: '8px 8px 8px 24px' }) }}>
                  {sortColumn('YTD Change', BenchmarkProgressScoreSortType.SpYtd)}
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== BenchmarkProgressScoreSortType.WrBoy && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('BOY', BenchmarkProgressScoreSortType.WrBoy)}
                </StyledTableCell3>
                <StyledTableCell3 >
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== BenchmarkProgressScoreSortType.WrMoy && { padding: '8px 8px 8px 24px' }) }}  >
                  {sortColumn('MOY', BenchmarkProgressScoreSortType.WrMoy)}
                </StyledTableCell3>
                <StyledTableCell3 >
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== BenchmarkProgressScoreSortType.WrEoy && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('EOY', BenchmarkProgressScoreSortType.WrEoy)}
                </StyledTableCell3>
                <StyledTableCell3 sx={{ ...(sort !== BenchmarkProgressScoreSortType.WrYtd && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('YTD Change', BenchmarkProgressScoreSortType.WrYtd)}
                </StyledTableCell3>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && data?.map((score) => (
                <ProgressRow progressScore={score} key={score?.student_id} />
              ))}
              {isTableEmpty && !loading && (
                <TableRow>
                  <TableCell
                    colSpan={14}
                    align="center"
                  >

                    <NoResults
                      description={TeacherBenchmarkDashBoard.ProgressScore.insufficientData.description}
                      svgComponent={<EmptyBenchmarkResults />}
                    />

                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={14}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

        </StyledContainerRoot>
      </Box>
      <Box
        display="grid"
        gap="20px"
      >
        <Box >
          {!isTableEmpty && !loading && < Legend
            wrapperStyle={{ width: '70%', display: 'flex', alignItems: 'flex-start', paddingLeft: '24px', paddingBottom: '24px', textAlign: 'left', fontSize: '14px', fontWeight: 600 }}
            payload={legendData?.map((item, index) => ({
              value: (
                <span style={{ color: palette.customBlack.fontBlack, fontSize: '14px', fontWeight: 600 }}>{item?.skillTitle}</span> // Setting legend label color to black
              ),
              type: 'circle',
              color: item?.color,
              textColor: palette.customBlack.fontBlack,
            }))}
          />}

        </Box>
        <Box>
          <CustomPagination
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={pagination.page}
            onChange={handlePageChange}
            setRowsPerPage={setRowsPerPage}
            paddingTop='16px'
          />
        </Box>
      </Box >
    </Box >
  );
};
export default List;
