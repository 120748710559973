import React from 'react';
import {
  Box,
  TextField,
  FormControl,
  Typography,
  Button,
} from '@mui/material';
import { InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  LoginButton,
} from '../Login/Login.styled';
import Loader from '../../components/Loader/loader';
import ConfirmationImage from '../../components/Icons/ConfirmationImage';
import LoginWrapper from '../../components/LoginWrapper';
import useForgotPassword from './forgot-password.hook';
import { CONFIRM_PASSWORD_HELPER_TEXT, EMAIL_REGEX } from '../../variables/constant';
import { inputStyles } from '../Login/Credentials';
import { palette  } from '../../theme/palette';
import { errorMessages, labels } from './constants';

const forgotStyles = {
  primaryBack: {
    backgroundColor: palette.customBlue.flashlightBlue,
    color: palette.customWhite.main,
    '&:hover': {
      backgroundColor: palette.customBlue.flashlightBlue,
    },
  },
  secondaryBack: {
    color: palette.customBlue.flashlightBlue,
  },
  description: {
    fontWeight: 500,
    fontSize: '16px',
    color: palette.fontColors.fontBlack,
    textTransform: 'none',
    lineHeight: '22px',
  },
};

const ForgotPassword = () => {
  const {
    handleSubmit,
    onPasswordChange,
    loading,
    token,
    register,
    errors,
    handleGoBack,
    handleForgotPassword,
    handleResetPasswordRedirect,
    getValues,
    status,
    description,
    showInvalidTokenMessage,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword,
    handleClickShowConfirmPassword,
    handleMouseConfirmDownPassword,
    showConfirmPassword,
  } = useForgotPassword();

  const getMessageHeader = () => {
    if (token) {
      if (status) {
        return status;
      }
      return labels.createPasswordLabel;
    }
    if (status) {
      return status;
    }
    return labels.resetLinkLabel;
  };

  return (
    <LoginWrapper
      text={getMessageHeader()}
      image={(
        <ConfirmationImage
          sx={{ ...inputStyles.input, transform: 'none' }}
          imagePrimaryColor={palette.customOrange.buttonFontOrange}
        />
      )}
      showInfoTooltip={!status && !token}
      tooltipText={labels.toolTipLabel}
      width="25%"
    >
      <>
        <form
          autoComplete="off"
          onSubmit={token ? handleSubmit(onPasswordChange) : handleSubmit(handleForgotPassword)}
          noValidate
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {description ? (
            <Typography sx={{ ...forgotStyles.description }}>
              {description}
            </Typography>
          ) : (
            <>
              {!token && (
                <FormControl sx={{ mt: 1 }}>
                  <TextField
                    fullWidth
                    type="email"
                    name="email"
                    sx={{ ...inputStyles.lowercaseInputText }}
                    placeholder="Email"
                    inputRef={register({
                      required: { value: true, message: errorMessages.requiredEmail },
                      pattern: {
                        value: EMAIL_REGEX,
                        message: errorMessages.validEmailText,
                      },
                    })}
                  />
                  {
                    errors.email && (
                      <Typography variant='sFormHelper'>
                        {errors?.email?.message}
                      </Typography>
                    )
                  }
                </FormControl>
              )}
              {token && (
                <>
                  <FormControl sx={{ mt: 1 }}>
                    <TextField
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      name="newPassword"
                      placeholder="New Password"
                      sx={{ ...inputStyles.input }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ position: 'absolute', right: '0px' }}>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={register({
                        required: { value: Boolean(token), message: errorMessages.passwordRequired },
                        validate: (value) => !!value.trim(),
                      })}
                    />
                    {
                      errors.newPassword && (
                        <Typography variant='sFormHelper'>
                          {errors?.newPassword?.message}
                        </Typography>
                      )
                    }
                  </FormControl>
                  <FormControl sx={{ mt: 1 }}>
                    <TextField
                      fullWidth
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      sx={{ ...inputStyles.input }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ position: 'absolute', right: '0px' }}>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseConfirmDownPassword}
                            >
                              {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputRef={register({
                        required: { value: true, message: errorMessages.passwordConfirm },
                        validate: (value) => (getValues('newPassword') === value) || CONFIRM_PASSWORD_HELPER_TEXT,
                      })}
                    />
                    {
                      errors.confirmPassword && (
                        <Typography variant='sFormHelper'>
                          {errors?.confirmPassword?.message}
                        </Typography>
                      )
                    }
                  </FormControl>
                </>
              )}
            </>
          )}
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            mt={5}
            mb={2}
          >
            {!status && (
              <LoginButton
                type="submit"
                variant="contained"
                color="secondary"
              >
                {token ? 'Create Password' : 'Send Reset Link'}
              </LoginButton>
            )}
            {
              !token && (
                <Button
                  variant={status ? 'contained' : 'outlined'}
                  onClick={handleGoBack}
                  sx={{ ...forgotStyles.secondaryBack, ...(status) && forgotStyles.primaryBack }}
                >
                  Go Back
                </Button>
              )
            }
            {
              token && status && !showInvalidTokenMessage && (
                <Button
                  variant="contained"
                  onClick={handleGoBack}
                  sx={{ ...forgotStyles.primaryBack }}
                >
                  Login
                </Button>
              )
            }
            {
              token && status && showInvalidTokenMessage && (
                <Button
                  variant="contained"
                  onClick={handleResetPasswordRedirect}
                  sx={{ ...forgotStyles.primaryBack }}
                >
                  Reset Password
                </Button>
              )
            }
          </Box>
        </form>
        <Loader open={loading} />
      </>
    </LoginWrapper>
  );
};

export default ForgotPassword;
