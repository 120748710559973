import React from 'react';
import { Box, Grid, Typography, Button, Tooltip } from '@mui/material';
import { Redirect, Switch } from 'react-router-dom';
import { toReadableFormat } from '../../../../utils/dateFormat';
import {
  ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR, AdminBenchmarkComparisonTabTooltip,
} from '../../../../variables/constant';
import {
  StyledDistrictWideComparisonsTabs,
  StyledTabs,
} from '../../../AdminAnalytics/DistrictWideComparisons/DistrictWideComparisons.styled';
import ExportToPdf from '../pdfExport';
import useAdminBenchmarkProgressHook from './ProgressScores/admin-benchmark-progress-hook';
import useRole from '../../../../hooks/useRole';
import RoutingPaths from '../../../RoutingPath';
import Layout from '../../../../components/Layout';
import List from '../BenchmarkComparison/ProgressScores/List';
import { useAuthProvider } from '../../../../core/authContext';
import { useImitationProvider } from '../../../../core/imitationContext';
import TypeformEmbed from '../../../../components/TypeformEmbed';
import AppToolTipIcon from '../../../../components/Icons/AppToolTipIcon';

const AdminBenchmarkComparison = () => {
  const { isSchoolAdmin } = useRole();
  const {
    loading, lastSnowflakeUpdateTimeData,
    tab, handleTabChange,
    schoolID, districtID,
    comparisonTabs,
    pagination,
    handlePageChange, handleSort, rowsPerPage,
    setRowsPerPage,
    queryInput,
    onTypeformReady,
    onTypeformSubmit,
    typeformData,
    dismissTypeform,
  }  = useAdminBenchmarkProgressHook();

  const { getUser } = useAuthProvider();
  const user = getUser();

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();

  return (
    <Layout>
      <Switch>
        <Redirect
          from={RoutingPaths.AdminBenchmarkComparison}
          exact
          to={RoutingPaths.AdminBenchmarkComparison + comparisonTabs[0].path}
        />
      </Switch>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Grid container sx={{ marginBottom: 6.5 }}>
          <Grid item xs={11}>
            <Box>
              <Typography variant="pageTitle">
                {isSchoolAdmin ? 'School' : 'District'} Benchmark Comparison
              </Typography>
              {!!lastSnowflakeUpdateTimeData && <Box>
                <Typography variant="toolTipGreyText">
                  Last Updated: {toReadableFormat(lastSnowflakeUpdateTimeData, ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR, 'en-US').replace('at', '')} UTC
                </Typography>
              </Box>}
            </Box>
          </Grid>
          <Grid item xs={1} mt={2} justifyItems="right">
            <ExportToPdf
              districtId={districtID as string}
              schoolId={schoolID as string}
              lastSnowflakeUpdateTimeData={lastSnowflakeUpdateTimeData}
              isBenchmarkComparison={true}
              tabKey={comparisonTabs[tab].label as string}
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={11.5}>
            <StyledTabs
              value={tab}
              onChange={handleTabChange}
              textColor="primary"
              scrollButtons={false}
            >
              {
                comparisonTabs.map((tabItem) => {
                  return (
                    <StyledDistrictWideComparisonsTabs
                      key={tabItem.value as string}
                      classes={{
                        root: `${tabItem.value as string}`,
                      }}
                      label={(
                        <Typography>
                          <Typography
                            component="span"
                            className="tag"
                          >
                            {tabItem.totalCount || '-'}
                          </Typography>
                          <Typography component="span">
                            {tabItem.label as string}
                          </Typography>
                        </Typography>
                      )}
                      disableRipple
                    />
                  );
                })
              }
            </StyledTabs>
          </Grid>
          <Grid item xs={0.5} justifyItems="right" mr={0} mt={1.5}>
            <Tooltip title={isSchoolAdmin ? AdminBenchmarkComparisonTabTooltip.schoolAdmin : AdminBenchmarkComparisonTabTooltip.districtAdmin} placement="left" arrow>
              <span>
                <Button sx={{ height: 0 }} disabled><AppToolTipIcon /></Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Box>
          <List
            isTableEmpty={!comparisonTabs[tab].totalCount}
            loading={loading!}
            totalCount={comparisonTabs[tab].totalCount || 0}
            pagination={pagination}
            data={comparisonTabs[tab].progressScoreData}
            handlePageChange={handlePageChange}
            handleSort={handleSort}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            sort={queryInput.sort}
            order={queryInput.orderBy}
            tabName={comparisonTabs[tab].value as string}
          />
        </Box>
      </Box>
      { /* FL-4988 Remove the comment to enable Typeform Survey
        isNotImitated &&
          !loading &&
          !dismissTypeform &&
          typeformData?.show_typeform && (
          <TypeformEmbed
            formId={typeformData?.typeform_form_id}
            onFormSubmittedHandler={onTypeformSubmit}
            onCloseHandler={onTypeformReady}
            typeformLayout="popover"
            delay={7000}
            hiddenFields={{ user_id: user?.id }}
            hiddenButton={true}
          />)
      */}
    </Layout>
  );
};

export default AdminBenchmarkComparison;
