import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClassComponent from './Class';
import ClassesTable from './ClassesTable';
import ManageClass from './ManageClass';
import Layout from '../../components/Layout';
import RoutingPaths from '../RoutingPath';
import CreateClass from './CreateClass';

const Classes = () => (
  <Layout>
    <Switch>
      <Route
        path={RoutingPaths.AdminClasses}
        component={ClassesTable}
        exact
      />
      <Route
        path={RoutingPaths.AdminClassManage}
        component={ManageClass}
        exact
      />
      <Route
        path={[`${RoutingPaths.AdminClass}`, `${RoutingPaths.AdminClass}/:sectionType`]}
        component={ClassComponent}
        exact
      />

      <Route
        path={[
          `${RoutingPaths.CreateClass}`, `${RoutingPaths.CreateClass}/:step`,
          `${RoutingPaths.EditClass}`, `${RoutingPaths.EditClass}/:step`,
        ]}
        component={CreateClass}
        exact
      />

    </Switch>
  </Layout>
);

export default React.memo(Classes);
