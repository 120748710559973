import { useHistory } from 'react-router-dom';

const useFeedbackTiles = () => {
  const history = useHistory();
  const handleRedirection = (submissionId: string) => async () => {
    if (submissionId) history.push(`/progress/score?submission=${submissionId}`);
  };
  return {
    handleRedirection,
  };
};

export default useFeedbackTiles;
