import { useEffect, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useDecoupleDistrictMutation,
  useDeleteDistrictRequestMutation,
  useDistrictDetailsQuery,
  useGetResourceCountByDistrictQuery,
  useMyDistrictQuery,
  UserPermissions,
} from '../../../generated/graphql';
import { MY_DISTRICT } from '../../../variables/constant';
import usePermission from '../../../hooks/usePermission';
import useRole from '../../../hooks/useRole';
import { NotifierType } from '../../../variables/types';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import useLocalStorageState from '../../../hooks/useLocalStorageState';


interface RouteProp {
  id: string;
}

const useDistrict = () => {
  const history = useHistory();
  const [redirectDistrictsPage] = useLocalStorageState('redirectDistrictsPage', '');
  const { isSuperAdmin, isSchoolAdmin } = useRole();
  const { id } = useParams<RouteProp>();
  const { data, loading, refetch: districtDetailRefetch } = useDistrictDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
    skip: id === MY_DISTRICT,
  });
  const { data: myDistrictData, loading: myDistrictLoading, refetch: myDistrictRefetch } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: id !== MY_DISTRICT,
  });
  const district = data?.districtDetails || myDistrictData?.myDistrict;

  useEffect(() => {
    if (district?.id === ''){
      openSnackbar({ message: 'District not found in database.' }, NotifierType.Error);
      history.push(`${redirectDistrictsPage.pathname}${redirectDistrictsPage.search}`);
    }
  }, [district]);

  const { hasPermission: editDistrictPermission } = usePermission(UserPermissions.EditDistricts);
  const { hasPermission: listSchoolsPermission } = usePermission(UserPermissions.ListSchools);
  const { hasPermission: mySchoolPermission } = usePermission([UserPermissions.MySchool]);

  const { data: districtResources } = useGetResourceCountByDistrictQuery({
    fetchPolicy: 'network-only',
    variables: {
      districtId: district?.id ?? id!,
    },
    skip: (!id) || isSchoolAdmin,
  });

  const [deleteDistrictRequest, { loading: deleteDistrictRequestLoading }] = useDeleteDistrictRequestMutation();

  const submitDeleteDistrictRequest = async (districtId: string) => {
    try {
      await deleteDistrictRequest({
        fetchPolicy: 'network-only',
        variables: {
          district_id: districtId,
        },
      });
      if (id === MY_DISTRICT) {
        myDistrictRefetch();
      } else {
        districtDetailRefetch();
      }
      openSnackbar({ message: `Request to permanantly delete ${district?.name} district is submitted.` }, NotifierType.Info);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      if (id === MY_DISTRICT) {
        myDistrictRefetch();
      } else {
        districtDetailRefetch();
      }
    }
  };

  const [decoupleDistrict, { loading: decoupleLoading, error: decoupleError }] = useDecoupleDistrictMutation();

  const initiateDecoupleDistrict = async (districtId: string) => {
    try {
      await decoupleDistrict({
        variables: {
          district_id: districtId,
        },
      });
      if (id === MY_DISTRICT) myDistrictRefetch();
      else districtDetailRefetch();
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      if (id === MY_DISTRICT) myDistrictRefetch();
      else districtDetailRefetch();
    }
  };

  useLayoutEffect(() => {
    if (decoupleError){
      openSnackbar({
        message: getErrorMessage(decoupleError),
      }, NotifierType.Error);
    }
  }, [decoupleError]);

  return {
    isSuperAdmin, isSchoolAdmin,
    loading: loading || myDistrictLoading || deleteDistrictRequestLoading || decoupleLoading,
    district: district,
    resourceCountByDistrict: districtResources?.resourceCountByDistrict,
    editDistrictPermission,
    listSchoolsPermission,
    mySchoolPermission,
    id,
    submitDeleteDistrictRequest,
    initiateDecoupleDistrict,
  };
};

export default useDistrict;
