import help from '../Images/help.jpg';

const data = {
  v: '5.7.6',
  fr: 60,
  ip: 0,
  op: 420,
  w: 1920,
  h: 1080,
  nm: '1920x1080 - Audio',
  ddd: 0,
  assets: [{ id: 'image_0', w: 2572, h: 1446, u: '', p: help, e: 0 }, {
    id: 'comp_0',
    layers: [{
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Cursor',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [{
            i: { x: 0.833, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 59,
            s: [222.625, 497.625, 0],
            to: [0, 0, 0],
            ti: [0, 0, 0],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 67,
            s: [225, 498, 0],
            to: [0, 0, 0],
            ti: [-132.333, 54.083, 0],
          }, { t: 88, s: [515, 485.5, 0] }],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 116,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 126,
            s: [150, 150, 100],
          }, {
            i: { x: [0.4, 0.4, 0.833], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 134,
            s: [220, 220, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 142,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 360,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 370,
            s: [150, 150, 100],
          }, {
            i: { x: [0.4, 0.4, 0.833], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 378,
            s: [220, 220, 100],
          }, { t: 386, s: [200, 200, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.311, -0.141], [0, 0], [0, 0], [0.144, 0.334], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.208, 0.093], [0, 0.274], [0, 0], [-0.306, -0.341], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.526, 0]],
              o: [[0, 0], [0, 0], [0, 0], [0, 0], [0.139, 0.336], [0, 0], [0, 0], [-0.297, 0.13], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.202, 0.208], [-0.218, -0.098], [0, 0], [0, -0.619], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.392, 0.382], [0, 0]],
              v: [[2.257, 4.068], [1.512, 4.068], [1.795, 4.757], [5.284, 13.257], [5.285, 13.258], [4.954, 14.11], [1.884, 15.448], [1.884, 15.448], [1.087, 15.106], [-2.228, 7.038], [-2.518, 6.333], [-3.049, 6.88], [-8.465, 12.451], [-8.465, 12.451], [-9.119, 12.585], [-9.499, 11.996], [-9.499, -14.859], [-8.479, -15.329], [-8.472, -15.321], [-8.465, -15.314], [9.31, 2.97], [9.31, 2.97], [9.32, 2.979], [8.888, 4.068]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 1, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 420,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 2,
      ty: 3,
      nm: 'Stop Button',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480, 453.25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 126,
      op: 370,
      st: 126,
      bm: 0,
    }, {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Stop',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.4, 0.4, 0.833], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 126,
            s: [105, 105, 100],
          }, {
            i: { x: [0.4, 0.4, 0.833], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 134,
            s: [100, 100, 100],
          }, {
            i: { x: [0.4, 0.4, 0.833], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 362,
            s: [100, 100, 100],
          }, { t: 370, s: [105, 105, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.656, 0], [0, 0], [0, -1.656], [0, 0], [-1.656, 0], [0, 0], [0, 1.656], [0, 0]],
              o: [[0, 0], [-1.656, 0], [0, 0], [0, 1.656], [0, 0], [1.656, 0], [0, 0], [0, -1.656]],
              v: [[11, -14], [-11, -14], [-14, -11], [-14, 11], [-11, 14], [11, 14], [14, 11], [14, -11]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 126,
      op: 370,
      st: 126,
      bm: 0,
    }, {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Button',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [{
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 126,
            s: [50, 49.5, 0],
            to: [0, -0.417, 0],
            ti: [0, 0.417, 0],
          }, {
            i: { x: 0.4, y: 0.4 },
            o: { x: 0.3, y: 0.3 },
            t: 134,
            s: [50, 47, 0],
            to: [0, 0, 0],
            ti: [0, 0, 0],
          }, {
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 362,
            s: [50, 47, 0],
            to: [0, 0.417, 0],
            ti: [0, -0.417, 0],
          }, { t: 370, s: [50, 49.5, 0] }],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-19.882, 0], [0, -19.882], [19.882, 0], [0, 19.882]],
              o: [[19.882, 0], [0, 19.882], [-19.882, 0], [0, -19.882]],
              v: [[0, -36], [36, 0], [0, 36], [-36, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 0.494117647409, 0.494117647409, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 126,
      op: 370,
      st: 126,
      bm: 0,
    }, {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Button Shadow 2',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [50, 49.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-19.882, 0], [0, -19.882], [19.882, 0], [0, 19.882]],
              o: [[19.882, 0], [0, 19.882], [-19.882, 0], [0, -19.882]],
              v: [[0, -36], [36, 0], [0, 36], [-36, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.941176470588, 0.286274509804, 0.286274509804, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 126,
      op: 370,
      st: 126,
      bm: 0,
    }, {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Ring 3',
      parent: 2,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ i: { x: [0.4], y: [1] }, o: { x: [0.3], y: [0] }, t: 156, s: [40] }, { t: 216, s: [0] }],
          ix: 11,
          x: 'var $bm_rt;\n$bm_rt = loopOut();',
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.7, 0.7, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 156,
            s: [60, 60, 100],
          }, { t: 216, s: [108, 108, 100] }],
          ix: 6,
          l: 2,
          x: 'var $bm_rt;\n$bm_rt = loopOut();',
        },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-31.48, 0], [0, -31.48], [31.48, 0], [0, 31.48]],
              o: [[31.48, 0], [0, 31.48], [-31.48, 0], [0, -31.48]],
              v: [[0, -57], [57, 0], [0, 57], [-57, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 0.494117647409, 0.494117647409, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 156,
      op: 370,
      st: 156,
      bm: 0,
    }, {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Ring 2',
      parent: 2,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ i: { x: [0.4], y: [1] }, o: { x: [0.3], y: [0] }, t: 126, s: [40] }, { t: 186, s: [0] }],
          ix: 11,
          x: 'var $bm_rt;\n$bm_rt = loopOut();',
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.7, 0.7, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 126,
            s: [60, 60, 100],
          }, { t: 186, s: [108, 108, 100] }],
          ix: 6,
          l: 2,
          x: 'var $bm_rt;\n$bm_rt = loopOut();',
        },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-31.48, 0], [0, -31.48], [31.48, 0], [0, 31.48]],
              o: [[31.48, 0], [0, 31.48], [-31.48, 0], [0, -31.48]],
              v: [[0, -57], [57, 0], [0, 57], [-57, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 0.494117647409, 0.494117647409, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 126,
      op: 370,
      st: 126,
      bm: 0,
    }, {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Block',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480, 280, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ty: 'rc',
          d: 1,
          s: { a: 0, k: [162.766, 144.705], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          r: { a: 0, k: 0, ix: 4 },
          nm: 'Rectangle Path 1',
          mn: 'ADBE Vector Shape - Rect',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [-16.416, 186.19], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Rectangle 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 126,
      op: 370,
      st: 126,
      bm: 0,
    }, {
      ddd: 0,
      ind: 9,
      ty: 5,
      nm: 'Time 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480.216, 389.846, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ef: [{
        ty: 5,
        nm: 'Slider Control',
        np: 3,
        mn: 'ADBE Slider Control',
        ix: 1,
        en: 1,
        ef: [{
          ty: 0,
          nm: 'Slider',
          mn: 'ADBE Slider Control-0001',
          ix: 1,
          v: {
            a: 1,
            k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 290, s: [0] }, { t: 534, s: [4.067] }],
            ix: 1,
          },
        }],
      }],
      t: {
        d: {
          k: [{
            s: {
              s: 32,
              f: 'Nunito-Regular',
              t: '00:04',
              ca: 0,
              j: 2,
              tr: 0,
              lh: 38.4,
              ls: 0,
              fc: [0.2, 0.2, 0.2],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 350,
      op: 414,
      st: 164,
      bm: 0,
    }, {
      ddd: 0,
      ind: 10,
      ty: 5,
      nm: 'Time 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480.216, 389.846, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ef: [{
        ty: 5,
        nm: 'Slider Control',
        np: 3,
        mn: 'ADBE Slider Control',
        ix: 1,
        en: 1,
        ef: [{
          ty: 0,
          nm: 'Slider',
          mn: 'ADBE Slider Control-0001',
          ix: 1,
          v: {
            a: 1,
            k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 226, s: [0] }, { t: 470, s: [4.067] }],
            ix: 1,
          },
        }],
      }],
      t: {
        d: {
          k: [{
            s: {
              s: 32,
              f: 'Nunito-Regular',
              t: '00:03',
              ca: 0,
              j: 2,
              tr: 0,
              lh: 38.4,
              ls: 0,
              fc: [0.2, 0.2, 0.2],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 286,
      op: 350,
      st: 100,
      bm: 0,
    }, {
      ddd: 0,
      ind: 11,
      ty: 5,
      nm: 'Time 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480.216, 389.846, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ef: [{
        ty: 5,
        nm: 'Slider Control',
        np: 3,
        mn: 'ADBE Slider Control',
        ix: 1,
        en: 1,
        ef: [{
          ty: 0,
          nm: 'Slider',
          mn: 'ADBE Slider Control-0001',
          ix: 1,
          v: {
            a: 1,
            k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 166, s: [0] }, { t: 410, s: [4.067] }],
            ix: 1,
          },
        }],
      }],
      t: {
        d: {
          k: [{
            s: {
              s: 32,
              f: 'Nunito-Regular',
              t: '00:02',
              ca: 0,
              j: 2,
              tr: 0,
              lh: 38.4,
              ls: 0,
              fc: [0.2, 0.2, 0.2],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 226,
      op: 286,
      st: 40,
      bm: 0,
    }, {
      ddd: 0,
      ind: 12,
      ty: 5,
      nm: 'Time 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480.216, 389.846, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ef: [{
        ty: 5,
        nm: 'Slider Control',
        np: 3,
        mn: 'ADBE Slider Control',
        ix: 1,
        en: 1,
        ef: [{
          ty: 0,
          nm: 'Slider',
          mn: 'ADBE Slider Control-0001',
          ix: 1,
          v: {
            a: 1,
            k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 106, s: [0] }, { t: 350, s: [4.067] }],
            ix: 1,
          },
        }],
      }],
      t: {
        d: {
          k: [{
            s: {
              s: 32,
              f: 'Nunito-Regular',
              t: '00:01',
              ca: 0,
              j: 2,
              tr: 0,
              lh: 38.4,
              ls: 0,
              fc: [0.2, 0.2, 0.2],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 166,
      op: 226,
      st: -20,
      bm: 0,
    }, {
      ddd: 0,
      ind: 13,
      ty: 5,
      nm: 'Time 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480.216, 389.846, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ef: [{
        ty: 5,
        nm: 'Slider Control',
        np: 3,
        mn: 'ADBE Slider Control',
        ix: 1,
        en: 1,
        ef: [{
          ty: 0,
          nm: 'Slider',
          mn: 'ADBE Slider Control-0001',
          ix: 1,
          v: {
            a: 1,
            k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 106, s: [0] }, { t: 350, s: [4.067] }],
            ix: 1,
          },
        }],
      }],
      t: {
        d: {
          k: [{
            s: {
              s: 32,
              f: 'Nunito-Regular',
              t: '00:00',
              ca: 0,
              j: 2,
              tr: 0,
              lh: 38.4,
              ls: 0,
              fc: [0.2, 0.2, 0.2],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: -20,
      op: 166,
      st: -20,
      bm: 0,
    }, {
      ddd: 0,
      ind: 14,
      ty: 3,
      nm: 'Mic Button',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480, 450.25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 420,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Mic',
      parent: 16,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0.5, 24.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.4, 0.4, 0.833], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 116,
            s: [100, 100, 100],
          }, {
            i: { x: [0.4, 0.4, 0.833], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 126,
            s: [108, 108, 100],
          }, {
            i: { x: [0.4, 0.4, 0.833], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.167], y: [0, 0, 0] },
            t: 370,
            s: [108, 108, 100],
          }, { t: 380, s: [100, 100, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0.553, 0], [0, 0], [0, -0.552], [0, 0], [4.768, 0.465], [0, 4.176], [0, 0], [0.553, 0], [0, 0], [0, -0.552], [0, 0], [-5.502, -0.759], [0, 0], [0, 0], [0, -0.553], [0, 0], [-0.553, 0], [0, 0], [0, 0.553], [0, 0], [0.553, 0], [0, 0], [0, 0], [0, 5.556], [0, 0]],
              o: [[0, 0], [-0.553, 0], [0, 0], [0, 4.675], [-4.157, -0.406], [0, 0], [0, -0.552], [0, 0], [-0.553, 0], [0, 0], [0, 5.602], [0, 0], [0, 0], [-0.553, 0], [0, 0], [0, 0.553], [0, 0], [0.553, 0], [0, 0], [0, -0.553], [0, 0], [0, 0], [5.357, -0.735], [0, 0], [0, -0.552]],
              v: [[10.5, 20.5], [9.5, 20.5], [8.5, 21.5], [8.5, 24.5], [-0.299, 32.461], [-7.5, 24.144], [-7.5, 21.5], [-8.5, 20.5], [-9.5, 20.5], [-10.5, 21.5], [-10.5, 24.01], [-1, 35.366], [-1, 37.5], [-4.5, 37.5], [-5.5, 38.5], [-5.5, 39.5], [-4.5, 40.5], [5.5, 40.5], [6.5, 39.5], [6.5, 38.5], [5.5, 37.5], [2, 37.5], [2, 35.389], [11.5, 24.5], [11.5, 21.5]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[-3.314, 0], [0, 3.314], [0, 0], [3.314, 0], [0, -3.314], [0, 0]],
              o: [[3.314, 0], [0, 0], [0, -3.314], [-3.314, 0], [0, 0], [0, 3.314]],
              v: [[0.5, 30.5], [6.5, 24.5], [6.5, 14.5], [0.5, 8.5], [-5.5, 14.5], [-5.5, 24.5]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 420,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Mic Button',
      parent: 14,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [{
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 116,
            s: [50, 50.25, 0],
            to: [0, 0.417, 0],
            ti: [0, -0.417, 0],
          }, {
            i: { x: 0.4, y: 0.4 },
            o: { x: 0.3, y: 0.3 },
            t: 126,
            s: [50, 52.75, 0],
            to: [0, 0, 0],
            ti: [0, 0, 0],
          }, {
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 370,
            s: [50, 52.75, 0],
            to: [0, -0.417, 0],
            ti: [0, 0.417, 0],
          }, { t: 380, s: [50, 50.25, 0] }],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0.25, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-19.882, 0], [0, -19.882], [19.882, 0], [0, 19.882]],
              o: [[19.882, 0], [0, 19.882], [-19.882, 0], [0, -19.882]],
              v: [[0, -36], [36, 0], [0, 36], [-36, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.258823543787, 0.690196096897, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 420,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Button Shadow',
      parent: 14,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [50, 52.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-19.882, 0], [0, -19.882], [19.882, 0], [0, 19.882]],
              o: [[19.882, 0], [0, 19.882], [-19.882, 0], [0, -19.882]],
              v: [[0, -36], [36, 0], [0, 36], [-36, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.184313729405, 0.501960813999, 0.929411768913, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 420,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [386.504, 161.445, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ty: 'rc',
          d: 1,
          s: { a: 0, k: [960, 200], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          r: { a: 0, k: 0, ix: 4 },
          nm: 'Rectangle Path 1',
          mn: 'ADBE Vector Shape - Rect',
          hd: false,
        }, {
          ty: 'op',
          nm: 'Offset Paths 1',
          a: {
            a: 0,
            k: 10,
            ix: 1,
            x: "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Stroke 1').strokeWidth, 2);",
          },
          lj: 1,
          ml: { a: 0, k: 4, ix: 3 },
          ix: 2,
          mn: 'ADBE Vector Filter - Offset',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 10, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [93.496, 278.555], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Rectangle 1',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 420,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 27,
      ty: 0,
      nm: 'Image',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [539, 164.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [715, 481.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [107.711, 107.711, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1430,
      h: 963,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }],
  }, {
    id: 'comp_1',
    layers: [{
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Map.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [574.81, 481, 0], ix: 2, l: 2 },
        a: { a: 0, k: [1286, 723, 0], ix: 1, l: 2 },
        s: { a: 0, k: [66.5, 66.5, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }],
  }],
  fonts: { list: [{ fName: 'Nunito-Regular', fFamily: 'Nunito', fStyle: 'Regular', ascent: 71.8990356661379 }] },
  layers: [{
    ddd: 0,
    ind: 1,
    ty: 0,
    nm: '2 - Audio',
    refId: 'comp_0',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
      a: { a: 0, k: [480, 270, 0], ix: 1, l: 2 },
      s: { a: 0, k: [200, 200, 100], ix: 6, l: 2 },
    },
    ao: 0,
    w: 960,
    h: 540,
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }],
  markers: [],
  chars: [{
    ch: '0',
    size: 32,
    style: 'Regular',
    w: 60,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-4.234, 6.2], [0, 11.8], [4.166, 6.1], [8.133, 0], [4.2, -6.133], [0, -11.8], [-4.2, -6.2], [-8.067, 0]],
              o: [[4.233, -6.2], [0, -11.8], [-4.167, -6.1], [-8.067, 0], [-4.2, 6.134], [0, 11.734], [4.2, 6.2], [8, 0]],
              v: [[48.35, -8.4], [54.7, -35.4], [48.45, -62.25], [30, -71.4], [11.6, -62.2], [5.3, -35.3], [11.6, -8.4], [30, 0.9]],
              c: true,
            },
            ix: 2,
          },
          nm: '0',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[2.7, 4.767], [0, 9.934], [-2.7, 4.667], [-5.6, 0], [-2.667, -4.633], [0, -10], [2.7, -4.766], [5.6, 0]],
              o: [[-2.7, -4.766], [0, -9.933], [2.7, -4.666], [5.666, 0], [2.666, 4.634], [0, 9.934], [-2.7, 4.767], [-5.6, 0]],
              v: [[17.55, -13.35], [13.5, -35.4], [17.55, -57.3], [30, -64.3], [42.5, -57.35], [46.5, -35.4], [42.45, -13.35], [30, -6.2]],
              c: true,
            },
            ix: 2,
          },
          nm: '0',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: '0',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: ':',
    size: 32,
    style: 'Regular',
    w: 22.8,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-0.967, 1.034], [0, 1.6], [0.966, 1], [1.666, 0], [1, -1], [0, -1.666], [-1, -1.033], [-1.667, 0]],
              o: [[0.966, -1.033], [0, -1.666], [-0.967, -1], [-1.667, 0], [-1, 1], [0, 1.6], [1, 1.034], [1.666, 0]],
              v: [[15.45, -39.55], [16.9, -43.5], [15.45, -47.5], [11.5, -49], [7.5, -47.5], [6, -43.5], [7.5, -39.55], [11.5, -38]],
              c: true,
            },
            ix: 2,
          },
          nm: ':',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[-0.967, 1.034], [0, 1.6], [0.966, 1.034], [1.666, 0], [1, -1.033], [0, -1.6], [-1, -1.033], [-1.667, 0]],
              o: [[0.966, -1.033], [0, -1.6], [-0.967, -1.033], [-1.667, 0], [-1, 1.034], [0, 1.6], [1, 1.034], [1.666, 0]],
              v: [[15.45, -1.15], [16.9, -5.1], [15.45, -9.05], [11.5, -10.6], [7.5, -9.05], [6, -5.1], [7.5, -1.15], [11.5, 0.4]],
              c: true,
            },
            ix: 2,
          },
          nm: ':',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: ':',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: '1',
    size: 32,
    style: 'Regular',
    w: 60,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 2.4], [2.533, 0], [0, 0], [0, 0], [0.633, 0.567], [0.933, 0], [0.8, -0.333], [0.866, -0.533], [0, 0], [0.166, -0.933], [-0.467, -0.8], [-0.9, -0.3], [-1.067, 0.667], [0, 0], [0, 0], [0, 0], [0, -2.266], [-2.534, 0]],
              o: [[2.533, 0], [0, -2.266], [0, 0], [0, 0], [0, -1.2], [-0.634, -0.566], [-0.8, 0], [-0.8, 0.334], [0, 0], [-1.067, 0.734], [-0.167, 0.934], [0.466, 0.8], [0.9, 0.3], [0, 0], [0, 0], [0, 0], [-2.534, 0], [0, 2.4], [0, 0]],
              v: [[48.7, 0], [52.5, -3.6], [48.7, -7], [36.7, -7], [36.7, -67.7], [35.75, -70.35], [33.4, -71.2], [31, -70.7], [28.5, -69.4], [14.7, -60.3], [12.85, -57.8], [13.3, -55.2], [15.35, -53.55], [18.3, -54.1], [28.5, -60.9], [28.5, -7], [15.5, -7], [11.7, -3.6], [15.5, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: '1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: '1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: '2',
    size: 32,
    style: 'Regular',
    w: 60,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 2.4], [2.533, 0], [0, 0], [0, 0], [-2.034, 4.034], [0, 4.2], [3.766, 3.3], [7.066, 0], [3.666, -1.3], [2.6, -2.133], [0.1, -0.966], [-0.434, -0.8], [-0.867, -0.233], [-1.067, 0.8], [-5.934, 0], [0, -8.2], [1.5, -3.1], [3.4, -3.666], [0, 0], [0, -1.4], [-0.667, -0.7], [-1.534, 0]],
              o: [[2.533, 0], [0, -2.266], [0, 0], [0, 0], [4.333, -4.533], [2.033, -4.033], [0, -6.066], [-3.767, -3.3], [-3.8, 0], [-3.667, 1.3], [-1, 0.734], [-0.1, 0.967], [0.433, 0.8], [0.866, 0.234], [4.866, -3.8], [9.533, 0], [0, 3.2], [-1.5, 3.1], [0, 0], [-1.4, 1.467], [0, 1.2], [0.666, 0.7], [0, 0]],
              v: [[50.8, 0], [54.6, -3.6], [50.8, -7], [19.4, -7], [38.4, -27.2], [47.95, -40.05], [51, -52.4], [45.35, -66.45], [29.1, -71.4], [17.9, -69.45], [8.5, -64.3], [6.85, -61.75], [7.35, -59.1], [9.3, -57.55], [12.2, -58.4], [28.4, -64.1], [42.7, -51.8], [40.45, -42.35], [33.1, -32.2], [10.7, -8.2], [8.6, -3.9], [9.6, -1.05], [12.9, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: '2',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: '2',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: '3',
    size: 32,
    style: 'Regular',
    w: 60,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-4.234, 3.6], [0, 6.067], [2.333, 3.034], [4.2, 1.134], [-2, 2.967], [0, 3.934], [3.833, 3.2], [6.8, 0], [3.6, -1.233], [2.6, -2.2], [0.166, -1], [-0.5, -0.766], [-0.9, -0.2], [-1, 0.734], [-2.634, 0.867], [-3.067, 0], [-2.434, -2], [0, -3.666], [2.633, -2.366], [4.533, 0], [0, 0], [0, -2.333], [-2.6, 0], [0, 0], [-2.734, -2.133], [0, -4.133], [2.7, -2.233], [5.066, 0], [2.933, 0.867], [2.933, 1.934], [0.933, -0.233], [0.433, -0.8], [-0.167, -0.966], [-1.067, -0.8], [-3.867, -1.133], [-3.934, 0]],
              o: [[4.233, -3.6], [0, -4.466], [-2.334, -3.033], [3.533, -1.333], [2, -2.966], [0, -5.666], [-3.834, -3.2], [-3.867, 0], [-3.6, 1.234], [-1.067, 0.8], [-0.167, 1], [0.5, 0.767], [0.9, 0.2], [2.6, -2.066], [2.633, -0.866], [4.466, 0], [2.433, 2], [0, 4.134], [-2.634, 2.367], [0, 0], [-2.6, 0], [0, 2.4], [0, 0], [5.2, 0], [2.733, 2.134], [0, 4.334], [-2.7, 2.234], [-3.134, 0], [-2.934, -0.866], [-1.134, -0.733], [-0.934, 0.234], [-0.434, 0.8], [0.166, 0.967], [2.866, 2.2], [3.866, 1.133], [7.333, 0]],
              v: [[46.55, -4.5], [52.9, -19], [49.4, -30.25], [39.6, -36.5], [47.9, -42.95], [50.9, -53.3], [45.15, -66.6], [29.2, -71.4], [18, -69.55], [8.7, -64.4], [6.85, -61.7], [7.35, -59.05], [9.45, -57.6], [12.3, -58.4], [20.15, -62.8], [28.7, -64.1], [39.05, -61.1], [42.7, -52.6], [38.75, -42.85], [28, -39.3], [21.6, -39.3], [17.7, -35.8], [21.6, -32.2], [28.7, -32.2], [40.6, -29], [44.7, -19.6], [40.65, -9.75], [29, -6.4], [19.9, -7.7], [11.1, -11.9], [8, -12.65], [5.95, -11.1], [5.55, -8.45], [7.4, -5.8], [17.5, -0.8], [29.2, 0.9]],
              c: true,
            },
            ix: 2,
          },
          nm: '3',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: '3',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: '4',
    size: 32,
    style: 'Regular',
    w: 60,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 2.8], [0, 0], [0, 0], [0, 2.334], [2.6, 0], [0, 0], [0, 0], [0.766, 0.7], [1.066, 0], [1.2, -1.866], [0, 0], [0, -1.2], [-0.767, -0.633], [-1.334, 0], [0, 0], [0, 0], [-2.734, 0]],
              o: [[0, 0], [0, 0], [2.6, 0], [0, -2.333], [0, 0], [0, 0], [0, -1.4], [-0.767, -0.7], [-1.867, 0], [0, 0], [-1.534, 2.267], [0, 1.2], [0.766, 0.634], [0, 0], [0, 0], [0, 2.8], [2.8, 0]],
              v: [[46, -3.5], [46, -15.1], [53.3, -15.1], [57.2, -18.6], [53.3, -22.1], [46, -22.1], [46, -67], [44.85, -70.15], [42.1, -71.2], [37.5, -68.4], [7, -24], [4.7, -18.8], [5.85, -16.05], [9, -15.1], [37.7, -15.1], [37.7, -3.5], [41.8, 0.7]],
              c: true,
            },
            ix: 2,
          },
          nm: '4',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 0], [0, 0]],
              o: [[0, 0], [0, 0], [0, 0]],
              v: [[13.6, -22.1], [37.7, -57.3], [37.7, -22.1]],
              c: true,
            },
            ix: 2,
          },
          nm: '4',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: '4',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }],
};

export default data;
