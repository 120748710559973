import React from "react";
import { Box, Typography } from "@mui/material";
import SearchStudentsField from './StudentSearchField'
import { labels } from "./constants"

interface Props {
    closeDialog: (type?: string, id?: string) => void
}

const SearchStudentForm = ({ closeDialog }: Props) => {
    return (
        <Box>
          <Typography variant="modalTitle" >{labels.addNewStudent}</Typography>
          <Box sx={{ marginTop: '36px' }} >
              <Typography variant="sLabel" >{labels.searchStudentByName}</Typography>
              <SearchStudentsField closeDialog={closeDialog} />
          </Box>
        </Box>
    );
}

export default SearchStudentForm;