import React from 'react';
import {
  Backdrop,
  Box,
  Modal,
  Slide,
  List, ListItem, ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ModalTypes, Student } from '../../variables/types';
import useRedirect from '../../hooks/useRedirect';
import { SKILL_LINK_TEXT } from '../../variables/constant';
import { StyledCloseIcon, StyledFlyoverBox, StyledHeaderBox, StyledSubTitleTypogrpahy, StyledTitleTypogrpahy } from './flyover-styled';
import { palette  } from '../../theme/palette';

export const styles = {
  fullscreen: {
    right: 30,
    left: 30,
    maxHeight: 'calc(100vh - 60px)',
  },
  drawer: {
    top: 0,
    bottom: 0,
    right: 0,
    minWidth: '350px',
    maxWidth: '415px',
  },
  link: {
    borderBottom: `2px solid ${palette.customBackground.disabled}`,
  },
  linkText: {
    '& .MuiListItemText-primary': {
      fontSize: '18px',
      lineHeight: '25px',
      fontWeight: 800,
      color: palette.customBlue.primaryBlue,
    },
  },
  list: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 10%)',
  },
};

interface Props {
  onClose?: any;
  open: boolean;
  variant?: ModalTypes;
  skill: any
  skillLevel?: string
}

export const StyledSliderHeader = (props: { color: any, skill: any, currentValue: string, type: any, onClick: any }) => <StyledHeaderBox
  display="grid"
  justifyContent="space-between"
  gridTemplateColumns="90% 10%"
  alignItems="flex-start"
  width="100%"
  marginBottom="20"
  color={props.color}
  style={{ backgroundColor: props.skill?.[`${props.currentValue}_color`] as string }}
>
  <Box>
    <StyledTitleTypogrpahy type={props.type}>
      {`${props.skill?.[`${props.currentValue}_label`] as string} ${props.skill?.[`${props.currentValue}_type`] as string}`}
    </StyledTitleTypogrpahy>
    <StyledSubTitleTypogrpahy type={props.type}>
      {`${props.skill?.[`${props.currentValue}_numberOfStudents`]} ${props.skill?.[`${props.currentValue}_numberOfStudents`] as number > 1 ? `${SKILL_LINK_TEXT}s` : SKILL_LINK_TEXT}`}
    </StyledSubTitleTypogrpahy>
  </Box>
  <StyledCloseIcon onClick={props.onClick} type={props.type}/>
</StyledHeaderBox>;

const Flyover = ({
  onClose,
  open,
  variant = ModalTypes.Drawer,
  skill,
  skillLevel,
}: Props) => {
  const { search } = useRedirect();

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      sx={{ display: 'flex', alignItems: 'center' }}
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <Slide
        in={open}
        direction="left"
      >
        <StyledFlyoverBox sx={{ ...styles[variant] }}>
          {skill && Object.keys(skill)?.reduce((prevValue: any[], currentValue) => {
            if (currentValue?.endsWith('_key') && currentValue === skillLevel) {
              prevValue.push(
                <>
                  <StyledSliderHeader color={skill?.color} skill={skill} currentValue={currentValue}
                    type={skill?.skill_label} onClick={onClose}/>
                  <Box>
                    <List component="nav" aria-label="students list" sx={{ ...styles.list }}>
                      {skill?.[`${currentValue}_students`]?.map((student: Student) => (
                        <ListItem
                          key={student.student_id}
                          component={Link}
                          to={{
                            pathname: `/students/${student.student_id}/view/task`,
                            search,
                          }}
                          sx={{ ...styles.link }}
                        >
                          <ListItemText
                            primary={student.student_name}
                            sx={{ ...styles.linkText }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </>,
              );
            }
            return prevValue;
          }, [])}
        </StyledFlyoverBox>
      </Slide>
    </Modal>
  );
};

export default Flyover;
