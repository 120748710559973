/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const UploadFileIcon = (props: any) => {
  const { fillColor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="56"
      viewBox="0 0 42 56"
      fill="none"
      sx={{
        fontSize: 50,
        width: '100%',
      }}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.40001 0.799805C3.64447 0.799805 0.600006 3.84427 0.600006 7.59981V48.3998C0.600006 52.1553 3.64447 55.1998 7.40001 55.1998H34.6C38.3555 55.1998 41.4 52.1553 41.4 48.3998V19.2081C41.4 17.4047 40.6836 15.6751 39.4083 14.3998L27.8 2.79148C26.5248 1.51623 24.7952 0.799805 22.9917 0.799805H7.40001ZM24.4 21.1998C24.4 19.322 22.8778 17.7998 21 17.7998C19.1222 17.7998 17.6 19.322 17.6 21.1998V27.9998H10.8C8.92224 27.9998 7.40001 29.522 7.40001 31.3998C7.40001 33.2776 8.92224 34.7998 10.8 34.7998H17.6V41.5998C17.6 43.4776 19.1222 44.9998 21 44.9998C22.8778 44.9998 24.4 43.4776 24.4 41.5998L24.4 34.7998H31.2C33.0778 34.7998 34.6 33.2776 34.6 31.3998C34.6 29.522 33.0778 27.9998 31.2 27.9998H24.4V21.1998Z" fill={fillColor || palette.customGrey.iconGrade} />
    </SvgIcon>
  );
};

export default UploadFileIcon;
