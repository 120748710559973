import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NotifierType } from '../../../variables/types';
import {
  File as APIFIle, SubmissionStatus,
  useUpdateSpeakingCompleteMutation,
  useUpdateSpeakingMutation,
} from '../../../generated/graphql';
import { useUploader } from '../../../utils/multipart-uploader';
import { getToken } from '../../../utils/auth';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { SubmitTaskContext } from '../submit-task-context';
import { RECORD_NOT_FOUND_ERROR, DELETED_ASSIGNMENT_MESSAGE } from '../../../variables/constant';
import { sentryErrorLog } from '../../../utils/sentry';

interface Props {
  id: string,
  speakings: Array<{ __typename?: 'File' } & Pick<APIFIle, 'url' | 'file_type'>>;
  refreshSubmissionData: Function;
}

const useSpeakingTask = ({ id, speakings, refreshSubmissionData }: Props) => {
  const history = useHistory();
  const [currentRecordingFileIndex, setCurrentRecordingFileIndex] = useState<number>(0);
  const [audioData, setAudioData] = useState<Pick<APIFIle, 'url' | 'file_type'> | null >(
    speakings?.length > 0 ? { url: speakings?.[currentRecordingFileIndex]?.url, file_type: speakings?.[currentRecordingFileIndex]?.file_type } : null,
  );
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [savingRecording, setSavingRecording] = useState(false);
  const {
    updateStatus,
    setPlaying,
    setRecording,
  } = useContext(SubmitTaskContext);

  const [records, setRecords] = useState<Array<{ __typename?: 'File' } & Pick<APIFIle, 'url' | 'file_type'>>>(speakings);
  const [updateSpeaking, { loading }] = useUpdateSpeakingMutation();
  const [updateSpeakingStatus, { loading: statusLoading }] = useUpdateSpeakingCompleteMutation();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (speakings) {
      setRecords(speakings);
    }
  }, [speakings]);

  useEffect(() => {
    setRecording?.(isRecording);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);

  useEffect(() => {
    setPlaying?.(isPlaying);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const handleRecordingPreviewIndexChange = (index: number) => {
    const currentAudioData = records?.length > 0 && records?.length > index ? { url: records?.[index]?.url, file_type: records?.[index].file_type } : null;
    setCurrentRecordingFileIndex(index);
    setAudioData(currentAudioData);
  };

  const handleDeletedAssignment = () => {
    openSnackbar({
      message: DELETED_ASSIGNMENT_MESSAGE,
    }, NotifierType.Error);
    history.replace('/');
  };

  const { uploadAsync, isLoading } = useUploader({
    // @ts-ignore
    uploadUrl: `${import.meta.env.REACT_APP_API_URL}/file/upload`,
    accessToken: getToken()?.idToken?.jwtToken,
  });
  const onRecord = async (file: File) => {
    setSavingRecording(true);
    try {
      if (file) {
        const result = await uploadAsync(file);

        if (!audioData) {
          setAudioData({ url: result.url, file_type: result.fileType });
        }

        const updateResponse = await updateSpeaking({
          variables: {
            input: {
              fileName: result.key,
              fileType: file.type,
              submission_id: id,
              url: result.url,
            },
          },
        });
        await refreshSubmissionData();
        setSavingRecording(false);
        setOpen(true);
        setRecords(updateResponse?.data?.updateSpeaking?.speakings!);
      }
    } catch (err) {
      sentryErrorLog(err);
      if (getErrorMessage(err) === RECORD_NOT_FOUND_ERROR) {
        handleDeletedAssignment();
      } else {
        setSavingRecording(false);
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
  };

  const onStatusChange = async () => {
    try {
      const result = await updateSpeakingStatus({ variables: { id } });
      if (result?.data?.updateSpeakingComplete?.status === SubmissionStatus.SpeakingCompleted) {
        updateStatus?.(SubmissionStatus.SpeakingCompleted);
        history.replace(`/tasks/${id}/submit/writing`);
      }
    } catch (err) {
      if (getErrorMessage(err) === RECORD_NOT_FOUND_ERROR) {
        handleDeletedAssignment();
      } else {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
  };

  return {
    records,
    audioData,
    loading: loading || statusLoading || isLoading || savingRecording,
    onRecord,
    onStatusChange,
    isRecording,
    setIsRecording,
    isPlaying,
    setIsPlaying,
    open,
    setOpen,
    handleCurrentRecordingChange: handleRecordingPreviewIndexChange,
    currentRecordingFileIndex,
    setCurrentRecordingFileIndex,
  };
};

export default useSpeakingTask;
