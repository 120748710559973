import React from 'react';
import { Portal } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledBackdrop } from './Loader.styled';

type Props = {
  open: boolean
};

const Loader = ({ open }: Props) => {
  return (
    <Portal>
      <StyledBackdrop open={open}>
        <CircularProgress color="primary" />
      </StyledBackdrop>
    </Portal>
  );
};

export default React.memo(Loader);
