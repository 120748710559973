import React from 'react';
import { Autocomplete } from '@mui/material';
import { Maybe, PredefinedGrades } from '../../generated/graphql';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { SxProps, Theme } from '@mui/system';
import { palette } from '../../theme/palette';

interface Props {
  onChange: (data: PredefinedGrades | null | string, oldValue: PredefinedGrades | null) => void;
  value?: PredefinedGrades | null | string;
  preDefinedGrades: Maybe<PredefinedGrades>[];
  isLoading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  idSelectOnly?: boolean;
  className?: string;
  noOptionPlaceholder?: string;
  sx?: SxProps<Theme> | undefined;
}

type GradeValue = PredefinedGrades | null | undefined;

/**
 * SelectGrade: Allow's user to select grade from a autocomplete dropdown
 * @param Props
 * @returns Autocomplete dropdown to select grade
 */
const SelectGrade = ({
  className,
  preDefinedGrades,
  value,
  placeholder,
  noOptionPlaceholder,
  onChange,
  disabled = false,
  readOnly = false,
  isLoading = false,
  idSelectOnly = false,
  sx,
}:Props) => {
  const onSelectChange = (_: any, grade: PredefinedGrades | null ) => {
    onChange(idSelectOnly ? (grade?.id ?? '') : grade, grade);
  };
  const selectedValue: GradeValue = idSelectOnly ? preDefinedGrades?.find((item) => item?.id === value ) ?? null : value as GradeValue;
  return (
    <Autocomplete
      className={className}
      readOnly={readOnly}
      sx={sx}
      options={preDefinedGrades || []}
      getOptionLabel={(option: any) => option.grade ?? option}
      isOptionEqualToValue={(option: any, selected: any) => option?.grade === selected?.grade}
      onChange={onSelectChange}
      value={selectedValue}
      disabled={disabled || isLoading}
      noOptionsText={noOptionPlaceholder || 'No options'}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            style: {
              ...(sx ? {  backgroundColor: palette.customWhite.whiteSmoke } : { backgroundColor: 'inherit' }),
            },
          }}
        />
      )}
    />
  );
};

export default React.memo(SelectGrade);
