/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const BackIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M6.28033 10.5303C5.98744 10.8232 5.51256 10.8232 5.21967 10.5303L0.71967 6.03033C0.426777 5.73744 0.426777 5.26256 0.71967 4.96967L5.21967 0.46967C5.51256 0.176777 5.98744 0.176777 6.28033 0.46967C6.57322 0.762563 6.57322 1.23744 6.28033 1.53033L3.06066 4.75H11.75C12.1642 4.75 12.5 5.08579 12.5 5.5C12.5 5.91421 12.1642 6.25 11.75 6.25L3.06066 6.25L6.28033 9.46967C6.57322 9.76256 6.57322 10.2374 6.28033 10.5303Z" fill={palette.customBlue.primaryBlue}/>
  </SvgIcon>
);

export default BackIcon;
