import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { StudentsIcon } from '../../../components/Icons';
import { openSnackbar } from '../../../components/Notifier';
import { useAuthProvider } from '../../../core/authContext';
import {
  Maybe,
  PredefinedGradeFragmentFragment,
  SchoolPredefinedGradeMappings,
  SectionCategory,
  useCreateStudentFormMutation,
  useGetTeacherByUserQuery,
  usePredefinedGradesListQuery,
  usePrimarySectionsOfTeacherQuery,
  useSchoolPredefinedGradesMappingQuery,
  useStudentEditFormQuery,
  useUpdateStudentFormMutation,
} from '../../../generated/graphql';
import getErrorMessage from '../../../utils/getErrorMessage';
import sortGradeMapping from '../../../utils/sortGradeMapping';
import { NotifierType } from '../../../variables/types';
import { HERITAGE_LANGUAGE_FREQUENT_OPTIONS, HERITAGE_LANGUAGE_OTHER_VALUE } from '../../Teachers/TeacherForm/constants';

type StudentFormData = {
  id: string
  state_id: string
  district_id: string
  school_id: string
  section_ids: string[]
  predefined_grade_id: string
  first_name: string
  last_name: string
  student_id: string
  password: string
  email: string
  heritage_language: string
  demographic_info: string,
  heritage_other_language: string
  language_level: string
};

interface Props {
  refetch: () => void;
  closeDialog: () => void;
}

const classTypes = [SectionCategory.Class, SectionCategory.CustomClass];

const useStudentForm = ({ refetch, closeDialog }: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
    clearErrors,
  } = useForm<StudentFormData>();
  const [checked, setChecked] = useState(false);
  const [selectedDistrictId, setDistrictId] = useState('');
  const [createStudentForm, { loading: createLoading } ] = useCreateStudentFormMutation();
  const [updateStudentForm, { loading: updateLoading } ] = useUpdateStudentFormMutation();
  const history = useHistory();
  const [scrollNode, setScrollNode] = useState<HTMLDivElement>();
  const { getUser } = useAuthProvider();
  const user = getUser();

  const handleScroll = () => {
    if (scrollNode)
      scrollNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  };
  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setChecked(event.target.checked);
  };

  const [editPassword, setEditPassword] = useState(false);
  const editPasswordToggle = () => {
    setEditPassword(!editPassword);
  };

  const onSchoolChange = (district?: string | null) => {
    if (district) {
      setValue('district_id', district);
      setDistrictId(district);
      clearErrors('district_id');
    }
  };

  const heritageLanguageOptions = useMemo(() => HERITAGE_LANGUAGE_FREQUENT_OPTIONS, []);
  const [studentId] = useQueryParam<string>('student');

  if (studentId) {
    register('id', { required: true });
  }

  const { data: preDefinedGrades, loading: preDefinedGradesLoading } = usePredefinedGradesListQuery({
    fetchPolicy: 'network-only',
  });

  const { data: studentDetails, loading: studentDetailsLoading } = useStudentEditFormQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: studentId!,
    },
    skip: !studentId,
  });

  useEffect(() => {
    if (studentDetails) {
      const student = studentDetails?.viewStudent;
      const heritageLanguageValue = heritageLanguageOptions.find((item) => item.value === student?.heritage_language);

      reset({
        id: student?.id,
        district_id: student?.district_id,
        state_id: student?.state_id ?? '',
        school_id: student?.school_id,
        section_ids: student?.sections.map((section) => section.id),
        predefined_grade_id: student && student?.grade
          ? preDefinedGrades?.allPredefinedGrades?.find((preGrade: PredefinedGradeFragmentFragment) => preGrade?.grade === student?.grade)?.id as string : '',
        first_name: student?.first_name,
        last_name: student?.last_name,
        student_id: student?.student_id,
        email: student?.email || undefined,
        heritage_language: (student?.heritage_language) ? ((!heritageLanguageValue) ? HERITAGE_LANGUAGE_OTHER_VALUE : student?.heritage_language) : undefined,
        heritage_other_language: (!heritageLanguageValue) ? (student?.heritage_language || '') : '',
        demographic_info: student?.demographic_info || undefined,
        language_level: student?.language_level || undefined,
      });
    }
  }, [heritageLanguageOptions, studentId, preDefinedGrades, reset, studentDetails]);

  const createStudent = async (student: StudentFormData) => {
    const studentName = `${student.first_name} ${student.last_name}`;
    const successMessage = `${studentName} student was ${student.id ? 'updated' : 'added'}!`;
    const editPasswordMessage = 'New password has been created.';
    student.district_id = studentDetails && studentDetails!.viewStudent.district_id ? studentDetails!.viewStudent.district_id :  selectedDistrictId;
    student.school_id = studentDetails && studentDetails!.viewStudent.school_id ? studentDetails!.viewStudent.school_id : student.school_id;
    const inputData = {
      id: student.id,
      district_id: student.district_id,
      school_id: student.school_id,
      section_ids: student.section_ids,
      predefined_grade_id: student.predefined_grade_id,
      demographic_info: student.demographic_info,
      language_level: student.language_level,
      first_name: student.first_name.trim(),
      last_name: student.last_name.trim(),
      student_id: student.student_id?.trim()?.toLowerCase(),
      email: student.email?.trim()?.toLowerCase(),
      heritage_language: (student.heritage_language === HERITAGE_LANGUAGE_OTHER_VALUE) ? student.heritage_other_language : student.heritage_language,
      password: student.password,
    };
    try {
      if (inputData.id != null) {
        await updateStudentForm({
          variables: {
            input: inputData,
          },
        });
      } else {
        await createStudentForm({
          variables: {
            input: inputData,
          },
        });
      }
      const studentDetailsPath = '/students';
      refetch();
      if (checked) {
        handleScroll();
        reset({
          id: '',
          state_id: student.state_id,
          district_id: student.district_id,
          school_id: student.school_id,
          section_ids: [],
          predefined_grade_id: '',
          first_name: '',
          last_name: '',
          student_id: '',
          password: '',
          email: '',
          heritage_language: '',
          heritage_other_language: '',
          demographic_info: '',
          language_level: '',
        });
      } else {
        closeDialog();
      }
      openSnackbar({
        message: successMessage,
        customIcon: StudentsIcon,
        actionButtonText: 'View',
        onActionButtonClick: () => { history.push(studentDetailsPath); },
      }, NotifierType.Success);
      if (editPassword) {
        openSnackbar({
          message: editPasswordMessage,
          customIcon: StudentsIcon,
        }, NotifierType.Success);
        editPasswordToggle();
      }
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };
  const selectedSchoolId = watch('school_id');
  const selectedClassIds = watch('section_ids');
  const heritageLanguage = watch('heritage_language');

  const { data: schoolsPredefinedGrades, loading: schoolPredefinedGradesLoading } = useSchoolPredefinedGradesMappingQuery({
    fetchPolicy: 'network-only',
    variables: {
      schoolID: selectedSchoolId!,
    },
    skip: !selectedSchoolId,
  });

  const getPredefinedGradesForSchool = useCallback(() => sortGradeMapping([...schoolsPredefinedGrades?.schoolPredefinedGrades ?? []] as Maybe<SchoolPredefinedGradeMappings>[])?.map((schoolPredefinedGrade) => schoolPredefinedGrade?.predefined_grades), [schoolsPredefinedGrades]);

  const { data } = usePrimarySectionsOfTeacherQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...(selectedSchoolId ? { schoolID: selectedSchoolId as string } : {}),
      ...(classTypes ? { classTypes: classTypes as SectionCategory[] } : {}),
    },
  });

  const { data: teacherDetails } = useGetTeacherByUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      userID: user.id,
    },
    skip: !user.id,
  });

  const isTeacherPrimaryInSection = () => {
    const sectionIds = studentDetails?.viewStudent.sections;
    const sections = data?.primarySectionsOfTeacher ?? [];
    const sectionIdSet = new Set(sectionIds?.map(id => id.id));
    // By using loops, we can short-circuit the iteration as soon as a primary teacher is found
    for (const section of sections) {
      if (sectionIdSet.has(section.id)) {
        for (const teacher of section.teachers) {
          if (teacher?.id === teacherDetails?.teacherByUser.id && teacher?.primary_teacher) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const isPrimaryInSelectedSections = () => {
    const sections = data?.primarySectionsOfTeacher ?? [];
    for (const section of sections) {
      if (selectedClassIds && selectedClassIds.includes(section.id)) {
        for (const teacher of section.teachers) {
          if (teacher?.id === teacherDetails?.teacherByUser.id && teacher?.primary_teacher) {
            return true;
          }
        }
      }
    }
    return false;
  };


  return {
    loading: schoolPredefinedGradesLoading || createLoading || updateLoading || studentDetailsLoading || preDefinedGradesLoading,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    createStudent,
    handleChange,
    onSchoolChange,
    checked,
    selectedSchoolId,
    selectedClassIds,
    editPassword,
    editPasswordToggle,
    preDefinedGrades: getPredefinedGradesForSchool(),
    heritageLanguageOptions,
    heritageLanguage,
    source: studentDetails?.viewStudent.source,
    initialSections: studentDetails?.viewStudent.sections,
    setScrollNode,
    teacherDetails,
    teacherPrimaryInSections: isTeacherPrimaryInSection(),
    isPrimaryInSelectedSections: isPrimaryInSelectedSections(),
  };
};

export default useStudentForm;
