import React from 'react';

const YaxisCustomizeTick = (props: any) => {
  const { x, y, payload  } = props;

  return (
    <text x={x - 15} y={y} dy={3} textAnchor="middle" fill="black" fontWeight={600} >
      {payload.value + '.0'}
    </text>
  );
};

export default YaxisCustomizeTick;
            
            