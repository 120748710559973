import React from 'react';
import Box from '@mui/material/Box';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  DashboardIcon,
} from '../Icons';
import {
  StyledNavigationButton,
  StyledNavigationButtonBox,
} from './TeacherOptions';
import BackPackIcon from '../Icons/BackPackIcon';
import ProgressIcon from '../Icons/ProgressIcon';
import { palette  } from '../../theme/palette';

const active = {
  color: palette.customWhite.main,
  boxSizing: 'border-box',
  backgroundColor:palette.customBlue.primaryBlue,
};

const StudentOptions = () => {
  const history = useHistory();

  const navigateTo = (path: string) => () => {
    history.push(path);
  };

  return (
    <Box width="100%">
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo('/')}
      >
        <StyledNavigationButtonBox
          sx={ useRouteMatch({ path: '/', exact: true }) ? active : undefined }
        >
          <DashboardIcon sx={{ mr: '12px' }}/>
          Dashboard
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo('/progress')}
      >
        <StyledNavigationButtonBox
          sx={ useRouteMatch({ path: ['/progress', '/progress/score'], exact: true }) ? active : undefined }
        >
          <ProgressIcon sx={{ mr: '12px' }} />
          Progress
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
      <StyledNavigationButton
        fullWidth
        onClick={navigateTo('/backpack')}
      >
        <StyledNavigationButtonBox
          sx={ useRouteMatch({ path: ['/backpack', '/backpack/:id/view'], exact: true }) ? active : undefined }
        >
          <BackPackIcon sx={{ mr: '12px' }} />
          Backpack
        </StyledNavigationButtonBox>
      </StyledNavigationButton>
    </Box>
  );
};

export default StudentOptions;
