import { useTheme } from '@mui/material/styles';
import {
  BenchmarkScoresWidgetStylesCSS,
} from '../../../../../TeacherBenchmarksDashboard/IndividualStudentDashboard/BenchmarkScoresWidget/BenchmarkScoresWidget.styled';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { StyledTableContainer, tableWithNoDataStyles } from '../../../../../../components/Common/Common.styled';
import { StyledTableCell4 } from '../../../../../Admin/admin-tables-styled';
import NoResults from '../../../../../../components/NoResults';
import { AnalyticsIcon } from '../../../../../../components/Icons';
import React from 'react';
import ListRow from './ListRow';
import { AdminBenchmarkGrowthReportChartData } from '../../benchmark-summary-hook';

interface Props {
  tableDomId?: string;
  isTableEmpty: boolean,
  loading: boolean,
  totalCount: number,
  benchmarkScores: AdminBenchmarkGrowthReportChartData[],
}

const List = ({
  tableDomId,
  isTableEmpty,
  loading,
  benchmarkScores,
}: Props) => {
  const theme = useTheme();
  const styles = BenchmarkScoresWidgetStylesCSS(theme);

  return (
    <Box
      display="grid"
      width="100%"
      height="100%"
    >
      <Box>
        <StyledTableContainer sx={{ ...styles.tableContainer }}>
          <Table
            id={tableDomId}
            stickyHeader
            aria-label="Student Benchmarks Scores Table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell4 sx={{ ...styles.width65, ...styles.tableCellTextAlignLeft }}>
                  Benchmark Type
                </StyledTableCell4>
                <StyledTableCell4 sx={styles.width15}>
                  Speaking
                </StyledTableCell4>
                <StyledTableCell4 sx={styles.width15}>
                  Writing
                </StyledTableCell4>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="We Need More Data."
                      description=""
                      svgComponent={<AnalyticsIcon />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {!loading && benchmarkScores?.map((benchmark, index) => (
                <ListRow rowIndex={index} data={benchmark} key={`admin-benchmark-${Math.random()}`} />
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </Box >
  );
};
export default List;
