export const shuffleArray = (array:any[]) => {
  const duplicate:any[] = [...array];
  for (let i:number = duplicate.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [duplicate[i], duplicate[j]] = [duplicate[j], duplicate[i]];
  }
  return duplicate[0];
};

// First or Second position of original array will be returned in most cases
export const biasedShuffleArray = (array:any[]) => array.sort(() => Math.random() - 0.5)[0];
