import React from 'react';
import {
  Checkbox,
  TableCell,
  TableRow,
} from '@mui/material';

interface Props {
  flag: string,
  checked: boolean,
  featureFlagDispatch: Function,
}

const Flag = ({ flag, checked, featureFlagDispatch }: Props) => {
  const handleChange = () => {
    featureFlagDispatch({
      type: 'UPDATE_FLAG',
      payload: {
        [flag]: !checked,
      },
    });
  };

  return (
    <TableRow>
      <TableCell>{flag}</TableCell>
      <TableCell>
        <Checkbox checked={checked} onChange={handleChange} />
      </TableCell>
    </TableRow>
  );
};

export default Flag;
