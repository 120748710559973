import { createContext } from 'react';

export interface AnalyticsContextValue {
  assignment?: string | null,
  selectedSection?: string | null,
  selectedSchool?: string | null,
}
export const AnalyticsContext = createContext<AnalyticsContextValue>({
  assignment: '',
  selectedSection: '',
  selectedSchool: '',
});
