import React from 'react';
import List from '../AdminStudents/StudentsTable/List';
import useStudents from '../AdminStudents/StudentsTable/students-hook';

interface Props {
  districtID?: string;
  schoolID?: string;
  classID?: string;
  teacherID?: string;
}
const StudentsList = ({ districtID, schoolID, classID, teacherID }: Props) => {
  const {
    students,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    rowsPerPage,
    setRowsPerPage,
  } = useStudents({ districtID, schoolID, classID, teacherID });
  return (
    <List
      marginTop={0}
      sxProps={{ borderTopLeftRadius: '0px' }}
      isTableEmpty={isTableEmpty}
      loading={loading}
      totalCount={totalCount}
      order={order}
      pagination={pagination}
      sort={sort}
      students={students}
      handlePageChange={handlePageChange}
      handleSort={handleSort}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};

export default StudentsList;