import React from 'react';
import { Box, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import ScoringStackedBar from './ScoringStackedBar';
import { DEFAULT_SCHEME } from '../../variables/constant';

const ScoringRubric = ({ stackedBarData, stackedBarDataColor, yAxisDomain, scoreSchemes, scoreSchemeType }: any) => {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      align: 'left',
      marginLeft: '-70px',
    }}>
      {stackedBarData && stackedBarDataColor && <ScoringStackedBar
        barData={stackedBarData}
        barDataColor={stackedBarDataColor}
        yAxisDomain={yAxisDomain}
        barWidth={100}
        barHeight={300}
        barSize={20} />}
      {scoreSchemes?.length && <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        {
          scoreSchemes?.slice(0).reverse().map((score: any) => (
            <Box key={score?.title_key} sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '3px',
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: scoreSchemeType === DEFAULT_SCHEME ? '215px' : '100%',
                height: scoreSchemeType === DEFAULT_SCHEME ? '60px' : '78px',
                color: palette.customBlack.fontBlack,
              }}>
                <Typography sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '19.1px',
                  color: palette.customBlack.fontBlack,
                }}>{score?.title + '(' + score?.range_start + ' - ' + score?.range_end + ')'}</Typography>
                <Typography sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '16.37px',
                  color: palette.customBlue.chipDark,
                }}>{score?.description}</Typography>
              </Box>
            </Box>
          ))
        }
      </Box>}
    </Box>
  );
};

export default ScoringRubric;