import { Maybe, SchoolPredefinedGradeMappings } from '../generated/graphql';

const sortGradeMapping = (predefineGradeMapping: Maybe<SchoolPredefinedGradeMappings>[]) => predefineGradeMapping?.sort(
  (a, b) => {
    if (!a || !a.predefined_grades || !a.predefined_grades.position) {
      return 1;
    }
    if (!b || !b.predefined_grades || !b.predefined_grades.position) {
      return -1;
    }
    if (a?.predefined_grades?.position > b?.predefined_grades?.position) {
      return 1;
    }
    if (a?.predefined_grades?.position < b?.predefined_grades?.position) {
      return -1;
    }
    return 0;
  },
);

export default sortGradeMapping;
