import { useContext, useState } from 'react';
import { GraphSetData, SpeakingSkills } from '../../../variables/types';
import { SPEAKING_SKILL_TYPE_TEXT, SKILL_LINK_TEXT } from '../../../variables/constant';
import { AnalyticsContext } from '../analytics-context';
import { useSpeakingScoreSummaryQuery } from '../../../generated/graphql';

const useSpeaking = () => {
  const [currentSkill, setCurrentSkill] = useState<GraphSetData | null>(null);
  const { selectedSection, assignment, selectedSchool } = useContext(AnalyticsContext);
  const [tableData, setTableData] = useState<GraphSetData | null>(null);
  const [showPreview, setShowPreview] = useState(false);

  const togglePreview = () => setShowPreview(!showPreview);

  const { data, loading } = useSpeakingScoreSummaryQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...(selectedSection ? { sectionIDs: [selectedSection as string] } : {}),
      ...(assignment ? { assignmentIDs: [assignment] } : {}),
      ...(selectedSchool ? { schoolIDs: [selectedSchool as string] } : {}),
    },
  });

  const getData = (skill: SpeakingSkills) => {
    let totalCount = 0;
    let graphData = null;
    const currentSkillData = data?.speakingScoreSummary?.filter((item) => item?.skill_key === skill ?? []);
    const image = currentSkillData?.[0]?.icon_base64;
    if (currentSkillData) {
      graphData = currentSkillData?.[0]?.scores?.reduce((prevValue:any, currentValue:any) => {
        const count = currentValue?.total_count;
        totalCount += count;
        if (currentValue?.skill_label) {
          return [...prevValue, {
            ...(currentValue),
            numberOfStudents: count,
            skillType: SPEAKING_SKILL_TYPE_TEXT[skill],
            students: currentValue?.students,
            skillKey: currentValue?.skill_label,
            skillLinkText: SKILL_LINK_TEXT,
          }];
        }
        return prevValue;
      }, []);
    }
    return {
      totalCount, graphData, image,
    };
  };
  const { graphData: grammarData, totalCount: grammarCount, image: grammarImage } = getData(SpeakingSkills.Grammar);
  const { graphData: vocabularyData, totalCount: vocabularyCount, image: vocabularyImage } = getData(SpeakingSkills.Vocabulary);
  const { graphData: pronunciationData, totalCount: pronunciationCount, image: pronunciationImage } = getData(SpeakingSkills.Pronunciation);
  const { graphData: interpretationData, totalCount: interpretationCount, image: interpretationImage } = getData(SpeakingSkills.Interpretation);
  const { graphData: fluencyData, totalCount: fluencyCount, image: fluencyImage } = getData(SpeakingSkills.Fluency);

  return ({
    grammarData,
    grammarCount,
    grammarImage,
    vocabularyData,
    vocabularyCount,
    vocabularyImage,
    pronunciationData,
    pronunciationCount,
    pronunciationImage,
    interpretationData,
    interpretationCount,
    interpretationImage,
    fluencyData,
    fluencyCount,
    fluencyImage,
    loading,
    currentSkill,
    setCurrentSkill,
    getData,
    togglePreview,
    showPreview,
    tableData,
    setTableData,
  });
};

export default useSpeaking;
