import axios from 'axios';
import { getToken } from './auth';
import { useImitationProvider } from '../core/imitationContext';

const useDownloadCsv = () => {
  const {
    getImitationUserId,
  } = useImitationProvider();
  const imitationUserId = getImitationUserId();
  const downloadCsv = async (selectedSchools: string[], selectedBenchmarkTypes: string[]) => {
    try {
      const benchmarkTypes = selectedBenchmarkTypes.map((type) => {
        return type.toLowerCase();
      }).flat().filter((type) => type !== 'all');
      const accessToken = getToken()?.idToken?.jwtToken;
      const response = await axios.post(`${import.meta.env.REACT_APP_API_URL}/file/teacher/csv/benchmarks`, {
        school_ids: selectedSchools,
        benchmark_types: benchmarkTypes,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`, ...(
            imitationUserId && accessToken ? {
              ImpersonatorId: imitationUserId,
            } : {}
          ),
        },
      });
      return response;

    } catch (error: any) {
      console.error('Error downloading CSV', error);
      return error;

    }
  };

  return { downloadCsv };

};
export default useDownloadCsv;
