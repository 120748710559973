/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const RocketIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="72"
    viewBox="0 0 42 72"
    fill="none"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M21.0069 0C26.9394 3.67002 38.1041 17.2684 35.3032 42.3018C38.8044 44.8129 44.7563 53.196 40.555 66.6398L30.3433 57.0785H11.6567L1.44504 66.6398C-2.75634 53.196 3.19561 44.8129 6.69676 42.3018C3.89584 17.2684 15.0606 3.67002 20.9931 0H21.0069ZM21.0069 25.6418C22.8599 25.6418 24.3622 24.1501 24.3622 22.3099C24.3622 20.4697 22.8599 18.9779 21.0069 18.9779C19.1538 18.9779 17.6516 20.4697 17.6516 22.3099C17.6516 24.1501 19.1538 25.6418 21.0069 25.6418Z" fill={props.pathFill || palette.customWhite.main} />
    <path d="M25.2374 60.5553H16.7763V63.7218C16.7763 66.7951 21.1528 72 21.1528 72C21.1528 72 25.2374 66.8882 25.2374 63.7218V60.5553Z" fill={props.pathFill || palette.customWhite.main} />
  </SvgIcon>
);

export default React.memo(RocketIcon);
