import { useMemo } from 'react';
import FileIcon from '../../../components/Icons/FileIcon';

const useRubricIndicatorsStandard = () => {
  const rubricIndicatorStandardsList = useMemo(() => [
    {
      title: 'Rubric & Indicators',
      Icon: FileIcon,
      buttonTitle: 'VIEW',
      redirectionLink: '/toolbox/rubric-indicators-standards/rubric',
      description: 'Descriptions of the scales and indicators for how speaking and writing are measured.',
    },
    {
      title: 'Standards Overview',
      Icon: FileIcon,
      buttonTitle: 'VIEW',
      redirectionLink: '/toolbox/rubric-indicators-standards/standards-overview',
      description: 'Description of how the English Language Arts and English Language Development Standards and Frameworks function and how they are engaged within Flashlight360.',
    },
    // { TO-DO in next release
    //   title: 'Early Childhood Standards',
    //   Icon: FileIcon,
    //   buttonTitle: 'VIEW',
    //   redirectionLink: '/toolbox/rubric-indicators-standards/childhood-standards',
    //   description: 'Here is a brief summary or abstract of this resource. This summary might be a single sentence long or a few sentences long.',
    // },
    {
      title: 'K-5 Standards',
      Icon: FileIcon,
      buttonTitle: 'VIEW',
      redirectionLink: '/toolbox/rubric-indicators-standards/k-5',
      description: 'Correlations between Flashlight360 and the K-5 Standards.',
    },
    {
      title: '6-8 Standards',
      Icon: FileIcon,
      buttonTitle: 'VIEW',
      redirectionLink: '/toolbox/rubric-indicators-standards/6-8',
      description: 'Correlations between Flashlight360 and the 6-8 Standards.',
    },
    {
      title: '9-12 Standards',
      Icon: FileIcon,
      buttonTitle: 'VIEW',
      redirectionLink: '/toolbox/rubric-indicators-standards/9-12',
      description: 'Correlations between Flashlight360 and the 9-12 Standards.',
    },
  ], []);

  return ({
    rubricIndicatorStandardsList,
  });
};

export default useRubricIndicatorsStandard;
