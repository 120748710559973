import React from 'react';
import { Backdrop } from '@mui/material';
import zIndex from '../../theme/z-index';

interface Props {
  open: boolean,
  onClose: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  children: React.ReactNode
}

const BackdropModal = ({ open, onClose, children }: Props) => {
  return (
    <Backdrop
      open={open}
      aria-labelledby="Preview"
      aria-describedby="Image Preview"
      onClick={onClose}
      sx={{ zIndex: zIndex.zIndex99999 - 1 }}
    >
      {children}
    </Backdrop>
  );
};

export default BackdropModal;
