import {
  GradeSortType,
  OrderBy,
  useGetAllPredefinedGradesQuery,
  useGetLastSnowflakeUpdateTimeQuery,
  useMyDistrictQuery,
  useMySchoolQuery,
  useSchoolsFilterQuery,
} from '../../../generated/graphql';
import useRole from '../../../hooks/useRole';
import useAdminTeacherFilter from '../../../components/MultiSelectAdminTeacherFilter/admin-teacher-filter-hook';


export interface AdminBenchmarkReportFiltersProps {
  schools?: string[],
  grades?: string[],
  teachers?: string[],
}

type GradeInput = {
  districtID?: string[],
  schoolIDs?: string[],
  sort?: GradeSortType,
  order?: OrderBy,
};

const useAdminBenchmarkReport = ({ selectedGrades, selectedSchools } : any) => {
  const { isSchoolAdmin, isDistrictAdmin } = useRole();
  
  const { data: myDistrictData, loading: myDistrictDataLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
  });
  
  const { data: mySchoolData, loading: mySchoolDataLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: !isSchoolAdmin,
  });

  const districtId = myDistrictData?.myDistrict?.id;
  const schoolId = mySchoolData?.mySchool?.id;

  const reqQuery: GradeInput = {
    sort: GradeSortType.Position,
    order: OrderBy.Asc,
  };
  if (isDistrictAdmin && districtId) reqQuery.districtID = [districtId];
  if (isSchoolAdmin && schoolId) reqQuery.schoolIDs = [schoolId];
  const { data: grades, loading: gradesLoading } = useGetAllPredefinedGradesQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...reqQuery,
    },
  });
  
  const { data: schools, loading: loadingSchools } = useSchoolsFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      districtIDs: [districtId as string],
    },
    skip: myDistrictDataLoading,
  });
  
  const { teachers, loadingTeachers } = useAdminTeacherFilter({
    districtId: districtId,
    schoolIds: isSchoolAdmin ? [schoolId] : selectedSchools,
    gradeIds: selectedGrades,
  });

  const { data: lastSnowflakeUpdateTimeData, loading: getLastSnowflakeUpdateTimeLoading } = useGetLastSnowflakeUpdateTimeQuery({
    fetchPolicy: 'network-only',
  });

  return {
    lastSnowflakeUpdateTimeData: lastSnowflakeUpdateTimeData?.getLastSnowflakeUpdateTime,
    getLastSnowflakeUpdateTimeLoading,
    schoolsData: schools?.schoolsFilterList?.map(
      school => ({ name: school?.name, id: school?.id, district_id: school?.district_id })) || [],
    loadingSchools,
    mySchoolData,
    mySchoolDataLoading,
    gradesData: grades?.allPredefinedGrades,
    gradesLoading,
    teachers: teachers?.map(teacher => (
      {
        name: teacher?.first_name + ' ' + teacher?.last_name,
        id: teacher?.id, 
      })) || [],
    loadingTeachers,
    isDistrictAdmin,
    isSchoolAdmin,
    districtId: districtId,
    schoolId: schoolId,
  };
};
export default useAdminBenchmarkReport;