import help from '../Images/help.jpg';

const data = {
  v: '5.7.6',
  fr: 60,
  ip: 0,
  op: 480,
  w: 1920,
  h: 1080,
  nm: '1920x1080 - Tagging',
  ddd: 0,
  assets: [{ id: 'image_0', w: 2572, h: 1446, u: '', p: help, e: 0 }, {
    id: 'comp_0',
    layers: [{
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Cursor',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [{
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 0,
            s: [284, 473, 0],
            to: [0, 0, 0],
            ti: [-196.259, 109.657, 0],
          }, {
            i: { x: 0.4, y: 0.4 },
            o: { x: 0.3, y: 0.3 },
            t: 21,
            s: [712, 347, 0],
            to: [0, 0, 0],
            ti: [0, 0, 0],
          }, {
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 109,
            s: [712, 347, 0],
            to: [-146.072, 41.938, 0],
            ti: [178.311, 37.853, 0],
          }, {
            i: { x: 0.4, y: 0.4 },
            o: { x: 0.3, y: 0.3 },
            t: 127,
            s: [353, 352, 0],
            to: [0, 0, 0],
            ti: [0, 0, 0],
          }, {
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 303,
            s: [353, 352, 0],
            to: [21.667, 9.667, 0],
            ti: [-21.667, -9.667, 0],
          }, { t: 317, s: [483, 410, 0] }],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 49,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 59,
            s: [150, 150, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 67,
            s: [220, 220, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 75,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 152,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 162,
            s: [150, 150, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 166,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 319,
            s: [200, 200, 100],
          }, {
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 329,
            s: [150, 150, 100],
          }, { t: 333, s: [200, 200, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.311, -0.141], [0, 0], [0, 0], [0.144, 0.334], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.208, 0.093], [0, 0.274], [0, 0], [-0.306, -0.341], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.526, 0]],
              o: [[0, 0], [0, 0], [0, 0], [0, 0], [0.139, 0.336], [0, 0], [0, 0], [-0.297, 0.13], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.202, 0.208], [-0.218, -0.098], [0, 0], [0, -0.619], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.392, 0.382], [0, 0]],
              v: [[2.257, 4.068], [1.512, 4.068], [1.795, 4.757], [5.284, 13.257], [5.285, 13.258], [4.954, 14.11], [1.884, 15.448], [1.884, 15.448], [1.087, 15.106], [-2.228, 7.038], [-2.518, 6.333], [-3.049, 6.88], [-8.465, 12.451], [-8.465, 12.451], [-9.119, 12.585], [-9.499, 11.996], [-9.499, -14.859], [-8.479, -15.329], [-8.472, -15.321], [-8.465, -15.314], [9.31, 2.97], [9.31, 2.97], [9.32, 2.979], [8.888, 4.068]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 1, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 480,
      st: -67,
      bm: 0,
    }, {
      ddd: 0,
      ind: 2,
      ty: 3,
      nm: 'Tag Control',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 333, s: [0] }, { t: 343, s: [100] }],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [695.164, 318.108, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.4, 0.4, 0.667], y: [1, 1, 1] },
            o: { x: [0.3, 0.3, 0.333], y: [0, 0, 0] },
            t: 333,
            s: [80, 80, 100],
          }, { t: 343, s: [100, 100, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 312,
      op: 480,
      st: 312,
      bm: 0,
    }, {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: 'Written Tag',
      parent: 2,
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 1, k: [{ i: { x: [0.4], y: [1] }, o: { x: [0.3], y: [0] }, t: 333, s: [0] }, { t: 343, s: [100] }], ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
        a: { a: 0, k: [480, 270, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 960,
      h: 540,
      ip: 333,
      op: 933,
      st: 333,
      bm: 0,
    }, {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Dot',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 329, s: [100] }, { t: 339, s: [0] }],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [694.5, 317.75, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
            t: 59,
            s: [0, 0, 100],
          }, { t: 71, s: [150, 150, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-7.18, 0], [0, -7.18], [7.18, 0], [0, 7.18]],
              o: [[7.18, 0], [0, 7.18], [-7.18, 0], [0, -7.18]],
              v: [[0, -13], [13, 0], [0, 13], [-13, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [0.20000000298, 0.20000000298, 0.20000000298, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.388235300779, 0.388235300779, 0.388235300779, 1], ix: 4 },
          o: { a: 0, k: 75, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 49,
      op: 480,
      st: 49,
      bm: 0,
    }, {
      ddd: 0,
      ind: 12,
      ty: 3,
      nm: 'Card Control',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 59, s: [0] }, {
            i: { x: [0.667], y: [1] },
            o: { x: [0.333], y: [0] },
            t: 71,
            s: [100],
          }, { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 329, s: [100] }, { t: 339, s: [0] }],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [461, 352, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.7, 0.7, 0.667], y: [1, 1, 1] },
            o: { x: [0.6, 0.6, 0.333], y: [0, 0, 0] },
            t: 59,
            s: [80, 80, 100],
          }, {
            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
            o: { x: [0.6, 0.6, 0.333], y: [0, 0, 0] },
            t: 71,
            s: [100, 100, 100],
          }, {
            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
            t: 329,
            s: [100, 100, 100],
          }, { t: 339, s: [80, 80, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 59,
      op: 480,
      st: 59,
      bm: 0,
    }, {
      ddd: 0,
      ind: 13,
      ty: 3,
      nm: 'Text Frame Control',
      parent: 12,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 59, s: [0] }, {
            i: { x: [0.667], y: [1] },
            o: { x: [0.333], y: [0] },
            t: 71,
            s: [100],
          }, { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 329, s: [100] }, { t: 339, s: [0] }],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-4, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [{
            i: { x: [0.7, 0.7, 0.667], y: [1, 1, 1] },
            o: { x: [0.6, 0.6, 0.333], y: [0, 0, 0] },
            t: 59,
            s: [80, 80, 100],
          }, {
            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
            o: { x: [0.6, 0.6, 0.333], y: [0, 0, 0] },
            t: 71,
            s: [100, 100, 100],
          }, {
            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
            t: 329,
            s: [100, 100, 100],
          }, { t: 339, s: [80, 80, 100] }],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 59,
      op: 480,
      st: 59,
      bm: 0,
    }, {
      ddd: 0,
      ind: 14,
      ty: 3,
      nm: 'Button Control',
      parent: 12,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 59,
      op: 334,
      st: 59,
      bm: 0,
    }, {
      ddd: 0,
      ind: 15,
      ty: 5,
      nm: 'Button Label',
      parent: 16,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Card Control').transform.opacity;" },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-20.708, 7.127, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [{
            s: { s: 18, f: 'Nunito-ExtraBold', t: 'ADD', ca: 0, j: 0, tr: 0, lh: 21.6, ls: 0, fc: [1, 1, 1] },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 59,
      op: 334,
      st: 59,
      bm: 0,
    }, {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Button',
      parent: 14,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Card Control').transform.opacity;" },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [{
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 319,
            s: [0, 26.5, 0],
            to: [0, 0.458, 0],
            ti: [0, 0, 0],
          }, {
            i: { x: 0.4, y: 1 },
            o: { x: 0.3, y: 0 },
            t: 329,
            s: [0, 29.25, 0],
            to: [0, 0, 0],
            ti: [0, 0.458, 0],
          }, { t: 334, s: [0, 26.5, 0] }],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-6.627, 0], [0, 0], [0, -6.627], [0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0]],
              o: [[0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0], [-6.627, 0], [0, 0], [0, -6.627]],
              v: [[-68, -24], [68, -24], [80, -12], [80, 12], [68, 24], [-68, 24], [-80, 12], [-80, -12]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.258823543787, 0.690196096897, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 59,
      op: 334,
      st: 59,
      bm: 0,
    }, {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Button Shadow',
      parent: 14,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Card Control').transform.opacity;" },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 29.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-6.627, 0], [0, 0], [0, -6.627], [0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0]],
              o: [[0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0], [-6.627, 0], [0, 0], [0, -6.627]],
              v: [[-68, -24], [68, -24], [80, -12], [80, 12], [68, 24], [-68, 24], [-80, 12], [-80, -12]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.184313729405, 0.501960813999, 0.929411768913, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 59,
      op: 334,
      st: 59,
      bm: 0,
    }, {
      ddd: 0,
      ind: 18,
      ty: 5,
      nm: 'Text 2',
      parent: 13,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Card Control').transform.opacity;" },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-167, -29.422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [{
            s: {
              s: 24,
              f: 'Nunito-SemiBold',
              t: 'Lion',
              ca: 0,
              j: 0,
              tr: 0,
              lh: 28.8,
              ls: 0,
              fc: [0.2, 0.2, 0.2],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [{
          nm: 'Animator 1',
          s: {
            t: 0,
            xe: { a: 0, k: 0, ix: 7 },
            ne: { a: 0, k: 0, ix: 8 },
            a: { a: 0, k: 100, ix: 4 },
            b: 1,
            rn: 0,
            sh: 1,
            s: {
              a: 1,
              k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 197, s: [0] }, { t: 243, s: [100] }],
              ix: 1,
            },
            r: 1,
          },
          a: { o: { a: 0, k: 0, ix: 9 } },
        }],
      },
      ip: 197,
      op: 334,
      st: 197,
      bm: 0,
    }, {
      ddd: 0,
      ind: 19,
      ty: 5,
      nm: 'Text',
      parent: 13,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 61, s: [0] }, {
            i: { x: [0.833], y: [0.833] },
            o: { x: [0.167], y: [0.167] },
            t: 63,
            s: [100],
          }, { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 161, s: [100] }, { t: 162, s: [50] }],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-167, -29.422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [{
            s: {
              s: 24,
              f: 'Nunito-SemiBold',
              t: 'Type here',
              ca: 0,
              j: 0,
              tr: 0,
              lh: 28.8,
              ls: 0,
              fc: [0.2, 0.2, 0.2],
            },
            t: 0,
          }],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 59,
      op: 202,
      st: 79,
      bm: 0,
    }, {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'Text Cursor 2',
      parent: 13,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [{ t: 162, s: [0], h: 1 }, { t: 182, s: [100], h: 1 }, { t: 202, s: [0], h: 1 }, { t: 243, s: [0], h: 1 }, {
            t: 273,
            s: [100],
            h: 1,
          }, { t: 303, s: [0], h: 1 }, { t: 333, s: [100], h: 1 }, { t: 353, s: [0], h: 1 }, { t: 373, s: [100], h: 1 }],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [{
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 202,
            s: [-167.25, -37, 0],
            to: [8.129, 0, 0],
            ti: [-8.129, 0, 0],
          }, { t: 243, s: [-118.477, -37, 0] }],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [-370.852, 6.607, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ty: 'rc',
          d: 1,
          s: { a: 0, k: [2, 28], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          r: { a: 0, k: 0, ix: 4 },
          nm: 'Rectangle Path 1',
          mn: 'ADBE Vector Shape - Rect',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.119706000534, 0.119706000534, 0.119706000534, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [-370.852, 6.607], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Rectangle 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 162,
      op: 334,
      st: 162,
      bm: 0,
    }, {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: 'Text Frame',
      parent: 13,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Card Control').transform.opacity;" },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [4, -37.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-6.075, 0], [0, 0], [0, -6.075], [0, 0], [6.075, 0], [0, 0], [0, 6.075], [0, 0]],
              o: [[0, 0], [6.075, 0], [0, 0], [0, 6.075], [0, 0], [-6.075, 0], [0, 0], [0, -6.075]],
              v: [[-172, -23], [172, -23], [183, -12], [183, 12], [172, 23], [-172, 23], [-183, 12], [-183, -12]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [0.898039221764, 0.898039221764, 0.898039221764, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.96862745285, 0.96862745285, 0.96862745285, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 59,
      op: 334,
      st: 59,
      bm: 0,
    }, {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: 'Card',
      parent: 12,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Card Control').transform.opacity;" },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, -4, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 536, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-6.351, 0], [0, 0], [0, -6.351], [0, 0], [6.351, 0], [0, 0], [0, 6.351], [0, 0]],
              o: [[0, 0], [6.351, 0], [0, 0], [0, 6.351], [0, 0], [-6.351, 0], [0, 0], [0, -6.351]],
              v: [[-180, -65], [180, -65], [191.5, -53.5], [191.5, 53.5], [180, 65], [-180, 65], [-191.5, 53.5], [-191.5, -53.5]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'st',
          c: { a: 0, k: [0.898039221764, 0.898039221764, 0.898039221764, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 1, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [960, 536], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }, {
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-6.627, 0], [0, 0], [0, -6.627], [0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0]],
              o: [[0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0], [-6.627, 0], [0, 0], [0, -6.627]],
              v: [[-180, -65.5], [180, -65.5], [192, -53.5], [192, 53.5], [180, 65.5], [-180, 65.5], [-192, 53.5], [-192, -53.5]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [960, 536], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 2',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 2,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 59,
      op: 334,
      st: 59,
      bm: 0,
    }, {
      ddd: 0,
      ind: 23,
      ty: 0,
      nm: 'Image',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [637, 331.2, 0], ix: 2, l: 2 },
        a: { a: 0, k: [715, 481.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [133, 133, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1430,
      h: 963,
      ip: -67,
      op: 533,
      st: -67,
      bm: 0,
    }],
  }, {
    id: 'comp_1',
    layers: [{
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Lion',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [480, 270, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [155, 155, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Shadow',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 11.667, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 6.627], [0, 0], [-6.627, 0], [0, 0], [0, 6.627], [0, 0], [6.627, 0], [0, 0]],
              o: [[0, 0], [0, 6.627], [0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0], [-6.627, 0]],
              v: [[-46.5, -6.833], [-46.5, -5.167], [-34.5, 6.833], [34.5, 6.833], [46.5, -5.167], [46.5, -6.833], [34.5, 5.167], [-34.5, 5.167]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.20000000298, 0.20000000298, 0.20000000298, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 14',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [22.5, -1, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 0], [0.156, 0.156], [0, 0], [0.156, -0.156], [0, 0], [0, 0], [0.156, -0.156], [0, 0], [-0.156, -0.156], [0, 0], [0, 0], [-0.156, -0.156], [0, 0], [-0.156, 0.156], [0, 0], [0, 0], [-0.156, 0.156], [0, 0], [0.156, 0.156]],
              o: [[0, 0], [0.156, -0.156], [0, 0], [-0.156, -0.156], [0, 0], [0, 0], [-0.156, -0.156], [0, 0], [-0.156, 0.156], [0, 0], [0, 0], [-0.156, 0.156], [0, 0], [0.156, 0.156], [0, 0], [0, 0], [0.156, 0.156], [0, 0], [0.156, -0.156], [0, 0]],
              v: [[1.19, 0], [3.883, -2.693], [3.883, -3.257], [3.257, -3.883], [2.693, -3.883], [0, -1.19], [-2.693, -3.883], [-3.258, -3.883], [-3.883, -3.257], [-3.883, -2.693], [-1.19, 0], [-3.883, 2.693], [-3.883, 3.257], [-3.257, 3.883], [-2.693, 3.883], [0, 1.19], [2.693, 3.883], [3.257, 3.883], [3.883, 3.257], [3.883, 2.693]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 13',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [22.5, -1, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-4.418, 0], [0, -4.418], [4.418, 0], [0, 4.418]],
              o: [[4.418, 0], [0, 4.418], [-4.418, 0], [0, -4.418]],
              v: [[0, -8], [8, 0], [0, 8], [-8, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 0.494117647409, 0.494117647409, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 6',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [22.5, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-4.418, 0], [0, -4.418], [4.418, 0], [0, 4.418]],
              o: [[4.418, 0], [0, 4.418], [-4.418, 0], [0, -4.418]],
              v: [[0, -8], [8, 0], [0, 8], [-8, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.964705884457, 0.313725501299, 0.313725501299, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 5',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0.464, 0.563, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0.8, 0], [0, 0.821], [0, 0], [-0.789, 0], [0, -0.811], [0, 0], [-0.448, 0.224], [-0.555, 0], [-0.459, -0.544], [0, -1.12], [0, 0], [0.811, 0], [0.213, 0.213], [0, 0.395], [0, 0], [0.203, 0.235], [0.395, 0], [0.309, -0.32], [0, -0.533], [0, 0]],
              o: [[-0.811, 0], [0, 0], [0, -0.811], [0.789, 0], [0, 0], [0.267, -0.427], [0.448, -0.224], [0.928, 0], [0.459, 0.533], [0, 0], [0, 0.821], [-0.384, 0], [-0.203, -0.213], [0, 0], [0, -0.544], [-0.203, -0.245], [-0.512, 0], [-0.299, 0.32], [0, 0], [0, 0.821]],
              v: [[-2.64, 4.08], [-3.856, 2.848], [-3.856, -2.832], [-2.672, -4.048], [-1.488, -2.832], [-1.488, -2.768], [-0.416, -3.744], [1.088, -4.08], [3.168, -3.264], [3.856, -0.784], [3.856, 2.848], [2.64, 4.08], [1.744, 3.76], [1.44, 2.848], [1.44, -0.672], [1.136, -1.84], [0.24, -2.208], [-0.992, -1.728], [-1.44, -0.448], [-1.44, 2.848]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 4',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-0.512, 0], [-0.32, 0.373], [0, 0.789], [0.331, 0.363], [0.512, 0], [0.32, -0.373], [0, -0.779], [-0.32, -0.373]],
              o: [[0.512, 0], [0.331, -0.373], [0, -0.779], [-0.32, -0.373], [-0.512, 0], [-0.32, 0.363], [0, 0.789], [0.32, 0.373]],
              v: [[-10.038, 2.867], [-8.79, 2.307], [-8.294, 0.563], [-8.79, -1.149], [-10.038, -1.709], [-11.286, -1.149], [-11.766, 0.563], [-11.286, 2.307]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[0.843, 0], [0.629, 0.331], [0.341, 0.608], [0, 0.843], [-0.341, 0.608], [-0.619, 0.331], [-0.843, 0], [-0.619, -0.331], [-0.341, -0.619], [0, -0.832], [0.352, -0.619], [0.629, -0.341]],
              o: [[-0.843, 0], [-0.619, -0.341], [-0.341, -0.619], [0, -0.832], [0.341, -0.619], [0.629, -0.331], [0.843, 0], [0.629, 0.331], [0.352, 0.608], [0, 0.843], [-0.341, 0.608], [-0.619, 0.331]],
              v: [[-10.038, 4.675], [-12.246, 4.179], [-13.686, 2.755], [-14.198, 0.563], [-13.686, -1.597], [-12.246, -3.021], [-10.038, -3.517], [-7.846, -3.021], [-6.39, -1.597], [-5.862, 0.563], [-6.39, 2.755], [-7.846, 4.179]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 3',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0.395, 0], [0.213, 0.224], [0, 0.416], [0, 0], [-0.811, 0], [-0.203, -0.235], [0, -0.427], [0, 0], [0.203, -0.235]],
              o: [[-0.395, 0], [-0.203, -0.235], [0, 0], [0, -0.885], [0.384, 0], [0.213, 0.224], [0, 0], [0, 0.416], [-0.203, 0.224]],
              v: [[-17.88, 4.627], [-18.792, 4.291], [-19.096, 3.315], [-19.096, -2.157], [-17.88, -3.485], [-16.999, -3.133], [-16.68, -2.157], [-16.68, 3.315], [-16.984, 4.291]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[0.917, 0], [0.245, 0.203], [0, 0.384], [-0.235, 0.203], [-0.448, 0], [0, -0.811]],
              o: [[-0.448, 0], [-0.235, -0.213], [0, -0.395], [0.245, -0.213], [0.917, 0], [0, 0.8]],
              v: [[-17.88, -4.797], [-18.919, -5.101], [-19.271, -5.997], [-18.919, -6.893], [-17.88, -7.213], [-16.503, -5.997]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Layer 2',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-25.139, -1.157, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0.213, 0.213], [0, 0.405], [0, 0], [-0.213, 0.213], [-0.405, 0], [0, -0.843], [0, 0], [0, 0], [0, -0.693], [0.715, 0]],
              o: [[-0.416, 0], [-0.213, -0.224], [0, 0], [0, -0.405], [0.213, -0.224], [0.821, 0], [0, 0], [0, 0], [0.715, 0], [0, 0.704], [0, 0]],
              v: [[-2.656, 5.656], [-3.6, 5.336], [-3.92, 4.392], [-3.92, -4.392], [-3.6, -5.32], [-2.672, -5.656], [-1.44, -4.392], [-1.44, 3.56], [2.848, 3.56], [3.92, 4.6], [2.848, 5.656]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }, {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'BG',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, -0.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-6.627, 0], [0, 0], [0, -6.627], [0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0]],
              o: [[0, 0], [6.627, 0], [0, 0], [0, 6.627], [0, 0], [-6.627, 0], [0, 0], [0, -6.627]],
              v: [[-34.5, -18], [34.5, -18], [46.5, -6], [46.5, 6], [34.5, 18], [-34.5, 18], [-46.5, 6], [-46.5, -6]],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ty: 'fl',
          c: { a: 0, k: [0.20000000298, 0.20000000298, 0.20000000298, 1], ix: 4 },
          o: { a: 0, k: 75, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        }, {
          ty: 'tr',
          p: { a: 0, k: [0, 0], ix: 2 },
          a: { a: 0, k: [0, 0], ix: 1 },
          s: { a: 0, k: [100, 100], ix: 3 },
          r: { a: 0, k: 0, ix: 6 },
          o: { a: 0, k: 100, ix: 7 },
          sk: { a: 0, k: 0, ix: 4 },
          sa: { a: 0, k: 0, ix: 5 },
          nm: 'Transform',
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }],
  }, {
    id: 'comp_2',
    layers: [{
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Map.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [574.81, 481, 0], ix: 2, l: 2 },
        a: { a: 0, k: [1286, 723, 0], ix: 1, l: 2 },
        s: { a: 0, k: [66.5, 66.5, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    }],
  }],
  fonts: {
    list: [{
      fName: 'Nunito-SemiBold',
      fFamily: 'Nunito',
      fStyle: 'SemiBold',
      ascent: 71.8990356661379,
    }, { fName: 'Nunito-ExtraBold', fFamily: 'Nunito', fStyle: 'ExtraBold', ascent: 72.2989258356392 }],
  },
  layers: [{
    ddd: 0,
    ind: 1,
    ty: 0,
    nm: '1 - Tagging',
    refId: 'comp_0',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
      a: { a: 0, k: [480, 270, 0], ix: 1, l: 2 },
      s: { a: 0, k: [200, 200, 100], ix: 6, l: 2 },
    },
    ao: 0,
    w: 960,
    h: 540,
    ip: 0,
    op: 480,
    st: 0,
    bm: 0,
  }],
  markers: [],
  chars: [{
    ch: 'T',
    size: 24,
    style: 'SemiBold',
    w: 62.3,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 3.534], [0, 0], [0, 0], [0, 2.934], [3.066, 0], [0, 0], [0, -2.866], [-3, 0], [0, 0], [0, 0], [-3.467, 0]],
              o: [[0, 0], [0, 0], [3.066, 0], [0, -2.866], [0, 0], [-3, 0], [0, 2.934], [0, 0], [0, 0], [0, 3.534], [3.4, 0]],
              v: [[35.7, -4.6], [35.7, -61.8], [56.9, -61.8], [61.5, -66.2], [56.9, -70.5], [4.2, -70.5], [-0.3, -66.2], [4.2, -61.8], [25.4, -61.8], [25.4, -4.6], [30.6, 0.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'T',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'T',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'y',
    size: 24,
    style: 'SemiBold',
    w: 54.8,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-0.834, 0.533], [-0.667, 1.533], [0, 0], [0.666, 1.4], [1.8, 0], [0.8, -0.566], [0.6, -1.533], [0, 0], [0, 0], [0.766, 0.6], [1.2, 0], [0.766, -1.4], [-0.8, -1.8], [0, 0], [0, 0], [-0.7, -1.367], [-1.867, 0]],
              o: [[0.833, -0.534], [0, 0], [0.733, -1.733], [-0.667, -1.4], [-1.4, 0], [-0.8, 0.567], [0, 0], [0, 0], [-0.6, -1.466], [-0.767, -0.6], [-2.267, 0], [-0.767, 1.4], [0, 0], [0, 0], [-0.8, 1.8], [0.7, 1.366], [1.333, 0]],
              v: [[21.85, 17.9], [24.1, 14.8], [49.1, -42.7], [49.2, -47.4], [45.5, -49.5], [42.2, -48.65], [40.1, -45.5], [26.2, -11.7], [12.5, -45.5], [10.45, -48.6], [7.5, -49.5], [2.95, -47.4], [3, -42.6], [20.8, -1.3], [14.9, 11.9], [14.75, 16.65], [18.6, 18.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'y',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'y',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'p',
    size: 24,
    style: 'SemiBold',
    w: 59.1,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 3.4], [0, 0], [-2.834, -1.566], [-3.6, 0], [-3.234, 2.034], [-1.767, 3.8], [0, 5.2], [1.766, 3.767], [3.233, 2.067], [4.333, 0], [2.866, -1.633], [1.466, -2.8], [0, 0], [3.333, 0], [0, -3.4], [0, 0], [-3.334, 0]],
              o: [[0, 0], [1.466, 2.734], [2.833, 1.566], [4.333, 0], [3.233, -2.033], [1.766, -3.8], [0, -5.2], [-1.767, -3.766], [-3.234, -2.066], [-3.667, 0], [-2.867, 1.634], [0, 0], [0, -3.4], [-3.267, 0], [0, 0], [0, 3.4], [3.4, 0]],
              v: [[17.2, 13.6], [17.2, -7.9], [23.65, -1.45], [33.3, 0.9], [44.65, -2.15], [52.15, -10.9], [54.8, -24.4], [52.15, -37.85], [44.65, -46.6], [33.3, -49.7], [23.5, -47.25], [17, -40.6], [17, -44.4], [12, -49.5], [7.1, -44.4], [7.1, 13.6], [12.1, 18.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'p',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[2.5, 3], [0, 5.667], [-2.5, 2.967], [-4.2, 0], [-2.5, -2.966], [0, -5.666], [2.5, -3], [4.2, 0]],
              o: [[-2.5, -3], [0, -5.666], [2.5, -2.966], [4.2, 0], [2.5, 2.967], [0, 5.667], [-2.5, 3], [-4.2, 0]],
              v: [[20.75, -11.4], [17, -24.4], [20.75, -37.35], [30.8, -41.8], [40.85, -37.35], [44.6, -24.4], [40.85, -11.4], [30.8, -6.9]],
              c: true,
            },
            ix: 2,
          },
          nm: 'p',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'p',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'e',
    size: 24,
    style: 'SemiBold',
    w: 53.7,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-3.034, 0.766], [-2.267, 1.534], [-0.3, 1.1], [0.466, 0.9], [1.033, 0.234], [1.4, -0.8], [2.2, -0.5], [2, 0], [0.733, 10.134], [0, 0], [0, 2.267], [3.866, 4.3], [6.733, 0], [3.6, -2.133], [2.033, -3.833], [0, -5], [-4.6, -4.5], [-8, 0]],
              o: [[3.033, -0.766], [1.266, -0.8], [0.3, -1.1], [-0.467, -0.9], [-1.034, -0.233], [-2.067, 1.334], [-2.2, 0.5], [-10, 0], [0, 0], [2.666, 0], [0, -7.533], [-3.867, -4.3], [-4.667, 0], [-3.6, 2.134], [-2.034, 3.834], [0, 7.8], [4.6, 4.5], [2.733, 0]],
              v: [[38.65, -0.25], [46.6, -3.7], [48.95, -6.55], [48.7, -9.55], [46.45, -11.25], [42.8, -10.4], [36.4, -7.65], [30.1, -6.9], [14, -22.1], [45.8, -22.1], [49.8, -25.5], [44, -43.25], [28.1, -49.7], [15.7, -46.5], [7.25, -37.55], [4.2, -24.3], [11.1, -5.85], [30, 0.9]],
              c: true,
            },
            ix: 2,
          },
          nm: 'e',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[-2.2, -2.5], [-0.2, -4.533], [0, 0], [-2.467, 2.6], [-4.2, 0]],
              o: [[2.2, 2.5], [0, 0], [0.533, -4.333], [2.466, -2.6], [3.933, 0]],
              v: [[37.7, -38.85], [41.3, -28.3], [14, -28.3], [18.5, -38.7], [28.5, -42.6]],
              c: true,
            },
            ix: 2,
          },
          nm: 'e',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'e',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, { ch: ' ', size: 24, style: 'SemiBold', w: 26.4, data: {}, fFamily: 'Nunito' }, {
    ch: 'h',
    size: 24,
    style: 'SemiBold',
    w: 57.6,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 3.4], [0, 0], [-2.434, 2.567], [-4.067, 0], [-1.634, -1.933], [0, -4.2], [0, 0], [-3.4, 0], [0, 3.4], [0, 0], [11.533, 0], [2.833, -1.433], [1.666, -2.8], [0, 0], [3.4, 0], [0, -3.4], [0, 0], [-3.334, 0]],
              o: [[0, 0], [0, -4.2], [2.433, -2.566], [3.466, 0], [1.633, 1.934], [0, 0], [0, 3.4], [3.333, 0], [0, 0], [0, -13.066], [-3.534, 0], [-2.834, 1.434], [0, 0], [0, -3.4], [-3.334, 0], [0, 0], [0, 3.4], [3.4, 0]],
              v: [[17.2, -4.4], [17.2, -27.6], [20.85, -37.75], [30.6, -41.6], [38.25, -38.7], [40.7, -29.5], [40.7, -4.4], [45.8, 0.7], [50.8, -4.4], [50.8, -30.1], [33.5, -49.7], [23.95, -47.55], [17.2, -41.2], [17.2, -66.1], [12.1, -71.2], [7.1, -66.1], [7.1, -4.4], [12.1, 0.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'h',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'h',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'r',
    size: 24,
    style: 'SemiBold',
    w: 37.3,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 3.4], [0, 0], [-8.867, 0.867], [0, 0], [0.266, 2.934], [2.466, -0.2], [2.7, -1.6], [1.333, -3], [0, 0], [3.266, 0], [0, -3.4], [0, 0], [-3.4, 0]],
              o: [[0, 0], [0, -8.266], [0, 0], [3.133, -0.266], [-0.2, -2.866], [-3.867, 0.267], [-2.7, 1.6], [0, 0], [0, -3.4], [-3.267, 0], [0, 0], [0, 3.4], [3.4, 0]],
              v: [[17.3, -4.4], [17.3, -27.1], [30.6, -40.8], [32.5, -41], [36.8, -45.8], [32.8, -49.8], [22.95, -47], [16.9, -40.1], [16.9, -44.4], [12, -49.5], [7.1, -44.4], [7.1, -4.4], [12.2, 0.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'r',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'r',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'L',
    size: 24,
    style: 'SemiBold',
    w: 55.2,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 3], [3.066, 0], [0, 0], [0, 0], [3.466, 0], [0, -3.533], [0, 0], [-3.534, 0]],
              o: [[3.066, 0], [0, -2.933], [0, 0], [0, 0], [0, -3.533], [-3.467, 0], [0, 0], [0, 3.534], [0, 0]],
              v: [[49.6, 0], [54.2, -4.5], [49.6, -8.9], [18.6, -8.9], [18.6, -65.6], [13.4, -70.9], [8.2, -65.6], [8.2, -5.3], [13.5, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'L',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'L',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'i',
    size: 24,
    style: 'SemiBold',
    w: 24.3,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 3.8], [4.2, 0], [0, -3.8], [-1.1, -1], [-2, 0]],
              o: [[0, -3.8], [-4.2, 0], [0, 1.8], [1.1, 1], [4.2, 0]],
              v: [[18.4, -65.4], [12.1, -71.1], [5.8, -65.4], [7.45, -61.2], [12.1, -59.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'i',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[0, 3.667], [0, 0], [3.4, 0], [0, -3.6], [0, 0], [-3.334, 0]],
              o: [[0, 0], [0, -3.6], [-3.334, 0], [0, 0], [0, 3.667], [3.4, 0]],
              v: [[17.2, -4.9], [17.2, -43.9], [12.1, -49.3], [7.1, -43.9], [7.1, -4.9], [12.1, 0.6]],
              c: true,
            },
            ix: 2,
          },
          nm: 'i',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'i',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'o',
    size: 24,
    style: 'SemiBold',
    w: 56.5,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-3.6, 2.067], [-2, 3.767], [0, 5.2], [2, 3.767], [3.6, 2.067], [4.8, 0], [3.6, -2.066], [1.966, -3.766], [0, -5.2], [-1.967, -3.766], [-3.6, -2.066], [-4.934, 0]],
              o: [[3.6, -2.066], [2, -3.766], [0, -5.2], [-2, -3.766], [-3.6, -2.066], [-4.934, 0], [-3.6, 2.067], [-1.967, 3.767], [0, 5.2], [1.966, 3.767], [3.6, 2.067], [4.8, 0]],
              v: [[40.9, -2.2], [49.3, -10.95], [52.3, -24.4], [49.3, -37.85], [40.9, -46.6], [28.3, -49.7], [15.5, -46.6], [7.15, -37.85], [4.2, -24.4], [7.15, -10.95], [15.5, -2.2], [28.3, 0.9]],
              c: true,
            },
            ix: 2,
          },
          nm: 'o',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[2.533, 3], [0, 5.667], [-2.534, 2.967], [-4.2, 0], [-2.534, -2.966], [0, -5.666], [2.533, -3], [4.133, 0]],
              o: [[-2.534, -3], [0, -5.666], [2.533, -2.966], [4.133, 0], [2.533, 2.967], [0, 5.667], [-2.534, 3], [-4.2, 0]],
              v: [[18.2, -11.4], [14.4, -24.4], [18.2, -37.35], [28.3, -41.8], [38.3, -37.35], [42.1, -24.4], [38.3, -11.4], [28.3, -6.9]],
              c: true,
            },
            ix: 2,
          },
          nm: 'o',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'o',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'n',
    size: 24,
    style: 'SemiBold',
    w: 57.6,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 3.4], [0, 0], [-2.434, 2.567], [-4.067, 0], [-1.634, -1.933], [0, -4.2], [0, 0], [-3.4, 0], [0, 3.4], [0, 0], [11.533, 0], [2.9, -1.533], [1.666, -2.933], [0, 0], [3.266, 0], [0, -3.4], [0, 0], [-3.334, 0]],
              o: [[0, 0], [0, -4.2], [2.433, -2.566], [3.466, 0], [1.633, 1.934], [0, 0], [0, 3.4], [3.333, 0], [0, 0], [0, -13.066], [-3.6, 0], [-2.9, 1.534], [0, 0], [0, -3.4], [-3.267, 0], [0, 0], [0, 3.4], [3.4, 0]],
              v: [[17.2, -4.4], [17.2, -27.6], [20.85, -37.75], [30.6, -41.6], [38.25, -38.7], [40.7, -29.5], [40.7, -4.4], [45.8, 0.7], [50.8, -4.4], [50.8, -30.1], [33.5, -49.7], [23.75, -47.4], [16.9, -40.7], [16.9, -44.4], [12, -49.5], [7.1, -44.4], [7.1, -4.4], [12.1, 0.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'n',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'n',
        np: 3,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'A',
    size: 18,
    style: 'ExtraBold',
    w: 75.3,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-1.134, 1.033], [-1, 2.334], [0, 0], [0, 0], [0, 0], [-1.234, -1.066], [-2.267, 0], [-1.167, 1.834], [1.266, 2.8], [0, 0], [1.466, 1.034], [1.933, 0], [1.466, -1.033], [1.066, -2.266], [0, 0], [-1.2, -1.866], [-2.8, 0]],
              o: [[1.133, -1.033], [0, 0], [0, 0], [0, 0], [0.933, 2.267], [1.233, 1.066], [2.6, 0], [1.166, -1.833], [0, 0], [-1, -2.266], [-1.467, -1.033], [-1.934, 0], [-1.467, 1.034], [0, 0], [-1.267, 2.734], [1.2, 1.867], [2.066, 0]],
              v: [[13.9, -0.65], [17.1, -5.7], [21.2, -15.3], [54.1, -15.3], [58.3, -5.7], [61.55, -0.7], [66.8, 0.9], [72.45, -1.85], [72.3, -8.8], [46.5, -64.9], [42.8, -69.85], [37.7, -71.4], [32.6, -69.85], [28.8, -64.9], [3.2, -8.8], [3.1, -1.9], [9.1, 0.9]],
              c: true,
            },
            ix: 2,
          },
          nm: 'A',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 0], [0, 0], [0, 0]],
              o: [[0, 0], [0, 0], [0, 0], [0, 0]],
              v: [[37.7, -54], [49, -27.4], [26.4, -27.4], [37.5, -54]],
              c: true,
            },
            ix: 2,
          },
          nm: 'A',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'A',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }, {
    ch: 'D',
    size: 18,
    style: 'ExtraBold',
    w: 77.4,
    data: {
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [-6.6, 6.134], [0, 11.267], [6.6, 6.1], [12, 0], [0, 0], [0, -5.4], [0, 0], [-5.4, 0]],
              o: [[12, 0], [6.6, -6.133], [0, -11.266], [-6.6, -6.1], [0, 0], [-5.4, 0], [0, 0], [0, 5.4], [0, 0]],
              v: [[34.7, 0], [62.6, -9.2], [72.5, -35.3], [62.6, -61.35], [34.7, -70.5], [15.2, -70.5], [7.1, -62.4], [7.1, -8.1], [15.2, 0]],
              c: true,
            },
            ix: 2,
          },
          nm: 'D',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 0], [0, -15], [15, 0], [0, 0]],
              o: [[0, 0], [15, 0], [0, 15.067], [0, 0], [0, 0]],
              v: [[22.6, -57.8], [33.8, -57.8], [56.3, -35.3], [33.8, -12.7], [22.6, -12.7]],
              c: true,
            },
            ix: 2,
          },
          nm: 'D',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        }],
        nm: 'D',
        np: 5,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false,
      }],
    },
    fFamily: 'Nunito',
  }],
};

export default data;
