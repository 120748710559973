import { useContext, useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { SearchContext } from '../../../core/searchContext';
import { GroupsListSortType, OrderBy, useSectionGroupsQuery } from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { PAGE_SIZE } from '../../../variables/constant';
import { GroupsSort } from '../../../variables/types';

interface Props {
  sectionID?: string
}

const useSectionGroups = ({ sectionID }: Props) => {
  const pagination = usePagination();
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, GroupsListSortType.Name));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Asc));
  const { searchTerm } = useContext(SearchContext);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  useEffect(() => {
    pagination.resetPage();
  }, [rowsPerPage]);

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const onSortChange = (sortBy: GroupsSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handleSort = (column: GroupsSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const { data, loading } = useSectionGroupsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort: sort as GroupsListSortType,
      orderBy: order as OrderBy,
      ...(sectionID ? { section_id: sectionID } : {}),
      ...(searchTerm && searchTerm?.length > 2  ? { search: searchTerm } : {}),
    },
  });

  return {
    loading,
    groups: data?.groupsBySection.nodes ?? [],
    totalCount: data?.groupsBySection.totalCount ?? 0,
    pagination,
    rowsPerPage,
    sort, order,
    setRowsPerPage,
    handlePageChange,
    handleSort,
  };
};

export default useSectionGroups;