import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledTableContainer from '../../Assignments/AssignmentsTable/List.styled';
import { StyledTableSort } from '../../../components/Common/Common.styled';
import { StyledViewButton } from '../../../components/PTTile/PTTtile.styled';
import TablePagination from '../../../components/TablePagination';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import {
  BenchmarkGradedBy,
  OrderBy,
  SubmissionStatus,
  TeacherBenchmarkSubmissionListSortType,
} from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import ImageCardAssignment from '../../../components/ImageCardAssignment';
import Loader from '../../../components/Loader/loader';
import { StylesCss } from '../../Assignments/Assignments.styled';
import { StyledTableCells } from '../../Admin/admin-tables-styled';
import { toReadableFormat } from '../../../utils/dateFormat';
import { PAGE_SIZE, READABLE_DATE_OPTIONS_YEAR } from '../../../variables/constant';
import GradeFilters from './GradeFilters';
import { getNoDataLabels } from '../../AdminBenchmarks/Grade/GradesTable';
import { BenchmarkTypeOptions } from '../../AdminBenchmarks/Create/Steps/DetailsStep';

const GradesTable = ({
  benchmarkSubmissionList,
  totalCount,
  loading,
  pagination,
  sort,
  order,
  handleSort,
  handleRedirection,
  selectedBenchmarkTypes, setSelectedBenchmarkTypes,
  selectedStatus, setSelectedStatus,
  handleApplyFilters,
  handleClearFilters,
  handlePagination,
  submissionQueueData,
  selectedGradedBy,
  setSelectedGradedBy,
}: any) => {
  const theme = useTheme();
  const styles = StylesCss(theme);

  const isTableEmpty = !loading && benchmarkSubmissionList?.length === 0;

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: React.SetStateAction<number> ) => handlePagination(value);

  const { label, description } = getNoDataLabels();
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const sortColumn = (title: string, sortEnum: TeacherBenchmarkSubmissionListSortType) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      {title}
    </StyledTableSort>
  );

  return (
    <>
      <Box
        display="grid"
        width="100%"
        mb={4}
      >
        <GradeFilters
          selectedBenchmarkTypes={selectedBenchmarkTypes}
          setSelectedBenchmarkTypes={setSelectedBenchmarkTypes}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
          totalCount={totalCount}
          page={pagination.page}
          selectedGradedBy={selectedGradedBy}
          setSelectedGradedBy={setSelectedGradedBy}
        />
        <Box mt={2}>
          <StyledTableContainer>
            <Table
              stickyHeader
              aria-label="Teacher Benchmarks Grades List"
              sx={{ ...((loading || isTableEmpty) && styles.tableWithNoData), ...(loading && { maxHeight: '60px' }) }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCells />
                  <StyledTableCells sx={styles.width25}>
                    {sortColumn('Student', TeacherBenchmarkSubmissionListSortType.StudentTitle)}
                  </StyledTableCells>
                  <StyledTableCells sx={styles.width20}>
                    {sortColumn('Benchmark', TeacherBenchmarkSubmissionListSortType.BenchmarkTitle)}
                  </StyledTableCells>
                  <StyledTableCells sx={styles.width15}>
                    Type
                  </StyledTableCells>
                  <StyledTableCells sx={styles.width15}>
                    {sortColumn('Submitted', TeacherBenchmarkSubmissionListSortType.SubmittedAt)}
                  </StyledTableCells>
                  <StyledTableCells sx={styles.width15}>
                    {sortColumn('School', TeacherBenchmarkSubmissionListSortType.SchoolTitle)}
                  </StyledTableCells>
                  <StyledTableCells sx={styles.width15} />
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && benchmarkSubmissionList?.map((row: any) => (
                  <TableRow key={row?.id}>
                    {/*Image*/}
                    <TableCell>
                      <Box>
                        <ImageCardAssignment
                          imageSrc={row?.benchmark_image_url || ''}
                          thumbnailImageSrc={row?.benchmark_thumbnail_image_url || row?.benchmark_image_url || ''}
                          key={row?.id}
                        />
                      </Box>
                    </TableCell>

                    {/*Student Name*/}
                    <TableCell
                      sx={styles.assignmentNameCell}
                    >
                      {row?.user_first_name} {row?.user_last_name}
                    </TableCell>

                    {/*Benchmark Title*/}
                    <TableCell
                      sx={styles.assignmentNameCell}
                    >
                      {row?.benchmark_title}
                    </TableCell>

                    {/*Benchmark Type*/}
                    <TableCell
                      sx={styles.assignmentNameCell}
                    >
                      {
                        BenchmarkTypeOptions.find(
                          (benchmarkType) => benchmarkType.value === row?.benchmark_type)?.label ?? ''
                      }
                    </TableCell>

                    {/*Date*/}
                    <TableCell
                      sx={{ ...styles.dateRangeCell, ...styles.uppercase }}
                    >
                      {`${toReadableFormat(row.submitted_at, READABLE_DATE_OPTIONS_YEAR)}`}
                    </TableCell>

                    {/*School*/}
                    <TableCell
                      sx={styles.assignmentNameCell}
                    >
                      {row?.student_school_title}
                    </TableCell>

                    {/*View*/}
                    <TableCell
                      sx={styles.dateRangeCell}
                    >
                      {
                        row.graded_by === BenchmarkGradedBy.Flashlight && row?.status === SubmissionStatus.Submitted ? (
                          <Box textAlign='center'>Grade Pending</Box>
                        ) : (
                          <StyledViewButton
                            variant="outlined"
                            onClick={
                              handleRedirection(
                                submissionQueueData ?? [row?.id],
                                row?.id,
                              )
                            }
                          >
                            {(row?.status === SubmissionStatus.Reviewed) && 'View'}
                            {(row?.status === SubmissionStatus.Submitted && row.graded_by === BenchmarkGradedBy.Teacher) && 'Grade'}
                          </StyledViewButton>
                        )
                      }
                    </TableCell>

                  </TableRow>
                ))}
                {isTableEmpty && (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      align="center"
                    >
                      <NoResults
                        label={label}
                        description={description}
                        svgComponent={<WavingLadyFrame />}
                      />
                    </TableCell>
                  </TableRow>
                )}
                <Loader open={loading} />
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
        {totalCount! > PAGE_SIZE && (
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingTop="20px"
          >
            <TablePagination
              count={Math.ceil(totalCount! / PAGE_SIZE) || 0}
              page={pagination.page}
              onChange={handlePageChange}
              defaultPage={1}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default GradesTable;
