import { OrderBy, TeacherStudentGradesSortType, useTeacherStudentGradesFilterQuery } from '../../generated/graphql';

const useTeacherStudentGradesFilter = () => {
  const { data, loading: loadingGrades } = useTeacherStudentGradesFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      sortBy: TeacherStudentGradesSortType.GradePosition,
      orderBy: OrderBy.Asc,
    },
  });

  return {
    grades: data?.teacherStudentGradesFilter ?? [],
    loadingGrades,
  };
};

export default useTeacherStudentGradesFilter;