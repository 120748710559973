import { StringParam, useQueryParam } from 'use-query-params';
import {
  GoalType,
  OrderBy,
  SectionFilterFragmentFragment,
  SubmissionsForStudentSortType,
  TeacherOfStudentFilterFragmentFragment,
  useGetActiveGoalsQuery,
  useStudentAssignmentsQuery,
} from '../../../generated/graphql';

import { useState } from 'react';

const useStudentsProgress = () => {
  const [selectedTeacher, setSelectedTeacher] = useQueryParam('teacher', StringParam);
  const [selectedSection, setSelectedSection] = useQueryParam('section', StringParam);
  const [sort, setSort] = useState<SubmissionsForStudentSortType>(SubmissionsForStudentSortType.SubmittedAt);
  const [order, setOrder] = useState<OrderBy>(OrderBy.Desc);
  const onSortChange = (sortBy: SubmissionsForStudentSortType, orderBy: OrderBy) => {
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handleSort = (column: SubmissionsForStudentSortType) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onTeacherChange = (teacher: string | TeacherOfStudentFilterFragmentFragment | null) => {
    setSelectedTeacher(teacher as string);
  };

  const onSectionChange = (section: string | SectionFilterFragmentFragment | null) => {
    setSelectedSection(section as string);
  };

  const { data: goalsDetail, loading: goalsLoading } = useGetActiveGoalsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      goalType: GoalType.Active,
    },
  });

  const { data: previousGoals, loading: perviousLoading } = useGetActiveGoalsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      goalType: GoalType.Attached,
    },
  });

  const { data, loading } = useStudentAssignmentsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        sort: sort,
        order_by: order,
      },
    },
  });

  const studentTasks = data?.getAllAssignmentsAndBenchmarksForStudent;
  const scoreScheme = data?.getAllAssignmentsAndBenchmarksForStudent?.score_details ?? [];

  const handleReset = () => {
    setSelectedSection('');
    setSelectedTeacher('');
  };

  return {
    loading: loading || goalsLoading || perviousLoading,
    studentTasks,
    previousGoals,
    goalsDetail,
    selectedTeacher,
    setSelectedTeacher,
    onTeacherChange,
    selectedSection,
    setSelectedSection,
    onSectionChange,
    handleReset,
    scoreScheme,
    sort,
    handleSort,
    order,
  };
};

export default useStudentsProgress;
