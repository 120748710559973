import { palette  } from '../palette';

const Table = {
  MuiTable: {
    styleOverrides: {
      root: {
        '& tbody tr:hover': {
          backgroundColor: palette.customWhite.whiteSmoke,
          cursor: 'pointer',
        },
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: '12px',
        border: `2px solid ${palette.customBackground.disabled}`,
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: palette.customGrey.lightGrey,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontWeight: 800,
        borderBottom: `2px solid ${palette.customBackground.disabled}`,
        padding: '11px 12px',
      },
      head: {
        fontWeight: 800,
        color: palette.fontColors.fontBlack,
        fontSize: 18,
        lineHeight: '25px',
      },
      stickyHeader: {
        backgroundColor: palette.customGrey.lightGrey,
        height: 52,
      },
    },
  },
};

export default Table;
