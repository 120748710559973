import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Loader from '../../../../../components/Loader/loader';
import DebouncedSearchBar from '../../../../../components/DebounceSearchBar';
import ManageStudentsTable from '../../../../../components/ManageStudentsTable';

import useManageStudents from './manage-students-hook';
import useCreateClass from '../../create-class-hook';
import { PROVIDER_TYPE } from '../../../../../variables/constant';
import WarningWindow from '../../../../../components/WarningWindow';

const ManageStudents = () => {

  const { teacherDetails } = useCreateClass();

  const {
    classTitle,
    availableStudents,
    selectedAvailableStudents, setSelectedAvailableStudents,
    allocatedStudents,
    selectedAllocatedStudents, setSelectedAllocatedStudents,
    handleAddStudents, handleRemoveStudents,
    loading, isTableEmpty,
    students,
    studentSearch, setStudentSearch, onStudentSearch,
    handleSort,
    removeStudentsWarning, setRemoveStudentsWarning, handleRemoveStudentsWithWarning,
  } = useManageStudents();

  const flTeacherNoStudentsMsg = 'No student match in our system. You can go to the “Students” page to add a new student.';
  const nonFlTeacherNoStudentsMsg = 'No student match in our system. Please contact your District IT Manager to add a new student.';

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Loader open={loading!} />
      <Box>
        <Typography variant="sLabel">
          Select students to be added to or removed from the class.<br/>
          Note: Students added to a class with an active Assignment or Benchmark will receive those Assignments or Benchmarks from the date after they are added.
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="30% repeat(3, 15%)"
        sx={{
          gridGap: 24,
        }}
        mt={2}
        width="100%"
      >
        <DebouncedSearchBar
          handleChange={onStudentSearch}
          searchTerm={studentSearch}
          mt={0}
          mb={0}
          placeholderTxt="Search By Name Or Student ID"
          showClearButton
          handleClear={() => setStudentSearch('')}
        />
      </Box>
      <Box>
        {(!loading && students.length === 0) &&
        <Typography variant='sFormHelper'>{teacherDetails?.source === PROVIDER_TYPE.FLASHLIGHT ? flTeacherNoStudentsMsg : nonFlTeacherNoStudentsMsg}</Typography>}
      </Box>
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns="2fr 1fr 2fr"
        sx={{
          gridGap: 48,
        }}
      >
        <Box>
          <ManageStudentsTable
            title="All Students"
            loading={loading}
            students={students}
            selectedStudents={selectedAvailableStudents}
            setSelectedStudents={setSelectedAvailableStudents}
            studentsToSkip={allocatedStudents}
            isTableEmpty={isTableEmpty}
            handleSort={handleSort}
          />
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="100%"
            display="grid"
            gridTemplateColumns="0.90fr"
            sx={{
              gridGap: 12,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIos />}
              disabled={selectedAvailableStudents.length === 0}
              onClick={() => handleAddStudents(selectedAvailableStudents)}
            >
              Add Selected
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIos />}
              disabled={selectedAllocatedStudents.length === 0}
              onClick={() => handleRemoveStudentsWithWarning(selectedAllocatedStudents)}
            >
              Remove Selected
            </Button>
          </Box>
        </Box>
        <Box>
          <ManageStudentsTable
            title={classTitle!}
            loading={loading}
            students={students}
            selectedStudents={selectedAllocatedStudents}
            setSelectedStudents={setSelectedAllocatedStudents}
            studentsToSkip={availableStudents}
            isTableEmpty={isTableEmpty}
            handleSort={handleSort}
          />
        </Box>
      </Box>
      <WarningWindow
        openDialog={removeStudentsWarning}
        closeDialog={() => setRemoveStudentsWarning(false)}
        header="Are you sure?"
        subheader={'Are you sure you want to proceed with removing students? removing students will also remove the assignments tied to their account'}
        actions={[{
          title: 'CANCEL',
          event: () => setRemoveStudentsWarning(false),
          classname: 'keepButton',
        }, {
          title: 'PROCEED',
          event: () => handleRemoveStudents(selectedAllocatedStudents),
          classname: 'deleteButton',
        }]}
      />

    </Box>
  );

};

export default ManageStudents;
