/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const NilIcon = (props: any) => (
  <SvgIcon width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg" fontSize='12px'>
    <path d="M12 2H2" stroke="#4B5563" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
);

export default NilIcon;



