import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, CheckboxProps, InputAdornment, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import BenchmarkTypeIcon from '../Icons/BenchmarkTypeIcon';
import { KeyValueType } from '../../generated/graphql';
import { BenchmarkTypeOptions } from '../../pages/AdminBenchmarks/Create/Steps/DetailsStep';


interface BenchmarkTypesMultiSelectFilterProps {
  onChange: (values:any[]) => void;
  value: (string | undefined)[]
  limitTags?: number;
  textInputPlaceholder?: string;
  placeholder?: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BenchmarkMultiTypeSelectFilter = ({
  onChange,
  limitTags = 3,
  textInputPlaceholder,
  placeholder,
  value,
}: BenchmarkTypesMultiSelectFilterProps) => {

  const benchmarkTypes = BenchmarkTypeOptions;

  const handleTypeChange = (_: React.ChangeEvent<{}>, newValues: any[]) => {
    onChange(newValues?.map((option: KeyValueType) => option?.value));
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={benchmarkTypes}
      limitTags={limitTags}
      getOptionLabel={(option: any) => option?.value}
      value={benchmarkTypes?.filter((benchType) => value?.includes(benchType?.value))}
      onChange={handleTypeChange}
      renderOption={(props, option, { selected }) => (
        <Box component="li" {...props}>
          <Checkbox
            color={'primary' as CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
          />
          {`${option?.label ?? ''}`}
        </Box>
      )}
      renderTags={(benchmarkValues, getTagProps) =>
        benchmarkValues.map((option, index) => (
          <Chip label={option?.label} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <>
          {placeholder && <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>}
          <TextField
            {...params}
            placeholder={params.InputProps.startAdornment ? undefined : (!textInputPlaceholder ? 'Select Benchmarks' : textInputPlaceholder)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <BenchmarkTypeIcon pathFill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default BenchmarkMultiTypeSelectFilter;
