import {
  styled,
  Box,
} from '@mui/material';

export const LoginContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.gradients.loginGradient,
  minHeight: '100vh',
  backgroundImage: 'url(\'/images/background.png\')',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  margin: '0px',
  padding: '0px',
  width:'100%',
  height:'100%',
}));

