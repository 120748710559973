export const escapingSpecialCharacters = (inputString: string) => {
  if (!inputString) return inputString;
  var regex = /[!@#$%^`&*()_+=\[\]{};':"|,.<>\/?~]/g;
  return inputString.replace(regex, '\\$&');
};

export const unescapeSpecialCharacters = (escapedString: string) => {
  if (!escapedString) return escapedString;
  const escapedCharactersRegex = /\\(.)/g;
  const result = escapedString.replaceAll(escapedCharactersRegex, '$1');
  return result;
};
