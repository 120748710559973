import React from 'react';
import {
  Box,
} from '@mui/material';
import {
  LoginButton,
} from '../Login.styled';

import Loader from '../../../components/Loader/loader';
import SuccessImage from '../../../components/Icons/SuccessImage';
import LoginWrapper from '../../../components/LoginWrapper';
import AutocompleteBox from '../../../components/Autocomplete';
import isEmpty from '../../../utils/isEmpty';
import useDistrictsList from './district-list-hook';
import { inputStyles } from '../Credentials';
import { palette  } from '../../../theme/palette';

const Login = () => {

  const {
    districtList,
    loading,
    handleRedirection,
    handleSubmit,
    district,
    setDistrict,
  } = useDistrictsList();

  const onSelectDistrict = () => {
    setDistrict(district);
  };

  const onDistrictChange = (data: any) => {
    setDistrict(data);
    if (data && data?.id) {
      handleRedirection(data?.id);
    }
  };

  return (
    <LoginWrapper
      title="Select your school district"
      image={(
        <SuccessImage
          sx={{ ...inputStyles.successImage }}
          imagePrimaryColor={palette.customYellow.buttonFontOrange}
        />
      )}
    >
      <>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSelectDistrict)}
          noValidate
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <AutocompleteBox
            onChange={onDistrictChange}
            inputValue={district}
            isFontVariable
            placeholderValue="Select District"
            sx={{
              '& .MuiAutocomplete-popupIndicator': {
                display: 'none',
              },
            }}
            data={districtList}
          />
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={5}
            mb={2}
          >
            <LoginButton
              type="submit"
              variant="contained"
              color="secondary"
              sx={{  minWidth: '10vw' }}
              onClick={() => handleRedirection(district?.id)}
              disabled={isEmpty(district)}
            >
              Continue
            </LoginButton>
          </Box>
        </form>
        <Loader open={loading} />
      </>
    </LoginWrapper>
  );
};

export default Login;
