import { Box } from '@mui/material';
import React from 'react';
import { Legend } from 'recharts';

type Props = {
  data: any[]
  id?: string,
  wrapperStyle?: any,
};
const MyLegend = ({ data, id = 'legend-dashboard', wrapperStyle = { width: '100%', bottom: '18px', fontSize: '14px', fontWeight: 600 } }: Props) => {
  return (
    <Box id={id} style={{ textAlign: 'center', width: '100%', marginTop: '8px' }}>
      <Legend
        formatter={(value ) => (
          <span style={{ color: 'black' }}>{value}</span>
        )}
        wrapperStyle={wrapperStyle}
        payload={data?.map((item) => ({
          value: item?.skillTitle,
          type: 'circle',
          color: item?.color,
        }))}
      />
    </Box>);
};
export default MyLegend;