import { styled } from '@mui/system';
import { TextField } from '@mui/material';

export const AutoCompleteTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  padding: 0,
  border: 'none',
  '& input.MuiInputBase-input.Mui-disabled': {
    background: `${theme.palette.customWhite.inputDisabled} !important`,
  },
}));
