import { useMySubmissionsFilterQuery, SubmissionStatus } from '../../generated/graphql';

const submissionVariables = (status?: [SubmissionStatus]) => {
  let variables = {};
  if (status) {
    variables = { ...variables, status };
  }
  return variables;
};

interface Props {
  status?: [SubmissionStatus]
}

const useSubmissionFilter = ({ status }: Props) => {
  const { data, loading } = useMySubmissionsFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(submissionVariables(status)),
    },
  });

  return {
    submissions: data?.allSubmissionsOfStudent ?? [],
    loading,
  };
};

export default useSubmissionFilter;
