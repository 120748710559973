import { useAuthProvider } from '../core/authContext';
import { UserRole } from '../generated/graphql';

const useRole = () => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const role = user?.role || '';

  return {
    isTeacher: role === UserRole.Teacher,
    isStudent: role === UserRole.Student,
    isSuperAdmin: role === UserRole.SuperAdmin,
    isSchoolAdmin: role === UserRole.SchoolAdmin,
    isDistrictAdmin: role === UserRole.DistrictAdmin,
  };
};

export default useRole;
