/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DistrictsIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    {...props}
  >
    <g id="icon-districts">
      <path d="M9.07102 8.62696H6.56801V6.70156H9.07102V8.62696Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5692 17.2912V11.0337C15.5692 10.502 15.1382 10.071 14.6065 10.071H12.4404V4.05415C12.4404 3.52247 12.0094 3.09146 11.4777 3.09146H9.071V0.925392H6.90493V3.09146H4.16125C3.62957 3.09146 3.19856 3.52247 3.19856 4.05415V10.071H1.36943C0.837752 10.071 0.406738 10.502 0.406738 11.0337V17.2912H15.5692ZM4.72981 15.4385V4.63084H10.9056V15.4385H9.05286V11.733H6.58255V15.4385H4.72981Z" fill={props?.pathfill} />
    </g>
  </SvgIcon>
);

export default DistrictsIcon;
