import { Grid, styled } from '@mui/material';
import StateFilter from '../../../components/StateFilter';
import DistrictFilter from '../../../components/DistrictFilter';
import SchoolFilter from '../../../components/SchoolFilter';
import TeacherSelectMultiple from '../../../components/TeacherSelectMultiple';
import TeacherFilter from '../../../components/TeacherFilter';

export const StyledStatesFilter = styled(StateFilter)(({ theme }) => ({
  width: 280,
  marginTop: theme.spacing(3),
}));
export const StyledDistrictFilter = styled(DistrictFilter)(({ theme }) => ({
  width: 280,
  marginTop: theme.spacing(1),
}));
export const StyledSchoolFilter = styled(SchoolFilter)(({ theme }) => ({
  width: 280,
  marginTop: theme.spacing(1),
}));
export const StyledTeacherFilter = styled(TeacherFilter)(({ theme }) => ({
  width: 280,
  marginTop: theme.spacing(1),
}));
export const StyledSecondaryTeacherFilter = styled(TeacherSelectMultiple)(({ theme }) => ({
  width: 480,
  marginTop: theme.spacing(1),
}));
export const StyledGrid = styled(Grid)(({ theme }) => ({
  '&.MuiGrid-root':{
    margin: theme.spacing(0, 0),
  },
}));
