import { styled, Box, Button, TableRow, Typography, Chip, Theme } from '@mui/material';
import zIndex from '../../theme/z-index';

export const StyledDownloadIcon = styled(Button)(({ theme }) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
  borderRadius: '50%',
  '&::after': {
    borderRadius: '50%',
  },
}));

export const StyledStickyBreadcrumbBox = styled(Box)(({ theme: { palette } }) => ({
  position: 'sticky',
  top: 0,
  zIndex: zIndex.zIndex2,
  background: palette.customWhite.main,
  borderBottom: `2px solid ${palette.customBackground.disabled}`,
}));

export const StyledTableRow = styled(TableRow)(({ theme: { palette } }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: palette.customGrey.lightGrey,
  },
}));

export const StyledTypographyAnchorText = styled(Typography)(({ theme }) => ({
  height: theme.spacing(5.125),
  fontWeight: 800,
  fontSize: '30px',
  lineHeight: '41px',
  letterSpacing: '0.1px',
  color: theme.palette.fontColors.fontBlack,
  marginLeft: 4,
}));

export const StyledTypographyAnchorSubText = styled(Typography)(({ theme: { palette } }) => ({
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '22px',
  letterSpacing: '0.1px',
  color: palette.fontColors.fontBlack,
}));

export const StyledTypographyText = styled(Typography)({
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '22px',
  letterSpacing: '0.1px',
  width: '90%',
});

export const StyledTypographyLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: '18px',
  lineHeight: '22px',
  letterSpacing: '0.1px',
  marginLeft: theme.spacing(1.5),
  marginBottom: theme.spacing(2),
}));

export const StyledBoxAnchorGrades = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 80px)',
  gridGap: theme.spacing(2),
  width: '100%',
  justifyContent: 'center',
  marginBottom: theme.spacing(5),
}));

export const StyledBoxAnchorGradesChip = styled(Chip)(({ theme: { palette } }) => ({
  fontWeight: 'bold',
  fontSize: '20.9362px',
  lineHeight: '29px',
  letterSpacing: '0.172723px',
  '&.MuiButtonBase-root.MuiChip-root.MuiChip-colorPrimary': {
    color: palette.customWhite.main,
  },
  '&.MuiButtonBase-root.MuiChip-root.MuiChip-colorSecondary': {
    color: palette.fontColors.fontBlack,
  },
  '&.MuiButtonBase-root.MuiChip-root.MuiChip-outlinedSecondary': {
    border: `2px solid ${palette.customBackground.disabled}`,
  },
}));

export const StyledAnchorTextButton = styled(Button)(({ theme: { palette } }) => ({
  height: '27px',
  fontWeight: 800,
  fontSize: '20px',
  lineHeight: '27px',
  letterSpacing: '0.1px',
  color: palette.primary.main,
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const StylesCSS = (theme: Theme) => ({
  tableRoot: {
    '&.MuiTableContainer-root': {
      border: 0,
      borderRadius: 0,
    },
  },
  tableRow: {
    height: '160px',
  },
  tableHead: {
    backgroundColor: theme.palette.customWhite.main,
  },
  tableCell: {
    tableLayout: 'fixed',
    width: '20%',
    align: 'center',
  },
  layoutStyle: {
    margin: theme.spacing(2),
  },
  innerBoxLayout: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr 300px',
  },
  pageCaptionBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  imageTableCell: {
    tableLayout: 'fixed',
    width: '20%',
    align: 'center',
  },
  linkText: {
    height: '27px',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '27px',
    letterSpacing: '0.1px',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});