/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const styles = {
  writingAnchorFontSize: {
    fontSize: '4rem',
  },
};

const PronunciationIcon = (props: any) => {
  return (
    <SvgIcon
      fontSize="small"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      classes={{ fontSizeLarge: styles.writingAnchorFontSize }}
      {...props}
    >
      <g opacity="0.8">
        <g clipPath="url(#clip0)">
          <path
            d="M33.8413 31.0788C30.7442 31.0788 29.255 32.8054 24.1724 32.8054C19.0897 32.8054 17.6113 31.0788 14.5034 31.0788C6.49633 31.0788 0 37.5751 0 45.5822V50.0713C0 52.931 2.32012 55.2511 5.17979 55.2511H43.165C46.0246 55.2511 48.3447 52.931 48.3447 50.0713V45.5822C48.3447 37.5751 41.8484 31.0788 33.8413 31.0788ZM44.8916 50.0713C44.8916 51.021 44.1146 51.7979 43.165 51.7979H5.17979C4.23017 51.7979 3.4532 51.021 3.4532 50.0713V45.5822C3.4532 39.4851 8.40637 34.532 14.5034 34.532C16.6185 34.532 18.7228 36.2586 24.1724 36.2586C29.6112 36.2586 31.7262 34.532 33.8413 34.532C39.9384 34.532 44.8916 39.4851 44.8916 45.5822V50.0713ZM24.1724 27.6256C31.8018 27.6256 37.9852 21.4422 37.9852 13.8128C37.9852 6.18338 31.8018 0 24.1724 0C16.543 0 10.3596 6.18338 10.3596 13.8128C10.3596 21.4422 16.543 27.6256 24.1724 27.6256ZM24.1724 3.4532C29.8809 3.4532 34.532 8.10422 34.532 13.8128C34.532 19.5214 29.8809 24.1724 24.1724 24.1724C18.4638 24.1724 13.8128 19.5214 13.8128 13.8128C13.8128 8.10422 18.4638 3.4532 24.1724 3.4532Z"
            fill={props.fillColor || palette.customBlack.fontBlack}
          />
        </g>
        <path
          d="M43.4158 8.42353C42.7415 8.09548 41.905 8.3821 41.5735 9.071C41.2446 9.75905 41.5355 10.5844 42.2236 10.9133C43.4425 11.4994 44.2005 12.6096 44.2005 13.8131C44.2005 15.0165 43.4425 16.1267 42.2236 16.712C41.5355 17.0409 41.2446 17.8671 41.5735 18.5543C41.9067 19.2492 42.7424 19.5289 43.4158 19.2017C45.6033 18.1537 46.963 16.0887 46.963 13.8131C46.963 11.5374 45.6033 9.47157 43.4158 8.42353ZM55.2506 13.8122C55.2506 8.10411 52.3137 2.8812 47.5837 0.183408C46.9199 -0.194714 46.0652 0.0297417 45.681 0.691887C45.2969 1.35317 45.5282 2.19747 46.1956 2.57904C50.0605 4.78476 52.4639 9.08827 52.4639 13.8122C52.4639 18.5361 50.0605 22.8405 46.1956 25.0454C45.5291 25.4269 45.2977 26.2712 45.681 26.9325C46.0523 27.5696 46.8948 27.8338 47.5837 27.441C52.3137 24.7441 55.2506 19.5212 55.2506 13.8122Z"
          fill={props.fillColor || palette.customBlack.fontBlack}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="48.3447" height="55.2511" fill={palette.customWhite.main} />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default React.memo(PronunciationIcon);
