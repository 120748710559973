export const errorMessages = {
  teacherNameHelperText: 'Please enter the teacher name.',
  teacherIDHelperText: 'Please enter a valid teacher ID.',
  stateHelperText: 'Please select a state.',
  districtHelperText: 'Please select a district.',
  schoolHelperText: 'Please select a school.',
  titleHelperText: 'Please select title.',
  lastNameHelperText: 'Please enter last name.',
  firstNameHelperText: 'Please enter first name.',
  jobTitleHelperText: 'Please enter job title.',
  emailHelperText: 'Please enter a email ID.',
  validEmailText: 'Please enter the correct email address.',
  phoneHelperText: 'Please enter a phone number.',
  passwordHelperText: 'Please set a password.',
  classHelperText: 'Please select a class.',
};

export const labels = {
  checkBoxLabel: "I'll be adding another teacher after this one",
};

export const titles = [
  {
    value: 'none',
    label: 'No Title',
  },
  {
    value: 'Mrs',
    label: 'Mrs.',
  },
  {
    value: 'Ms',
    label: 'Ms.',
  },
  {
    value: 'Mr',
    label: 'Mr.',
  },
];

export const HERITAGE_LANGUAGE_OTHER_VALUE = 'Other';
export const HERITAGE_LANGUAGE_FREQUENT_OPTIONS = [
  {
    key: 'english',
    value: 'English',
    divider: true,
  },
  {
    key: 'spanish',
    value: 'Spanish',
    divider: false,
  },
  {
    key: 'chinese',
    value: 'Chinese',
    divider: false,
  },
  {
    key: 'arabic',
    value: 'Arabic',
    divider: false,
  },
  {
    key: 'vietnamese',
    value: 'Vietnamese',
    divider: true,
  },
  {
    key: 'armenian',
    value: 'Armenian',
    divider: false,
  },
  {
    key: 'bengali',
    value: 'Bengali',
    divider: false,
  },
  {
    key: 'french',
    value: 'French',
    divider: false,
  },
  {
    key: 'frenchcreole',
    value: 'French Creole',
    divider: false,
  },
  {
    key: 'german',
    value: 'German',
    divider: false,
  },
  {
    key: 'greek',
    value: 'Greek',
    divider: false,
  },
  {
    key: 'gujarati',
    value: 'Gujarati',
    divider: false,
  },
  {
    key: 'hebrew',
    value: 'Hebrew',
    divider: false,
  },
  {
    key: 'hindi',
    value: 'Hindi',
    divider: false,
  },
  {
    key: 'hmong',
    value: 'Hmong',
    divider: false,
  },
  {
    key: 'italian',
    value: 'Italian',
    divider: false,
  },
  {
    key: 'japanese',
    value: 'Japanese',
    divider: false,
  },
  {
    key: 'korean',
    value: 'Korean',
    divider: false,
  },
  {
    key: 'panjabi',
    value: 'Panjabi',
    divider: false,
  },
  {
    key: 'persian',
    value: 'Persian',
    divider: false,
  },
  {
    key: 'polish',
    value: 'Polish',
    divider: false,
  },
  {
    key: 'portuguese',
    value: 'Portuguese',
    divider: false,
  },
  {
    key: 'russian',
    value: 'Russian',
    divider: false,
  },
  {
    key: 'tagalog',
    value: 'Tagalog',
    divider: false,
  },
  {
    key: 'telugu',
    value: 'Telugu',
    divider: false,
  },
  {
    key: 'urdu',
    value: 'Urdu',
    divider: false,
  },
  {
    key: 'other',
    value: HERITAGE_LANGUAGE_OTHER_VALUE,
    divider: false,
  },
];
