import { useEffect, useState } from 'react';
import useUserInfo from '../../utils/useUserInfo';

const useScoreSchemeDomain = () => {
  const [isLoadingUserInfo, user] = useUserInfo();
  const [yAxisDomain, setYAxisDomain] = useState<Array<number>>([]);
  const [yAxisTicks, setYAxisTicks] = useState<Array<number>>([]);
  const scoreScheme = user?.score_scheme?.scoreDetail;

  useEffect(() => {
    let yAxisMaxDomain: number = 0;
    scoreScheme?.forEach((score: any, index: number) => {
      yAxisMaxDomain = Math.max(yAxisMaxDomain, score?.score_value);
    });
    setYAxisDomain([1, yAxisMaxDomain]);
    setYAxisTicks([...Array(yAxisMaxDomain).keys()].map((i) => i + 1));
  }, [scoreScheme]);

  return {
    yAxisDomain,
    yAxisTicks,
    scoreScheme,
  };
};
export default useScoreSchemeDomain;