import React from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import ImageUpload from '../../../components/ImageUpload';
import MultipleSelectChips from '../../../components/MultiSelectChips';
import useUpload from './upload-image-hook';
import Loader from '../../../components/Loader/loader';
import { AddButton, ImageBox } from '../ImageUpload.styled';
import TaskTypes from '../../../components/TaskTypes';
import KeywordSearch from '../../../components/AddKeywordTags';
import { GradeOptions, THUMBNAIL_RES, THUMBNAIL_MAX_FILE_SIZE_KB, STATIC_RESOURCE_IMG_UPLOAD_DEL_TEXT } from '../../../variables/constant';
import { SettingsValidation } from '../../../variables/formValidation';

interface GradeSelectorProp {
  onChange: (selectedItems: string[] | null) => void,
  value?: string[],
}

const FileUpload = () => {
  const {
    imageFile,
    setImageFile,
    thumbnailFile,
    setThumbnailFile,
    getInputProps,
    getRootProps,
    getThumbnailRootProps,
    getThumbnailInputProps,
    remove,
    removeThumbnail,
    showWarning,
    setShowWarning,
    showThumbnailWarning,
    setShowThumbnailWarning,
    open,
    openThumbnail,
    handleOpenThumbnail,
    handleClose,
    handleOpen,
    uploadImage,
    handleSubmit,
    errors,
    register,
    loading,
    control,
    gradeValue,
    subjectAreas,
  } = useUpload();

  const subjectOptions = subjectAreas?.map(subject => ({ label: subject.title, value:subject.id }));

  return (
    <>
      <Loader open={loading} />
      <ImageBox sx={{ height: 'auto', pb: '20px' }}>
        <Typography align={'left'} variant='tileTitle'>
          New Image
        </Typography>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(uploadImage)}
        >
          <Stack direction='row' spacing={2}>
            <Box>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>Image Title</Typography>
                <TextField
                  id="image-title"
                  aria-describedby="image-title"
                  error={!!errors.title}
                  fullWidth
                  name="title"
                  helperText={errors.title && 'Please enter the image title.'}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>Grade Level</Typography>
                <Controller
                  name="grades"
                  render={({ onChange, value }: GradeSelectorProp) => (
                    <MultipleSelectChips
                      value={value}
                      onChange={onChange}
                      options={GradeOptions}
                    />
                  )}
                  rules={{
                    required: SettingsValidation.GRADE_LEVEL,
                    validate: (value) => value?.length > 0 || SettingsValidation.GRADE_LEVEL,
                  }}
                  control={control}
                  defaultValue={gradeValue}
                />
                {
                  errors.grades && <Typography variant='sFormHelper' sx={{ fontSize: '14px' }}>{SettingsValidation.GRADE_LEVEL}</Typography>
                }
              </Box>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>Subject Area</Typography>
                <Controller
                  name="subject_areas"
                  render={({ onChange, value }) => (
                    <MultipleSelectChips
                      value={value}
                      onChange={onChange}
                      options={subjectOptions as Object[]}
                    />
                  )}
                  rules={{
                    required: SettingsValidation.SUBJECT_AREA,
                    validate: (value) => value?.length > 0 || SettingsValidation.SUBJECT_AREA,
                  }}
                  control={control}
                  defaultValue={[]}
                />
                {
                  errors.subject_areas && <Typography variant='sFormHelper' sx={{ fontSize: '14px' }}>{SettingsValidation.SUBJECT_AREA}</Typography>
                }
              </Box>
              <Box mt={2} sx={{ minHeight: '48px' }}>
                <Controller
                  name="task_types"
                  render={({ onChange, value }) => (
                    <TaskTypes
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: SettingsValidation.TASK_TYPE,
                    validate: (value) => value?.length > 0 || SettingsValidation.TASK_TYPE,
                  }}
                  control={control}
                  defaultValue={[]}
                />
                {
                  errors.task_types && <Typography variant='sFormHelper' sx={{ fontSize: '14px' }}>{SettingsValidation.TASK_TYPE}</Typography>
                }
              </Box>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>Add Keyword Tags</Typography>
                <Controller
                  name="keywords"
                  render={({ onChange, value }) => (
                    <KeywordSearch
                      onChange={onChange}
                      value={value}/>
                  )}
                  rules={{
                    required: SettingsValidation.KEYWORDS,
                    validate: (value) => value?.length > 0 || SettingsValidation.KEYWORDS,
                  }}
                  control={control}
                  defaultValue={[]}
                />
                {
                  errors.keywords && <Typography variant='sFormHelper' sx={{ fontSize: '14px' }}>{SettingsValidation.KEYWORDS}</Typography>
                }
              </Box>
            </Box>
            <Box pl='15%'>
              <Box>
                <Typography variant='subtitle1' sx={{ fontWeight: '800' }}>Image</Typography>
                <ImageUpload
                  files={imageFile}
                  setFiles={setImageFile}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  deleteImage={remove}
                  showWarning={showWarning}
                  setShowWarning={setShowWarning}
                  header="Delete this image?"
                  subheader={STATIC_RESOURCE_IMG_UPLOAD_DEL_TEXT}
                  primaryTitle="Keep Image"
                  secondaryTitle="Delete Image"
                  open={open}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                />
              </Box>
              <Box mt={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '800' }}>
                  Thumbnail Image
                  <Typography sx={{ fontSize: '12px' }} component="span" >{' (Optional)'}</Typography>
                </Typography>
                <Typography variant='font16'>File needs to be less than {THUMBNAIL_MAX_FILE_SIZE_KB} KB</Typography>
                <ImageUpload
                  thumbnail
                  resolution={THUMBNAIL_RES}
                  dropzoneRefKey="upload-images-thumbnail"
                  files={thumbnailFile}
                  setFiles={setThumbnailFile}
                  getRootProps={getThumbnailRootProps}
                  getInputProps={getThumbnailInputProps}
                  deleteImage={removeThumbnail}
                  showWarning={showThumbnailWarning}
                  setShowWarning={setShowThumbnailWarning}
                  header="Delete this image?"
                  subheader={STATIC_RESOURCE_IMG_UPLOAD_DEL_TEXT}
                  primaryTitle="Keep Thumbnail Image"
                  secondaryTitle="Delete Thumbnail Image"
                  open={openThumbnail}
                  handleClose={handleOpenThumbnail}
                  handleOpen={handleOpenThumbnail}
                />
              </Box>
            </Box>
          </Stack>
          <Box
            display="flex"
          >
            <AddButton
              variant="contained"
              color="primary"
              type="submit"
            >
              Add Image
            </AddButton>
          </Box>
        </form>
      </ImageBox>
    </>
  );
};

export default FileUpload;
