import React from 'react';
import {
  CardContent,
  CardActionArea,
  CardMedia,
  Button,
  Typography,
} from '@mui/material';
import { StyledCard, StyledCardActions, StyledButton } from './celebrationCard-styled';

interface Props {
  imageUrl: string,
  imageTitle:string,
  primaryButtonText: string,
  secondaryButtonText?: string,
  primaryMessage: string,
  secondaryMessage?: string,
  primaryAction: Function,
  secondaryAction?: Function
}

const styles = {
  media: {
    width: 540,
    height: 310,
  },
};

const CelebrationCard = ({
  imageUrl,
  imageTitle,
  primaryButtonText,
  secondaryButtonText,
  primaryMessage,
  secondaryMessage,
  primaryAction,
  secondaryAction,
}: Props) => {
  return (
    <StyledCard>
      <CardActionArea>
        <CardMedia
          component="img"
          src={imageUrl}
          alt={imageTitle}
          sx={{ ...styles.media }}/>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h5">
            {primaryMessage}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {secondaryMessage}
          </Typography>
        </CardContent>
      </CardActionArea>
      <StyledCardActions>
        {secondaryButtonText && secondaryAction && (
          <StyledButton size="large" color="primary" onClick={() => secondaryAction()}>
            {secondaryButtonText}
          </StyledButton>
        )}
        <Button size="large" color="primary" variant="contained" onClick={() => primaryAction()}>
          {primaryButtonText}
        </Button>
      </StyledCardActions>
    </StyledCard>
  );
};

export default CelebrationCard;
