import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PronunciationIcon from '../../../components/Icons/PronunciationIcon';
import TableHeaderChip from '../../../components/TableHeaderChip';
import useAnchorData, { Datum, GradeEntity, SkillLevel, TableEntity } from './anchors-hook';
import TableHeader from '../../../components/TableHeader/TableHeader';
import useRedirect from '../../../hooks/useRedirect';
import AnchorTableCell from '../../../components/AnchorTableCell';
import { Skills } from '../../../variables/types';
import ArrowLeft from '../../../components/Icons/ArrowLeft';
import ArrowRight from '../../../components/Icons/ArrowRight';
import {
  StyledBoxAnchorGrades,
  StyledBoxAnchorGradesChip,
  StyledTableRow,
  StyledTypographyAnchorSubText,
  StyledTypographyAnchorText,
  StyledAnchorTextButton,
  StylesCSS,
} from '../TeacherToolbox.styled';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';
import WritingAnchorIcon from '../../../components/Icons/WritingAnchorIcon';
import FullscreenImage from '../../../components/FullscreenImage/FullscreenImage';
import BackdropModal from '../../../components/BackdropModal/BackdropModal';

interface Props {
  section: string;
}

const Anchors = (props: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const { section } = props;
  const nextPage = section === Skills.Speaking ? Skills.Writing : Skills.Speaking;
  const {
    anchorData,
    tableChipData,
    gradeChipData,
    grade,
    setGrade,
    anchorContent,
    selectedPlayerId,
    setSelectedPlayerId,
    showPreview,
    setShowPreview,
  } = useAnchorData({ section });
  const { navigateTo: navigateToPath } = useRedirect();
  const navigateTo = (route: string) => () => {
    navigateToPath(route);
  };

  const handleGradeClick = (selectedGrade:string) => {
    setSelectedPlayerId('');
    setGrade(selectedGrade);
  };

  const handleFullscreen = () => {
    setShowPreview(!showPreview);
  };

  const getSpeakingRow = (category:string) => {
    switch (category) {
      case 'Interpretation':
        return anchorContent?.interpretation;
      case 'Vocabulary':
        return anchorContent?.vocabulary;
      case 'Grammar':
        return anchorContent?.grammar;
      case 'Fluency':
        return anchorContent?.fluency;
      case 'Pronunciation':
        return anchorContent?.pronunciation;
      case 'Vocabulary & Grammar':
        return anchorContent?.vocabularyAndGrammar;
      case 'Description/Explanation':
        return anchorContent?.descriptionAndExplanation;
      case 'Points & Reasons':
        return anchorContent?.pointsAndReasons;
      default:
        return null;
    }
  };

  return (
    <StyledFlexColBoxContainer sx={{ m: theme.spacing(4) }}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box display="flex" flexDirection="column">
          <Box
            sx={{ ...styles.innerBoxLayout }}
            mb={theme.spacing(4)}
          >
            <Box display="flex" justifyContent="flex-start">
              <StyledAnchorTextButton
                variant="text"
                onClick={navigateTo('/toolbox/speaking-writing-anchors')}
                startIcon={<ArrowLeft />}
              >
                Back
              </StyledAnchorTextButton>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box sx={{ ...styles.pageCaptionBox }}>
                {section === Skills.Speaking
                  ? <PronunciationIcon fontSize="medium" />
                  : <WritingAnchorIcon fontSize="medium" />}
                <StyledTypographyAnchorText>
                  {anchorData?.pageCaption}
                </StyledTypographyAnchorText>
              </Box>
              <StyledTypographyAnchorSubText>
                {anchorData?.pageSubCaption}
              </StyledTypographyAnchorSubText>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <StyledAnchorTextButton
                variant="text"
                onClick={navigateTo(
                  `/toolbox/speaking-writing-anchors/${nextPage}`,
                )}
                endIcon={<ArrowRight />}
              >
                {`${nextPage} Anchors `}
              </StyledAnchorTextButton>
            </Box>
          </Box>
          <StyledBoxAnchorGrades>
            {gradeChipData?.map((grades: GradeEntity) => (
              <StyledBoxAnchorGradesChip
                label={grades.label}
                color={grade === grades.label ? 'primary' : 'secondary'}
                variant={grade !== grades.label ? 'outlined' : undefined}
                defaultValue={grades.label}
                onClick={() => handleGradeClick(grades.label)}
                key={grades.label}
              />
            ))}
          </StyledBoxAnchorGrades>
        </Box>
        <Box display="flex">
          <TableContainer sx={styles.tableRoot}>
            <Table aria-label="customized table">
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  <TableCell
                    sx={styles.tableCell}
                  >
                    {gradeChipData?.map((grades: GradeEntity) => (
                      grade === grades.label && (
                        <Box>
                          <BackdropModal
                            open={showPreview}
                            onClose={handleFullscreen}
                          >
                            <FullscreenImage
                              imageSrc={grade === grades.label
                                ? grades.pt_image : ''}
                              setShowPreview={setShowPreview}
                              showPreview={showPreview}
                              fullScreen
                            />
                          </BackdropModal>
                          <FullscreenImage
                            imageSrc={grade === grades.label
                              ? grades.pt_image : ''}
                            setShowPreview={setShowPreview}
                            showPreview={showPreview}
                            fullScreen={false}
                          />
                        </Box>
                      )
                    ))}
                  </TableCell>
                  {tableChipData?.map((row: TableEntity) => (
                    <TableHeaderChip
                      key={row.label}
                      fill={row.fill as string}
                      textColor={row.textColor as string}
                      label={row.label}
                      information={row.description}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {anchorData?.data?.map((row: Datum) => (
                  <StyledTableRow
                    sx={ styles.tableRow }
                    key={row.name}
                  >
                    <TableHeader
                      name={row?.name}
                      subText={row?.subText}
                      Icon={row?.Icon}
                    />
                    {getSpeakingRow(row?.name)?.map((cell: SkillLevel) => {
                      const id = `${row.name}_${cell.scoreType}_${grade}`;
                      return (
                        <AnchorTableCell
                          anchorType={section}
                          url={cell?.url}
                          fileType={cell?.fileType}
                          key={id}
                          id={id}
                          disable={cell?.disable}
                          onPlaying={setSelectedPlayerId}
                          active={selectedPlayerId === id}
                          data={cell?.description}
                        />
                      );
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </StyledFlexColBoxContainer>
  );
};

export default React.memo(Anchors);
