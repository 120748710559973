import { SxProps, Theme } from '@mui/material';
import React from 'react';
import RadioGroup, { RadioGroupProps } from '../Radio';

interface Props {
  onChange: (value: string) => void;
  value?: string,
  disabled?: boolean,
  radioContainerStyles?: SxProps<Theme> | undefined,
  scoreSchemeOptions?: RadioGroupProps[]
}

const ScoreSchemeRadioGroup = ({
  value,
  onChange,
  disabled,
  radioContainerStyles,
  scoreSchemeOptions,
}: Props) => (
  <RadioGroup
    disableSelection={disabled}
    handleChange={
      (event: React.ChangeEvent<HTMLInputElement>) => onChange(event?.target?.value)
    }
    radios={scoreSchemeOptions}
    radioContainerStyles={radioContainerStyles}
    value={value ?? ''}
  />
);

export default React.memo(ScoreSchemeRadioGroup);
