import React from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { styledTypo } from '../Player/Player.styled';
import secondsToMinute from '../../utils/secondsToMinutes';

interface Props {
  currentTime?: number
  onChangeCommitted: (event: React.SyntheticEvent | Event, value: number | Array<number>) => void,
  max?: number,
  customStyle?: boolean
}

const AudioSlider = ({ currentTime, onChangeCommitted, max, customStyle }: Props) => (
  <Box
    display="grid"
    gridTemplateColumns={customStyle ? '0.5fr 7fr 0.5fr' : '1fr 7fr 1fr'}
    sx={{
      gridGap: 14,
    }}
    width={customStyle ? '100%' : '70%'}
  >
    <Typography variant ='sTaskTitle' sx={{ ...styledTypo }}>
      {secondsToMinute(currentTime ?? 0)}
    </Typography>
    <Slider
      size="small"
      onChangeCommitted={onChangeCommitted}
      value={currentTime || 0}
      min={0}
      max={max}
    />
    <Typography variant ='sTaskTitle' sx={{ ...styledTypo }}>
      {max ? secondsToMinute(max) : '--:--'}
    </Typography>
  </Box>
);

export default AudioSlider;
