import { useTaskTypesFilterQuery } from '../../generated/graphql';

const useTaskTypesFilter = ()=>{
  const { data, loading } = useTaskTypesFilterQuery({
    fetchPolicy: 'network-only',
  });

  return {
    taskTypes: data?.taskType ?? [],
    loading,
  };
};

export default useTaskTypesFilter;
