import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import {
  drawAutotableCellEmptyIcons,
  drawAutotableCellIcons,
  drawAutotableRowBorders,
  drawColumnBorders,
  drawSmallAutotableCellChipsWithText,
} from '../../../../utils/pdf';
import { BenchmarkComparisonTabKeys, UnassignedStudents } from '../../../../variables/constant';

interface Props {
  pdfDoc: jsPDF,
  posY: number,
  styles: any,
  tabKey?: string,
  dashboardData: any,
}

export const BenchmarkComparisonPdf = ({
  pdfDoc,
  posY,
  styles,
  tabKey,
  dashboardData,
}: Props) => {
  let positionY = 0;

  const progressScoresTable = {
    0: {
      'key': 'name',
      'name': tabKey === BenchmarkComparisonTabKeys.School ?
        'School' : tabKey === BenchmarkComparisonTabKeys.Grade ? 'Grade' : 'Teacher',
    },
    1: {
      'key': 'speaking_boy_score',
      'name': 'BOY',
    },
    2: {
      'key': 'speaking_boy_to_moy_progress',
      'name': '',
    },
    3: {
      'key': 'speaking_moy_score',
      'name': 'MOY',
    },
    4: {
      'key': 'speaking_moy_to_eoy_progress',
      'name': '',
    },
    5: {
      'key': 'speaking_eoy_score',
      'name': 'EOY',
    },
    6: {
      'key': 'speaking_ytd_change',
      'name': 'YTD Change',
    },
    7: {
      'key': 'writing_boy_score',
      'name': 'BOY',
    },
    8: {
      'key': 'writing_boy_to_moy_progress',
      'name': '',
    },
    9: {
      'key': 'writing_moy_score',
      'name': 'MOY',
    },
    10: {
      'key': 'writing_moy_to_eoy_progress',
      'name': '',
    },
    11: {
      'key': 'writing_eoy_score',
      'name': 'EOY',
    },
    12: {
      'key': 'writing_ytd_change',
      'name': 'YTD Change',
    },
  };

  const getColVal = (score: any, colkey: string) => {
    const speakingScore = score?.benchmark_data_points?.find?.((score_by_type: any) => score_by_type?.submission_type === 'Speaking');
    const writingScore = score?.benchmark_data_points?.find?.((score_by_type: any) => score_by_type?.submission_type === 'Writing');
    if (colkey === 'name') {
      return !score?.name && score.id === UnassignedStudents.toLowerCase() ? UnassignedStudents : `${score?.name}`;
    }
    if (colkey === 'speaking_boy_score') {
      return speakingScore?.boy_score;
    }
    if (colkey === 'speaking_moy_score') {
      return speakingScore?.moy_score;
    }
    if (colkey === 'speaking_eoy_score') {
      return speakingScore?.eoy_score;
    }
    if (colkey === 'writing_boy_score') {
      return writingScore?.boy_score;
    }
    if (colkey === 'writing_moy_score') {
      return writingScore?.moy_score;
    }
    if (colkey === 'writing_eoy_score') {
      return writingScore?.eoy_score;
    }
    if (colkey === 'speaking_ytd_change') {
      return speakingScore?.ytd_change;
    }
    if (colkey === 'writing_ytd_change') {
      return writingScore?.ytd_change;
    }
    if (colkey === 'speaking_moy_to_eoy_progress') {
      return speakingScore?.moy_to_eoy_progress;
    }
    if (colkey === 'speaking_boy_to_moy_progress') {
      return speakingScore?.boy_to_moy_progress;
    }
    if (colkey === 'writing_boy_to_moy_progress') {
      return writingScore?.boy_to_moy_progress;
    }
    if (colkey === 'writing_moy_to_eoy_progress') {
      return writingScore?.moy_to_eoy_progress;
    }
    return score[colkey];
  };

  const tableHeader1: RowInput = [];
  const tableHeader2: RowInput = [];
  tableHeader1.push({ colSpan: 1, content: '' }, { colSpan: 6, content: 'Speaking' }, { colSpan: 6, content: 'Writing' });
  Object.values(progressScoresTable).forEach((column: any) => tableHeader2.push(column.name));

  const tableRows: RowInput[] = [];
  dashboardData?.forEach((score: any) => {
    const dataRow: RowInput = [];
    Object.values(progressScoresTable).forEach(column => dataRow.push(getColVal(score, column.key)));
    tableRows.push(dataRow);
  });

  autoTable(pdfDoc, {
    head: [tableHeader1, tableHeader2],
    body: tableRows,
    theme: 'plain',
    margin: styles.margin,
    headStyles: styles.header,
    styles: styles.rows,
    columnStyles: styles.columns,
    didDrawCell: (data) => {
      drawAutotableRowBorders(data, pdfDoc);
      drawSmallAutotableCellChipsWithText(data, pdfDoc, [1, 3, 5, 7, 9, 11], 3);
      drawAutotableCellIcons(data, pdfDoc, [2, 4, 8, 10]);
      drawColumnBorders(data, pdfDoc, [6]);
      drawAutotableCellEmptyIcons(data, pdfDoc, [6, 12]);
    },

    didDrawPage: (data) => {
      positionY = (data.cursor?.y ?? 0);
    },

    startY: posY,
  });
  return positionY;
};
