import {
  useAddTypeformUserMappingMutation,
  useGetAdminDashboardTourTypeformPhase2Query,
} from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';

const usePerformance = () => {
  const { data: typeformDetails, loading: typeformDetailsLoading, refetch: refetchTypeform } = useGetAdminDashboardTourTypeformPhase2Query({
    fetchPolicy: 'network-only',
    variables: {},
  });

  const [addTypeformUserMapping, { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();

  // call mutation to add typeform user mapping
  const onTypeformReady = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetails?.getAdminDashboardTourTypeformPhase2?.id ?? '',
          submitted: true,
        },
      });
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
    refetchTypeform();
  };

  const onTypeformSubmit = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetails?.getAdminDashboardTourTypeformPhase2?.id ?? '',
          submitted: true,
        },
      });
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
    refetchTypeform();
  };
  
  return {
    updateUserLoading,
    loading: typeformDetailsLoading,
    onTypeformReady,
    onTypeformSubmit,
    typeformData: typeformDetails?.getAdminDashboardTourTypeformPhase2 ?? null,
  };

};

export default usePerformance;