import React, { useState } from 'react';
import { Box, FormControl, TextField, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Layout from '../../components/Layout';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import Loader from '../../components/Loader/loader';
import { inputStyles } from '../Login/Credentials';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();

  const onResetPassword = async (data:Record<string, string>) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, data.password.trim(), data.newPassword.trim());
      openSnackbar({ message: 'Password updated successfully.' }, NotifierType.Success);
    } catch (err: any) {
      openSnackbar({ message: err.message }, NotifierType.Error);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        m={0}
        p={0}
        width="100%"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '4px', width: '100%' }}>
          <Box mb={1}>
            <Typography variant="pageTitle">Reset Password</Typography>
          </Box>
          <Box sx={{ display: 'grid', mt: '2px', width: '100%' }}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(onResetPassword)}
              noValidate
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
              }}
            >
              <FormControl sx={{ mt: 5 }}>
                <TextField
                  fullWidth
                  type="password"
                  name="password"
                  placeholder="Password"
                  sx={{ ...inputStyles.input }}
                  inputRef={register({
                    required: { value: true, message: '*Password is required' },
                  })}
                />
                {
                  errors.password && (
                    <Typography variant='sFormHelper'>
                      {errors?.password?.message}
                    </Typography>
                  )
                }
              </FormControl>
              <FormControl sx={{ mt: 5 }}>
                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  name="newPassword"
                  placeholder="New Password"
                  sx={{ ...inputStyles.input }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{
                        position: 'absolute',
                        right: '0px',
                      }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: { value: true, message: '*New Password is required' },
                  })}
                />
                {
                  errors.newPassword && (
                    <Typography variant='sFormHelper'>
                      {errors?.newPassword?.message}
                    </Typography>
                  )
                }
              </FormControl>
              <FormControl sx={{ mt: 5 }}>
                <TextField
                  fullWidth
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm New Password"
                  sx={{ ...inputStyles.input }}
                  inputRef={register({
                    validate: (value) => value === watch('newPassword') || 'The passwords do not match',
                    required: { value: true, message: '*Confirm New Password is required' },
                  })}
                />
                {
                  errors.confirmPassword && (
                    <Typography variant='sFormHelper'>
                      {errors?.confirmPassword?.message}
                    </Typography>
                  )
                }
              </FormControl>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={5}
                mb={2}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Reset Password
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
      <Loader open={loading} />
    </Layout>
  );
};

export default React.memo(ResetPassword);
