import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledTableContainer } from '../../TeacherDashboard/TeacherDashboard.styled';
import TablePagination from '../../../components/TablePagination';
import { PAGE_SIZE } from '../../../variables/constant';
import GroupsTable from './Groups';
import useGroups from '../groups-hook';
import SectionFilter from '../../../components/SectionsFilter';
import CreateGroup from '../CreateGroup';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';
import TeacherSchoolFilter from '../../../components/SchoolFilterTeacher';

const GroupList = () => {
  const theme = useTheme();

  const {
    loading,
    groups,
    totalCount,
    pagination,
    section,
    onSectionChange,
    handleAddGroup,
    school,
    onSchoolChange,
  } = useGroups();


  const [showModal, setShowModal] = useState(false);

  const handleChange = (_: any, value: React.SetStateAction<number>) => {
    if (typeof value === 'number') {
      pagination.setPage(value);
    }
  };

  const handleSuccess = () => {
    setShowModal(false);
    handleAddGroup();
  };

  return (
    <>
      {
        showModal && (
          <CreateGroup
            openDialog={showModal}
            header="Create a new group"
            closeDialog={() => { setShowModal(false); }}
            onSuccess={handleSuccess}
          />
        )
      }
      <StyledFlexColBoxContainer mt={theme.spacing(0.5)}>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="pageTitle">Student Groups</Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(3, 250px)"
            sx={{
              gridGap: theme.spacing(1),
            }}
          >
            <TeacherSchoolFilter
              value={school}
              onChange={onSchoolChange}
              placeholder="All Schools"
              idSelectOnly
            />
            <SectionFilter
              value={section}
              onChange={onSectionChange}
              idSelectOnly
              placeholder="All Classes"
              hideGroups
              schoolId={school}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              Create
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            width: '100%',
            marginTop: theme.spacing(0.25),
          }}
        >
          <Box>
            <StyledTableContainer>
              <GroupsTable
                stickyHeader
                loading={loading}
                groups={groups}
              />
            </StyledTableContainer>
          </Box>
          {totalCount > PAGE_SIZE && (
            <Box
              display="flex"
              justifyContent="flex-end"
              paddingTop={theme.spacing(2.5)}
            >
              <TablePagination
                count={Math.ceil(totalCount / PAGE_SIZE) || 0}
                page={pagination.page}
                onChange={handleChange}
                defaultPage={1}
              />
            </Box>
          )}
        </Box>
      </StyledFlexColBoxContainer>
    </>
  );
};

export default GroupList;
