import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const StyledBoxContainer = styled(Box)(({ theme }) => ({
  width: '500px',
  minHeight: '270px',
  display: 'flex',
  flexDirection: 'column',
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  borderRadius: '14px',
}));

export const StyledImageBox = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '65%',
});

export const StyledDescriptionBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 2,
});

export const PrimaryDetailBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 12,
}));

export const StyledViewButton = styled(Button)(() => ({
  fontWeight: 800,
  fontSize: 18,
  padding: '12px 40px',
  height: 50,
  width: 150,
  margin: 20,
}));

export const StyledImg = styled('img')(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '12px 12px 0 0',
  objectFit: 'cover',
  display: 'block',
}));
