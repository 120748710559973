import React, { useContext } from 'react';
import RecordingRow from './RecordingRow';
import { ScoreContext } from '../score-context';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';

const RecordingCard = () => {
  const { submission } = useContext(ScoreContext);

  return (
    <StyledFlexColBoxContainer>
      <RecordingRow recordings={submission?.speakings || []} />
    </StyledFlexColBoxContainer>
  );
};

export default React.memo(RecordingCard);
