import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import useSearchStudents from './search-student-hook';
import { SearchStudentFragmentFragment } from '../../../../generated/graphql';
import { AutoCompleteTextField } from '../../../../components/Autocomplete/Autocomplete.styled';
import { Box, List, ListItem, Button, styled, Typography } from '@mui/material';
import AddIcon from '../../../../components/Icons/AddIcon';
import { labels } from '../constants';
import { palette } from '../../../../theme/palette';


const StyledTextButton = styled(Button)(({ theme: { palette } }) => ({
    fontSize: '20px',
    letterSpacing: '0.1px',
    color: palette.primary.main,
    textTransform: 'none',
    height: '30px',
    left: '82px',
}));

const style = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '175px',
}

interface Props {
  closeDialog: (type?:string, id?: string) => void
}

const SearchStudentsField = ({ closeDialog }: Props) => {
  const {
    students,
    loading,
    onSearch,
  } = useSearchStudents();
  const [enteredValue, setEnteredValue] = useState<String>('');
  const [totalOptions, setTotalOptions] = useState(0)
  const [open, setOpen] = useState(false);
  const multiplyHeight = students.length > 3 ? 80 : 100;
  const minHeight = open && students.length > 1 ? `${students.length * multiplyHeight}px` : '130px';
  return (
    <Autocomplete
      freeSolo
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInput={(e) => onSearch(e)}
      onFocus={(e) => onSearch()}
      options={students || []}
      getOptionLabel={(option: any) => `${option?.user?.first_name ?? ''} ${option?.user?.last_name ?? ''}`}
      onChange={(_:any,  student: SearchStudentFragmentFragment | null) => closeDialog("edit", student?.id)}
      loading={loading}
      filterOptions={(options, params) => {
        setEnteredValue(params.inputValue);
        setTotalOptions(options.length)
        options.push({
            id: labels.createNewStudent,
            student_number: labels.createNewStudent
        });
        return options;
      }}
      PaperComponent={({ children }) => (
        <Box
          style={{
            border: `2px solid ${palette.customGrey.adminButtonBorder}`,
            borderRadius: '8px'
          }}
        >
          {children}
        </Box>
      )}
      renderOption={(props, option) => {
        const name = `${option.user?.first_name ?? ""} ${option.user?.last_name ?? ""}`;
        const studentNumber = option.student_number;
        if (totalOptions > 0) {
            return (
              <Box key={option.id} >
                  {option.student_number != labels.createNewStudent ? <List {...props} sx={{ p:'0px' }}>
                    <ListItem divider sx={{ justifyContent: 'space-between' }} >
                      <Typography variant='sLabel' sx={name.length > 30 ? { ...style} : {}} > {name ?? ''}</Typography>
                      <Typography variant='sLabel'>{option.grade ?? ''}</Typography>
                      <Typography variant='sLabel' sx={studentNumber.length > 30 ? { ...style} : {}}>{option.student_number ?? ''}</Typography>
                    </ListItem>
                  </List> :
                  <StyledTextButton variant="text" onClick={()=> closeDialog("create")} startIcon={<AddIcon />} >
                      {labels.createNewStudent}
                  </StyledTextButton>}
              </Box>
            )
        } else if (option.student_number == labels.createNewStudent) {
            return (
                <Box key={option.id}>
                    {enteredValue.length ? <ListItem divider><Typography variant='sLabel' >{`${labels.notFoundMsg} "${enteredValue}"`}</Typography></ListItem> : ""}
                    <StyledTextButton variant="text" onClick={()=> closeDialog("create")} startIcon={<AddIcon />} >
                      {labels.createNewStudent}
                    </StyledTextButton>
                </Box>
            );
        }
      }}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          sx={{ minHeight: minHeight }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default SearchStudentsField;
