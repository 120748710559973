import { StringParam, useQueryParam } from 'use-query-params';
import React, { useContext, useEffect, useState } from 'react';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import {
  DistrictFilterFragmentFragment,
  OrderBy,
  SchoolFilterFragmentFragment, SectionCategory,
  SectionsListSortType,
  StateFilterFragmentFragment,
  TeacherFilterFragmentFragment,
  UserPermissions,
  useSectionsListQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { ClassesSort } from '../../../variables/types';
import usePermission from '../../../hooks/usePermission';
import { SearchContext } from '../../../core/searchContext';



interface Props {
  classTypes?: SectionCategory[],
  districtID?: string,
  schoolID?: string,
  studentID?: string,
  teacherID?: string,
}

const useClasses = ({ classTypes, districtID, schoolID, studentID, teacherID }: Partial<Props> = {}) => {
  const pagination = usePagination();
  const { searchTerm } = useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [order, setOrder] = useQueryParam('order', StringParam);
  const [selectedState, setStateValue] = useQueryParam('state', StringParam);
  const [selectedDistrict, setSelectedDistrict] = useQueryParam('district', StringParam);
  const [selectedTeacher, setSelectedTeacher] = useQueryParam('teacher', StringParam);
  const [selectedSchool, setSelectedSchool] = useQueryParam('school', StringParam);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const { hasPermission: districtPermission } = usePermission([
    UserPermissions.ListDistricts,
    UserPermissions.ViewDistricts,
  ]);
  const { hasPermission: schoolPermission } = usePermission([
    UserPermissions.ListSchools,
    UserPermissions.ViewSchools,
  ]);

  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage]);

  const { data, loading } = useSectionsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: rowsPerPage,
      page: pagination.page,
      sort: sort as SectionsListSortType,
      orderBy: order as OrderBy,
      ...(searchTerm && searchTerm.length > 2 ? { searchByName: searchTerm } : {}),
      ...(selectedState ? { stateId: selectedState } : {}),
      ...(selectedDistrict || districtID ? { districtId: districtID ?? selectedDistrict } : {}),
      ...(selectedSchool || schoolID ? { schoolId: schoolID ?? selectedSchool } : {}),
      ...(selectedTeacher || teacherID ? { teacherId: teacherID ?? selectedTeacher } : {}),
      ...(studentID ? { studentId: studentID } : {}),
      ...(classTypes ? { classTypes } : {}),
    },
  });

  const onSortChange = (sortBy: ClassesSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const classes = data?.sectionsList.nodes ?? [];
  const totalCount = data?.sectionsList.totalCount ?? 0;

  const isTableEmpty = !loading && classes?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: ClassesSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onStateChange = (state: string | StateFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setStateValue(state as string);
    setSelectedDistrict(null);
    setSelectedTeacher(null);
    setSelectedSchool(null);
  };

  const onDistrictChange = (district: string | DistrictFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedDistrict(district as string);
    setSelectedTeacher(null);
    setSelectedSchool(null);
  };

  const onSchoolChange = (school: string | SchoolFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedSchool(school as string);
    setSelectedTeacher(null);
  };

  const onTeacherChange = (teacher: string | TeacherFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedTeacher(teacher as string);
  };

  return {
    loading,
    totalCount,
    classes,
    pagination,
    onSortChange,
    sort: sort as ClassesSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    selectedTeacher,
    onTeacherChange,
    selectedSchool,
    onSchoolChange,
    districtPermission,
    schoolPermission,
    rowsPerPage,
    setRowsPerPage,
  };
};

export default useClasses;
