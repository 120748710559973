/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';


const EmptyBenchmarkResults = () => {
  return (
    <SvgIcon width="210" height="209" viewBox="0 0 210 209" fill="none" xmlns="http://www.w3.org/2000/svg" sx={{ fontSize: '108px' }}>
      <g clip-path="url(#clip0_28866_57907)">
        <mask id="mask0_28866_57907" maskUnits="userSpaceOnUse" x="0" y="0" width="210" height="209">
          <path d="M209.27 0H0.728516V208.542H209.27V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_28866_57907)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M165.873 34.5717C168.168 36.9297 168.118 40.702 165.76 42.9974L34.1654 171.102C31.8074 173.397 28.0351 173.346 25.7397 170.988C23.4443 168.63 23.495 164.858 25.8529 162.563L157.447 34.4585C159.805 32.1631 163.578 32.2137 165.873 34.5717Z" fill="#D1D5DB" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M44.1758 144.724V152.926C44.1758 159.616 49.65 165.091 56.3406 165.091H58.0784C64.7693 165.091 70.2435 159.616 70.2435 152.926V119.347L44.1758 144.724ZM92.8354 97.3542V152.926C92.8354 159.616 98.3096 165.091 105 165.091C111.691 165.091 117.165 159.616 117.165 152.926V73.6698L92.8354 97.3542ZM117.165 69.5121L92.8354 93.1968V55.6062C92.8354 48.9156 98.3096 43.4414 105 43.4414C111.691 43.4414 117.165 48.9156 117.165 55.6062V69.5121ZM70.2435 115.19L44.1758 140.566V92.101C44.1758 85.4104 49.65 79.9362 56.3406 79.9362H58.0784C64.7693 79.9362 70.2435 85.4104 70.2435 92.101V115.19ZM165.825 125.12C165.825 118.429 160.351 112.955 153.66 112.955C146.969 112.955 141.495 118.429 141.495 125.12V152.926C141.495 159.616 146.969 165.091 153.66 165.091C160.351 165.091 165.825 159.616 165.825 152.926V125.12Z" fill="#D1D5DB" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_28866_57907">
          <rect width="208.542" height="208.542" fill="white" transform="translate(0.728516)" />
        </clipPath>
      </defs>
    </SvgIcon>



  );
};

export default EmptyBenchmarkResults;

