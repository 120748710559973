import { Button, Card, CardActions, styled } from '@mui/material';

export const StyledCard = styled(Card)({
  maxWidth: 520,
});

export const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: theme.spacing(2),
}));

export const StyledButton = styled(Button)({
  textTransform: 'none',
});
