import { Backdrop, Box, styled } from '@mui/material';
import zIndex from '../../theme/z-index';
import { palette  } from '../../theme/palette';

export const StyledLoaderContainer = styled(Box)({
  display: 'inline-block',
  position: 'relative',
  width: '230px',
  height: '230px',
});

export const StyledLoaderElement = styled(Box)(({ theme })=>({
  '@keyframes doughnutGraphLoader': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(-360deg)' },
  },
  boxSizing: 'border-box',
  display: 'block',
  position: 'absolute',
  width: '230px',
  height: '230px',
  margin: theme.spacing(1),
  border: `28px solid ${theme.palette.customGrey.main}`,
  borderRadius: '50%',
  animation: 'doughnutGraphLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
  borderColor: `${theme.palette.customGrey.main} transparent transparent transparent`,
  '&:nth-child(1)': {
    animationDelay: '-0.45s',
  },
  '&:nth-child(2)': {
    animationDelay: '-0.3s',
  },
  '&:nth-child(3)': {
    animationDelay: '-0.15s',
  },
}));

export const StyledBoxYaxis = styled(Box)({
  '@keyframes fadify': {
    '0%': { opacity: 1 },
    '100%': {
      opacity: 1,
    },
    '85%': {
      opacity: 0.85,
    },
    '70%': {
      opacity: 0.7,
    },
    '55%': {
      opacity: 0.55,
    },
    '40%': {
      opacity: 0.4,
    },
  },
  '@keyframes dash': {
    '0%': {
      stroke: palette.customGrey.darkGrey,
      transform: 'translateX(-490px)',
    },

    '50%': {
      stroke: palette.customGrey.silver,
    },

    '100%': {
      stroke: palette.customGrey.darkGrey,
      transform: 'translateX(0px)',
    },
  },
  display: 'grid',
  gridTemplateColumns: 'fr',
  gridGap: '16px',
  height: '200px',
  width: '100%',
  animation: 'fadify  1s infinite ease',
  maxWidth: '350px',
});

export const StyledSpanYTick = styled('span')({
  width: '100%',
  height: '20px',
  borderRadius: '2px',
  animationDuration: '1s',
  background: palette.customGrey.silver,
});

export const StyledBackdrop = styled(Backdrop)({
  /*
     * NOTE: theme.drawer.zIndex + 1 wasn't working
     *  as drawer zIndex value is 9999
     */
  zIndex: zIndex.zIndex99999,
  color: palette.overlays.overlay,
});

export const StyledSpanXTick = styled('span')({
  width: '40px',
  height: '20px',
  borderRadius: '4px',
  animationDuration: '1s',
  background: palette.customGrey.darkGrey,
});

export const StyledBoxXaxis = styled(Box)({
  width: '80%',
  position: 'absolute',
  bottom: 0,
  left: '7%',
  justifyContent: 'space-between',
  display: 'flex',
});

export const StyledSpanXBars = styled(Box)({
  width: '30px',
  height: '180px',
  margin: '0 5px',
  borderRadius: '4px',
  animationDuration: '1s',
  background: palette.customGrey.silver,
});

