import React from 'react';
import {
  Modal,
  Slide,
} from '@mui/material';
import { ModalTypes } from '../../../variables/types';
import { styles } from '../../../components/Flyover';
import { StyledFlyoverBox } from '../../../components/Flyover/flyover-styled';

export interface Props {
  open?: boolean,
  onClose?: any,
  variant?: ModalTypes,
  children?: any,
}

const Flyover = ({
  open, onClose,
  variant = ModalTypes.Drawer,
  children,
}: Props) => {

  const handleModalOnClose = (
    _: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason && ['backdropClick', 'escapeKeyDown'].includes(reason)) return;
    onClose();
  };

  return (
    <Modal
      open={open!}
      onClose={handleModalOnClose}
      sx={{ display: 'flex', alignItems: 'center' }}
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <Slide
        in={open}
        direction="left"
      >
        <StyledFlyoverBox
          sx={{
            ...styles[variant],
            maxWidth: 'calc(100% - 60vh)',
            minWidth: 'inherit',
            width: 'calc(100% - 70vh)',
          }}>
          {children}
        </StyledFlyoverBox>
      </Slide>
    </Modal>
  );
};

export default Flyover;
