import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import { Stack } from '@mui/material';
import { useTeacherBenchmarksDashboardContext } from '../../TeacherBenchmarksDashboard/teacherbenchmarks-dashboard-hook';
import {
  AdminBenchmarkReportWidgets,
  BENCHMARK_TYPE,
  BenchmarkComparisonTabKeys,
  PDF_GENERATING_ERROR,
  TeacherBenchmarkStudentGrowthReportLegendData,
} from '../../../variables/constant';
import { extractTitle, extractWidget, renderChips, renderLegend, renderText } from '../../../utils/pdf';
import { sentryErrorLog } from '../../../utils/sentry';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import { trackEvent } from '../../../utils/trackevent';
import { StyledIconButton } from '../../Assignments/Assignments.styled';
import DownloadFileIcon from '../../../components/Icons/DownloadFileIcon';
import PrintIcon from '../../../components/Icons/PrintIcon';
import { palette } from '../../../theme/palette';
import useUserInfo from '../../../utils/useUserInfo';
import { DashboardWidgetsDataStatusProps } from '../../AdminAnalytics/admin-analytics-hook';
import {
  useAdminGradeBenchmarkComparisonQuery,
  useAdminSchoolBenchmarkComparisonQuery,
  useAdminTeacherBenchmarkComparisonQuery,
} from '../../../generated/graphql';
import { BenchmarkComparisonPdf } from './BenchmarkComparison/pdf';
import { NetworkStatus } from '@apollo/client';

interface ExportToPdfProps {
  lastSnowflakeUpdateTimeData?: string;
  isBenchmarkComparison?: boolean;
  tabKey?: string;
  districtId?: string;
  schoolId?: string;
}

const ExportToPdf = ({
  lastSnowflakeUpdateTimeData,
  isBenchmarkComparison = false,
  tabKey,
  districtId,
  schoolId,
}: ExportToPdfProps) => {
  let pdfName = '';
  let dashboard = 'Admin Dashboard Benchmark Summary';
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dashboardPrint, setDashboardPrint] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const {
    areWidgetsLoading, isDataMissingInAllWidgets,
    loading, setLoading,
    isAdminReportBSGrowthDataMissing,
    dashboardWidgetsDataStatus,
  } = useTeacherBenchmarksDashboardContext();

  const indicatorWidgetDataStatus: any = dashboardWidgetsDataStatus?.filter((dashboardStatus: DashboardWidgetsDataStatusProps) => {
    return dashboardStatus?.widget === AdminBenchmarkReportWidgets?.BenchmarkSummary?.DistrictBenchmarkIndicatorScores.name;
  });

  const [_, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const legendData = scoreScheme?.map((item: any) => {
    return { skillTitle: item?.title, color: item?.color_code };
  });

  const {
    data: benchmarkComparisonSchoolData,
    loading: benchmarkComparisonSchoolloading,
    refetch: benchmarkComparisonSchoolRefetch,
    networkStatus: benchmarkComparisonSchoolNetworkStatus,
  } = useAdminSchoolBenchmarkComparisonQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { districtId: districtId  as string },
    skip: !isBenchmarkComparison || !(tabKey === BenchmarkComparisonTabKeys.School),
  });


  const {
    data: benchmarkComparisonGradeData,
    loading: benchmarkComparisonGradeloading,
    refetch: benchmarkComparisonGradeRefetch,
    networkStatus: benchmarkComparisonGradeNetworkStatus,
  } = useAdminGradeBenchmarkComparisonQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { districtId: districtId || undefined, schoolId: schoolId || undefined },
    skip: !isBenchmarkComparison || !(tabKey === BenchmarkComparisonTabKeys.Grade),
  });


  const {
    data: benchmarkComparisonTeacherData,
    loading: benchmarkComparisonTeacherloading,
    refetch: benchmarkComparisonTeacherRefetch,
    networkStatus: benchmarkComparisonTeacherNetworkStatus,
  } = useAdminTeacherBenchmarkComparisonQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { schoolId: schoolId as string },
    skip: !isBenchmarkComparison || !(tabKey === BenchmarkComparisonTabKeys.Teacher),
  });

  // Refetch the whole Dashboard Table Data
  const fetchDashboardData = async () => {
    try {
      if (isBenchmarkComparison) {
        setLoadingData(true);
        switch (tabKey) {
          case BenchmarkComparisonTabKeys.School:
            await benchmarkComparisonSchoolRefetch({ districtId: districtId  });
            setDashboardData(benchmarkComparisonSchoolData?.adminBenchmarkSchoolsComparison?.nodes ?? []);
            setTotalCount(benchmarkComparisonSchoolData?.adminBenchmarkSchoolsComparison?.total_count ?? 0);
            break;
          case BenchmarkComparisonTabKeys.Grade:
            await benchmarkComparisonGradeRefetch({ districtId: districtId, schoolId: schoolId  });
            setDashboardData(benchmarkComparisonGradeData?.adminBenchmarkGradeComparison?.nodes ?? []);
            setTotalCount(benchmarkComparisonGradeData?.adminBenchmarkGradeComparison?.total_count ?? 0);
            break;
          case BenchmarkComparisonTabKeys.Teacher:
            await benchmarkComparisonTeacherRefetch({ schoolId: schoolId });
            setDashboardData(benchmarkComparisonTeacherData?.adminBenchmarkTeacherComparison?.nodes ?? []);
            setTotalCount(benchmarkComparisonTeacherData?.adminBenchmarkTeacherComparison?.total_count ?? 0);
            break;
        }
      }
    } catch (e) {
      sentryErrorLog(e, [
        { label: 'pdfDataErr', value: `Error fetching data for PDF for District Comparisons > ${tabKey}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  };

  const renderFilterChips = (pdf: jsPDF, posX: number, posY: number, pdfPadding: number) => {
    let fontSize = 8;
    let height = 20;
    let defaultChipProps = {
      pdf: pdf, fontSize,
      x: posX, y: posY + pdfPadding - 3,
      rx: 4, ry: 4,
      height,
    };
    const chips = [`(${totalCount}) ${tabKey}`];
    renderChips(pdf, posX, posY, pdfPadding, chips, defaultChipProps, fontSize);
  };

  const generatePdf = async (print: boolean = false) => {
    try {
      setLoading?.(true);
      setLoadingData(true);
      const pdfPadding = 10;
      const pdfMargin = 20;
      const adjustHeight = 50;
      let posX = pdfPadding;
      let posY = pdfPadding + 5;
      let elHeight = 0;
      let elWidth = 0;
      const autoTableStyles = {
        margin: {
          left: pdfPadding,
          right: pdfPadding,
        },
        header: {
          fillColor: [42, 42, 139], // Set background color for the header row
          textColor: [255, 255, 255],
          fontSize: 8,
          fontStyle: 'bold',
          0: {
            halign: 'left',

          },
          6: {
            halign: 'center', // Draw a solid border on the right side of column 7
            cellWidth: 25,
          },
          12: {
            halign: 'center', // Draw a solid border on the right side of column 7
            cellWidth: 25,
          },
        },
        rows: {
          halign: 'center',
          fontSize: 8,

        },
        columns: {
          6: { cellWidth: 50 },
          10: { textColor: '#fff' },
          0: { halign: 'left' },
          2: { textColor: '#fff' },
          4: { textColor: '#fff' },
          8: { textColor: '#fff' },
          12: { cellWidth: 50 },
        },
      };
      const pdfDoc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = pdfDoc.internal.pageSize.getWidth();
      let headerText = isBenchmarkComparison ? 'Admin Dashboard Benchmark Comparison' : 'Admin Dashboard Benchmark Summary';
      let dashboardTitle = isBenchmarkComparison ? 'Benchmark Comparison' : 'Benchmark Summary';
      let dateString1: string = '';
      [posX, posY, dateString1] = await extractTitle(pdfDoc, posX, posY, pdfPadding, pdfMargin, headerText, dashboardTitle, lastSnowflakeUpdateTimeData);
      renderFilterChips(pdfDoc, posX, posY, pdfPadding);
      if (isBenchmarkComparison) {
        posY += 2 * pdfPadding + 5;
        posX = pdfPadding;
        const positionY = BenchmarkComparisonPdf({ pdfDoc, posY, styles: autoTableStyles, tabKey, dashboardData  },
        );
        posY = positionY + 2 * pdfPadding;
        const datalegend: any[] = [...legendData, { skillTitle: 'Incomplete / No Score', color: `${palette.customGrey.iconGrade}` }];
        renderLegend(pdfDoc, posX, posY, datalegend);
      } else {
        posY += 2 * pdfPadding;
        posX = pdfPadding - 5;
        [posX, posY, elHeight, elWidth] = await extractWidget(
          pdfDoc, posX, posY, pdfPadding,
          AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkSubmissionStatusReport.name,
          pageWidth, pdfMargin, adjustHeight - 15, 30, ['#submission-status-boy-id',
            '#submission-status-moy-id', '#submission-status-eoy-id']);
        renderText(pdfDoc, posX + 65, posY + 36, BENCHMARK_TYPE.BOY);
        renderText(pdfDoc, posX + 255, posY + 36, BENCHMARK_TYPE.MOY);
        renderText(pdfDoc, posX + 433, posY + 36, BENCHMARK_TYPE.EOY);

        posX = pdfPadding - 5;
        posY += elHeight + pdfMargin + 40;
        [posX, posY, elHeight, elWidth] = await extractWidget(
          pdfDoc, posX, posY, pdfPadding,
          AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkGrowthReport.name,
          (2 * pageWidth) / 3.6, pdfMargin, adjustHeight - 30, 20, ['#student-growth-report-legend']);
        const posYforNextWidget = posY;
        posX = pdfMargin + elWidth / 11 - 12; // Default positioning for legend
        posY += elHeight + 2 * pdfMargin - 40;
        if (!isAdminReportBSGrowthDataMissing) {
          renderLegend(pdfDoc, posX, posY, TeacherBenchmarkStudentGrowthReportLegendData as any[]);
        }

        posX = elWidth + pdfMargin + 15;
        posY = posYforNextWidget;
        [posX, posY, elHeight, elWidth] = await extractWidget(
          pdfDoc, posX, posY, pdfPadding,
          AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkScores.name,
          pageWidth / 2.2, pdfMargin, adjustHeight + 12, pdfMargin + 15);

        posX = pdfPadding - 5;
        posY += elHeight + pdfMargin + 65;
        [posX, posY, elHeight, elWidth] = await extractWidget(
          pdfDoc, posX, posY, pdfPadding,
          AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkIndicatorScores.name,
          pageWidth, pdfMargin, pdfMargin + 20, 30, ['#indicator-score-legend-data']);
        if (!indicatorWidgetDataStatus?.dataMissing) {
          renderLegend(pdfDoc, posX + 10, posY + 40, legendData as any[]);
        }
      }

      pdfName = `${headerText}-${dashboardTitle}-${dateString1}`;
      pdfDoc.setProperties({
        title: pdfName,
      });
      if (print) {
        pdfDoc.autoPrint({ variant: 'non-conform' });
        window.open(pdfDoc.output('bloburl'), '_blank');
      } else {
        pdfDoc.save(pdfName);
      }
      setDashboardData([]);
      setTotalCount(0);
      setDashboardPrint(false);
      setLoading?.(false);
      setLoadingData(false);
    } catch (e) {
      setDashboardData([]);
      setDashboardPrint(false);
      setTotalCount(0);
      setLoading?.(false);
      setLoadingData(false);
      sentryErrorLog(e, [
        { label: 'pdfErr', value: `Error Generating PDF for ${dashboard}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  };

  const generatePdfCustomEvent = (print: boolean) => {

    if (print) {
      isBenchmarkComparison ? trackEvent('abcmp_in_dist_schl_print', 'admin_benchmark_comparison_pdf_print') : trackEvent('adbs_in_st_print', 'admin_dashboard_benchmark_summary_pdf_print');
    } else {
      isBenchmarkComparison ? trackEvent('abcmp_in_dist_schl_download', 'admin_benchmark_comparison_download') : trackEvent('adbs_in_st_download', 'admin_dashboard_benchmark_summary_download');
    }
  };

  useEffect(() => {
    if (isBenchmarkComparison) {
      switch (tabKey) {
        case BenchmarkComparisonTabKeys.School:
          if ([benchmarkComparisonSchoolNetworkStatus].includes(NetworkStatus.refetch)) {
            setLoadingData(true);
          }
          setLoadingData(benchmarkComparisonSchoolloading);
          break;
        case BenchmarkComparisonTabKeys.Grade:
          if ([benchmarkComparisonGradeNetworkStatus].includes(NetworkStatus.refetch)) {
            setLoadingData(true);
          }
          setLoadingData(benchmarkComparisonGradeloading);
          break;
        case BenchmarkComparisonTabKeys.Teacher:
          if ([benchmarkComparisonTeacherNetworkStatus].includes(NetworkStatus.refetch)) {
            setLoadingData(true);
          }
          setLoadingData(benchmarkComparisonTeacherloading);
          break;
      }
    }
  }, [
    benchmarkComparisonGradeNetworkStatus,
    benchmarkComparisonTeacherNetworkStatus,
    benchmarkComparisonSchoolNetworkStatus,
    benchmarkComparisonTeacherloading,
    benchmarkComparisonSchoolloading,
    benchmarkComparisonGradeloading,
  ]);

  useEffect(() => {
    if (dashboardData.length > 0) {
      try {
        generatePdf(
          dashboardPrint,
        );
      } catch (e) {
        sentryErrorLog(e, [
          { label: 'pdfErr', value: `Error Generating PDF for District-wide Comparisons > ${tabKey}` },
        ]);
        openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
      }
    }
  }, [dashboardData, dashboardPrint, totalCount]);


  const isDisabled =  areWidgetsLoading || isDataMissingInAllWidgets || loading || loadingData;

  return (
    <Stack direction="row" justifyContent="end" marginRight={-1}  >
      <StyledIconButton
        id='download-pdf-button-admin-dashboard-benchmark-summary'
        color="primary"
        onClick={() => {
          generatePdfCustomEvent(false);
          if (!isBenchmarkComparison) { generatePdf(false); } else {
            setDashboardPrint(false);
            fetchDashboardData();
          }
        }}
        className="print-ignore"
        disabled={isDisabled}
      >
        <DownloadFileIcon id='download-pdf-button-admin-dashboard-benchmark-summary-icon' fillColor={isDisabled ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
      <StyledIconButton
        id='print-pdf-button-admin-dashboard-benchmark-summary'
        color="primary"
        onClick={() => {
          generatePdfCustomEvent(true);
          if (!isBenchmarkComparison) { generatePdf(true); } else {
            setDashboardPrint(true);
            fetchDashboardData();
          }
        }}
        className="print-ignore"
        disabled={isDisabled}
      >
        <PrintIcon id='print-pdf-button-admin-dashboard-benchmark-summary' fillColor={isDisabled ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
    </Stack >

  );
};

export default ExportToPdf;
