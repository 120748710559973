import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 12,
  backgroundColor: theme.palette.customWhite.main,
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: 520,
}));

export default StyledBoxWrapper;
