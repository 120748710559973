import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { CUSTOM_TOOLTIP_HEIGHT, CUSTOM_TOOLTIP_WIDTH } from '../../../variables/constant';
import { StyledTypography } from './tooltip-styled';
import { palette  } from '../../../theme/palette';
import { SxProps } from '@mui/system';

interface Props {
  data: any
  tooltipType?: string
}

const styles = {
  box: {
    padding: 2,
    borderRadius: 1.5,
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customBackground.disabled}`,
    minHeight: CUSTOM_TOOLTIP_HEIGHT,
    width: CUSTOM_TOOLTIP_WIDTH,
    display:'flex',
    flexDirection:'column',
  },
  title: {
    '&.MuiTypography-root': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 800,
      marginBottom: 1,
      display: 'flex',
    },
  },
  description: {
    '&.MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: 1,
    },
  },
};

const StudentsToolTip = ({ data }: Props) => {

  return (
    <>
      <Box sx={{ ...styles.box as SxProps }}>
        <Typography sx={{ ...styles.title }} variant='rubricOrder'>
          {data?.scoreLabel}
          :
          <StyledTypography color={data?.color} sx={{ ...styles.title }}>
            {data?.skillLabel}
          </StyledTypography>
        </Typography>
        <Typography sx={{ ...styles.description }}>
          {data?.skillDescription}
        </Typography>
      </Box>
    </>
  );
};

export default StudentsToolTip;
