import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useGetBackPackSubmissionQuery } from '../../../generated/graphql';

interface RouteProp {
  id: string
}

const useBackPackViewForStudents = () => {
  const { id } = useParams<RouteProp>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>();

  const { data, loading } = useGetBackPackSubmissionQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: id!,
    },
  });

  const submission = data?.submission;

  const togglePreview = () => setShowPreview(!showPreview);

  return {
    loading,
    submission,
    showPreview,
    isPlaying,
    setIsPlaying,
    togglePreview,
    selectedPlayerId,
    setSelectedPlayerId,
  };
};

export default useBackPackViewForStudents;
