import { useEffect, useState } from 'react';
import useUserInfo from '../../../../utils/useUserInfo';
import { RubricTitle, StudentBenchmarkDashBoard } from '../../../../variables/constant';
import {
  BenchmarkIndividualVsGradeLevelInput,
  BenchmarkType,
  SubmissionType,
  useBenchmarkIndividualVsGradeLevelInfoQuery,
} from '../../../../generated/graphql';
import { openSnackbar } from '../../../../components/Notifier';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { NotifierType } from '../../../../variables/types';
import { useParams } from 'react-router-dom';
import { RouteParamProp } from '../../../AdminBenchmarks/Edit/benchmark-detail-hook';
import { useIndividualStudentBenchmarksDashboardContext } from '../individual-student-dashboard-hook';
import { DashboardWidgetsDataStatusProps } from '../../../AdminAnalytics/admin-analytics-hook';

type IndicatorPerformanceBarChartData = {
  rubricTitle: string;
  rubricScoreAverage: number;
  rubricDistrictGradeScoreAverage: number;
  rubricIcon: string;
  color: string;
};

const useBenchmarkIndicatorPerformanceWidget = ( submissionType: SubmissionType, benchmarkType: BenchmarkType ) => {
  const { id } = useParams<RouteParamProp>();
  const [stackedBarData, setStackedBarData] = useState<any>();
  const [stackedBarDataColor, setStackedBarDataColor] = useState<any>();
  const [scoreSchemes, setScoreSchemes] = useState<any>(0);
  const [yAxisDomain, setYAxisDomain] = useState<Array<number>>([]);
  const [yAxisTicks, setYAxisTicks] = useState<Array<number>>([]);
  const [individualVsGradeLevelBarChartData, setIndividualVsGradeLevelBarChartData] = useState<Array<IndicatorPerformanceBarChartData>>([]);
  const [hasData, setHasData] = useState<boolean>(false);
  const { updateDashboardWidgetsDataStatus } = useIndividualStudentBenchmarksDashboardContext();

  const [isLoadingUserInfo, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const scoreSchemeType = user?.score_scheme?.title_key;

  const queryParameters : BenchmarkIndividualVsGradeLevelInput = {
    student_user_id: id,
    benchmark_type: benchmarkType,
    submission_type: submissionType,
  };

  const {
    data: individualVsGradeLevelInfoData,
    loading: individualVsGradeLevelInfoDataLoader,
    error: individualVsGradeLevelInfoGqlErr,
  } = useBenchmarkIndividualVsGradeLevelInfoQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: queryParameters,
    },
  });
  
  useEffect(() => {
    if (individualVsGradeLevelInfoGqlErr) {
      openSnackbar(getErrorMessage(individualVsGradeLevelInfoGqlErr), NotifierType.Error);
    }
    const scores : any = {};
    const colors : any = {};
    let yAxisMaxDomain: number = 0;
    scoreScheme?.forEach((score: any, index: number) => {
      scores[score?.title_key] = scoreScheme?.[index - 1] ?
        score?.range_end - scoreScheme?.[index - 1].range_end :
        score?.range_end;
      colors[score?.title_key] = score?.color_code;
      yAxisMaxDomain = Math.max(yAxisMaxDomain, score?.score_value);
    });
    const barChartData: IndicatorPerformanceBarChartData[] = [];
    if (!individualVsGradeLevelInfoDataLoader) {
      individualVsGradeLevelInfoData?.benchmarkIndividualVsGradeLevelInfo?.rubric_line_items?.forEach((item: any) => {
        barChartData.push({
          rubricTitle: item?.rubric_title === RubricTitle.DescriptionAndExplanation.backend ?
            RubricTitle.DescriptionAndExplanation.frontend : item?.rubric_title,
          rubricScoreAverage: item?.rubric_score_average,
          rubricDistrictGradeScoreAverage: item?.rubric_district_grade_score_average,
          rubricIcon: item?.rubric_icon,
          color: item?.rubric_score_color,
        });
      });
    }
    setIndividualVsGradeLevelBarChartData(barChartData);
    setStackedBarData(scores);
    setStackedBarDataColor(colors);
    setScoreSchemes(scoreScheme);
    setYAxisDomain([1, yAxisMaxDomain]);
    setYAxisTicks([...Array(yAxisMaxDomain).keys()].map((i) => i + 1));
  }, [scoreScheme, individualVsGradeLevelInfoDataLoader, individualVsGradeLevelInfoData, individualVsGradeLevelInfoGqlErr]);

  useEffect(() => {
    if (!individualVsGradeLevelInfoDataLoader && individualVsGradeLevelInfoData) {
      setHasData(individualVsGradeLevelBarChartData?.length > 0 );
      const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
        widget: submissionType === SubmissionType.Speaking ? 
          StudentBenchmarkDashBoard.SpeakingBenchmarkIndicatorPerformaneWidget.name :
          StudentBenchmarkDashBoard.WritingBenchmarkIndicatorPerformaneWidget.name,
        loading: individualVsGradeLevelInfoDataLoader,
        dataMissing: individualVsGradeLevelBarChartData?.length === 0,
      };
      updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    }

  }, [individualVsGradeLevelInfoDataLoader, individualVsGradeLevelBarChartData]);

  return {
    barChartData: individualVsGradeLevelBarChartData.length === 0 ? undefined : individualVsGradeLevelBarChartData,
    individualVsGradeLevelInfoDataLoader,
    stackedBarData,
    stackedBarDataColor,
    isLoadingUserInfo,
    scoreSchemes,
    yAxisDomain,
    yAxisTicks,
    scoreSchemeType,
    hasData,
  };
};
export default useBenchmarkIndicatorPerformanceWidget;