// The video tile content is currently static
// but business might choose to have it dynamic in future
import { useMemo, useState } from 'react';
import { SHARED_BUCKET_URL } from '../../../variables/constant';
import { ScoreSchemeTitleKeys } from '../../../variables/types';
import { useAuthProvider } from '../../../core/authContext';

const bucketVideosBaseURL = `${SHARED_BUCKET_URL}/videos/teacher-toolbox-videos`;

interface ToolboxVideoData {
  title: string
  description: string
  videoURL: string
  thumbnailURL: string
}

const useVideos = () => {
  const [selectedVideo, setSelectedVideo] = useState<ToolboxVideoData | undefined>();
  const { getUser } = useAuthProvider();
  const user = getUser();

  const VIDEOS = [
    {
      title: 'Primary Student Tutorial',
      description: 'Student-facing introductory video for students in lower elementary to become familiar with Flashlight360.',
      videoURL: `${bucketVideosBaseURL}/primary-student-tutorial.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-primary-student-tutorial-video.jpeg',
    },
    {
      title: 'Secondary Student Tutorial',
      description: 'Student-facing introductory video for students in elementary, middle and high school to become familiar with Flashlight360.',
      videoURL: `${bucketVideosBaseURL}/secondary-student-tutorial.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-secondary-student-tutorial.jpeg',
    },
    {
      title: 'Student Tutorial En Español',
      description: 'Student-facing introductory video in Spanish for students in lower elementary to become familiar with Flashlight360.',
      videoURL: `${bucketVideosBaseURL}/student-tutorial-en-espanol.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-student-tutorial-en-español.jpeg',
    },
    {
      title: user?.score_scheme.title_key === ScoreSchemeTitleKeys?.DefaultScheme ? 'Overview of Flashlight360' : 'Comprehensive Overview',
      description: 'Provides a comprehensive overview of Flashlight360’s formative assessment approach.',
      videoURL: user?.score_scheme.title_key === ScoreSchemeTitleKeys?.DefaultScheme ? `${bucketVideosBaseURL}/overview-of-flashlight360-default.mp4` : `${bucketVideosBaseURL}/overview-of-flashlight360.mp4`,
      thumbnailURL: user?.score_scheme.title_key === ScoreSchemeTitleKeys?.DefaultScheme ? '/images/teacher-toolbox-videos-thumbnails/thumbnail-overview-of-flashlight360-default.jpeg' : '/images/teacher-toolbox-videos-thumbnails/thumbnail-overview-of-flashlight360.jpeg',
    },
    {
      title: 'EJE Academies Story',
      description: 'Documents the journey and partnership with EJE Academies in the research, development, and implementation of Flashlight360 for continuous evidence-based improvement.',
      videoURL: `${bucketVideosBaseURL}/eje-academies-story.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-eje-academies-story.jpeg',
    },
    {
      title: 'Introduction to Rubrics, Scoring and Anchors',
      description: 'An introduction to the speaking and writing indicators, the 5-scale rubric, and scoring.',
      videoURL: user?.score_scheme.title_key === ScoreSchemeTitleKeys?.DefaultScheme ? `${bucketVideosBaseURL}/scoring-and-calibration-default.mp4` : `${bucketVideosBaseURL}/scoring-and-calibration.mp4`,
      thumbnailURL: user?.score_scheme.title_key === ScoreSchemeTitleKeys?.DefaultScheme ? '/images/teacher-toolbox-videos-thumbnails/thumbnail-scoring-and-calibration-default.jpeg' : '/images/teacher-toolbox-videos-thumbnails/thumbnail-scoring-and-calibration.jpeg',
    },
    {
      title: 'Level 1 Scoring Demo',
      description: 'A demo on how to score Level 1 student responses.',
      videoURL: `${bucketVideosBaseURL}/level-1-scoring-demo.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-level-1-scoring-demo.jpeg',
    },
    {
      title: 'Level 2 Scoring Demo',
      description: 'A demo on how to score Level 2 student responses.',
      videoURL: `${bucketVideosBaseURL}/level-2-scoring-demo.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-level-2-scoring-demo.jpeg',
    },
    {
      title: 'Level 3 Scoring Demo',
      description: 'A demo on how to score Level 3 student responses.',
      videoURL: `${bucketVideosBaseURL}/level-3-scoring-demo.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-level-3-scoring-demo.jpeg',
    },
    {
      title: 'Level 4 Scoring Demo',
      description: 'A demo on how to score Level 4 student responses.',
      videoURL: `${bucketVideosBaseURL}/level-4-scoring-demo.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-level-4-scoring-demo.jpeg',
    },
    {
      title: 'Level 5 Scoring Demo',
      description: 'A demo on how to score Level 5 student responses.',
      videoURL: `${bucketVideosBaseURL}/level-5-scoring-demo.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-level-5-scoring-demo.jpeg',
    },
    {
      title: 'Success Story: West Lake Junior High',
      description: 'Educators from West Lake Junior High celebrate students\' growth on WIDA Assessments from successful progress monitoring practices with Flashlight360.',
      videoURL: `${bucketVideosBaseURL}/flashlight_learning_west_lake_story.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-west-lake.jpeg',
    },
    {
      title: 'Success Story: Temple ISD',
      description: 'Educators and students from Temple ISD share their implementation highlights and wins with Flashlight360.',
      videoURL: `${bucketVideosBaseURL}/flashlight_learning_thornton_elementary_temple_isd.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-temple.jpeg',
    },
    {
      title: 'Success Story: Roswell, NM',
      description: 'Educators from Roswell, NM describe how the actionable data from Flashlight360 allows teachers to target students\' strengths and weaknesses in speaking and writing development.',
      videoURL: `${bucketVideosBaseURL}/roswell_isd_story.mp4`,
      thumbnailURL: '/images/teacher-toolbox-videos-thumbnails/thumbnail-roswell.jpeg',
    },
  ];
  const videos = useMemo(() => VIDEOS, [VIDEOS]);
  const openVideoModal = (video: ToolboxVideoData) => () => setSelectedVideo(video);
  const closeVideoModal = () => setSelectedVideo(undefined);

  return { videos, selectedVideo, openVideoModal, closeVideoModal };
};

export default useVideos;
