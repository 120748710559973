/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ProgressIcon = (props: any) => (
  <SvgIcon
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M0.5 11H2.5C2.77625 11 3 11.2238 3 11.5V15.5C3 15.7762 2.77625 16 2.5 16H0.5C0.22375 16 0 15.7762 0 15.5V11.5C0 11.2238 0.22375 11 0.5 11Z" />
      <path d="M15.5 6H17.5C17.7762 6 18 6.22375 18 6.5V15.5C18 15.7762 17.7762 16 17.5 16H15.5C15.2238 16 15 15.7762 15 15.5V6.5C15 6.22375 15.2238 6 15.5 6Z" />
      <path d="M16.5 3C16.2097 3 15.9412 2.91375 15.7116 2.77125L12.9116 5.01094C12.965 5.165 13 5.32781 13 5.5C13 6.32844 12.3284 7 11.5 7C10.6716 7 10 6.32844 10 5.5C10 5.32781 10.035 5.165 10.0884 5.01094L7.28844 2.77094C7.05875 2.91375 6.79031 3 6.5 3C6.26875 3 6.0525 2.94344 5.85687 2.85L2.85 5.85687C2.94344 6.0525 3 6.26875 3 6.5C3 7.32844 2.32844 8 1.5 8C0.671562 8 0 7.32844 0 6.5C0 5.67156 0.671562 5 1.5 5C1.73125 5 1.9475 5.05656 2.14313 5.15L5.15 2.14313C5.05656 1.9475 5 1.73125 5 1.5C5 0.671562 5.67156 0 6.5 0C7.32844 0 8 0.671562 8 1.5C8 1.67219 7.965 1.835 7.91156 1.98875L10.7116 4.22875C10.9412 4.08594 11.2097 3.99969 11.5 3.99969C11.7903 3.99969 12.0588 4.08594 12.2884 4.22875L15.0884 1.98875C15.035 1.83469 15 1.67219 15 1.5C15 0.671562 15.6716 0 16.5 0C17.3284 0 18 0.671562 18 1.5C18 2.32844 17.3284 3 16.5 3Z" />
      <path d="M5.5 6H7.5C7.77625 6 8 6.22375 8 6.5V15.5C8 15.7762 7.77625 16 7.5 16H5.5C5.22375 16 5 15.7762 5 15.5V6.5C5 6.22375 5.22375 6 5.5 6Z" />
      <path d="M10.5 10H12.5C12.7763 10 13 10.2237 13 10.5V15.5C13 15.7762 12.7763 16 12.5 16H10.5C10.2237 16 10 15.7762 10 15.5V10.5C10 10.2237 10.2237 10 10.5 10Z" />
    </g>
  </SvgIcon>
);

export default React.memo(ProgressIcon);
