import { useEffect, useState } from 'react';
import { SchoolValue } from '../../../components/SchoolFilterTeacher';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import useDownloadCsv from '../../../utils/download-csv-benchmarks';
import useTeacherSchoolsFilter from '../../../components/SchoolFilterTeacher/teacherschools-filter-hook';

type Props
  = {
    handleClose: Function,
    handleIsCsvDownloaded: Function,
    openModal: boolean,
  };
const useTeacherBenchmarksDownloadData = ({ handleClose, handleIsCsvDownloaded, openModal }: Props) => {
  const benchmarkOptions = [{ label: 'BOY', value: 'BOY' }, { label: 'MOY', value: 'MOY' }, { label: 'EOY', value: 'EOY' }, { label: 'ALL', value: 'ALL' }];
  const [selectedSchools, setSelectedSchools] = useState<string[] | undefined>(undefined);
  const [selectedBenchmarkTypes, setSelectedBenchmarkTypes] = useState<string[] | undefined>([]);
  const [selectedSchoolErrorMessage, setSelectedSchoolErrorMessage] = useState<string>('');
  const [selectedBenchmarkTypeErrorMessage, setSelectedBenchmarkTypeErrorMessage] = useState<string>('');
  const [downloadAttemptCount, setDownloadAttemptCount] = useState<number>(0);
  const [isDownloadFailed, setIsDownloadFailed] = useState<boolean>(false);
  const [loadingBenchmarks, setLoadingBenchmarks] = useState<boolean>(false);
  const onSchoolChange = (schoolData: SchoolValue[] | string[]) => {
    setSelectedSchools(schoolData as string[]);
  };
  const {
    schools,
    loading,
  } = useTeacherSchoolsFilter();

  useEffect(() => {
    if (schools?.length === 1) {
      onSchoolChange(schools?.map((school) => school?.id) as string[]);
    }
  }, [schools, openModal]);

  const { downloadCsv } = useDownloadCsv();
  const handleCancel = () => {
    handleClose();
    setSelectedBenchmarkTypes?.([]);
    if (schools?.length !== 1) { 
      setSelectedSchools?.([]); 
    }
    setSelectedSchoolErrorMessage('');
    setSelectedBenchmarkTypeErrorMessage('');
    setDownloadAttemptCount(0);
    setIsDownloadFailed(false);
  };

  const handleSelectedBenchmarkTypes = (benchmarkTypes: string[] | undefined) => {
    if (benchmarkTypes &&
      benchmarkTypes.length === 3 &&
      !benchmarkTypes.includes('ALL') && selectedBenchmarkTypes?.includes('ALL')) {
      setSelectedBenchmarkTypes?.([]);
    } else if (benchmarkTypes &&
      benchmarkTypes.length === 3 &&
      benchmarkTypes.includes('ALL') && selectedBenchmarkTypes?.includes('ALL')) {
      setSelectedBenchmarkTypes?.(benchmarkTypes?.filter((type) => type !== 'ALL'));
    } else if (
      benchmarkTypes &&
      benchmarkTypes.length === 3 &&
      benchmarkTypes.includes('BOY') &&
      benchmarkTypes.includes('MOY') &&
      benchmarkTypes.includes('EOY')
    ) {
      // All individual options are selected, so select 'ALL'
      setSelectedBenchmarkTypes?.(['BOY', 'MOY', 'EOY', 'ALL']);
    } else if ((benchmarkTypes?.includes('ALL') && benchmarkTypes?.length < 4)) {
      setSelectedBenchmarkTypes?.(['BOY', 'MOY', 'EOY', 'ALL']);
      // If any individual option is deselected, deselect 'ALL'
    } else { setSelectedBenchmarkTypes?.(benchmarkTypes); }
    setSelectedBenchmarkTypeErrorMessage('');
  };

  const downloadBenchmarks = async () => {
    if (!selectedSchools || selectedSchools.length === 0 || !selectedBenchmarkTypes || selectedBenchmarkTypes.length === 0) {
      if (!selectedSchools || selectedSchools.length === 0) {
        setSelectedSchoolErrorMessage('Please select at least 1 School');
      }
      if (!selectedBenchmarkTypes || selectedBenchmarkTypes.length === 0) { setSelectedBenchmarkTypeErrorMessage('Please select at least 1 Benchmark Type'); }
      return;
    }
    setLoadingBenchmarks(true);

    const csvresponse = await downloadCsv(selectedSchools, selectedBenchmarkTypes);

    if (csvresponse?.response?.status === 404) {
      openSnackbar({ message: 'Data Not Found' }, NotifierType.Error);
      setLoadingBenchmarks(false);

    } else if (csvresponse?.status === 200) {
      openSnackbar({ message: 'Download Started Successfully' }, NotifierType.Success);
      setTimeout(handleIsCsvDownloaded, 3000);
      const link = document.createElement('a');
      link.href = csvresponse?.data?.pre_signed_url;
      link.setAttribute('download', 'benchmarks.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); 
      handleCancel();
    } else {
      setDownloadAttemptCount(downloadAttemptCount + 1);
      setIsDownloadFailed(true);  
    }
    setLoadingBenchmarks(false);
  };

  return {
    selectedSchools,
    onSchoolChange,
    selectedBenchmarkTypes,
    handleSelectedBenchmarkTypes,
    benchmarkOptions,
    selectedBenchmarkTypeErrorMessage,
    selectedSchoolErrorMessage,
    downloadBenchmarks,
    setSelectedBenchmarkTypeErrorMessage,
    setSelectedSchoolErrorMessage,
    handleCancel,
    isDownloadFailed,
    downloadAttemptCount,
    loadingBenchmarks,
    schools,
  };
};
export default useTeacherBenchmarksDownloadData;