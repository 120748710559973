import React from 'react';
import { Box } from '@mui/material';
import useStudentsProgress from './student-progress-hook';
import CurrentGoals from '../../Students/Student/CurrentGoals';
import PreviousGoals from '../../Students/Student/PreviousGoals';
import Submissions from '../../Students/Student/Submissions';
import Layout from '../../../components/Layout';
import StudentFixedHeader from '../StudentFIxedHeader';

const StudentProgress = () => {
  const {
    studentTasks,
    loading,
    goalsDetail,
    previousGoals,
    scoreScheme,
    sort,
    handleSort,
    order,
  } = useStudentsProgress();

  return (
    <Layout>
      <StudentFixedHeader
        pageTitle="My Progress"
        containIcon={false}
      />
      <Box
        width="100%"
        marginTop="24px"
      >
        <Submissions studentAssignments={studentTasks?.assignments} benchmarks={studentTasks?.benchmarks} scoreScheme={scoreScheme} loading={loading} title="Submissions" isStudent sort={sort} handleSort={handleSort} order={order} />
        <CurrentGoals activeGoal={goalsDetail?.getStudentGoals} loading={loading} title="Current Goals" />
        <PreviousGoals previousGaols={previousGoals?.getStudentGoals } loading={loading} title="Previous Goals" />
      </Box>
    </Layout>
  );
};

export default React.memo(StudentProgress);
