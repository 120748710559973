/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CrossIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    {...props}
  >
    <g id="icon / arrow up icon">
      <path d="M5.18973 4.5L7.88269 1.80704C8.03844 1.65129 8.03844 1.39855 7.88269 1.24255L7.25695 0.616811C7.1012 0.461063 6.84845 0.461063 6.69246 0.616811L3.99975 3.31002L1.30679 0.617061C1.15104 0.461313 0.898299 0.461313 0.742301 0.617061L0.116811 1.24255C-0.0389369 1.3983 -0.0389369 1.65104 0.116811 1.80704L2.80977 4.5L0.116811 7.19296C-0.0389369 7.34871 -0.0389369 7.60145 0.116811 7.75745L0.742551 8.38319C0.898299 8.53894 1.15104 8.53894 1.30704 8.38319L3.99975 5.68998L6.69271 8.38294C6.84846 8.53869 7.1012 8.53869 7.2572 8.38294L7.88294 7.7572C8.03869 7.60145 8.03869 7.34871 7.88294 7.19271L5.18973 4.5Z" fill={props.pathFill} />
    </g>
  </SvgIcon>
);

export default CrossIcon;
