import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledContainerRoot } from '../../../Assignments/AssignmentsTable/List.styled';
import { PaginationProps, SuperAdminSort } from '../../../../variables/types';
import CustomPagination from '../../../../components/CustomPagination';
import { ROWS_PER_PAGE } from '../../../../variables/constant';
import NoResults from '../../../../components/NoResults';
import WavingLadyFrame from '../../../../components/Icons/WavingLadyFrame';
import { OrderBy } from '../../../../generated/graphql';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';
import { StyledTableCell, StyledTableDataCell, StyledTableDataColumn, StyledTableCell2 } from '../../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../../components/Common/Common.styled';
import { toReadableFormat } from '../../../../utils/dateFormat';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: SuperAdminSort,
  order: OrderBy,
  superAdmins: any[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: SuperAdminSort) => void) | Function,
  rowsPerPage: number,
  setRowsPerPage: Function,
}


const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  superAdmins,
  handleSort,
  rowsPerPage,
  setRowsPerPage,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const sortColumn = (title: string, sortEnum: SuperAdminSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      {title}
    </StyledTableSort>
  );
  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box>
        <StyledContainerRoot>
          <Table
            stickyHeader
            aria-label="districts table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell2 sx={{ textAlign: 'left', width: '12%' }}>
                  {sortColumn('Super Admin', SuperAdminSort.FirstName)}
                </StyledTableCell2>
                <StyledTableCell>
                  {sortColumn('Title', SuperAdminSort.JobTitle)}
                </StyledTableCell>
                <StyledTableDataCell>
                  {sortColumn('Email', SuperAdminSort.Email)}
                </StyledTableDataCell>
                <StyledTableDataCell>
                  {sortColumn('Last Updated', SuperAdminSort.UpdatedAt)}
                </StyledTableDataCell>
                <StyledTableDataCell>
                  {sortColumn('Updated By', SuperAdminSort.UpdatedByFirstName)}
                </StyledTableDataCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && superAdmins?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ textAlign: 'left', width: '12%' }}>
                    <Typography
                      component={Link}
                      variant='redirectionLinks'
                      to={`/settings/super-admin/${row.id}`}
                    >
                      {row.first_name} {row?.middle_name} {row.last_name}
                    </Typography>
                  </TableCell>
                  <StyledTableDataColumn>
                    {row?.job_title}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.email}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {/* variable names may need to be updated after backend change if required */}
                    {toReadableFormat(row?.updated_at, { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('/', '-')}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {/* variable names may need to be updated after backend change if required */}
                    {row?.updated_by_first_name} {row?.updated_by_last_name}
                  </StyledTableDataColumn>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="No Super Admins"
                      description="There has yet to be a super admin created."
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      <CustomPagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onChange={handlePageChange}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};
export default List;
