import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { stringify } from 'query-string';
import { Entity } from '../../variables/types';
import { adminLevel } from '../../pages/Admin/constants';

interface Action {
  label: string;
  value: Entity | string;
  school?: boolean;
  district?: boolean;
  teacher?: boolean;
  student?: boolean;
  class?: boolean;
  primary?: Entity[];
}

interface ReduceReturn {
  actionItems: Action[];
}

export interface Props {
  entity: Entity;
  districtId?: string;
  schoolId?: string;
  stateId?: string;
  teacherId?: string;
  disabled?: boolean;
}

const useAddNewMenu = ({ entity, districtId, schoolId, stateId, teacherId }: Props) => {
  const history = useHistory();
  const { actionItems } = useMemo(() => {
    const actions = [{
      label: 'School',
      value: Entity.School,
      district: true,
      [Entity.DistrictAdmin]: true,
    },
    {
      label: 'Class',
      value: Entity.Class,
      school: true,
      district: true,
      teacher: true,
      student: true,
      [Entity.CleverDistrict]: true,
      [Entity.DistrictAdmin]: true,
      [Entity.SchoolAdmin]: true,
      [Entity.CleverDistrictNoSchool]: true,
      primary: [Entity.Student],
    },
    {
      label: 'Teacher',
      value: Entity.Teacher,
      district: true,
      school: true,
      [Entity.CleverDistrict]: true,
      [Entity.DistrictAdmin]: true,
      [Entity.SchoolAdmin]: true,
      [Entity.CleverDistrictNoSchool]: true,
      student: true,
    },
    {
      label: 'Student',
      value: Entity.Student,
      district: true,
      school: true,
      class: true,
      teacher: true,
      [Entity.CleverDistrict]: true,
      [Entity.DistrictAdmin]: true,
      [Entity.SchoolAdmin]: true,
      [Entity.CleverDistrictNoSchool]: true,
    },
    {
      label: 'School Admin',
      value: Entity.SchoolAdmin,
      district: true,
      school: true,
      [Entity.DistrictAdmin]: true,
      [Entity.CleverDistrict]: true,
      [Entity.CleverDistrictNoSchool]: true,
    },
    {
      label: 'District Admin',
      value: Entity.DistrictAdmin,
      district: true,
      [Entity.CleverDistrictNoSchool]: true,
    }];

    return actions.reduce((acc: ReduceReturn, action: Action) => {
      const { label, value } = action;
      const result = {
        ...acc,
      };
      if (action[entity as keyof Action]) {
        return {
          ...result,
          actionItems: [...acc.actionItems, {
            label, value,
          }],
        };
      }
      return result;
    }, {
      actionItems: [],
    });
  }, [entity]);

  const onClick = ({ value }: Action) => {
    let pathname = '';
    const queryParams: Record<string, string | undefined> = {
      ...(districtId ? { districtId } : {}),
      ...(schoolId ? { schoolId } : {}),
      ...(stateId ? { stateId } : {}),
      ...(teacherId ? { teacherId } : {}),
    };
    switch (value) {
      case Entity.SchoolAdmin: {
        pathname = '/admin/create/admin';
        queryParams.level = adminLevel.SCHOOL;
        break;
      }
      case Entity.DistrictAdmin: {
        pathname = '/admin/create/admin';
        queryParams.level = adminLevel.DISTRICT;
        break;
      }
      default:
        pathname = `/admin/create/${value}`;
    }
    history.push({
      pathname,
      search: `?${stringify(queryParams)}`,
    });
  };

  return {
    actionItems,
    onClick,
  };
};

export default useAddNewMenu;