/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GoalsIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g>
      <path d="M14.7605 4.39065L15.5623 6.79548L11.1789 11.1789C10.725 11.6327 10.725 12.3673 11.1789 12.8211C11.6323 13.2745 12.3673 13.275 12.8211 12.8211L17.2045 8.43774L19.6094 9.23952C19.9669 9.35855 20.3608 9.26565 20.6274 8.99952L23.7082 5.91871C24.2308 5.39613 24.0208 4.50581 23.3192 4.27161L20.626 3.37403L19.7284 0.68129C19.4947 -0.0203224 18.6044 -0.230322 18.0813 0.292258L15.001 3.37258C14.8704 3.50327 14.7788 3.66771 14.7363 3.8475C14.6938 4.02728 14.7022 4.21534 14.7605 4.39065ZM12 6.96774C12.3706 6.96774 12.7297 7.01419 13.0776 7.09064L13.7908 6.37742L13.2915 4.87984C13.2798 4.84452 13.2818 4.80871 13.2716 4.7729C12.8569 4.70032 12.4355 4.64468 11.9995 4.64468C7.93403 4.64468 4.64468 7.935 4.64468 11.9995C4.64468 16.065 7.935 19.3544 11.9995 19.3544C16.065 19.3544 19.3544 16.064 19.3544 11.9995C19.3544 11.5626 19.2987 11.1406 19.2261 10.725C19.1913 10.7148 19.154 10.7192 19.1197 10.7076L17.6226 10.2087L16.9094 10.9219C16.9858 11.2698 17.0318 11.6289 17.0318 11.9995C17.0318 14.7745 14.7745 17.0318 11.9995 17.0318C9.22452 17.0318 6.96726 14.7745 6.96726 11.9995C6.96726 9.22452 9.225 6.96774 12 6.96774ZM23.4402 8.37581L21.7224 10.0935C21.6576 10.1584 21.5835 10.2087 21.5134 10.2653C21.616 10.829 21.6774 11.4068 21.6774 11.9995C21.6774 17.3356 17.3361 21.6769 12 21.6769C6.66387 21.6769 2.32258 17.3356 2.32258 11.9995C2.32258 6.66339 6.66387 2.3221 12 2.3221C12.5971 2.3221 13.1792 2.38403 13.7468 2.48806C13.8029 2.41935 13.8426 2.34097 13.9065 2.2771L15.6242 0.559355C14.4519 0.188443 13.2296 -0.000208213 12 1.72449e-07C5.37242 1.72449e-07 0 5.37242 0 12C0 18.6276 5.37242 24 12 24C18.6276 24 24 18.6276 24 12C24 10.7366 23.8021 9.51968 23.4402 8.37581Z" fill={props.pathFill} />
    </g>
  </SvgIcon>
);

export default GoalsIcon;
