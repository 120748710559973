/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const CheckIcon = (props: any) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 24.1004C15.5765 24.1004 18.527 22.8783 20.7024 20.7028C22.8779 18.5274 24.1 15.5769 24.1 12.5004C24.1 9.42388 22.8779 6.47337 20.7024 4.29795C18.527 2.12253 15.5765 0.900391 12.5 0.900391C9.42348 0.900391 6.47298 2.12253 4.29756 4.29795C2.12213 6.47337 0.899994 9.42388 0.899994 12.5004C0.899994 15.5769 2.12213 18.5274 4.29756 20.7028C6.47298 22.8783 9.42348 24.1004 12.5 24.1004ZM17.8751 10.6255C18.1393 10.3521 18.2854 9.98579 18.2821 9.60561C18.2788 9.22542 18.1263 8.86175 17.8575 8.5929C17.5886 8.32406 17.225 8.17157 16.8448 8.16826C16.4646 8.16496 16.0983 8.31111 15.8248 8.57524L11.05 13.3501L9.17514 11.4752C8.90167 11.2111 8.5354 11.065 8.15521 11.0683C7.77503 11.0716 7.41135 11.2241 7.14251 11.4929C6.87366 11.7617 6.72117 12.1254 6.71787 12.5056C6.71456 12.8858 6.86071 13.2521 7.12484 13.5255L10.0248 16.4255C10.2968 16.6974 10.6655 16.8501 11.05 16.8501C11.4345 16.8501 11.8032 16.6974 12.0751 16.4255L17.8751 10.6255Z"
      fill={palette.customBlue.primaryBlue}
      {...props}
    />
  </SvgIcon>
);

export default CheckIcon;
