import React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { svgStyles } from '../Common/Common.styled';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Checkbox, CheckboxProps, InputAdornment, SxProps, Theme, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import ClassesIcon from '../Icons/ClassesIcon';
import useGroupedClasses from './class-grouped-hook';
import { SectionCategory } from '../../generated/graphql';

export interface SectionDetails {
  id: string;
  name: string;
  school_id: string;
  school: string;
}

interface Props {
  error?: boolean;
  className?: string;
  value?: SectionDetails[] | undefined;
  onChange?: (data: SectionDetails[] | undefined) => void;
  stateId?: string | undefined;
  districtId?: string | undefined;
  schoolIds?: string[] | undefined | null;
  disabled?: boolean;
  placeholder?: string | undefined;
  isPrimary? : boolean | undefined;
  classTypes?: SectionCategory[];
  sx?: SxProps<Theme> | undefined;
}

const styles = {
  border: '0px',
  minHeight: '48px',
  height: 'auto',
  '& input.MuiInputBase-input.Mui-disabled' : {
    backgroundColor: palette.customWhite.inputDisabled,
  },
};

const GroupedClassesPrimary = ({
  className,
  value,
  disabled,
  placeholder = 'Classes',
  isPrimary = false,
  schoolIds,
  districtId,
  stateId,
  classTypes,
  sx,
}: Props) => {
  const { classes, loading } = useGroupedClasses({ schoolIds, districtId, stateId,  classTypes });
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const checkGroup = (group: string) => {
    const groupLength = classes.filter((classItem) => classItem.school_id === group).length;
    const selectedGroupLength = value?.filter((classItem) => classItem.school_id === group).length;
    return groupLength === selectedGroupLength;
  };

  return (
    <Autocomplete
      multiple
      className={className}
      limitTags={3}
      options={classes}
      disableCloseOnSelect
      getOptionLabel={(option: any) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={loading || disabled}
      value={value ?? []}
      getOptionDisabled={() => isPrimary}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return (
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }}
                component='span'>{option?.name ?? ''}</Typography>}
              {...getTagProps({ index })}
              disabled={isPrimary}
              key={index}
            />
          );
        })
      }
      renderOption={(props, option: any, { selected }) => (
        <Box component="li" {...props}>
          <Checkbox
            key={option.id}
            color={'primary' as  CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            disabled={isPrimary}
            sx={{ mr: '8px' }}
          />
          <Typography sx={{ fontWeight: 800 }}>{`${option?.name ?? ''}`}</Typography>
        </Box>
      )}
      groupBy={(option) => option.school_id}
      renderGroup={(params) => (
        <>
          <Checkbox
            key={params.group}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={checkGroup(params.group)}
            disabled={isPrimary}
          />
          <Typography component='span' sx={{ fontWeight: 800 }}>{classes.find((section) => section.school_id === params.group)?.school_name || ''}</Typography>
          {params.children}
        </>
      )}
      renderInput={(params) => (
        <>
          <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>
          <TextField
            {...params}
            sx={{ ...styles }}
            placeholder={params.InputProps.startAdornment ? undefined : 'None'}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                height: 'auto',
                maxHeight: '500px',
                fontSize: '16px',
                overflowY: 'scroll',
                ...(sx ? {  backgroundColor: palette.customWhite.whiteSmoke } : { backgroundColor: 'inherit' })
              },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <ClassesIcon
                      pathfill={palette.customBlue.primaryBlue}
                      sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default GroupedClassesPrimary;
