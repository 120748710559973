import React from 'react';
import { Box } from '@mui/material';
import Performance from './Performance';
import useOverview from './overview-hook';
import useRedirect from '../../../hooks/useRedirect';
import ActiveGoal from './ActiveGoal';
import Flyover from '../../../components/Flyover';
import { GoalSetGraphData, GraphSetData, AnalyticsTabs } from '../../../variables/types';

const Overview = () => {
  const { navigateTo: navigateToPath } = useRedirect();
  const {
    currentSkill,
    setCurrentSkill,
    speakingData,
    speakingCount,
    writingCount,
    writingData,
    scoreLoading,
    activeSpeakingGoalCount,
    activeSpeakingGoalData,
    activeWritingGoalCount,
    activeWritingGoalData,
    goalLoading,
    speakingType,
    writingType,
    skillFetch,
    setSkillFetch,
  } = useOverview();

  const handleTooltipClick = (data: GraphSetData | null, skillScore: any) => {
    setCurrentSkill(data);
    setSkillFetch(skillScore);
  };

  const navigateTo = (route: string) => () => {
    navigateToPath(route);
  };

  const navigateToTab = (route: string) => (data: GoalSetGraphData | null) => {
    navigateToPath(`${route}/${data?.linkText ?? ''}`);
  };

  return (
    <Box
      display="grid"
      sx={{
        gridGap: 24,
        width: '100%',
      }}
      my={4}
      gridTemplateColumns="repeat(2, 1fr)"
    >
      <Performance
        loading={scoreLoading}
        totalCount={speakingCount}
        data={speakingData}
        skillTypes={speakingType}
        title="Overall Speaking Performance"
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Speaking}`)}
        handleTooltipClick={handleTooltipClick}
      />
      <ActiveGoal
        loading={goalLoading}
        totalCount={activeSpeakingGoalCount}
        data={activeSpeakingGoalData}
        title="Active Speaking Goals"
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.SpeakingGoals}`)}
        handleTooltipClick={navigateToTab(`/analytics/${AnalyticsTabs.SpeakingGoals}`)}
      />
      <Performance
        loading={scoreLoading}
        totalCount={writingCount}
        data={writingData}
        skillTypes={writingType}
        title="Overall Writing Performance"
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Writing}`)}
        handleTooltipClick={handleTooltipClick}
      />
      <ActiveGoal
        loading={goalLoading}
        totalCount={activeWritingGoalCount}
        data={activeWritingGoalData}
        title="Active Writing Goals"
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.WritingGoals}`)}
        handleTooltipClick={navigateToTab(`/analytics/${AnalyticsTabs.WritingGoals}`)}
      />
      {currentSkill && (
        <Flyover
          open={!!currentSkill}
          skill={currentSkill}
          skillLevel={skillFetch}
          onClose={() => handleTooltipClick(null, null)}
        />
      )}
    </Box>
  );
};

export default React.memo(Overview);
