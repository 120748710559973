/* eslint-disable indent */ /* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import { Box, Button, Tabs, Tab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Switch,
  useHistory,
  useParams,
  Route,
} from 'react-router-dom';
import Layout from '../../../components/Layout';
import { AssignmentPageParams, AssignmentTabs } from '../../../variables/types';
import List from './List';

const getIndexBySection = (section?: string) => {
  if (!section || section === AssignmentTabs.Past) {
    return 0;
  }
  if (section === AssignmentTabs.Current) {
    return 1;
  }
  return 2;
};

const AssignmentsTable = () => {
  const theme = useTheme();
  const { section } = useParams<AssignmentPageParams>();
  const [tab, setTab] = useState(getIndexBySection(section));
  const history = useHistory();
  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    if (newValue === 0) {
      return history.push(`/assignments/${AssignmentTabs.Past}`);
    }
    if (newValue === 1) {
      return history.push(`/assignments/${AssignmentTabs.Current}`);
    }
    if (newValue === 2) {
      return history.push(`/assignments/${AssignmentTabs.Upcoming}`);
    }
    return null;
  };

  useEffect(() => {
    const index = getIndexBySection(section);
    setTab(index);
  }, [section]);

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 200px"
          gap='32px'
          alignItems="center"
        >
          <Box>
            <Typography variant="pageTitle">Assignments</Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { history.push('/assignments/create'); }}
          >
            Create
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
          width="100%"
          mt={theme.spacing(3)}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            textColor="primary"
            scrollButtons={false}
          >
            <Tab
              label="Past"
              disableRipple
            />
            <Tab
              label="Current"
              disableRipple
            />
            <Tab
              label="Upcoming"
              disableRipple
            />
          </Tabs>
        </Box>
        <Box display="flex" mt={2}>
          <Switch>
            <Route
              path={[
                `/assignments/${AssignmentTabs.Past}`,
                `/assignments/${AssignmentTabs.Current}`,
                `/assignments/${AssignmentTabs.Upcoming}`,
              ]}
              render={(props: any) => (
                <List section={section} {...props} />
              )}
              exact
            />
          </Switch>
        </Box>
      </Box>
    </Layout>
  );
};

export default React.memo(AssignmentsTable);
