import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  SectionFilterFragmentFragment,
  SubmissionStatus,
  TeacherOfStudentFilterFragmentFragment,
  useGetAllAssignmentsForStudentQuery,
  useGetTeachersAssociatedWithSectionsQuery,
} from '../../../generated/graphql';

const useBackPackForStudents = () => {
  const [selectedTeacher, setSelectedTeacher] = useQueryParam('teacher', StringParam);
  const [selectedSection, setSelectedSection] = useQueryParam('section', StringParam);

  const onTeacherChange = (teacher: string | TeacherOfStudentFilterFragmentFragment | null) => {
    setSelectedTeacher(teacher as string);
  };

  const onSectionChange = (section: string | SectionFilterFragmentFragment | null) => {
    setSelectedSection(section as string);
  };

  const handleReset = () => {
    setSelectedSection('');
    setSelectedTeacher('');
  };

  const history = useHistory();
  const { data, loading } = useGetAllAssignmentsForStudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        include_not_started: true,
        status: [
          SubmissionStatus.Started,
          SubmissionStatus.AnnotationCompleted,
          SubmissionStatus.SpeakingCompleted,
          SubmissionStatus.Submitted,
          SubmissionStatus.Reviewed,
        ],
      },
    },
  });
  const assignments = data?.getAllAssignmentsAndBenchmarksForStudent.assignments ?? [];
  const benchmarks = data?.getAllAssignmentsAndBenchmarksForStudent.benchmarks ?? [];
  const handleRedirection = (submissionId: string) => async () => {
    if (submissionId) history.push(`/backpack/${submissionId}/view`);
  };

  const assignmentTeachers = (teachers?: string[]) => {
    return teachers?.join(', ');
  };

  return {
    loading: loading,
    assignments,
    benchmarks,
    handleRedirection,
    selectedTeacher,
    setSelectedTeacher,
    onTeacherChange,
    selectedSection,
    setSelectedSection,
    onSectionChange,
    handleReset,
    assignmentTeachers,
  };
};

export default useBackPackForStudents;
