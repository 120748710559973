/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ClassesIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    {...props}
  >
    <g id="icon / nav open">
      <path
        d="M3.6 7.39922C4.92375 7.39922 6 6.32297 6 4.99922C6 3.67547 4.92375 2.59922 3.6 2.59922C2.27625 2.59922 1.2 3.67547 1.2 4.99922C1.2 6.32297 2.27625 7.39922 3.6 7.39922ZM20.4 7.39922C21.7238 7.39922 22.8 6.32297 22.8 4.99922C22.8 3.67547 21.7238 2.59922 20.4 2.59922C19.0763 2.59922 18 3.67547 18 4.99922C18 6.32297 19.0763 7.39922 20.4 7.39922ZM21.6 8.59922H19.2C18.54 8.59922 17.9438 8.86547 17.5088 9.29672C19.02 10.1255 20.0925 11.6217 20.325 13.3992H22.8C23.4638 13.3992 24 12.863 24 12.1992V10.9992C24 9.67547 22.9238 8.59922 21.6 8.59922ZM12 8.59922C14.3213 8.59922 16.2 6.72047 16.2 4.39922C16.2 2.07797 14.3213 0.199219 12 0.199219C9.67875 0.199219 7.8 2.07797 7.8 4.39922C7.8 6.72047 9.67875 8.59922 12 8.59922ZM14.88 9.79922H14.5688C13.7888 10.1742 12.9225 10.3992 12 10.3992C11.0775 10.3992 10.215 10.1742 9.43125 9.79922H9.12C6.735 9.79922 4.8 11.7342 4.8 14.1192V15.1992C4.8 16.193 5.60625 16.9992 6.6 16.9992H17.4C18.3938 16.9992 19.2 16.193 19.2 15.1992V14.1192C19.2 11.7342 17.265 9.79922 14.88 9.79922ZM6.49125 9.29672C6.05625 8.86547 5.46 8.59922 4.8 8.59922H2.4C1.07625 8.59922 0 9.67547 0 10.9992V12.1992C0 12.863 0.53625 13.3992 1.2 13.3992H3.67125C3.9075 11.6217 4.98 10.1255 6.49125 9.29672Z"
        fill={props?.pathfill}
      />
    </g>
  </SvgIcon>
);

export default ClassesIcon;
