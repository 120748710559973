import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { palette } from '../../theme/palette';
import TextField from '@mui/material/TextField';

export type TeacherStudentValue = {
  id: string;
  name: string;
};
  
interface Props {
  students: TeacherStudentValue[];
  error?: boolean;
  value: TeacherStudentValue | undefined;
  onChange?: (data: TeacherStudentValue ) => void;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: any;
  isStartAdornment?: boolean;
  zIndexPopper?: number;
}

const styles = {
  textField: {
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    '& input.MuiInputBase-input.Mui-disabled': {
      backgroundColor: palette.customWhite.inputDisabled,
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '30px !important',
      zIndex: 2,
    },
  },
  inputText: {
    overflow: 'scroll',
    fontSize: '18px',
    fontWeight: 800,
    background: palette.customWhite.main,
    letterSpacing:  '1px',
    lineHeight: '24px',
  },
};

const SelectTeacherStudentFilter = ({
  placeholder = '',
  onChange,
  value,
  disabled,
  students,
  zIndexPopper = 1300,
}: Props) => {

  return (
    <Autocomplete
      disableClearable={true}
      options={students || []}
      getOptionLabel={(option: any) => option?.name}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      onChange={ (_: any, student: TeacherStudentValue ) => {
        onChange?.(student);
      }}
      autoSelect
      value={value}
      defaultValue={value}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          sx={{ ...styles.textField }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: { ...styles.inputText },
          }}
        />
      )}
    />
  );
};

export default SelectTeacherStudentFilter;
