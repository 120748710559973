import { useEffect, useState } from 'react';
import { AudioFileType } from '../../../../variables/types';
import {
  File as APIFIle,
} from '../../../../generated/graphql';

interface Props {
  id?: string,
  textPrompt: string,
  audioPrompt: Pick<APIFIle, 'id' | 'url' | 'file_type'> | null;
  promptType: string,
  updateContextInput: Function,
  setSpeakingPromptFile: any,
  setWritingPromptFile: any,
}

const usePromptInput = ({
  textPrompt,
  audioPrompt,
  promptType,
  updateContextInput,
  setSpeakingPromptFile,
  setWritingPromptFile,
}: Props) => {
  const [audioData, setAudioData] = useState<Pick<APIFIle, 'url' | 'file_type'> | null>(audioPrompt);
  const [notes, updateNotes] = useState<string>(textPrompt);
  const [currentFile, setCurrentFile] = useState<File | null | undefined>();

  useEffect(() => {
    updateNotes(textPrompt);
  }, [textPrompt]);

  useEffect(() => {
    setAudioData(audioPrompt);
  }, [audioPrompt]);

  const saveAudioFileContext = (file: File | null | undefined) => {
    let data: Pick<APIFIle, 'url' | 'file_type'> = { url: '', file_type: '' };
    if (file) {
      data = {
        url: URL.createObjectURL(file),
        file_type: file.type,
      };
    }
    setAudioData(data);
    setCurrentFile(file);
    if (promptType === 'speaking') {
      updateContextInput({
        speaking_prompt_url: data?.url,
        speaking_prompt_file_type: data?.file_type,
      });
      setSpeakingPromptFile?.(file);
    } else {
      updateContextInput({
        writing_prompt_url: data?.url,
        writing_prompt_file_type: data?.file_type,
      });
      setWritingPromptFile?.(file);
    }
  };

  const onRecord = (file: File) => {
    const data: AudioFileType = { url: '', fileType: '' };
    if (file) {
      data.url = URL.createObjectURL(file);
      data.fileType = file.type;
    }
    saveAudioFileContext(file);
  };

  const onDelete = async () => {
    setAudioData(null);
    setCurrentFile(null);
    saveAudioFileContext(null);
  };

  return {
    audioData,
    setAudioData,
    currentFile,
    setCurrentFile,
    onRecord,
    notes,
    updateNotes,
    onDelete,
  };
};

export default usePromptInput;
