import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StudentBackPackBoard from './StudentBackPackBoard';
import StudentBackPackView from './StudentBackPackView';
import RoutingPaths from '../RoutingPath';

const StudentBackPack = () => (
  <Switch>
    <Route
      path={RoutingPaths.BackPack}
      component={StudentBackPackBoard}
      exact
    />
    <Route
      path={RoutingPaths.StudentBackPackView}
      component={StudentBackPackView}
      exact
    />
  </Switch>
);

export default React.memo(StudentBackPack);
