import React, { useContext, useState } from 'react';
import { Box, Button, InputBase, styled, TextField } from '@mui/material';
import { CLICK_AND_LABEL_TEXT_LENGTH, TAG_WIDTH } from '../../../variables/constant';
import { SubmitTaskContext } from '../submit-task-context';
import zIndex from '../../../theme/z-index';
import { palette  } from '../../../theme/palette';
import { SxProps } from '@mui/system';
import { isIOS } from 'react-device-detect';

export interface NewTag {
  x?: number,
  y?: number,
}

const styles = {
  inputRoot: {
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
    backgroundColor: palette.customWhite.main,
    boxShadow: palette.shadow.main,
    borderRadius: '12px',
    border: `1px solid ${palette.customBackground.disabled}`,
    padding: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  saveButton: {
    width: '120px',
    marginTop: 2,
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: 800,
  },
  saveButtonEnabled: {
    color: `${palette.customWhite.main} !important`,
  },
  saveButtonDisabled: {
    color: `${palette.customBlack.lightBlack} !important`,
  },
  cancelButton: {
    marginLeft: 1,
  },
  form: {
    position: 'fixed',
    width: '100%',
    zIndex: zIndex.zIndex999,
    transform: 'translateZ(0)',
  },
  iOSBox: {
    width: '230px',
    minHeight: '45px',
    height: '45px',
    wordBreak: 'break-word',
    overflowY: 'scroll',
    padding: 0,
    '& .MuiInputBase-root.MuiOutlinedInput-root':{
      height: '45px',
      overflowY: 'scroll',
      alignItems: 'flex-start',
      '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline':{
        height: '45px',
        overflowY: 'scroll',
        alignItems: 'flex-start',
      },
    },
  },
  iOSInput: {
    '& .MuiInputBase-input': {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      height: '45px',
      width: '230px',
      wordBreak: 'break-word',
      overflowY: 'scroll',
      verticalAlign: 'top',
      border: 'none',
      padding: '0px 5px',
    },
  },
};

interface Props {
  newTag: NewTag,
  setNewTag: Function,
}

const StyledForm = styled('form')({
  position: 'fixed',
  width: '100%',
  zIndex: zIndex.zIndex999,
  transform: 'translateZ(0)',
});

const AddTagForm = ({ newTag, setNewTag }: Props) => {
  const [title, setTitle] = useState<string>('');
  const {
    addAnnotation,
  } = useContext(SubmitTaskContext);

  const onAddTag = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (title && newTag?.x && newTag?.y) {
      addAnnotation?.({
        x_position: newTag.x - (TAG_WIDTH / 2),
        y_position: newTag.y,
        title: title.trim(),
      });
      hideAddTag();
    }
  };

  const hideAddTag = () => {
    setNewTag(null);
    setTitle('');
  };

  return (
    <>
      {/*
        // @ts-ignore */}
      <StyledForm xmlns="http://www.w3.org/1999/xhtml">
        <Box sx={{ ...styles.inputWrapper as SxProps }}>
          {/* The 'data-gramm': false prop is added to disable Grammarly extension check,
          For any similar extension we may have to find the prop which works with that extension.
           This is subject to change as it is highly dependent on the extension. */}

          {
            !isIOS && (
              <InputBase
                sx={{ fontSize: '26px' }}
                type="email"
                autoFocus
                value={title}
                inputProps={{
                  maxLength: CLICK_AND_LABEL_TEXT_LENGTH,
                  spellCheck: 'false',
                  'data-gramm': false,
                  autocorrect: 'off',
                }}
                onChange={
                  (event: React
                    .ChangeEvent<HTMLInputElement>) => setTitle(
                    event.target.value,
                  )
                }
                autoCorrect="off"
              />
            )
          }
          {
            isIOS && (
              <TextField
                multiline
                sx={{ ...styles.iOSBox, ...styles.iOSInput }}
                spellCheck="false"
                autoFocus
                value={title}
                inputProps={{
                  maxLength: CLICK_AND_LABEL_TEXT_LENGTH,
                  'data-gramm': false,
                  autocorrect: 'off',
                }}
                onChange={
                  (event: React
                    .ChangeEvent<HTMLInputElement>) => setTitle(
                    event.target.value,
                  )
                }
                autoCorrect="off"
              />
            )
          }

          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            width="100%"
          >
            {/* This multiple button rendering is added to fix the safari paint issue */}
            {!title && (
              <Button
                sx={{ ...styles.saveButton, ...styles.saveButtonDisabled }}
                variant="contained"
                color="primary"
                onClick={onAddTag}
                disabled={!title?.trim()}
                type="submit"
              >
                Add
              </Button>
            )}
            {!!title && (
              <Button
                sx={{ ...styles.saveButton, ...styles.saveButtonEnabled }}
                variant="contained"
                color="primary"
                onClick={onAddTag}
                disabled={!title?.trim()}
                type="submit"
              >
                Add
              </Button>
            )}
            <Button
              sx={{ ...styles.saveButton, ...styles.cancelButton }}
              variant="outlined"
              onClick={hideAddTag}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </StyledForm>
    </>
  );
};

export default React.memo(AddTagForm);
