import React from 'react';
import InputBase from '@mui/material/InputBase';
import { Box, styled } from '@mui/material';
import SearchIcon from '../Icons/SearchIcon';
import { palette  } from '../../theme/palette';

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  searchTerm?: string | number | null
  mt?: number
  mb?: number
  disabled?: boolean
  placeholder?: string
  isCommon?: boolean
}

const useStyles = {
  search: {
    width: '100%',
    height: 48,
    background: palette.customWhite.main,
    position: 'relative',
    borderRadius: 12,
  },
  searchIcon: {
    padding: '0 16px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    '&.MuiInputBase-input': {
      padding: '12px 8px 12px 0',
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${(32)}px)`,
      width: '100%',
      fontSize: 18,
      letterSpacing: 1,
      color: palette.customBlack.lightBlack,
      background: 'none',
      border: 0,
    },
  },
};

const useCommonSearchStyles = {
  search: {
    height: 50,
    background: palette.transparent.main,
    position: 'relative',
    '& .MuiInputBase-root': {
      width: '200%',
    },
  },
  searchIcon: {
    padding: '0 16px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.customGrey.darkGrey,
  },
  inputInput: {
    '&.MuiInputBase-input': {
      padding: '12px 8px 12px 0',
      paddingLeft: `calc(1em + ${(32)}px)`,
      width: '100%',
      fontSize: 18,
      letterSpacing: 1,
      color: palette.customBlack.lightBlack,
      background: 'none',
      border: '0px',
    },
  },
};

const StyledInputBase = styled(InputBase)(()=>({
  '& .MuiInputBase-input': {
    padding: '12px 8px 12px 0',
    paddingLeft: 'calc(1em + 32px)',
    width: '100%',
    fontSize: 18,
    letterSpacing: 1,
    color: palette.customBlack.lightBlack,
    background: 'none',
    border: '0px',
  },
}));


const SearchBar = ({ handleChange, searchTerm, mt = 2, mb = 2, disabled = false, placeholder = 'Search', isCommon = false }: Props) => {
  return (
    <Box
      sx={{ ...useStyles.search, ...(isCommon) && useCommonSearchStyles.search }}
      mt={isCommon ? 0 : mt}
      mb={isCommon ? 0 : mb}
    >
      <Box sx={{ ...useStyles.searchIcon, ...(isCommon) && useCommonSearchStyles.searchIcon }}>
        <SearchIcon />
      </Box>
      <StyledInputBase
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        value={searchTerm || '' }
        onChange={handleChange}
        disabled={disabled}
      />
    </Box>
  );
};
export default SearchBar;

