import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledContainer11, StyledContainer12 } from '../ScoreTask.styled';

const GoalsListRubric = () => {
  const theme = useTheme();
  return (
    <StyledContainer11>
      <Typography variant='sContentHeader' mb={theme.spacing(1.5)}>
        Goals Rubric
      </Typography>
      <StyledContainer12>
        <Typography variant='rubricOrder'>
          1.
        </Typography>
        <Typography variant="rubricOrderContent">
          No Attempt
        </Typography>
      </StyledContainer12>
      <StyledContainer12>
        <Typography variant='rubricOrder'>
          2.
        </Typography>
        <Typography variant="rubricOrderContent">
          Emerging
        </Typography>
      </StyledContainer12>
      <StyledContainer12>
        <Typography variant='rubricOrder'>
          3.
        </Typography>
        <Typography variant="rubricOrderContent">
          Progressing
        </Typography>
      </StyledContainer12>
      <StyledContainer12>
        <Typography variant='rubricOrder'>
          4.
        </Typography>
        <Typography variant="rubricOrderContent">
          Goal Met
        </Typography>
      </StyledContainer12>
    </StyledContainer11>
  );
};

export default React.memo(GoalsListRubric);
