import React from 'react';
import { Box, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader/loader';
import { CreateAssignmentStylesCss } from './CreateAssignment.styled';
import { AssignmentInput, CreateAssignmentContext, CreateAssignmentInit } from './create-assignment-hook';
import DetailsStep from './Steps/DetailsStep';
import SelectImageStep from './Steps/SelectImageStep';
import InstructionsStep from './Steps/instructionsStep';
import { useForm, FormProvider } from 'react-hook-form';


const CreateAssignment = () => {
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);

  const {
    routeData,
    loading, setLoading,
    assignmentInput, setAssignmentInput,
    staticResourceImgSelected, setStaticResourceImgSelected,
    recordingTracker, setRecordingTracker,
    speakingPromptFile, setSpeakingPromptFile,
    writingPromptFile, setWritingPromptFile,
    editAssignmentLoaded, setEditAssignmentLoaded,
    assignmentFetched, setAssignmentFetched,
    isPastAssignment, setIsPastAssignment,
    isAssignmentSubmissionsSatrted, setIsAssignmentSubmissionsSatrted,
    selectedGrade, setSelectedGrade,
    searchTerm, setSearchTerm,
    selectedSubjects, setSubjectAreas,
    selectedTaskTypes, setTaskTypes,
  } = CreateAssignmentInit();

  const assignmentForm = useForm<AssignmentInput>({
    defaultValues: assignmentInput,
    reValidateMode: 'onChange',
  });

  return (
    <Layout>
      <Box sx={styles.formContainer as SxProps}>
        <CreateAssignmentContext.Provider value={{
          routeData,
          loading, setLoading,
          assignmentInput, setAssignmentInput,
          staticResourceImgSelected, setStaticResourceImgSelected,
          speakingPromptFile, setSpeakingPromptFile,
          writingPromptFile, setWritingPromptFile,
          editAssignmentLoaded, setEditAssignmentLoaded,
          assignmentFetched, setAssignmentFetched,
          isPastAssignment, setIsPastAssignment,
          isAssignmentSubmissionsSatrted, setIsAssignmentSubmissionsSatrted,
          selectedGrade, setSelectedGrade,
          searchTerm, setSearchTerm,
          recordingTracker, setRecordingTracker,
          selectedSubjects, setSubjectAreas,
          selectedTaskTypes, setTaskTypes,
        }}
        >
          <FormProvider {...assignmentForm}>
            <Switch>
              <Route path={`${routeData?.rootPath}/details`}>
                <DetailsStep title={`${routeData?.action} Assignment`} />
              </Route>
              <Route path={`${routeData?.rootPath}/select-image`}>
                <SelectImageStep title="Select Image"/>
              </Route>
              <Route path={`${routeData?.rootPath}/instructions`}>
                <InstructionsStep title={`${routeData?.action} Instructions`}/>
              </Route>
              <Redirect
                from={`${routeData?.rootPath}`}
                to={`${routeData?.rootPath}/details`}
              />
            </Switch>
          </FormProvider>
        </CreateAssignmentContext.Provider>
      </Box>
      <Loader open={loading} />
    </Layout>
  );
};

export default CreateAssignment;
