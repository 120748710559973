import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DiagonalArrowIcon = (props: any) => (
  <SvgIcon
    fontSize="medium"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g id="icon / nav open">
      <path d="M15.0526 4.656L9.45317 6.52744L15.8101 12.8547L12.8693 15.7955L6.52719 9.45341L4.65576 15.0529L0.199951 0.200195L15.0526 4.656Z" fill={props?.pathfil} />
    </g>
  </SvgIcon>
);

export default DiagonalArrowIcon;
