import React from 'react';
import StyledCard from '../../../../components/StyledCard';
import AppTooltip from '../../../../components/AppTooltip';
import useGoalsAndScoresByIndicatorWidget from './goals-and-scores-by-indicator-widget-hook';
import List from './List';
import {
  LearningGoalsScoresByIndicatorLineItem,
} from '../../../../generated/graphql';
import { Box } from '@mui/material';
import { AdminDashboards } from '../../../../variables/constant';

interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
}

const GoalsAndScoresByIndicatorWidget = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
}: Props) => {
  const {
    title, hasData, queryReqInput, handleSort,
    goalsScoresByIndicatorWidgetLoading, goalsScoresByIndicatorWidgetData,
  } = useGoalsAndScoresByIndicatorWidget();

  return (
    <Box id={AdminDashboards.learningGoals.GoalsAndScoresByIndicatorWidget.name}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
        fullHeight
        marginBottom="0"
      >
        <List
          submissionType={queryReqInput.input.filters?.submission_score_type!}
          isTableEmpty={!hasData}
          loading={goalsScoresByIndicatorWidgetLoading}
          sort={queryReqInput.input.sort!}
          order={queryReqInput.input.order_by!}
          dataRows={goalsScoresByIndicatorWidgetData as LearningGoalsScoresByIndicatorLineItem[]}
          handleSort={handleSort}
        />
      </StyledCard>
    </Box>
  );
};

export default React.memo(GoalsAndScoresByIndicatorWidget);
