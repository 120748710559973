import React from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import NoResults from '../../../components/NoResults';
import HumanDeskFrame from '../../../components/Icons/HumanDeskFrame';
import { palette  } from '../../../theme/palette';
import useSectionGroups from './section-groups-hook';
import CustomPagination from '../../../components/CustomPagination';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { StyledTableCell } from '../../Admin/admin-tables-styled';
import { StyledTableDataColumn, StyledTableSort } from '../../../components/Common/Common.styled';
import { GroupsSort } from '../../../variables/types';
import { ArrowDownIcon } from '../../../components/Icons';
import { OrderBy } from '../../../generated/graphql';

interface Props {
  sectionID?: string,
}

const tableWithNoDataStyles = {
  height: '100%',
  '& tbody tr:hover': {
    backgroundColor: palette.transparent.main,
    cursor: 'initial',
  },
};

const SectionGroupsList = ({ sectionID }: Props) => {
  const {
    loading,
    groups,
    totalCount,
    pagination,
    rowsPerPage,
    sort, order,
    setRowsPerPage,
    handlePageChange,
    handleSort,
  } = useSectionGroups({ sectionID });
  const isTableEmpty = !loading && groups?.length === 0;
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const sortColumn = (title:string, sortEnum: GroupsSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );
  return (
    <Box
      display="grid"
      width="100%"
      mt={0}
    >
      <StyledContainerRoot sx={{ borderTopLeftRadius: '0px' }} >
        <Table
          stickyHeader
          aria-label="student directory table"
          sx={loading || isTableEmpty ? tableWithNoDataStyles : {}}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ textAlign:'left' }} >
                { sortColumn('Group', GroupsSort.Name) }
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign:'left' }}>
                Students
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !loading && groups?.map((group) => (
                <TableRow key={group.id} >
                  <StyledTableDataColumn sx={{ pl: '25px' }} >
                    {group?.name ?? ''}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn sx={{ pl: '50px' }} >
                    {group?.student_count ?? ''}
                  </StyledTableDataColumn>
                </TableRow>
              ))
            }
            {isTableEmpty && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="center"
                >
                  <NoResults
                    label="No groups yet"
                    description="You have not created any groups at this time."
                    svgComponent={<HumanDeskFrame />}
                  />
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="center"
                >
                  <CircularProgress size={28} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledContainerRoot>
      <Box> 
        <CustomPagination
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={pagination.page}
          onChange={handlePageChange}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default SectionGroupsList;
