import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
  CircularProgress, SxProps,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TablePagination from '../../../../components/TablePagination';
import NoResults from '../../../../components/NoResults';
import { AnalyticsIcon } from '../../../../components/Icons';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';
import { StyledTableContainer, StyledTableSort2, tableWithNoDataStyles } from '../../../../components/Common/Common.styled';
import { DistrictWideComparisonsStylesCSS } from '../DistrictWideComparisons.styled';
import { StyledTableCell2 } from '../../../Admin/admin-tables-styled';
import SchoolSpecificDetailRow from './SchoolSpecificDetailRow';

import { PaginationProps } from '../../../../variables/types';
import { OrderBy, DistrictWideSchoolSpecificDetailsSortType, DistrictWideSchoolSpecificDetails } from '../../../../generated/graphql';

interface Props {
  isTableEmpty: boolean,
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  limit: number,
  sort: DistrictWideSchoolSpecificDetailsSortType,
  order: OrderBy,
  teachers: DistrictWideSchoolSpecificDetails[],
  handlePageChange: (_: React.ChangeEvent<unknown>, value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: DistrictWideSchoolSpecificDetailsSortType) => void) | Function,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  limit,
  sort,
  order,
  teachers,
  handlePageChange,
  handleSort,
}: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  return (
    <Box
      display="grid"
      width="100%"
    >
      <Box>
        <StyledTableContainer sx={{ ...styles.tableContainer, ...styles.sliderTableContainer as SxProps }}>
          <Table
            stickyHeader
            aria-label="District-wide Comparisons School Specific Table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell2 sx={{ ...styles.width35, ...styles.drawerTableColumn, textAlign: 'left' }}>
                  <StyledTableSort2
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolSpecificDetailsSortType.Teacher)}
                    active={sort === DistrictWideSchoolSpecificDetailsSortType.Teacher}
                    IconComponent={ArrowDownIcon}
                  >
                    Teachers
                  </StyledTableSort2>
                </StyledTableCell2>
                <StyledTableCell2 sx={{ ...styles.width10, ...styles.drawerTableColumn }}>
                  <StyledTableSort2
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolSpecificDetailsSortType.GradePosition)}
                    active={sort === DistrictWideSchoolSpecificDetailsSortType.GradePosition}
                    IconComponent={ArrowDownIcon}
                  >
                    Grade
                  </StyledTableSort2>
                </StyledTableCell2>
                <StyledTableCell2 sx={{ ...styles.width35, ...styles.drawerTableColumn }}>
                  <StyledTableSort2
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolSpecificDetailsSortType.Section)}
                    active={sort === DistrictWideSchoolSpecificDetailsSortType.Section}
                    IconComponent={ArrowDownIcon}
                  >
                    Class
                  </StyledTableSort2>
                </StyledTableCell2>
                <StyledTableCell2 sx={{ ...styles.width10, ...styles.drawerTableColumn }}>
                  <StyledTableSort2
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolSpecificDetailsSortType.AvgSpeakingScore)}
                    active={sort === DistrictWideSchoolSpecificDetailsSortType.AvgSpeakingScore}
                    IconComponent={ArrowDownIcon}
                  >
                    Speaking
                  </StyledTableSort2>
                </StyledTableCell2>
                <StyledTableCell2 sx={{ ...styles.width10, ...styles.drawerTableColumn }}>
                  <StyledTableSort2
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolSpecificDetailsSortType.AvgWritingScore)}
                    active={sort === DistrictWideSchoolSpecificDetailsSortType.AvgWritingScore}
                    IconComponent={ArrowDownIcon}
                  >
                    Writing
                  </StyledTableSort2>
                </StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && teachers?.map((teacher, index) => (
                <SchoolSpecificDetailRow rowIndex={index} teacher={teacher} key={teacher?.teacher} />
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="We Need More Data."
                      description=""
                      svgComponent={<AnalyticsIcon />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        paddingTop="20px"
      >
        {totalCount > limit && (<TablePagination
          count={Math.ceil(totalCount / limit) || 0}
          page={pagination.page}
          onChange={handlePageChange}
          defaultPage={pagination.page}
          disabled={totalCount < limit}
        />)}
      </Box>
    </Box>
  );
};
export default List;
