import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledBodyTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  color: theme.palette.customBlack.lightBlack,
  marginTop: 12,
  maxHeight: 150,
  overflowY: 'scroll',
}));

export default StyledBodyTypography;
