import React from 'react';
import StyledCard from '../../../../components/StyledCard';
import NoResults from '../../../../components/NoResults';
import EmptyResults from '../../../../components/Icons/EmptyResults';
import SingleBarGraphChart from '../../../../components/Charts/Graph/SingleBarGraphChart';
import SingleBarGraphLoader from '../../../../components/Loader/SingleBarGraphLoader';
import { palette  } from '../../../../theme/palette';
import PerformanceTable from '../../../Analytics/Overview/PerformanceTable';
import AppTooltip from '../../../../components/AppTooltip';
import Flyover from './Flyover';
import usePerformanceInfo, { Props } from './performance-widget-hook';
import { AdminDashboards } from '../../../../variables/constant';
import { Box } from '@mui/material';

const PerformanceWidget = ({
  skillTypes,
  alignTitle,
  enableTooltip,
  tooltipTitle,
}: Props) => {
  const {
    currentSkill, skillFetch,
    totalCount, hasData,
    performanceSkillData,
    barGraphData,
    performanceWidgetDataLoading,
    title, handleTooltipClick,
  } = usePerformanceInfo();

  return (
    <Box id={AdminDashboards.performance.PerformanceSliderWidget.name} sx={{ height: '100%' }}>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle}/>}
        fullHeight
        marginBottom='0px'
      >
        {!performanceWidgetDataLoading && hasData && (
          <>
            <SingleBarGraphChart
              boxWidth='100%'
              data={barGraphData}
              onLinkClick={(current, skillScore) => {
                handleTooltipClick(current?.payload ?? null, skillScore);
              }}
            />
            <PerformanceTable
              data={performanceSkillData}
              skillType={skillTypes}
              skillCount={totalCount}
              customLabel
              fullWidth
            />
          </>
        )}
        {!performanceWidgetDataLoading && !hasData && (
          <NoResults
            description={AdminDashboards.performance.PerformanceSliderWidget.insufficientData.description}
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {performanceWidgetDataLoading && <SingleBarGraphLoader />}
      </StyledCard>
      {currentSkill && (
        <Flyover
          open={!!currentSkill}
          skill={currentSkill}
          skillLevel={skillFetch!}
          onClose={() => handleTooltipClick(null, null)}
        />
      )}
    </Box>
  );
};

export default React.memo(PerformanceWidget);
