/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UploadImageIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="60"
    height="52"
    viewBox="0 0 60 52"
    fill="none"
    {...props}
  >
    <g>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.80001 0.100098C3.71311 0.100098 0.400024 3.41319 0.400024 7.5001V44.5001C0.400024 48.587 3.71311 51.9001 7.80001 51.9001H52.1999C56.2868 51.9001 59.5999 48.587 59.5999 44.5001V7.5001C59.5999 3.41319 56.2868 0.100098 52.1999 0.100098H7.80001ZM52.1999 44.5001H7.80001L22.6 14.9001L33.7 37.1001L41.0999 22.3001L52.1999 44.5001Z" />
    </g>
  </SvgIcon>
);

export default UploadImageIcon;
