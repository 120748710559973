import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import RoutingPaths from '../RoutingPath';
import ClassList from './ClassList';
import CreateClass from '../Classes/CreateClass';

const TeacherClass = () =>  (
  <Layout>
    <Switch>
      <Route
        path={RoutingPaths.TeacherClasses}
        component={ClassList}
        exact
      />
      <Route
        path={[
          `${RoutingPaths.CreateClass}`, `${RoutingPaths.CreateClass}/:step`,
          `${RoutingPaths.EditClass}`, `${RoutingPaths.EditClass}/:step`,
        ]}
        component={CreateClass}
        exact
      />

    </Switch>
  </Layout>
);


export default TeacherClass;
