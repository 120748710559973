import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';
import AppRouter from './pages/AppRouter';
import './core/polyfill';
import './core/sentry';
import Notifier from './components/Notifier';
import apolloClient from './core/apolloClient';
import ImitationContextInit, { useImitationProvider } from './core/imitationContext';
import NavigationContext from './core/navigationContext';
import AuthContext from './core/authContext';
import { NavigationType } from './variables/types';
import initAWSAmplify from './core/awsAmplify';
import { FeatureFlagContextProvider } from './core/featureContext';
import DevTools from './components/DevTool/Devtool';
import ErrorPage from './components/ErrorBoundary/ErrorPage';
import ThemeConfig from './theme';
import SiteMaintenance from './pages/SiteMaintenance';

// @ts-ignore - .env tag lint error in VSCODE
const { REACT_APP_SITE_MAINTENANCE } = import.meta.env;

initAWSAmplify();

const CCSThemeProvider = () => {
  return (
    <ThemeConfig>
      <>
        <CssBaseline />
        <Notifier />
        <AppRouter />
        <DevTools />
      </>
    </ThemeConfig>
  );
};
const ApolloContainer = () => {
  const { getImitationUserId } = useImitationProvider();
  return (
    <ApolloProvider client={apolloClient(getImitationUserId())}>
      <NavigationContext defaultNavigation={NavigationType.Both}>
        <AuthContext>
          <CCSThemeProvider />
        </AuthContext>
      </NavigationContext>
    </ApolloProvider>
  );
};
const App = () => {
  if (REACT_APP_SITE_MAINTENANCE === 'true') {
    return <SiteMaintenance />;
  }
  return (
    <ErrorBoundary fallback={<ErrorPage/>}>
      <FeatureFlagContextProvider>
        <ImitationContextInit>
          <ApolloContainer/>
        </ImitationContextInit>
      </FeatureFlagContextProvider>
    </ErrorBoundary>
  );
};

export default App;
