import { useHistory } from 'react-router-dom';

const useRedirect = () => {
  const history = useHistory();
  const { location: { search } } = history;

  const navigateTo = (path: string) => {
    history.push({
      pathname: path,
      search,
    });
  };

  return {
    navigateTo,
    search,
  };
};

export default useRedirect;
