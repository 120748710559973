import { matchPath, useHistory, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  CreateAnnotationsInput,
  SubmissionStatus,
  useCreateOrSkipAnnotationsMutation,
  useGetSubmissionQuery,
  useUpdateSubmissionMutation,
} from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { AnnotationInterface, NotifierType, PTSubmissionSteps } from '../../variables/types';
import toRound from '../../utils/toRound';
import useSpeechSynthesis from '../../utils/useSpeechSynthesis';
import { DELETED_ASSIGNMENT_MESSAGE, SUBMISSION_DOES_NOT_EXIST_ERROR } from '../../variables/constant';
import usePrevious from '../../utils/usePrevious';
import { SubmitTaskContext } from './submit-task-context';
import { escapingSpecialCharacters } from '../../utils/handleEscapeCharacters';

interface RouteProp {
  id: string
}

const useSubmitTask = () => {
  const { id } = useParams<RouteProp>();
  const [status, updateStatus] = useState<SubmissionStatus | undefined>();
  const [annotations, setAnnotations] = useState<Array<AnnotationInterface>>();
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [showGoals, setShowGoals] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showQuitConfirmation, setShowQuitConfirmation] = useState<boolean>(false);
  const [submissionStep, setSubmissionStep] = useState<string>(PTSubmissionSteps.Labeling);
  const { speak, speaking, voices } = useSpeechSynthesis({});
  const [playing, setPlaying] = useState(false);
  const [recording, setRecording] = useState(false);
  const prevStatus = usePrevious(status);
  const [isSpeakingWritingPromptPlaying, setIsSpeakingWritingPromptPlaying] = useState(false);
  const { data, loading, refetch } = useGetSubmissionQuery({
    fetchPolicy: 'network-only',
    variables: { id },
  });
  const [createAnnotationsForAssignment, { loading: annotationLoading }] = useCreateOrSkipAnnotationsMutation();
  const [updateSubmission, { loading: updateLoading }] = useUpdateSubmissionMutation();
  const disableEdit = status !== SubmissionStatus.Started;
  const history = useHistory();

  const {
    setShowSuccess: setShowSuccessContext,
  } = useContext(SubmitTaskContext);

  const updateSubmissionStatus = async (submissionStatus: SubmissionStatus, writing: any) => {
    if (!id) {
      return;
    }
    try {
      const response = await updateSubmission({
        variables: {
          id,
          input: {
            status: submissionStatus,
            writing: escapingSpecialCharacters(writing),
          },
        },
      });
      if (response?.data?.updateSubmission?.id) {
        updateStatus?.(submissionStatus);
        setShowSuccessContext?.(true);
      }
    } catch (error) {
      if (getErrorMessage(error) === SUBMISSION_DOES_NOT_EXIST_ERROR) {
        openSnackbar({
          message: DELETED_ASSIGNMENT_MESSAGE,
        }, NotifierType.Error);
        history.replace('/');
      } else {
        openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
      }
    }
  };

  const createAnnotations = async (annotationsInput?: CreateAnnotationsInput[]) => {
    try {
      const response = await createAnnotationsForAssignment({
        variables: {
          submission_id: id,
          annotations: annotationsInput,
        },
      });
      if (response.data?.createOrSkipAnnotations) {
        refetch();
      }
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  const handleCreateAnnotations = async () => {
    const annotationsInput = annotations?.map((annotation) => ({
      x_position: toRound(annotation?.x_position),
      y_position: toRound(annotation?.y_position),
      title: annotation.title,
    }));

    await createAnnotations(annotationsInput);
    history.push(`/tasks/${id}/submit/speaking`);
    if (!(data?.submission && data?.submission?.getGoalsForSubmission?.speaking?.length)) {
      setShowHelp(true);
      setShowGoals?.(false);
    } else {
      setShowHelp(false);
      setShowGoals?.(true);
    }
  };

  const handleAnnotationUpdate = (index: number, toBeUpdated: Partial<AnnotationInterface>) => {
    // @ts-ignore
    const currentAnnotations = [...annotations as any[]];
    currentAnnotations[index] = {
      ...currentAnnotations[index],
      ...toBeUpdated,
    };
    setAnnotations(currentAnnotations);
  };

  const addAnnotation = (tag: AnnotationInterface) => {
    const currentAnnotations = [...annotations as any[]];
    currentAnnotations.push({
      ...tag,
      id: uuid(),
    });
    setAnnotations(currentAnnotations);
  };

  const deleteAnnotation = (index: number) => {
    if (!matchPath(history.location.pathname, '/tasks/:id/submit/annotation')) {
      return;
    }
    const currentAnnotations = [...annotations as any[]];
    currentAnnotations.splice(index, 1);
    setAnnotations(currentAnnotations);
  };

  const getCurrentGoal = () => {
    if (matchPath(history.location.pathname, '/tasks/:id/submit/speaking') && data?.submission && data?.submission?.getGoalsForSubmission?.speaking?.length > 0) {
      return data?.submission?.getGoalsForSubmission?.speaking?.[0];
    } if (matchPath(history.location.pathname, '/tasks/:id/submit/writing') && data?.submission && data?.submission?.getGoalsForSubmission?.writing?.length > 0) {
      return data?.submission?.getGoalsForSubmission?.writing?.[0];
    }
    return null;
  };

  const getSubmissionStep = () => {
    if (matchPath(history.location.pathname, '/tasks/:id/submit/speaking')) {
      setSubmissionStep(PTSubmissionSteps.Speaking);
    } else if (matchPath(history.location.pathname, '/tasks/:id/submit/writing')) {
      setSubmissionStep(PTSubmissionSteps.Writing);
    } else {
      setSubmissionStep(PTSubmissionSteps.Labeling);
    }
  };

  useEffect(() => {
    if (!loading && (!data?.submission?.assignment?.id && !data?.submission?.getBenchmark?.id)) {
      openSnackbar({
        message: DELETED_ASSIGNMENT_MESSAGE,
      }, NotifierType.Error);
    } else if (data?.submission?.status) {
      updateStatus(data?.submission?.status);
      setAnnotations(data?.submission?.annotations);
    }
    getSubmissionStep();
  }, [data, history, loading]);

  return {
    id,
    disableEdit,
    loading: loading || annotationLoading || updateLoading,
    submission: data?.submission ?? undefined,
    status,
    updateStatus,
    showHelp,
    setShowHelp,
    showGoals,
    setShowGoals,
    showSuccess,
    setShowSuccess,
    showConfirmation,
    setShowConfirmation,
    showQuitConfirmation,
    setShowQuitConfirmation,
    handleCreateAnnotations,
    annotations,
    handleAnnotationUpdate,
    deleteAnnotation,
    addAnnotation,
    speak,
    speaking,
    voices,
    playing,
    setPlaying,
    currentGoal: getCurrentGoal(),
    prevStatus,
    updateSubmissionStatus,
    refetch,
    submissionStep,
    getSubmissionStep,
    recording,
    setRecording,
    isSpeakingWritingPromptPlaying,
    setIsSpeakingWritingPromptPlaying,
  };
};

export default useSubmitTask;
