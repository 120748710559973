import React from 'react';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomPagination from '../../../../components/CustomPagination';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';
import {
  StyledContainerRoot2,
  StyledTableSort,
  tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import { StyledTableDataCell } from '../../../Admin/admin-tables-styled';
import { BenchmarkAssignToSortType, OrderBy } from '../../../../generated/graphql';
import StudentCountRow from './StudentCountRow';
import useStudentsCountFilter from '../students-count-filter-hook';
import { PAGE_SIZE } from '../../../../variables/constant';
import useCreateBenchmark from '../create-benchmark-hook';


const List = () => {
  const theme = useTheme();

  const { districts, grades, schools } = useCreateBenchmark();

  const {
    benchmarkStudents,
    totalCount,
    studentCountLoading,
    pagination,
    sort,
    handleSort,
    handlePageChange,
    order,
    rowsPerPage, setRowsPerPage,
  } = useStudentsCountFilter({ districts, grades, schools, currentStep: 4 });

  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  return (
    <Box
      display="grid"
      width="98%"
      height="100%"
      mt = {theme.spacing(2)}
    >
      <Box>
        <StyledContainerRoot2>
          <Table
            stickyHeader
            aria-label="District Grade School wide Student count"
            sx={{ ...((studentCountLoading) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableDataCell sx={{ textAlign: 'left' }}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(BenchmarkAssignToSortType.DistrictName)}
                    active={sort === BenchmarkAssignToSortType.DistrictName}
                    IconComponent={ArrowDownIcon}
                  >
                    District
                  </StyledTableSort>
                </StyledTableDataCell>
                <StyledTableDataCell>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(BenchmarkAssignToSortType.GradeName)}
                    active={sort === BenchmarkAssignToSortType.GradeName}
                    IconComponent={ArrowDownIcon}
                  >
                    Grade
                  </StyledTableSort>
                </StyledTableDataCell>
                <StyledTableDataCell>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(BenchmarkAssignToSortType.SchoolName)}
                    active={sort === BenchmarkAssignToSortType.SchoolName}
                    IconComponent={ArrowDownIcon}
                  >
                    School
                  </StyledTableSort>
                </StyledTableDataCell>
                <StyledTableDataCell>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(BenchmarkAssignToSortType.StudentCount)}
                    active={sort === BenchmarkAssignToSortType.StudentCount}
                    IconComponent={ArrowDownIcon}
                  >
                    Students
                  </StyledTableSort>
                </StyledTableDataCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!studentCountLoading && benchmarkStudents?.map((studentsData, index) => (
                <StudentCountRow rowIndex={index} rowData={studentsData} key={index}/>
              ))}
              {studentCountLoading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28}/>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot2>
      </Box>
      <CustomPagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onChange={handlePageChange}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};
export default List;
