/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GirlDeskFrame = (props: any) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="291"
      height="384"
      viewBox="0 0 291 384"
      fill="none"
      {...props}
      sx={{ fontSize: 185 }}
    >
      <g clipPath="url(#clip0)">
        <path d="M102.133 131.873C101.958 131.347 101.783 130.822 101.433 130.296C98.2824 125.917 84.1047 131.522 79.5538 132.398C71.5022 133.799 63.4506 135.201 55.399 136.427C42.2715 138.704 27.0436 139.229 14.7912 144.834C0.61346 151.315 -6.3879 170.758 10.4154 178.815C19.3421 183.194 30.7193 183.194 40.3461 184.42C47.5225 185.296 54.874 186.347 62.0504 187.223C67.3014 187.923 72.9025 189.325 78.3285 188.274C87.9554 186.347 92.3312 175.837 95.6568 167.78C98.1073 161.825 99.8577 155.694 100.908 149.388C101.608 145.71 103.533 136.427 102.133 131.873Z" fill="black" />
        <path d="M15.8421 177.239C3.93981 173.56 -0.0859747 161.299 9.71593 152.191C16.5423 145.885 27.3944 144.659 36.4961 143.258C43.3224 154.994 45.948 167.955 43.6726 181.443C34.3958 180.216 24.5938 179.866 15.8421 177.239Z" fill="white" />
        <path d="M72.7268 184.945C64.3251 184.77 55.5734 183.019 46.9967 181.792C49.2722 168.13 46.8217 154.643 39.9954 142.557C59.4242 139.229 78.853 135.901 98.1068 132.748V132.923C98.6319 148.512 93.9059 185.646 72.7268 184.945Z" fill="#2A2A8B" />
        <path d="M88.1302 99.8189C86.905 93.5132 81.1289 95.9654 76.2279 99.2935C78.6784 94.389 80.0786 88.9591 76.4029 85.9814C73.9524 83.8795 70.4518 85.1056 68.5264 87.2075C66.2509 89.6597 64.5006 92.8126 62.7503 95.9654C62.2252 93.6884 61 91.9368 58.5495 91.7616C56.7992 91.5865 55.2238 92.4623 53.9986 93.6884C53.1234 92.2871 51.3731 91.5865 49.4477 91.9368C45.2469 92.6374 43.3215 97.717 42.7964 101.395C42.6214 103.147 42.6214 104.899 43.3215 106.65C43.1465 106.825 42.7964 107 42.6213 107.176C40.3459 110.504 39.6458 114.357 40.1709 118.211C40.1709 118.211 40.1709 118.211 39.9958 118.211C31.4191 122.59 23.3676 128.195 16.1912 134.5C9.0148 140.806 0.963202 149.389 0.0880303 159.373C-0.612107 166.905 2.88858 174.086 9.71491 177.239C17.7665 180.917 27.5684 179.691 33.6946 173.035C45.0719 160.424 37.7204 140.281 49.9728 128.195C55.7489 129.946 62.2252 131.347 67.3012 127.144C69.7517 125.042 70.8018 122.239 70.6268 119.437C74.8276 116.634 78.6785 113.481 82.1791 109.978C84.8047 107.526 89.0054 103.848 88.1302 99.8189Z" fill="black" />
        <path d="M54.5237 101.221C54.6987 99.8196 56.274 94.0394 58.8996 95.9661C60.1248 96.8419 59.5998 100.871 59.5998 102.272C59.5998 102.447 59.5998 102.797 59.5998 102.972C59.2497 104.549 58.5495 106.3 56.9742 106.476C55.924 106.651 55.0489 105.25 54.6988 104.374C54.5237 103.323 54.5237 102.272 54.5237 101.221Z" fill="white" />
        <path d="M46.6467 100.871C46.8217 99.8197 47.1718 98.9439 47.6969 97.8929C47.872 97.5426 48.047 97.3675 48.222 97.0171C48.9222 95.6159 49.9724 95.2656 51.5477 96.3165C51.3727 97.0171 51.5476 97.5426 51.8977 97.8929C51.5476 98.9439 51.3727 99.8197 51.1976 100.695C51.0226 101.922 51.1976 103.148 51.3726 104.374C50.6725 104.549 50.3225 105.074 50.3225 105.95C48.5721 107.351 47.5219 107.351 47.1719 105.775C46.6468 105.074 46.4717 104.374 46.4717 103.673C46.2966 102.973 46.4716 101.922 46.6467 100.871Z" fill="white" />
        <path d="M69.9262 115.934C69.5761 115.233 69.226 114.533 68.7009 113.832C65.7253 109.979 59.4241 107.877 55.9234 112.256C52.9479 116.109 55.5734 121.364 60.2993 121.364C62.5747 121.364 62.5747 117.861 60.2993 117.861C58.724 114.357 59.249 112.956 61.5244 113.307C62.3996 113.131 63.0998 113.307 63.8 114.007C65.0252 114.708 65.9003 115.584 66.6005 116.81C68.0008 119.437 67.8257 122.24 65.7253 124.342C61.6995 128.195 55.2233 126.093 50.6724 124.692C49.9723 124.517 49.4471 124.692 49.0971 125.042C48.922 125.042 48.5719 125.217 48.3969 125.392C40.8704 132.399 39.6453 142.033 38.42 151.841C37.3698 158.673 36.3196 166.555 31.0686 171.634C23.367 178.991 10.5895 177.064 5.68858 167.781C1.31272 159.373 6.56377 150.44 12.1649 144.134C20.3915 134.676 31.4186 126.969 42.4458 121.189C42.7958 121.014 42.9708 120.838 43.1459 120.663C44.021 120.488 44.7212 119.612 44.5462 118.561C43.8461 115.408 44.3712 112.431 45.9465 109.628C46.4716 110.154 46.9967 110.504 47.6968 110.679C50.1473 111.555 52.7727 110.504 53.6479 108.227C54.348 108.752 55.0482 109.278 56.0984 109.453C60.8244 110.329 62.9248 105.6 63.8 101.921C65.0252 99.8193 66.2504 97.7174 67.6507 95.6155C68.3508 94.5645 68.876 93.5136 69.5761 92.4626C70.1012 91.5869 73.6019 85.106 75.3522 90.5359C75.8773 92.2875 73.952 96.3161 73.0768 97.8926C71.8515 100.345 70.4513 102.622 68.7009 104.724C67.4757 106.3 69.226 108.402 70.6263 107.526C70.8013 107.526 70.9763 107.351 71.1513 107.176C73.6018 105.424 76.2274 103.498 78.6779 101.746C79.5531 101.045 82.5286 98.5932 83.5788 99.1187C86.7295 100.345 83.4037 103.498 82.5286 104.549C78.6778 109.103 74.4771 112.781 69.9262 115.934Z" fill="white" />
        <path d="M158.144 141.157C148.692 133.8 136.615 131.348 125.062 129.947C117.536 129.071 109.134 125.392 101.258 127.319C91.6308 129.771 88.8303 140.806 84.8045 148.689C71.852 173.736 63.8004 201.236 61.3499 229.261C58.5493 260.089 58.8994 299.85 89.0053 317.541C103.358 326.124 120.686 327.701 137.14 325.073C149.742 322.971 163.92 318.417 166.195 303.879C167.421 295.471 165.32 286.714 164.62 278.306C163.57 267.271 163.22 256.236 163.22 245.201C163.395 224.182 166.72 203.864 170.046 183.195C172.146 167.781 171.621 151.666 158.144 141.157Z" fill="black" />
        <path d="M166.547 181.093C161.821 213.322 157.795 244.15 160.595 276.905C161.121 283.736 162.521 290.742 162.696 297.573C163.046 309.309 156.745 316.315 145.892 319.643C129.264 324.723 110.01 324.373 94.2574 316.666C62.2261 301.076 62.0511 260.264 64.8517 229.437C66.427 211.395 70.6278 193.704 76.9291 176.714C80.0797 167.956 83.9304 159.548 87.9562 151.316C90.2317 146.937 91.4568 142.032 93.9073 137.653C95.1325 135.376 96.883 133.099 99.3334 131.873C103.009 130.122 106.86 131.348 110.536 131.873C114.386 132.574 118.412 132.749 122.438 133.275C130.315 134.15 138.191 135.376 145.717 138.354C164.621 145.711 169.347 162.176 166.547 181.093Z" fill="#2A2A8B" />
        <path d="M145.717 138.179C140.641 147.287 133.639 156.045 122.787 156.22C107.034 156.395 106.684 142.908 110.185 131.698C108.959 131.523 107.734 131.172 106.509 130.997C103.008 141.507 103.008 153.593 115.261 158.322C130.489 164.277 141.516 151.841 148.692 139.58C147.817 139.054 146.767 138.704 145.717 138.179Z" fill="white" />
        <path d="M238.308 287.239C237.082 283.911 233.757 282.861 230.606 283.386C225.705 284.087 220.804 287.415 216.428 289.867C211.527 292.494 205.401 295.297 201.726 299.851C195.249 307.908 203.476 314.389 210.652 317.192C210.827 317.892 211.352 318.418 212.228 318.418C222.73 317.542 228.856 305.806 234.632 298.274C236.907 295.122 239.708 291.268 238.308 287.239Z" fill="black" />
        <path d="M232.182 295.473C230.781 297.4 229.206 299.327 227.806 301.078C224.13 305.983 219.929 313.164 213.453 314.565C213.278 314.565 213.278 314.39 213.103 314.39C211.178 313.865 209.252 312.989 207.327 312.113C202.776 306.158 202.776 301.954 206.977 299.502C208.727 297.75 211.528 296.524 213.628 295.298C217.129 293.196 223.255 288.467 227.456 287.941C239.183 286.54 234.457 292.32 232.182 295.473Z" fill="black" />
        <path d="M222.206 308.26C221.331 306.158 219.931 304.406 217.83 303.706C214.68 302.479 211.004 304.056 208.904 306.508C205.403 310.712 205.403 317.368 204.878 322.623C204.003 331.381 202.952 339.963 202.077 348.721C193.851 339.088 186.849 328.753 180.898 317.543C180.023 315.967 178.098 316.667 177.748 317.893C176.872 320.521 171.446 324.024 169.346 325.951C166.195 328.753 162.87 331.205 159.369 333.658C155.868 336.11 152.368 338.212 148.692 340.314C146.942 341.19 139.24 343.817 139.59 346.094C139.765 346.97 140.465 347.67 140.99 348.371C143.966 351.524 146.066 355.728 148.517 359.231C151.317 362.909 154.293 366.587 157.444 370.091C163.57 376.922 170.046 383.403 177.222 389.358C187.725 398.116 204.878 413.355 219.756 406.874C232.533 401.269 233.058 384.103 233.058 372.368C233.058 360.982 232.008 349.772 230.083 338.562C228.507 329.804 227.107 320.696 223.781 312.288C223.256 310.712 222.731 309.486 222.206 308.26Z" fill="black" />
        <path d="M216.08 404.071C201.027 408.1 181.773 389.008 171.971 379.9C161.294 369.916 151.842 358.53 143.966 346.269C157.093 340.139 168.821 331.731 178.973 321.397C188.075 337.862 199.452 352.575 213.104 365.537C214.68 367.113 217.305 364.661 215.555 363.084C211.879 359.581 208.378 355.903 205.053 352.225C205.053 352.049 205.228 352.049 205.228 351.874C205.928 345.043 206.803 338.212 207.503 331.205C208.028 325.776 208.554 320.346 209.429 314.916C209.604 313.865 209.779 312.814 210.304 311.938C212.404 309.136 217.305 309.486 219.406 311.938C221.506 314.39 221.856 318.244 222.556 321.221C223.606 325.425 224.657 329.629 225.532 333.833C227.282 342.24 228.332 350.648 229.033 359.056C229.733 371.842 232.883 399.692 216.08 404.071Z" fill="white" />
        <path d="M143.791 346.271C151.842 358.532 161.119 369.917 171.796 379.901C178.973 386.557 191.4 398.643 203.477 403.022C208.028 397.242 211.004 389.71 212.929 383.229C214.68 377.449 215.38 371.494 215.555 365.538C214.855 366.239 213.979 366.414 213.104 365.538C199.452 352.576 188.074 337.863 178.973 321.398C168.821 331.732 157.093 340.14 143.791 346.271Z" fill="#D3DDE1" />
        <path d="M204.879 493.576C202.778 485.343 195.077 475.534 192.626 471.506C187.725 463.098 183.174 454.34 178.798 445.582C169.522 426.84 161.645 407.573 154.994 387.78C151.668 377.971 148.693 367.987 146.067 357.828C144.142 350.296 142.917 342.589 140.991 335.057C139.941 331.028 139.941 328.751 135.915 327.35C132.589 326.124 128.914 325.423 125.413 324.898C118.412 323.847 111.235 324.022 104.234 325.423C100.733 326.124 97.2326 327.175 93.907 328.401C91.6315 329.277 89.7062 331.028 87.4308 331.904C85.5054 332.605 81.6546 332.43 82.5298 335.407C97.4076 379.197 121.912 421.235 150.268 457.843C157.969 468.002 166.196 477.636 174.773 487.095C178.973 491.649 183.349 496.203 187.725 500.582C190.701 503.56 194.551 507.413 199.277 505.661C205.229 503.735 206.279 499.005 204.879 493.576Z" fill="black" />
        <path d="M194.201 501.106C193.851 501.106 193.676 500.931 193.326 500.756C190.175 499.53 186.85 494.801 184.574 492.173C181.774 489.195 178.973 486.218 176.173 483.24C165.145 471.154 154.818 458.368 145.192 445.056C125.938 418.607 109.485 390.056 96.357 359.928C92.8564 351.871 89.5308 343.814 86.5552 335.757C101.958 326.823 119.812 325.247 136.615 331.202C136.615 331.202 136.615 331.202 136.615 331.378C142.566 361.33 151.318 390.581 162.87 418.607C168.296 431.568 174.247 444.355 180.723 456.791C184.049 463.272 187.55 469.578 191.226 475.708C193.326 479.211 195.426 482.715 197.702 486.218C198.577 487.619 199.277 488.845 200.152 490.246C202.253 494.801 200.327 502.683 194.201 501.106Z" fill="white" />
        <path d="M148.693 426.665C152.368 423.513 157.094 419.484 161.12 414.93C150.268 387.955 142.041 360.105 136.44 331.554C136.44 331.554 136.44 331.554 136.44 331.379C119.637 325.424 101.783 327 86.3804 335.933C89.356 344.166 92.6817 352.223 96.1824 360.105C107.56 385.853 121.212 410.726 137.14 434.022C141.341 432.27 145.192 429.643 148.693 426.665Z" fill="#D3DDE1" />
        <path d="M216.606 344.165C215.38 338.21 209.779 333.831 205.754 329.802C198.752 322.621 192.276 314.914 186.675 306.681C175.823 290.742 167.596 273.226 162.345 254.659C161.995 253.608 160.945 253.258 160.245 253.433C159.895 253.083 159.369 252.908 158.669 253.083C144.842 257.987 130.139 261.14 115.436 262.366C101.258 263.417 86.0301 263.242 72.3774 259.388C69.0518 258.513 65.7262 257.812 62.5756 257.111C56.7994 255.885 59.425 263.067 59.425 266.395C59.6 270.423 59.5999 274.452 59.7749 278.481C59.95 286.538 60.3001 294.595 60.4751 302.653C61.0002 318.767 61.3503 334.882 61.8754 350.821C62.0504 358.353 62.4005 365.885 62.5756 373.417C62.7506 382.175 59.7749 399.515 71.1522 402.843C77.4534 404.595 83.4046 400.566 89.7059 400.391C97.5824 400.216 103.709 406.697 109.66 410.901C117.886 416.856 126.288 419.483 135.565 414.579C148.517 407.748 155.344 395.312 171.797 397.413C184.749 398.99 191.401 396.538 198.052 384.977C202.253 377.445 206.104 369.913 210.304 362.207C212.93 357.127 217.831 350.821 216.606 344.165Z" fill="black" />
        <path d="M210.13 355.025C208.205 358.878 205.93 362.732 204.004 366.585C200.503 372.891 197.528 379.547 193.677 385.677C187.201 396.187 180.024 394.786 169.522 393.91C147.468 391.983 135.041 424.387 113.161 409.499C105.46 404.244 98.4586 396.362 88.3066 397.238C80.9552 397.763 70.6282 404.244 67.3025 393.91C65.0271 386.728 66.2522 377.62 65.9022 370.088C65.027 338.034 63.9768 305.98 63.1017 273.751C63.1017 270.949 62.9267 268.321 62.9267 265.519C62.9267 264.468 62.2264 262.541 63.2766 261.665C64.852 260.089 71.8533 262.891 73.7787 263.242C81.1301 264.643 88.6567 265.519 96.1832 265.869C111.236 266.57 126.289 265.168 140.992 261.84C144.318 261.14 147.818 260.264 151.144 259.213C153.944 258.337 156.57 257.461 159.37 256.586C166.547 281.283 178.799 304.229 195.427 323.671C199.628 328.576 205.229 332.955 209.08 338.034C213.281 343.99 213.106 349.069 210.13 355.025Z" fill="white" />
        <path d="M207.504 336.459C206.629 335.583 205.928 334.532 205.053 333.656L194.901 352.749C191.051 360.105 187.55 369.564 181.599 375.694C170.222 387.43 153.418 373.417 140.816 384.627C133.814 390.758 127.513 401.793 116.836 395.662C111.585 392.685 108.259 387.255 103.008 384.277C98.2825 381.475 93.5566 381.825 88.3056 383.051C82.3544 384.452 77.4534 386.029 71.5022 383.752C69.4018 382.876 67.4764 381.825 65.9011 380.424C65.9011 381.825 65.9011 383.401 65.9011 384.803C67.1264 385.678 68.5267 386.379 69.927 386.904C77.8035 390.057 83.9297 386.729 91.9812 385.853C107.559 384.277 115.436 408.974 131.714 398.64C134.865 396.713 137.315 393.736 139.766 391.108C145.367 385.153 150.793 382.35 159.019 383.401C166.371 384.452 173.547 386.379 180.198 381.825C187.025 377.446 190.525 368.863 194.026 362.032C198.577 353.274 203.128 344.866 207.504 336.459Z" fill="#2A2A8B" />
        <path d="M200.502 329.276C199.627 328.401 198.752 327.7 198.051 326.824L189.65 338.56C186.674 342.764 183.524 346.967 180.548 351.171C177.923 354.85 174.422 360.98 170.046 362.732C161.819 366.235 154.468 359.929 146.416 362.031C140.815 363.432 137.14 368.337 133.639 372.541C129.788 377.27 125.762 381.649 119.461 379.897C114.035 378.496 112.985 375.869 109.659 371.665C107.034 368.337 104.408 365.709 100.032 364.834C93.381 363.608 87.6049 368.162 81.1286 368.337C75.3525 368.512 69.9264 366.235 65.7256 362.557C65.7256 363.958 65.9006 365.534 65.9006 366.936C71.6767 370.964 78.8532 372.716 86.2046 370.964C92.3308 369.563 98.632 365.534 104.408 370.614C107.034 372.891 108.784 376.394 111.06 379.021C114.91 383.4 119.636 385.853 125.587 383.576C132.939 380.948 136.089 373.416 141.515 368.512C150.267 360.63 159.894 369.913 169.521 366.41C177.748 363.432 182.824 353.448 187.725 346.792C191.925 341.187 196.301 335.232 200.502 329.276Z" fill="#2A2A8B" />
        <path d="M223.606 174.787C211.529 166.38 198.576 159.198 185.274 153.068C178.622 150.09 171.621 147.288 164.795 144.835C162.344 143.959 157.443 141.157 155.343 142.733C152.542 144.835 153.068 150.616 152.717 153.593C152.192 159.373 151.667 165.154 152.192 171.109C153.068 182.67 156.393 194.055 161.819 204.389C162.169 204.915 162.694 205.265 163.22 205.265C163.395 205.44 163.745 205.44 164.095 205.44C171.096 204.915 178.272 204.214 185.099 206.141C194.551 208.768 203.127 217.876 198.401 228.211C193.15 239.771 176.872 247.478 165.495 251.332C161.469 248.529 153.593 245.727 149.217 249.23C147.641 250.456 147.116 252.908 148.517 254.485C150.092 256.411 153.593 256.937 156.043 257.462C153.242 261.491 150.617 265.344 147.817 269.373C146.241 271.65 144.141 274.803 146.416 277.255C146.941 277.781 147.466 278.131 147.992 278.306C147.992 278.481 147.992 278.656 147.992 278.832C148.342 282.335 151.142 283.736 154.118 283.386C153.943 283.736 153.943 284.086 153.943 284.437C154.643 288.115 158.318 288.816 161.294 287.765C161.469 287.94 161.469 288.29 161.819 288.465C172.496 297.048 177.572 272 178.622 263.943C200.677 257.112 225.357 248.704 237.259 227.335C248.111 207.192 241.285 187.224 223.606 174.787Z" fill="black" />
        <path d="M197.876 208.768C189.825 201.236 174.597 200.886 164.445 201.586C155.518 184.246 153.243 165.153 157.619 146.236C173.722 151.491 189.3 158.322 204.178 166.73C211.354 170.758 219.231 174.962 225.882 180.567C215.03 188.625 205.753 198.083 197.876 208.768Z" fill="#2A2A8B" />
        <path d="M234.634 224.008C223.432 245.553 198.402 253.61 176.873 260.441C176.698 260.441 176.697 260.616 176.522 260.616C175.822 260.791 175.122 261.317 175.122 262.193C174.772 266.396 174.072 270.25 172.847 274.279C172.322 275.68 168.121 288.817 164.095 285.664C166.546 283.562 168.296 280.759 169.346 277.782C170.221 275.68 166.721 274.804 166.02 276.906C165.32 278.833 164.27 280.584 162.695 282.161C161.645 283.212 157.619 286.54 157.094 283.212C157.094 282.861 156.744 282.511 156.569 282.336C160.769 280.234 163.745 276.38 164.97 272.002C165.67 269.9 162.345 268.849 161.645 271.126C160.944 273.228 159.894 275.33 158.319 276.906C157.444 277.782 151.668 282.161 151.318 278.658C151.318 278.307 151.143 278.132 151.143 277.957C155.168 276.205 159.194 269.9 160.419 267.447C161.47 265.521 158.494 263.769 157.444 265.696C156.219 267.973 154.993 269.9 153.243 271.826C152.893 272.177 150.968 274.454 150.442 274.454C147.817 274.103 150.442 271.651 150.792 270.951C153.768 266.572 156.919 262.193 159.894 257.814C160.594 256.763 159.894 255.712 159.019 255.361C158.844 254.836 158.494 254.311 157.794 254.135C156.043 253.61 154.468 253.26 152.718 252.559C151.843 252.384 150.967 252.384 151.668 251.683C152.368 250.983 153.593 251.158 154.468 251.158C157.969 251.158 160.944 252.384 163.745 254.311C163.92 254.486 163.92 254.486 164.095 254.486C164.445 254.836 164.97 255.011 165.67 254.836C179.148 250.457 203.828 238.897 203.478 221.556C203.303 217.527 202.253 214.199 200.327 211.572C208.379 200.712 217.831 191.078 228.508 182.846C230.083 184.422 231.658 185.999 232.883 187.75C240.76 198.26 240.585 212.623 234.634 224.008Z" fill="white" />
        <path d="M174.246 103.672C163.219 97.1915 153.242 114.707 164.444 121.013C168.995 123.465 175.296 121.714 177.922 117.16C180.547 112.605 178.797 106.3 174.246 103.672Z" fill="black" />
        <path d="M174.946 115.408C173.371 118.21 169.17 119.436 166.37 118.035C159.193 114.181 165.144 102.446 172.496 106.825C175.471 108.401 176.521 112.43 174.946 115.408Z" fill="white" />
        <path d="M110.709 82.8283C99.6817 76.3474 89.7047 93.8633 100.907 100.169C105.458 102.621 111.759 100.87 114.385 96.3155C117.01 91.7614 115.26 85.2805 110.709 82.8283Z" fill="black" />
        <path d="M111.41 94.3895C109.834 97.192 105.634 98.4181 102.833 97.0168C95.6568 93.1633 101.608 81.4277 108.959 85.8067C111.76 87.3831 112.985 91.5869 111.41 94.3895Z" fill="white" />
        <path d="M184.573 49.0224C180.197 37.9874 170.745 27.653 159.193 24.1498C145.365 19.946 134.163 30.2804 125.586 39.9141C115.959 50.5988 107.908 63.0351 105.282 77.3981C102.482 92.812 106.508 108.751 117.535 119.962C115.784 126.267 110.533 131.697 111.234 138.528C111.759 143.783 115.784 147.812 120.685 149.388C136.263 154.293 144.14 136.076 146.765 124.165C179.672 118.21 196.3 78.2738 184.573 49.0224Z" fill="black" />
        <path d="M164.094 111.905C158.667 115.934 152.366 118.911 145.365 120.663C144.84 120.838 144.49 120.838 144.14 121.188C142.564 122.414 142.214 128.019 141.514 130.121C140.464 133.274 139.064 136.427 137.313 139.23C133.638 145.01 127.861 148.338 121.035 145.71C105.807 139.755 125.236 126.268 120.51 117.685C118.585 114.182 114.734 111.555 112.633 107.876C110.358 103.848 108.783 99.2934 108.258 94.5642C107.032 85.1056 109.133 75.647 112.809 67.0643C116.659 57.956 122.61 49.3733 129.437 41.8414C135.213 35.5357 142.039 27.1281 151.316 26.4275C167.594 25.2014 180.897 44.2937 184.047 58.3063C188.248 78.7999 180.197 99.9941 164.094 111.905Z" fill="white" />
        <path d="M125.411 119.786C124.011 119.611 122.435 119.436 121.035 119.085C122.085 123.464 118.235 128.894 116.309 133.974C120.16 135.725 124.536 133.974 127.686 131.522C130.487 129.42 135.738 125.041 131.712 121.362C129.962 119.611 127.511 119.961 125.411 119.786Z" fill="#D3DDE1" />
        <path d="M172.845 103.671C182.997 91.4096 187.373 74.5944 183.873 58.1295C180.722 44.1168 167.419 25.0245 151.141 26.2506C142.039 26.9512 135.038 35.3589 129.262 41.6646C122.436 49.1964 116.659 57.7791 112.634 66.8874C111.758 68.8141 111.058 70.916 110.358 73.0179C130.137 69.8651 150.791 72.8428 170.045 65.8364C168.995 78.623 169.87 91.0593 172.845 103.671Z" fill="#D3DDE1" />
        <path d="M156.567 86.3315C153.242 83.529 150.616 91.0608 150.791 93.3379C150.966 94.9143 152.541 95.6149 153.767 94.564C155.342 93.1627 158.842 88.6086 156.567 86.3315C156.567 86.5067 156.567 86.5067 156.567 86.3315Z" fill="black" />
        <path d="M139.589 82.4783C136.088 86.1567 134.863 91.0611 135.738 96.1407C136.263 98.4178 139.589 97.3668 139.064 95.2649C138.364 91.5866 139.414 87.7331 142.039 84.9305C143.615 83.179 141.164 80.7267 139.589 82.4783Z" fill="black" />
        <path d="M147.641 100.869C144.665 103.322 140.64 103.497 137.314 101.57C135.389 100.344 133.638 103.497 135.564 104.548C140.289 107.35 145.891 106.825 150.091 103.322C151.842 101.745 149.391 99.293 147.641 100.869Z" fill="black" />
        <path d="M132.763 87.0322C132.063 84.7552 130.487 82.8284 128.387 81.7775C127.687 81.252 126.987 80.9017 126.112 80.7265C125.061 80.3762 124.186 81.0769 124.011 81.9526C124.011 82.1278 123.836 82.1278 123.836 82.303C123.661 83.1787 124.186 84.4049 125.062 84.4049C125.762 84.58 126.287 84.7552 126.812 84.9303C127.862 85.631 128.737 86.6819 129.262 87.908C130.137 90.0099 133.288 89.1341 132.763 87.0322Z" fill="black" />
        <path d="M288.369 114.532C283.118 116.284 267.365 118.911 274.541 105.424C275.591 103.497 278.042 101.921 279.442 100.52C282.243 97.717 284.518 94.7393 286.093 91.061C288.894 84.4049 289.244 76.8731 286.619 70.0419C283.818 63.0356 278.042 57.2554 271.04 54.4528C262.814 51.1248 242.86 57.0802 240.935 45.3446C239.359 35.7109 249.161 27.6536 246.536 17.3192C244.96 11.1887 240.409 5.75874 234.633 2.9562C226.932 -1.07245 218.18 -0.722128 210.304 2.43073C206.453 4.00715 202.777 6.10906 199.452 8.56128C191.575 14.3415 186.499 20.6472 184.574 30.4561C182.473 40.9656 184.399 52.3509 190.35 61.284C195.076 68.4655 202.427 74.0706 210.479 77.0483C219.055 80.2011 227.107 78.975 235.859 79.3253C264.389 80.5515 241.985 116.109 255.988 130.472C268.59 143.433 284.343 127.844 290.819 116.984C291.344 115.408 289.769 114.007 288.369 114.532Z" fill="black" />
        <path d="M259.314 128.369C251.787 122.239 254.238 111.029 254.763 102.446C255.463 92.4619 254.238 80.7263 243.386 76.8728C238.135 74.9461 232.359 75.8219 226.757 75.997C217.481 76.1722 208.729 73.8951 201.203 68.1149C185.975 56.3793 183.174 35.3602 192.626 19.2456C201.202 10.1374 212.405 2.08009 225.707 3.83168C232.709 4.88263 239.36 9.08644 242.16 15.7425C245.136 22.5737 242.16 28.3539 239.71 34.8347C237.435 40.615 235.509 47.7965 240.235 52.8761C244.436 57.4302 251.262 56.5544 256.863 56.2041C269.991 55.1531 283.118 62.8601 284.694 76.8728C285.394 83.0034 283.819 89.6594 280.143 94.5638C277.517 98.067 273.667 100.519 271.391 104.198C268.941 107.876 268.241 112.43 271.041 116.108C274.542 120.662 280.143 120.312 285.044 119.086C279.093 127.669 269.116 136.427 259.314 128.369Z" fill="black" />
        <path d="M192.625 40.4403C188.249 34.8353 181.073 33.0837 174.772 36.2365C174.247 36.5868 174.072 36.9372 173.896 37.2875C173.196 37.813 172.846 38.6888 173.196 39.5645C175.822 45.3448 178.447 51.3002 181.073 57.0804C182.823 60.9339 184.399 67.0644 187.374 70.3925C191.75 75.1217 195.776 69.3415 196.476 65.1377C198.051 57.0804 198.051 47.2715 192.625 40.4403Z" fill="black" />
        <path d="M193.85 57.7809C193.85 59.7077 192.8 70.3923 189.299 67.0643C186.499 64.4369 184.748 56.9051 182.998 53.402C180.898 48.6727 178.797 44.1185 176.697 39.3893C184.398 35.7109 191.05 42.367 192.975 49.5485C193.85 52.0007 193.85 54.9784 193.85 57.7809Z" fill="#2A2A8B" />
        <path d="M187.374 51.6501C187.199 51.2998 186.849 51.1246 186.499 50.9495C186.324 31.3317 167.42 18.7203 148.341 21.3477C125.237 24.5005 108.433 45.3444 105.108 67.5895C104.933 68.2902 105.283 68.8156 105.808 69.3411C105.983 70.2169 106.683 71.0927 107.908 70.5672C130.313 62.3348 155.518 68.9908 177.222 59.5322C168.995 67.4144 158.143 71.7933 146.416 72.1436C144.14 72.1436 144.14 75.6468 146.416 75.6468C157.793 75.2965 168.12 71.443 176.697 64.6118C172.496 77.7487 171.271 91.2359 173.021 105.249C173.196 106.475 174.421 107.526 175.647 106.825C194.55 94.3888 199.801 70.5672 187.374 51.6501Z" fill="black" />
        <path d="M108.959 66.3636C112.284 48.8478 123.662 32.7332 140.99 26.7778C159.894 20.1218 183.698 30.281 182.998 52.351C182.298 52.1758 181.598 52.351 181.248 53.2267C181.073 53.4019 181.073 53.7522 180.898 53.9274C159.019 66.3636 132.238 58.6567 108.959 66.3636Z" fill="black" />
        <path d="M176.171 101.921C174.771 87.2073 176.872 72.8442 182.298 59.1819C183.348 57.9558 184.398 56.7297 185.448 55.5035C185.448 55.5035 185.448 55.5035 185.623 55.5035C194.9 71.443 191.049 90.8856 176.171 101.921Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="290.923" height="384" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default GirlDeskFrame;
