/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SxProps, Theme } from '@mui/system';
import { palette  } from '../../theme/palette';

interface Props {
  // TODO - handle with correct type
  svg?: any;
  path?: any;
  sx?: SxProps<Theme> | undefined;
}

const ArrowDownIcon = (props: Props) => {
  let { path: pathProps, svg: svgProps } = props;

  // set default values
  if (!pathProps?.fill) {
    if (pathProps) {
      pathProps.fill = palette.customBlue.primaryBlue;
    } else {
      pathProps = { fill: palette.customBlue.primaryBlue };
    }
  }

  if (!svgProps?.fill) {
    if (svgProps) {
      svgProps.fill = palette.customBlue.primaryBlue;
    } else {
      svgProps = { fill: palette.customBlue.primaryBlue };
    }
  }

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="20"
      viewBox="0 0 15 20"
      sx={props.sx}
      {...svgProps ?? {}}
    >
      <path
        d="M0.762564 17.7374C0.0791463 17.054 0.0791463 15.946 0.762564 15.2626L6.52513 9.5L0.762563 3.73744C0.0791456 3.05402 0.0791456 1.94598 0.762563 1.26256C1.44598 0.579145 2.55402 0.579145 3.23744 1.26256L10.2374 8.26256C10.9209 8.94598 10.9209 10.054 10.2374 10.7374L3.23744 17.7374C2.55402 18.4209 1.44598 18.4209 0.762564 17.7374Z"
        {...pathProps ?? {}}
      />
    </SvgIcon>
  );
};
export default ArrowDownIcon;
