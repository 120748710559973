import React from 'react';
import { Box, Button, Dialog, TextField, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useCreateGroup from './create-group-hook';
import ListMenu from '../../../components/AppFilter/ListMenu';

interface Props {
  openDialog: boolean,
  closeDialog?: any,
  header: string,
  onSuccess?: Function,
}

const StylesCSS = (theme: Theme) => ({
  helpText: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(1.75),
  },
  inputStyle: {
    '& .MuiInputBase-input': {
      background: 'none',
      fontWeight: 'normal',
    },
  },
  disabled: {
    width: theme.spacing(17.5),
    background: theme.palette.customWhite.whiteSmoke,
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    '& .MuiInputBase-input': {
      background: theme.palette.customWhite.whiteSmoke,
      border: 0,
      cursor: 'not-allowed',
    },
  },
  saveButton: {
    marginTop: theme.spacing(3),
    width: theme.spacing(17.5),
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3),
    fontWeight: 700,
  },
});

const CreateGroup = ({
  openDialog,
  closeDialog,
  header,
  onSuccess,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const {
    groupName,
    handleGroupNameChange,
    addGroup,
    adding,
    sections,
    selectedSection,
    setSelectedSection,
    schools,
    setSelectedSchool,
  } = useCreateGroup();

  const handleSave = () => {
    addGroup(selectedSection).then(() => onSuccess?.());
  };

  const enabledSubmit = selectedSection && groupName;

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        closeDialog();
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="pageTitle">{header}</Typography>
      </Box>
      <Box sx={styles.helpText}>
        You can give assignments to a specific group
        {' '}
        <br />
        rather than the entire class.
      </Box>
      <Box mb={3}>
        <ListMenu
          menuTitle="Select a school"
          hideMenuTitle
          options={schools}
          keyAttribute="id"
          labelAttribute="name"
          onChange={(event) => {
            setSelectedSchool(event.target.value as string);
          }}
        />
      </Box>
      <Box>
        <ListMenu
          menuTitle="Select a class"
          hideMenuTitle
          options={sections}
          keyAttribute="id"
          labelAttribute="name"
          onChange={(event) => setSelectedSection(event.target.value as string)}
        />
      </Box>
      <Box>
        <Typography variant='sFormTitle' component='p'>Group Name</Typography>
        <TextField
          id="group-name"
          sx={styles.inputStyle}
          fullWidth
          value={groupName}
          onChange={((event: any) => handleGroupNameChange(event.target.value))}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          sx={styles.saveButton}
          variant="outlined"
          color="primary"
          onClick={() => closeDialog?.()}
        >
          Cancel
        </Button>
        <Button
          sx={{
            ...styles.disabled, ...(enabledSubmit && {
              ...styles.saveButton,
            }),
          }}
          variant="contained"
          color="primary"
          disabled={!enabledSubmit || adding}
          onClick={handleSave}
        >
          {adding ? 'Creating...' : 'Create'}
        </Button>
      </Box>
    </Dialog>
  );
};

export default CreateGroup;
