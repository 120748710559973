import { useEffect, useState } from 'react';
import {
  LearningGoalsByIndicator,
  useLearningGoalsByIndicatorWidgetQuery,
} from '../../../../generated/graphql';
import { GoalGraphData } from '../../../../variables/types';
import { AdminDashboards } from '../../../../variables/constant';
import { DashboardWidgetsDataStatusProps, useDashboardContext } from '../../admin-analytics-hook';

export interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
}

const useGoalsByIndicatorWidget = () => {

  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    analyticsFilters: queryFilters,
    updateDashboardWidgetsDataStatus,
  } = useDashboardContext();

  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasData, setHasData] = useState<boolean>(false);
  const [barGraphData, setBarGraphData] = useState<GoalGraphData[]>();

  var queryInput = {
    input: queryFilters,
  };
  const updateQueryInput = (data: any) => {
    queryInput = {
      ...queryInput,
      ...data,
    };
  };
  if (isSchoolAdmin && mySchoolData?.id) updateQueryInput({ 'school_id': mySchoolData?.id });
  if (isDistrictAdmin && myDistrictData?.id) updateQueryInput({ 'district_id': myDistrictData?.id });

  const { data, loading: goalsByIndicatorWidgetLoading } = useLearningGoalsByIndicatorWidgetQuery({
    fetchPolicy: 'no-cache',
    skip: skipGraphQLRequest(),
    variables: { ...queryInput  },
  });

  // RePopulate Bar performanceSkillData on performanceWidgetDataResp Refresh
  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: AdminDashboards.learningGoals.GoalsByIndicatorWidget.name,
      loading: goalsByIndicatorWidgetLoading,
      dataMissing: undefined,
    };
    if (!goalsByIndicatorWidgetLoading && !!data) {
      // generate a serialized goal bar graph data.
      const goalBarGraphData: GoalGraphData[] = [];
      data?.learningGoalsIndicatorData?.learningGoalsbyIndicator?.forEach((skillData: LearningGoalsByIndicator) => goalBarGraphData.push({
        skillLabel: skillData.skill_label,
        skillLinkText: '',
        color: skillData.color,
        numberOfGoals: skillData.goals_count,
        skillType: queryInput.input?.submission_score_type!,
        skillKey: skillData.skill_key,
        goals: [],
      }));
      setBarGraphData([ ...goalBarGraphData ]);
      //
      setTotalCount(data?.learningGoalsIndicatorData?.total_goals ?? 0);
      // update widget's data status in root component data status tracker
      dashboardWidgetStatus.dataMissing = !goalsByIndicatorWidgetLoading && !data?.learningGoalsIndicatorData?.total_goals;
    }
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalsByIndicatorWidgetLoading, data]);

  useEffect(() => {
    setHasData(totalCount > 0 && !!barGraphData);
  }, [totalCount, barGraphData]);

  return ({
    totalCount, setTotalCount,
    hasData, setHasData,
    barGraphData, setBarGraphData,
    goalsByIndicatorWidgetLoading,
    title: `${queryInput.input?.submission_score_type} Goals by Indicator`,
  });
};

export default useGoalsByIndicatorWidget;
