import React from 'react';
import List from '../Teachers/TeachersTable/List';
import useTeachers from '../Teachers/TeachersTable/teachers-hook';

interface Props {
  districtID?: string;
  schoolID?: string;
}
const TeachersList = ({ districtID, schoolID }: Props) => {
  const {
    teachers,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    rowsPerPage,
    setRowsPerPage,
  } = useTeachers({ districtID, schoolID });
  return (
    <List
      marginTop={0}
      sxProps={{ borderTopLeftRadius: '0px' }}
      isTableEmpty={isTableEmpty}
      loading={loading}
      totalCount={totalCount}
      order={order}
      pagination={pagination}
      sort={sort}
      teachers={teachers}
      handlePageChange={handlePageChange}
      handleSort={handleSort}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};

export default TeachersList;