import React from 'react';
import { TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledTableDataColumn } from '../../../Admin/admin-tables-styled';
import { BenchmarkScoresWidgetStylesCSS } from './BenchmarkScoresWidget.styled';
import toRound from '../../../../utils/toRound';
import { BenchmarkScoresLineItemFragmentFragment, TeacherBenchmarkSubmissionStatus } from '../../../../generated/graphql';
import { Link } from 'react-router-dom';
import { truncateString } from '../../../../utils/truncateString';
import { trackEvent } from '../../../../utils/trackevent';

interface Props {
  rowIndex: number,
  data: BenchmarkScoresLineItemFragmentFragment,
}

const ListRow = ({
  rowIndex,
  data,
}: Props) => {
  const theme = useTheme();
  const styles = BenchmarkScoresWidgetStylesCSS(theme);
  const getTagStyleClass = (score: number | string) => {
    if (0 < score && score <= 1.4) return styles.tagRed;
    if (1.5 <= score && score <= 2.4) return styles.tagYellow;
    if (2.5 <= score && score <= 3.4) return styles.tagGreen;
    if (3.5 <= score && score <= 4.4) return styles.tagBlue;
    if (4.5 <= score && score <= 5) return styles.tagPurple;
    return styles.tagDisabled;
  };

  const avgSpeakingScore = data?.speaking_score_average !== 0 ? toRound(data?.speaking_score_average as number, 1) : '-';
  const avgWritingScore = data?.writing_score_average !== 0 ?  toRound(data?.writing_score_average as number, 1) : '-';
  const isReviewedBenchmark = data?.submission_status as string === TeacherBenchmarkSubmissionStatus.Reviewed;
    
  return (
    <TableRow key={rowIndex} sx={{ ...styles.tableRowHover, ...(rowIndex % 2 !== 0 && styles.tableRowEven), ...styles.tableRowHeight }}>
      <StyledTableDataColumn sx={{ ...styles.width5, ...styles.tableCellTextAlignLeft, cursor: 'default' }}>
        <Typography
          variant='redirectionLinks'
          sx={{ color: isReviewedBenchmark ? theme.palette.customBlue.primaryBlue : theme.palette.customBlue.chipDark }}
        >
          {data?.benchmark_type?.toUpperCase()}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width65, ...styles.tableCellTextAlignLeft }}>
        {isReviewedBenchmark ? (<Link to={`/tasks/${data?.submission_id}/benchmark/score/Speaking?prevpage=individual`} style={{ textDecoration: 'none' }} onClick={() => { trackEvent('bs_sub_link', 'benchmark_score_submission_link'); }} >
          <Tooltip title={data?.benchmark_title} placement='top'><Typography variant='redirectionLinks' noWrap>
            {truncateString(data?.benchmark_title, 22)}
          </Typography>
          </Tooltip>
        </Link>) : (<Tooltip title={data?.benchmark_title} placement='top'>
          <Typography variant='redirectionLinks'
            sx={{ color: theme.palette.customBlue.chipDark, cursor: 'default' }} noWrap>
            {truncateString(data?.benchmark_title, 22)}
          </Typography>
        </Tooltip>)}
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width15, cursor: 'default' }}>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getTagStyleClass?.(avgSpeakingScore) }}
        >
          { avgSpeakingScore  }
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width15, cursor: 'default' }}>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getTagStyleClass?.(avgWritingScore) }}
        >
          { avgWritingScore }
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default ListRow;
