import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DialogTitleTypography } from '../../TeacherBenchmarks/Grade/BenchmarkDownload.styled';
import ListenIcon from '../../../components/Icons/ListenIcon';
import AudioPlayer from '../../../components/Player';
import {
  File as APIFIle,

} from '../../../generated/graphql';
type Props = {
  openModal: boolean,
  handleClose: () => void,
  handleNext: () => void,
  speakings: Array<{ __typename?: 'File' } & Pick<APIFIle, 'url' | 'file_type'>>;
};
const SpeakingModal = ({ openModal, handleClose, handleNext, speakings }: Props) => {
  const [listenFlags, setListenFlags] = useState<any[]>([]);
  const toEnableOrDisablePlay = (url: string) => {
    const flag = listenFlags?.find(file =>
      file.url === url,
    )?.disablePlaying;
    return flag;
  };

  useEffect(() => {
    setListenFlags(speakings.map((speaking) => { return { ...speaking, listen: false, disablePlaying: false }; }));
  }, [speakings]);


  const handleModalClose = () => {
    setListenFlags(speakings.map((speaking) => { return { ...speaking, listen: false }; }));
    handleClose();
  };

  return (
    <Dialog open={openModal} onClose={handleModalClose} sx={{ '.MuiPaper-root.MuiDialog-paper': { overflow: 'hidden ', margin: '32px', maxWidth: '1000px', width: '720px' } }}>
      <DialogTitle sx={{}}>

        <Box display="flex" justifyContent="center" alignItems="center" width="100%" mb={2}>
          <ListenIcon />
        </Box >
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" sx={{ marginTop: '8px' }}>
          <DialogTitleTypography>Did you listen to your speaking?</DialogTitleTypography>
        </Box>

      </DialogTitle>
      <DialogContent>
        {
          speakings?.map((recording, index) => (
            <AudioPlayer
              showLabel
              label={`Recording ${index + 1}`}
              url={recording.url}
              fileType={recording.file_type}
              submitTaskPlayer={true}
              setListenFlags={setListenFlags}
              listenFlags={listenFlags}
              disablePlay={toEnableOrDisablePlay(recording.url)}
            />
          ))
        }
      </DialogContent>
      <DialogActions style={{ justifyContent: 'end' }} >
        {speakings?.length < 3 && (<Button onClick={handleModalClose} variant={'outlined'} >
          Go Back & Record Again
        </Button>)}
        <Button onClick={handleNext} variant={'contained'} >
          Go to Writing
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SpeakingModal;
