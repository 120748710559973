/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DashboardIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    {...props}
  >
    <g id="icon / nav open">
      <path d="M21.75 0.5H2.25C1.00734 0.5 0 1.50734 0 2.75V19.25C0 20.4927 1.00734 21.5 2.25 21.5H21.75C22.9927 21.5 24 20.4927 24 19.25V2.75C24 1.50734 22.9927 0.5 21.75 0.5ZM10.875 19.25H2.53125C2.45666 19.25 2.38512 19.2204 2.33238 19.1676C2.27963 19.1149 2.25 19.0433 2.25 18.9688V12.875H10.875V19.25ZM10.875 10.625H2.25V4.25H10.875V10.625ZM21.4688 19.25H13.125V12.875H21.75V18.9688C21.75 19.0433 21.7204 19.1149 21.6676 19.1676C21.6149 19.2204 21.5433 19.25 21.4688 19.25ZM21.75 10.625H13.125V4.25H21.75V10.625Z" />
    </g>
  </SvgIcon>
);

export default DashboardIcon;
