import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { isIOS } from 'react-device-detect';

interface Props {
  handleBack?: Function,
  disabled?: boolean,
  backBtnLabel?: string,
}

const styles = {
  backBtn: {
    width: 160,
  },
  backBtnIOS: {
    width: 120,
  },
};

const BackButton = ({ handleBack, disabled = false, backBtnLabel } : Props) => {
  const history = useHistory();
  return (
    <Button
      sx={{ ...(isIOS ? styles.backBtnIOS : styles.backBtn) }}
      variant="outlined"
      onClick={() => {
        if (handleBack) {
          handleBack();
        } else {
          history.goBack();
        }
      }}
      disabled={disabled}
    >
      { backBtnLabel ?? 'BACK'}
    </Button>
  );
};

export default BackButton;
