/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Drawer from '@mui/material/Drawer';

interface Props {
  error?: boolean;
  open: boolean;
  onClose: () => void;
  size: 'large' | 'medium' | 'small';
  children: React.ReactNode;
}

const FlyoverDrawer = ({ open, onClose, size, children }: Props) => {
  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          ...(size === 'large' ? { width: '1100px' } : {}),
          ...(size === 'medium' ? { width: '680px' } : {}),
          ...(size === 'small' ? { width: '450px' } : {}),
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default FlyoverDrawer;
