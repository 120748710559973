import React from 'react';
import {
  PrimaryDetailBox,
  StyledBoxContainer,
  StyledDescriptionBox,
  StyledImageBox,
  StyledViewButton,
} from './PTTtile.styled';
import { toReadableFormat } from '../../utils/dateFormat';
import { SxProps, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import { capIconStyles, StyledIconContainer } from '../../pages/StudentDashboard/StudentDashboard.styled';
import GradeCard from '../GradeCard';
import BenchmarkIcon from '../Icons/BenchmarkIcon';
import { READABLE_DATE_OPTIONS_YEAR } from '../../variables/constant';

interface Props {
  imageSrc?: string,
  ptTitle?: string,
  teacherName?: string | string[],
  assignmentDate?: string,
  submissionId?: string,
  handleRedirect: Function,
  submissionStatus?: string,
  submittedDate?: string,
  thumbnailImgSrc?: string,
  isBenchmark?: boolean,
}

const PTTile = ({ imageSrc, ptTitle, teacherName, assignmentDate, handleRedirect, submissionId, submissionStatus, submittedDate, isBenchmark = false, thumbnailImgSrc }: Props) => {
  const formattedAssignmentDate = assignmentDate ? toReadableFormat(assignmentDate, READABLE_DATE_OPTIONS_YEAR) : '';
  const formattedSubmittedDate = submittedDate ? toReadableFormat(submittedDate, READABLE_DATE_OPTIONS_YEAR) : '';
  return (
    <StyledBoxContainer>
      <StyledImageBox>
        <GradeCard
          imageSrc={imageSrc || ''}
          thumnailImageSrc={thumbnailImgSrc || ''}
          cardTitle={''}
        />
        {isBenchmark && <StyledIconContainer sx={{  ...capIconStyles.iconWrapperBackpack, ...capIconStyles.iconPosition } as SxProps}>
          <BenchmarkIcon pathfill={palette.customWhite.main} sx={{ ...capIconStyles.iconDimensions }}/>
        </StyledIconContainer>}
      </StyledImageBox>
      <StyledDescriptionBox>
        <PrimaryDetailBox>
          <Typography variant='sContentHeader'>{ptTitle}</Typography>
          <Typography variant='secondaryLabel' sx={{ color: palette.fontColors.fontBlack }}>{teacherName}</Typography>
          <Typography variant='secondaryLabel' sx={{ color: palette.fontColors.fontBlack }}>
            {(submissionStatus === 'Reviewed' && submittedDate) ? `Submitted at ${formattedSubmittedDate}` : (submissionStatus === 'Submitted' ? 'Review Pending' : `Due by ${formattedAssignmentDate}`)}
          </Typography>
        </PrimaryDetailBox>
        <StyledViewButton
          size="large"
          variant="outlined"
          disabled={submissionStatus !== 'Reviewed'}
          onClick={
            handleRedirect(
              submissionId,
            )
          }
        >
          View
        </StyledViewButton>
      </StyledDescriptionBox>
    </StyledBoxContainer>
  );
};

export default React.memo(PTTile);
