import React from 'react';
import {
  Box,
  Tooltip, Typography,
} from '@mui/material';
import {
  LoginContainer,
} from './LoginWrapper.styled';
import FlashlightIcon from '../Icons/FlashlightLogo';
import InfoIcon from '../Icons/InfoIcon';
import { palette } from '../../theme/palette';

interface Props {
  children: React.ReactNode,
  image: React.ReactNode,
  title?: string,
  text?: string,
  frameText?: string,
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  showInfoTooltip?: boolean,
  tooltipText?: string,
  width?: string,
}


const LoginWrapper = ({ children, title, text, frameText, onClick, showInfoTooltip, tooltipText, width }: Props) => {

  return (
    <LoginContainer>
      <Box
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        position="relative"
        p={5}
        sx={{
          backgroundColor: palette.customWhite.main,
          borderRadius: '12px',
        }}
        width={width || 'auto'}
      >
        <FlashlightIcon
          sx={{
            width: '18rem',
            height: '4rem',
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          mt={5}
        >
          {title && (
            <Box sx={{ display: 'flex', width: '400px', maxWidth: '100%' }}>
              <Typography sx={{ color: palette.customBlack.fontBlack, fontSize: '32px', fontWeight: 'bold', wordBreak: 'break-word', }}>
                {title}
              </Typography>
            </Box>
          )}
          {frameText && (
            <Typography sx={{ color: palette.customBlue.flashlightBlue, fontSize: '16px', fontWeight: 700, lineHeight: '22px', cursor: 'pointer' }} onClick={onClick}>
              {frameText}
            </Typography>
          )}
          {text && (
            <Box sx={{
              display: 'flex',
              alignItems: 'baseline',
            }}>
              <Typography sx={{ color: palette.customBlack.fontBlack, fontSize: '22px', lineHeight: '30px', fontWeight: 700, mt: '30px' }}>
                {text}
              </Typography>
              {showInfoTooltip && (
                <Tooltip
                  title={tooltipText || ''}
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={15000}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'baseline', ml: '16px',
                  }} >
                    <InfoIcon />
                  </Box>
                </Tooltip>
              )}
            </Box>
          )}
          <Box
            mt={2}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </LoginContainer>
  );
};

export default LoginWrapper;
