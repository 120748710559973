import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType, SizeMetrics } from '../../../variables/types';
import gradeSort from '../../Assignments/CreateAssignment/grade-sort';
import { useParams } from 'react-router-dom';
import {
  useEditAdminResourceMutation,
  useDeleteAdminResourceMutation,
  useStaticResourceDetailsQuery,
  useStaticResourcesQuery,
  useSubjectAreasQuery,
  UpdateAdminResourceInput,
  StaticResourceType,
} from '../../../generated/graphql';
import useRedirect from '../../../hooks/useRedirect';
import { useDropzone } from 'react-dropzone';
import { checkFileSize } from '../../../utils/files';
import { IMAGE_RES_ERROR, THUMBNAIL_MAX_FILE_SIZE_ERROR, THUMBNAIL_MAX_FILE_SIZE_KB } from '../../../variables/constant';
import getBrowserImageDimensions from 'browser-image-dimensions';
import { getToken } from '../../../utils/auth';
import { useUploader } from '../../../utils/multipart-uploader';
import useThumbnailUtil, { ThumbnailGenInputType } from '../../../utils/useThumbnailUtil';
import { sentryErrorLog } from '../../../utils/sentry';


interface ImageManageFormData {
  title: string,
  grades: string[],
  subject_areas: string[],
  task_types: string[],
  keywords: string[],
  thumbnailFile?: HTMLInputElement,
  resourceType: StaticResourceType,
}

interface RouteProp {
  id: string
}

const useManageImage =  () => {
  const { navigateTo } = useRedirect();
  const { id } = useParams<RouteProp>();
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
  } = useForm<ImageManageFormData>();
  const [isWarningEnabled, setWarning] = useState(false);
  const routePathEdit = window.location.href.indexOf('benchmarks') > -1;
  const openWarning = () => { setWarning(true); };
  const closeWarning = () => { setWarning(false); };
  const [editAdminResource, { loading }] = useEditAdminResourceMutation();
  const [deleteAdminResource, { loading: loadingDelete }] = useDeleteAdminResourceMutation();

  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [showThumbnailWarning, setShowThumbnailWarning] = useState('');
  const [openThumbnail, setOpenThumbnail] = useState(false);

  // Thumbnail Handlers
  const { getRootProps: getThumbnailRootProps, getInputProps: getThumbnailInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      setThumbnailFile(acceptedFiles.map((file: any) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
    },
  });
  const removeThumbnail = (file: any) => {
    const newFiles = [...thumbnailFile];
    newFiles.splice(file, 1);
    setThumbnailFile(newFiles);
    setShowThumbnailWarning('');
  };
  const handleOpenThumbnail = () => {
    setOpenThumbnail(!openThumbnail);
  };
  // Thumbnail Generator
  const { thumbnailGenAsync, isLoading: thumbnailGenAsyncLoading } = useThumbnailUtil({
    // @ts-ignore
    thumbnailUrl: `${import.meta.env.REACT_APP_API_URL}/file/thumbnail`,
    accessToken: getToken()?.idToken?.jwtToken as string,
  });

  // File Uploader
  const { uploadAsync, isLoading: isUploading } = useUploader({
    // @ts-ignore
    uploadUrl: `${import.meta.env.REACT_APP_API_URL}/file/upload`,
    accessToken: getToken()?.idToken?.jwtToken,
  });

  const { data: subjectAreas, loading: subjectsLoading } = useSubjectAreasQuery({
    fetchPolicy: 'network-only',
  });

  const { data: staticResourcesImageData, loading: imagesLoading } = useStaticResourcesQuery({
    fetchPolicy: 'network-only',
    variables:  {
      resourceType: routePathEdit ? StaticResourceType.Benchmark : StaticResourceType.Regular,
    },
  });

  const { data: resourceDetails, loading: resourceDetailsLoading } = useStaticResourceDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id!,
    },
    skip: !id,
  });

  useEffect(() => {
    if (resourceDetails) {
      const resource = resourceDetails.staticResource;
      reset({
        title: resource.title,
        grades: resource.grade,
        subject_areas: resource.subject_areas,
        task_types: resource.task_types,
        keywords: resource.keywords,
      });
    }
  }, [id, resourceDetails]);

  const setGrades = gradeSort(staticResourcesImageData);

  const handleFileSizeError = (errMsg: string = IMAGE_RES_ERROR) => {
    openSnackbar({
      message: errMsg,
    }, NotifierType.Error);
  };

  const onSaveResource = async (data: ImageManageFormData) => {
    try {
      // validate thumbnail image before any upload action.
      if (thumbnailFile.length && !checkFileSize(SizeMetrics.KB, THUMBNAIL_MAX_FILE_SIZE_KB, thumbnailFile?.[0] as File)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw THUMBNAIL_MAX_FILE_SIZE_ERROR;
      }
      let updateStaticResourceInput: UpdateAdminResourceInput = {
        id,
        title: data.title,
        grades: data.grades,
        subject_areas: data?.subject_areas,
        task_types: data?.task_types,
        keywords: data?.keywords,
        thumbnail_height: resourceDetails?.staticResource?.thumbnail_height,
        thumbnail_width: resourceDetails?.staticResource?.thumbnail_width,
        resourceType: routePathEdit ? StaticResourceType.Benchmark : StaticResourceType.Regular,
        file: {
          thumbnail_url: resourceDetails?.staticResource?.file?.thumbnail_url,
          thumbnail_file_key: resourceDetails?.staticResource?.file?.thumbnail_file_key,
          thumbnail_file_type: resourceDetails?.staticResource?.file?.thumbnail_file_type,
        },
      };
      // thumbnail image upload
      if (thumbnailFile.length) {
        const thumbnailDetails: any = await getBrowserImageDimensions(thumbnailFile?.[0]);
        if (thumbnailDetails) {
          updateStaticResourceInput.thumbnail_height = thumbnailDetails?.height as number ?? 0;
          updateStaticResourceInput.thumbnail_width = thumbnailDetails?.width as number ?? 0;
        }
        const thumbnailResult = await uploadAsync(thumbnailFile?.[0] as File);
        updateStaticResourceInput = {
          ...updateStaticResourceInput,
          file: {
            thumbnail_url: thumbnailResult?.url,
            thumbnail_file_key: thumbnailResult?.key,
            // @ts-ignore
            thumbnail_file_type: thumbnailFile?.[0]?.type ?? '',
          },
        };
      } else if (resourceDetails?.staticResource?.file?.url && !resourceDetails?.staticResource?.file?.thumbnail_url) {
        const thumbnailGeneratorInput: ThumbnailGenInputType = {
          url: resourceDetails?.staticResource?.file?.url,
          file_name: resourceDetails?.staticResource?.file?.file_key,
          file_type: resourceDetails?.staticResource?.file?.file_type,
        };
        const thumbnailResult = await thumbnailGenAsync(thumbnailGeneratorInput);
        updateStaticResourceInput = {
          ...updateStaticResourceInput,
          file: {
            thumbnail_url: thumbnailResult?.url,
            thumbnail_file_key: thumbnailResult?.file_key,
            thumbnail_file_type: thumbnailResult?.file_type,
          },
        };
      }
      const response =  await editAdminResource({
        variables: {
          input: {
            ...updateStaticResourceInput,
          },
        },
      });
      if (response.data){
        openSnackbar({ message: 'Edit successful' }, NotifierType.Success);
        navigateTo(routePathEdit ? '/benchmarks/upload' : '/settings/upload-images');
      }
    } catch (err){
      sentryErrorLog(err);
      if (err === THUMBNAIL_MAX_FILE_SIZE_ERROR) {
        handleFileSizeError(THUMBNAIL_MAX_FILE_SIZE_ERROR);
      } else {
        openSnackbar({ message: 'Edit not successful' }, NotifierType.Error);
      }
    }
  };

  const onDeleteResource = async () => {
    try {
      await deleteAdminResource({
        variables:{
          input: id,
        },
      });
      openSnackbar({ message: 'Image deleted successfully' }, NotifierType.Success);
      navigateTo(routePathEdit ? '/benchmarks/upload' : '/settings/upload-images');
    } catch (err) {
      openSnackbar({ message: 'Could not delete image' }, NotifierType.Error);
    }
  };

  const onCancel = () => {
    navigateTo(routePathEdit ? '/benchmarks/upload' : '/settings/upload-images');
  };

  return {
    onSaveResource,
    onDeleteResource,
    onCancel,
    staticResourcesImageData,
    setGrades,
    loading: loading || imagesLoading || resourceDetailsLoading || loadingDelete || isUploading || thumbnailGenAsyncLoading,
    register,
    errors,
    handleSubmit,
    control,
    getValues,
    watch,
    subjectAreas: subjectAreas?.subjectArea,
    subjectsLoading,
    resource: resourceDetails?.staticResource,
    isWarningEnabled,
    openWarning,
    closeWarning,
    thumbnailFile, setThumbnailFile,
    showThumbnailWarning, setShowThumbnailWarning,
    openThumbnail, setOpenThumbnail, handleOpenThumbnail,
    getThumbnailRootProps, getThumbnailInputProps,
    removeThumbnail,
  };
};

export default useManageImage;
