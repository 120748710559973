import * as React from 'react';
import DropdownButton, { Action } from '../DropdownButton';
import { palette } from '../../theme/palette';


const MoreActions = ({ handleSync, handleDecouple, handleDelete, deleteDistrictShow, decoupleDistrictShow, syncShow, disabled }: any) => {
  const getActionItems  = () => {
    const actionItems = [];
    if (syncShow) {
      actionItems.push({ label: 'Sync Now', value: 'sync' });
    }
    if (decoupleDistrictShow) {
      actionItems.push({ label: 'Decouple', value: 'decouple' });
    }
    if (deleteDistrictShow) {
      actionItems.push({ label: 'Delete District', value: 'delete', color: palette.customRed.darkRed });
    }
    return actionItems;
  };


  const handleButtonClick = (action: Action)=>{
    switch (action.value) {
      case 'sync': handleSync(); break;
      case 'decouple': handleDecouple(); break;
      case 'delete': handleDelete(); break;
    }
  };

  return (
    <DropdownButton
      label={'More'}
      actionItems={getActionItems()}
      handleClick={handleButtonClick}
      disabled={disabled}
      closeOnSelect={true}
    />
  );
};

export default MoreActions;
