import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Grid, Typography, SxProps, Avatar, Accordion, AccordionSummary, AccordionDetails, AccordionActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Loader from '../../../components/Loader/loader';
import FormProgressBar from './../../../components/FormProgressBar';
import Details from './Steps/details';
import ManageStudents from './Steps/ManageStudents';
import Summary from './Steps/Summary';
import { CreateClassStylesCss } from './CreateClass.styled';

import { StepFormNavigation } from '../../../variables/types';
import { CreateClassSteps } from './../../../variables/constant';
import useCreateClass from './create-class-hook';
import WarningWindow from '../../../components/WarningWindow';

const ClassForm = () => {
  const theme = useTheme();
  const styles = CreateClassStylesCss(theme);
  const {
    loading,
    routeData,
    setSubmitType, submitStep,
    setAddAnotherClass,
    blockNavigation, setBlockNavigation,
    noStudentWarning, setNoStudentWarning, handleNextOnStep2, backToDashboard,
  } = useCreateClass();

  const {
    handleSubmit,
  } = useFormContext();

  const handleFormNavButton = (navType: StepFormNavigation) => {
    setSubmitType(navType);
    setTimeout(() => {
      const classForm = document?.getElementById('class-form') as HTMLFormElement;
      classForm.requestSubmit();
    }, 50);
  };

  return (
    <>
      {routeData?.step === 'summary' && <Loader open={loading} />}
      <Box sx={styles.formatTopNavBar as SxProps}>
        <Grid container justifyContent="space-between">
          <Grid item xs={3}>
            <Typography variant='secondaryTitle'>{routeData?.action} Class</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormProgressBar
              steps={CreateClassSteps}
              progressValue={routeData?.currentStep! - 1}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.formContainer as SxProps}>
        <Box sx={{ p: 2.5, paddingTop: 1.5, paddingRight: 4 } as SxProps}>
          <form
            id="class-form"
            name="class-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(submitStep)}
          >
            {CreateClassSteps.map((step, _) => {
              return (
                <Accordion sx={styles.formStepPanel as SxProps} expanded={routeData?.step === step.name}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${step.name}-content`}
                    id={`${step.name}-header`}
                    sx={{
                      '.MuiAccordionSummary-content': { m: 0 },
                    } as SxProps}
                  >
                    <span style={{ alignContent: 'center' }}>
                      <Avatar sx={styles.formStepPanelHeaderStepIcon as SxProps}>{step.step}</Avatar>
                    </span>
                    <Typography variant='font24' style={{ textTransform: 'capitalize' }}>
                      {step.step === 1 ? `${routeData?.action} ${step.label}` : `${step.label}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingTop: 0 } as SxProps} >
                    {routeData?.step === 'details' && <Details />}
                    {routeData?.step === 'manage-students' && <ManageStudents />}
                    {routeData?.step === 'summary' && <Summary />}
                  </AccordionDetails>
                  <AccordionActions sx={styles.formStepPanelFooter as SxProps}>

                    {routeData?.step === 'details' ? <Button
                      variant="outlined"
                      color="primary"
                      sx={{ px: 2 } as SxProps}
                      onClick={() => setBlockNavigation(true)}
                    >
                          BACK TO DASHBOARD
                    </Button> : <Button
                      variant="outlined"
                      color="primary"
                      sx={{ px: 2 } as SxProps}
                      onClick={() => handleFormNavButton(StepFormNavigation.Back)}
                    >
                      BACK
                    </Button>}
                    <Box>
                      {routeData?.step === 'summary' && routeData?.action !== 'edit' &&
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setAddAnotherClass(true);
                            handleFormNavButton(StepFormNavigation.Next);
                          }}
                          sx={{ px: 2, marginRight: 2 } as SxProps}
                        >
                          ADD ANOTHER CLASS
                        </Button>}

                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ px: 2 } as SxProps}
                        onClick={() => handleFormNavButton(StepFormNavigation.Next)}
                      >
                        {routeData?.step === 'summary' ? 'CONFIRM' : 'NEXT'}
                      </Button>
                    </Box>
                  </AccordionActions>
                </Accordion>
              );
            })}
          </form>
        </Box>
      </Box>
      <WarningWindow
        openDialog={blockNavigation}
        closeDialog={() => setBlockNavigation(false)}
        header="Are you sure you want to go?"
        subheader={'Change made while creating a class will not be saved if you navigate away from this page. Are you sure you want to leave?'}
        actions={[{
          title: 'Yes, Leave Page',
          event: () => {
            setBlockNavigation(false);
            backToDashboard();
          },
          classname: 'continueNavigation',
        }, {
          title: 'No, Stay on Page',
          event: () => setBlockNavigation(false),
          classname: 'cancelNavigation',
        }]}
        navigateWarning={true}
      />
      <WarningWindow
        openDialog={noStudentWarning}
        closeDialog={() => setNoStudentWarning(false)}
        header="Are you sure?"
        subheader={'Are you sure you want to resume without adding students? You may still be able to create your class, but there will be no students to assign tasks to.'}
        actions={[{
          title: 'Skip this step',
          event: () => {
            handleNextOnStep2();
          },
          classname: 'continueNavigation',
        }, {
          title: 'Add Students',
          event: () => setNoStudentWarning(false),
          classname: 'cancelNavigation',
        }]}
        navigateWarning={true}
      />
    </>
  );

};

export default ClassForm;
