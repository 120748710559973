import { useEffect } from 'react';
import { OverallPerformanceWidgetInput, useOverallPerformanceWidgetQuery } from '../../../../generated/graphql';
import { AdminDashboards } from '../../../../variables/constant';
import { DashboardWidgetsDataStatusProps, useDashboardContext } from '../../admin-analytics-hook';


const useOverallPerformanceInfo = () => {

  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
    analyticsFilters: queryFilters,
    sessionStartDate, sessionEndDate,
    startDateSelected, endDateSelected,
  } = useDashboardContext();

  const queryInput: OverallPerformanceWidgetInput = {
    filters: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.schoolID = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData?.id) queryInput.districtID = myDistrictData?.id;

  const { data: overallPerformanceData, loading: overallPerformanceDataLoading, error: overallPerformanceDataError } = useOverallPerformanceWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });

  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: AdminDashboards.performance.overallPerformance.name,
      loading: overallPerformanceDataLoading,
      dataMissing: undefined,
    };
    if (!overallPerformanceDataLoading && !!overallPerformanceData?.overallPerformanceInfo) {
      dashboardWidgetStatus.dataMissing = !overallPerformanceData?.overallPerformanceInfo?.historical_score?.length;
    }
    if (!overallPerformanceDataLoading && overallPerformanceDataError) {
      dashboardWidgetStatus.dataMissing = true;
    }
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallPerformanceDataLoading, overallPerformanceData, overallPerformanceDataError]);

  return ({
    overallPerformanceDataLoading, overallPerformanceData: overallPerformanceData?.overallPerformanceInfo, overallPerformanceDataError,
    title: `Overall ${queryInput.filters?.submission_score_type} Performance`,
    sessionStartDate, sessionEndDate,
    currStartDate: startDateSelected, currEndDate: endDateSelected,
  });
};

export default useOverallPerformanceInfo;