import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledEmptyTag = styled(Box)(({ theme: { palette } }) => ({
  backgroundColor: palette.customBackground.tagBackground,
  border: `1px solid ${palette.fontColors.fontBlack}`,
  borderRadius: '50%',
  color: palette.customWhite.main,
  height: 20,
  width: 20,
}));

export default StyledEmptyTag;
