/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SuccessIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <svg width={props?.iconWidth ?? '24'} height={props?.iconHeight ?? '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#43D46A" />
      <g clipPath="url(#clip0)">
        <path d="M16.2149 7.95542L10.0498 14.1205L7.78453 11.8552C7.6747 11.7454 7.49662 11.7454 7.38677 11.8552L6.72387 12.5181C6.61404 12.628 6.61404 12.806 6.72387 12.9159L9.85092 16.0429C9.96075 16.1528 10.1388 16.1528 10.2487 16.0429L17.2755 9.01608C17.3854 8.90625 17.3854 8.72818 17.2755 8.61832L16.6126 7.95542C16.5028 7.84559 16.3247 7.84559 16.2149 7.95542Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" transform="translate(5.99976 6)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default SuccessIcon;
