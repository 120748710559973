import React from 'react';
import { Box, Button, Checkbox, Grid, SxProps, TextField, Theme, Typography } from '@mui/material';
import Loader from '../../../components/Loader/loader';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { cleanDate } from '../../../utils/dateFormat';
import CalendarIcon from '../../../components/Icons/CalendarIcon';
import { addDays, isAfter, isBefore, isValid, max } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import { CreateAssignmentStylesCss } from '../../Assignments/CreateAssignment/CreateAssignment.styled';
import { StyledNextButton } from '../../SubmitTask/Common/SubmitTask.styled';
import useBenchmarksDetail from './benchmark-detail-hook';
import BackIcon from '../../../components/Icons/BackIcon';
import { Link } from 'react-router-dom';
import { palette } from '../../../theme/palette';
import Layout from '../../../components/Layout';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import AssignedToItems from './AssignedToItems';
import BenchmarkIcon from '../../../components/Icons/BenchmarkIcon';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import { BenchmarkTypeOptions } from '../Create/Steps/DetailsStep';
import AssignmentNavigationModal from '../../Assignments/CreateAssignment/Steps/AssignmentNavigationModal';

interface DateSelectorProp {
  onChange: (date: Date | null, keyboardString?: string | undefined) => void,
  value: Date | null,
}

const dateIconStyle = {
  customBox:{
    '& .MuiButtonBase-root.MuiIconButton-root':{
      marginRight: '0px !important',
    },
  },
};

const navigateBackStyles = {
  backButton:{
    borderWidth: 0,
    borderRadius: 0,
    fontSize: 16,
    color: palette.customBlue.primaryBlue,
    textTransform: 'none',
    disableRipple: true,
    textDecoration: 'none',
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent !important',
    },
    '&:active': {
      borderWidth: 0,
      borderRadius: 0,
    },
    '&::after': {
      display: 'none',
    },
  },
};

const boxStyle = {
  box: {
    background: palette.customGrey.main,
    border:'8px',
    borderRadius:'12px',
    padding: '20px',
  },
};

const Edit = () => {
  const today = new Date();
  const {
    benchmarks,
    loading,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    edit,
    navigateTo,
    academicSessionEndDate,
    openNavigationModal,
    setOpenNavigationModal,
  } = useBenchmarksDetail();
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);


  const pluralCount = (item: string, count: string[] | undefined | null) =>{
    return count?.length! > 1 ? item + 's' : item;
  };

  const startAt = watch('start_at', cleanDate(benchmarks?.start_at));
  const closeAt = watch('close_at', cleanDate(benchmarks?.close_at));

  const currDate = new Date();

  const isPastBenchmark = new Date() > cleanDate(benchmarks?.close_at);

  return (
    <Layout>
      <Typography variant='secondaryTitle' component='p'>Edit Benchmark</Typography>
      <Button
        startIcon={<BackIcon sx={{ fontSize: '16px !important' }}/>}
        to={'/benchmarks'}
        component={Link}
        sx={{ ...navigateBackStyles.backButton as SxProps }}
      >
        Back to Benchmarks
      </Button>
      {!loading && <Box display='flex' sx={{ ...boxStyle.box }} >
        <Box display='flex' flexDirection='column' mt={2} flex={1}>
          <Box m={1}>
            <Typography variant='sFormTitle'>Benchmark Title</Typography>
            <Typography>{benchmarks?.title}</Typography>
          </Box>
          <Box m={1}>
            <Typography variant='sFormTitle'>Benchmark Type</Typography>
            <Typography>
              {
                BenchmarkTypeOptions.find((benchmarkType) => benchmarkType.value === benchmarks?.benchmark_type?.toString())?.label ?? ''
              }
            </Typography>
          </Box>
          <Box m={1}>
            <Typography variant='sFormTitle'>Graded by</Typography>
            <Typography>{benchmarks?.graded_by == 'teacher' ? 'Teacher' : 'Flashlight 360'}</Typography>
          </Box>
          <Box m={1}>
            <Checkbox
              checked={ benchmarks?.back_navigation_allowed}
              disabled
              sx={{ pl: 0 }}
            />
            <Typography variant='sFormTitle' sx={{ verticalAlign: 'middle' }}>Allow students to go back during the assignment</Typography>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' flex={1}>
          <Typography variant='sFormTitle'>Assigned To</Typography>
          <Box m={1}>
            <AssignedToItems
              Icon={DistrictsIcon}
              label={pluralCount('District', benchmarks?.district_names)}
              values={`${benchmarks?.district_names.map(district => ` ${district}`)}`}
            />
          </Box>
          <Box m={1}>
            <AssignedToItems
              Icon={BenchmarkIcon}
              label={pluralCount('Grade', benchmarks?.grade_titles)}
              values={benchmarks?.grade_titles?.length ? `${pluralCount('Grade', benchmarks?.grade_titles)}(${benchmarks?.grade_titles.map(grade => ` ${grade}`)} )` : '-'}
            />
          </Box>
          <Box m={1}>
            <AssignedToItems
              Icon={SchoolsIcon}
              label={pluralCount('School', benchmarks?.school_names)}
              values={benchmarks?.school_names?.length ? `${benchmarks?.school_names.map(school => ` ${school}`)}` : '-'}
            />
          </Box>
        </Box>
      </Box>
      }

      {!loading && (
        <>
          <Box mt={3} ml={1}>
            <Typography variant='sFormTitle'>Date Range</Typography>
            <Typography>This is when the assignment will be available to students</Typography>
          </Box>
          <form
            name="edit-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(edit)}
          >
            <Grid container item mt={2}>
              <Grid item xs={2} sx={{ ...dateIconStyle.customBox }}>
                <Controller
                  name="start_at"
                  defaultValue={startAt}
                  value={startAt}
                  render={({ onChange, value }: DateSelectorProp) => (
                    <DatePicker
                      views={['day']}
                      renderInput={(params:any) => <TextField {...params} helperText={null} sx={styles.datepickerInput}/>}
                      minDate={isBefore(startAt, currDate) ? startAt : currDate}
                      maxDate={cleanDate(academicSessionEndDate)}
                      disabled={benchmarks?.is_submissions_started || isPastBenchmark}
                      onChange={onChange}
                      value={cleanDate(value)}
                      components={{
                        OpenPickerIcon: CalendarIcon,
                      }}
                    />
                  )}
                  rules={{
                    required: 'Please select the start date.',
                    validate: {
                      validStartDate: (value: Date) => isValid(cleanDate(value)) || 'Please enter valid start date.',
                    },
                  }}
                  control={control}
                />
                <Typography variant='sFormHelper'>
                  {errors?.start_at && errors?.start_at?.message}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography fontSize={18} fontWeight={800} align="center" m={theme.spacing(1.25, 0.1)}> to </Typography>
              </Grid>
              <Grid item xs={2} sx={{ ...dateIconStyle.customBox }}>
                <Controller
                  name="close_at"
                  defaultValue={closeAt}
                  value={closeAt}
                  render={({ onChange, value }: DateSelectorProp) => (
                    <DatePicker
                      views={['day']}
                      renderInput={(params:any) => <TextField {...params} helperText={null} sx={styles.datepickerInput}/>}
                      minDate={max([addDays(startAt!, 1), today])}
                      value={cleanDate(value)}
                      maxDate={cleanDate(academicSessionEndDate)}
                      components={{
                        OpenPickerIcon: CalendarIcon,
                      }}
                      PopperProps={{
                        sx: {
                          ' & .Mui-selected': {
                            color: `${palette.customWhite.main}!important`,
                          },
                        },
                      }}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Please select the end date.',
                    validate: {
                      dateAfter: (value: Date) => isAfter(cleanDate(value),
                        cleanDate(getValues('start_at')))
        || 'Close date should be greater than start date.',
                      validCloseDate: (value: Date) => isValid(cleanDate(value))
        || 'Please enter valid close date.',
                    },
                  }}
                  control={control}
                />
                <Typography variant='sFormHelper'>
                  {errors?.close_at && errors?.close_at?.message}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={styles.editFormNavBottom as SxProps<Theme>}
            >
              <Box>
                <StyledNextButton
                  variant="outlined"
                  color="primary"
                  onClick={() => { setOpenNavigationModal(true); }}
                >
              Cancel
                </StyledNextButton>
              </Box>
              <Box ml={3}>
                <StyledNextButton
                  variant="contained"
                  color="primary"
                  type="submit"
                >
              Update
                </StyledNextButton>
              </Box>
            </Box>
          </form>
          <AssignmentNavigationModal
            openModal={openNavigationModal}
            handleClose={() => { setOpenNavigationModal(false); }}
            handleNext={() => { navigateTo('/benchmarks'); }} />
        </>
      )}
      <Loader open={loading} />
    </Layout>
  );
};

export default Edit;
