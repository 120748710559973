import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';

import React from 'react';
import { StyledTableSort3, tableWithNoDataStyles } from '../../../../../components/Common/Common.styled';
import { ArrowDownIcon } from '../../../../../components/Icons';
import WavingLadyFrame from '../../../../../components/Icons/WavingLadyFrame';
import NoResults from '../../../../../components/NoResults';
import { BenchmarkType, OrderBy, SubmissionStatusSlideoutSortType } from '../../../../../generated/graphql';
import { StyledTableCell2, TeacherBenchmarkDashboardStylesCSS } from '../../../teacherbenchmarkdashboard.styled';
import TruncateStudentName from '../../../../AdminBenchmarks/List/StudentListSlideout/TruncateStudentName';
import { Link } from 'react-router-dom';
import { SubmissionTypes } from '../../../../../variables/constant';
import { useTeacherBenchmarksDashboardContext } from '../../../teacherbenchmarks-dashboard-hook';
import InfiniteScroll from 'react-infinite-scroll-component';

type ListProps = {
  order: OrderBy,
  submissionStatusSlideOutDataLoading: boolean,
  slideOutData: any,
  sort: SubmissionStatusSlideoutSortType,
  handleSort: Function,
  benchmarkType: BenchmarkType | undefined
  page: number,
  setPage: Function,
  totalCount: number,
};
const List = ({ order, submissionStatusSlideOutDataLoading, slideOutData, sort, handleSort, benchmarkType, setPage, totalCount, page }: ListProps) => {
  const theme = useTheme();
  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const { selectedSchools, selectedClasses, selectedGrades, setLocalTeacherBenchmarkDashboardFilters } = useTeacherBenchmarksDashboardContext();
  return (

    <TableContainer sx={{ border: 'none', height: '75vh', paddingTop: '8px' }}>
      <Table
        stickyHeader
        aria-label="overall performance scores"
        sx={{ ...((submissionStatusSlideOutDataLoading || !slideOutData?.length) && tableWithNoDataStyles.noData) }}
      >
        {!submissionStatusSlideOutDataLoading && slideOutData && (
          <>
            <TableHead>
              <TableRow>
                <StyledTableCell2 sx={{ ...styles.width15, ...styles.drawerTableColumn, textAlign: 'left', fontSize: '14px', fontWeight: '800', color: `${theme.palette.customBlack.fontBlack} !important` }}>
                  <StyledTableSort3
                    direction={orderByIcon}
                    onClick={handleSort(SubmissionStatusSlideoutSortType.StudentFullName)}
                    active={sort === SubmissionStatusSlideoutSortType.StudentFullName}
                    IconComponent={ArrowDownIcon}
                  >
                    Name
                  </StyledTableSort3>
                </StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              <InfiniteScroll
                height={650}
                next={() => setPage(page + 1)}
                hasMore={totalCount > slideOutData?.length}
                loader={null}
                dataLength={totalCount}>{
                  slideOutData?.map((student: any, rowIndex: any) =>  (
                    <TableRow key={rowIndex} sx={{ ...styles.tableRowHover, ...styles.tableRowBorder }}>
                      <TableCell sx={{ ...styles.width35, textAlign: 'left', cursor: 'default' }}>
                        {student.submission_status === SubmissionTypes.Graded ?
                          (<Link to={`/teacher/student-dashboard/${student.student_user_id}?benchmarkType=${benchmarkType}&linkPage=benchmarksummary`} style={{ textDecoration: 'none' }} onClick={() => { setLocalTeacherBenchmarkDashboardFilters?.({ schools: selectedSchools, grades: selectedGrades, classes: selectedClasses, benchmarkType }); }}>
                            <TruncateStudentName student={{ first_name: student.student_first_name, last_name: student.student_last_name }} maxChars={30} typographySx={{ fontSize: '18px', fontWeight: 800, color: theme.palette.customBlue.primaryBlue }} />
                          </Link>) :
                          <TruncateStudentName student={{ first_name: student.student_first_name, last_name: student.student_last_name }} maxChars={40} typographySx={{ fontSize: '18px', fontWeight: 800, color: theme.palette.customBlue.chipDark }} />}
                      </TableCell>
                    </TableRow>),
                  )}
              </InfiniteScroll>
            </TableBody>
          </>
        )}
        {(!!slideOutData || slideOutData === null) && !slideOutData?.length && !submissionStatusSlideOutDataLoading && (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={6}
                align="center"
              >
                <NoResults
                  label="No Students"
                  description="No students at this step yet"
                  svgComponent={<WavingLadyFrame />}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {submissionStatusSlideOutDataLoading && (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={6}
                align="center"
              >
                <CircularProgress size={28} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
export default List;
