import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../../../../components/Layout';
import AppBreadcrumbs from '../../../../components/AppBreadcrumbs';
import PDFViewer from '../../../../components/PDFViewer';
import { StyledStickyBreadcrumbBox } from '../../TeacherToolbox.styled';
import { SHARED_BUCKET_URL } from '../../../../variables/constant';
import useRubricIndicatorAndStandards from '../rubricIndicatorStandards-hook';
import { ScoreSchemeTitleKeys } from '../../../../variables/types';

const breadcrumb = [{
  label: 'Toolbox',
  route: '/toolbox',
},
{
  label: 'Rubric, Indicators, & standards',
  route: '/toolbox/rubric-indicators-standards',
},
{
  label: '9-12 standards',
}];

const Standards9To12View = () => {
  const { user } = useRubricIndicatorAndStandards();
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <StyledStickyBreadcrumbBox>
          <Typography variant="pageTitle">9-12 Standards</Typography>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
        </StyledStickyBreadcrumbBox>
        <Box>
          <PDFViewer
            downloadFile
            pdfLink={(user?.score_scheme.title_key === ScoreSchemeTitleKeys.Scheme1)
              ? `${SHARED_BUCKET_URL}/pdfs/TexasScoring/Standard9To12.pdf` : `${SHARED_BUCKET_URL}/pdfs/StandardScoring/Standard9To12.pdf`}
            downloadLink="Standards9To12.pdf"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default React.memo(Standards9To12View);
