/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { getToken } from '../utils/auth';

interface Props {
  children: React.ReactNode;
  path: string;
}

const PrivateRoute = ({ children, ...rest }: Props) => (
  <Route
    {...rest}
    render={({ location }) => {
      if (getToken()) {
        return children;
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
