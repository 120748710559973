import { useTeacherSchoolFilterQuery } from '../../generated/graphql';

const useTeacherSchoolsFilter = () => {
  const { data, loading } = useTeacherSchoolFilterQuery({
    fetchPolicy: 'network-only',
  });

  return {
    schools: data?.teacherSchools ?? [],
    loading,
  };
};

export default useTeacherSchoolsFilter;
