import React from 'react';
import { Avatar, Backdrop, Box, Card, CardHeader, SxProps, styled } from '@mui/material';
import { AnalyticsIcon } from '../../components/Icons';
import { palette } from '../../theme/palette';
import { AdminDashboards } from '../../variables/constant';

interface Props {
  dataMissing: boolean;
}

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  margin: theme.spacing(1, -3, -2, -2),
  position: 'absolute',
  zIndex: theme?.zIndex?.drawer - 1,
}));

const InsufficientDataBackdrop = ({ dataMissing }: Props) => {

  const iconWidth = 98;
  const iconHeight = 98;

  return (
    <StyledBackdrop
      open={dataMissing}
    >
      <Box sx={{ p: 2 } as SxProps}>
        <Card sx={{ p: 2 }}>
          <CardHeader
            avatar={
              <Avatar variant="square" sx={{ width: iconWidth, height: iconHeight, borderRadius: 3 }}>
                <AnalyticsIcon
                  fillColor={palette.customWhite.main}
                  sx={{
                    backgroundColor: palette.customBlue.primaryBlue,
                    width: iconWidth,
                    height: iconHeight,
                    p: 2,
                  }}
                />
              </Avatar>
            }
            title={AdminDashboards.common.insufficientData.title}
            titleTypographyProps={{
              variant: 'font28',
              color: palette.customBlue.primaryBlue,
            }}
            subheader={AdminDashboards.common.insufficientData.description}
            subheaderTypographyProps={{
              variant: 'sLabel',
            }}
            sx={{
              '& .MuiCardHeader-content': {
                height: iconHeight,
              },
            }}
          />
        </Card>
      </Box>
    </StyledBackdrop>
  );
};

export default InsufficientDataBackdrop;
