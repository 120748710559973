import React from 'react';
import Box from '@mui/material/Box';
import MiniPlayerWithDeleteButton from '../../../../components/MiniPlayerWithDeleteButton';
import { palette  } from '../../../../theme/palette';

interface Props {
  type?: string;
  promptText?: string;
  promptFileId?: string;
  promptFileType?: string;
  promptFileURL?: string;
}

const styles = {
  promptContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
    backgroundColor: palette.customGrey.promptBackground,
    borderRadius: '8px',
    padding: '12px',
  }
}

const BackpackPrompt = ({
  type,
  promptText,
  promptFileId,
  promptFileType,
  promptFileURL,
}: Props) => {
  return (
    <Box sx={{ ...styles.promptContainer }}>
      <Box>
        {type && (<b>{`${type} Prompt: `}</b>)}
        {promptText}
      </Box>
      {
        promptFileURL && (
          <Box
            ml={1}
            id={promptFileId}
          >
            <MiniPlayerWithDeleteButton
              url={promptFileURL}
              fileType={promptFileType}
              disableDelete
            />
          </Box>
        )
      }
    </Box>
  );
};

export default BackpackPrompt;
