const ordinal = (number: number) => {
  const ordinalRules = new Intl.PluralRules('en', {
    type: 'ordinal',
  });
  const suffixes = {
    zero: 'th',
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
    many: 'th',
  };
  const suffix = suffixes?.[ordinalRules.select(number)];
  return `${number}${suffix}`;
};

export default ordinal;
