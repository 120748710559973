import React from 'react';
import { Box } from '@mui/material';
import { StyledBoxYaxis, StyledSpanYTick } from './Loader.styled';

const styles = {
  delay1: {
    animationDelay: '.25s !important',
  },
};

const SingleBarGraphLoader = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center">
      <StyledBoxYaxis>
        <StyledSpanYTick sx= {{ ...styles.delay1 }} />
      </StyledBoxYaxis>
    </Box>
  );
};
export default SingleBarGraphLoader;
