import {
  useTeacherBenchmarksDashboardContext,
} from '../../../TeacherBenchmarksDashboard/teacherbenchmarks-dashboard-hook';
import { useEffect, useState } from 'react';
import {
  AdminBenchmarkInput,
  BenchmarkType,
  useAddTypeformUserMappingMutation,
  useAdminBenchmarkSubmissionStatusGrowthQuery,
  useGetAdminDashboardSurveyTypeformQuery,
} from '../../../../generated/graphql';
import { openSnackbar } from '../../../../components/Notifier';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { NotifierType } from '../../../../variables/types';
import { DashboardWidgetsDataStatusProps } from '../../../AdminAnalytics/admin-analytics-hook';
import {
  AdminBenchmarkReportWidgets,
  BenchmarkSubmissionStatusColors,
  SubmissionStatusObj,
  SubmissionTypesForDashboard,
} from '../../../../variables/constant';
import useScoreSchemeDomain from '../../../TeacherBenchmarksDashboard/scorescheme-domain-hook';
import useRole from '../../../../hooks/useRole';
import { useAuthProvider } from '../../../../core/authContext';

export type AdminBenchmarkGrowthReportChartData = {
  benchmarkType: string;
  speakingScore?: number;
  writingScore?: number;
};

export type PieChartData = {
  studentCount: number;
  color: string;
  skillLabel: string;
};

const useAdminBenchmarkSummary = (districtId: string, schoolId: string) => {
  const {
    selectedGrades,
    selectedSchools,
    selectedTeachers,
    isFilterSelected,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
    resetDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsAdminReportBSGrowthDataMissing,
  } = useTeacherBenchmarksDashboardContext();

  const { getUser } = useAuthProvider();
  const user = getUser();

  const [ hasGrowthData, setHasGrowthData ] = useState<boolean>(false);
  const [ hasBoyStatusData, setHasBoyStatusData ] = useState<boolean>(false);
  const [ hasMoyStatusData, setHasMoyStatusData ] = useState<boolean>(false);
  const [ hasEoyStatusData, setHasEoyStatusData ] = useState<boolean>(false);
  const [ growthReportData, setGrowthReportData] = useState<Array<AdminBenchmarkGrowthReportChartData>>([]);
  const [ boySubmissionPieChartData, setBoySubmissionPieChartData ] = useState<PieChartData[]>();
  const [ moySubmissionPieChartData, setMoySubmissionPieChartData ] = useState<PieChartData[]>();
  const [ eoySubmissionPieChartData, setEoySubmissionPieChartData ] = useState<PieChartData[]>();
  const [ boyTotalSubmissions, setBoyTotalSubmissions] = useState<number>(0);
  const [ moyTotalSubmissions, setMoyTotalSubmissions] = useState<number>(0);
  const [ eoyTotalSubmissions, setEoyTotalSubmissions] = useState<number>(0);
  const [ dismissTypeform, setDismissTypeform] = useState<boolean>(false);

  const { yAxisDomain, yAxisTicks } = useScoreSchemeDomain();
  const { isSchoolAdmin } = useRole();

  // if schoolAdmin then send schoolId else selected schools
  const schoolFilter = isSchoolAdmin ? [schoolId] : selectedSchools;

  const queryInput: AdminBenchmarkInput = {
    district_id: districtId,
    school_ids: schoolFilter || [],
    grade_ids: selectedGrades || [],
    teacher_ids: selectedTeachers || [],
  };

  const {
    data: benchmarkSummaryWidgetsData,
    loading: benchmarkSummaryWidgetsDataLoading,
    error: benchmarkSummaryWidgetsDataErr,
  } = useAdminBenchmarkSubmissionStatusGrowthQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: queryInput,
    },
    skip: skipGraphQLRequest(),
  });

  const {
    data: typeformDetail,
    loading: typeformLoading,
    refetch: refetchAdminDashboardSurveyTypeform,
  } = useGetAdminDashboardSurveyTypeformQuery();

  const [addTypeformUserMapping,
    { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();

  useEffect(() => {
    setBoyTotalSubmissions(0);
    setMoyTotalSubmissions(0);
    setEoyTotalSubmissions(0);
    setHasBoyStatusData(false);
    setHasMoyStatusData(false);
    setHasEoyStatusData(false);
    setBoySubmissionPieChartData([]);
    setMoySubmissionPieChartData([]);
    setEoySubmissionPieChartData([]);
    resetDashboardWidgetsDataStatus(true);

    if (benchmarkSummaryWidgetsDataErr){
      openSnackbar(
        { message: getErrorMessage(benchmarkSummaryWidgetsDataErr) },
        NotifierType.Error );
    }

    const growthData: AdminBenchmarkGrowthReportChartData[] = [
      {
        benchmarkType: BenchmarkType.Boy,
      }, {
        benchmarkType: BenchmarkType.Moy,
      }, {
        benchmarkType: BenchmarkType.Eoy,
      }];
    if (!benchmarkSummaryWidgetsDataLoading) {
      benchmarkSummaryWidgetsData?.adminBenchmarkSubmissionStatusGrowth?.map((item: any) => {
        growthData.forEach((data: AdminBenchmarkGrowthReportChartData, index: number) => {
          if (data.benchmarkType === item?.benchmark_type && (item?.speaking_score || item?.writing_score)) {
            growthData[index].speakingScore = item?.speaking_score;
            growthData[index].writingScore = item?.writing_score;
          }
        });

        switch (item?.benchmark_type) {
          case BenchmarkType.Boy:
            const pieChartBoySubmissions = fillPieChartData(item.submission_line_items);
            setBoySubmissionPieChartData(pieChartBoySubmissions);
            setBoyTotalSubmissions(item?.total_submission || 0);
            setHasBoyStatusData(true);
            break;
          case BenchmarkType.Moy:
            const pieChartMoySubmissions = fillPieChartData(item.submission_line_items);
            setMoySubmissionPieChartData(pieChartMoySubmissions);
            setMoyTotalSubmissions(item?.total_submission || 0);
            setHasMoyStatusData(true);
            break;
          case BenchmarkType.Eoy:
            const pieChartEoySubmissions = fillPieChartData(item.submission_line_items);
            setEoySubmissionPieChartData(pieChartEoySubmissions);
            setEoyTotalSubmissions(item?.total_submission || 0);
            setHasEoyStatusData(true);
            break;
        }
      });
    }
    setGrowthReportData(benchmarkSummaryWidgetsData?.adminBenchmarkSubmissionStatusGrowth?.length ? growthData : []);
  }, [benchmarkSummaryWidgetsData, benchmarkSummaryWidgetsDataLoading, benchmarkSummaryWidgetsDataErr]);

  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkGrowthReport.name,
      loading: benchmarkSummaryWidgetsDataLoading,
      dataMissing: !(growthReportData?.length > 0),
    };

    const isGrowthWidgetDataMissing = growthReportData?.length > 0 &&
        growthReportData?.some((item) => item.speakingScore || item.writingScore);

    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    setHasGrowthData(isGrowthWidgetDataMissing);
    setIsAdminReportBSGrowthDataMissing?.(!isGrowthWidgetDataMissing);
  }, [growthReportData, benchmarkSummaryWidgetsDataLoading]);

  const fillPieChartData = (data: any[] | undefined) => {
    const pieChartData: PieChartData[] = [];
    data?.forEach((lineItem) => {
      let status = lineItem?.submission_status as string;
      let colorStatus = SubmissionStatusObj?.[status] as string;
      if (lineItem?.submission_count <= 0) {
        return;
      }
      pieChartData.push({
        studentCount: lineItem?.submission_count,
        skillLabel: SubmissionTypesForDashboard?.[status],
        color: BenchmarkSubmissionStatusColors?.[colorStatus],
      });
    });
    return pieChartData;
  };

  // call mutation to add typeform user mapping
  const onTypeformReady = async () => {
    setDismissTypeform(true);
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetail?.getAdminDashboardSurveyTypeform.id ?? '',
          submitted: false,
        },
      });
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  const onTypeformSubmit = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetail?.getAdminDashboardSurveyTypeform.id ?? '',
          submitted: true,
        },
      });
      refetchAdminDashboardSurveyTypeform();
      setDismissTypeform(true);
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };


  return {
    boySubmissionPieChartData,
    moySubmissionPieChartData,
    eoySubmissionPieChartData,
    boyTotalSubmissions,
    moyTotalSubmissions,
    eoyTotalSubmissions,
    isFilterSelected,
    hasGrowthData,
    hasStatusData : hasBoyStatusData || hasMoyStatusData || hasEoyStatusData,
    growthReportData,
    yAxisDomain,
    yAxisTicks,
    benchmarkSummaryWidgetsDataLoading,
    hasBoyStatusData,
    hasMoyStatusData,
    hasEoyStatusData,
    isSchoolAdmin,
    updateUserLoading,
    onTypeformReady,
    onTypeformSubmit,
    typeformData: typeformDetail?.getAdminDashboardSurveyTypeform ?? null,
    typeformLoading,
    userId: user?.id ?? '',
    dismissTypeform,
    isDataMissingInAllWidgets,
  };
};

export default useAdminBenchmarkSummary;
