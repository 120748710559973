import React from 'react';
import { SectionCategory } from '../../generated/graphql';
import useClasses from '../Classes/ClassesTable/classes-hook';
import List from '../Classes/ClassesTable/List';

interface Props {
  districtID?: string;
  schoolID?: string;
  studentID?: string;
  teacherID?: string
}
const ClassesList = ({ districtID, schoolID, studentID, teacherID }: Props) => {
  const {
    classes,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    rowsPerPage,
    setRowsPerPage,
  } = useClasses({ classTypes: [SectionCategory.Class, SectionCategory.CustomClass], districtID, schoolID, studentID, teacherID });
  return (
    <List
      marginTop={0}
      sxProps={{ borderTopLeftRadius: '0px' }}
      isTableEmpty={isTableEmpty}
      loading={loading}
      totalCount={totalCount}
      order={order}
      pagination={pagination}
      sort={sort}
      classes={classes}
      handlePageChange={handlePageChange}
      handleSort={handleSort}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};

export default  ClassesList;