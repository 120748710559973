import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, InputBase, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MiniPlayerWithDeleteButton from '../../../../components/MiniPlayerWithDeleteButton';
import Recorder from '../../../../components/Recorder';
import { ASSIGNMENT_PROMPT_DELAY_TIME } from '../../../../variables/constant';
import { RecordingTracker } from '../create-assignment-hook';
import usePromptInput from './prompt-input-hook';
import useRecordingTracker from '../recording-track-hook';
import { unescapeSpecialCharacters } from '../../../../utils/handleEscapeCharacters';

export const StylesCss = (theme: Theme) => ({
  textBoxContainer: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
  },
  textBox: {
    '& .MuiInputBase-input': {
      width: '100%',
      paddingTop: theme.spacing(1),
      borderRadius: theme.spacing(1, 1, 0, 0),
    },
  },
  errTextBox: {
    '& .MuiInputBase-input': {
      border: '1px solid',
      borderColor: theme.palette.customRed.main,
    },
  },
  inputStyles: {
    border: `2px solid ${theme.palette.customBackground.disabled}`,
    backgroundColor: theme.palette.customWhite.main,
  },
  recordingBox: {
    marginTop: -1,
    padding: theme.spacing(2, 1, 1, 1),
    backgroundColor: theme.palette.customGrey.main,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    borderRadius: theme.spacing(0, 0, 1, 1),
  },
  playerBox: {
    margin: theme.spacing(2, 0, 2, 3),
  },
  saveButton: {
    marginTop: theme.spacing(3),
    width: theme.spacing(8),
    fontSize: 18,
    lineHeight: theme.spacing(3),
    fontWeight: 800,
  },
  recordMessageTitle: {
    fontSize: '18px',
    fontWeight: 800,
    alignSelf: 'center',
  },
});

interface Props {
  promptType: string,
  isCommonPrompt: boolean,
  promptState:string,
  setPromptState:Function,
  isPastPT: boolean,
  contextInput: any,
  updateContextInput: Function,
  setSpeakingPromptFile: any,
  setWritingPromptFile: any,
  recordingTracker: RecordingTracker[],
  setRecordingTracker: Function,
}

const PromptInput = ({
  promptType,
  isCommonPrompt,
  promptState,
  setPromptState,
  isPastPT = false,
  contextInput,
  updateContextInput,
  setSpeakingPromptFile,
  setWritingPromptFile,
  recordingTracker,
  setRecordingTracker,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCss(theme);

  const { updateRecordingTracker, disableRecorder } = useRecordingTracker({
    recordingTracker,
    setRecordingTracker,
  });

  const handleStartRecording = (state: boolean) => {
    updateRecordingTracker({ name: promptType, isRecording: state });
  };

  const { errors, control, clearErrors } = useFormContext();

  const {
    onRecord,
    onDelete,
  } = usePromptInput({
    textPrompt: '',
    audioPrompt: null,
    promptType,
    updateContextInput,
    setSpeakingPromptFile,
    setWritingPromptFile,
  });

  const CHARACTER_LIMIT = 230;
  const promptData = {
    url: '',
    fileType: '',
  };
  if (promptType === 'speaking') {
    promptData.url = contextInput?.speaking_prompt_url!;
    promptData.fileType = contextInput?.speaking_prompt_file_type!;
  } else {
    promptData.url = contextInput?.writing_prompt_url!;
    promptData.fileType = contextInput?.writing_prompt_file_type!;
  }
  var promptErrorMessage = promptType === 'speaking' ? errors?.speaking_prompt?.message : errors?.writing_prompt?.message;
  return (
    <Box>
      <Typography variant="sFormHelper">
        {promptErrorMessage}
      </Typography>
      <Box>
        <Controller
          name={`${promptType}_prompt`}
          defaultValue={promptState}
          value={promptState}
          render={({ onChange }) => (
            <InputBase
              id={`assignment-${promptType}_prompt`}
              aria-describedby={`assignment-${promptType}_prompt`}
              name={`${promptType}_prompt`}
              multiline
              rows={5}
              value={unescapeSpecialCharacters(promptState)}
              onChange={(e) => {
                setPromptState(e.target.value);
                if (promptState) clearErrors(`${promptType}_prompt`);
                promptErrorMessage = promptType === 'speaking' ? errors?.speaking_prompt?.message : errors?.writing_prompt?.message;
                onChange(e);
              }}
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
              sx={{ ...styles.textBoxContainer, ...styles.textBox }}
              disabled={isPastPT}
            />
          )}
          rules={{
            validate: () => {
              if (!isPastPT && (!isCommonPrompt || promptData.url) && (!promptState || !promptState.trim())) {
                if (promptData.url) return `Please submit a written ${isCommonPrompt ? 'speaking and/or writing' : promptType} prompt for your student.`;
                return `A ${promptType} prompt is required`;
              }
              return true;
            },
          }}
          control={control}
        />
      </Box>
      <Box sx={styles.recordingBox}>
        <Box sx={styles.recordMessageTitle}>Record a prompt (optional)</Box>
        <Box display="flex" flexDirection="row-reverse">
          {
            promptData.url ? (
              <MiniPlayerWithDeleteButton url={promptData.url} fileType={promptData.fileType} onDelete={onDelete} disableDelete={isPastPT!} deleteIconNoBorder/>
            ) : (
              <Recorder
                disable={disableRecorder(promptType) || isPastPT!}
                onRecord={onRecord}
                setIsRecording={(state: boolean) => handleStartRecording(state)}
                recordButtonHeight={60}
                recordButtonWidth={60}
                delayTime={ASSIGNMENT_PROMPT_DELAY_TIME}
                showElapsedTime
                maxRecordingTime={30}
              />
            )
          }
        </Box>
      </Box>
    </Box>
  );
};

export default PromptInput;
