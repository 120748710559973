import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../variables/constant';
import { OrderBy, BenchmarkTeacherDtoSortType, useTeacherBenchmarksListQuery } from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';


const useTeacherBenchmarksList = () => {
  const pagination = usePagination();
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, BenchmarkTeacherDtoSortType.StartDate));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage]);
  const onSortChange = (sortBy: BenchmarkTeacherDtoSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const { data, loading, refetch: tBenchmarksListRefetch } = useTeacherBenchmarksListQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort: sort as BenchmarkTeacherDtoSortType,
      orderBy: order as OrderBy,
    },
  });

  const benchmarks = data?.teacherBenchmarks?.nodes;
  const totalCount = data?.teacherBenchmarks?.total_count ?? 0;

  return {
    loading: loading,
    benchmarks, tBenchmarksListRefetch,
    pagination,
    totalCount,
    onSortChange,
    sort,
    order: order as OrderBy,
    rowsPerPage,
    setRowsPerPage,
  };
};

export default useTeacherBenchmarksList;
