/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const PauseIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="24"
    viewBox="0 0 15 24"
    fill="none"
    {...props}
  >
    <rect
      width="6"
      height="24"
      rx="1"
      fill={props.pathFill || palette.customWhite.main}
    />
    <rect
      x="9"
      width="6"
      height="24"
      rx="1"
      fill={props.pathFill || palette.customWhite.main}
    />
  </SvgIcon>
);

export default React.memo(PauseIcon);
