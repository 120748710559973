/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { AssignmentFilterFragmentFragment } from '../../generated/graphql';
import useAssignmentsFilter from './assignments-filter-hook';
import { useTheme } from '@mui/material';

interface Props {
  error?: boolean;
  value?: AssignmentFilterFragmentFragment | null | string;
  onChange?: (data: AssignmentFilterFragmentFragment | null | string) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  studentId?:string;
  sectionId?:string;
  schoolId?:string;
}

type AssignmentValue = AssignmentFilterFragmentFragment | null | undefined;


const AssignmentFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  sectionId,
  studentId,
  schoolId,
}: Props) => {
  const {
    assignments,
    loading,
  } = useAssignmentsFilter({ sectionId, studentId, schoolId });
  const theme = useTheme();
  const onSelectChange = (_: any, assignment: AssignmentFilterFragmentFragment | null) => {
    onChange?.(idSelectOnly ? (assignment?.id ?? '') : assignment);
  };

  const selectedValue: AssignmentValue = idSelectOnly ? assignments?.find((item) => item.id === value) ?? null : value as AssignmentValue;
  return (
    <Autocomplete
      sx={{ ...( Boolean(error) && { border: `1px solid ${theme.palette.customBlack.fontBlack}` } ) }}
      options={assignments || []}
      getOptionLabel={(option: any) => option?.title}
      isOptionEqualToValue={(option: any, selected: any) => option?.title === selected?.title}
      onChange={onSelectChange}
      value={selectedValue}
      disabled={loading}
      renderInput={(params:any) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style:{ fontWeight: 800 },
          }}
        />
      )}
    />
  );
};

export default React.memo(AssignmentFilter);
