import { useContext, useEffect, useState } from 'react';
import { useCleanupTablesMutation } from '../../generated/graphql';
import { FeatureFlagContext } from '../../core/featureContext';
import { openSnackbar } from '../Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType } from '../../variables/types';

const useDevtool = () => {
  const [showDevtools, setShowDevtools] = useState(
    localStorage.getItem('devtool') === 'true',
  );
  const [showPanel, setShowPanel] = useState(false);
  const { featureFlags, featureFlagDispatch } = useContext(FeatureFlagContext);
  const [cleanupTablesMutation] = useCleanupTablesMutation();

  const clearData = async () => {
    try {
      const response = await cleanupTablesMutation({});
      if (response.data) {
        openSnackbar({ message: 'Cleanup successful.' }, NotifierType.Success);
      } else {
        openSnackbar({ message: 'Error in cleanup table mutation.' }, NotifierType.Error);
      }
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  useEffect(() => {
    const handleChange = () => {
      const devToolStatus = localStorage.getItem('devtool');
      if (showDevtools.toString() !== devToolStatus) {
        setShowDevtools(devToolStatus === 'true');
      }
    };
    window.addEventListener(
      'storage',
      handleChange,
      false,
    );

    return () => window.removeEventListener('storage', handleChange);
  }, [showDevtools]);

  return {
    showDevtools,
    showPanel,
    setShowPanel,
    featureFlags,
    featureFlagDispatch,
    clearData,
  };
};

export default useDevtool;
