import { useParams } from 'react-router-dom';
import { UserPermissions, useTeacherDetailsQuery } from '../../../generated/graphql';
import usePermission from '../../../hooks/usePermission';
import useRole from '../../../hooks/useRole';
import { useImitationProvider } from '../../../core/imitationContext';

interface RouteProp {
  id: string
}

interface Props {
  teacherId?: string;
}

const useTeacher = ({ teacherId }: Props) => {
  const { id } = useParams<RouteProp>();
  const { data, loading } = useTeacherDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: teacherId || id,
    },
  });

  const { isSchoolAdmin, isDistrictAdmin, isSuperAdmin } = useRole();
  const { hasPermission: editTeacherPermission } = usePermission(UserPermissions.EditTeachers);
  const { hasPermission: imitateUserPermission } = usePermission(UserPermissions.ImitateUser);
  const { handleImitationLogin } = useImitationProvider();

  const teacher = data?.teacher;

  return {
    loading,
    teacher,
    editTeacherPermission,
    isSchoolAdmin, isDistrictAdmin, isSuperAdmin,
    imitateUserPermission, teacherID: id,
    handleImitationLogin,
  };
};

export default useTeacher;
