import React from 'react';
import useAdmins from '../Admins/AdminsTable/admins-hook';
import List from '../Admins/AdminsTable/List';
import { AdminType } from '../../variables/types';

interface Props {
  districtID?: string;
  schoolID?: string;
  adminType?: AdminType;
}
const SchoolAdminsList = ({ districtID, schoolID, adminType = AdminType.School }: Props) => {
  const {
    admins,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    isSchoolAdmin,
    isDistrictAdmin,
    type,
    rowsPerPage,
    setRowsPerPage,
  } = useAdmins({ districtID, schoolID, adminType: adminType });
  return (
    <List
      marginTop={0}
      sxProps={{ borderTopLeftRadius: '0px' }}
      isTableEmpty={isTableEmpty}
      loading={loading}
      totalCount={totalCount}
      order={order}
      pagination={pagination}
      sort={sort}
      admins={admins}
      handlePageChange={handlePageChange}
      handleSort={handleSort}
      isSchoolAdmin={isSchoolAdmin}
      isDistrictAdmin={isDistrictAdmin}
      type={type}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};

export default SchoolAdminsList;