import { useMemo } from 'react';
import { usePredefinedGradesListQuery } from '../../generated/graphql';
import { Direction, sortData } from '../../utils/sort';

interface Props {
  states?: string[];
  districts?: string[];
  schools?: string[];
}

const useGradesFilter = ({ states, districts, schools }: Props) => {
  const { data, loading } = usePredefinedGradesListQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(states ? { stateIDs: states } : {}),
      ...(districts ? { districtIDs: districts } : {}),
      ...(schools ? { schoolIDs: schools } : {}),
    },
  });

  const grades = useMemo(() => data?.allPredefinedGrades?.sort(sortData({ direction: Direction.Up, name: 'position' })), [data]);

  return {
    loading,
    grades,
  };
};

export default useGradesFilter;
