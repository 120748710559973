import React from 'react';
import { Box, Chip } from '@mui/material';
import { PredefinedGrades } from '../../../generated/graphql';
import { palette  } from '../../../theme/palette';

interface Props {
  onChange: (selectedItemsKey: string[] | null) => void,
  selected?: string[],
  preDefinedGrades?: PredefinedGrades[] | null,
  existingGrades?: (string | undefined)[],
}

const chipStyles = {
  gradeChip: {
    alignItems: 'center',
    border: `2px solid ${palette.customGrey.silver}`,
    borderRadius: '12px',
    width: '100px',
    height: '48px',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '1px',
  },
  active: {
    backgroundColor:palette.customBlue.primaryBlue,
    borderColor:palette.customBlue.primaryBlue,
    color: palette.customWhite.main,
    '&.MuiChip-outlined:hover, &.MuiChip-outlined:focus': {
      backgroundColor:palette.customBlue.primaryBlue,
      borderColor:palette.customBlue.primaryBlue,
      color: palette.customWhite.main,
    },
  },
};


const GradesGrid = ({ onChange, selected, preDefinedGrades, existingGrades }: Props) => {

  const handleChipClick = (key: string) => () => {
    let grades = selected ? [...selected] : [];
    if (grades?.includes(key)) {
      grades = grades.filter((grade) => grade !== key);
    } else {
      grades?.push(key);
    }
    onChange(grades);
  };

  return (
    <Box mt={2} sx={{ flexGrow: 1 }}>
      <Box display="grid" gridTemplateColumns="repeat(6, 110px)" sx={{
        gridGap: 24,
      }}>
        {
          preDefinedGrades?.map((grade: PredefinedGrades) => {
            const active = selected?.includes(grade.id);
            return (
              <Chip
                key={grade.id}
                variant="outlined"
                label={grade.grade}
                onClick={handleChipClick(grade.id)}
                color={active ? 'primary' : 'default'}
                sx={{
                  ...chipStyles.gradeChip,
                  ...(active && {
                    ...chipStyles.active,
                  }),
                }}
                disabled={existingGrades?.includes(grade.id)}
              />
            );
          })
        }
      </Box>
    </Box>
  );
};

export default React.memo(GradesGrid);
