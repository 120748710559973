import React from 'react';
import { Box } from '@mui/material';
import PTTile from '../../../components/PTTile';
import Layout from '../../../components/Layout';
import useBackPackForStudents from './student-backpack-dashboard-hook';
import { AssignmentForStudentFragment, BenchmarkForStudentFragment } from '../../../generated/graphql';
import NoResultsFirstPage from '../../../components/NoResultsFirstPage';
import StudentFixedHeader from '../StudentFIxedHeader';
import Loader from '../../../components/Loader/loader';
import { Direction, sortByDate } from '../../../utils/sort';

const StudentBackPackBoard = () => {
  const {
    assignments,
    benchmarks,
    loading,
    handleRedirection,
    assignmentTeachers,
  } = useBackPackForStudents();

  const sortAssignments = (assignments: any[]) => {
    const sortedSubmittedAssignments = assignments?.filter(assignment =>
      assignment?.submission_status === 'Reviewed')?.sort(sortByDate({ direction: Direction.Down, name: 'submitted_at' }),
    );

    const sortedReviewPendingAssignments = assignments?.filter(assignment =>
      assignment?.submission_status === 'Submitted')?.sort(sortByDate({ direction: Direction.Down, name: 'submitted_at' }),
    );
    const sortedDueAssignments = assignments?.filter(assignment =>
      !assignment?.submission_status,
    )?.sort(sortByDate({ direction: Direction.Down, name: 'close_at' }));



    return sortedSubmittedAssignments.concat(sortedReviewPendingAssignments).concat(sortedDueAssignments);
  };

  const sortedAssignments = sortAssignments(assignments);
  const sortedBenchMarks = sortAssignments(benchmarks);

  return (
    <Layout>
      <Box>
        <StudentFixedHeader
          pageTitle="My Backpack"
          containIcon={false}
        />
        <Box
          width="100%"
          marginTop="24px"
        >
          <Box
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            sx={{
              gridGap: 16,
            }}
            width="100%"
          >

            {!loading && sortedBenchMarks.map((benchmark: BenchmarkForStudentFragment) => (
              <PTTile
                key={benchmark?.id}
                imageSrc={benchmark?.resource_url || ''}
                ptTitle={benchmark?.title}
                teacherName={''}
                assignmentDate={benchmark?.close_at}
                submittedDate={benchmark?.submitted_at ?? ''}
                submissionId={benchmark?.submission_id ?? ''}
                handleRedirect={handleRedirection}
                submissionStatus={benchmark?.submission_status ?? ''}
                thumbnailImgSrc={benchmark?.thumbnail_url ? benchmark?.thumbnail_url : benchmark?.resource_url || ''}
                isBenchmark={true}
              />
            ))}
            {!loading && sortedAssignments?.map((assignment: AssignmentForStudentFragment) => (
              <PTTile
                key={assignment?.id}
                imageSrc={assignment?.resource_url || ''}
                ptTitle={assignment?.title}
                teacherName={`${assignmentTeachers(assignment.teachers ?? [])}`}
                assignmentDate={assignment?.close_at}
                submittedDate={assignment?.submitted_at ?? ''}
                submissionId={assignment?.submission_id ?? ''}
                handleRedirect={handleRedirection}
                submissionStatus={assignment?.submission_status ?? ''}
                thumbnailImgSrc={assignment?.thumbnail_url ? assignment?.thumbnail_url : assignment?.resource_url || ''}
              />
            ))}
            {((!assignments || assignments?.length === 0) && (!benchmarks || benchmarks.length === 0)) && !loading && (
              <NoResultsFirstPage
                label="Looks like you’re all caught up!"
                description="All performance tasks have either been completed or have not been assigned at this time.
          Come back and check at a later time, till then have a fantastic day!"
              />
            )}
          </Box>
        </Box>
        <Loader open={loading} />
      </Box>
    </Layout>
  );
};

export default React.memo(StudentBackPackBoard);
