import React from 'react';
import { Box, Link, SxProps, Typography } from '@mui/material';
import { palette  } from '../../theme/palette';

interface Props {
  label?: string
  description?: string,
  suggestString?: string,
  email?: string,
  svgComponent?:object,
}

const styles = {
  common: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainBox: {
    width: '100%',
    height: '100%',
    minHeight: '400px',
    minWidth: '300px',
    flexDirection: 'column',
    background: palette.customWhite.main,
    borderRadius: '10px',
    boxShadow:' 0 2px 4px 0 rgba(0,0,0,0.2)',
  },
  secondaryBox: {
    minHeight: '150px',
    minWidth: '150px',
    width: '30%',
    height: '50%',
    borderRadius: '5px',
    background: palette.customGrey.main,
  },
  label: {
    textTransform: 'none',
    color: palette.errors.dark,
    fontSize: '20px',

  },
  description: {
    color: palette.customBlack.lightBlack,
    marginTop: '8px',
    textAlign: 'center',
    width: '50%',
    fontWeight: 600,
    fontSize: '18px',
  },
};

const NoImagesFound = ({ label, description, suggestString, email } : Props) => (
  <Box sx={{ ...styles.common, ...styles.mainBox } as SxProps } >
    <Box sx={{ ...styles.common, ...styles.secondaryBox } as SxProps } >
      {label && <Typography variant='secondaryLabel' sx={{ ...styles.label } as SxProps}>{label}</Typography>}
    </Box>
    {description && <Typography variant='secondaryLabel' sx={{ ...styles.description } as SxProps }>{description}</Typography>}
    {suggestString && <Typography variant='secondaryLabel' sx={{ ...styles.description } as SxProps }>{suggestString} {email && <Link href={`mailto: ${email}`} underline='none' sx={{ fontWeight: 'bold' }} >{email}</Link>} </Typography>}
  </Box>
);

export default NoImagesFound;
