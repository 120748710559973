import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  useHistory,
} from 'react-router-dom';
import BenchmarkTable from './BenchmarkTable';
import Layout from '../../../components/Layout';

const BenchmarkList = () => {
  const history = useHistory();
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 200px"
          gap='32px'
          alignItems="center"
        >
          <Box>
            <Typography variant="pageTitle">Benchmarks</Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { history.push('/benchmarks/create'); }}
          >
            Create
          </Button>
        </Box>
        <Box display="flex" mt={2}>
          <BenchmarkTable />
        </Box>
      </Box>
    </Layout>
  );
};

export default BenchmarkList;
