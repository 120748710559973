import { useAddKeywordsMutation, useGetKeywordsQuery } from '../../generated/graphql';
import { openSnackbar } from '../Notifier';
import { NotifierType } from '../../variables/types';
import getErrorMessage from '../../utils/getErrorMessage';


const useKeyword = ()=>{
  const [addKeywords, { loading }] = useAddKeywordsMutation();
  const { data, loading: keywordLoading, refetch } = useGetKeywordsQuery({
    fetchPolicy: 'network-only',
  });

  const createTags = async (searchText: string[])=>{
    try {
      const response = await addKeywords({
        variables: {
          search: searchText,
        },
      });
      openSnackbar({ message: 'Word added successfully' }, NotifierType.Success);
      refetch();
      return response;
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    keywords: data?.keyword ?? [],
    keywordLoading,
    createTags,
    loading,
  };
};

export default useKeyword;
