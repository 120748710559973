const zIndex = {
  zIndexNegativeOne : -1,
  zIndexAuto: 'auto',
  zIndex1 : 1,
  zIndex2 : 2,
  zIndex3 : 3,
  zIndex9 : 9,
  zIndex99 : 99,
  zIndex999 : 999,
  zIndex9999 : 9999,
  zIndex99999 : 99999,
};

export default zIndex;
