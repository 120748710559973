import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export const LoginLink = styled(Link)(({ theme: { palette } }) => ({
  color: palette.customBlue.flashlightBlue,
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 'bold',
  textDecoration: 'none',
}));

export const LoginButton = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: palette.customBlue.flashlightBlue,
  color: palette.customWhite.main,
  '&::after': {
    backgroundColor: palette.customBlue.flashlightBlue,
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&:hover': {
    backgroundColor: palette.customBlue.primaryPurple,
  },
}));
