import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography, Tooltip,
} from '@mui/material';
import { StyledTableContainer } from './Student.styled';
import StyledCard from '../../../components/StyledCard';
import GoalRow from './GoalRow';
import QuestionIcon from '../../../components/Icons/QuestionIcon';
import GoalsListRubric from '../../ScoreTask/Common/GoalsListRubric';
import { palette  } from '../../../theme/palette';
import { StudentGoalListResult } from '../../../generated/graphql';

interface Props {
  stickyHeader?: boolean;
  previousGaols?: StudentGoalListResult | null;
  loading: boolean;
  title: string;
}

const styles = {
  tableCellStylesGoals: {
    padding: '12px 32px',
    width: '40%',
    textAlign: 'left',
  },
  tableCellStyles: {
    padding: '12px 32px',
    width: '20%',
    textAlign: 'center',
  },
  titleStyles: {
    '&.MuiTableContainer-root': {
      marginTop: '12px',
      height: 'auto',
    },
  },
  tableCellStylesLeft: {
    textAlign: 'left',
  },
  noRecord: {
    fontWeight: 800,
    fontSize: 20,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
  },
  gradingRubricContainer: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  tooltip: {
    maxWidth: 'none',
  },
  tableCellRelative: {
    position: 'relative',
  },
  tableHeadContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  width20: {
    width: '20%',
  },
  width40: {
    width: '40%',
  },
  customBackground: {
    '&.MuiTableCell-root': {
      background:palette.customBlue.primaryBlue,
      color: palette.customWhite.main,
    },
  },
}

const previousGoalsStyles = {
  customBackground: {
    '&.MuiTableCell-root': {
      background:palette.customBlue.primaryBlue,
      color: palette.customWhite.main,
    },
  },
  width20: {
    width: '20%',
  },
  width40: {
    width: '40%',
  },
  tableCellStylesGoals: {
    padding: '12px 32px',
    width: '40%',
    textAlign: 'left',
  },
  tableCellStyles: {
    padding: '12px 32px',
    width: '20%',
    textAlign: 'center',
  },
};

const GoalsRubric = () => {
  return (
    <Box sx={{ ...styles.gradingRubricContainer }} >
      <Tooltip
        title={<GoalsListRubric />}
        sx={{ ...styles.tooltip }}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={15000}
      >
        <Box sx={{ ...styles.questionContainer }} >
          <QuestionIcon pathFill={palette.customWhite.main} />
        </Box>
      </Tooltip>
    </Box>
  );
};

const getScores = (score: any) => {
  switch (score) {
    case 'NoAttempt':
      return 1;
    case 'Emerging':
      return 2;
    case 'Progressing':
      return 3;
    case 'GoalMet':
      return 4;
    default:
      return null;
  }
};

const PreviousGoals = ({
  stickyHeader = true,
  previousGaols,
  loading,
  title
}: Props) => {
  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box mt={3}>
        <StyledCard title={title} titleAlignment="left" contentWrapperMinHeightAuto>
          <StyledTableContainer sx={{ ...styles.titleStyles }} >
            <Table
              /*
              *  Due to 'stickyHeader' prop, backgroundColor on TableHead or TableRow
              *  was not working. So added global styles under theme.ts file for
              *  MuiTableCell & MuiTableHead
              */
              stickyHeader={stickyHeader}
              aria-label={title}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ ...styles.tableCellStyles, ...styles.tableCellStylesLeft, ...styles.width20, ...styles.customBackground }}
                  >
                    Type
                  </TableCell>
                  <TableCell sx={{  ...previousGoalsStyles.tableCellStylesGoals, ...previousGoalsStyles.width40, ...previousGoalsStyles.customBackground  }}>
                    Goal
                  </TableCell>
                  <TableCell sx={{  ...previousGoalsStyles.tableCellStyles, ...previousGoalsStyles.width20, ...previousGoalsStyles.customBackground  }}>
                    <Box sx={{ ...styles.tableHeadContentContainer }} width="100%" justifyContent="center">
                      Progress
                      <GoalsRubric />
                    </Box>
                  </TableCell>
                  <TableCell sx={{  ...previousGoalsStyles.tableCellStyles, ...previousGoalsStyles.width20, ...previousGoalsStyles.customBackground  }}>
                    Assigned
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && previousGaols &&
                  (previousGaols?.speaking?.length > 0 || previousGaols?.writing?.length > 0)
                  && <GoalRow
                    data={previousGaols}
                    getScores={getScores}
                  />
                }
                {!loading && (!previousGaols || (previousGaols?.speaking?.length === 0 && previousGaols?.writing?.length === 0)) && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                    >
                      <Typography variant='sTitle'>
                        No Previous Goals Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow sx={{ ...styles.noRecord }}>
                    <TableCell
                      colSpan={6}
                      align="center"
                    >
                      <CircularProgress size={28} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </StyledCard>
      </Box>
    </Box>
  );
};
export default PreviousGoals;
