import React from 'react';
import StyledCard from '../../../components/StyledCard';
import DonutChartLoader from '../../../components/Loader/DonutChartLoader';
import NoResults from '../../../components/NoResults';
import EmptyResults from '../../../components/Icons/EmptyResults';
import PerformanceChart from '../../../components/Charts/Graph/PerformanceChart';
import { GraphSetData } from '../../../variables/types';
import { palette  } from '../../../theme/palette';

interface Props {
  loading: boolean,
  data: GraphSetData[] | null,
  totalCount: number,
  title: string,
  buttonTitle?: string,
  handleButtonClick?: () => void,
  onLinkClick: (data: GraphSetData | null) => void,
  iconString?: string
  clickToRedirect?: boolean
}

const PerformanceGraph = ({
  loading,
  totalCount,
  data,
  handleButtonClick,
  onLinkClick,
  buttonTitle = 'View Details',
  title,
  iconString,
  clickToRedirect,
}: Props) => {
  const hasData = !loading && totalCount > 0;
  return (
    <StyledCard
      title={title}
      buttonTitle={hasData && clickToRedirect ? buttonTitle : undefined}
      onClick={handleButtonClick}
    >
      <>
        {hasData && data && (
          <PerformanceChart
            data={data}
            iconString={iconString}
            dataKey="numberOfStudents"
            onLinkClick={onLinkClick}
          />
        )}
        {!loading && totalCount === 0 && (
          <NoResults
            description="No scores recorded yet"
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {loading && <DonutChartLoader />}
      </>
    </StyledCard>
  );
};

export default React.memo(PerformanceGraph);
