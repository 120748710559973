import * as React from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { PAGE_SIZE } from '../../variables/constant';
import {
  OrderBy,
  SectionFilterFragmentFragment,
  SubmissionStatus,
  useAddTypeformUserMappingMutation,
  useGetCsvDownloadSurveyTypeformQuery,
  useSubmissionsIdQueueQuery,
  useSubmissionsQuery,
} from '../../generated/graphql';
import usePagination from '../../utils/usePagination';
import { SubmissionFilter, SubmissionSort } from '../../variables/types';
import { SelectChangeEvent } from '@mui/material/Select';
import { SchoolValue } from '../../components/SchoolFilterTeacher';
import { useHistory } from 'react-router-dom';

const getFilterList = (filter?: string | null) => {
  if (filter === SubmissionFilter.Scored) {
    return [SubmissionStatus.Reviewed];
  }
  if (filter === SubmissionFilter.NotScored) {
    return [
      SubmissionStatus.Submitted,
    ];
  }
  return [
    SubmissionStatus.Reviewed,
    SubmissionStatus.Submitted,
  ];
};

const useSubmissions = () => {
  const pagination = usePagination();
  const [search, setSearchValue] = useQueryParam('search', StringParam);
  const [filter, setFilter] = useQueryParam('filter', withDefault(StringParam, SubmissionFilter.NotScored));
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, SubmissionSort.Date));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [section, setSectionValue] = useQueryParam('section', StringParam);
  const [school, setSchoolValue] = useQueryParam('school', StringParam);
  const history = useHistory();

  const { data, loading } = useSubmissionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      search,
      statusList: getFilterList(filter),
      sort: sort || 'submitted_at',
      orderBy: order as OrderBy,
      ...(section ? { section_id: section } : {}),
      ...(school ? { school_id: school } : {}),
    },
  });

  const { data: submissionQueueData, loading: submissionQueueLoading } = useSubmissionsIdQueueQuery({
    fetchPolicy: 'network-only',
    variables: {
      search,
      status: getFilterList(filter),
      sort: sort || 'submitted_at',
      orderBy: order as OrderBy,
      ...(section ? { section_id: section } : {}),
      ...(school ? { school_id: school } : {}),
    },
  });

  const { data: csvDownloadSurvey, loading: csvDownloadSurveyLoading } = useGetCsvDownloadSurveyTypeformQuery({
    fetchPolicy: 'network-only',
    variables: {},
  });

  const [addTypeformUserMapping, { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();

  const onSortChange = (sortBy: SubmissionSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const onFilter = (event: SelectChangeEvent<string[]>) => {
    pagination.setPage(1);
    setFilter(event.target.value as string);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination.setPage(1);
    if (event.target.value === '') {
      setSearchValue(undefined);
    } else {
      setSearchValue(event.target.value);
    }
  };

  const onSectionChange = (sectionData: string | SectionFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSectionValue(sectionData as string);
  };

  const onSchoolChange = (schoolData: SchoolValue | string) => {
    pagination.setPage(1);
    setSectionValue(undefined);
    setSchoolValue(schoolData as string);
  };

  const submissions = data?.submissions?.nodes ?? [];
  const totalCount = data?.submissions?.totalCount ?? 0;

  // call mutation to add typeform user mapping
  const onTypeformReady = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: csvDownloadSurvey?.getCSVDownloadSurveyTypeform.id ?? '',
          submitted: true,
        },
      });
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  const onTypeformSubmit = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: csvDownloadSurvey?.getCSVDownloadSurveyTypeform.id ?? '',
          submitted: true,
        },
      });
      history.push('/teacher/benchmarks/grade');
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  return {
    loading: loading || submissionQueueLoading || csvDownloadSurveyLoading,
    submissions,
    totalCount,
    pagination,
    search,
    onSearch,
    filter,
    onFilter,
    onSortChange,
    sort: sort as SubmissionSort,
    order: order as OrderBy,
    submissionQueueData: submissionQueueData?.submissionQueue?.IDs ?? [],
    section,
    onSectionChange,
    school,
    onSchoolChange,
    typeformData: csvDownloadSurvey?.getCSVDownloadSurveyTypeform ?? null,
    onTypeformReady,
    updateUserLoading,
    onTypeformSubmit,
  };
};

export default useSubmissions;
