import React, { useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import { ScoreContext } from '../score-context';
import { SubmissionStatus } from '../../../generated/graphql';
import { GOAL_BUTTON_LABELS } from '../../../variables/constant';
import GoalsRow from './GoalsRow';
import { StylesCss } from '../ScoreTask.styled';

interface Props {
  taskType: string
}

const SpeakingGoalCard = ({ taskType }: Props) => {
  const theme = useTheme();
  const Styles = StylesCss(theme);
  const {
    submission,
    activeGoals,
    fetchingGoals,
    refetch,
    submissionGoalInfoRefetch,
    speakingGoalCount,
    writingGoalCount,
  } = useContext(ScoreContext);

  const refetchValues = () => {
    if (refetch) {
      refetch();
    }
    if (submissionGoalInfoRefetch) {
      submissionGoalInfoRefetch();
    }
  };

  const isSpeakingGoalEditEnabled = (submission?.status === SubmissionStatus.Submitted)
    ? !(speakingGoalCount > 0)
    : false;

  const isWritingGoalEditEnabled = (submission?.status === SubmissionStatus.Submitted)
    ? !(writingGoalCount > 0)
    : false;

  return (
    <Box>
      <GoalsRow
        title={`${taskType} Goal`}
        containerStyles={Styles.goalsRowContainerStyles}
        buttonProps={taskType === 'Speaking' ? {
          buttonTitle: activeGoals?.getGoalsByStudentID
          && activeGoals?.getGoalsByStudentID?.speaking?.length > 0
            ? GOAL_BUTTON_LABELS.edit : GOAL_BUTTON_LABELS.create,
          isDisabled: !isSpeakingGoalEditEnabled,
        } : {
          buttonTitle: activeGoals?.getGoalsByStudentID
          && activeGoals?.getGoalsByStudentID?.writing?.length > 0
            ? GOAL_BUTTON_LABELS.edit : GOAL_BUTTON_LABELS.create,
          isDisabled: !isWritingGoalEditEnabled,
        }}
        goal={taskType === 'Speaking' ? activeGoals?.getGoalsByStudentID?.speaking : activeGoals?.getGoalsByStudentID?.writing}
        loading={!!fetchingGoals}
        onSuccess={refetchValues}
      />
    </Box>
  );
};

export default React.memo(SpeakingGoalCard);
