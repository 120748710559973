import React from 'react';
import { Grid, Box, Typography, Theme, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledNextButton, StyledDangerButton } from '../../Assignments.styled';
import { CreateAssignmentStylesCss } from '../CreateAssignment.styled';
import { StepProp, useCreateAssignment } from '../create-assignment-hook';
import StaticResources from '../../StaticResources';
import FormProgressBar from '../../../../components/FormProgressBar';
import { CreatePtSteps } from '../../../../variables/constant';
import AssignmentNavigationModal from './AssignmentNavigationModal';

interface ImageSelectorProp {
  onChange: (
    selectedItemKey: string | number | null, keyboardString?: string
  ) => void,
  value?: string,
}

const SelectImageStep = ({ title }: StepProp) => {
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);

  const {
    routeData,
    assignmentInput,
    submitStep,
    backToAssignments,
    handlePrev,
    isPastAssignment,
    loading,
    setStaticResourceImgSelected,
    setGrades,
    selectedGrade,
    setSelectedGrade,
    searchTerm,
    setSearchTerm,
    selectedSubjects,
    subjectAreas,
    setSubjectAreas,
    selectedTaskTypes,
    setTaskTypes,
    onSearch,
    resetFilters,
    updateAssignmentInput,
    openNavigationModal,
    setOpenNavigationModal,
  } = useCreateAssignment();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useFormContext();

  return (
    <>
      <Box sx={styles.formatTopNavBar}>
        <Grid container xs={12}>
          <Grid item xs={3}>
            <Typography variant='secondaryTitle'>{title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormProgressBar
              steps={CreatePtSteps}
              progressValue={routeData?.currentStep! - 1}
            />
          </Grid>
        </Grid>
      </Box>
      <form
        name="assignment-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(submitStep)}
      >
        <Box>
          <Grid
            xs={12}
          >
            <Box sx={styles.formItemContainer}>
              <Controller
                name="resource_id"
                defaultValue={assignmentInput?.resource_id!}
                value={assignmentInput?.resource_id!}
                render={(
                  { onChange, value }: ImageSelectorProp,
                ) => (
                  <StaticResources
                    onChange={onChange}
                    selected={value}
                    isEditMode
                    isDisabled={isPastAssignment}
                    isBenchmark={false}
                    loading={loading}
                    setStaticResourceImgSelected={setStaticResourceImgSelected}
                    setGrades={setGrades}
                    selectedGrade={selectedGrade}
                    setSelectedGrade={setSelectedGrade}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    selectedSubjects={selectedSubjects}
                    subjectAreas={subjectAreas}
                    setSubjectAreas={setSubjectAreas}
                    selectedTaskTypes={selectedTaskTypes}
                    setTaskTypes={setTaskTypes}
                    onSearch={onSearch}
                    resetFilters={resetFilters}
                    updateContextInput={updateAssignmentInput}
                  />
                )}
                rules={{ required: 'Please select the image.' }}
                control={control}
              />
              <Typography variant='sFormHelper'>
                {errors.resource_id && errors.resource_id.message}
              </Typography>
            </Box>
          </Grid>
          <Box sx={styles.formBottomNavBar as  SxProps<Theme> }>
            <Box>
              <StyledNextButton
                variant="outlined"
                color="primary"
                onClick={() => { handlePrev(getValues()); }}
              >
                Back
              </StyledNextButton>
            </Box>
            <Box>
              <StyledDangerButton
                variant="outlined"
                color="primary"
                onClick={() =>  setOpenNavigationModal(true)}
                sx={{ mr: 2 }}
              >
                Cancel
              </StyledDangerButton>
              <StyledNextButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={!assignmentInput?.resource_id}
              >
                Next
              </StyledNextButton>
            </Box>
          </Box>
        </Box>
      </form>
      <AssignmentNavigationModal openModal={openNavigationModal} handleClose={() => { setOpenNavigationModal(false); }} handleNext={() => { backToAssignments(); }} />
    </>
  );
};

export default SelectImageStep;
