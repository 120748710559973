import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import Grade from './Grade';
import RoutingPaths from '../RoutingPath';

const TeacherBenchmarks = () => (
  <Switch>
    <Route
      path={RoutingPaths.TeacherBenchMarks}
      component={List}
      exact
    />
    <Route
      path={RoutingPaths.TeacherGradeBenchMarks}
      component={Grade}
      exact
    />
  </Switch>
);

export default TeacherBenchmarks;
