import React from 'react';
import { Box, Paper, Button, Typography, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import TablePagination from '../../../components/TablePagination';
import useStudents from './students-hook';
import { StyledTableContainer } from '../../TeacherDashboard/TeacherDashboard.styled';
import StudentTable from './StudentTable';
import { PAGE_SIZE, PROVIDER_TYPE, ROWS_PER_PAGE } from '../../../variables/constant';
import { TeacherInfo } from '../../../generated/graphql';
import TeachersIcon from '../../../components/Icons/TeachersIcon';
import DebouncedSearchBar from '../../../components/DebounceSearchBar';
import TeacherSchoolFilter from '../../../components/SchoolFilterTeacher';
import SectionsFilter from '../../../components/SectionsFilter';
import AddStudentSearch from '../StudentForm';
import RemoveStudentModal from '../RemoveStudent';

export const StylesCSS = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0.5),
    width: '100%',
  },
  contentContainer: {
    display: 'grid',
    marginTop: theme.spacing(0.25),
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.25),
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
});


const TeacherDashboard = () => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const history = useHistory();
  const {
    loading,
    search,
    onSearch,
    students,
    totalCount,
    onSortChange,
    sort,
    order,
    section,
    sectionTeacher = [],
    handlePageChange,
    pagination,
    school,
    onSectionChange,
    onSchoolChange,
    onSelectAllClick,
    handleClick,
    rowCount,
    isStudentSelected,
    numSelectedStudents,
    selectedStudents,
    openDialog,
    closeDialog,
    componentType,
    openRemoveStudentDialog,
    closeRemoveStudentDialog,
    teacherDetails,
    isTeacherPrimary,
    refetch,
  } = useStudents();
  const teacherSource = teacherDetails?.teacherByUser?.source;
  const sourceCheck = teacherSource !== PROVIDER_TYPE.FLASHLIGHT;
  return (
    <Box sx={styles.container}>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="pageTitle">Student Directory</Typography>
        <Box
          display="grid"
          gridTemplateColumns={'1fr repeat(3, 190px)'}
          sx={{
            gridGap: 28,
          }}
        >
          <Box>
            {!sourceCheck && <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                closeDialog('search');
              }}
            >
              Add Student
            </Button>}
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push('/groups')}
            >
              Manage Groups
            </Button>
          </Box>
          <Box>
            <TeacherSchoolFilter
              value={school}
              onChange={onSchoolChange}
              placeholder="All Schools"
              idSelectOnly
            />
          </Box>
          <Box>
            <SectionsFilter
              value={section}
              onChange={onSectionChange}
              placeholder="All Classes"
              schoolId={school as string | undefined}
              idSelectOnly
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.contentContainer}>
        <Box>
          <Box sx={styles.headerContainer}>
            <Box>
              <DebouncedSearchBar
                handleChange={onSearch}
                searchTerm={search}
              />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="250px"
            >
              {
                !!sectionTeacher?.length && (
                  <Box>
                    <Paper sx={{ maxHeight: 100, overflowY: 'auto', my: 3 }}>
                      <TeachersIcon pathfill={theme.palette.primary.main} sx={styles.icon} />
                      <Typography variant='body1' color='warning' sx={{ display: 'inline' }}>Teachers : </Typography>
                      {
                        sectionTeacher.map((teacher:TeacherInfo, idx: number) => (idx === 0 ? `${teacher.teacher_name}*` : teacher.teacher_name))
                          .join(', ')
                      }
                    </Paper>
                  </Box>
                )
              }
            </Box>
          </Box>
          {numSelectedStudents > 0 && !sourceCheck && (
            <Box sx={{ mb: 2 }} >
              <Button
                variant="contained"
                color="error"
                onClick={() => closeRemoveStudentDialog('remove')} >
                  Remove {numSelectedStudents} students from class
              </Button>
            </Box>
          )}
          <StyledTableContainer>
            <StudentTable
              loading={loading}
              stickyHeader
              students={students}
              onSortChange={onSortChange}
              sort={sort}
              order={order}
              onSelectAllClick={onSelectAllClick}
              handleClick={handleClick}
              rowCount={rowCount}
              isSelected={isStudentSelected}
              numSelectedStudents={numSelectedStudents}
              closeDialog={closeDialog}
              teacher={teacherDetails?.teacherByUser}
              isTeacherPrimary={isTeacherPrimary}
            />
          </StyledTableContainer>
        </Box>
        {totalCount > PAGE_SIZE && (
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingTop="20px"
          >
            <TablePagination
              count={Math.ceil(totalCount / PAGE_SIZE) || 0}
              page={pagination.page}
              onChange={handlePageChange}
              defaultPage={1}
            />
          </Box>
        )}
      </Box>
      { openDialog && componentType &&
          <AddStudentSearch
            openDialog={openDialog}
            closeDialog={closeDialog}
            closeRemoveStudentDialog={closeRemoveStudentDialog}
            componentType={componentType}
            refetch={refetch}
          />
      }
      { openRemoveStudentDialog && componentType &&
          <RemoveStudentModal
            openDialog={openRemoveStudentDialog}
            closeDialog={closeRemoveStudentDialog}
            studentIDs={selectedStudents}
            refetch={refetch}
          />
      }
    </Box>
  );
};

export default React.memo(TeacherDashboard);
