import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { ScoreTabs } from '../../variables/types';
import useScore from './score-hook';
import Speaking from './Speaking';
import Writing from './Writing';

const ScoreTask = () => {
  const { id } = useScore();

  return (
    <Switch>
      <Route
        path={`/tasks/:id/score/${ScoreTabs.Speaking}`}
        component={Speaking}
        exact
      />
      <Route
        path={`/tasks/:id/score/${ScoreTabs.Writing}`}
        component={Writing}
        exact
      />
      <Redirect
        from="/tasks/:id/score"
        to={`/tasks/${id}/score/${ScoreTabs.Speaking}`}
      />
    </Switch>
  );
};

export default ScoreTask;
