import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { isIpad } from '../../variables/constant';

export const StyledLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.fontColors.fontBlack,
  fontWeight: 800,
  lineHeight: '25px',
  fontSize: isIpad ? '14px' : '18px',
}));


export const StyledMicIcon = styled(Button)<{ buttonHeight?: string, buttonWidth?: string }>(({  buttonHeight, buttonWidth }) => ({
  minWidth: 50,
  borderRadius: '50%',
  '&::after': {
    borderRadius: '50%',
  },
  height: buttonHeight ? buttonHeight : 50,
  width: buttonWidth ? buttonWidth : 50,
  '&:active': {
    borderRadius: '50%',
  },
}));
