
import React from "react";
import { CheckBoxOutlineBlankOutlined } from "@mui/icons-material";
import { Maybe } from "graphql/jsutils/Maybe";
import { Autocomplete, Checkbox, CheckboxProps, Chip, InputAdornment, List, ListItem, TextField, Typography } from "@mui/material";
import { palette } from "../../../../theme/palette";
import { PrimarySectionsFilterFragment, SectionCategory } from "../../../../generated/graphql";
import CheckboxCheckedIcon from "../../../../components/Icons/CheckboxCheckedIcon";
import ClassesIcon from "../../../../components/Icons/ClassesIcon";
import { svgStyles } from "../../../../components/Common/Common.styled";
import useMultiSelectSection from "./multselect-sections-hook";

interface Props {
  error?: boolean;
  className?: string;
  value?: Maybe<string>[];
  onChange?: (data: Maybe<string>[] | undefined) => void;
  schoolId?: string;
  teacherId?: string;
  classTypes?: SectionCategory[];
}

type ClassValue =  PrimarySectionsFilterFragment | null | undefined;

const styles = {
  border: "0px",
  minHeight: "48px",
  height: "auto",
  "& input.MuiInputBase-input.Mui-disabled": {
      backgroundColor: palette.customWhite.inputDisabled
  }
};

/**
 * SectionsMultiSelect: allow's user to select multiple sections from the autocomplete dropdown
 * @param Props
 * @returns MultiSelect autocomplete dropdown
 */

const SectionsMultiSelect = ({ className, schoolId, value, onChange, classTypes, teacherId }:Props) => {
  // useSectionsFilter hook: to get sections based on the provided filters
  const { sections, loading } = useMultiSelectSection({ schoolId, classTypes });
  const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />;
  const checkedIcon = <CheckboxCheckedIcon pathFill={palette.customBlue.primaryBlue} fontSize="small" />;
  const onSelectChange = (_: any, sectionsList: PrimarySectionsFilterFragment[]) => {
      onChange?.(sectionsList?.map((section: PrimarySectionsFilterFragment) => section?.id));
  };
  const isTeacherPrimary = (section: PrimarySectionsFilterFragment | null | undefined, teacherID?: String) => {
    return section?.teachers?.some((teacherInfo) => teacherInfo?.id === teacherID && teacherInfo?.primary_teacher === true )
  }

  return (
      <Autocomplete
        multiple
        className={className}
        disableCloseOnSelect
        options={sections}
        getOptionLabel={(option: ClassValue) => `${option?.name ?? ""}`}
        isOptionEqualToValue={(option: ClassValue, selected: any) => option?.id === selected?.id}
        disabled={loading}
        disableClearable
        onChange={onSelectChange}
        getOptionDisabled={(option) => !isTeacherPrimary(option, teacherId)}
        value={sections?.filter((section) => value?.includes(section?.id))}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            // selected options: viewed as removable chips in the textfield
            <Chip
              label={
                <Typography sx={{ fontWeight: 800, fontSize: "13px" }} component="span">
                  {option?.name}
                </Typography>
              }
              {...getTagProps({ index })}
              key={option.id}
              disabled={!isTeacherPrimary(option, teacherId)}
            />
            ))
        }
        renderOption={(props, option: ClassValue, { selected }) => (
            // List Options with a checkbox divided by a divider
            <List key={option?.id} sx={{ p:'0px' }}>
              <ListItem {...props} divider>
              <Checkbox
                color={"primary" as CheckboxProps["color"]}
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
                disabled={!isTeacherPrimary(option, teacherId)}
              />
              {option?.name ?? ""}
              </ListItem>
            </List>
            )}
        renderInput={(params) => (
            <TextField
              {...params}
              sx={{ ...styles }}
              placeholder={params.InputProps.startAdornment ? undefined : "Select class(es)"}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style: { height: "auto", maxHeight: "500px", fontSize: "16px", padding:'1px' },
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <ClassesIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }}
            />
        )}
      />
  );
}

export default React.memo(SectionsMultiSelect);