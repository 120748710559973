import { StringParam, useQueryParam } from 'use-query-params';
import React, { useEffect, useState } from 'react';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import {
  ClassFilterFragmentFragment,
  DistrictFilterFragmentFragment,
  OrderBy,
  SchoolFilterFragmentFragment,
  StateFilterFragmentFragment,
  UserPermissions,
  useMyDistrictQuery,
  useStudentsListQuery,
  AdminStudentsListSortType,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { AdminStudentsSort } from '../../../variables/types';
import usePermission from '../../../hooks/usePermission';
import { SearchContext } from '../../../core/searchContext';

interface Props {
  districtID?: string;
  schoolID?: string;
  classID?: string;
  teacherID?: string;
}

const useStudents = ({ districtID, schoolID, classID, teacherID }: Partial<Props> = {}) => {
  const pagination = usePagination();
  const { searchTerm } = React.useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [order, setOrder] = useQueryParam('order', StringParam);
  const [selectedState, setStateValue] = useQueryParam('state', StringParam);
  const [selectedDistrict, setSelectedDistrict] = useQueryParam('district', StringParam);
  const [selectedClass, setSelectedClass] = useQueryParam('class', StringParam);
  const [selectedSchool, setSelectedSchool] = useQueryParam('school', StringParam);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [isSourceId, setIsSourceId] = useState(false);
  const { hasPermission: districtPermission } = usePermission([
    UserPermissions.ListDistricts,
    UserPermissions.ViewDistricts,
  ]);
  const { hasPermission: schoolPermission } = usePermission([UserPermissions.ListSchools, UserPermissions.ViewSchools]);
  const { hasPermission: myDistrictPermission } = usePermission([
    UserPermissions.MyDistrict,
  ]);
  const { data: district, loading: myDistrictDataLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: !myDistrictPermission,
  });

  useEffect(() => {
    if (district) {
      setSelectedDistrict(district?.myDistrict?.id);
    }
  }, [district]);

  useEffect(() => {
    pagination.setPage(1);
  }, [rowsPerPage]);

  const { data, loading } = useStudentsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: rowsPerPage,
      page: pagination.page,
      sort: sort as AdminStudentsListSortType,
      searchByName: searchTerm,
      orderBy: order as OrderBy,
      ...(selectedState ? { stateId: selectedState } : {}),
      ...(selectedDistrict || districtID ? { districtId: districtID ?? selectedDistrict } : {}),
      ...(selectedSchool || schoolID ? { schoolId: schoolID || selectedSchool } : {}),
      ...(teacherID  ? { teacherId: teacherID } : {}),
      ...(selectedClass || classID ? { sectionId: classID || selectedClass } : {}),
    },
  });

  const onSortChange = (sortBy: AdminStudentsSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const students = data?.allStudentsList.nodes ?? [];
  const totalCount = data?.allStudentsList.totalCount ?? 0;

  const isTableEmpty = !loading && students?.length === 0;

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: React.SetStateAction<number>) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: AdminStudentsSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onStateChange = (state: string | StateFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setStateValue(state as string);
    setSelectedDistrict(null);
    setSelectedClass(null);
    setSelectedSchool(null);
  };

  const onDistrictChange = (districtId: string | DistrictFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedDistrict(districtId as string);
    setSelectedClass(null);
    setSelectedSchool(null);
  };

  const onSchoolChange = (school: string | SchoolFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedSchool(school as string);
    setSelectedClass(null);
  };

  const onClassChange = (section: string | ClassFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedClass(section as string);
  };

  return {
    loading: loading || myDistrictDataLoading,
    totalCount,
    students,
    pagination,
    onSortChange,
    sort: sort as AdminStudentsSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    selectedClass,
    onClassChange,
    selectedSchool,
    onSchoolChange,
    districtPermission,
    schoolPermission,
    rowsPerPage,
    setRowsPerPage,
    isSourceId, setIsSourceId,
  };
};

export default useStudents;
