import React from 'react';
import { Box, Divider, SxProps, Typography } from '@mui/material';
import { boxStyles, StyledIconContainer } from './StudentDashboard.styled';
import { Skills } from '../../variables/types';
import useAssignmentsForStudents from './student-dashboard-hook';
import Loader from '../../components/Loader/loader';
import { StudentGoalFragment, StudentDashboardAssignmentFragment, StudentDashboardBenchmarkFragment } from '../../generated/graphql';
import NoResultsFirstPage from '../../components/NoResultsFirstPage';
import GirlDeskFrame from '../../components/Icons/GirlDeskFrame';
import PronunciationIcon from '../../components/Icons/PronunciationIcon';
import PencilIcon from '../../components/Icons/PencilIcon';
import GoalsIcon from '../../components/Icons/GoalsIcon';
import NoResults from '../../components/NoResults';
import DashboardFeedbackTiles from '../../components/DashboardFeedbackTiles';
import CheckedCircleIcon from '../../components/Icons/CheckedCircleIcon';
import { palette } from '../../theme/palette';
import StudentFixedHeader from '../StudentBackPack/StudentFIxedHeader';
import DashboardAssignmentBenchmarkTile from './dashboard-benchmark-tile';

const dashboardStyles = {
  goalIcon: {
    '&.MuiSvgIcon-root': {
      height: '30px',
      width: '30px',
    },
  },
  iconDimensions: {
    height: '15px',
    width: '15px',
    fill: palette.customWhite.main,
  },
  rowBox: {
    '&:last-child': {
      border: 'none',
    },
  },
};

const StudentDashboard = () => {
  const {
    assignments,
    benchmarks,
    loading,
    handleRedirection,
    teacherIDColorMap,
    activeGoals,
    handleExit,
    feedback,
    exitFeedbacks,
    progressRedirect,
    assignmentTeachers,
  } = useAssignmentsForStudents();

  const speaking = activeGoals?.getStudentGoals?.speaking;
  const writing = activeGoals?.getStudentGoals?.writing;
  const feedbackAvailable = feedback?.length > 0;

  const getGoalsRow = (goals: StudentGoalFragment[], goalType: Skills) => (
    <Box display="grid" gridTemplateColumns="30px 1fr" sx={{ gridGap: 8 }}>
      {goalType === Skills.Speaking
        ? (
          <StyledIconContainer sx={{ background: palette.customBlue.primaryBlue }}>
            <PronunciationIcon fillColor={palette.customWhite.main} sx={{ ...dashboardStyles.iconDimensions }} />
          </StyledIconContainer>
        )
        : (
          <StyledIconContainer sx={{ background: palette.customBlue.cornflowerBlue }}>
            <PencilIcon fillColor={palette.customWhite.main} sx={{ ...dashboardStyles.iconDimensions }} />
          </StyledIconContainer>
        )}
      <Box>
        <Typography variant='sContentHeader' sx={{ fontWeight: 'bold' }}>
          {`My ${goalType === 'speaking' ? 'Speaking' : 'Writing'} Goals`}
        </Typography>
        <Box>
          {goals?.map((goal) => (
            <Box
              py={2}
              key={`${goalType}_${goal?.submission_id}`}
              borderBottom={`2px dashed ${palette.customBackground.disabled}`}
              sx={{ ...dashboardStyles.rowBox }}
            >
              <Typography variant="secondaryLabel" sx={{ margin: '4px 0 8px 0', color: palette.fontColors.fontBlack, fontWeight: 'normal' }} component='p'>
                {`${goal?.teacher_user_first_name ?? ''} ${goal?.teacher_user_last_name ?? ''}`}
              </Typography>
              <Typography variant="secondaryLabel">
                {goal.predefined_goal_description}
              </Typography>
              <Typography sx={{ fontStyle: 'italic' }}>
                {goal.predefined_goal_example}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
  return (
    <Box width="100%">
      <StudentFixedHeader
        pageTitle="My Performance Tasks"
        containIcon
        Icon={CheckedCircleIcon}
      />
      {
        feedbackAvailable
        && (
          <Box
            width="calc(100vw - 250px)"
            mt={3}
          >
            <DashboardFeedbackTiles
              data={feedback}
              onNextExit={handleExit}
              onClose={exitFeedbacks}
              progressRedirect={progressRedirect}
            />
          </Box>
        )
      }
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        sx={{
          gridGap: 16,
        }}
        width="100%"
        mt={6}
      >
        {benchmarks?.map((benchmark: StudentDashboardBenchmarkFragment) => (
          <DashboardAssignmentBenchmarkTile
            key={benchmark.id}
            assignmentId={benchmark.id}
            assignmentTitle={benchmark.title}
            submissionId={benchmark.submission_id ?? ''}
            teacherUserId={''}
            resourceUrl={'../images/student-dashboard/waitBenchmark.jpg'}
            handleRedirection={handleRedirection}
            closeAt={benchmark.close_at}
            teacherIDColorMap={teacherIDColorMap}
            isBenchmark={true}
            thumbnailUrl={'../images/student-dashboard/waitBenchmark.jpg'}
          />
        ))}
        {assignments?.map((assignment: StudentDashboardAssignmentFragment) => (
          <DashboardAssignmentBenchmarkTile
            key={assignment.id}
            assignmentId={assignment.id}
            assignmentTitle={assignment.title}
            submissionId={assignment.submission_id ?? ''}
            teacherUserId={assignment.teacher_user_id ?? ''}
            resourceUrl={assignment.resource_url}
            handleRedirection={handleRedirection}
            closeAt={assignment.close_at}
            teacherIDColorMap={teacherIDColorMap}
            teachers={`${assignmentTeachers(assignment.teachers ?? [])}`}
            thumbnailUrl={assignment.thumbnail_url ?? ''}
          />
        ))}
      </Box>
      {((!assignments || assignments?.length === 0) && (!benchmarks || benchmarks?.length === 0)) && !loading && (
        <Box mt={2}>
          <NoResultsFirstPage
            customPadding
            label="Looks like you’re all caught up!"
            description="All performance tasks have either been completed or have not been assigned at this time.
          Come back and check at a later time, till then have a fantastic day!"
            svgComponent={<GirlDeskFrame />}
          />
        </Box>
      )}
      {
        !loading && (
          <Box sx={{ ...boxStyles.goalContainer, mt: 5 }} >
            <Box mb={3}>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <GoalsIcon sx={{ ...dashboardStyles.goalIcon }} />
                <Typography component="span" variant='font28' sx={{ ml: '16px' }}>
                  My Goals
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ ...boxStyles.goalBox } as SxProps} >
              <Box>
                {speaking?.length ? getGoalsRow(speaking, Skills.Speaking) : null}
                {speaking?.length && writing?.length ? (<Divider variant="middle" sx={{ m: '16px 0' }} />) : null}
                {writing?.length ? getGoalsRow(writing, Skills.Writing) : null}
                {((!speaking || speaking?.length === 0) && (!writing || writing?.length === 0))
                  ? (
                    <NoResults
                      label="Your goals are coming soon."
                    />
                  ) : ''}
              </Box>
            </Box>
          </Box>
        )
      }
      <Loader open={loading} />
    </Box>
  );
};

export default StudentDashboard;
