import React from 'react';
import PrimaryOutlinedButtonStyled from './PrimaryOutlinedButton.styled';

interface Props {
  label: string;
  handleClick: () => void;
  disabled?: boolean;
}

const PrimaryOutlinedButton = ({ label, handleClick, disabled = false }:Props) => (
  <PrimaryOutlinedButtonStyled onClick={handleClick} disabled={disabled}>
    {label}
  </PrimaryOutlinedButtonStyled>
);

export default React.memo(PrimaryOutlinedButton);
