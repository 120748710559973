import { palette } from '../palette';

const Dialog = {
  MuiDialog: {
    styleOverrides: {
      root: {
        container: {
          minWidth: 200,
        },
        '& .MuiPaper-root': { padding: '24px', borderRadius: '24px' },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: 22,
        color: palette.customBlue.primaryBlue,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        '&:first-of-type': {
          paddingTop: 0,
        },
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        fontSize: 16,
        '&:first-of-type': {
          paddingTop: 0,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '16px 24px',
        justifyContent: 'flex-start',
      },
    },
  },
};

export default Dialog;
