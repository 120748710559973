/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { svgStyles } from '../Common/Common.styled';
import { Maybe, StateFilterFragmentFragment } from '../../generated/graphql';
import useStatesFilter from './states-filter-hook';
import StateIcon from '../Icons/StateIcon';
import { palette } from '../../theme/palette';
import { SxProps, Theme } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { Box, CheckboxProps, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
  error?: boolean;
  className?: string;
  value?: Maybe<string>[];
  onChange?: (stateList: Maybe<string>[] | undefined, data: StateFilterFragmentFragment) => void;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  isVariableHeight?: boolean
  sx?: SxProps<Theme> | undefined;
  limitTags?: number;
  textInputPlaceholder?: string;
  onSelectedStateChange?: (selectedStates: StateFilterFragmentFragment[]) => void;
}

const MultiStateSelectFilter = ({
  placeholder,
  onChange,
  value,
  limitTags = 3,
  disabled,
  textInputPlaceholder,
  onSelectedStateChange,
}: Props) => {
  const {
    states,
    loading,
  } = useStatesFilter();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onSelectChange = (_: any, statesList: StateFilterFragmentFragment[] | undefined = []) => {
    onChange?.(statesList?.map((state: StateFilterFragmentFragment) => state?.id),
      statesList[0]);
    onSelectedStateChange?.(statesList);
  };


  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={states || []}
      limitTags={limitTags}
      getOptionLabel={(option: StateFilterFragmentFragment) => `${option?.name ?? ''}`}
      value={states?.filter((state) => value?.includes(state?.id))}
      onChange={onSelectChange}
      disabled={disabled || loading}
      renderOption={(props, option, { selected }) => (
        <Box component="li" {...props}>
          <Checkbox
            color={'primary' as CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
          />
          {`${option?.name ?? ''}`}
        </Box>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Box sx={{ maxWidth: '120px' }}>
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.name ?? ''}</Typography>}
              {...getTagProps({ index })}
            />
          </Box>
        ))
      }
      renderInput={(params) => (
        <>
          {placeholder && <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>}
          <TextField
            {...params}
            placeholder={params.InputProps.startAdornment ? undefined : (!textInputPlaceholder ? 'Select States' : textInputPlaceholder)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <StateIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default MultiStateSelectFilter;
