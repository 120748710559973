import React from 'react';
import { Box, TableCell } from '@mui/material';
import { StyledTypographyLabel, StyledTypographyText } from '../../pages/TeacherToolBox/TeacherToolbox.styled';

interface Props {
  name?: string,
  subText?: string,
  Icon: React.ComponentType<any>,
}

const TableHeader = ({
  name,
  subText,
  Icon,
}: Props) => (
  <>
    <TableCell component="th" scope="row">
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          <Icon />
          <StyledTypographyLabel>
            {name}
          </StyledTypographyLabel>
        </Box>
        <StyledTypographyText>
          {subText}
        </StyledTypographyText>
      </Box>
    </TableCell>
  </>
);

export default TableHeader;
