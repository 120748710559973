/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const RubricIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="62"
    viewBox="0 0 55 62"
    fill="none"
    {...props}
  >
    <path d="M0 13.6667H54.6667V20.5H0V13.6667ZM0 34.1667H54.6667V27.3333H0V34.1667ZM0 47.8333H23.9167V41H0V47.8333ZM0 61.5H23.9167V54.6667H0V61.5ZM38.9842 51.8308L34.1667 46.9792L29.3492 51.7967L38.9842 61.5L54.6667 45.8517L49.815 41L38.9842 51.8308ZM0 0V6.83333H54.6667V0H0Z" fill={props.pathFill || palette.customWhite.main} />
  </SvgIcon>
);

export default React.memo(RubricIcon);
