import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const AddIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M14.5002 28.5C22.2322 28.5 28.5002 22.232 28.5002 14.5C28.5002 6.76801 22.2322 0.5 14.5002 0.5C6.76826 0.5 0.500244 6.76801 0.500244 14.5C0.500244 22.232 6.76826 28.5 14.5002 28.5ZM16.2502 9.25C16.2502 8.2835 15.4667 7.5 14.5002 7.5C13.5337 7.5 12.7502 8.2835 12.7502 9.25V12.75H9.25024C8.28375 12.75 7.50024 13.5335 7.50024 14.5C7.50024 15.4665 8.28375 16.25 9.25024 16.25H12.7502V19.75C12.7502 20.7165 13.5337 21.5 14.5002 21.5C15.4667 21.5 16.2502 20.7165 16.2502 19.75V16.25H19.7502C20.7167 16.25 21.5002 15.4665 21.5002 14.5C21.5002 13.5335 20.7167 12.75 19.7502 12.75H16.2502V9.25Z" fill={palette.customBlue.primaryBlue} />
  </SvgIcon>
);

export default AddIcon;
