import React from 'react';
import { add, format } from 'date-fns';
import { palette } from '../../../theme/palette';
import { SPEAKING_SKILL_TYPE_TEXT, WRITING_SKILL_TYPE_TEXT } from '../../../variables/constant';
import GrammarIcon from '../../Icons/GrammarIcon';
import PronunciationIcon from '../../Icons/PronunciationIcon';
import InterpretationIcon from '../../Icons/InterpretationIcon';
import FluencyIcon from '../../Icons/FluencyIcon';
import VocabularyIcon from '../../Icons/VocabularyIcon';
import VocabularyAndGrammarIcon from '../../Icons/VocabularyAndGrammarIcon';
import DescriptionAndExplanation from '../../Icons/DescriptionAndExplanation';
import PointsAndReason from '../../Icons/PointsAndReason';

export const dateFormatter = (date: string | number | Date) => {
  return format(new Date(date), 'MMM');
};

export const getTicks = (startDate: Date, endDate: Date) => {
  const ticks = [];
  let tStartDate = startDate;
  while (tStartDate < endDate){
    ticks.push(tStartDate.getTime());
    tStartDate = add(tStartDate, { months:1 });
  }
  return ticks;
};

export const getColor = (goalName: string) => {
  switch (goalName) {
    case 'GoalMet':
      return palette.customBlue.aquaBlue;
      break;
    case 'Progressing':
      return palette.fontColors.buttonFontGreen;
      break;
    case 'Emerging':
      return palette.customYellow.main;
      break;
    case 'NoAttempt':
      return  palette.fontColors.buttonFontRed;
      break;
    default:
      return palette.customBackground.disabled;
  }
};


export const getSkillTypeIcon = (skillType: string) => {
  switch (skillType) {
    case SPEAKING_SKILL_TYPE_TEXT.grammar:
      return (<GrammarIcon />);
    case SPEAKING_SKILL_TYPE_TEXT.vocabulary:
      return (<VocabularyIcon />);
    case SPEAKING_SKILL_TYPE_TEXT.fluency:
      return (<FluencyIcon />);
    case SPEAKING_SKILL_TYPE_TEXT.interpretation:
      return (<InterpretationIcon />);
    case SPEAKING_SKILL_TYPE_TEXT.pronunciation:
      return (<PronunciationIcon />);
    case WRITING_SKILL_TYPE_TEXT.vocab_and_grammar:
      return (<VocabularyAndGrammarIcon />);
    case WRITING_SKILL_TYPE_TEXT.points_and_reasons:
      return (<PointsAndReason />);
    case WRITING_SKILL_TYPE_TEXT.description_and_explanation:
      return (<DescriptionAndExplanation />);
    default:
      return (<></>);
  }
};

export const getMonthName = (monthNumber:number) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'short' });
};

export const getMonthNameLong = (monthNumber:number) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US',  { month: 'long' });
};

export const getMonth = (dataKeys: { month: number }) =>{
  let keys = getMonthName(dataKeys.month);
  return keys.toUpperCase();
};

export const formatSessionDate = (dateItem: Date) => {
  let calendarDate;
  calendarDate = format(new Date(`${dateItem}`), 'LLLL yyyy');
  return calendarDate;
};
