import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Student from './Student';
import StudentsTable from './StudentsTable';
import Layout from '../../components/Layout';
import RoutingPaths from '../RoutingPath';

const AdminStudents = () => (
  <Layout>
    <Switch>
      <Route
        path={RoutingPaths.AdminStudents}
        component={StudentsTable}
        exact
      />
      <Route
        path={[`${RoutingPaths.AdminStudents}/:id`, `${RoutingPaths.AdminStudents}/:id/:sectionType`]}
        component={Student}
        exact
      />
    </Switch>
  </Layout>
);

export default React.memo(AdminStudents);
