export const errorMessages = {
    studentNameHelperText: 'Please enter the student name.',
    studentIDHelperText: 'Please enter the student ID with minimum of four characters without space.',
    stateHelperText: 'Please select a state.',
    districtHelperText: 'Please select a district.',
    schoolHelperText: 'Please select a school.',
    lastNameHelperText: 'Please enter last name.',
    firstNameHelperText: 'Please enter first name.',
    gradeHelperText: 'Please add a grade',
    classesHelperText: 'Please select a class',
    passwordHelperText: 'Please enter a password with minimum length of 6 characters.',
    gradeSchoolHelperText: 'Please add grades in selected school.',
    gradeMissingSchoolHelperText: 'Please select a school.',
    otherHeritageLanguageHelperText: 'Please enter other language',
    confirmPasswordHelperText: 'Password and confirm password do not match',
  };
  
  export const labels = {
    checkBoxLabel: "I'll be adding another student after this one",
    createNewStudent: 'Create new student account',
    addNewStudent: 'Add a student',
    searchStudentByName: 'Search student by (Name, Student Number or Grade)',
    notFoundMsg: 'Sorry, we couldn’t find any results that match',
  };
  
  export const languages = ['Spanish', 'Arabic', 'Chinese'];
  