import { styled, Tabs, Tab, Theme, Button } from '@mui/material';

export const StyledAnalyticsFiltersTabs = styled(Tabs)(({ theme }) => ({
  '&.MuiTabs-root': {
    border: theme.spacing(0),
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    marginLeft: theme.spacing(-1),
  },
}));

export const StyledAnalyticsFiltersTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    '&.MuiButtonBase-root': {
      backgroundColor: theme.palette.customWhite.main,
      color: theme.palette.fontColors.fontBlack,
      borderRadius: theme.spacing(4, 4, 4, 4),
      border: `2px solid ${theme.palette.customGrey.adminButtonBorder}`,
      minWidth: theme.spacing(16),
      padding: theme.spacing(0, 2, 0, 2),
      margin: theme.spacing(0, 1, 0, 1),
      textTransform: 'none',
    },
    '&.Mui-selected': {
      '&.MuiButtonBase-root': {
        backgroundColor: theme.palette.customBlue.primaryBlue,
        color: theme.palette.customWhite.main,
        border: 'none',
      },
    },
  },
}));

export const StyledFilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.customWhite.main,
  border: `2px solid ${theme.palette.customGrey.adminButtonBorder}`,
}));

export const AnalyticsFiltersStylesCSS = (theme: Theme) => ({
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '100%',
    marginTop: theme.spacing(1),
  },
  filterDialog: {
    left: '15%',
    bottom: '30%',
  },
  filterDialogPaperSx: {
    '&.MuiPaper-root': {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2, 1),
    },
  },
  filterDialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
  },
  filterChipBox: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    minWidth: '100%',
    padding: theme.spacing(0, 0, 2, 0),
  },
  filterChip: {
    '&.MuiChip-root': {
      backgroundColor: theme.palette.customGrey.dragBorder,
      border: `2px solid ${theme.palette.customGrey.adminButtonBorder}`,
      paddingHorizontal: theme.spacing(2),
      margin: theme.spacing(1, 1, 0, 0),
    },
  },
});
