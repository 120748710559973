import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { DemoAdminDetails, DemoStudentDetails, DemoTeacherDetails } from '../../generated/graphql';
import DemoAccountType from './constants';
import {
  StyledTableCell,
  StyledTableDataColumn,
  StyledTableSecondary,
  tableWithNoDataStyles,
} from '../../components/Common/Common.styled';

interface Props {
  data: DemoTeacherDetails[] | DemoStudentDetails[] | DemoAdminDetails[] | undefined;
  type: DemoAccountType;
}

const List = ({
  data,
  type,
}: Props) => {
  let accountType = '';
  let label = '';
  switch (type) {
    case DemoAccountType.TEACHER:
      accountType = 'Teachers';
      label = 'Teacher ID';
      break;
    case DemoAccountType.DISTRICTADMIN:
      accountType = 'District Admins';
      label = 'Admin ID';
      break;
    case DemoAccountType.STUDENT:
      accountType = 'Students';
      label = 'Student ID';
      break;
  }

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Typography variant='sFormTitle'>
        {accountType}
      </Typography>
      <StyledTableSecondary>
        <Table
          stickyHeader
          aria-label={`${type} demo table`}
          sx={{ ...((data?.length === 0) && tableWithNoDataStyles.noData) }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Name
              </StyledTableCell>
              <StyledTableCell>
                {`${label}`}
              </StyledTableCell>
              <StyledTableCell>
                Email
              </StyledTableCell>
              <StyledTableCell>
                Password
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data as Array<DemoTeacherDetails | DemoStudentDetails | DemoAdminDetails>)?.map((row: DemoStudentDetails | DemoTeacherDetails | DemoAdminDetails) => {
              const studentId = (row as DemoStudentDetails)?.student_id;
              const teacherId = (row as DemoTeacherDetails)?.teacher_id;
              const adminId = (row as DemoAdminDetails)?.admin_id;
              const id = studentId || teacherId || adminId;
              return (
                <TableRow key={id}>
                  <TableCell>
                    {`${row?.first_name} ${row?.last_name}`}
                  </TableCell>
                  <StyledTableDataColumn>
                    {id}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.email}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.password}
                  </StyledTableDataColumn>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableSecondary>
    </Box>
  );
};

export default React.memo(List);
