/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SettingIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g id="icon / settings">
      <path d="M13.9507 8.78334C13.984 8.53334 14.0007 8.275 14.0007 8C14.0007 7.73334 13.984 7.46667 13.9424 7.21667L15.634 5.9C15.7069 5.83992 15.7567 5.75642 15.775 5.66372C15.7932 5.57103 15.7787 5.47489 15.734 5.39167L14.134 2.625C14.0869 2.54131 14.0117 2.47698 13.9217 2.44343C13.8317 2.40987 13.7328 2.40925 13.6424 2.44167L11.6507 3.24167C11.234 2.925 10.7924 2.65834 10.3007 2.45834L10.0007 0.341669C9.98594 0.246277 9.93747 0.159342 9.86407 0.0966492C9.79067 0.0339567 9.69723 -0.000334587 9.6007 2.46116e-06H6.4007C6.2007 2.46116e-06 6.04237 0.141669 6.00904 0.341669L5.70904 2.45834C5.21737 2.65834 4.76737 2.93334 4.35904 3.24167L2.36737 2.44167C2.18404 2.375 1.9757 2.44167 1.8757 2.625L0.284036 5.39167C0.184036 5.56667 0.217369 5.78334 0.384036 5.9L2.0757 7.21667C2.03404 7.46667 2.0007 7.74167 2.0007 8C2.0007 8.25834 2.01737 8.53334 2.05904 8.78334L0.367369 10.1C0.294472 10.1601 0.244664 10.2436 0.22643 10.3363C0.208195 10.429 0.222664 10.5251 0.267369 10.6083L1.86737 13.375C1.96737 13.5583 2.1757 13.6167 2.35904 13.5583L4.3507 12.7583C4.76737 13.075 5.20904 13.3417 5.7007 13.5417L6.0007 15.6583C6.04237 15.8583 6.2007 16 6.4007 16H9.6007C9.8007 16 9.96737 15.8583 9.99237 15.6583L10.2924 13.5417C10.784 13.3417 11.234 13.075 11.6424 12.7583L13.634 13.5583C13.8174 13.625 14.0257 13.5583 14.1257 13.375L15.7257 10.6083C15.8257 10.425 15.784 10.2167 15.6257 10.1L13.9507 8.78334ZM8.0007 11C6.3507 11 5.0007 9.65 5.0007 8C5.0007 6.35 6.3507 5 8.0007 5C9.6507 5 11.0007 6.35 11.0007 8C11.0007 9.65 9.6507 11 8.0007 11Z" />
    </g>
  </SvgIcon>
);

export default SettingIcon;
