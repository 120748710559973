import React from 'react';
import { useLocation } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import { Stack } from '@mui/material';
import { AdminDashboards, PDF_GENERATING_ERROR } from '../../../variables/constant';
import { StyledIconButton } from '../../Assignments/Assignments.styled';
import DownloadFileIcon from '../../../components/Icons/DownloadFileIcon';
import PrintIcon from '../../../components/Icons/PrintIcon';
import { PerformancePdf } from '../Performance/pdf';
import { LearningGoalsPdf } from '../LearningGoals/pdf';
import { AnalyticsFiltersProps, useDashboardContext } from '../admin-analytics-hook';
import { colors, drawChipWithText, getPdfTextWidth } from '../../../utils/pdf';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import { sentryErrorLog } from '../../../utils/sentry';
import { palette } from '../../../theme/palette';
import { toReadableFormat } from '../../../utils/dateFormat';

interface ExportToPdfProps extends AnalyticsFiltersProps {
  disable: boolean,
}

const ExportToPdf = ({
  disable,
  dateRange,
  submissionType,
  schools,
  grades,
  teachers,
  classes,
}: ExportToPdfProps) => {
  let pdfName = '';
  let dashboard = 'Performance Data Dashboard';
  const location = useLocation();
  const {
    setLoading,
    mySchoolData,
    isDistrictAdmin,
    myDistrictData,
    areWidgetsLoading,
    lastSnowflakeUpdateTimeData,
  } = useDashboardContext();
  const renderFilterChips = (pdf: jsPDF, posX: number, posY: number, pdfPadding: number) => {
    let fontSize = 8;
    let height = 15;
    let defaultChipProps = {
      pdf: pdf, fontSize,
      x: posX, y: posY,
      rx: 8, ry: 8,
      height,
      backgroundColor: colors.bgGrey,
      textColor: colors.fntBlack,
    };
    // Submisison Type
    let text = submissionType as string;
    let textWidth = getPdfTextWidth(submissionType as string, pdf, fontSize);
    let textWidthPrev = textWidth;
    drawChipWithText({ ...defaultChipProps, text, textWidth, backgroundColor: colors.bgPurple, textColor: colors.fntWhite });
    //
    text = dateRange as string;
    textWidth = getPdfTextWidth(text, pdf, fontSize);
    posX = posX + textWidthPrev + 2 * defaultChipProps.rx + pdfPadding / 3;
    drawChipWithText({ ...defaultChipProps, text, textWidth, x: posX });
    //
    textWidthPrev = textWidth;
    text = `Schools (${schools?.length === 0 ? 'All' : schools?.length})`;
    textWidth = getPdfTextWidth(text, pdf, fontSize);
    posX = posX + textWidthPrev + 2 * defaultChipProps.rx + pdfPadding / 3;
    drawChipWithText({ ...defaultChipProps, text, textWidth, x: posX });
    //
    textWidthPrev = textWidth;
    text = `Grades (${grades?.length === 0 ? 'All' : grades?.length})`;
    textWidth = getPdfTextWidth(text, pdf, fontSize);
    posX = posX + textWidthPrev + 2 * defaultChipProps.rx + pdfPadding / 3;
    drawChipWithText({ ...defaultChipProps, text, textWidth, x: posX });
    //
    textWidthPrev = textWidth;
    text = `Teachers (${teachers?.length === 0 ? 'All' : teachers?.length})`;
    textWidth = getPdfTextWidth(text, pdf, fontSize);
    posX = posX + textWidthPrev + 2 * defaultChipProps.rx + pdfPadding / 3;
    drawChipWithText({ ...defaultChipProps, text, textWidth, x: posX });
    //
    textWidthPrev = textWidth;
    text = `Classes (${classes?.length === 0 ? 'All' : classes?.length})`;
    textWidth = getPdfTextWidth(text, pdf, fontSize);
    posX = posX + textWidthPrev + 2 * defaultChipProps.rx + pdfPadding / 3;
    drawChipWithText({ ...defaultChipProps, text, textWidth, x: posX });
  };
  const generatePdf = async (print: boolean = false) => {
    try {
      setLoading?.(true);
      const pdfPadding = 10;
      const pdfMargin = 20;
      let posX = pdfMargin;
      let posY = pdfPadding + 5;
      const pdfDoc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = pdfDoc.internal.pageSize.getWidth();
      const pageHeight = pdfDoc.internal.pageSize.getHeight();
      // Add a header with Date for referrence.
      pdfDoc.setFontSize(8);
      let headerText = isDistrictAdmin ? myDistrictData?.name : mySchoolData?.name;
      pdfDoc.text(`${headerText}`, posX, posY);
      const date = new Date();
      const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      pdfDoc.text(dateString, pdfDoc.internal.pageSize.width - pdfMargin, posY, { align: 'right' });
      posY += 5;
      pdfDoc.line(posX, posY, pdfDoc.internal.pageSize.width - pdfMargin, posY);
      posY += 2 * pdfPadding;
      // export the respective dsashboard into the PDF to be generated.
      if (location.pathname === AdminDashboards.routePaths.performance) {
        pdfDoc.setFontSize(18);
        pdfDoc.text('Performance Data Dashboard', posX, posY);
        posY += pdfPadding + 4;
        pdfDoc.setFontSize(10);
        pdfDoc.setTextColor(141, 141, 141);
        const lastUpdated = 'Last Updated:' + toReadableFormat(lastSnowflakeUpdateTimeData, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone: 'UTC',
        }, 'en-US').replace('at', '') + 'UTC';
        pdfDoc.text(lastUpdated, posX, posY);
        posY += pdfPadding;
        pdfDoc.setTextColor(51, 51, 51);
        renderFilterChips(pdfDoc, posX, posY, pdfPadding);
        posY += 2 * pdfPadding;
        await PerformancePdf({
          pdfDoc,
          pageWidth,
          pageHeight,
          pdfMargin,
          pdfPadding,
          posX,
          posY,
        });
      } else if (location.pathname === AdminDashboards.routePaths.learningGoals) {
        dashboard = 'Learning Goals Dashboard';
        pdfDoc.setFontSize(18);
        pdfDoc.text('Learning Goals Dashboard', posX, posY);
        posY += pdfPadding + 4;
        pdfDoc.setFontSize(10);
        pdfDoc.setTextColor(141, 141, 141);
        const lastUpdated = 'Last Updated:' + toReadableFormat(lastSnowflakeUpdateTimeData, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone: 'UTC',
        }, 'en-US').replace('at', '') + 'UTC';
        pdfDoc.text(lastUpdated, posX, posY);
        pdfDoc.setTextColor(51, 51, 51);
        posY += pdfPadding;
        renderFilterChips(pdfDoc, posX, posY, pdfPadding);
        posY += 2 * pdfPadding;
        await LearningGoalsPdf({
          pdfDoc,
          pageWidth,
          pageHeight,
          pdfMargin,
          pdfPadding,
          posX,
          posY,
        });
      }
      pdfName += `${headerText}-${dashboard}-${submissionType}-${dateString}`;
      pdfDoc.setProperties({
        title: pdfName,
      });
      if (print) {
        pdfDoc.autoPrint({ variant: 'non-conform' });
        window.open(pdfDoc.output('bloburl'), '_blank');
      } else {
        pdfDoc.save(pdfName);
      }
      setLoading?.(false);
    } catch (e) {
      sentryErrorLog(e, [
        { label: 'pdfErr', value: `Error Generating PDF for ${dashboard}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  };
  return (
    <Stack direction="row" justifyContent="end">
      <StyledIconButton
        color="primary"
        onClick={() => generatePdf()}
        className="print-ignore"
        disabled={areWidgetsLoading || disable}
      >
        <DownloadFileIcon fillColor={(areWidgetsLoading || disable) ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
      <StyledIconButton
        color="primary"
        onClick={() => generatePdf(true)}
        className="print-ignore"
        disabled={areWidgetsLoading || disable}
      >
        <PrintIcon fillColor={(areWidgetsLoading || disable) ? palette.customBackground.disabled : ''}  />
      </StyledIconButton>
    </Stack>
  );
};

export default ExportToPdf;