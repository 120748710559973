import React, { useState } from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  Box,
  IconButton, Modal, SxProps,
} from '@mui/material';
import FullScreenIcon from '../Icons/FullScreenIcon';
import { palette  } from '../../theme/palette';

interface Props {
  imageSrc : string,
  selected?: string,
  thumbnailImageSrc?: string,
  showFullscreenIcon?: boolean,
}
const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const styles = {
  root: {
    width: '110px',
    height: '58px',
    position: 'relative',
    borderRadius: '4px',
    marginRight: '16px',
  },
  cardContentContainer: {
    width: '100%',
    opacity: 0.8,
    bottom: '-1px',
    position: 'absolute',
  },
  cardContentText: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    width: '100%',
    color: palette.customWhite.main,
    backgroundColor: palette.customBlack.fontBlack,
    letterSpacing: 1,
    paddingLeft: '16px',
  },
  fullScreenButton: {
    top: 'unset',
    bottom: '30px',
  },
  fullScreenIcon: {
    height: '20px',
    width: '20px',
  },
  paper: {
    position: 'absolute',
    width: '1000px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullScreenImage: {
    width: '100%',
    height: 'auto',
  },
  wrapperButton: {
    background: palette.customWhite.main,
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    position: 'absolute',
    top: '35%',
    left: '38%',
    display: 'flex',
    justifyContent: 'center',
  },
};

const ImageCardAssignment = ({
  imageSrc,
  selected,
  thumbnailImageSrc,
  showFullscreenIcon = false,
}: Props) => {
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [iconVisible, setIconVisible] = useState(showFullscreenIcon);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fullScreenPreview = (
    <Box style={modalStyle} sx={{ ...styles.paper }}>
      <Box
        component={'img'}
        src={imageSrc}
        sx={{ ...styles.fullScreenImage }}
      />
    </Box>
  );
  return (
    <>
      <Card
        sx={{ ...styles.root }}
        onMouseEnter={() => setIconVisible(true)}
        onMouseLeave={() => setIconVisible(false || showFullscreenIcon)}
      >
        <CardActionArea disableRipple>
          <CardMedia
            sx={{ ...(selected ? { height: '202px' } : { height: 'auto' }), width: '100%' }}
            image={thumbnailImageSrc ? thumbnailImageSrc : imageSrc}
            component="img"
          />
        </CardActionArea>
        <>
          {iconVisible && (
            <Box sx={{ ...styles.wrapperButton } as SxProps}>
              <IconButton
                aria-label="Image Preview"
                onClick={handleOpen}
                disableTouchRipple
                disableRipple
              >
                <FullScreenIcon
                  pathFill={palette.customBlack.fontBlack}
                  sx={{ ...styles.fullScreenIcon }}
                  strokeWidth={8}
                />
              </IconButton>
            </Box>
          )}
        </>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
      >
        {fullScreenPreview}
      </Modal>
    </>
  );
};
export default React.memo(ImageCardAssignment);
