import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledTableContainer from '../../Assignments/AssignmentsTable/List.styled';
import TablePagination from '../../../components/TablePagination';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { BenchmarkTeacherDtoSortType, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import ImageCardAssignment from '../../../components/ImageCardAssignment';
import Loader from '../../../components/Loader/loader';
import { StyledTableSort } from '../../../components/Common/Common.styled';
import { StyledTableCells } from '../../Admin/admin-tables-styled';
import { StylesCss } from '../../Assignments/Assignments.styled';
import { CountTooltip } from '../../Assignments/AssignmentsTable/List';
import { toReadableFormat } from '../../../utils/dateFormat';
import { PAGE_SIZE, READABLE_DATE_OPTIONS_YEAR, READABLE_SHORT_DATE_OPTIONS, SubmissionTypes } from '../../../variables/constant';
import useTeacherBenchmarksList from './teacher-benchmarks-list-hook';
import { BenchmarkTypeOptions, GradedByOptions } from '../../AdminBenchmarks/Create/Steps/DetailsStep';
import useStudentSlideout from '../../AdminBenchmarks/List/StudentListSlideout/student-slideout-common-hook';
import FlyoverHeader from '../../../components/FlyoverHeader';
import FlyoverContainer from '../../../components/FlyoverContainer';
import Submitted from './StudentListSlideout/Submitted';
import NotStarted from './StudentListSlideout/NotStarted';
import InProgress from './StudentListSlideout/InProgress';
import FlyoverDrawer from '../../../components/FlyoverDrawer';


const BenchmarkTable = () => {
  const theme = useTheme();
  const styles = StylesCss(theme);
  const {
    benchmarks, tBenchmarksListRefetch,
    pagination,
    loading,
    totalCount,
    order,
    sort,
    onSortChange,
  } = useTeacherBenchmarksList();

  const {
    showSlideout,
    handleOpenSlideout,
    handleCloseSlideout,
    submissionColor,
    submissionType,
    studentCount, setStudentCount,
    entityId,
    studentGradedBy,
  } = useStudentSlideout();
  const handleDrawerClose = () => {
    handleCloseSlideout();
    tBenchmarksListRefetch();
  };

  const isTableEmpty = !loading && !benchmarks;
  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    // @ts-ignore
    pagination.setPage(value);
  };

  const handleSort = (column: BenchmarkTeacherDtoSortType) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const getNoDataLabels = () => ({
    label: 'No Benchmarks Created Yet',
    description: 'There has yet to be a benchmark created at this time',
  });

  const { label, description } = getNoDataLabels();
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const sortColumn = (title: string, sortEnum: BenchmarkTeacherDtoSortType) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
      mb={2}
    >
      <Box>
        <StyledTableContainer>
          <Table
            stickyHeader
            aria-label="Teacher Benchmarks List"
            sx={{ ...((loading || isTableEmpty) && styles.tableWithNoData), ...(loading && { maxHeight: '60px' }) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCells />
                <StyledTableCells sx={styles.width30}>
                  {sortColumn('Benchmark Title', BenchmarkTeacherDtoSortType.BenchmarkTitle)}
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  { sortColumn('Date', BenchmarkTeacherDtoSortType.StartDate) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width15}>
                  {sortColumn('Benchmark Type', BenchmarkTeacherDtoSortType.BenchmarkType)}
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  {sortColumn('Graded By', BenchmarkTeacherDtoSortType.GradedBy)}
                </StyledTableCells>
                <StyledTableCells sx={styles.width15}>
                  Submissions
                </StyledTableCells>
                <StyledTableCells />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && benchmarks?.map((row) => (
                <TableRow key={row?.benchmark_id}>
                  {/*Image*/}
                  <TableCell>
                    <Box>
                      <ImageCardAssignment
                        imageSrc={row.resource?.file?.url || ''}
                        key={row?.benchmark_id}
                        thumbnailImageSrc={row.resource?.file?.thumbnail_url || row.resource?.file?.url || ''}
                        showFullscreenIcon
                      />
                    </Box>
                  </TableCell>

                  {/*Assignment Title*/}
                  <TableCell
                    sx={styles.assignmentNameCell}
                  >
                    {row.benchmark_title}
                  </TableCell>

                  {/*Date*/}
                  <TableCell
                    sx={{ ...styles.dateRangeCell, ...styles.uppercase }}
                  >
                    <TableRow>
                      {`${toReadableFormat(row.start_date, READABLE_DATE_OPTIONS_YEAR)} -`}
                    </TableRow>
                    <TableRow>
                      {`${toReadableFormat(row.close_date, READABLE_DATE_OPTIONS_YEAR)}`}
                    </TableRow>
                  </TableCell>

                  {/*Benchmark Type*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {
                      BenchmarkTypeOptions.find((benchmarkType) => benchmarkType.value === row.benchmark_type)?.label ?? ''
                    }
                  </TableCell>

                  {/*Graded By*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {GradedByOptions.find(options => options.value === row.graded_by)?.label}
                  </TableCell>

                  {/*Submissions*/}
                  <TableCell
                    sx={styles.countCell}
                  >
                    <Box component="div" sx={styles.flexDesign}>
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.Submitted}
                        count={row.status_aggregation?.Submitted ?? 0}
                        onClick={() => handleOpenSlideout(row.benchmark_id, SubmissionTypes.Submitted, row.status_aggregation?.Submitted ?? 0, row?.graded_by)}
                      />
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.InProgress}
                        count={row.status_aggregation?.InProgress ?? 0}
                        onClick={() => handleOpenSlideout(row.benchmark_id, SubmissionTypes.InProgress, row.status_aggregation?.InProgress ?? 0)}
                      />
                      <CountTooltip
                        tooltipTitle={SubmissionTypes.NotStarted}
                        count={row.status_aggregation?.NotStarted ?? 0}
                        onClick={() => handleOpenSlideout(row.benchmark_id, SubmissionTypes.NotStarted, row.status_aggregation?.NotStarted ?? 0)}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                  >
                    <NoResults
                      label={label}
                      description={description}
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              <Loader open={loading} />
            </TableBody>
          </Table>
        </StyledTableContainer>
        <FlyoverDrawer open={showSlideout} size={'small'} onClose={handleCloseSlideout}>
          <FlyoverHeader
            title={submissionType}
            color={submissionColor}
            onClose={handleDrawerClose}
            subtitle={`${studentCount} ${studentCount !== 1 ? 'Students' : 'Student'}`}
          />
          <FlyoverContainer>
            {submissionType === SubmissionTypes.Submitted && <Submitted benchmarkId={entityId} benchmarkGradedBy={studentGradedBy} setStudentCount={setStudentCount} />}
            {submissionType === SubmissionTypes.NotStarted && <NotStarted benchmarkId={entityId} setStudentCount={setStudentCount} />}
            {submissionType === SubmissionTypes.InProgress && <InProgress benchmarkId={entityId} setStudentCount={setStudentCount} />}
          </FlyoverContainer>
        </FlyoverDrawer>
      </Box>
      {totalCount! > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount! / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
    </Box>
  );
};

export default BenchmarkTable;
