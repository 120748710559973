import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { palette  } from '../../theme/palette';
import { ImitatationUserType } from '../../variables/types';
import { useImitationProvider } from '../../core/imitationContext';
import useTeacher from '../../pages/Teachers/Teacher/teacher-hook';
import useStudent from '../../pages/AdminStudents/Student/student-hook';
import useRole from '../../hooks/useRole';
import { useDistrictAdminQuery, useSchoolAdminQuery } from '../../generated/graphql';

const styles = {
  imitationMessage: {
    fontSize: '18px',
    textDecoration: 'none',
    fontWeight: 700,
    color: palette.customWhite.main,
    minHeight: '60px',
    backgroundColor: palette.customBlue.primaryBlue,
  },
  logoutBtn: {
    '&.MuiButtonBase-root': {
      padding: '8px 16px',
      marginLeft: '24px',
    },
  },
};

interface BannerProps {
  imitationId?: string,
}

const TeacherBanner = ({
  imitationId,
}: BannerProps) => {
  const { teacher, loading } = useTeacher({ teacherId: imitationId });

  return (
    <>
      {
        !loading &&
        `Logged in as ${teacher?.first_name || ''} ${teacher?.last_name || ''}, ${teacher?.job_title || ''},
        at ${teacher?.district || ''}, ${teacher?.state_code || ''}`
      }
    </>
  );
};

const StudentBanner = ({
  imitationId,
}: BannerProps) => {
  const { student, loading } = useStudent({ studentId: imitationId });

  return (
    <>
      {
        !loading &&
        `Logged in as ${student?.first_name || ''} ${student?.last_name || ''}, Student at
        ${student?.school_name || ''}`
      }
    </>
  );
};

const AdminBanner = ({
  imitationId,
}: BannerProps) => {
  const { isSchoolAdmin, isDistrictAdmin } = useRole();

  const useQeury: any = isSchoolAdmin ? useSchoolAdminQuery : useDistrictAdminQuery;

  const { data, loading } = useQeury({
    fetchPolicy: 'network-only',
    variables: {
      id: imitationId!,
    },
  });
  const adminDetails: any = isDistrictAdmin ? data?.districtAdmin : (isSchoolAdmin ? data?.schoolAdmin : undefined);

  return (
    <>
      {
        !loading &&
        `Logged in as ${adminDetails?.first_name || ''} ${adminDetails?.last_name || ''}
        at ${isSchoolAdmin ? adminDetails?.schoolData[0]?.name : ''} ${adminDetails?.districtData?.name || ''},
        ${adminDetails?.districtData?.state?.state_id || ''}`
      }
    </>
  );
};

const ImitationBanner = () => {
  const {
    getImitationUserId,
    getImitationUserType,
    getImitationId,
    handleImitationLogout,
  } = useImitationProvider();
  const imitationUserId = getImitationUserId();
  const imitationUserType = getImitationUserType();
  const imitationId = getImitationId();

  return (
    <Box
      sx={{ ...styles.imitationMessage }}
      display="grid"
      gridTemplateColumns="210px 1fr"
      justifyContent="center"
    >
      <Box />
      {
        imitationUserId && imitationUserType && imitationId && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box>
              {imitationUserType === ImitatationUserType.Teacher ? <TeacherBanner imitationId={imitationId} /> : ''}
              {imitationUserType === ImitatationUserType.Student ? <StudentBanner imitationId={imitationId} /> : ''}
              {imitationUserType === ImitatationUserType.DistrictAdmin ? <AdminBanner imitationId={imitationId} /> : ''}
              {imitationUserType === ImitatationUserType.SchoolAdmin ? <AdminBanner imitationId={imitationId} /> : ''}
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleImitationLogout}
                sx={{ ...styles.logoutBtn }}
              >
                Log out
              </Button>
            </Box>
          </Box>
        )
      }
    </Box>
  );
};

export default React.memo(ImitationBanner);
