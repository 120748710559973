import React, { useEffect } from 'react';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { DistrictWideSchoolDetails, DistrictWideSchoolDetailsSortType } from '../../../../generated/graphql';
import List from './List';
import { drawAutotableRowBorders, drawAutotableCellChipWithText } from '../../../../utils/pdf';
import { DistrictWideQueryInput } from '../district-wide-comparisons-hook';
import usePagination from '../../../../utils/usePagination';

interface Props {
  pdfDoc: jsPDF,
  posY: number,
  styles: any,
  schoolsData: DistrictWideSchoolDetails[],
}

export const districtWideSchoolsPdfTable = ({ pdfDoc, posY, styles, schoolsData }: Props) => {
  const districtWideSchoolsTable = {
    0: {
      'key': 'school_name',
      'name': 'Name',
    },
    1: {
      'key': 'teachers',
      'name': 'Teachers',
    },
    2: {
      'key': 'students',
      'name': 'Students',
    },
    3: {
      'key': 'goals_assigned',
      'name': 'Goals Assigned',
    },
    4: {
      // `${school?.pts_completed} / ${school?.pts_assigned}
      'key': 'pts_completed',
      'name': 'PTs Completed',
    },
    5: {
      'key': 'avg_speaking_score',
      'name': 'Speaking',
    },
    6: {
      'key': 'avg_writing_score',
      'name': 'Writing',
    },
  };
  const getColVal = (school: any, colkey: string) => {
    if (colkey === 'pts_completed') {
      return `${school?.pts_completed} / ${school?.pts_assigned}`;
    }
    return school[colkey];
  };
  const tableHeader: RowInput = [];
  Object.values(districtWideSchoolsTable).forEach((column: any) => tableHeader.push(column.name));
  const tableRows: RowInput[] = [];
  schoolsData.forEach(school => {
    const dataRow: RowInput = [];
    Object.values(districtWideSchoolsTable).forEach(column => dataRow.push(getColVal(school, column.key)));
    tableRows.push(dataRow);
  });
  autoTable(pdfDoc, {
    head: [tableHeader],
    body: tableRows,
    theme: 'plain',
    margin: styles.margin,
    headStyles: styles.header,
    styles: styles.rows,
    columnStyles: styles.columns,
    didDrawCell: (data) => {
      drawAutotableRowBorders(data, pdfDoc);
      drawAutotableCellChipWithText(data, pdfDoc, [5, 6]);
    },
    startY: posY,
  });
  //
  // autoTable(pdfDoc, {
  //   html: '#dw-schools-html-table',
  //   theme: 'grid',
  //   includeHiddenHtml: true,
  //   useCss: true,
  //   // tableWidth: 'wrap',
  // });
};

interface HTMLTableProps {
  loading: boolean,
  queryInput: DistrictWideQueryInput,
  districtWideSchoolsData: DistrictWideSchoolDetails[],
  setPdfHTMLRendered: Function,
}

export const DistrictWideSchoolsHTMLTable = ({
  loading, queryInput, districtWideSchoolsData, setPdfHTMLRendered,
}: HTMLTableProps) => {
  useEffect(() => setPdfHTMLRendered(true), []);
  const pagination = usePagination('dw-schools-html-table');
  return (
    <List
      tableDomId="dw-schools-html-table"
      isTableEmpty={districtWideSchoolsData.length === 0}
      loading={loading}
      totalCount={districtWideSchoolsData.length as number}
      pagination={pagination}
      limit={queryInput.limit!}
      sort={queryInput.sort! as DistrictWideSchoolDetailsSortType}
      order={queryInput.orderBy!}
      schools={districtWideSchoolsData}
      handlePageChange={() => {}}
      handleSort={() => {}}
    />
  );
};