import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { isIpad } from '../../variables/constant';

export const StyledLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.fontColors.fontBlack,
  fontWeight: 800,
  lineHeight: '25px',
  fontSize: isIpad ? '14px' : '18px',
}));


export const StyledMicIcon = styled(Button)({
  minWidth: 50,
  width: 50,
  height: 50,
  borderRadius: '50%',
  '&::after': {
    borderRadius: '50%',
  },
});
