import { useEffect, useState } from 'react';
import { AdminDashboardFilterInput, useStudentCompletionRatesWidgetQuery } from '../../../../generated/graphql';
import { AdminDashboards } from '../../../../variables/constant';
import { useDashboardContext } from '../../admin-analytics-hook';

interface QueryInputProps {
  district_id?: string,
  school_id?: string,
  input?: AdminDashboardFilterInput,
}

const useStudentCompletionRate = () => {
  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    analyticsFilters: queryFilters,
    updateDashboardWidgetsDataStatus,
    sessionEndDate, sessionStartDate,
    startDateSelected, endDateSelected,
  } = useDashboardContext();

  const [hasData, setHasData] = useState();
  const queryInput: QueryInputProps = {
    input: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.school_id = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData) queryInput.district_id = myDistrictData?.id;

  const { data, loading: studentCompletionRateLoading } = useStudentCompletionRatesWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryInput },
  });

  useEffect(() => {
    const studentCompletionRateDataMissing = data?.performanceWideStudentCompletionRateData
      ?.performanceWideStudentCompletionRateBar.map((rateData) => rateData?.pts_assigned as number)
      .reduce((total: any, num: any) => total + num, 0)!;
    setHasData(studentCompletionRateDataMissing);
    updateDashboardWidgetsDataStatus?.({
      widget: AdminDashboards.performance.studentCompletionRatesWidget.name,
      loading: studentCompletionRateLoading,
      dataMissing: !studentCompletionRateDataMissing && !data?.performanceWideStudentCompletionRateData?.percentage_of_pts_submitted_and_graded_for_ytd,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentCompletionRateLoading, data]);

  return ({
    studentCompletionRateLoading,
    studentCompletionRateData: data?.performanceWideStudentCompletionRateData?.performanceWideStudentCompletionRateBar,
    pieValue: data?.performanceWideStudentCompletionRateData?.percentage_of_pts_submitted_and_graded_for_ytd,
    sessionEndDate, sessionStartDate,
    currStartDate: startDateSelected, currEndDate: endDateSelected,
    completeData: data,
    title: 'Student Completion Rates',
    hasData,
  });
};

export default useStudentCompletionRate;
