import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 12,
  backgroundColor: theme.palette.customWhite.main,
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: 580,
}));

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  height: 65,
  display: 'flex',
  gap: 10,
  padding: theme.spacing(3),
  borderBottom: `2px solid ${theme.palette.customBackground.disabled}`,
}));

export const StyledBodyBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const StyledPaddedBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 3),
}));
