import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { truncateString } from '../../../../utils/truncateString';

type Props = {
  student: any;
  maxChars: number;
  typographySx?: any;
  handleRedirection?: Function;

};
const TruncateStudentName = ({ maxChars, typographySx = {}, handleRedirection, student }: Props) => {
  const studentName = student?.first_name! + ' ' + student?.last_name!;
  return (
    studentName.length > maxChars ?
      <Tooltip title={studentName} placement='top'>
        <Typography variant='tableTitleData' sx={{ ...typographySx }} onClick={handleRedirection?.(student?.submission_id as string, student?.submission_status)} noWrap>
          {truncateString(studentName, maxChars)}
        </Typography>
      </Tooltip> :
      <Typography variant='tableTitleData' sx={{ ...typographySx }} 
        onClick={handleRedirection?.(student?.submission_id as string, student?.submission_status)} noWrap> 
        {truncateString(studentName, maxChars)}</Typography>
  );
};

export default TruncateStudentName;