/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

interface Props {
  strokeColor?: string,
  className?: string,
  strokeWidth?: number,
  svgWidth?: string | number,
  svgHeight?: string | number,
  viewBox?: string,
}

const DeleteIcon = ({ strokeColor, className, strokeWidth, svgWidth = 18, svgHeight = 20, viewBox = '0 0 18 20' }: Props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: svgWidth,
        height: svgHeight,
      }}
      viewBox={viewBox}
      fill="none"
      className={className}
    >
      <path d="M1 5H17M16 5L15.133 17.142C15.0971 17.6466 14.8713 18.1188 14.5011 18.4636C14.1309 18.8083 13.6439 19 13.138 19H4.862C4.35614 19 3.86907 18.8083 3.49889 18.4636C3.1287 18.1188 2.90292 17.6466 2.867 17.142L2 5H16ZM7 9V15V9ZM11 9V15V9ZM12 5V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H7C6.73478 1 6.48043 1.10536 6.29289 1.29289C6.10536 1.48043 6 1.73478 6 2V5H12Z" stroke={strokeColor || palette.customRed.buttonFontRed} strokeWidth={strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const DeleteIconNoBorder = ({ strokeColor, className, strokeWidth, svgWidth = 21, svgHeight = 24, viewBox = '0 0 18 20' }: Props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: svgWidth,
        height: svgHeight,
      }}
      viewBox={viewBox}
      fill="none"
      className={className}
    >
      <path d="M 1 5 H 17 M 16 5 L 15.133 17.142 C 15.0971 17.6466 14.8713 18.1188 14.5011 18.4636 C 14.1309 18.8083 13.6439 19 13.138 19 H 4.862 C 4.3561 19 3.8691 18.8083 3.4989 18.4636 C 3.1287 18.1188 2.9029 17.6466 2.867 17.142 L 2 5 H 16 Z M 7 9 V 9 Z M 11 9 V 9 Z M 12 5 V 2 C 12 1.7348 11.8946 1.4804 11.7071 1.2929 C 11.5196 1.1054 11.2652 1 11 1 H 7 C 6.7348 1 6.4804 1.1054 6.2929 1.2929 C 6.1054 1.4804 6 1.7348 6 2 V 5 H 12 Z" stroke={palette.customWhite.main} strokeWidth={strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M 7 9 V 15 V 9 Z M 11 9 V 15 V 9 Z Z" stroke={strokeColor || palette.customRed.buttonFontRed} strokeWidth={strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default DeleteIcon;
