import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
  SxProps,
} from '@mui/material';
import { Link } from 'react-router-dom';
import  { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { TeachersSort, PaginationProps } from '../../../variables/types';
import CustomPagination from '../../../components/CustomPagination';
import { ROWS_PER_PAGE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { OrderBy, TeacherDetailsFragmentFragment, SchoolInfo } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import {
  StyledTableCell,
  StyledTableDataCell,
  StyledTableDataCellWidth10,
  StyledTableDataColumn,
} from '../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../components/Common/Common.styled';
import useRole from '../../../hooks/useRole';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: TeachersSort,
  order: OrderBy,
  teachers?: TeacherDetailsFragmentFragment[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: TeachersSort) => void) | Function,
  marginTop?: number,
  sxProps?: SxProps,
  rowsPerPage: number,
  setRowsPerPage: Function,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  teachers,
  handleSort,
  marginTop = 2, sxProps,
  rowsPerPage,
  setRowsPerPage,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const { isDistrictAdmin, isSchoolAdmin } = useRole();
  const sortColumn = (title: string, sortEnum: TeachersSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  return (
    <Box
      display="grid"
      width="100%"
      mt={marginTop}
    >
      <Box>
        <StyledContainerRoot sx={ sxProps ?? {}}>
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableDataCell sx={{ textAlign: 'left' }}>
                  { sortColumn('Teacher', TeachersSort.TeacherFirstName) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('Teacher ID', TeachersSort.TeacherId) }
                </StyledTableDataCell>
                <StyledTableDataCellWidth10>
                  { sortColumn('Job Title', TeachersSort.JobTitle) }
                </StyledTableDataCellWidth10>
                <StyledTableDataCell>
                  { sortColumn('District', TeachersSort.District) }
                </StyledTableDataCell>
                <StyledTableCell>
                  School
                </StyledTableCell>
                <StyledTableDataCellWidth10>
                  { sortColumn('Source', TeachersSort.Source) }
                </StyledTableDataCellWidth10>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && teachers?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography
                      component={Link}
                      variant='redirectionLinks'
                      to={`/teachers/${row.id}`}
                    >
                      {`${row?.first_name ? row.first_name : ''} ${row?.last_name ? row?.last_name : ''}`}
                    </Typography>
                  </TableCell>
                  <StyledTableDataColumn>
                    {row?.teacher_id}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.job_title}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    <Typography
                      component={Link}
                      variant='redirectionLinks'
                      to={isDistrictAdmin || isSchoolAdmin ? '/districts/my-district' : `/districts/${row?.district_id}`}
                    >
                      {row?.district}
                    </Typography>
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.school?.length < 2 ? row?.school?.map((school: SchoolInfo | undefined | null, index: number) =>(
                      <Typography
                        key={school?.id || index}
                        component={Link}
                        variant='redirectionLinks'
                        to={isSchoolAdmin ? '/schools/my-school' : `/schools/${school?.id}`}
                      >
                        {school?.name || '-'}
                      </Typography>)) : 'Multiple'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    <Typography variant='capitalized'>
                      {row?.source}
                    </Typography>
                  </StyledTableDataColumn>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="No Teachers"
                      description="There has yet to be a teacher created."
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      <CustomPagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={pagination.page}
        onChange={handlePageChange}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};
export default List;
