import React from 'react';
import { CleverSyncDetails } from '../../generated/graphql';
import List from '../DistrictSync/DistrictSyncTable/List';
import SyncDetails from '../DistrictSync/DistrictSyncTable/SyncDetails';
import useSyncJobList from '../DistrictSync/DistrictSyncTable/synclog-list-hook';

interface Props {
  districtID?: string;
}

const SyncsList = ({ districtID }: Props) => {
  const {
    syncJobList,
    totalCount,
    isTableEmpty,
    loading,
    sort,
    order,
    pagination,
    handlePageChange,
    handleSort,
    handleViewButton,
    setViewSyncDistrictDetails,
    setSyncDetailId,
    viewSyncDistrictDetails,
    syncDetails,
    loadingDetails,
    rowsPerPage,
    setRowsPerPage,
  } = useSyncJobList({ districtID, });
  return (
    <>
      <List
        marginTop={0}
        sxProps={{ borderTopLeftRadius: '0px' }}
        isTableEmpty={isTableEmpty}
        pagination={pagination}
        loading={loading}
        totalCount={totalCount || 0}
        sort={sort}
        order={order}
        syncJobList={syncJobList}
        handlePageChange={handlePageChange}
        handleSort={handleSort}
        handleViewButton={handleViewButton}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <SyncDetails
        openDialog={!!viewSyncDistrictDetails}
        syncDetails={syncDetails as CleverSyncDetails}
        loading={loadingDetails}
        handleClose={() => { setViewSyncDistrictDetails(false); setSyncDetailId(''); }}
      />
    </>
  );
};

export default SyncsList;