/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SchoolAdminIcon = (props: any) => (
  <SvgIcon
    fontSize="small"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g id="icon-school-admin">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 4V3C4 1.34315 5.34315 0 7 0H9C10.6569 0 12 1.34315 12 3V4H14C15.1046 4 16 4.89543 16 6V9.57076C13.5096 10.4947 10.8149 10.9999 8.00002 10.9999C5.18514 10.9999 2.49037 10.4947 0 9.57075V6C0 4.89543 0.895431 4 2 4H4ZM6 3C6 2.44772 6.44772 2 7 2H9C9.55228 2 10 2.44772 10 3V4H6V3ZM7 8C7 7.44772 7.44772 7 8 7H8.01C8.56229 7 9.01 7.44772 9.01 8C9.01 8.55229 8.56229 9 8.01 9H8C7.44772 9 7 8.55229 7 8Z" fill={props?.pathfill} />
      <path d="M0 11.6923V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V11.6923C13.4872 12.5404 10.7964 12.9999 8.00002 12.9999C5.20363 12.9999 2.51279 12.5404 0 11.6923Z" fill={props?.pathfill} />
    </g>
  </SvgIcon>
);

export default SchoolAdminIcon;
