import React, { useContext, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  MenuItem,
  Menu,
  Box,
  IconButton,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Sidebar from '../Sidebar/index';
import {
  NavBarStyledNavigationButton,
  NavBarNameStyledTypography,
  NavBarSchoolStyledTypography, StyledAddBtn, StyledBox,
} from './NavBar.styled';
import InfoIcon from '../Icons/InfoIcon';
import VideoModal from './VideoModal';
import useNavbar from './navbar-hook';
import useFeature from '../../hooks/useFeature';
import useRole from '../../hooks/useRole';
import ImitationBanner from '../ImitationBanner';
import { SHARED_BUCKET_URL } from '../../variables/constant';
import { SearchContext } from '../../core/searchContext';
import SearchBar from '../SearchBar';
import usePagination from '../../utils/usePagination';
import { palette } from '../../theme/palette';
import zIndex from '@mui/material/styles/zIndex';


const styles = {
  appBar: {
    borderBottom: `2px solid ${palette.customBackground.disabled}`,

  },
  toolBar: {
    paddingLeft: 0,
    marginLeft: '260px',
    justifyContent: 'space-between',
  },
  dropdownPaper: {
    '& div.MuiMenu-paper': {
      width: '280px',
    },
  },
  separator: {
    backgroundColor: palette.customBackground.disabled,
    height: '50px',
    width: '1px',
  },
  infoIcon: {
    width: '24px',
    height: '24px',
  },
};

interface Props {
  imitationUserId?: String
}

const Index = ({
  imitationUserId,
}: Props) => {
  const history = useHistory();
  const {
    anchorEl,
    user,
    isMenuOpen,
    handleProfileMenuOpen,
    handleMenuClose,
    signOut,
    showVideoModal,
    hideVideoModal,
    showVideo,
  } = useNavbar();
  const pagination = usePagination();
  const { isEnabled: isPasswordFlowEnable } = useFeature('PASSWORD_FLOW');
  const { isTeacher, isStudent, isSuperAdmin } = useRole();
  const { isEnabled: isDemoFeatureEnabled } = useFeature('DEMO_FEATURES');
  const { placeholder, isSearchEnabled, searchTerm, onSearch, setSearchEnabled, setTabSection } = useContext(SearchContext);
  const navigateToResetPassword = () => {
    history.push('/reset-password');
  };

  useEffect(() => {
    setTabSection?.('');
    setSearchEnabled?.();

  }, []);
  const setSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    pagination.setPage(1);
    onSearch?.(e);
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="primary-account-menu"
      transformOrigin={{
        vertical: -40,
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      disableAutoFocusItem
      sx={{ ...styles.dropdownPaper }}
    >
      {isTeacher && isPasswordFlowEnable && (
        <MenuItem onClick={navigateToResetPassword} key={1}>
          Reset Password
        </MenuItem>
      )}
      <MenuItem onClick={signOut} key={2}>Sign out</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        color="inherit"
        sx={{ ...styles.appBar }}
      >
        {
          imitationUserId && (
            <ImitationBanner />
          )
        }
        <Toolbar sx={{ ...styles.toolBar }}>
          {isSuperAdmin && isSearchEnabled ? (
            <StyledBox>
              <SearchBar handleChange={setSearchTerm} searchTerm={searchTerm} placeholder={placeholder} mt={0} mb={0} isCommon />
            </StyledBox>
          ) : <div />}
          <Box display="flex">
            {isStudent && (
              <>
                <IconButton
                  key="info"
                  aria-label="Video Tutorial"
                  onClick={showVideoModal}
                  disableTouchRipple
                  disableRipple
                >
                  <InfoIcon
                    pathFill={palette.customBlue.primaryBlue}
                    sx={{ ...styles.infoIcon }}
                  />
                </IconButton>
                <Box sx={{ ...styles.separator }} />
              </>
            )}
            {isSuperAdmin && isDemoFeatureEnabled && (
              <StyledAddBtn
                variant="contained"
                color="primary"
                component={Link}
                to="/admin/create/demo"
                startIcon={<AddIcon />}
              >
                Add Demo Accounts
              </StyledAddBtn>
            )}
            <NavBarStyledNavigationButton
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <NavBarNameStyledTypography>
                  {`${user?.first_name} ${user?.last_name}`}
                </NavBarNameStyledTypography>
                <NavBarSchoolStyledTypography>
                  {user?.school}
                </NavBarSchoolStyledTypography>
              </Box>
            </NavBarStyledNavigationButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        zIndex={1200}
      >
        <Sidebar />
      </Box>
      {!imitationUserId && renderProfileMenu}
      {showVideo && (
        <VideoModal
          open={showVideo}
          onClose={hideVideoModal}
          url={`${SHARED_BUCKET_URL}/videos/teacher-toolbox-videos/primary-student-tutorial.mp4`}
          showCloseButton
        />
      )}
    </>
  );
};

export default Index;
