import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GraphSetData, ScoreType, Skills, SpeakingSkills, WritingSkills, ScoreSchemeTitleKeys } from '../../../variables/types';
import { SKILL_TYPE_TEXT, SPEAKING_SKILL_TYPE_TEXT, WRITING_SKILL_TYPE_TEXT } from '../../../variables/constant';
import useRedirect from '../../../hooks/useRedirect';
import useSpeaking from '../Speaking/speaking-hook';
import useWriting from '../Writing/writing-hook';
import { useAuthProvider } from '../../../core/authContext';

interface RouteProp {
  section?: Skills;
  skillType?: SpeakingSkills | WritingSkills;
  skillKey?: ScoreType;
}

const getIndexBySection = (skillKey?: ScoreType) => {
  switch (skillKey) {
    case ScoreType.emerging:
      return 1;
    case ScoreType.expanding:
      return 2;
    case ScoreType.bridging:
      return 3;
    case ScoreType.advanced:
      return 4;
    case ScoreType.limited:
    default:
      return 0;
  }
};

const getIndexBySectionTexas = (skillKey?: ScoreType) => {
  switch (skillKey) {
    case ScoreType.intermediate:
      return 1;
    case ScoreType.texasAdvanced:
      return 2;
    case ScoreType.advancedHigh:
      return 3;
    case ScoreType.beginner:
    default:
      return 0;
  }
};

const usePerformanceDetails = () => {
  const { section, skillType, skillKey } = useParams<RouteProp>();
  const [tab, setTab] = useState(getIndexBySection(skillKey));
  const { navigateTo } = useRedirect();
  const { getData: getSpeakingData, loading: speakingLoading } = useSpeaking();
  const { getData: getWritingData, loading: writingLoading } = useWriting();
  const { getUser } = useAuthProvider();
  const user = getUser();

  useEffect(() => {
    (user?.score_scheme.title_key === ScoreSchemeTitleKeys.Scheme1) ? setTab(getIndexBySectionTexas(skillKey)) : setTab(getIndexBySection(skillKey));
  }, [skillKey, user]);

  const getData = () => {
    if (section === Skills.Speaking) {
      return getSpeakingData(skillType as SpeakingSkills);
    }
    return getWritingData(skillType as WritingSkills);
  };

  const navigateToTab = (route: string) => (data: GraphSetData | null) => {
    navigateTo(`${route}/${data?.skill_key ?? ''}`);
  };

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    switch (newValue) {
      case 1:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.emerging}`);
        return;
      case 2:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.expanding}`);
        return;
      case 3:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.bridging}`);
        return;
      case 4:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.advanced}`);
        return;
      case 0:
      default:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.limited}`);
    }
  };

  const handleChangeForTexas = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    switch (newValue) {
      case 1:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.intermediate}`);
        return;
      case 2:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.texasAdvanced}`);
        return;
      case 3:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.advancedHigh}`);
        return;
      case 0:
      default:
        navigateTo(`/analytics/${section}/details/${skillType}/${ScoreType.beginner}`);
    }
  };

  const title = useMemo(() => (section ? (section === Skills.Speaking
    ? `${SKILL_TYPE_TEXT?.[section]} Performance: ${SPEAKING_SKILL_TYPE_TEXT?.[skillType as SpeakingSkills]}`
    : `${SKILL_TYPE_TEXT?.[section]} Performance: ${WRITING_SKILL_TYPE_TEXT?.[skillType as WritingSkills]}`) : ''), [section, skillType]);

  const redirectionLinkText = useMemo(() => (section ? (section === Skills.Speaking
    ? `Back to ${SKILL_TYPE_TEXT?.[section]} Overview`
    : `Back to ${SKILL_TYPE_TEXT?.[section]} Overview`) : ''), [section]);

  const { graphData, totalCount, image } = getData();
  const loading = speakingLoading || writingLoading;

  return ({
    tab,
    setTab,
    title,
    loading,
    graphData,
    totalCount,
    navigateToTab,
    section,
    skillType,
    redirectionLinkText,
    image,
    user,
    handleChange,
    handleChangeForTexas,
  });
};

export default usePerformanceDetails;
