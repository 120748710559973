import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.fontColors.fontBlack,
  fontSize: 24,
  lineHeight: '32.74px',
  letterSpacing: '0.1px',
  fontWeight: 800,
}));
export const GoalContainer = styled(Box)({
  borderLeft: 'none',
  borderRight: 'none',
});
export const GoalDescription = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '21.82px',
});
