import React from 'react';
import { palette } from '../../../../theme/palette';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import SubmissionsIcon from '../../../../components/Icons/SubmissionsIcon';
import CompletedIcon from '../../../../components/Icons/CompletedIcon';
import { getMonthNameLong } from '../../../../components/AdminGraphs/CommonFunctions';

interface Props {
  active?: string,
  payload?: any,
}

const style = {
  dialogBox: {
    padding: 10,
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customRed.progressPrimary}`,
    width: 'auto',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: `0 3px 5px 1px ${palette.customBackground.disabled}`,
  },
};

const CustomTooltip = ({ active, payload }:Props) => {
  const dataVar = payload?.[0]?.payload;
  const calendarMonth = getMonthNameLong(dataVar?.month);
  const totalUnits = dataVar?.pts_assigned;
  const consumedUnits = dataVar?.pts_submitted_and_graded;
  const displayYear = dataVar?.year;

  if (active && totalUnits != null) {
    return (
      <Box className="line-chart-tooltip" style={style.dialogBox}>
        <Typography
          component='p'
          variant="toolTipGreyText"
          marginBottom="5px"
          marginLeft="10px"
        >
          {calendarMonth} {displayYear}
        </Typography>
        <Typography variant="toolTipValue"
          marginBottom="5px"
          marginLeft="10px">
          Assignments
        </Typography>
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SubmissionsIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="toolTipText">Created: </Typography>}
              />
              <ListItemText disableTypography
                primary={<Typography variant="toolTipValue">{ totalUnits }</Typography>}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CompletedIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="toolTipText">Completed: </Typography>}
              />
              <ListItemText disableTypography
                primary={<Typography variant="toolTipValue" paddingLeft="10px">
                  { consumedUnits > 0 ? consumedUnits : 0 }</Typography>}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    );
  }

  return null;
};

export default CustomTooltip;
