/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface Props{
  fillColor?: string
}
const AnalyticsIcon = ({ fillColor }:Props) => {
  return (
    <SvgIcon
      fontSize="small"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
    >
      <g id="icon / nav open">
        <path d="M21.2758 3.85958C21.5013 3.9475 21.7438 4 22 4C23.1046 4 24 3.10458 24 2C24 0.895417 23.1046 0 22 0C20.8954 0 20 0.895417 20 2C20 2.10125 20.0154 2.19833 20.0296 2.29542L16.0571 5.47333C15.8321 5.38542 15.5892 5.33292 15.3329 5.33292C15.0767 5.33292 14.8342 5.38542 14.6087 5.47333L10.6371 2.29583C10.6517 2.19875 10.6667 2.10125 10.6667 2C10.6667 0.895417 9.77125 0 8.66667 0C7.56208 0 6.66667 0.895417 6.66667 2C6.66667 2.17792 6.6975 2.3475 6.74083 2.51167L2.51167 6.74083C2.3475 6.6975 2.17792 6.66667 2 6.66667C0.895417 6.66667 0 7.56208 0 8.66667C0 9.77125 0.895417 10.6667 2 10.6667C3.10458 10.6667 4 9.77125 4 8.66667C4 8.48875 3.96917 8.31917 3.92583 8.155L8.155 3.92583C8.31917 3.96917 8.48875 4 8.66667 4C8.92292 4 9.16542 3.9475 9.39083 3.85958L13.3633 7.0375C13.3488 7.13458 13.3338 7.23208 13.3338 7.33333C13.3338 8.43792 14.2292 9.33333 15.3338 9.33333C16.4383 9.33333 17.3338 8.43792 17.3338 7.33333C17.3338 7.23208 17.3183 7.135 17.3042 7.03792L21.2758 3.85958ZM16.6667 13.3333H14C13.6317 13.3333 13.3333 13.6317 13.3333 14V20.6667C13.3333 21.035 13.6317 21.3333 14 21.3333H16.6667C17.035 21.3333 17.3333 21.035 17.3333 20.6667V14C17.3333 13.6317 17.035 13.3333 16.6667 13.3333ZM23.3333 8H20.6667C20.2983 8 20 8.29833 20 8.66667V20.6667C20 21.035 20.2983 21.3333 20.6667 21.3333H23.3333C23.7017 21.3333 24 21.035 24 20.6667V8.66667C24 8.29833 23.7017 8 23.3333 8ZM10 8H7.33333C6.965 8 6.66667 8.29833 6.66667 8.66667V20.6667C6.66667 21.035 6.965 21.3333 7.33333 21.3333H10C10.3683 21.3333 10.6667 21.035 10.6667 20.6667V8.66667C10.6667 8.29833 10.3683 8 10 8ZM3.33333 14.6667H0.666667C0.298333 14.6667 0 14.965 0 15.3333V20.6667C0 21.035 0.298333 21.3333 0.666667 21.3333H3.33333C3.70167 21.3333 4 21.035 4 20.6667V15.3333C4 14.965 3.70167 14.6667 3.33333 14.6667Z"
          fill={fillColor || 'inherit'}
        />
      </g>
    </SvgIcon>
  );
};

export default AnalyticsIcon;
