import React from 'react';
import { Grid, Box, Typography, Theme, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';
import useCreateBenchmark from '../../create-benchmark-hook';
import { StepProp } from '../../../../Assignments/CreateAssignment/create-assignment-hook';
import { CreateAssignmentStylesCss } from '../../../../Assignments/CreateAssignment/CreateAssignment.styled';
import FormProgressBar from '../../../../../components/FormProgressBar';
import { CreateBenchmarkSteps } from '../../../../../variables/constant';
import StaticResources from '../../../../Assignments/StaticResources';
import { StyledDangerButton, StyledNextButton } from '../../../../Assignments/Assignments.styled';
import AssignmentNavigationModal from '../../../../Assignments/CreateAssignment/Steps/AssignmentNavigationModal';

interface ImageSelectorProp {
  onChange: (
    selectedItemKey: string | number | null, keyboardString?: string
  ) => void,
  value?: string,
}

const SelectImageStep = ({ title }: StepProp) => {
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);

  const {
    routeData,
    benchmarkInput,
    submitStep,
    backToBenchmarks,
    handlePrev,
    loading,
    setStaticResourceImgSelected,
    setGrades,
    selectedGrade,
    setSelectedGrade,
    searchTerm,
    setSearchTerm,
    selectedSubjects,
    subjectAreas,
    setSubjectAreas,
    selectedTaskTypes,
    setTaskTypes,
    onSearch,
    resetFilters,
    updateBenchmarkContext,
    openNavigationModal,
    setOpenNavigationModal,
  } = useCreateBenchmark();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useFormContext();

  return (
    <>
      <Box sx={styles.formatTopNavBar}>
        <Grid container xs={12}>
          <Grid item xs={3}>
            <Typography variant='secondaryTitle'>{title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormProgressBar
              steps={ CreateBenchmarkSteps }
              progressValue={routeData?.currentStep! - 1}
            />
          </Grid>
        </Grid>
      </Box>
      <form
        name="assignment-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(submitStep)}
      >
        <Box>
          <Grid
            xs={12}
          >
            <Box sx={styles.formItemContainer}>
              <Controller
                name="resource_id"
                defaultValue={benchmarkInput?.resource_id!}
                value={benchmarkInput?.resource_id!}
                render={(
                  { onChange, value }: ImageSelectorProp,
                ) => (
                  <StaticResources
                    onChange={onChange}
                    selected={value}
                    isEditMode
                    isBenchmark={true}
                    loading={loading}
                    setStaticResourceImgSelected={setStaticResourceImgSelected}
                    setGrades={setGrades}
                    selectedGrade={selectedGrade}
                    setSelectedGrade={setSelectedGrade}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    selectedSubjects={selectedSubjects}
                    subjectAreas={subjectAreas}
                    setSubjectAreas={setSubjectAreas}
                    selectedTaskTypes={selectedTaskTypes}
                    setTaskTypes={setTaskTypes}
                    onSearch={onSearch}
                    resetFilters={resetFilters}
                    updateContextInput={updateBenchmarkContext}
                  />
                )}
                rules={{ required: 'Please select the image.' }}
                control={control}
              />
              <Typography variant='sFormHelper'>
                {errors.resource_id && errors.resource_id.message}
              </Typography>
            </Box>
          </Grid>
          <Box sx={styles.benchmarkFormBottomNavBar as  SxProps<Theme> }>
            <Box>
              <StyledNextButton
                variant="outlined"
                color="primary"
                onClick={() => { handlePrev(getValues()); }}
              >
                Back
              </StyledNextButton>
            </Box>
            <Box>
              <StyledDangerButton
                variant="outlined"
                color="primary"
                onClick={() => setOpenNavigationModal(true)}
                sx={{ mr: 2 }}
              >
                Cancel
              </StyledDangerButton>
              <StyledNextButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={!benchmarkInput?.resource_id}
              >
                Next
              </StyledNextButton>
            </Box>
          </Box>
        </Box>
      </form>
      <AssignmentNavigationModal openModal={openNavigationModal} handleClose={() => { setOpenNavigationModal(false); }} handleNext={() => { backToBenchmarks(); }} isBenchmarksPage={true} />
    </>
  );
};

export default SelectImageStep;
