import React from 'react';
import { Input, MenuItem, Select, SelectChangeEvent, } from '@mui/material';
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from '../../variables/constant';

interface Props {
  onChange: (event: SelectChangeEvent<string[]>) => void;
  selectedItem: string | undefined;
  options: any[];
  startAdornment?: React.ReactNode;
}

const MenuProps = {
  border: 'none',
  PaperProps: {
    border: 'none',
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 'fit-content',
      transform: 'translateY(-2%)',
    },
    sx: {
      '&.MuiPaper-root.MuiPaper-root.MuiPopover-paper': {
        borderRadius: '0px',
      },
    },
    MenuListProps: {
      disablePadding: true
    },
    getContentAnchorEl: null,
  }
};


const styles = {

  menuStyle: {
    '&.MuiMenu-paper': {
      border: 'none !important',
    },
  },
  inputInput: {
    '& div.MuiInputBase-input': {
      background: 'none',
      border: 0,
    },

  },
  menuItemStyles: {
    '&.MuiButtonBase-root': {
      border: 'none',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        border: 'none',

      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(42, 42, 139, 0.12) !important',
      },
    },
  },
};

const SelectDropDown = ({ selectedItem, onChange, options, startAdornment }: Props) => {
  return (
    <Select
      value={selectedItem}
      onChange={onChange}
      sx={{ ...styles.inputInput, ...styles.menuStyle }}
      input={<Input disableUnderline startAdornment={startAdornment} />}
      MenuProps={MenuProps}  >
      {
        options.map((option) => (<MenuItem sx={styles.menuItemStyles} value={option.value}>{option.label}</MenuItem>))
      }
    </Select >)
};
export default SelectDropDown;