import * as Sentry from '@sentry/react';
import { Cache } from 'aws-amplify';
import {SeverityLevel} from "@sentry/types/types/severity";

export interface SentryTag {
  label: string;
  value: string;
}

export const sentryErrorLog = (err: any, tags?: SentryTag[]) => {
  const tagsObj: any = {};
  tags?.forEach((tag) => {
    tagsObj[tag.label] = tag.value;
  });
  Sentry.captureException(err, {
    tags: { ...tagsObj },
    extra: {
      'school-district': Cache.getItem('school-district'),
    },
  });
};

export const sentryMessageLog = (msg: any, level: SeverityLevel, tags?: SentryTag[]) => {
  const tagsObj: any = {};
  tags?.forEach((tag) => {
    tagsObj[tag.label] = tag.value;
  });
  Sentry.captureMessage(msg, {
    tags: { ...tagsObj },
    level: level,
    extra: {
      'school-district': Cache.getItem('school-district'),
    },
  });
};
