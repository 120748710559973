import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../../../components/Layout';
import PDFViewer from '../../../components/PDFViewer';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import { StyledStickyBreadcrumbBox } from '../TeacherToolbox.styled';
import { SHARED_BUCKET_URL } from '../../../variables/constant';
import useRubricIndicatorAndStandards from '../RubricIndicatorsStandards/rubricIndicatorStandards-hook';
import { ScoreSchemeTitleKeys } from '../../../variables/types';

const breadcrumb = [{
  label: 'Toolbox',
  route: '/toolbox',
},
{
  label: 'Getting started',
}];

const GettingStarted = () => {
  const { user } = useRubricIndicatorAndStandards();
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <StyledStickyBreadcrumbBox>
          <Typography variant="pageTitle">Getting Started</Typography>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
        </StyledStickyBreadcrumbBox>
        <Box>
          <PDFViewer
            downloadFile
            pdfLink={(user?.score_scheme.title_key === ScoreSchemeTitleKeys.Scheme1)
              ? `${SHARED_BUCKET_URL}/pdfs/TexasScoring/QuickStartGuide.pdf` : `${SHARED_BUCKET_URL}/pdfs/StandardScoring/QuickStartGuide.pdf`}
            downloadLink="QuickStartGuide.pdf"
            renderInteractiveForms
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default React.memo(GettingStarted);
