import React from 'react';
import { Box, Button, TableRow } from '@mui/material';
import { StyledTableDataCellRoot } from '../../Admin/admin-tables-styled';
import NumberFormat from 'react-number-format';
import { toReadableFormat, toReadableMonthFormat } from '../../../utils/dateFormat';
import { Maybe, SubmissionStatus } from '../../../generated/graphql';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { palette } from '../../../theme/palette';
import { useHistory } from 'react-router-dom';
import { READABLE_DATE_OPTIONS_YEAR } from '../../../variables/constant';

interface Props {
  title?: string;
  submissionStatus?: Maybe<string>;
  submissionId?: Maybe<string>;
  submissionSpeakingAverageScore?: Maybe<number>;
  submissionWritingAverageScore?: Maybe<number>;
  startAt?: string;
  isStudent?: boolean;
  submittedAt?: string;
  getScoreClass: (score: number) => any

}

const baseStyles = {
  marking: {
    width: 40,
    minHeight: 40,
    alignItems: 'center',
    borderRadius: '12px',
    justifyContent: 'center',
    display: 'inline-flex',
    color: palette.customWhite.main,
  },
};

const SubmissionsRow = ({ title, submissionStatus, submissionId, submissionSpeakingAverageScore, submissionWritingAverageScore, startAt, isStudent, getScoreClass, submittedAt }: Props) => {
  const history = useHistory();
  const navigateTo = (id: string | undefined) => {
    history.push(`/progress/score?submission=${id}`);
  };
  return (
    <TableRow>
      <StyledTableDataCellRoot sx={{ textAlign: 'left' }}>
        {title ?? '-'}
      </StyledTableDataCellRoot>
      <StyledTableDataCellRoot>
        {submissionStatus === SubmissionStatus.Reviewed ? 'Scored' : submissionStatus === SubmissionStatus.Submitted ? 'Not scored' : submissionId ? 'Not submitted' : 'Not started'}
      </StyledTableDataCellRoot>
      <StyledTableDataCellRoot>
        <Box sx={{
          ...(submissionSpeakingAverageScore && baseStyles.marking),
          ...(getScoreClass(submissionSpeakingAverageScore ?? 0)),
        }}
        >
          {submissionSpeakingAverageScore
            ? (
              <NumberFormat
                value={submissionSpeakingAverageScore ?? ''}
                decimalSeparator="."
                displayType="text"
                allowNegative
                isNumericString
                decimalScale={1}
              />
            ) : ''}
        </Box>
      </StyledTableDataCellRoot>
      <StyledTableDataCellRoot>
        <Box sx={{
          ...(getScoreClass(submissionWritingAverageScore ?? 0)),
          ...(submissionWritingAverageScore && baseStyles.marking),
        }}
        >
          {submissionWritingAverageScore
            ? (
              <NumberFormat
                value={submissionWritingAverageScore ?? ''}
                decimalSeparator="."
                displayType="text"
                allowNegative
                isNumericString
                decimalScale={1}
              />
            ) : ''}
        </Box>
      </StyledTableDataCellRoot>
      <StyledTableDataCellRoot sx={{ minWidth: "200px" }}>
        {startAt ? toReadableFormat(startAt, READABLE_DATE_OPTIONS_YEAR) : '-'}
      </StyledTableDataCellRoot>
      <StyledTableDataCellRoot>
        {submittedAt ? toReadableFormat(submittedAt, READABLE_DATE_OPTIONS_YEAR) : '-'}
      </StyledTableDataCellRoot>
      {
        isStudent && (
          <StyledTableDataCellRoot>
            {(submissionStatus === SubmissionStatus.Reviewed) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box>
                  <Button
                    onClick={() => navigateTo(submissionId ?? undefined)}
                    sx={{ padding: '6px 8px' }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Box>
              </Box>
            )}
          </StyledTableDataCellRoot>
        )
      }
    </TableRow>
  );
};

export default SubmissionsRow;