import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DistrictWideComparisonsTabs } from '../../../variables/types';
import Loader from '../../../components/Loader/loader';
import {
  DistrictWideComparisonsStylesCSS,
  StyledDistrictWideComparisonsTabs,
  StyledTabs,
} from './DistrictWideComparisons.styled';
import useDistrictWideComparisons, { DistrictWideComparisonsContext } from './district-wide-comparisons-hook';
import Schools from './Schools';
import Grades from './Grades';
import ExportToPdf from './pdfExport';
import { toReadableFormat } from '../../../utils/dateFormat';
import { ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR } from '../../../variables/constant';

interface DistrictWideComparisonsProps {
  lastSnowflakeUpdateTimeData: any;
  getLastSnowflakeUpdateTimeLoading: boolean;
}

const DistrictWideComparisons = ({ lastSnowflakeUpdateTimeData, getLastSnowflakeUpdateTimeLoading }: DistrictWideComparisonsProps) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const {
    getTagStyleClass,
    isSchoolAdmin, isDistrictAdmin,
    loading, setLoading,
    widgetLoading, setWidgetLoading,
    schoolsDataCount, setSchoolsDataCount,
    schoolsDataQueryInput, setSchoolsDataQueryInput,
    schoolSpecificDataQueryInput, setSchoolSpecificDataQueryInput,
    gradesDataCount, setGradesDataCount,
    gradesDataQueryInput, setGradesDataQueryInput,
    gradeSpecificDataQueryInput, setGradeSpecificDataQueryInput,
    tabKey, tab, handleTabChange,
    myDistrictData, mySchoolData,
  } = useDistrictWideComparisons();

  return (
    <>
      <DistrictWideComparisonsContext.Provider value={{
        getTagStyleClass,
        isSchoolAdmin, mySchoolData,
        isDistrictAdmin, myDistrictData,
        loading, setLoading,
        widgetLoading, setWidgetLoading,
        schoolsDataCount, setSchoolsDataCount,
        schoolsDataQueryInput, setSchoolsDataQueryInput,
        schoolSpecificDataQueryInput, setSchoolSpecificDataQueryInput,
        gradesDataCount, setGradesDataCount,
        gradesDataQueryInput, setGradesDataQueryInput,
        gradeSpecificDataQueryInput, setGradeSpecificDataQueryInput,
        lastSnowflakeUpdateTimeData, getLastSnowflakeUpdateTimeLoading,
      }}>
        <Loader open={loading || widgetLoading || getLastSnowflakeUpdateTimeLoading} />
        <Switch>
          <Redirect
            from="/admin/analytics/district-wide-comparisons"
            exact
            to={`/admin/analytics/district-wide-comparisons/${DistrictWideComparisonsTabs.Schools}`}
          />
        </Switch>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          sx={styles.dashBoardContainer}
        >
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="pageTitle">
              {isSchoolAdmin ? 'School' : 'District'}-wide Comparisons
            </Typography>
            {!!lastSnowflakeUpdateTimeData && <Box>
              <Typography variant="toolTipGreyText">
                Last Updated: {toReadableFormat(lastSnowflakeUpdateTimeData, ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR, 'en-US').replace('at', '')} UTC
              </Typography>
            </Box>}
          </Box>
          <Grid container>
            <Grid item xs={10}>
              <StyledTabs
                value={tab}
                onChange={handleTabChange}
                textColor="primary"
                scrollButtons={false}
              >
                {
                  Object.entries(DistrictWideComparisonsTabs).map(([tabLabel, tabKey1]) => {
                    return (
                      <StyledDistrictWideComparisonsTabs
                        key={tabKey1 as string}
                        classes={{
                          root: `${tabKey1 as string}`,
                        }}
                        label={(
                          <Typography>
                            <Typography
                              component="span"
                              className="tag"
                            >
                              {tabKey1 === DistrictWideComparisonsTabs.Schools && (schoolsDataCount || '-')}
                              {tabKey1 === DistrictWideComparisonsTabs.Grades && (gradesDataCount || '-')}
                            </Typography>
                            <Typography component="span">
                              {tabLabel as string}
                            </Typography>
                          </Typography>
                        )}
                        disableRipple
                      />
                    );
                  })
                }
              </StyledTabs>
            </Grid>
            <Grid item xs={2} justifyItems="right">
              <ExportToPdf
                areWidgetsLoading={loading || widgetLoading}
                tabKey={tabKey as string}
              />
            </Grid>
          </Grid>
          {
            tab === 0 ? (
              <Schools
                districtId={myDistrictData?.id}
                schoolId={mySchoolData?.id}
              />
            ) : (
              <Grades
                districtId={myDistrictData?.id}
                schoolId={mySchoolData?.id}
              />
            )
          }
        </Box>
      </DistrictWideComparisonsContext.Provider>
    </>
  );
};

export default DistrictWideComparisons;
