import React from 'react';
import { Backdrop, Box, Card, CardHeader, styled } from '@mui/material';
import { palette } from '../../../theme/palette';

interface Props {
  enable: boolean;
  title?: string;
  description?: string;
}

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  zIndex: theme?.zIndex?.drawer + 1,
  borderRadius: theme.spacing(2),
}));

const DeleteDistrictBackdrop = ({ enable, title, description }: Props) => {

  return (
    <StyledBackdrop
      open={enable}
    >
      <Box>
        <Card>
          <CardHeader
            title={title}
            titleTypographyProps={{
              variant: 'font28',
              color: palette.customBlue.primaryBlue,
            }}
            subheader={description}
            subheaderTypographyProps={{
              variant: 'sLabel',
            }}
          />
        </Card>
      </Box>
    </StyledBackdrop>
  );
};

export default DeleteDistrictBackdrop;
