import * as htmlToImage from 'html-to-image';
import { AdminDashboards } from '../../../variables/constant';
import { PdfProps, adjustWidgetElementHeightWidth, pdfNewPage } from '../../../utils/pdf';

export const LearningGoalsPdf = async ({
  pdfDoc,
  pageWidth,
  pageHeight,
  pdfMargin,
  pdfPadding,
  posX,
  posY,
}: PdfProps) => {
  // Export Widgets one by one
  for (const widget of Object.values(AdminDashboards.learningGoals)) {
    const widgetElement = document.getElementById(widget.name) as HTMLElement;
    const png = await htmlToImage.toPng(widgetElement);

    let elHeight = widgetElement.offsetHeight;
    let elWidth = widgetElement.offsetWidth;

    if ([
      AdminDashboards.learningGoals.GoalPerformanceWidget.name,
      AdminDashboards.learningGoals.GoalsByIndicatorWidget.name,
    ].includes(widget.name)) {
      [elHeight, elWidth] = adjustWidgetElementHeightWidth(widgetElement, pageWidth / 2, 10);
      [posX, posY] = pdfNewPage(pdfDoc, pageHeight, pdfMargin, pdfPadding, posX, posY, elHeight);
      if (widget.name === AdminDashboards.learningGoals.GoalsByIndicatorWidget.name) {
        posX = pdfDoc.internal.pageSize.width - pdfMargin - elWidth + 5;
        posY -= 5;
      }
    } else {
      [elHeight, elWidth] = adjustWidgetElementHeightWidth(widgetElement, pageWidth, pdfMargin);
      if (widget.name === AdminDashboards.learningGoals.GoalsAndScoresByIndicatorWidget.name) {
        elHeight += 20;
      }
      [posX, posY] = pdfNewPage(pdfDoc, pageHeight, pdfMargin, pdfPadding, posX, posY, elHeight);
    }
    pdfDoc.addImage(png, 'PNG', posX, posY, elWidth, elHeight, widget.name, 'NONE');
    if (widget.name !== AdminDashboards.learningGoals.GoalPerformanceWidget.name) {
      posY += elHeight + pdfPadding;
    }
  }
};