import * as React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import usePagination from '../../utils/usePagination';
import { SectionFilterFragmentFragment, useGroupsQuery } from '../../generated/graphql';
import { PAGE_SIZE } from '../../variables/constant';
import { SchoolValue } from '../../components/SchoolFilterTeacher';

const useGroups = () => {
  const pagination = usePagination();
  const [search, setSearchValue] = useQueryParam('search', StringParam);
  const [section, setSectionValue] = useQueryParam('section', StringParam);
  const [school, setSchoolValue] = useQueryParam('school', StringParam);

  const { data, loading, refetch } = useGroupsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      ...(section ? { section_id: section } : {}),
      ...(search ? { search } : {}),
      ...(school ? { school_id: school } : {}),
    },
  });

  const onSectionChange = (sectionData: string | SectionFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSectionValue(sectionData as string);
    // TODO set respective school value
  };

  const onSchoolChange = (schoolData: SchoolValue | string) => {
    pagination.setPage(1);
    setSectionValue(undefined);
    setSchoolValue(schoolData as string);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination.setPage(1);
    if (event.target.value === '') {
      setSearchValue(undefined);
    } else {
      setSearchValue(event.target.value);
    }
  };

  const handleAddGroup = () => {
    if (pagination.page !== 1) {
      pagination.setPage(1);
    }
    if (section !== '') {
      setSectionValue('');
    }
    if (search !== '') {
      setSearchValue('');
    }
    refetch();
  };

  const groups = data?.groupList?.nodes ?? [];
  const totalCount = data?.groupList?.totalCount ?? 0;

  return {
    loading,
    groups,
    totalCount,
    search,
    section,
    onSearch,
    onSectionChange,
    pagination,
    handleAddGroup,
    school,
    onSchoolChange,
  };
};

export default useGroups;
