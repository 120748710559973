import React from 'react';
import { Box, Typography } from '@mui/material';
import usePermission from '../../../hooks/usePermission';
import { UserPermissions } from '../../../generated/graphql';
import FileUpload from './FileUpload';
import ImageLibrary from './ImageLibrary';

interface ImageSelectorProp {
  value?: string,
}
const UploadImages = ({ value }: ImageSelectorProp) => {
  const { hasPermission } = usePermission(UserPermissions.UploadImage);
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      {hasPermission && (
        <>
          <Box>
            <Typography variant="pageTitle">
              Upload Images
            </Typography>
          </Box>
          <Box mt={3} sx={{
            minWidth: 350,
          }}>
            <FileUpload />
          </Box>
          <Box mt={3}>
            <ImageLibrary selected={value} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default UploadImages;
