import { add, fromUnixTime, getUnixTime } from 'date-fns';

interface Props {
  keyTag: string,
  initialValue: any,
  expiryMin?: number,
  timeoutMS?: number,
}

const useLocalStorage = ({
  keyTag, initialValue, expiryMin = 120, timeoutMS = 100,
}: Props) => {

  const jsonStrigifyDateHelper = function (this: any, key: any, value: any) {
    if (this[key] instanceof Date) {
      return `{timestamp}${getUnixTime(this[key])}`;
    }
    return value;
  };

  const jsonParseDateHelper = function (this: any, key: any, value: any) {
    if (typeof this[key] === 'string') {
      var regexp;
      regexp = /^{timestamp}(\d*)$/.exec(this[key]);
      if (regexp) {
        return fromUnixTime(parseInt(regexp[1]));
      }
    }
    return value;
  };

  const setLocalStorageValue = (newValue: any, ttlMin: number = expiryMin) => {
    try {
      window.localStorage.setItem(keyTag, JSON.stringify({
        value: newValue,
        expiry: getUnixTime(add(new Date(), { minutes: ttlMin })),
      }, jsonStrigifyDateHelper));
      // timeout to allow for local storage write
      setTimeout(() => {}, timeoutMS);
    } catch (error) {
      console.error(error);
    }
  };

  const getLocalStorageValue = (defaultValue: any = initialValue) => {
    try {
      var now = new Date();
      const item = window.localStorage.getItem(keyTag);
      // timeout to allow for local storage read
      setTimeout(() => { }, timeoutMS);
      // Parse stored json or if none return initialValue
      if (item) {
        const itemParsed = JSON.parse(item, jsonParseDateHelper);
        if (itemParsed.expiry && (itemParsed.expiry > getUnixTime(now))) {
          // reset expiry on the value stored in local Storage when accessed
          setLocalStorageValue(itemParsed.value);
          return itemParsed.value;
        }
      }
      setLocalStorageValue(defaultValue);
      return defaultValue;
    } catch (error) {
      // If error also return initialValue
      setLocalStorageValue(defaultValue);
      return defaultValue;
    }
  };

  const localStorageValue = getLocalStorageValue(initialValue);

  return {
    localStorageValue, getLocalStorageValue, setLocalStorageValue,
  };
};

export default useLocalStorage;

export const clearLocalStorageAnalyticsFilters = () => {
  localStorage.removeItem('analytics_performance_filters');
  localStorage.removeItem('analytics_learningGoals_filters');
  localStorage.removeItem('teacher_benchmark_dashboard_filters');
};
