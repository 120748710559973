import { Link, styled, Typography } from '@mui/material';
import { ACTIVE_GOAL_TOOLTIP_TYPE, CUSTOM_TOOLTIP_HEIGHT, CUSTOM_TOOLTIP_WIDTH } from '../../../variables/constant';

export const StyledPerformanceLink = styled(Link)<{ color: string, numberOfStudents: number }>(({ color, numberOfStudents }) => ({
  '&.MuiLink-root': {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 800,
    color: color ? color : 'inherit',
    textDecoration: 'none !important',
    cursor: numberOfStudents > 0 ? 'pointer' : 'auto',
  },
}));

export const StyledLink = styled(Link)<{ color: string, numberOfStudents: number, numberOfGoals: number }>(({ color, numberOfStudents, numberOfGoals }) => ({
  '&.MuiLink-root': {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 800,
    color: color ? color : 'inherit',
    textDecoration: 'none !important',
    cursor: (numberOfStudents > 0 || numberOfGoals > 0) ? 'pointer' : 'auto',
  },
}));

export const StyledTypography = styled(Typography)<{ color: string }>(({ theme, color }) => ({
  '&.MuiTypography-root': {
    color: color ? color : 'inherit',
    marginLeft: theme.spacing(1),
  },
}));


export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 800,
    marginBottom: theme.spacing(1),
  },
}));

export const StyledDescriptionTypography = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '19px',
    marginBottom: theme.spacing(1),
  },
}));

export const StyledGoalTitleTypography = styled(Typography)(({ theme }) => ({
  height: '33px',
  fontWeight: 'bold',
  fontSize: '30px',
  lineHeight: '41px',
  letterSpacing: '0.13px',
  color: theme.palette.fontColors.fontBlack,
}));

export const StyledGoalDescriptionTypography = styled(Typography)({
  height: '25px',
  fontSize: '18px',
  lineHeight: '25px',
  letterSpacing: '0.1px',
  color: 'rgba(51, 51, 51, 0.73)',
});

export const StyledBox = styled(Typography)<{ tooltipType: string }>(({ theme, tooltipType }) => ({
  '&.MuiTypography-root': {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.customWhite.main,
    border: `2px solid ${theme.palette.customBackground.disabled}`,
    minHeight: CUSTOM_TOOLTIP_HEIGHT,
    width: CUSTOM_TOOLTIP_WIDTH,
    alignItems: tooltipType !== ACTIVE_GOAL_TOOLTIP_TYPE ? 'left' : 'center',
  },
}));


