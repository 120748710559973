import { useMemo } from 'react';
import RocketIcon from '../../../components/Icons/RocketIcon';
import RubricIcon from '../../../components/Icons/RubricIcon';
import AnchorIcon from '../../../components/Icons/AnchorIcon';
import VideoIcon from '../../../components/Icons/VideoIcon';
import { palette  } from '../../../theme/palette';
// import BulbIcon from '../../../components/Icons/BulbIcon';
// import NotebookIcon from '../../../components/Icons/NotebookIcon';

const TOOLBOXLIST = [
  {
    label: 'Getting Started',
    Icon: RocketIcon,
    redirectionLink: '/toolbox/getting-started',
    primaryBackground: palette.customBlue.cornflowerBlue,
    secondaryBackground: palette.customBlue.darkBlueTanned,
    description: 'Supports the initial launch of Flashlight360 in your classroom with a simple step-by-step process that includes onboarding videos for students and teachers, and tips for success.',
  },
  {
    label: 'Rubric, Indicators, & Standards',
    Icon: RubricIcon,
    redirectionLink: '/toolbox/rubric-indicators-standards',
    primaryBackground: palette.customBlue.oldTheme,
    secondaryBackground: palette.customBlue.darkMagenta,
    description: 'Supports understanding of how students are measured across speaking and writing indicators on a scoring scale of 1-5. Correlations to ELA/ELD standards are also provided.',
  },
  {
    label: 'Speaking & Writing Anchors',
    Icon: AnchorIcon,
    redirectionLink: '/toolbox/speaking-writing-anchors',
    primaryBackground: palette.fontColors.buttonFontGreen,
    secondaryBackground: palette.customGreen.darkParrot,
    description: 'Supports teachers and students in understanding the progression of what performance sounds like and looks like across each of the Speaking and Writing indicators and the five scoring scales.',
  },
  {
    label: 'Videos',
    Icon: VideoIcon,
    redirectionLink: '/toolbox/videos',
    primaryBackground: palette.customYellow.main,
    secondaryBackground: palette.customYellow.darkMustard,
    description: 'A library of training, teaching, and learning videos that support the use of Flashlight360 and enhance understandings about language development overall.',
  },
  // TO-DO
  // {
  //   label: 'Teaching Ideas',
  //   Icon: BulbIcon,
  //   redirectionLink: '/toolbox/teaching-ideas',
  //   primaryBackground: COLORS.tanHide,
  //   secondaryBackground: COLORS.darkOrange,
  //   description: 'Resources for supporting and developing speaking and writing proficiency, including an Online Community of Educators that share best practices and resources.',
  // },
  // {
  //   label: 'Research',
  //   Icon: NotebookIcon,
  //   redirectionLink: '/toolbox/research',
  //   primaryBackground: COLORS.deleteButton,
  //   secondaryBackground: COLORS.subRed,
  //   description: 'Evidence-based and research on language development for expanding educator knowledge.',
  // },
];

const useToolBoxList = () => {
  const toolBoxData = useMemo(() => TOOLBOXLIST, []);
  return (
    toolBoxData
  );
};

export default useToolBoxList;
