import { styled, Theme } from '@mui/material';
import { Button } from '@mui/material';

export const StyledNextButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(20),
}));

export const CreateClassStylesCss = (theme: Theme) => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  formatTopNavBar: {
    padding: theme.spacing(2, 2, 3, 2),
    borderBottom: `2px solid ${theme.palette.customGrey.darkGrey}`,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 140px)',
    margin: theme.spacing(-2),
    padding: theme.spacing(4, 0, 0, 2),
    backgroundColor: theme.palette.customBackground.dragBackground,
    overflowY: 'scroll',
  },
  formStepPanel: {
    padding: theme.spacing(2, 1),
    marginBottom: theme.spacing(2),
    borderRadius: `${theme.spacing(1.5)} !important`,
    border: `1px solid ${theme.palette.customGrey.darkGrey}`,
  },
  formStepPanelHeaderStepIcon: {
    width: '24px', height: '24px',
    fontSize: '1rem', fontWeight: 'bold',
    background: theme.palette.customBlue.primaryBlue,
    marginRight: theme.spacing(1),
  },
  formStepPanelFooter: {
    justifyContent: 'space-between',
    paddingX: theme.spacing(3),
  },
  summaryTableRow: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:last-child td, &:last-child th': { border: 0 },
  },
  summaryTableRowHelpText: {
    paddingX: theme.spacing(1),
    margin: 0,
  },
  inputInput: {
    '& .MuiInputBase-input': {
      background: 'none',
      border: 0,
    },
  },
});
