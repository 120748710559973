import { Box } from '@mui/material';
import React from 'react';

interface Props {
  bodyContent: React.ReactFragment
}

const BodyContainer = ({ bodyContent }: Props) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      sx={{
        gridColumnGap: 30,
      }}
      margin='20px 0'
    >
      {bodyContent}
    </Box>
  );
};

export default BodyContainer;
