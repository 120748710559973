import { palette  } from '../palette';

const TextField =  {
  MuiTextField: {
    styleOverrides: {
      root: {
        input:{
          '&.MuiInputBase-input.MuiOutlinedInput-input':{
            padding: 8,
            border:0,
          },
        },
        '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input':{
          padding: 8,
          border: 0,
          fontWeight: 800,
        },
        '& .MuiInputBase-input': {
          background: 'none',
          fontWeight: 800,
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root':{
          fontSize: 18,
          fontWeight: 800,
          letterSpacing: 0.1,
          color: palette.fontColors.fontBlack,
          width: '100%',
          border: `2px solid ${palette.customRed.progressPrimary}`,
          borderWidth: '2px 2px 4px',
          borderRadius: 12,
          height: 48,
        },
      },
    },
  },
};

export default TextField;
