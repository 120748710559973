import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { 
  StyledContainer,
  StyledRecordingContainer,
} from '../ScoreTask.styled';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';
import AudioPlayer from '../../../components/Player';
import NoAudioIcon from '../../../components/Icons/NoAudioIcon';
import { File } from '../../../generated/graphql';
import { StylesCss } from '../ScoreTask.styled';

interface Props {
  recordings: Pick<File, 'id' | 'url' | 'file_type'>[],
}

const NoRecordings = () => {
  const theme = useTheme();
  const Styles = StylesCss(theme);
  return (
    <Box sx={Styles.recordingNoData}>
      <NoAudioIcon />
      <Typography sx={Styles.noRecordingText}>
        No audio recording submitted
      </Typography>
    </Box>
  );
};

const RecordingRow = ({ recordings }: Props) => {
  const theme = useTheme();
  const Styles = StylesCss(theme);
  return (
    <StyledContainer sx={{ ...Styles.whiteBackground, px: theme.spacing(1) }}>
      <StyledFlexColBoxContainer>
        {recordings?.length > 0
          ? recordings?.map((recording, index: number) => (
            <StyledRecordingContainer
              key={recording.id}
            >
              <AudioPlayer
                showLabel
                label={`Recording ${index + 1}`}
                url={recording.url}
                fileType={recording.file_type}
                flexStructure={false}
              />
            </StyledRecordingContainer>
          ))
          : (
            <Box pb={1}>
              <NoRecordings />
            </Box>
          )}
      </StyledFlexColBoxContainer>
    </StyledContainer>
  );
};

export default React.memo(RecordingRow);
