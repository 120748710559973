import { useHistory } from 'react-router-dom';
import { useDeleteAssignmentMutation } from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import getErrorMessage from '../../../utils/getErrorMessage';

const useDeleteAssignment = () => {
  const history = useHistory();
  const [deleteAssignment, { loading }] = useDeleteAssignmentMutation();

  const deleteSelectedAssignment = async (id: string, section: string) => {
    try {
      await deleteAssignment({
        variables: {
          input: id,
        },
      });
      history.push(`/assignments/${section}`);
      openSnackbar({
        message: 'Assignment deleted successfully',
      }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    deleting: loading,
    deleteAssignment: deleteSelectedAssignment,
  };
};

export default useDeleteAssignment;
