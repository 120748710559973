import { Theme } from '@mui/material';

export const BenchmarkScoresWidgetStylesCSS = (theme: Theme) => ({
  dashBoardContainer: {
    padding: theme.spacing(2, 2, 2, 1),
  },
  width5: {
    width: '5%',
  },
  width10: {
    width: '10%',
  },
  width15: {
    width: '15%',
  },
  width20: {
    width: '20%',
  },
  width25: {
    width: '25%',
  },
  width30: {
    width: '30%',
  },
  width35: {
    width: '35%',
  },
  width50: {
    width: '50%',
  },
  width55: {
    width: '55%',
  },
  width60: {
    width: '60%',
  },
  width65: {
    width: '65%',
  },
  tableContainer: {
    border: `2px solid ${theme.palette.customBlue.primaryBlue}`,
    borderTop: 'none',
    borderRadius: theme.spacing(1.5, 1.5, 1.5, 1.5),
    backgroundColor: theme.palette.customWhite.main,
    marginTop: theme.spacing(1),
    maxHeight: '380px',
  },
  tableRowBorder: {
    borderBottom: `1px solid ${theme.palette.customGrey.adminButtonBorder}`,
  },
  tableRowHover: {
    '&:focus, &:hover': {
      backgroundColor: theme.palette.customGrey.adminButtonBorder,
    },
  },
  tableRowEven: {
    backgroundColor: theme.palette.customBackground.dragBackground,
  },
  tableCellTextAlignLeft: {
    '&.MuiTableCell-root':{
      textAlign: 'left',
    },
  },
  tableTypographyTextAlignLeft: {
    '&.MuiTypography-root':{
      textAlign: 'left',
    },
  },
  sliderTableContainer: {
    borderRadius: 0,
    border: 'none',
    height: '82vh',
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.customGrey.adminButtonBorder,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.customBlue.primaryBlue,
      borderRadius: 2,
    },
    overflowX: 'hidden',
  },
  drawerHeader: {
    width: '100%',
    height: theme.spacing(10),
    backgroundColor: theme.palette.customBlue.primaryBlue,
    color: theme.palette.customWhite.main,
    padding: theme.spacing(2),
  },
  drawerHeaderIcon: {
    color: theme.palette.customWhite.main,
    fontWeight: 'bold',
  },
  drawerTableColumn: {
    '&.MuiTableCell-root': {
      backgroundColor: theme.palette.customWhite.main,
      color: theme.palette.customBlack.dimmedBlack,
      top: '-8px',
    },
  },
  tag: {
    display: 'inline-block',
    width: theme.spacing(5),
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(1.35),
    fontWeight: 'bold',
  },
  tagDisabled: {
    backgroundColor: theme.palette.customGrey.iconGrade,
    color: theme.palette.customWhite.main,
  },
  tagRed: {
    background: theme.palette.customRed.buttonFontRed,
    color: theme.palette.customWhite.main,
  },
  tagYellow: {
    background: theme.palette.customYellow.main,
    color: theme.palette.customBlack.fontBlack,
  },
  tagBlue: {
    background: theme.palette.customBlue.waterBlue,
    color: theme.palette.customWhite.main,
  },
  tagGreen: {
    background: theme.palette.customGreen.fontGreen,
    color: theme.palette.customWhite.main,
  },
  tagPurple: {
    background: theme.palette.customBlue.primaryBlue,
    color: theme.palette.customWhite.main,
  },
  benchmarkNonActive: {
    color: theme.palette.customBlue.chipDark,
    cursor: 'default',
  },
  tableRowHeight: {
    '&.MuiTableRow-root': {
      height: theme.spacing(6.75),
    },
  },
});
