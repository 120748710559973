/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const AnchorIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="59"
    height="66"
    viewBox="0 0 59 66"
    fill="none"
    {...props}
  >
    <path d="M45.6944 42.4306L50.7535 47.4896C47.6201 53.0056 39.8847 57.4118 32.6389 58.4889V29.375H42.4306V22.8472H32.6389V18.9958C36.425 17.625 39.1667 14.0347 39.1667 9.79167C39.1667 4.40625 34.7604 0 29.375 0C23.9896 0 19.5833 4.40625 19.5833 9.79167C19.5833 14.0347 22.325 17.625 26.1111 18.9958V22.8472H16.3194V29.375H26.1111V58.4889C18.8653 57.4118 11.1299 53.0056 7.99653 47.4896L13.0556 42.4306L0 32.6389V42.4306C0 55.0944 16.0583 65.2778 29.375 65.2778C42.6917 65.2778 58.75 55.0944 58.75 42.4306V32.6389L45.6944 42.4306ZM29.375 6.52778C31.1701 6.52778 32.6389 7.99653 32.6389 9.79167C32.6389 11.5868 31.1701 13.0556 29.375 13.0556C27.5799 13.0556 26.1111 11.5868 26.1111 9.79167C26.1111 7.99653 27.5799 6.52778 29.375 6.52778Z" fill={props.pathFill || palette.customWhite.main} />
  </SvgIcon>
);

export default React.memo(AnchorIcon);
