import * as React from 'react';

const styles = {
  icon: {
    width: '25px',
    height: '25px',
  },
};

const IconBase64 = (props: any) => {
  const { x, y, payload } = props;
  return (
    <image transform={`translate(${x - 10}, ${y})`} style={styles.icon} href={`data:image/svg+xml;base64,${payload.value}`} />
  );
};

export default IconBase64;
