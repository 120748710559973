import React, { useContext } from 'react';
import {
  TableRow,
  Typography,
  Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledTableDataColumn } from '../../../Admin/admin-tables-styled';
import { DistrictWideGradeDetail } from '../../../../generated/graphql';
import { DistrictWideComparisonsStylesCSS } from '../DistrictWideComparisons.styled';
import { DistrictWideComparisonsContext } from '../district-wide-comparisons-hook';
import toRound from '../../../../utils/toRound';

interface Props {
  rowIndex: number,
  grade: DistrictWideGradeDetail,
  openFlyover: Function,
}

const GradeRow = ({
  rowIndex,
  grade,
  openFlyover,
}: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const {
    getTagStyleClass,
  } = useContext(DistrictWideComparisonsContext);

  const avgSpeakingScore = toRound(grade?.speaking_overall_score, 1);
  const avgWritingScore = toRound(grade?.writing_overall_score, 1);

  return (
    <TableRow key={rowIndex} sx={{ ...styles.tableRowHover }}>
      <StyledTableDataColumn sx={{ ...styles.width20, ...styles.tableCellTextAlignLeft }}>
        <Link underline="none" component="button" onClick={() => {
          openFlyover(grade);
        }}>
          <Typography
            variant='redirectionLinks'
          >
            {grade?.grade_title}
          </Typography>
        </Link>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width10 }}>
        {grade?.teacher_count}
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width10 }}>
        {grade?.student_count}
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width20 }}>
        {grade?.total_goals_assigned}
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width20 }}>
        {`${grade?.total_submissions_completed_count} / ${grade?.total_submissions_count}`}
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width10 }}>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getTagStyleClass?.(avgSpeakingScore) }}
        >
          {avgSpeakingScore}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width10 }}>
        <Typography
          component="span"
          sx={{ ...styles.tag, ...getTagStyleClass?.(avgWritingScore) }}
        >
          {avgWritingScore}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default GradeRow;
