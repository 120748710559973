import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { AnalyticsTabs } from '../../variables/types';

interface RouteProp {
  section?: string;
}
const getIndexBySection = (section?: string) => {
  switch (section) {
    case AnalyticsTabs.Speaking:
      return 1;
    case AnalyticsTabs.Writing:
      return 2;
    case AnalyticsTabs.SpeakingGoals:
      return 3;
    case AnalyticsTabs.WritingGoals:
      return 4;
    case AnalyticsTabs.Overview:
    default:
      return 0;
  }
};
const useAnalytics = () => {
  const { section } = useParams<RouteProp>();
  const [tab, setTab] = useState(getIndexBySection(section));
  const [assignment, setAssignment] = useQueryParam('assignment', StringParam);
  const [selectedSection, setSelectedSection] = useQueryParam('section', StringParam);
  const [selectedSchool, setSchoolValue] = useQueryParam('school', StringParam);

  useEffect(() => {
    setTab(getIndexBySection(section));
  }, [section]);
  return {
    section,
    tab,
    setTab,
    assignment,
    setAssignment,
    selectedSection,
    setSelectedSection,
    selectedSchool,
    setSchoolValue,
  };
};
export default useAnalytics;
