import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryParam } from 'use-query-params';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType } from '../../variables/types';
import SchoolsIcon from '../../components/Icons/SchoolsIcon';
import DistrictsIcon from '../../components/Icons/DistrictsIcon';
import {
  SchoolFilterFragmentFragment,
  useDistrictAdminEditDetailsQuery,
  useDistrictAdminFormMutation,
  UserRole,
  useSchoolAdminEditDetailsQuery,
  useSchoolAdminFormMutation,
} from '../../generated/graphql';
import { adminLevel } from './constants';

type AdminFormData = {
  id: string
  state_id: string
  district_id: string
  school_id: string
  title: string
  first_name: string
  last_name: string
  admin_id: string
  job_title: string
  email: string
  phone_number: string
  role: UserRole
  password: string
};

interface RouteProp {
  id: string
  admin: string
}

const useAdminForm = () => {
  const [level, setLevel] = useState(adminLevel.SCHOOL);
  const [checked, setChecked] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    setValue,
    clearErrors,
  } = useForm<AdminFormData>();
  const [districtParam] = useQueryParam('districtId');
  const [stateParam] = useQueryParam('stateId');
  const [schoolParam] = useQueryParam('schoolId');
  const [levelParam] = useQueryParam('level');

  const { id, admin } = useParams<RouteProp>();
  const history = useHistory();

  const schoolAdminID = level === adminLevel.SCHOOL ? id : null;
  const districtAdminID = level === adminLevel.DISTRICT ? id : null;
  const handleCase = (elemName: keyof AdminFormData) => (event: any) => {
    const content = event.target.value.toString().trim();
    if (content != '') {
      setValue(elemName, content.toLowerCase());
    }
  };

  const { data: schoolAdminDetails, loading: schoolAdminDetailsLoading } = useSchoolAdminEditDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: schoolAdminID!,
    },
    skip: !schoolAdminID,
  });

  const { data: districtAdminDetails, loading: districtAdminDetailsLoading } = useDistrictAdminEditDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: districtAdminID!,
    },
    skip: !districtAdminID,
  });

  const [schoolAdminForm, { loading: schoolAdminLoading }] = useSchoolAdminFormMutation();
  const [districtAdminForm, { loading: districtAdminLoading }] = useDistrictAdminFormMutation();

  useEffect(() => {
    if (id) {
      register('id', { required: true });
    }
  }, [id, register]);

  useEffect(() => {
    if (stateParam) {
      setValue('state_id', stateParam);
    }
  }, [setValue, stateParam]);

  useEffect(() => {
    if (levelParam) {
      setLevel(levelParam as string);
    }
  }, [setValue, levelParam]);

  useEffect(() => {
    if (districtParam) {
      setValue('district_id', districtParam);
    }
  }, [setValue, districtParam]);

  useEffect(() => {
    if (schoolParam) {
      setValue('school_id', schoolParam);
    }
  }, [setValue, schoolParam]);

  useEffect(() => {
    if (admin) {
      setLevel(admin);
    }
  }, [admin]);

  useEffect(() => {
    const adminDetails = schoolAdminDetails ? schoolAdminDetails.schoolAdmin : districtAdminDetails?.districtAdmin;

    if (adminDetails) {
      const details = {
        id: adminDetails.id,
        state_id: adminDetails.districtData?.state_id || undefined,
        district_id: adminDetails.district_id,
        school_id: adminDetails.school_id || undefined,
        title: adminDetails.title,
        job_title: adminDetails.job_title,
        first_name: adminDetails.first_name,
        last_name: adminDetails.last_name,
        admin_id: adminDetails.admin_id,
        email: adminDetails.email,
        phone_number: adminDetails.phone_number || undefined,
      };
      if (schoolAdminDetails) {
        reset({
          ...details,
          role: UserRole.SchoolAdmin,
        });
      } else if (districtAdminDetails) {
        reset({
          ...details,
          role: UserRole.DistrictAdmin,
        });
      }
    }
  }, [id, reset, schoolAdminDetails, districtAdminDetails]);

  const handleLevelChange = (value: string) => {
    setLevel(value);
  };

  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setChecked(event.target.checked);
  };

  const onStateChange = () => {
    setValue('district_id', null);
    setValue('school_id', null);
  };

  const onDistrictChange = (stateId?: string | null) => {
    setValue('school_id', null);

    if (stateId) {
      setValue('state_id', stateId);
      clearErrors('state_id');
    }
  };

  const onSchoolChange = (schoolFragment?: SchoolFilterFragmentFragment | null) => {
    if (schoolFragment?.district_id) {
      setValue('district_id', schoolFragment.district_id);
      clearErrors('district_id');
    }
    if (schoolFragment?.district_state_id) {
      setValue('state_id', schoolFragment.district_state_id);
      clearErrors('state_id');
    }
  };

  const create = async (adminForm: AdminFormData) => {
    const adminName = `${adminForm.first_name} ${adminForm.last_name}`;
    const successMessage = `${adminName} ${level} admin was ${adminForm.id ? 'updated' : 'added'}!`;
    try {
      let adminDetailsPath: string;
      const adminInput = {
        id: adminForm.id,
        district_id: adminForm.district_id,
        school_id: adminForm.school_id,
        title: adminForm.title,
        first_name: adminForm.first_name,
        last_name: adminForm.last_name,
        admin_id: adminForm.admin_id,
        job_title: adminForm.job_title,
        email: adminForm.email && adminForm.email?.trim()?.toLowerCase(),
        phone_number: adminForm.phone_number,
        role: level === adminLevel.DISTRICT ? UserRole.DistrictAdmin : UserRole.SchoolAdmin,
        password: adminForm.password,
      };
      if (level === adminLevel.SCHOOL) {
        const response = await schoolAdminForm({
          variables: {
            input: {
              ...adminInput,
            },
          },
        });
        adminDetailsPath = `/admins/${level}/${response?.data?.upsertSchoolAdmin}`;
      } else {
        const response = await districtAdminForm({
          variables: {
            input: {
              ...adminInput,
            },
          },
        });
        adminDetailsPath = `/admins/${level}/${response?.data?.upsertDistrictAdmin}`;
      }

      if (checked) {
        reset({
          id: '',
          title: '',
          first_name: '',
          last_name: '',
          admin_id: '',
          job_title: '',
          email: '',
          phone_number: '',
          password: '',
          state_id: adminForm.state_id,
          district_id: adminForm.district_id,
          school_id: adminForm.school_id,
        });
      } else {
        history.push(adminDetailsPath);
      }
      openSnackbar({
        message: successMessage,
        customIcon: level === adminLevel.DISTRICT ? DistrictsIcon : SchoolsIcon,
        actionButtonText: 'View',
        onActionButtonClick: () => { history.push(adminDetailsPath); },
      }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const selectedStateId = watch('state_id');
  const selectedDistrictId = watch('district_id');

  return {
    loading: schoolAdminLoading || schoolAdminDetailsLoading || districtAdminLoading || districtAdminDetailsLoading,
    register,
    handleSubmit,
    errors,
    control,
    adminID: id,
    watch,
    createAdmin: create,
    checked,
    handleChange,
    level,
    handleLevelChange,
    onStateChange,
    onDistrictChange,
    onSchoolChange,
    selectedStateId,
    selectedDistrictId,
    handleCase,
  };
};

export default useAdminForm;
