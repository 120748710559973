import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import List from './List';
import StateFilter from '../../../components/StateFilter';
import DistrictFilter from '../../../components/DistrictFilter';
import SchoolFilter from '../../../components/SchoolFilter';
import useAdmins from './admins-hook';
import useRole from '../../../hooks/useRole';
import { Link } from 'react-router-dom';

const Admins = () => {
  const {
    admins,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    selectedSchool,
    onSchoolChange,
    isSchoolAdmin,
    isDistrictAdmin,
    type,
    districtPermission,
    rowsPerPage,
    setRowsPerPage,
  } = useAdmins();
  const { isSuperAdmin } = useRole();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="pageTitle">
            {`${type} Admins`}
          </Typography>
        </Box>
        <Box>
          {isSuperAdmin  && (
            <Button
              variant="outlined"
              color="primary"
              disableRipple={true}
              component={Link}
              to={`/admin/create/admin?level=${type}`}
            >
              {`Add ${type} Admin`}
            </Button>
          )}
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(4, 15%) 1fr"
        sx={{
          gridGap: 24,
        }}
        mt={2}
      >
        {districtPermission && (
          <>
            <StateFilter
              value={selectedState}
              onChange={onStateChange}
              idSelectOnly
              placeholder="All states"
            />
            <DistrictFilter
              value={selectedDistrict}
              onChange={onDistrictChange}
              stateIds={selectedState ?? undefined}
              idSelectOnly
              placeholder="All districts"
            />
          </>
        )}
        {isSchoolAdmin && (
          <SchoolFilter
            value={selectedSchool}
            onChange={onSchoolChange}
            stateId={selectedState ?? undefined}
            districtId={selectedDistrict ?? undefined}
            idSelectOnly
            placeholder="All schools"
          />
        )}
      </Box>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading}
          totalCount={totalCount}
          order={order}
          pagination={pagination}
          sort={sort}
          admins={admins}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          isSchoolAdmin={isSchoolAdmin}
          isDistrictAdmin={isDistrictAdmin}
          type={type}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default React.memo(Admins);
