import { Input, InputLabel, ListItemText, MenuItem, Box, FormControl, styled } from '@mui/material';
import SelectDropdownIcon from '../Icons/SelectDropdownIcon';

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: 16,
    fontWeight: 800,
    letterSpacing: 0.1,
    color: theme.palette.customBlack.fontBlack,
    top: -4,
    left: 16,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 800,
  letterSpacing: 0.1,
  color: theme.palette.customBlack.fontBlack,
  borderTop: 'none',
}));

export const StyledListItemText = styled(ListItemText)(({theme  }) => ({
  letterSpacing: 1,
  color: theme.palette.customBlack.fontBlack,
  '& span': {
    fontSize: 16,
    fontWeight: 800,
  },
}));

export const StyledListSubItemText = styled(ListItemText)(({ theme }) => ({
  letterSpacing: 1,
  color: theme.palette.customBlack.fontBlack,
  '& span': {
    fontSize: 16,
    fontWeight: 500,
  },
  paddingLeft: theme.spacing(7.25),
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  fontWeight: 800,
  fontSize: 16,
  textTransform: 'uppercase',
  paddingLeft: theme.spacing(1.75),
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& div[class^="MuiBox-root"]': {
    marginRight: theme.spacing(2),
    width: '100%',
  },
  '& button': {
    marginLeft: theme.spacing(2),
  },
}));

export const StyledFormControl = styled(FormControl)({
  minWidth: 120,
  maxWidth: 300,
});

export const StyledSelectDropdownIcon = styled(SelectDropdownIcon)(({ theme }) => ({
  fontSize: '0.75rem !important',
  marginRight: theme.spacing(1.8),
}));

