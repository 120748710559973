import { createContext } from 'react';
import { DashboardWidgetsDataStatusProps } from '../AdminAnalytics/admin-analytics-hook';
import { BenchmarkProgressScoreInput, BenchmarkType } from '../../generated/graphql';

export interface TeacherBenchmarksDashboardContextValue {
  selectedGrades?: string[],
  selectedClasses?: string[],
  selectedSchools?: string[],
  selectedTeachers?: string[],
  setSelectedClasses?: Function,
  setSelectedGrades?: Function,
  setSelectedSchools?: Function,
  setSelectedTeachers?: Function,
  areWidgetsLoading?: boolean,
  setAreWidgetsLoading?: (value: boolean) => void,
  dashboardWidgetsDataStatus?: DashboardWidgetsDataStatusProps[],
  setDashboardWidgetsDataStatus?: Function,
  isDataMissingInAllWidgets?: boolean,
  setIsDataMissingInAllWidgets?: Function,
  loading?: boolean,
  setLoading?: (value: boolean) => void,
  benchmarkType?: BenchmarkType,
  setBenchmarkType?: (value: BenchmarkType) => void,
  lastSnowflakeUpdateTimeData?: any,
  getLastSnowflakeUpdateTimeLoading?: boolean,
  setIsProgressData?: Function,
  isProgressScoresLoading?: boolean,
  setIsProgressScoresLoading?: Function,
  isProgressData?: boolean,
  progressScoreQueryInput?: BenchmarkProgressScoreInput,
  setProgressScoreQueryInput?: Function,
  progressScoreDataCount?: number,
  setProgressScoreDataCount?: Function,
  legendDataOverallPerformanceWidget?: any[],
  setLegendDataOverallPerformanceWidget?: Function,
  localTeacherBenchmarkDashboardFilters?: any,
  getLocalTeacherBenchmarkDashboardFilters?: Function,
  setLocalTeacherBenchmarkDashboardFilters?: Function,
  isAdminReportBSGrowthDataMissing?: boolean,
  setIsAdminReportBSGrowthDataMissing?: Function,
}
export const TeacherBenchmarksDashboardContext = createContext<TeacherBenchmarksDashboardContextValue>({
  selectedGrades: [],
  selectedClasses: [],
  selectedSchools: [],
  selectedTeachers: [],
  benchmarkType: BenchmarkType.Boy,
});

