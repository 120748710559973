import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader/loader';
import { CreateAssignmentStylesCss } from '../../Assignments/CreateAssignment/CreateAssignment.styled';
import DetailsStep from './Steps/DetailsStep';
import SummaryStep from './Steps/SummaryStep';
import SelectImageStep from './Steps/CommonSteps/SelectImageStep';
import InstructionsStep from './Steps/CommonSteps/InstructionsStep';
import { useForm, FormProvider } from 'react-hook-form';
import { BenchmarkInput, CreateBenchmarkContext, CreateBenchmarkInit } from './create-benchmark-hook';
import { SxProps } from '@mui/system';


const CreateBenchmark = () => {
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);

  const {
    routeData,
    loading, setLoading,
    benchmarkInput, setBenchmarkInput, isPastBenchmark,
    staticResourceImgSelected, setStaticResourceImgSelected,
    speakingPromptFile, setSpeakingPromptFileBM,
    writingPromptFile, setWritingPromptFileBM,
    selectedGrade, setSelectedGrade,
    searchTerm, setSearchTerm,
    recordingTracker, setRecordingTracker,
    selectedSubjects, setSubjectAreas,
    selectedTaskTypes, setTaskTypes,
  } = CreateBenchmarkInit();

  const benchmarkForm = useForm<BenchmarkInput>({
    defaultValues: benchmarkInput,
    reValidateMode: 'onSubmit',
  });

  return (
    <Layout>
      <Box sx={styles.formContainer as SxProps}>
        <CreateBenchmarkContext.Provider value={{
          routeData,
          loading, setLoading,
          benchmarkInput, setBenchmarkInput, isPastBenchmark,
          staticResourceImgSelected, setStaticResourceImgSelected,
          speakingPromptFile, setSpeakingPromptFileBM,
          writingPromptFile, setWritingPromptFileBM,
          selectedGrade, setSelectedGrade,
          searchTerm, setSearchTerm,
          recordingTracker, setRecordingTracker,
          selectedSubjects, setSubjectAreas,
          selectedTaskTypes, setTaskTypes,
        }}
        >
          <FormProvider {...benchmarkForm}>
            <Switch>
              <Route path={`${routeData?.rootPath}/details`}>
                <DetailsStep title="Create Benchmark" />
              </Route>
              <Route path={`${routeData?.rootPath}/select-image`}>
                <SelectImageStep title="Select Image" />
              </Route>
              <Route path={`${routeData?.rootPath}/instructions`}>
                <InstructionsStep title='Create Instructions' />
              </Route>
              <Route path={`${routeData?.rootPath}/summary`}>
                <SummaryStep title='Summary' />
              </Route>
              <Redirect
                from={`${routeData?.rootPath}`}
                to={`${routeData?.rootPath}/details`}
              />
            </Switch>
          </FormProvider>
        </CreateBenchmarkContext.Provider>
      </Box>
      <Loader open={loading} />
    </Layout>
  );
};

export default React.memo(CreateBenchmark);
