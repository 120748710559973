import { SectionCategory, usePrimarySectionsOfTeacherQuery } from '../../../../generated/graphql';

interface Props {
  schoolId?: string
  classTypes?: SectionCategory[],
}

const useMultiSelectSection = ({ schoolId, classTypes }: Props) => {
  const { data, loading } = usePrimarySectionsOfTeacherQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...(schoolId ? { schoolID: schoolId as string } : {}),
      ...(classTypes ? { classTypes: classTypes as SectionCategory[] } : {}),
    },
  });

  return {
    sections: data?.primarySectionsOfTeacher ?? [],
    loading,
  };
};

export default useMultiSelectSection;
