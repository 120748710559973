import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Modal,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { palette } from '../../theme/palette';
import FullScreenIcon from '../Icons/FullScreenIcon';

interface Props {
  imageSrc: string,
  cardTitle: string,
  selected?: boolean,
  isDisabled?: boolean,
  cardGrade?: string,
  isEditMode?: boolean,
  meadiaSkeletonLoader?: boolean,
  instructionsImage?: boolean,
  thumnailImageSrc?: string,
}

const styles = {
  root: {
    '&.MuiCard-root': {
      position: 'relative',
      borderRadius: '17px',
      marginRight: 0.25,
    },
  },
  cardContentContainer: {
    width: '100%',
    opacity: 0.8,
    bottom: '-1px',
    position: 'absolute',
    display: 'none', /* // Will be used a future reference for last used feature, got display none in ABL-1419. */
  },
  cardContentText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    width: '100%',
    color: palette.customWhite.main,
    backgroundColor: palette.fontColors.fontBlack,
    letterSpacing: 1,
    paddingLeft: 2,
  },
  active: {
    border: `4px solid ${palette.customBlue.primaryBlue}`,
  },
  disabled: {
    background: palette.customWhite.whiteSmoke,
    cursor: 'not-allowed',
    border: 'none',
  },
  wrapperButton: {
    background: palette.customWhite.main,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  fullScreenButton: {
    top: 'unset',
    bottom: '10px',
  },
  fullScreenIcon: {
    height: '20px',
    width: '20px',
  },
  paper: {
    position: 'absolute',
    width: '1000px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullScreenImage: {
    width: '100%',
    height: 'auto',
  },
};

const GradeCard = ({
  imageSrc,
  cardTitle,
  cardGrade = '',
  selected,
  isDisabled, isEditMode,
  meadiaSkeletonLoader = false,
  instructionsImage = false,
  thumnailImageSrc,
}: Props) => {
  const [open, setOpen] = useState(false);
  const getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fullScreenPreview = (
    <Box style={getModalStyle()} sx={{ ...styles.paper }}>
      <Box
        component={'img'}
        src={imageSrc}
        sx={{ ...styles.fullScreenImage }}
      />
    </Box>
  );

  const myRef = useRef<null | HTMLDivElement>(null);

  const CustomBox = forwardRef((props: any, ref: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...props} ref={ref} />
  ));

  useEffect(() => {
    if (isEditMode && selected) {
      myRef?.current?.scrollIntoView();
    }
  }, [isEditMode, selected]);

  return (
    <CustomBox mb={3.5} ref={myRef}>
      <Card
        sx={{ ...styles.root, ...(selected && styles.active) }}
      >
        <CardActionArea
          disableRipple
          sx={{ ...(isDisabled && styles.disabled) }}
        >
          {meadiaSkeletonLoader ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                ...(
                  instructionsImage ? { height: '202px' } : (selected ? { height: '202px' } : { height: '210' })
                ),
                width: '100%',
              }}
            />
          ) : (
            <CardMedia
              sx={{
                ...(
                  instructionsImage ? { height: '290px' } : (selected ? { height: '202px' } : { height: '210px' })
                ),
                width: '100%',
              }}
              key={imageSrc}
              image={thumnailImageSrc ? thumnailImageSrc : imageSrc}
              component="img"
            />
          )}
        </CardActionArea>
        <>
          <Box sx={{ ...styles.wrapperButton, ...styles.fullScreenButton }}>
            <IconButton
              aria-label="Image Preview"
              onClick={handleOpen}
              disableTouchRipple
              disableRipple
            >
              <FullScreenIcon
                pathFill={palette.customBlack.fontBlack}
                sx={{ ...styles.fullScreenIcon }}
              />
            </IconButton>
          </Box>
        </>
      </Card>
      {cardTitle && <Typography variant="tableTitleData" component="p">{cardTitle}</Typography>}
      {cardGrade && <Typography sx={{ fontSize: '14px', fontWeight: 600, color: palette.customBlack.lightBlack }}>{`Grades ${cardGrade}`}</Typography>}
      <Modal
        open={open}
        onClose={handleClose}
      >
        {fullScreenPreview}
      </Modal>
    </CustomBox>
  );
};
export default React.memo(GradeCard);
