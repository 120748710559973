import { styled, Button } from '@mui/material';
import ScoreSchemeRadioGroup from '../../../components/ScoreSchemeRadioGroup';


export const StyledSaveButton = styled(Button)(({ theme }) => ({
  width: 200,
  fontSize: 18,
  lineHeight: '24px',
  fontWeight: 800,
  marginTop: theme.spacing(4),
}));

export const StyledRadioButton = styled(ScoreSchemeRadioGroup)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  '& .formLabel': {
    fontSize: 16,
    fontWeight: 600,
  },
}));



