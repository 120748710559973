import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { OrderBy, StudentListOfTeacherSortType, useSearchStudentQuery } from '../../../../generated/graphql';

const SEARCH_LIMIT = 5;

const useSearchStudents = () => {
  const [search, setSearchValue] = useQueryParam('search-query', StringParam);
  const { data, loading } = useSearchStudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: SEARCH_LIMIT,
      orderBy: OrderBy.Asc,
      sort: StudentListOfTeacherSortType.Name,
      ...(search?.length ? { search } : {}),
    },
    skip: !search,
  });

  const onSearch = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target.value) {
      setSearchValue(event.target.value);
    } else {
      setSearchValue(undefined);
    }
  };

  return {
    students: data?.getAllStudentsOfTeacher ?? [],
    loading,
    onSearch,
  };
};

export default useSearchStudents;
