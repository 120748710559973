import React, { ChangeEvent } from 'react';
import { Box, Grid, Link, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LazyLoad from 'react-lazy-load';
import GradeCard from '../../../components/GradeCard';
import { StaticResource, SubjectArea } from '../../../generated/graphql';
import Loader from '../../../components/Loader/loader';
import { GradeOptions } from '../../../variables/constant';
import MultipleSelectChips from '../../../components/MultiSelectChips';
import NoImagesFound from '../../../components/NoImageFound';
import KeywordSearchBar from './KeywordSearchBar';
import NoBenchmarkImageFound from '../../../components/NoBenchmarkImageFound';
import TaskTypes from '../../../components/TaskTypes';

const StylesCss = (theme: Theme) => ({
  GradeLevelTypography: {
    color: theme.palette.fontColors.fontBlack,
    fontSize: theme.spacing(2.25),
    fontWeight: 800,
  },
  formItemContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formLabelContainer: {
    display: 'flex',
    padding: theme.spacing(2, 4, 2, 0),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formFieldContainer: {
    padding: theme.spacing(0, 4, 2, 0),
  },
  gradeChipContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 8%)',
    gridGap: theme.spacing(1.25),
  },
  innerWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: theme.spacing(2),
    position: 'relative',
    width: '100%',
  },
  disabled: {
    background: theme.palette.customWhite.whiteSmoke,
    cursor: 'not-allowed',
  },
  ptImageBox: {
    width: '100%',
    height: theme.spacing(62.5),
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.customBlack.scrollBarBackgroundColor,
      boxShadow: `0 0 1px ${theme.palette.customBlack.scrollBarBackgroundColor}`,
    },
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  ptImageBoxtextHelper: {
    fontSize: theme.spacing(2.75),
    paddingBottom: theme.spacing(2),
  },
  buttonTransformation: {
    textTransform: 'none',
    fontSize: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    cursor: 'pointer',
  },
});

interface Props {
  onChange: (selectedItemKey: string | number | null) => void,
  selected?: string,
  isDisabled?: boolean,
  disableTile?: (selectedItemKey : string | number | null) => void;
  resourceId?: string,
  editableField?: boolean,
  isEditMode?: boolean,
  isBenchmark?: boolean,
  loading?: boolean,
  setStaticResourceImgSelected?: Function,
  setGrades?: any,
  selectedGrade?: string[] | undefined,
  setSelectedGrade?: any,
  searchTerm?: string | undefined,
  setSearchTerm?: Function,
  selectedSubjects?: string[] | undefined,
  subjectAreas?: SubjectArea[] | null | undefined,
  setSubjectAreas?: Function,
  selectedTaskTypes?: string[] | undefined,
  setTaskTypes: Function,
  resetFilters?: Function,
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void,
  updateContextInput?: Function,
}

const StaticResources = ({
  onChange,
  selected,
  isDisabled,
  resourceId,
  editableField,
  isEditMode,
  isBenchmark = false,
  loading,
  setStaticResourceImgSelected,
  setGrades,
  selectedGrade,
  setSelectedGrade,
  searchTerm,
  setSearchTerm,
  selectedSubjects,
  subjectAreas,
  onSearch,
  resetFilters,
  updateContextInput,
  setSubjectAreas,
  selectedTaskTypes,
  setTaskTypes,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCss(theme);

  const subjectOptions = subjectAreas?.map(subject => ({ label: subject.title, value:subject.id }));

  const handleGradeChange = (grades: string[] | null) => {
    setSelectedGrade?.(grades);
  };


  const handleSubjectChange = (subjects: string[] | null) => {
    setSubjectAreas?.(subjects);
  };

  const selectStaticResource = (data: StaticResource) => {
    if (!isDisabled) {
      onChange(data.id);
      setStaticResourceImgSelected?.(data);
      updateContextInput?.({
        resource_id: data.id,
      });
    }
  };

  const filterResources = () => {
    let totalResourceCount: number = 0;
    for (const [key, value] of Object.entries(setGrades)) {
      if (selectedGrade?.length && selectedGrade?.length !== GradeOptions.length  && !selectedGrade?.includes(key)) continue;
      const resources = value as StaticResource[];
      totalResourceCount += resources.length;
    }
    return [setGrades, totalResourceCount];
  };

  const resourceCount = (rows: JSX.Element[]) => rows.find((row) => row.props.children.length > 0);

  const noResult = () =>  (!isBenchmark ?
    !loading && (<NoImagesFound
      label="No image(s) found"
      description="We don’t currently have any images for this, but more images are on the way!"
      suggestString="If you have an image suggestion, email us at"
      email='photos@flashlight360.com'
    />
    ) :  !loading && (<NoBenchmarkImageFound
      label="No image(s) found"
      description="We don’t currently have any images for this, but more images are on the way!"
    />
    )
  );

  const renderCards = (rows: StaticResource[], key: string) => {
    return rows
      .map((row: StaticResource) => (
        <LazyLoad
          key={`static-resource-lazy-${row.id}`}
          threshold={0.75}
        >
          <Box
            onClick={() => {
              selectStaticResource(row);
            }}
            key={`static-resource-box-${row.id}`}
          >
            <GradeCard
              imageSrc={row?.file?.url  || ''}
              thumnailImageSrc={row?.file?.thumbnail_url || ''}
              cardTitle={row.title}
              cardGrade={key}
              key={`static-resource-lazy-grade-card-${row.id}`}
              selected={((isDisabled || editableField)
                && resourceId === selected)
                ? (resourceId === row.id) : (selected === row.id)}
              isDisabled={isDisabled}
              isEditMode={isEditMode}
            />
          </Box>
        </LazyLoad>
      ));
  };

  const renderStaticResources = () => {
    let [filteredResources, filteredResourcesCount] = filterResources();
    const rows = [];
    for (const [key, value] of Object.entries(filteredResources)) {
      let newResources = value as StaticResource[];
      newResources && rows.push(
        <Box sx={styles.innerWrapper}>
          { renderCards(newResources, key) }
        </Box>,
      );
    }
    const gradesString = selectedGrade?.length ? ` in Grades ${selectedGrade?.map((item) => ` ${item}`)}` : '';
    const subjectsString = selectedSubjects?.length ? ` and in Subject Area${selectedSubjects.length > 1 ? 's' : ''} ${subjectAreas?.filter((area: any) => selectedSubjects.includes(area?.id))?.map((subject) => ` ${subject.title}`)}` : '';
    return (
      <>
        <Box>
          <Typography variant='h6' component='p' sx={styles.ptImageBoxtextHelper}>
            {!loading && filteredResourcesCount === 0
              ? <Box>
                No result {(searchTerm && searchTerm.length > 2 ? `for "${searchTerm}"` : '') + gradesString + subjectsString + '. Try again with new criteria'}
                <Link
                  underline="none"
                  sx={{ ...styles.buttonTransformation }}
                  onClick={() => resetFilters?.()} >
                    Reset Filters
                </Link>
              </Box>
              : `${filteredResourcesCount} Image(s) Available`}
          </Typography>
        </Box>
        <Box sx={{ ...styles.ptImageBox, ...(isDisabled && styles.disabled) }}>
          <Loader open={loading!} />
          {(rows && resourceCount(rows)) ? rows : noResult()}
        </Box>
      </>
    );
  };

  return (
    <Grid
      xs={12}
    >
      <Box
        display="flex"
        flexDirection="column"
        paddingTop={2}
      >
        <Box sx={styles.formLabelContainer}>
          <Typography variant='sFormTitle' mt={0.25}>Grade Level:</Typography>
        </Box>
        <Box>
          <MultipleSelectChips
            value={selectedGrade}
            onChange={handleGradeChange}
            options={GradeOptions}
          />
        </Box>
        <Box sx={styles.formLabelContainer}>
          <Typography variant='sFormTitle' mt={0.25}>Subject Area:</Typography>
        </Box>
        <Box>
          <MultipleSelectChips
            value={selectedSubjects}
            onChange={handleSubjectChange}
            options={subjectOptions as Object[]}
          />
        </Box>
        <Grid container spacing={10}>
          <Grid item xs={4.5}>
            <Box sx={styles.formLabelContainer}>
              <Typography sx={styles.GradeLevelTypography}>Keyword/Title Search</Typography>
              {searchTerm && <Link underline="none" sx={{ cursor: 'pointer' }} onClick={() => setSearchTerm?.('')} >Clear Search</Link>}
            </Box>
            <Box sx={styles.formFieldContainer} mt={2}>
              <KeywordSearchBar
                searchTerm={searchTerm}
                handleChange={onSearch!}
                mt={-2}
              />
            </Box>
          </Grid>
          <Grid item xs={4.5} mt={1}>
            <TaskTypes
              value={selectedTaskTypes}
              onChange={setTaskTypes}
            />
          </Grid>
        </Grid>
        {renderStaticResources()}
      </Box>
    </Grid>
  );
};

export default React.memo(StaticResources);
