/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';

import { AUTH_KEY } from '../variables/constant';
import client from '../core/apolloClient';
import meQuery from './meQuery';
import { useAuthProvider } from '../core/authContext';
import { openSnackbar } from '../components/Notifier';
import getErrorMessage from './getErrorMessage';
import { NotifierType } from '../variables/types';
import { useImitationProvider } from '../core/imitationContext';

const useUserInfo = () => {
  const { getUser, setUser } = useAuthProvider();
  const [user, setNewUser] = useState(getUser());
  const { getImitationUserId } = useImitationProvider();
  const [isLoadingUserInfo, setLoadingUserInfo] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setLoadingUserInfo(true);
        const { data: userInfo } = await client(getImitationUserId()).query({ query: meQuery });
        const userDetails = userInfo?.me;
        setUser(userDetails);
        setNewUser(userDetails);
      } catch (error) {
        openSnackbar({ message: getErrorMessage(error, true) }, NotifierType.Error);
      }
      setLoadingUserInfo(false);
    };

    const onAuthChange = async ({ payload: { event } }: any) => {
      switch (event) {
        case 'signIn':
          await getUserInfo();
          break;
        case 'signOut':
          setUser(null);
          setNewUser(null);
          break;
      }
    };

    Hub.listen(AUTH_KEY, onAuthChange);
    return () => {
      Hub.remove(AUTH_KEY, onAuthChange);
    };
  }, [getImitationUserId, setUser]);
  return [isLoadingUserInfo, user];
};

export default useUserInfo;
