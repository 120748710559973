import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Box, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import XaxisCustomizeTick from '../OverallBenchmarkIndicatorGraph/XaxisCustomizeTick';
import YaxisCustomizeTick from '../OverallBenchmarkIndicatorGraph/YaxisCustomizeTick';

interface Props {
  barData: any,
  xAxisKey: string,
  barKey1: string,
  barKey2: string,
  barKey3: string,
  barWidth1?: number,
  barWidth2?: number,
  barWidth3?: number,
  barHeight: number,
  barSize1: number,
  barSize2: number,
  barSize3: number,
  yAxisDomain: Array<number>,
  barRadius: any,
  yAxisTicks: Array<number>,
  styleClass?: any,
}

const AdminBenchmarkIndicatorScoreChart = ({
  barData,
  xAxisKey,
  barKey1,
  barKey2,
  barKey3,
  barWidth1 = 800,
  barWidth2 = 300,
  barHeight,
  barSize1,
  barSize2,
  barSize3,
  yAxisDomain,
  barRadius,
  yAxisTicks,
}: Props) => {
  const barData1 = barData.filter((data: any, index: number) => index <= 4);
  const barData2 = barData.filter((data: any, index: number) => index > 4);

  const overrideContent = ({ value, width, height, x, y, ...rest }: any) => {
    const containsDecimal = value.toString().includes('.');
    const labelColor =  (rest?.fill && rest?.fill !== palette.customYellow.main) ?
      value >= 1.5 ? palette.customWhite.main : palette.customBlack.main : palette.customBlack.main;
    return value !== 0 && <text {...rest} x={containsDecimal ? x + width / 3 - 3 : (x + width / 2) - 2}
      y={y + height - 10} fill={labelColor}>{value}</text>;
  };

  const overrideContentTop = ({ value, x, y, ...rest }: any) => {
    const splitValue = value.split('-');
    const scoreValInt = splitValue?.[1] && parseFloat(splitValue?.[1] );
    let yValue = 0;
    let labelColor = palette.customBlue.chipDark;
    switch (yAxisTicks?.length) {
      case 4:
        yValue = scoreValInt === 0 ? y - 80 : scoreValInt >= 1 && scoreValInt <= 1.3 ? y - 18 : scoreValInt === 4 ? y + 12 : y - 3;
        labelColor =  (scoreValInt === 4) ? palette.customWhite.main : labelColor;
        break;
      default:
        yValue = scoreValInt === 0 ? y - 63 : scoreValInt >= 1 && scoreValInt <= 1.3 ? y - 20 : scoreValInt === 5 ? y + 12 : y - 3;
        labelColor =  (scoreValInt === 5) ? palette.customWhite.main : labelColor;
        break;
    }

    return <text {...rest}
      x={x + 6}
      y={yValue}
      fill={labelColor}>{splitValue[0]}</text>;
  };

  const overrideLabelList = (key : string, 
    position: any =  'insideBottom',
    fontSize: number = 13,
    fontWeight: number = 800,
    content: any) => {
    return  (<LabelList
      dataKey={key}
      position={position}
      fontSize={fontSize}
      fontWeight={fontWeight}
      content={content ? content : overrideContent}
    />
    );
  };

  return (
    <Box sx={{ height: '410px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box sx={{ display: 'flex',  flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              paddingBottom={2}
              paddingTop={5}
              paddingLeft={5}
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: palette.customBlack.fontBlack,
              }}>Speaking</Typography>
            <BarChart
              width={barWidth1}
              height={barHeight}
              data={barData1}
              margin={{
                top: 5,
                bottom: 40,
                left: -20,
              }}
              barGap={3}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={xAxisKey}
                tickLine={false}
                minTickGap={1}
                tickMargin={1}
                interval={0}
                axisLine={false}
                scale="point"
                padding={{ left: 60, right: 80 }}
                tick={<XaxisCustomizeTick barData={barData}/>} />
              <YAxis tickLine={false} axisLine={false}  tick={<YaxisCustomizeTick />} tickMargin={8}
                domain={yAxisDomain} ticks={yAxisTicks} allowDataOverflow={true} />
              <Bar dataKey={barKey1} fill="fill" barSize={barSize1} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey1, 'insideBottom', 14, 800, overrideContent)}
                {overrideLabelList('barBoy', 'top', 12, 700, overrideContentTop)}
                {barData1.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.rubricBoyScoreColor} />
                ))}
              </Bar>
              <Bar dataKey={barKey2} fill="fill" barSize={barSize2} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey2, 'insideBottom', 14, 800, overrideContent)}
                {overrideLabelList('barMoy', 'top', 12, 700, overrideContentTop)}
                {barData1.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.rubricMoyScoreColor} />
                ))}
              </Bar>
              <Bar dataKey={barKey3} fill="fill" barSize={barSize3} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey3, 'insideBottom', 14, 800, overrideContent)}
                {overrideLabelList('barEoy', 'top', 12, 700, overrideContentTop)}
                {barData1.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.rubricEoyScoreColor} />
                ))}
              </Bar>
            </BarChart>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              paddingBottom={2}
              paddingTop={5}
              paddingLeft={4}
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: palette.customBlack.fontBlack,
              }}>Writing</Typography>
            <BarChart
              width={barWidth2}
              height={barHeight}
              data={barData2}
              margin={{
                top: 5,
                bottom: 40,
                left: -60,
              }}
              barGap={3}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={xAxisKey}
                tickLine={false}
                minTickGap={1}
                tickMargin={1}
                interval={0}
                axisLine={false}
                domain={[0, 5]}
                padding={{  right: -25 }}
                tick={<XaxisCustomizeTick barData={barData}/>} />
              <YAxis tickLine={false} axisLine={true}  tick={<YaxisCustomizeTick />} domain={yAxisDomain} ticks={yAxisTicks} allowDataOverflow={true}/>
              <Bar dataKey={barKey1} fill="fill" barSize={barSize1} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey1, 'insideBottom', 14, 800, overrideContent)}
                {overrideLabelList('barBoy', 'top', 12, 700, overrideContentTop)}
                {barData2.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.rubricBoyScoreColor} />
                ))}
              </Bar>
              <Bar dataKey={barKey2} fill="fill" barSize={barSize2} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey2, 'insideBottom', 14, 800, overrideContent)}
                {overrideLabelList('barMoy', 'top', 12, 700, overrideContentTop)}
                {barData2.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.rubricMoyScoreColor} />
                ))}
              </Bar>
              <Bar dataKey={barKey3} fill="fill" barSize={barSize3} isAnimationActive={false} radius={barRadius} minPointSize={4}>
                {overrideLabelList(barKey3, 'insideBottom', 14, 800, overrideContent)}
                {overrideLabelList('barEoy', 'top', 12, 700, overrideContentTop)}
                {barData2.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.rubricEoyScoreColor} />
                ))}
              </Bar>
            </BarChart>
          </Box>
        </Box>
      </ResponsiveContainer>
    </Box>
  );
};

export default AdminBenchmarkIndicatorScoreChart;

