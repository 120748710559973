import React from 'react';
import { Box, Typography } from '@mui/material';
import TileButton from '../../components/TileButton';
import usePermission from '../../hooks/usePermission';
import { UserPermissions } from '../../generated/graphql';
import DistrictsIcon from '../../components/Icons/DistrictsIcon';
import SchoolsIcon from '../../components/Icons/SchoolsIcon';
import ClassesIcon from '../../components/Icons/ClassesIcon';
import TeachersIcon from '../../components/Icons/TeachersIcon';
import SchoolAdminIcon from '../../components/Icons/SchoolAdminIcon';
import { StudentsIcon } from '../../components/Icons';

const LandingPage = () => {
  const { hasPermission: canAddDistrict } = usePermission(UserPermissions.AddDistricts);
  const { hasPermission: canAddSchool } = usePermission(UserPermissions.AddSchools);
  const { hasPermission: canAddClasses } = usePermission(UserPermissions.AddClasses);
  const { hasPermission: canAddTeacher } = usePermission(UserPermissions.AddTeachers);
  const { hasPermission: canAddStudent } = usePermission(UserPermissions.AddStudents);
  const { hasPermission: canAddSchoolAdmins } = usePermission(UserPermissions.AddSchoolAdmins);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box>
        <Typography variant="pageTitle">
          Add a new:
        </Typography>
      </Box>
      <Box
        mt={3}
        display="grid"
        gridTemplateColumns="repeat(3, 200px)"
        sx={{
          gridGap: 32,
        }}
      >
        {canAddDistrict && (
          <TileButton
            label="District"
            Icon={DistrictsIcon}
            redirectionLink="/admin/create/district"
          />
        )}
        {canAddSchool && (
          <TileButton
            label="School"
            Icon={SchoolsIcon}
            redirectionLink="/admin/create/school"
          />
        )}
        {canAddClasses && (
          <TileButton
            label="Class"
            Icon={ClassesIcon}
            redirectionLink="/admin/create/class"
          />
        )}
        {canAddTeacher && (
          <TileButton
            label="Teacher"
            Icon={TeachersIcon}
            redirectionLink="/admin/create/teacher"
          />
        )}
        {canAddStudent && (
          <TileButton
            label="Student"
            Icon={StudentsIcon}
            redirectionLink="/admin/create/student"
          />
        )}
        {canAddSchoolAdmins && (
          <TileButton
            label="Admin"
            Icon={SchoolAdminIcon}
            redirectionLink="/admin/create/admin"
          />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(LandingPage);
