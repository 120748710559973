import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DownArrowIcon = ({ pathfill }: any) => {
    return (

        <SvgIcon width="10" height="6" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg" fontSize='smaller'>
            <path d="M12.4632 2.97877L7.77414 7.49047C7.06806 8.16984 5.92747 8.16984 5.22139 7.49047L0.532297 2.97877C-0.608295 1.88133 0.206414 -1.64777e-07 1.81772 -2.39113e-08L11.1959 7.95956e-07C12.8072 9.36822e-07 13.6038 1.88133 12.4632 2.97877Z" fill="#4B5563" />
        </SvgIcon>
    )
}
export default DownArrowIcon;


