import { useAuthProvider } from '../core/authContext';
import { Permission } from '../generated/graphql';

const usePermission = (permissions: string | string[]) => {
  const { getUser } = useAuthProvider();

  const checkPermission = () => {
    const user = getUser();
    if (user !== null) {
      const { permissions: userPermissions } = user;
    if (Array.isArray(permissions)) {
      const flatUserPermissions = userPermissions?.map((permission: Permission) => permission.permission);
      return permissions?.every((permission) => flatUserPermissions?.includes(permission as string));
    }
    return !userPermissions?.every((permission: Permission) => permission.permission !== permissions);
    }
    return false;
  };

  return {
    hasPermission: checkPermission(),
  };
};

export default usePermission;
