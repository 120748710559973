/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const PencilIcon = (props: any) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path d="M18.2678 1.73223L18.9749 1.02513V1.02513L18.2678 1.73223ZM4.50006 19.0355V20.0355C4.76528 20.0355 5.01963 19.9301 5.20717 19.7426L4.50006 19.0355ZM1.00006 19.0355H6.10352e-05C6.10352e-05 19.5878 0.447776 20.0355 1.00006 20.0355L1.00006 19.0355ZM1.00006 15.4644L0.292954 14.7573C0.105418 14.9448 6.10352e-05 15.1992 6.10352e-05 15.4644H1.00006ZM15.4393 2.43934C16.0251 1.85355 16.9749 1.85355 17.5607 2.43934L18.9749 1.02513C17.608 -0.341709 15.392 -0.341709 14.0251 1.02513L15.4393 2.43934ZM17.5607 2.43934C18.1465 3.02513 18.1465 3.97487 17.5607 4.56066L18.9749 5.97487C20.3417 4.60804 20.3417 2.39196 18.9749 1.02513L17.5607 2.43934ZM17.5607 4.56066L3.79295 18.3284L5.20717 19.7426L18.9749 5.97487L17.5607 4.56066ZM4.50006 18.0355H1.00006V20.0355H4.50006V18.0355ZM14.0251 1.02513L0.292954 14.7573L1.70717 16.1715L15.4393 2.43934L14.0251 1.02513ZM6.10352e-05 15.4644V19.0355H2.00006V15.4644H6.10352e-05ZM12.5251 3.93934L16.0607 7.47487L17.4749 6.06066L13.9393 2.52513L12.5251 3.93934Z" fill={props.fillColor || palette.customBlack.fontBlack}/>
  </SvgIcon>
);

export default PencilIcon;
