import React, { useReducer, createContext, useEffect } from 'react';

interface State {
  featureFlags: Record<string, boolean>
}

interface Action {
  type: string,
  payload: any
}

interface Props {
  children: React.ReactNode
}

const initialState = {
  featureFlags: {},
};

const reducer = (state: State, action : Action) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE_FLAG': {
      const featureFlags = {
        ...state.featureFlags,
        ...payload,
      };
      localStorage.setItem('featureFlags', JSON.stringify(featureFlags));
      return {
        ...state,
        featureFlags,
      };
    }
    case 'RESET_FLAGS':
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

interface ContextVal {
  featureFlags?: Record<string | number, unknown>,
  featureFlagDispatch?: Function
}

// @ts-ignore
const FeatureFlagContext = createContext<ContextVal>();

/**
 * To use feature toggle, We can add environment variable prefixed with "REACT_APP_FEATURE_FLAG_".
 * e.g. "REACT_APP_FEATURE_FLAG_CLEVER_BUTTON=false"
 *
 * The same can be updated using devtool.
 *
 * To enable dev tool. Add "devtool=true" in the local storage
 */

const FeatureFlagContextProvider = ({ children }: Props) => {
  const [state, featureFlagDispatch] = useReducer(reducer, initialState);

  const loadFlags = () => {
    const flags = Object.keys(import.meta.env)?.filter((flag) => /REACT_APP_FEATURE_FLAG_/gi.test(flag));
    const updatedAt = localStorage.getItem('updatedAt') || '0';
    const currentReleaseTime = import.meta.env.REACT_APP_BUILD_TIME;
    let flagsLocal = {};
    if (updatedAt !== currentReleaseTime) {
      localStorage.setItem('updatedAt', currentReleaseTime ?? '0');
    } else {
      flagsLocal = JSON.parse(localStorage.getItem('featureFlags') || '{}');
    }
    return flags?.reduce((envFlags: Record<string, boolean>, flag: string) => {
      const flagAttribute = flag.replace(/REACT_APP_FEATURE_FLAG_/igm, '');
      return ({
        ...envFlags,
        [flagAttribute]: ['true', '1'].includes(import.meta.env[flag] || ''),
        ...flagsLocal,
      });
    }, {}) || {};
  };

  useEffect(() => {
    const flags = loadFlags();
    featureFlagDispatch({
      type: 'UPDATE_FLAG',
      payload: flags,
    });
  }, []);

  return (
    <FeatureFlagContext.Provider value={{ ...state, featureFlagDispatch }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export {
  FeatureFlagContext,
  FeatureFlagContextProvider,
  reducer,
};
