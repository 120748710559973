import { RecordingTracker } from './create-assignment-hook';

interface Props {
  // Form Behaviour trackers
  recordingTracker?: RecordingTracker[],
  setRecordingTracker?: Function,
}

const useRecordingTracker = ({
  recordingTracker,
  setRecordingTracker,
}: Props) =>{
  const updateRecordingTracker = (recorder: RecordingTracker) => {
    let trackerUpdated: boolean = false;
    const newRecordingTracker = recordingTracker?.map((tracker: RecordingTracker) => {
      if (tracker.name === recorder.name) {
        trackerUpdated = true;
        return {
          ...tracker,
          isRecording: recorder.isRecording,
        };
      }
      return tracker;
    });
    if (!trackerUpdated) newRecordingTracker?.push(recorder);
    setRecordingTracker?.(newRecordingTracker);
  };

  const disableRecorder = (recorderTag: string): boolean => {
    let disable: boolean = false;
    recordingTracker?.forEach((recTracker) => {
      if (recTracker.name !== recorderTag && recTracker.isRecording) disable = true;
    });
    return disable;
  };

  return {
    recordingTracker,
    setRecordingTracker,
    updateRecordingTracker,
    disableRecorder,
  };
};

export default useRecordingTracker;
