import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Theme, Typography, useTheme } from '@mui/material';
import WarningIcon from '../Icons/WarningIcon';
import { palette } from '../../theme/palette';

interface Action {
  title: string;
  event: any;
  classname?: 'deleteButton' | 'keepButton' | 'cancelChanges' | 'saveChanges' | 'cancelNavigation' | 'continueNavigation';
  buttonVariant?: any;
}

interface Props {
  openDialog : any,
  closeDialog?: any,
  header: String,
  subheader: string | React.ReactNode,
  onDelete?: () => void,
  onCancel?: () => void,
  actions?: Array<Action>;
  highlight?: boolean;
  navigateWarning?: boolean;
}

const warningStyles = (theme: Theme) => ({
  deleteButton: {
    '&.MuiButtonBase-root.MuiButton-root': {
      color: theme.palette.customWhite.main,
      fontWeight: 'bold',
      borderRadius: theme.spacing(1.5),
      backgroundColor: theme.palette.customRed.buttonFontRed,
      '&:hover': {
        color: theme.palette.customWhite.main,
        backgroundColor: theme.palette.customRed.buttonFontRed,
      },
      '&::after': {
        backgroundColor: theme.palette.customRed.buttonFontRed,
      },
    },
  },
  keepButton: {
    '&.MuiButtonBase-root.MuiButton-root': {
      color: theme.palette.customBlack.lightBlack,
      fontWeight: 'bold',
      backgroundColor: theme.palette.customWhite.main,
      border: `2px solid ${theme.palette.customBlack.lightBlack}`,
      borderRadius: theme.spacing(1.5),
      '&::after': {
        backgroundColor: theme.palette.customWhite.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.customWhite.main,
      },
    },
  },
  cancelChanges: {
    color: theme.palette.overlays.cornflowerBlue,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customWhite.main,
    border: `2px solid ${theme.palette.overlays.cornflowerBlue}`,
    borderRadius: theme.spacing(1.5),
    '&::after': {
      backgroundColor: theme.palette.customWhite.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.customWhite.main,
    },
  },
  saveChanges: {
    marginLeft: theme.spacing(3.5),
    color: theme.palette.customWhite.main,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBlue.primaryPurple,
    '&:hover': {
      color: theme.palette.customWhite.main,
      backgroundColor: theme.palette.customBlue.primaryPurple,
    },
    '&::after': {
      backgroundColor: theme.palette.overlays.cornflowerBlue,
    },
  },
  cancelNavigation: {
    '&.MuiButtonBase-root.MuiButton-root': {
      color: theme.palette.customBlue.primaryBlue,
      fontWeight: 'bold',
      backgroundColor: theme.palette.customWhite.main,
      border: `2px solid ${theme.palette.customBlue.primaryBlue}`,
      borderRadius: theme.spacing(1.5),
      '&::after': {
        backgroundColor: theme.palette.customWhite.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.customWhite.main,
      },
    },
  },
  continueNavigation: {
    '&.MuiButtonBase-root.MuiButton-root': {
      color: theme.palette.customRed.darkRed,
      fontWeight: 'bold',
      backgroundColor: theme.palette.customWhite.main,
      border: `2px solid ${theme.palette.customRed.darkRed}`,
      borderRadius: theme.spacing(1.5),
      '&::after': {
        backgroundColor: theme.palette.customWhite.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.customWhite.main,
      },
    },
  },
});

const WarningWindow = (
  {
    openDialog,
    closeDialog,
    header,
    subheader,
    actions,
    navigateWarning = false,
  }: Props,
) => {
  const theme = useTheme();
  const styles = warningStyles(theme);
  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby="warning-dialog-title"
      aria-describedby="warning-dialog-description"
    >
      {navigateWarning && (
        <Box p={2} display="flex" justifyContent='center'>
          <WarningIcon colorFill={palette.customBlue.primaryBlue} width={40} height={40}/>
        </Box>
      )}
      <Typography variant='secondaryTitle' sx={{ textTransform: 'none', marginLeft: '24px' }} component='p'>
        {header}
      </Typography>
      <DialogContent>
        <Typography variant='sLabel' component='p' mb={2}>
          {subheader}
        </Typography>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end">
        <DialogActions>
          {actions?.map((action, index) => (
            <Button
              key={`action${index}`}
              variant={action.buttonVariant ? action.buttonVariant : 'contained'}
              color="primary"
              autoFocus
              onClick={action.event}
              sx={{ ...(action?.classname) && styles[action?.classname] }}
            >
              {action.title}
            </Button>
          ))}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default WarningWindow;
