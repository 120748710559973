import { useState } from 'react';
import getPathFromUrl from './url';
import { getHeaders } from './multipart-uploader/uploader';

export interface ThumbnailGenInputType {
  url: string,
  file_name: string,
  file_type: string,
}

export interface ThumbnailGenResponseType {
  url: string,
  file_type: string,
  file_key: string,
}

export interface ThumbnailGenUtilInterface {
  thumbnailUrl: string,
  accessToken: string,
}
export interface ThumbnailGenReqInterface extends ThumbnailGenUtilInterface{
  url: string,
  file_name: string,
  file_type: string,
}

/** Thumbnail Util Request Handlers */
const thumbnailGenReq = async ({
  url, file_name, file_type, thumbnailUrl, accessToken,
}: ThumbnailGenReqInterface) => fetch(`${thumbnailUrl}/generate`, {
  method: 'POST',
  ...getHeaders({ accessToken, contentType: 'application/json' }),
  body: JSON.stringify({
    url,
    file_name,
    file_type,
  }),
});

// Thumbnail Util Hook
const useThumbnailUtil = ({
  thumbnailUrl,
  accessToken,
}: ThumbnailGenUtilInterface) => {
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line no-async-promise-executor
  const thumbnailGenAsync = async (input: ThumbnailGenInputType) => new Promise<ThumbnailGenResponseType>(async (resolve, reject) => {
    try {
      setIsLoading(true);
      const req = await thumbnailGenReq({
        ...input,
        thumbnailUrl,
        accessToken,
      });
      const resp = await req.json();
      resp.url = getPathFromUrl(resp.url);
      resolve(resp);
      setIsLoading(false);
    } catch (err) {
      reject(err);
      setIsLoading(false);
    }
  });

  return { thumbnailGenAsync, isLoading };
};

export default useThumbnailUtil;
