import { useAdminBmStudentsSlideoutQuery } from '../../../../generated/graphql';

const useAdminStudentSlideout = ({ benchmarkId, statusList }:any) => {

  const { data, loading } = useAdminBmStudentsSlideoutQuery({
    fetchPolicy: 'network-only',
    variables: {
      benchmarkId: benchmarkId,
      status: statusList,
    },
  });

  return {
    totalCount: data?.adminBenchmarkSlideOut?.total_count ?? 0,
    slideoutStudents: data?.adminBenchmarkSlideOut?.nodes ?? [],
    loading,
  };
};

export default useAdminStudentSlideout;
