/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { svgStyles } from '../Common/Common.styled';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { SchoolFilterFragmentFragment } from '../../generated/graphql';
import useSchoolsFilter from './school-filter-hook';
import SchoolsIcon from '../Icons/SchoolsIcon';
import { palette } from '../../theme/palette';
import { SxProps, Theme } from '@mui/system';
import { List, ListItem } from '@mui/material';

interface Props {
  error?: boolean;
  className?: string;
  value?: SchoolFilterFragmentFragment | null | string;
  onChange?: (
    data: SchoolFilterFragmentFragment | null | string,
    school: SchoolFilterFragmentFragment | null
  ) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  stateId?:string | undefined;
  districtId?:string | undefined;
  disabled?:boolean;
  readOnly?:boolean;
  sx?: SxProps<Theme> | undefined;
}

type SchoolValue = SchoolFilterFragmentFragment | null | undefined;

const SchoolFilter = ({
  className,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  stateId,
  districtId,
  disabled,
  readOnly = false,
  sx,
}: Props) => {
  const districts =  !!districtId ? [districtId] : undefined;
  const states = !!stateId ? [stateId] : undefined;
  const {
    schoolsOnly: schools,
    loading,
  } = useSchoolsFilter({ stateIds: states, districts });
  const onSelectChange = (
    _: any,
    school: SchoolFilterFragmentFragment | null,
  ) => {
    onChange?.(idSelectOnly ? (school?.id ?? '') : school, school);
  };
  const selectedValue: SchoolValue = idSelectOnly ? schools?.find((item: any) => item.id === value) ?? null : value as SchoolValue;

  return (
    <Autocomplete
      className={className}
      options={schools || []}
      getOptionLabel={(option: any) => option?.name}
      isOptionEqualToValue={(option: any, selected: any) => option?.name === selected?.name}
      onChange={onSelectChange}
      value={selectedValue}
      sx={sx}
      readOnly={readOnly}
      disabled={disabled || loading}
      renderOption={(props, option: any) => (
        <List
          key={option?.id}
          sx={{ p: '0px' }}>
          <ListItem {...props}>
            {option?.name ?? ''}
          </ListItem>
        </List>
      )}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: { ...(sx ? {  backgroundColor: palette.customWhite.whiteSmoke } : { backgroundColor: 'inherit' }), fontWeight: 800, fontSize: '16px' },
            startAdornment: (
              <InputAdornment position="start">
                <SchoolsIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(SchoolFilter);
