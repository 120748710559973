import React, { useEffect } from 'react';
import { Box, CheckboxProps, Divider, InputAdornment, SxProps, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SchoolsIcon from '../Icons/SchoolsIcon';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';

type TeacherValue = {
  id: string;
  name: string;
};

interface Props {
  error?: boolean;
  value?: string[] | null;
  onChange?: (data: any[]) => void;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: any;
  limitTags?: number;
  isStartAdornment?: boolean;
  hideGroups?: boolean,
  schoolId?: string | null,
  zIndexPopper?: number;
  teachers?: TeacherValue[] | undefined;
}

const styles = {
  textField: {
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    '& input.MuiInputBase-input.Mui-disabled': {
      backgroundColor: palette.customWhite.inputDisabled,
    },
  },
  box: {
    backgroundColor: palette.customWhite.main,
    height: '45px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

const MultiSelectAdminTeacherFilter = ({
  placeholder = '',
  onChange,
  value,
  errorMessage,
  disabled,
  limitTags = 2,
  isStartAdornment = true,
  zIndexPopper = 1300,
  teachers,
}: Props) => {

  const allTeachersOption: any = {
    id: 'all',
    teacher: 'Select All',
  };

  const teacherOptions: TeacherValue[] | undefined = teachers && teachers?.length ? [allTeachersOption, ...teachers] : teachers;
  const [checkAll, setCheckAll] = React.useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const onSelectChange = (_: any, teacherList: TeacherValue[] | undefined = []) => {
    const teacherListIds = teacherList?.map((teacherOption: TeacherValue) => teacherOption?.id);
    onChange?.(teacherListIds as string[] || []);
  };
  const filterIdsWithTeachers = (selectedValue: any[] | undefined | null) => {
    const teacherIds = teachers?.map((teacherOption: any) => teacherOption.id) ?? [];
    const filteredIds = selectedValue?.filter((id) => teacherIds.includes(id)) ?? [];
    const teacherList = teachers?.filter((teacherOption) => filteredIds.includes(teacherOption?.id));
    if (selectedValue?.length !== filteredIds.length && teachers) onChange?.(teacherList?.map((teacherOption: any) => teacherOption?.id) ?? []);
    if (selectedValue?.length === 0 || selectedValue?.length !== teachers?.length) {
      setCheckAll(false);
    } else if (selectedValue?.length === teachers?.length) {
      setCheckAll(true);
    }
  };
  useEffect(() => {
    filterIdsWithTeachers(value);
  }, [value]);

  const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      onChange?.(teachers?.map((teacherOption) => teacherOption?.id) as string[]);
    } else {
      onChange?.([]);
    }
  };
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disabled={disabled}
      limitTags={limitTags}
      options={teacherOptions ?? []}
      getOptionLabel={(option: TeacherValue) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: TeacherValue, selected: TeacherValue) => option?.id === selected?.id}
      onChange={onSelectChange}
      slotProps={{ popper: { sx: { zIndex: zIndexPopper } } }}
      value={teachers?.filter((teacherOption: TeacherValue) => value?.includes(teacherOption?.id!)) ?? []}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Box sx={{ maxWidth: '120px' }}>
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.name ?? ''}</Typography>}
              {...getTagProps({ index })}
            />
          </Box>
        ))
      }
      renderOption={(props, option: any, { selected }) => {


        return (
          option?.id === 'all' ? (
            <>
              <Box sx={{ ...styles.box } as SxProps} key={option?.id}>
                <Checkbox
                  checked={checkAll}
                  onChange={checkAllChange}
                  id="check-all"
                  onMouseDown={(e) => e.preventDefault()}
                />
              Select All
              </Box>
              <Divider />
            </>
          ) : <Box component="li" {...props} key={option?.id}>
            <Checkbox
              color={'primary' as CheckboxProps['color']}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
              sx={{ mr: '8px' }}
            />
            {`${option?.name ?? ''}`}
          </Box>
        );
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            sx={{ ...styles.textField }}
            placeholder={params.InputProps.startAdornment ? undefined : placeholder.length > 1 ? placeholder : 'Select Teachers'}
            error={!!value}
            helperText={value?.length === 0 ? errorMessage?.schoolHelperText : ''}
            required={value?.length === 0}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '300px', overflow: 'scroll', fontSize: '16px', background: palette.customWhite.main, zIndex: zIndexPopper },
              startAdornment: isStartAdornment ? (
                <>
                  <InputAdornment position="start">
                    <SchoolsIcon
                      pathfill={palette.customBlue.primaryBlue}
                      sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ) : <>{params.InputProps.startAdornment}</>,
            }}
          />
        </>
      )}
    /> 
  );
};

export default MultiSelectAdminTeacherFilter;
