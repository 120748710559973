import { useEffect, useState } from 'react';
import {
  OrderBy,
  GoalsScoreWidgetSortType,
  LearningGoalsScoresByIndicatorInput,
} from '../../../../generated/graphql';
import { DashboardWidgetsDataStatusProps, useDashboardContext } from '../../admin-analytics-hook';
import { useLearningScoresAndGoalsWidgetQuery } from '../../../../generated/graphql';
import { AdminDashboards } from '../../../../variables/constant';

interface QueryReqInputProps {
  input: LearningGoalsScoresByIndicatorInput,
}

const useGoalsAndScoresByIndicatorWidget = () => {

  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    analyticsFilters: queryFilters,
    updateDashboardWidgetsDataStatus,
  } = useDashboardContext();

  const [hasData, setHasData] = useState<boolean>(false);

  const [queryReqInput, setQueryReqInput] = useState<QueryReqInputProps>({
    input: {
      filters: queryFilters,
      sort: GoalsScoreWidgetSortType.RubricTitle,
      order_by: OrderBy.Asc,
      ...((isSchoolAdmin && mySchoolData?.id) && { school_id: mySchoolData?.id }),
      ...((isDistrictAdmin && myDistrictData?.id) && { district_id: myDistrictData?.id }),
    },
  });
  const updateQueryReqInput = (data: any) => {
    setQueryReqInput({
      input: {
        ...queryReqInput.input,
        ...data,
      },
    });
  };

  const onSortChange = (sortBy: GoalsScoreWidgetSortType, orderBy: OrderBy) => {
    updateQueryReqInput({
      sort: sortBy,
      order_by: orderBy,
    });
  };

  const handleSort = (column: GoalsScoreWidgetSortType) => () => {
    if (column === queryReqInput.input.sort) {
      onSortChange(column, queryReqInput.input.order_by === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const { data: goalsScoresByIndicatorWidgetResp, loading: goalsScoresByIndicatorWidgetLoading } = useLearningScoresAndGoalsWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryReqInput },
  });

  useEffect(() => {
    const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
      widget: AdminDashboards.learningGoals.GoalsAndScoresByIndicatorWidget.name,
      loading: goalsScoresByIndicatorWidgetLoading,
      dataMissing: undefined,
    };
    if (!goalsScoresByIndicatorWidgetLoading && !!goalsScoresByIndicatorWidgetResp?.learningScoresAndGoalsWidget) {
      const goalsScoresData = goalsScoresByIndicatorWidgetResp?.learningScoresAndGoalsWidget;
      // update widget's data status in root component data status tracker
      const goalsCount = goalsScoresData?.map((item) => item?.goals_count).reduce((total, num) => (total ?? 0) + (num ?? 0), 0)!;
      const avgScoresCount = goalsScoresData?.map((item) => item?.average_score).reduce((total, num) => (total ?? 0) + (num ?? 0), 0)!;
      // update widget's data status in root component data status tracker
      dashboardWidgetStatus.dataMissing = !goalsCount && !avgScoresCount;
      setHasData(goalsCount > 0 || avgScoresCount > 0);
    } else {
      setHasData(!goalsScoresByIndicatorWidgetLoading && !!goalsScoresByIndicatorWidgetResp?.learningScoresAndGoalsWidget);
    }
    updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalsScoresByIndicatorWidgetLoading, goalsScoresByIndicatorWidgetResp]);

  useEffect(() => {
    if (isSchoolAdmin && mySchoolData?.id) updateQueryReqInput({ school_id: mySchoolData?.id });
    if (isDistrictAdmin && myDistrictData?.id) updateQueryReqInput({ district_id: myDistrictData?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    queryFilters,
  ]);

  useEffect(() => {
    updateQueryReqInput({ filters: queryFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryFilters,
  ]);

  return ({
    queryReqInput, handleSort,
    goalsScoresByIndicatorWidgetLoading, goalsScoresByIndicatorWidgetData: goalsScoresByIndicatorWidgetResp?.learningScoresAndGoalsWidget,
    hasData,
    title: `${queryReqInput.input.filters?.submission_score_type} Goals & Scores by Indicator`,
  });
};

export default useGoalsAndScoresByIndicatorWidget;
