import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const BenchmarkTypeIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M12.4727 2.49693C12.1708 2.36756 11.8292 2.36756 11.5273 2.49693L3.1273 6.09693C2.68608 6.28602 2.4 6.71987 2.4 7.1999C2.4 7.67994 2.68608 8.11378 3.1273 8.30288L6.30045 9.6628C6.41584 9.53206 6.56048 9.42558 6.7273 9.35408L11.5273 7.29694C12.1365 7.03588 12.8419 7.31806 13.103 7.92721C13.364 8.53637 13.0819 9.24182 12.4727 9.50289L9.20002 10.9055L11.5273 11.9029C11.8292 12.0322 12.1708 12.0322 12.4727 11.9029L20.8727 8.30288C21.3139 8.11378 21.6 7.67994 21.6 7.1999C21.6 6.71987 21.3139 6.28602 20.8727 6.09693L12.4727 2.49693Z" fill={palette.customBlue.primaryBlue}/>
    <path d="M3.97205 11.276L6 12.1451V17.0671C5.58867 16.9738 5.16846 16.904 4.74085 16.8591C4.17712 16.8 3.73163 16.3545 3.67253 15.7908C3.62455 15.3333 3.6 14.8692 3.6 14.3997C3.6 13.3239 3.72889 12.2777 3.97205 11.276Z" fill={palette.customBlue.primaryBlue}/>
    <path d="M11.1599 19.8869C10.3525 19.0953 9.422 18.43 8.4 17.922V13.1737L10.5819 14.1088C11.4875 14.4969 12.5125 14.4969 13.4181 14.1088L20.0279 11.276C20.2711 12.2777 20.4 13.3239 20.4 14.3997C20.4 14.8692 20.3754 15.3333 20.3275 15.7908C20.2684 16.3545 19.8229 16.8 19.2591 16.8591C16.7717 17.12 14.5349 18.2255 12.8401 19.8869C12.3734 20.3443 11.6266 20.3443 11.1599 19.8869Z" fill={palette.customBlue.primaryBlue}/>
    <path d="M7.2 21.5999C7.86274 21.5999 8.4 21.0626 8.4 20.3999V17.922C7.64563 17.5471 6.84141 17.2579 6 17.0671V20.3999C6 21.0626 6.53726 21.5999 7.2 21.5999Z" fill={palette.customBlue.primaryBlue}/>
  </SvgIcon>
);

export default BenchmarkTypeIcon;
