import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  TableCell,
  CircularProgress,
} from '@mui/material';
import {
  StyledTableCellFlyover,
  tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import { SubmissionStatus } from '../../../../generated/graphql';
import NoResults from '../../../../components/NoResults';
import WavingLadyFrame from '../../../../components/Icons/WavingLadyFrame';
import usePTStudentSlideout from './pt-student-slideout-hook';
import TruncateStudentName from '../../../AdminBenchmarks/List/StudentListSlideout/TruncateStudentName';
import { MAX_CHARS_NOTSTARTED_STUDENT_NAME } from '../../../../variables/constant';

interface Props {
  assignId: string,
  setStudentCount: Function,
}

const NotStarted = ({ assignId, setStudentCount }: Props) => {

  const { totalCount, slideoutStudents, loading } = usePTStudentSlideout({
    assignId: assignId,
    statusList: [SubmissionStatus.NotStarted],
  });
  setStudentCount(totalCount);

  const isTableEmpty = !loading && slideoutStudents?.length === 0;

  return (
    <Box>
      <Table
        stickyHeader
        aria-label="students submissions table"
        sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
      >
        <TableBody>
          {slideoutStudents && slideoutStudents?.map((student) => (
            <TableRow key={student.student_number}>
              <StyledTableCellFlyover>
                <TruncateStudentName student={student} maxChars={MAX_CHARS_NOTSTARTED_STUDENT_NAME} />
              </StyledTableCellFlyover>
            </TableRow>
          ))}
          {isTableEmpty && (
            <TableRow>
              <TableCell
                colSpan={6}
                align="center"
              >
                <NoResults
                  label="No Students"
                  description="No students at this step yet"
                  svgComponent={<WavingLadyFrame />}
                />
              </TableCell>
            </TableRow>
          )}
          {loading && (
            <TableRow>
              <TableCell
                colSpan={6}
                align="center"
              >
                <CircularProgress size={28} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default NotStarted;
