/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';


const TeacherBenchmarkAnalyticsIcon = () => {
  return (
    <SvgIcon width="96" height="98" viewBox="0 0 96 98" fill="none" xmlns="http://www.w3.org/2000/svg" sx={{ fontSize: '98px' }}>
      <rect width="96" height="98" rx="12" fill="#2A2A8B" />
      <g clip-path="url(#clip0_27031_34520)">
        <mask id="mask0_27031_34520" maskUnits="userSpaceOnUse" x="13" y="14" width="70" height="70">
          <path d="M83 14H13V84H83V14Z" fill="white" />
        </mask>
        <g mask="url(#mask0_27031_34520)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M68.4327 25.6049C69.2032 26.3964 69.1862 27.6626 68.3947 28.4331L24.2232 71.4331C23.4317 72.2036 22.1655 72.1866 21.395 71.3951C20.6246 70.6036 20.6416 69.3374 21.433 68.5669L65.6046 25.5669C66.3961 24.7964 67.6623 24.8134 68.4327 25.6049Z" fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M27.584 62.5788V65.332C27.584 67.5778 29.4215 69.4153 31.6673 69.4153H32.2506C34.4965 69.4153 36.334 67.5778 36.334 65.332V54.0608L27.584 62.5788ZM43.9173 46.6786V65.332C43.9173 67.5778 45.7548 69.4153 48.0006 69.4153C50.2465 69.4153 52.084 67.5778 52.084 65.332V38.7286L43.9173 46.6786ZM52.084 37.333L43.9173 45.2831V32.6653C43.9173 30.4195 45.7548 28.582 48.0006 28.582C50.2465 28.582 52.084 30.4195 52.084 32.6653V37.333ZM36.334 52.6653L27.584 61.1832V44.9153C27.584 42.6695 29.4215 40.832 31.6673 40.832H32.2506C34.4965 40.832 36.334 42.6695 36.334 44.9153V52.6653ZM68.4173 55.9986C68.4173 53.7528 66.5798 51.9153 64.334 51.9153C62.0881 51.9153 60.2506 53.7528 60.2506 55.9986V65.332C60.2506 67.5778 62.0881 69.4153 64.334 69.4153C66.5798 69.4153 68.4173 67.5778 68.4173 65.332V55.9986Z" fill="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_27031_34520">
          <rect width="70" height="70" fill="white" transform="translate(13 14)" />
        </clipPath>
      </defs>
    </SvgIcon >



  );
};

export default TeacherBenchmarkAnalyticsIcon;
