import React, { useRef, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Text, Label,
} from 'recharts';
import { Box, styled } from '@mui/material';
import { EventData, TooltipState } from '../../../variables/types';
import { ACTIVE_GOAL_TOOLTIP_TYPE, CUSTOM_TOOLTIP_HEIGHT, CUSTOM_TOOLTIP_WIDTH } from '../../../variables/constant';
import CustomTooltip from '../Tooltips/ToolTip';
import { Scalars } from '../../../generated/graphql';
import zIndex from '../../../theme/z-index';
import { palette  } from '../../../theme/palette';

type DonutChartData = {
  name: Scalars['String'];
  value: Scalars['Int'];
  color: Scalars['String'];
};

interface Props {
  data: DonutChartData[],
  dataKey: string,
  boxHeight?: number | string,
  boxWidth?: number | string,
  chartHeight?: number,
  chartWidth?: number,
  onLinkClick?: (data?: any) => void
  startAngle?: number,
  endAngle?: number,
  Icon?: React.ReactNode
  tooltipType?: string
  label?: boolean
  innerRadius?: number
  outerRadius?: number
  totalCount?: number
  hideTooltip?: boolean;
  hideGoalsLabel?: boolean;
  angle?: number;
}

const styles = {
  totalGoal: {
    width: '82.14px',
    height: '77.45px',
    fontWeight: 'bold',
    fontSize: '40px',
  },
  totalGoalText: {
    width: '119.81px',
    height: '24.84px',
    fontWeight: 600,
    fontSize: '16px',
    color: palette.customBlue.darkestBlue,
    letterSpacing: '0.0757853px',
  },
  labelText: {
    width: '59px',
    height: '19px',
    fontSize: '14px',
    maxWidth: '50px',
  },
  outlineStyles: {
    '.recharts-wrapper>svg>g': {
      outline: 'none !important',
    } 
  },
};

const StyledProgressIcon = styled('g')<{ angle?: string, }>(({  angle }) => ({
  transform: `rotate(${angle}deg) scale(1.5)`,
  'transform-origin': 'center',
}));

const DonutLabel = ({ viewBox, Icon, angle }: any) => {
  const { cx, cy } = viewBox;
  return (
    <StyledProgressIcon textAnchor="middle" angle={angle}>
      {Icon && <Icon textAnchor="middle" x={cx - 30} y={cy - 10} />}
    </StyledProgressIcon>
  );
};

const DonutScoreChart = ({
  data,
  dataKey,
  Icon,
  angle,
  boxHeight = 300,
  boxWidth = 300,
  chartHeight = 300,
  chartWidth = 300,
  onLinkClick,
  startAngle = 210,
  endAngle = -30,
  tooltipType,
  label = false,
  innerRadius = 90,
  outerRadius = 120,
  hideTooltip = false,
}: Props) => {
  const [state, setState] = useState<TooltipState>({
    showTooltip: false,
    event: {
      pageX: 0,
      pageY: 0,
    },
    tooltipData: {},
  });
  const { showTooltip, event, tooltipData } = state;
  // Allow tooltip to stay open for timeout to make links clickable
  const tooltipTimeout = useRef<any>();
  const bodyWidth = document.querySelector('body')?.getBoundingClientRect().width ?? 0;
  const bodyHeight = document.querySelector('body')?.getBoundingClientRect().height ?? 0;
  /**
   * Track the hover on bar and capture the tooltip data
   * @param item - is data for an individual bar.
   * @param active - if the tooltip/hover is active.
   * @param eventObject: {EventData} - Captures the pageX and pageY to render the tooltip.
   * @param actionCreator - specifies if the current hove event is on tooltip or on the bar/cell.
   * */
  const handleHover = (
    item: any,
    active: boolean,
    eventObject: EventData | null,
    actionCreator: string,
  ) => {
    if (active) {
      if (actionCreator === 'tooltip') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        setState((prevState) => ({
          ...prevState,
          showTooltip: true,
        }));
      } else if (actionCreator === 'cell') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        setState((prevState) => ({
          ...prevState,
          showTooltip: true,
          tooltipData: item?.data ?? item,
          event: eventObject,
        }));
      }
    } else {
      if (actionCreator === 'cell') {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        tooltipTimeout.current = setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            showTooltip: false,
            event: eventObject,
          }));
        }, 200);
      }
      if (actionCreator === 'tooltip') {
        setState((prevState) => ({
          ...prevState,
          showTooltip: false,
          event: eventObject,
          tooltipData: {},
        }));
      }
    }
  };

  const renderCustomizedLabel = ({ midAngle, x, y, skillLabel, numberOfGoals } : any) => {
    const RADIAN = Math.PI / 180;
    const cos = Math.cos(-RADIAN * midAngle);
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        {tooltipType === ACTIVE_GOAL_TOOLTIP_TYPE && numberOfGoals > 0 && (
          <Text
            style={styles.labelText}
            x={x}
            y={y}
            dy={8}
            fill="black"
            width={100}
            textAnchor={textAnchor}
            dominantBaseline="middle"
          >
            {skillLabel}
          </Text>
        )}
      </g>
    );
  };

  return (
    <Box width={boxWidth} height={boxHeight} sx={styles.outlineStyles}>
      {/* Rendering custom tooltip, The rechart library doesn't provide a way to make tooltip data clickable. */}
      {showTooltip && !hideTooltip && (
        <div
          className="custom-tooltip-container"
          id="graph-tooltip"
          style={{
            zIndex: zIndex.zIndex1,
            position: 'fixed',
            left:
              event ? (event?.pageX < (bodyWidth - CUSTOM_TOOLTIP_WIDTH)
                ? event?.pageX + 5
                : event?.pageX - CUSTOM_TOOLTIP_WIDTH) : 0,
            top:
              event ? (event?.pageY < bodyHeight - CUSTOM_TOOLTIP_HEIGHT
                ? event?.pageY - 50
                : event?.pageY - CUSTOM_TOOLTIP_HEIGHT / 2) : 0,
          }}
          onMouseEnter={() => {
            handleHover({}, true, event, 'tooltip');
          }}
          onMouseLeave={() => {
            handleHover({}, false, null, 'tooltip');
          }}
        >
          <CustomTooltip
            data={tooltipData}
            onLinkClick={onLinkClick}
            tooltipType={tooltipType}
          />
        </div>
      )}
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={chartWidth} height={chartHeight}>
          <Pie
            data={data}
            cx={chartWidth / 2}
            cy={chartHeight / 2}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            style={{ outline: 'none' }}
            fill={palette.customWhite.main}
            dataKey={dataKey}
            cornerRadius={4}
            onMouseEnter={(item: any, index: number, e: any) => {
              if (hideTooltip) {
                return;
              }
              handleHover(
                item,
                true,
                { pageX: e?.pageX, pageY: e?.pageY },
                'cell',
              );
            }}
            onMouseLeave={() => {
              if (hideTooltip) {
                return;
              }
              handleHover({}, false, null, 'cell');
            }}
            labelLine={label}
            label={renderCustomizedLabel}
          >
            <Label content={<DonutLabel Icon={Icon} angle={angle} />} />
            {data?.map((item, index) => <Cell key={index} style={{ outline: 'none' }} fill={item.color} />)}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DonutScoreChart;
