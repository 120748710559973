import { useEffect, useState } from 'react';
import {
  BenchmarkStudentGrowthReportResult,
  useBenchmarkStudentGrowthReportQuery,
  useGetAcademicSessionQuery,
} from '../../../../generated/graphql';
import { useParams } from 'react-router-dom';
import { fromUTCEOD, fromUTCSOD } from '../../../../utils/dateFormat';
import { StudentBenchmarkDashBoard } from '../../../../variables/constant';
import useScoreSchemeDomain from '../../scorescheme-domain-hook';
import { useIndividualStudentBenchmarksDashboardContext } from '../individual-student-dashboard-hook';
import { DashboardWidgetsDataStatusProps } from '../../../AdminAnalytics/admin-analytics-hook';
import { NotifierType } from '../../../../variables/types';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { openSnackbar } from '../../../../components/Notifier';

type RouteParms = {
  id?: string;
};
type GrowthReportBenchmarkData = BenchmarkStudentGrowthReportResult & {
  timestamp: any;
};

const useStudentGrowthReportWidget = () => {

  const { id: studentId } = useParams<RouteParms>();
  const [growthReportData, setGrowthReportData] = useState<any[]>();
  const [sessionStartDate, setSessionStartDate] = useState<Date>();
  const [sessionEndDate, setSessionEndDate] = useState<Date>();
  const [hasData, setHasData] = useState<boolean>(false);
  const { updateDashboardWidgetsDataStatus } = useIndividualStudentBenchmarksDashboardContext();
  const {
    data: studentGrowthReportData,
    loading: studentGrowthReportLoading,
    error: studentGrowthReportError,
  } = useBenchmarkStudentGrowthReportQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          student_user_id: studentId,
        },
      },
    });

  // YTD
  const { data: academicSessionData, loading: academicSessionLoading } = useGetAcademicSessionQuery({
    fetchPolicy: 'network-only',
  });

  if (studentGrowthReportError) {
    openSnackbar({ message: getErrorMessage(studentGrowthReportError) }, NotifierType.Error);
  }

  useEffect(() => {
    if (academicSessionData) {
      academicSessionData?.getAcademicSession?.session_end_date;
      const startDate = fromUTCSOD(academicSessionData?.getAcademicSession?.session_start_date!);
      const endDate = fromUTCEOD(academicSessionData?.getAcademicSession?.session_end_date!);
      setSessionStartDate(startDate);
      setSessionEndDate(endDate);
    }
  }, [academicSessionData]);
  useEffect(() => {
    if (!studentGrowthReportLoading) {

      const reportData: any[] = [];
      studentGrowthReportData?.benchmarkStudentGrowthReport?.map((item: BenchmarkStudentGrowthReportResult) => {
        reportData.push({ ...item, timestamp: new Date(item?.submission_submitted_at_timestamp).getTime() });
      });
      setGrowthReportData(reportData);

    }
  }, [studentGrowthReportData, studentGrowthReportLoading, studentId]);
  const { yAxisDomain, yAxisTicks, scoreScheme } = useScoreSchemeDomain();

  useEffect(() => {
    if (!studentGrowthReportLoading && growthReportData) {
      setHasData(growthReportData?.length > 0);
      const hasChartData = (growthReportData?.length) > 0;
      const dashboardWidgetStatus: DashboardWidgetsDataStatusProps = {
        widget: StudentBenchmarkDashBoard.StudentGrowthReportWidget.name,
        loading: studentGrowthReportLoading,
        dataMissing: !hasChartData,
      };
      updateDashboardWidgetsDataStatus?.({ ...dashboardWidgetStatus });
      setHasData(hasChartData);
    }
  }, [growthReportData, studentGrowthReportLoading]);

  return {
    studentGrowthReportData,
    studentGrowthReportLoading,
    studentGrowthReportError,
    growthReportData,
    yAxisDomain, yAxisTicks,
    academicSessionData,
    academicSessionLoading,
    sessionStartDate,
    sessionEndDate,
    hasData,

  };
};


export default useStudentGrowthReportWidget;