import React from 'react';
import {
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BenchmarkAssignedToNode } from '../../../../generated/graphql';
import { DistrictWideComparisonsStylesCSS } from '../../../AdminAnalytics/DistrictWideComparisons/DistrictWideComparisons.styled';
import { StyledTableDataColumn } from '../../../Admin/admin-tables-styled';

interface Props {
  rowIndex: number,
  rowData: BenchmarkAssignedToNode | null,
}

const StudentCountRow = ({
  rowIndex,
  rowData,
}: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);


  return (
    <TableRow key={rowIndex} sx={{
      ...styles.tableRowHover, ...styles.tableRowBorder,
    }}>
      <TableCell sx={{ ...styles.width35, textAlign: 'left' }}>
        <Typography
          variant='secondaryLabel'
        >
          {rowData?.district_name}
        </Typography>
      </TableCell>
      <StyledTableDataColumn sx={styles.width10}>
        <Typography
          variant='secondaryLabel'
        >
          {rowData?.grade_name ?? '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width35}>
        <Typography
          variant='secondaryLabel'
        >
          {rowData?.school_name ?? '-'}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width10}>
        <Typography
          variant='secondaryLabel'
        >
          {rowData?.student_count}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default StudentCountRow;
