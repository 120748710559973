import React from 'react';
import SchoolFilterWithMultiSelect from '../../../../components/SchoolFilterWithMultiSelect';
import DistrictMultiSelectFilter from '../../../../components/DistrictMultiSelectFilter';
import { Button, Grid } from '@mui/material';
import { StyledFilterButton } from '../../../AdminAnalytics/AnalyticsFilters/AnalyticsFilters.styled';
import SyncIcon from '@mui/icons-material/Sync';
import GradeStatusFilter from '../../../../components/GradeStatusFilter';
import BenchmarkMultiTypeSelectFilter from '../../../../components/BenchmarkMultiTypeSelectFilter';
import MultiStateSelectFilter from '../../../../components/StateFilter/MultiSelectStateFilter';
import GradedByFilter from '../../../../components/GradedByFilter';
import { BenchmarkGradedBy, SubmissionStatus } from '../../../../generated/graphql';
import { GradedByOptions } from '../../Create/Steps/DetailsStep';
import PredefinedGradeMultiSelect from '../../../../components/PredefinedGradeMultiSelect';

export const SubmissionOptions  = [
  {
    label: 'All Submissions',
    value: 'all',
  },
  {
    label: 'Graded',
    value: SubmissionStatus.Reviewed,
  },
  {
    label: 'Not Yet Graded',
    value: SubmissionStatus.Submitted,
  },
];

const GradeFilters = ({
  benchmarkTypes, setBenchmarkTypes,
  selectedStatus, setSelectedStatus,
  selectedStates, setSelectedStates,
  selectedDistricts, setSelectedDistricts,
  selectedSchools, setSelectedSchools,
  selectedGradedBy, setSelectedGradedBy,
  handleApplyFilters, handleClearFilters,
  selectedGrades, setSelectedGrades,
}: any) =>{

  const handleStateChange = (values:any[] | undefined) => {
    if (values?.length) setSelectedStates(values);
    else setSelectedStates(undefined);
    setSelectedDistricts(undefined);
    setSelectedSchools(undefined);
  };

  const handleDistrictChange = (values:any[] | undefined) => {
    if (values?.length) setSelectedDistricts(values);
    else setSelectedDistricts(undefined);
    setSelectedSchools(undefined);
  };

  const gradedByOptions = [...GradedByOptions, {
    label: 'Graded By All',
    value: 'all',
  }] as any[];

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <MultiStateSelectFilter
            onChange={handleStateChange}
            value={selectedStates}
            limitTags={1}
            textInputPlaceholder='All States'/>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.6}>
          <DistrictMultiSelectFilter
            stateIds={selectedStates as [string] | undefined}
            onChange={handleDistrictChange}
            value={selectedDistricts}
            limitTags={1}
            textInputPlaceholder='All Districts'/>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <SchoolFilterWithMultiSelect
            stateIds={selectedStates}
            districts={selectedDistricts}
            onChange={(values:any[]) => {
              (values?.length) ? setSelectedSchools(values) : setSelectedSchools(undefined);
            }}
            value={selectedSchools}
            limitTags={1}
            textInputPlaceholder="All Schools"/>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <PredefinedGradeMultiSelect
            states={selectedStates}
            districts={selectedDistricts}
            schools={selectedSchools}
            onChange={(values:any[]) => setSelectedGrades(values)}
            value={selectedGrades}
            textInputPlaceholder="All Grades"/>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.2}>
          <GradedByFilter
            onChange={(value: BenchmarkGradedBy | undefined) => !!value ? setSelectedGradedBy(value) : setSelectedGradedBy(undefined)}
            gradedBy={selectedGradedBy}
            options={gradedByOptions as any[]}
            textInputPlaceholder='Graded By'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <GradeStatusFilter
            onChange={(value: any) => !!value ? setSelectedStatus(value) : setSelectedStatus(undefined)}
            status={selectedStatus}
            options={SubmissionOptions}
            textInputPlaceholder='All Submissions'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <BenchmarkMultiTypeSelectFilter
            onChange={(values:any) => (values?.length) ? setBenchmarkTypes(values) : setBenchmarkTypes([])}
            value={benchmarkTypes as (string | undefined)[]}
            limitTags={1}
            textInputPlaceholder='All Benchmark Types'
          />
        </Grid>
        <Grid item>
          <StyledFilterButton
            variant="contained"
            color='inherit'
            startIcon={<SyncIcon color="primary" fontSize="medium" />}
            onClick={handleClearFilters}
          >
            Clear all filters
          </StyledFilterButton>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
          >
            Apply filters
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export  default GradeFilters;


