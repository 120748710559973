import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
  Theme, useTheme,
} from '@mui/material';
import { StyledTableContainer } from './Student.styled';
import StyledCard from '../../../components/StyledCard';
import GoalRow from './GoalRow';
import { palette  } from '../../../theme/palette';
import { StudentGoalListResult } from '../../../generated/graphql';

interface Props {
  stickyHeader?: boolean;
  activeGoal?: StudentGoalListResult | null;
  loading: boolean;
  title: string;
}

const StylesCSS = (theme: Theme) => ({
  tableCellStylesGoals: {
    padding: '12px 32px',
    width: '60%',
    textAlign: 'left',
  },
  tableCellStyles: {
    padding: '12px 32px',
    width: '20%',
    textAlign: 'center',
  },
  titleStyles: {
    '&.MuiTableContainer-root': {
      marginTop: theme.spacing(1.5),
      height: 'auto',
    },
  },
  tableCellStylesLeft: {
    textAlign: 'left',
  },
  noRecord: {
    fontWeight: 800,
    fontSize: 20,
  },
  customBackground: {
    '&.MuiTableCell-root': {
      background: theme.palette.primary.main,
      color: theme.palette.customWhite.main,
    },
  },
});

const currentGoalStyles = {
  customBackground: {
    '&.MuiTableCell-root': {
      background:palette.customBlue.primaryBlue,
      color: palette.customWhite.main,
    },
  },
  tableCellStylesGoals: {
    padding: '12px 32px',
    width: '60%',
    textAlign: 'left',
  },
  tableCellStyles: {
    padding: '12px 32px',
    width: '20%',
    textAlign: 'center',
  },
};

const CurrentGoals = ({
  stickyHeader = true,
  activeGoal,
  loading,
  title,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box mt={3}>
        <StyledCard title={title} titleAlignment="left" contentWrapperMinHeightAuto>
          <StyledTableContainer sx={styles.titleStyles}>
            <Table
              /*
          *  Due to 'stickyHeader' prop, backgroundColor on TableHead or TableRow
          *  was not working. So added global styles under theme.ts file for
          *  MuiTableCell & MuiTableHead
          */
              stickyHeader={stickyHeader}
              aria-label={title}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    ...styles.tableCellStyles, ...styles.tableCellStylesLeft, ...styles.customBackground,
                  }}
                  >
                    Type
                  </TableCell>
                  <TableCell sx={{ ...currentGoalStyles.tableCellStylesGoals, ...currentGoalStyles.customBackground }}>
                    Goal
                  </TableCell>
                  <TableCell sx={{ ...currentGoalStyles.tableCellStyles, ...currentGoalStyles.customBackground }}>
                    Assigned
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && activeGoal
                && (activeGoal?.speaking?.length > 0 || activeGoal?.writing?.length > 0)
                && <GoalRow data={activeGoal} hideStatus />}
                {!loading
                && (!activeGoal || (activeGoal?.speaking?.length === 0 && activeGoal?.writing?.length === 0)) && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                    >
                      <Typography variant='sTitle'>
                        No Current Goals Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow sx={styles.noRecord}>
                    <TableCell
                      colSpan={6}
                      align="center"
                    >
                      <CircularProgress size={28} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </StyledCard>
      </Box>
    </Box>
  );
};
export default CurrentGoals;
