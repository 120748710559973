import React from 'react';
import { Box, Button } from '@mui/material';
import StyledCard from '../../../../components/StyledCard';
import AppTooltip from '../../../../components/AppTooltip';
import { ACTIVE_GOAL_TOOLTIP_TYPE, TeacherBenchmarkDashBoard } from '../../../../variables/constant';
import { BenchmarkType } from '../../../../generated/graphql';
import useBenchmarkSubmissionStatus from './benchmark-submission-status-hook';
import OverallPerformanceDonutChart from '../../../../components/Charts/Graph/OverallPerformanceDonutChart';
import NoResults from '../../../../components/NoResults';
import EmptyBenchmarkResults from '../../../../components/Icons/EmptyBenchmarksResults';
import DonutChartLoader from '../../../../components/Loader/DonutChartLoader';
import FlyoverDrawer from '../../../../components/FlyoverDrawer';
import FlyoverHeader from '../../../../components/FlyoverHeader';
import FlyoverContainer from '../../../../components/FlyoverContainer';
import SubmissionSlideOut from './SubmissionSlideOut';

interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  benchmarkType?: BenchmarkType,
}
const SubmissionStatus = ({
  alignTitle,
  enableTooltip,
  tooltipTitle,
  benchmarkType,
}: Props) => {

  const {
    submissionPieChartData,
    benchmarkSubmissionLoading,
    hasData,
    totalSubmissions,
    flyOverData,
    showFlyover,
    toggleFlyover,
    totalSubmissionsCountByOption,
    setTotalSubmissionsCountByOption,
  } = useBenchmarkSubmissionStatus({ selectedBenchmarkType: benchmarkType });
  const title = 'Submission Status';
  return (
    <>
      <Box id={TeacherBenchmarkDashBoard.BenchmarkSummary.SubmissionStatusWidget.name} sx={{ height: '100%', width: '100%' }}>
        <StyledCard
          title={title}
          columnSetting
          isToolTip={enableTooltip}
          titleAlignment={alignTitle ? 'left' : 'center'}
          toolTipComponent={<AppTooltip toolTipText={tooltipTitle} />}
          fullHeight
          marginBottom='0px'
      
        >
          { !benchmarkSubmissionLoading && hasData && submissionPieChartData?.length > 0 && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Box>
                <OverallPerformanceDonutChart
                  data={submissionPieChartData!}
                  startAngle={90}
                  endAngle={-270}
                  boxWidth={410}
                  chartWidth={400}
                  chartHeight={350}
                  boxHeight={350}
                  dataKey="studentCount"
                  tooltipType={ACTIVE_GOAL_TOOLTIP_TYPE}
                  totalCount={totalSubmissions}
                  label
                  innerLabel='Total Submissions'
                  paddingAngle={1.5}
                />
              </Box>
              <Box  sx={{ mb: 3, mt: 1.5 }} >
                <Button
                  id="see-submission-list-button"
                  variant="outlined"
                  color="primary"
                  onClick={toggleFlyover}
                  sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 2, paddingBottom: 2 }}
                >
                  See Submission List
                </Button>
              </Box>
            </Box>
          )}
          <FlyoverDrawer open={showFlyover} size={'small'} onClose={toggleFlyover}>
            <FlyoverHeader
              title={'Submission List'}
              onClose={toggleFlyover}
              subtitle={`${totalSubmissionsCountByOption} Submissions`}
            />
            <FlyoverContainer>
              <SubmissionSlideOut 
                data={flyOverData}
                benchmarkType={benchmarkType} 
                setTotalSubmissionsCountByOption={setTotalSubmissionsCountByOption}/>
            </FlyoverContainer>
          </FlyoverDrawer>
          {!benchmarkSubmissionLoading && !hasData && (
            <NoResults
              description={TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.insufficientData.description}
              svgComponent={<EmptyBenchmarkResults />}
            />
          )}
          {benchmarkSubmissionLoading && <DonutChartLoader />}
        </StyledCard>
      </Box>
    </>
  );
};

export default SubmissionStatus;
