import React from 'react';
import { Box, Typography } from '@mui/material';
import GradesTable from './GradesTable';
import GradeFilters from './GradeFilters';
import Layout from '../../../components/Layout';
import useBenchmarkGradesList from './grades-list-hook';

const Grade = () => {
  const {
    benchmarkTypes, setBenchmarkTypes,
    selectedStatus, setSelectedStatus,
    selectedStates, setSelectedStates,
    selectedDistricts, setSelectedDistricts,
    selectedSchools, setSelectedSchools,
    handleApplyFilters, handleClearFilters,
    pagination, totalCount, order, sort,
    onSortChange, handleRedirection,
    gradeBenchmarks, loading,
    handlePagination,
    submissionQueueData,
    selectedGradedBy, setSelectedGradedBy,
    selectedGrades, setSelectedGrades,
    rowsPerPage, setRowsPerPage,
  } = useBenchmarkGradesList();

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Typography variant="pageTitle">
         Grade
        </Typography>
        <GradeFilters
          benchmarkTypes={benchmarkTypes}
          setBenchmarkTypes={setBenchmarkTypes}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          selectedStates={selectedStates}
          setSelectedStates={setSelectedStates}
          selectedDistricts={selectedDistricts}
          setSelectedDistricts={setSelectedDistricts}
          selectedSchools={selectedSchools}
          setSelectedSchools={setSelectedSchools}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
          selectedGradedBy={selectedGradedBy}
          setSelectedGradedBy={setSelectedGradedBy}
          selectedGrades={selectedGrades}
          setSelectedGrades={setSelectedGrades}
        />
        <Box display="flex" mt={2}>
          <GradesTable
            pagination={pagination}
            handlePagination={handlePagination}
            handleRedirection={handleRedirection}
            loading={loading}
            onSortChange={onSortChange}
            order={order}
            sort={sort}
            totalCount={totalCount}
            gradeBenchmarks={gradeBenchmarks}
            submissionQueueData={submissionQueueData}
            page={pagination.page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Grade;
