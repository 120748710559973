import React, { useMemo } from 'react';
import PronunciationIcon from '../../../components/Icons/PronunciationIcon';
import { Skills } from '../../../variables/types';
import WritingAnchorIcon from '../../../components/Icons/WritingAnchorIcon';

export interface SpeakingWritingCard {
  title: string,
  Icon: React.ComponentType<any>,
  buttonTitle: string,
  redirectionLink: string,
}

const useSpeakingWritingAnchors = () => {
  const speakingWritingAnchorsList = useMemo(() => [
    {
      title: 'Speaking Anchors',
      Icon: PronunciationIcon,
      buttonTitle: 'VIEW',
      redirectionLink: `/toolbox/speaking-writing-anchors/${Skills.Speaking}`,
    },
    {
      title: 'Writing Anchors',
      Icon: WritingAnchorIcon,
      buttonTitle: 'VIEW',
      redirectionLink: `/toolbox/speaking-writing-anchors/${Skills.Writing}`,
    },
  ], []);

  return ({
    speakingWritingAnchorsList,
  });
};

export default useSpeakingWritingAnchors;
