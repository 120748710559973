import React from 'react';

interface Props {
  selectedStudents?: string[],
  setSelectedStudents?: Function,
}

const useManageStudentsTable = ({
  selectedStudents, setSelectedStudents,
}: Props) => {

  const selectStudent = (studentId: string) => {
    setSelectedStudents?.((prevArray: any) => [...prevArray, studentId]);
  };

  const unSelectStudent = (studentId: string) => {
    setSelectedStudents?.((prevArray: any) =>
      prevArray.filter((element: any) => element !== studentId),
    );
  };

  const studentSelectToggle = (event: React.ChangeEvent<HTMLInputElement>, studentId: string) => {
    if (event.target.checked) {
      selectStudent(studentId);
    } else {
      unSelectStudent(studentId);
    }
  };

  const isStudentSelected = (studentId: string) => selectedStudents?.includes(studentId) ?? false;

  return {
    studentSelectToggle, isStudentSelected,
  };
};

export default useManageStudentsTable;
