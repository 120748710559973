import { Box } from '@mui/material';
import React from 'react';
import { Legend } from 'recharts';

type Props = {
  data: any[]
  id?: string,
};

const CustomLegend = ({ data, id = 'legend-dashboard' }: Props) => {
  return (
    <Box id={id} sx={{ paddingTop: '50px' }}>
      <Legend
        formatter={(value) => (
          <span style={{ color: 'black', marginRight: '24px', marginLeft: '10px' }}>{value}</span>
        )}
        align='left'

        wrapperStyle={{
          fontSize: '16px', fontWeight: 600, paddingLeft: '26px',
        }}
        payload={data?.map((item: any) => ({
          value: item?.skillTitle,
          type: 'circle',
          color: item?.color,
        }))
        }
      />
    </Box>);
};
export default CustomLegend;