import React from 'react';
import { Box, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Layout from '../../components/Layout';
import { TeacherBenchmarksDashboardContext } from './teacherbenchmarks-dashboard-context';
import useTeacherBenchmarksDashboard, { TeacherBenchmarkDashboardContextInit } from './teacherbenchmarks-dashboard-hook';
import { TeacherBenchmarkDashboardStylesCSS } from './teacherbenchmarkdashboard.styled';
import MultiSelectTeacherSchoolFilter from '../../components/MultiSelectTeacherSchoolFilter';
import { Redirect, Route, Switch } from 'react-router-dom';
import RoutingPaths from '../RoutingPath';
import { TeacherBenchmarksDashboardTabs } from '../../variables/types';
import BenchmarkSummary from './BenchmarkSummary';
import ProgressScores from './ProgressScores';
import useRedirect from '../../hooks/useRedirect';
import MultiSelectTeacherGradeFilter from '../../components/MultiSelectTeacherGradeFilter';
import MultiSelectTeacherSectionFilter from '../../components/MultiSelectTeacherSectionFilter ';
import zIndex from '@mui/material/styles/zIndex';
import ExportToPdf from './pdfExport';
import { TBDPageRevisitCount, TeacherBenchmarkDashBoard as TeacherBenchmarkDashBoards } from '../../variables/constant';
import Loader from '../../components/Loader/loader';
import { toReadableFormat } from '../../utils/dateFormat';
import TypeformEmbed from '../../components/TypeformEmbed';
import { useImitationProvider } from '../../core/imitationContext';


const TeacherBenchmarkDashboard = () => {

  const theme = useTheme();
  const { navigateTo } = useRedirect();
  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const {
    loading,
    setLoading,
    areWidgetsLoading,
    setAreWidgetsLoading,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    localTeacherBenchmarkDashboardFilters,
    getLocalTeacherBenchmarkDashboardFilters,
    setLocalTeacherBenchmarkDashboardFilters,
    teacherDashboardFilters, selectedClasses, setSelectedClasses, selectedSchools, setSelectedSchools, selectedGrades, setSelectedGrades,
    onSchoolChange, onClassChange, onGradeChange, benchmarkType, setBenchmarkType,
  } = TeacherBenchmarkDashboardContextInit({ dashboardWidgets: TeacherBenchmarkDashBoards.BenchmarkSummary });
  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();
  const additionalProps = { selectedClasses, setSelectedClasses, selectedSchools, setSelectedSchools, selectedGrades, setSelectedGrades };
  const {
    schoolsData,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
    onTypeformReady,
    typeformData,
    onTypeformSubmit,
    userId,
    isTypeformFilterSelected,
    checkFilterSelected,
    teacherBenchmarkLoading,
    isProgressData,
    isProgressScoresLoading,
    setIsProgressData,
    setIsProgressScoresLoading,
    progressScoreDataCount,
    progressScoreQueryInput,
    setProgressScoreQueryInput,
    setProgressScoreDataCount,
    legendDataOverallPerformanceWidget,
    setLegendDataOverallPerformanceWidget,
    grades,
  } = useTeacherBenchmarksDashboard({ selectedClasses, selectedGrades, selectedSchools, setLocalTeacherBenchmarkDashboardFilters });

  const currentPath = window.location.pathname;
  const currentTab = currentPath?.includes('progress-scores') ? 1 : 0;
  const [tab, setTab] = React.useState(currentTab);
  if (currentTab !== tab) {
    setTab(currentTab);
  }
  const isFilter = selectedClasses?.length! > 0 || selectedGrades?.length! > 0 || selectedSchools?.length! > 0;
  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    switch (newValue) {
      case 0:
        navigateTo(`${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.BenchmarkSummary}`);
        return;
      case 1:
        navigateTo(`${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.ProgressScores}`);
        return;
      default:
        navigateTo(`${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.BenchmarkSummary}`);
    }
  };
  const tbdTypeformSurvey = localStorage?.getItem('TBD-typeform-survey') || 0;
  const dismissTypeform = localStorage?.getItem('Dismiss-TBD-typeform-survey');

  return (
    <TeacherBenchmarksDashboardContext.Provider value={{
      selectedGrades,
      selectedClasses,
      selectedSchools,
      setSelectedClasses,
      setSelectedGrades,
      setSelectedSchools,
      loading, setLoading,
      areWidgetsLoading, setAreWidgetsLoading,
      dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
      isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
      benchmarkType, setBenchmarkType, lastSnowflakeUpdateTimeData, getLastSnowflakeUpdateTimeLoading,
      isProgressData,
      isProgressScoresLoading,
      setIsProgressData,
      setIsProgressScoresLoading,
      progressScoreDataCount,
      progressScoreQueryInput,
      setProgressScoreQueryInput,
      setProgressScoreDataCount,
      legendDataOverallPerformanceWidget,
      setLegendDataOverallPerformanceWidget,
      localTeacherBenchmarkDashboardFilters,
      getLocalTeacherBenchmarkDashboardFilters,
      setLocalTeacherBenchmarkDashboardFilters,
    }} >
      <Layout padding={0}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          sx={{ ...styles.dashBoardContainer, padding: 0, zIndex: 1 }}
          id="teacher-benchmarks-dashboard"


        >
          <Loader open={loading || getLastSnowflakeUpdateTimeLoading} />
          <Box sx={styles.dashboard as SxProps} pl={1}  >
            <Box
              display="flex"
              justifyContent="space-between"
              paddingLeft={4}
              paddingRight={4}
            >
              <Box>
                <Typography variant="pageTitle"  >{tab === 0 ? 'Benchmark Summary' : 'Benchmark Progress Scores'}</Typography>
                {!!lastSnowflakeUpdateTimeData && !getLastSnowflakeUpdateTimeLoading && <Box marginTop={-0.5} >
                  <Typography variant="toolTipGreyText">
                    Last Updated: {toReadableFormat(lastSnowflakeUpdateTimeData, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      timeZone: 'UTC',
                  }, 'en-US').replace('at', '')} UTC
                  </Typography>
                </Box>}
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="repeat(3, 270px)"
                sx={{
                  gridGap: 4,
                  height: '48px',
                  maxHeight: '48px',
                }}
              >
                <Box>
                  <MultiSelectTeacherSchoolFilter
                    schools={schoolsData}
                    value={selectedSchools}
                    onChange={onSchoolChange}
                    placeholder="All Schools"
                    isStartAdornment={false}
                    limitTags={1}
                    zIndexPopper={1000}
                  />
                </Box>
                <Box>
                  <MultiSelectTeacherGradeFilter
                    grades={grades as any}
                    value={selectedGrades}
                    onChange={onGradeChange}
                    placeholder="All Grades"
                    isStartAdornment={false}
                    limitTags={1}
                    zIndexPopper={1000}
                  />
                </Box>
                <Box>
                  <MultiSelectTeacherSectionFilter
                    value={selectedClasses}
                    onChange={onClassChange}
                    placeholder="All Classes"
                    isStartAdornment={false}
                    schoolId={selectedSchools || ''}
                    limitTags={1}
                    zIndexPopper={1000}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              flexDirection="row"
              width="100%"
              pt={theme.spacing(3)}
              pl={4}
              pr={4}
            >
              <Tabs
                value={tab}
                onChange={handleChange}
                textColor="primary"
                scrollButtons={undefined}

              >
                <Tab
                  id='benchmark-summary-tab'
                  label="Benchmark Summary"
                  disableRipple
                  sx={styles.tabStyle}
                  disabled={!isFilter}
                />
                <Tab
                  id='progress-scores-tab'
                  label="Progress Scores"
                  disableRipple
                  sx={styles.tabStyle}
                  disabled={!isFilter}
                />
              </Tabs>

              <ExportToPdf tabKey={tab} />

            </Box>
          </Box>
          <Box display="flex" mt={1} sx={{ zIndex: zIndex.drawer - 2 }}>
            <Switch>
              <Route
                path={`${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.BenchmarkSummary}`}
                component={BenchmarkSummary}
                exact
              />
              <Route
                path={`${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.ProgressScores}`}
                component={ProgressScores}
                exact
              />
              <Redirect to={`${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.BenchmarkSummary}`} />
            </Switch>
          </Box>
          { isNotImitated &&
              !dismissTypeform &&
              tbdTypeformSurvey >= TBDPageRevisitCount  &&
              !teacherBenchmarkLoading &&
              !areWidgetsLoading &&
              !isDataMissingInAllWidgets &&
              typeformData?.show_typeform &&
              setTimeout(() => checkFilterSelected(), 15000) &&
              isTypeformFilterSelected && (
            <TypeformEmbed
              formId={typeformData?.typeform_form_id}
              onFormSubmittedHandler={onTypeformSubmit}
              onCloseHandler={onTypeformReady}
              typeformLayout="popover"
              hiddenFields={{ user_id: userId }}
              hiddenButton={true}
            />)
          }
        </Box>
      </Layout>
    </TeacherBenchmarksDashboardContext.Provider>);
};

export default TeacherBenchmarkDashboard;
