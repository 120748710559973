import { palette  } from '../palette';

const Button = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        fontSize: '1rem',
        margin: '0',
        overflow: 'visible',
        outline: 'none',
        fontWeight: 700,
        letterSpacing: '.8px',
        borderStyle: 'solid',
        transform: 'translateZ(0)',
        transition: 'filter .2s',
        borderRadius: '16px',
        verticalAlign: 'middle',
        lineHeight: '20px',
        borderColor: 'transparent',
        borderTopLeftRadius: '18px',
        borderTopRightRadius: '18px',
        padding: '13px 16px',
        borderWidth: '0 0 4px',
        boxShadow: 'none',
        '&::after': {
          borderColor: 'transparent',
          bottom: '-4px',
          content: '" "',
          left: 0,
          right: 0,
          top: 0,
          borderWidth: '0 0 4px',
          borderRadius: '16px',
          backgroundClip: 'padding-box',
          borderStyle: 'solid',
          position: 'absolute',
          zIndex: -1,
          boxShadow: 'none',
        },
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: 'none',
          borderRadius: '16px',
          borderWidth: '4px 0 0',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: 'none',
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: `${palette.customBackground.disabled}`,
          color: palette.customBlack.lightBlack,
          '&::after': {
            backgroundColor: `${palette.customBackground.disabled}`,
            color: palette.customBlack.lightBlack,
          },
        },
      },
      containedPrimary: {
        backgroundColor: palette.customBlue.primaryDark,
        color: palette.customWhite.main,
        '&::after': {
          backgroundColor:palette.customBlue.primaryBlue,
        },
        '&:active': {
          backgroundColor: 'transparent',
        },
      },
      containedSecondary: {
        backgroundColor: palette.customYellow.secondaryDark,
        color: palette.customWhite.main,
        '&::after': {
          backgroundColor: palette.customYellow.main,
        },
        '&:active': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: palette.customYellow.secondaryDark,
        },
      },
      outlined: {
        borderRadius: '16px',
        cursor: 'pointer',
        touchAction: 'manipulation',
        borderColor: 'transparent',
        padding: '12px 16px',
        borderWidth: '2px 2px 4px',
        background: 'none',
        color:palette.customBlue.primaryBlue,
        '&::after': {
          backgroundColor: palette.customWhite.main,
          borderColor: palette.customBackground.disabled,
          left: '-2px',
          right: '-2px',
          top: '-2px',
          borderWidth: '2px 2px 4px',
          borderRadius: '16px',
          backgroundClip: 'padding-box',
        },
        '&:hover': {
          borderColor: 'transparent',
          borderWidth: '2px 2px 4px',
          borderStyle: 'solid',
        },
        '&:active': {
          backgroundColor: 'none',
          boxShadow: 'none',
          borderColor: 'transparent',
          borderWidth: '4px 2px 2px',
          '&::after': {
            borderBottomWidth: '2px',
            bottom: '-2px',
          },
        },
      },
      outlinedPrimary: {
        borderWidth: '0 0 4px',
        backgroundColor: palette.customBlue.primaryBlue,
        color: palette.customBlue.primaryBlue,
        '&:active': {
          backgroundColor: 'transparent',
          borderWidth: '4px 2px 2px',
        },
        '&:hover': {
          border: 'none',
          borderColor: 'transparent',
          borderWidth: '2px 2px 4px',
          borderStyle: 'solid',
        },
        '&::after': {
          borderColor: palette.customBlue.primaryBlue,
        },
        '&.Mui-disabled': {
          '&::after': {
            borderColor: palette.customBackground.disabled,
          },
        },
      },
      outlinedSecondary: {
        borderWidth: '2px 2px 4px',
        backgroundColor: palette.customYellow.main,
        color: palette.customYellow.main,
        '&:active': {
          backgroundColor: 'transparent',
          borderWidth: '4px 2px 2px',
        },
        '&:hover': {
          borderColor: 'transparent',
          borderWidth: '2px 2px 4px',
          borderStyle: 'solid',
        },
        '&::after': {
          borderColor: palette.customYellow.main,
        },
      },
    },
  },
};

export default Button;
