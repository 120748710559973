import {
  BenchmarkGradedBy,
  OrderBy,
  SectionCategory,
  SubmissionStatus,
  TeacherBenchmarkSubmissionListSortType,
  useAddTypeformUserMappingMutation,
  useBenchmarkGradesCountQuery,
  useGetBtsSideTabTypeformQuery,
  useSectionCountQuery,
  useSubmissionCountQuery,
  useTeacherBenchmarkGradesListQuery,
} from '../../generated/graphql';
import useRole from '../../hooks/useRole';
import { PAGE_SIZE } from '../../variables/constant';
import { openSnackbar } from '../Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType } from '../../variables/types';

const useSidebarHook = () => {
  const { isTeacher } = useRole();

  const { data, loading, refetch: sidenavPTSubmissionCountRefetch } = useSubmissionCountQuery({
    variables: {
      status: [SubmissionStatus.Submitted],
    },
    skip: !isTeacher,
    // pollInterval: 120000,
  });

  const { data: teacherBenchmarkGradesData, loading: teacherBenchmarksDataLoading, refetch: teacherBenchmarksDataRefetch } = useTeacherBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    skip: !isTeacher,
    variables: {
      grade_statuses: [SubmissionStatus.Submitted],
      limit: PAGE_SIZE,
      page: 1,
      sort: TeacherBenchmarkSubmissionListSortType.SubmittedAt,
      order_by: OrderBy.Desc,
      graded_by: [BenchmarkGradedBy.Teacher],
    },
  });

  const { data: adminBenchmarkCount, loading: adminBenchmarkSubmissionLoading, refetch: adminBenchmarkCountRefetch } = useBenchmarkGradesCountQuery({
    fetchPolicy: 'network-only',
    skip: isTeacher,
    variables: {
      gradeStatus: [SubmissionStatus.Submitted],
    },
    // pollInterval: 120000,
  });

  const { data: sectionCountData, loading: sectionLoading, refetch:sectionCountRefetch } = useSectionCountQuery({
    fetchPolicy: 'network-only',
    skip: !isTeacher,
    variables: {
      classTypes: [SectionCategory.CustomClass, SectionCategory.Class],
    },
  });

  const { data: typeformSideTabDetails, loading: typeformSideTabDetailsLoading } = useGetBtsSideTabTypeformQuery({
    fetchPolicy: 'network-only',
    variables: {},
  });

  const [addTypeformUserMapping, { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();

  // call mutation to add typeform user mapping
  const onTypeformReady = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformSideTabDetails?.getBTSSideTabTypeform?.id ?? '',
          submitted: false,
        },
      });
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const onTypeformSubmit = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformSideTabDetails?.getBTSSideTabTypeform?.id ?? '',
          submitted: false,
        },
      });
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    loading: loading || teacherBenchmarksDataLoading || adminBenchmarkSubmissionLoading || typeformSideTabDetailsLoading,
    submittedSubmissionCount: data?.submissionsCount ?? 0,
    sidenavPTSubmissionCountRefetch,
    sidenavBenchmarkGradeTileCount: isTeacher ? teacherBenchmarkGradesData?.teacherBenchmarkSubmissionList?.node_count ?? 0 : adminBenchmarkCount?.adminBenchmarkSubmissionsCount ?? 0,
    teacherBenchmarksDataRefetch,
    adminBenchmarkCountRefetch,
    sectionCount: sectionCountData?.sectionCount ?? 0,
    sectionLoading,
    sectionCountRefetch,
    typeformData: typeformSideTabDetails?.getBTSSideTabTypeform || null,
    onTypeformReady,
    onTypeformSubmit,
  };
};

export default useSidebarHook;
