/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const CheckmarkHollowIcon = (props: any) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15" fill={palette.customWhite.main} stroke={palette.customGreen.darkerGreen} strokeWidth="2" />
      <g clipPath="url(#clip0)"><path d="M21.6195 10.6069l-8.2201 8.2201-3.0204-3.0204c-.1464-.1464-.38385-.1464-.53032 0l-.88387.8839c-.14644.1465-.14644.3839 0 .5304l4.16939 4.1694c.1465.1464.3839.1464.5304 0l9.3691-9.3692c.1465-.1464.1465-.3839 0-.5303l-.8839-.8839c-.1464-.1464-.3838-.1464-.5303 0z" fill={palette.customGreen.darkerGreen} /></g>
      <defs><clipPath id="clip0"><path fill={palette.customWhite.main} transform="translate(7.99951 7.99963)" d="M0 0h16v16H0z" /></clipPath></defs>
    </svg>
  </SvgIcon>
);

export default CheckmarkHollowIcon;
