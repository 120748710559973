import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const GradeSelectionIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M10.8 2.3999C10.1373 2.3999 9.6 2.93716 9.6 3.5999C9.6 4.26264 10.1373 4.7999 10.8 4.7999H13.2C13.8627 4.7999 14.4 4.26264 14.4 3.5999C14.4 2.93716 13.8627 2.3999 13.2 2.3999H10.8Z" fill={palette.customBlue.primaryBlue}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.8 5.9999C4.8 4.67442 5.87452 3.5999 7.2 3.5999C7.2 5.58813 8.81178 7.1999 10.8 7.1999H13.2C15.1882 7.1999 16.8 5.58813 16.8 3.5999C18.1255 3.5999 19.2 4.67442 19.2 5.9999V19.1999C19.2 20.5254 18.1255 21.5999 16.8 21.5999H7.2C5.87452 21.5999 4.8 20.5254 4.8 19.1999V5.9999ZM16.4485 12.8484C16.9172 12.3798 16.9172 11.62 16.4485 11.1514C15.9799 10.6827 15.2201 10.6827 14.7515 11.1514L10.8 15.1028L9.24853 13.5514C8.7799 13.0827 8.0201 13.0827 7.55148 13.5514C7.08285 14.02 7.08285 14.7798 7.55148 15.2484L9.95148 17.6484C10.4201 18.1171 11.1799 18.1171 11.6485 17.6484L16.4485 12.8484Z" fill={palette.customBlue.primaryBlue}/>
  </SvgIcon>
);

export default GradeSelectionIcon;
