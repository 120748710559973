import React from 'react';
import { SchoolDetails } from '../../generated/graphql';
import useSchools from '../Schools/SchoolsTable/schools-hook';
import List from '../Schools/SchoolsTable/List';

interface Prop {
  districtID?: string

}
const SchoolList = ({ districtID }: Prop) => {
  const {
    schools,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    rowsPerPage,
    setRowsPerPage,
  } = useSchools({ districtID });
  return (
    <List
      marginTop={0}
      sxProps={{ borderTopLeftRadius: '0px' }}
      isTableEmpty={isTableEmpty}
      loading={loading}
      totalCount={totalCount}
      order={order}
      pagination={pagination}
      sort={sort}
      schools={schools as SchoolDetails[]}
      handlePageChange={handlePageChange}
      handleSort={handleSort}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};

export default SchoolList;