/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

const ArrowUpIcon = (props: any) => (

  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none" fontSize='smaller' >
    <g clip-path="url(#clip0_28194_23166)">
      <path d="M0.53676 5.02123L5.22586 0.509527C5.93194 -0.169841 7.07253 -0.169841 7.77861 0.509528L12.4677 5.02123C13.6083 6.11867 12.7936 8 11.1823 8L1.80408 8C0.192771 8 -0.603831 6.11867 0.53676 5.02123Z" fill="#4B5563" />
    </g>
  </SvgIcon>
);

export default ArrowUpIcon;
