import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import useDistricts from './districts-hook';
import List from './List';
import StateFilter from '../../../components/StateFilter';
import useRole from '../../../hooks/useRole';

const Districts = () => {
  const {
    districts,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    rowsPerPage,
    setRowsPerPage,
  } = useDistricts();

  const { isSuperAdmin } = useRole();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="pageTitle">
          Districts
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={`1fr repeat(${isSuperAdmin ? 1 : 0 }, 250px)`}
          sx={{ gridGap: 16 }}>
          {isSuperAdmin && (
            <Button
              variant="outlined"
              color="primary"
              disableRipple={true}
              component={Link}
              to="/admin/create/district"
            >
            Add District
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            disableRipple={true}
            component={Link}
            to="/syncDistricts"
          >
            Sync Districts
          </Button>
        </Box>
      </Box>
      <Box width="100%" mt={2} display="flex" justifyContent="space-between">
        <Box width="15%">
          <StateFilter
            value={selectedState}
            onChange={onStateChange}
            idSelectOnly
            placeholder="All states"
          />
        </Box>
      </Box>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading}
          totalCount={totalCount}
          order={order}
          pagination={pagination}
          sort={sort}
          districts={districts}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default React.memo(Districts);
