import React from 'react';
import { ACTIVE_GOAL_TOOLTIP_TYPE } from '../../../variables/constant';
import {
  StyledBox,
  StyledDescriptionTypography, StyledGoalDescriptionTypography,
  StyledGoalTitleTypography,
  StyledLink,
  StyledTitleTypography,
} from './tooltip-styled';

interface Props {
  data: any
  onLinkClick?: (data?: any) => void
  tooltipType?: string,
  styledLink?: boolean,
  tooltipLabelSuffix?: string,
}

const RevisedTooltip = ({ data, tooltipType, onLinkClick = () => { }, styledLink = true, tooltipLabelSuffix }: Props) => {

  const handleClick = () => {
    const number = tooltipType === ACTIVE_GOAL_TOOLTIP_TYPE ? data?.numberOfGoals : data?.numberOfStudents;
    if (number > 0) {
      onLinkClick(data);
    }
  };

  const toolTipContent = tooltipType !== ACTIVE_GOAL_TOOLTIP_TYPE ? (
    <>
      <StyledTitleTypography>
        {`Level ${data?.skill_level}: ${data?.skill_label} ${data?.skillType}`}
      </StyledTitleTypography>
      <StyledDescriptionTypography>
        {data?.skill_description}
      </StyledDescriptionTypography>
      {styledLink && <StyledLink
        color={data?.color ?? ''}
        numberOfStudents={data?.numberOfStudents ?? 0}
        numberOfGoals={data?.numberOfGoals ?? 0}
        onClick={handleClick}>
        {`${data?.numberOfStudents} ${data?.numberOfStudents > 1 ? `${data?.skillLinkText}s` : data?.skillLinkText}`}
        {' '}
        {data?.numberOfStudents > 0 && <span>&rarr;</span>}
      </StyledLink>}
    </>
  ) : (
    <>
      <StyledGoalTitleTypography>
        {data?.numberOfGoals}
      </StyledGoalTitleTypography>
      <StyledGoalDescriptionTypography>
        {`${data?.skillLabel} ${tooltipLabelSuffix}`}
      </StyledGoalDescriptionTypography>
      {styledLink && <StyledLink
        color={data?.color ?? ''}
        numberOfStudents={data?.numberOfStudents ?? 0}
        numberOfGoals={data?.numberOfGoals ?? 0}
        onClick={handleClick}>
        {`${data?.numberOfGoals > 1 ? `${data?.skillLinkText}` : data?.skillLinkText}`}
        {data?.skill_key && <span>&rarr;</span>}
      </StyledLink>}
    </>
  );

  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      width="100%"
      tooltipType={tooltipType || ''}
    >
      {toolTipContent}
    </StyledBox>
  );
};

export default RevisedTooltip;
