import { SectionCategory, useClassesFilterQuery } from '../../generated/graphql';

interface Props {
  stateId?: string
  districtId?: string
  schoolIds?: string[] | undefined | null
  teacherId?: string
  classTypes?: SectionCategory[]
}

const useGroupedClasses = ({ stateId, districtId, schoolIds, classTypes }: Props) => {
  const { data, loading } = useClassesFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(stateId ? { stateID: stateId } : {}),
      ...(districtId ? { districtID: districtId } : {}),
      ...(schoolIds?.length ? { schoolIDs: schoolIds } : {}),
      ...(classTypes ? { classTypes: classTypes } : {}),
    },
  });

  const sections = data?.sectionsFilterList?.sort(
    (secA, secB) => -secB.school_name.localeCompare(secA.school_name),
  ) || []; //sorted array for groups always

  return {
    classes: sections,
    loading,
  };
};

export default useGroupedClasses;
