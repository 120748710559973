import React, { useState } from 'react';
import {
  Box,
  Select,
  Input,
  MenuItem,
  FormControl,
} from '@mui/material';
import {
  StyledInputLabel,
  StyledListItemText, StyledListSubItemText,
  StyledMenuItem,
} from './AppFilter.styled';
import SelectDropdownIcon from '../Icons/SelectDropdownIcon';
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from '../../variables/constant';
import noop from '../../utils/noop';
import { SelectChangeEvent } from '@mui/material/Select';
import { palette } from '../../theme/palette';

interface Props {
  menuTitle?: string
  selectedItem?: string | null | number
  onChange: (event: SelectChangeEvent<any>) => void
  options: any[]
  keyAttribute: string | number
  labelAttribute: string | number,
  childrenAttribute?: string | number,
  hideMenuTitle?: boolean,
  onOpen?: (event: React.ChangeEvent<{}>) => void,
  onClose?: (event: React.ChangeEvent<{}>) => void,
  isDisabled?: boolean,
  menuValue?: string | null,
  defaultValue?: string | null,
  width?: string,
}

const styles = {
  selectDropdownIcon: {
    '& .MuiSelect-icon': {
      fontSize: '0.75rem !important',
      marginRight: '8px',
    },
  },
  inputInput: {
    '& div.MuiInputBase-input': {
      background: 'none',
      border: 0,
    },
  },
  inputLabelFocus: {
    color: palette.customBlack.fontBlack,
    '&.Mui-focused': {
      color: palette.customBlack.fontBlack,
    },
  },
  disabled: {
    background: palette.customWhite.whiteSmoke,
    borderRadius: '12px',
    '& .MuiInputBase-input': {
      background: palette.customWhite.whiteSmoke,
      border: 0,
      cursor: 'not-allowed',
    },
  },
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 'fit-content',
      transform: 'translateY(-2%)',
    },
  },
  MenuListProps: {
    disablePadding: true,
  },
  getContentAnchorEl: null,
};
const ListMenu = ({
  menuTitle,
  onChange,
  options,
  selectedItem,
  keyAttribute,
  labelAttribute,
  childrenAttribute,
  hideMenuTitle,
  onOpen,
  onClose,
  isDisabled,
  menuValue,
  defaultValue,
  width = '220px',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<unknown>('');

  const handleOnOpen = (event: React.ChangeEvent<{}>) => {
    setIsOpen(true);
    onOpen?.(event);
  };

  const handleOnClose = (event: React.ChangeEvent<{}>) => {
    setIsOpen(false);
    onClose?.(event);
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    setSelected(event);
    onChange?.(event);
  };

  const getSelectOptions = () => {
    let optionsArray: JSX.Element[] = [];
    options?.forEach((item) => {
      const listEntry = [(
        <MenuItem key={item[keyAttribute]} value={item[keyAttribute]}>
          <StyledListItemText primary={item[labelAttribute]} />
        </MenuItem>
      )];
      if (childrenAttribute) {
        const childList: any[] = item[childrenAttribute] ?? [];
        childList.forEach((subItem) => {
          listEntry.push(
            <MenuItem key={subItem[keyAttribute]} value={subItem[keyAttribute]}>
              {
                !isOpen ? (
                  <StyledListItemText primary={subItem[labelAttribute]} />
                ) : (
                  <StyledListSubItemText primary={subItem[labelAttribute]} />
                )
              }
            </MenuItem>,
          );
        });
      }
      optionsArray = [...optionsArray, ...listEntry];
    });
    return optionsArray;
  };

  return (
    <Box>
      <FormControl
        disabled={!options?.length}
        sx={{
          minWidth: width,
          width: '100%',
        }}
      >
        {
          !selectedItem && !selected && !isOpen && (
            <StyledInputLabel
              sx={{ ...styles.inputLabelFocus }}
              shrink={false}
            >
              {menuTitle}
            </StyledInputLabel>

          )
        }
        <Select
          onOpen={handleOnOpen}
          onClose={handleOnClose}
          onChange={handleChange || noop}
          /*
           * NOTE: 'disableUnderline' added below. Otherwise a dashed underline
           * appears when FormComponent is disabled
          */
          input={<Input disableUnderline />}
          MenuProps={MenuProps}
          sx={{ ...styles.inputInput, ...(menuValue && isDisabled && styles.disabled), ...styles.selectDropdownIcon, minWidth: width }}
          value={selectedItem}
          disabled={isDisabled}
          defaultValue={defaultValue}
          IconComponent={SelectDropdownIcon}
        >
          {!hideMenuTitle && (
            <StyledMenuItem value={menuTitle} >
              <StyledListItemText>{menuTitle}</StyledListItemText>
            </StyledMenuItem>
          )}
          {getSelectOptions()}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(ListMenu);
