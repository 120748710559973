import React from 'react';
import {
  Box,
  CircularProgress, PaginationProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  StyledTableCell2,
} from '../../../../TeacherBenchmarksDashboard/teacherbenchmarkdashboard.styled';
import useUserInfo from '../../../../../utils/useUserInfo';
import { palette } from '../../../../../theme/palette';
import {
  AdminBenchmarkReportingWidgetSortType,
  OrderBy,
} from '../../../../../generated/graphql';
import { ArrowDownIcon } from '../../../../../components/Icons';
import {
  StyledTableSort,
  tableWithNoDataStyles,
} from '../../../../../components/Common/Common.styled';
import { TeacherBenchmarkDashBoard } from '../../../../../variables/constant';
import { StyledTableCell3, StyledTableCell5 } from '../../../../Admin/admin-tables-styled';
import AdminProgressScoresRow from './ProgressScoresRow';
import NoResults from '../../../../../components/NoResults';
import EmptyBenchmarkResults from '../../../../../components/Icons/EmptyBenchmarksResults';
import CustomPagination from '../../../../../components/CustomPagination';
import { Legend } from 'recharts';
import useAdminBenchmarkProgressHook from './admin-benchmark-progress-hook';
import { StyledTableContainer } from '../../../../../components/Common/Common.styled';
import {
  DistrictWideComparisonsStylesCSS,
} from '../../../../AdminAnalytics/DistrictWideComparisons/DistrictWideComparisons.styled';
import { useTheme } from '@mui/material/styles';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: AdminBenchmarkReportingWidgetSortType,
  order: OrderBy,
  data: any[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: AdminBenchmarkReportingWidgetSortType) => void) | Function,
  rowsPerPage: number,
  setRowsPerPage: Function,
  tabName: string,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  data,
  handleSort,
  rowsPerPage,
  setRowsPerPage,
  tabName,
}: Props) => {
  const [_, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const legendData: any[] = [];
  const datalegend = scoreScheme?.map((item: any) => {
    legendData.push({ skillTitle: item?.title, color: item?.color_code });
  });
  legendData.push({ skillTitle: 'Incomplete / No Score', color: palette.customGrey.iconGrade });

  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);

  const { getSortType } = useAdminBenchmarkProgressHook();
  const tabKey = tabName + 's';

  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const sortColumn = (title: string, sortEnum: AdminBenchmarkReportingWidgetSortType) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      {title}
    </StyledTableSort>
  );
  return (
    <Box
      display="grid"
      width="100%"
      id={tabName}
      paddingBottom={2}
      mt={0}
    >
      <Box>
        <StyledTableContainer sx={{ ...styles.tableContainer }}>
          <Table
            stickyHeader
            aria-label={tabName + '-progress-table'}
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead >
              <TableRow >
                <StyledTableCell2 sx={{ border: 'none' }}>
                </StyledTableCell2>
                <StyledTableCell2 colSpan={6} sx={{ textAlign: 'center', border: 'none', height: '52px' }}>
                                    Speaking
                </StyledTableCell2>
                <StyledTableCell2 colSpan={6} sx={{ textAlign: 'center', border: 'none', height: '52px' }}>
                                    Writing
                </StyledTableCell2>
              </TableRow>
              <TableRow >
                <StyledTableCell5>
                  {sortColumn(tabName,  getSortType(tabKey.toLowerCase()))}
                </StyledTableCell5>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.SpBoyScore && { padding: '8px 8px 8px 24px' }) }}>
                  {sortColumn('BOY', AdminBenchmarkReportingWidgetSortType.SpBoyScore)}
                </StyledTableCell3>
                <StyledTableCell3 >
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.SpMoyScore && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('MOY', AdminBenchmarkReportingWidgetSortType.SpMoyScore)}
                </StyledTableCell3>
                <StyledTableCell3 >
                </StyledTableCell3 >
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.SpEoyScore && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('EOY', AdminBenchmarkReportingWidgetSortType.SpEoyScore)}
                </StyledTableCell3>
                <StyledTableCell3 sx={{ ...(sort !== AdminBenchmarkReportingWidgetSortType.SpYtdChange && { padding: '8px 8px 8px 24px' }) }}>
                  {sortColumn('YTD Change', AdminBenchmarkReportingWidgetSortType.SpYtdChange)}
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.WrBoyScore && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('BOY', AdminBenchmarkReportingWidgetSortType.WrBoyScore)}
                </StyledTableCell3>
                <StyledTableCell3 >
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.WrMoyScore && { padding: '8px 8px 8px 24px' }) }}  >
                  {sortColumn('MOY', AdminBenchmarkReportingWidgetSortType.WrMoyScore)}
                </StyledTableCell3>
                <StyledTableCell3 >
                </StyledTableCell3>
                <StyledTableCell3 sx={{ textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.WrEoyScore && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('EOY', AdminBenchmarkReportingWidgetSortType.WrEoyScore)}
                </StyledTableCell3>
                <StyledTableCell3 sx={{ ...(sort !== AdminBenchmarkReportingWidgetSortType.WrYtdChange && { padding: '8px 8px 8px 24px' }) }} >
                  {sortColumn('YTD Change', AdminBenchmarkReportingWidgetSortType.WrYtdChange)}
                </StyledTableCell3>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && data?.map((score) => (
                <AdminProgressScoresRow progressScore={score} key={score?.id} />
              ))}
              {isTableEmpty && !loading && (
                <TableRow>
                  <TableCell
                    colSpan={14}
                    align="center"
                  >
                    <NoResults
                      description={TeacherBenchmarkDashBoard.ProgressScore.insufficientData.description}
                      svgComponent={<EmptyBenchmarkResults />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={14}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <Box
        display="flex"
        justifyContent='space-between'
      >
        <Box mt={3} ml={1}>
          {!isTableEmpty && !loading && <Legend
            wrapperStyle={{ position: 'inherit', fontSize: '14px', fontWeight: 600 }}
            payload={legendData?.map((item, index) => ({
              value: (
                <span style={{ color: palette.customBlack.fontBlack, fontSize: '14px', fontWeight: 600 }}>{item?.skillTitle}</span> // Setting legend label color to black
              ),
              type: 'circle',
              color: item?.color,
              textColor: palette.customBlack.fontBlack,
            }))}
          />}
        </Box>
        <Box>
          <CustomPagination
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={pagination.page || 1}
            onChange={handlePageChange}
            setRowsPerPage={setRowsPerPage}
            paddingTop='16px'
          />
        </Box>
      </Box>
    </Box>
  );
};
export default List;
