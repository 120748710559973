/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const QuestionIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g id="icon / question icon">
      <path d="M23.625 12c0 6.4217-5.2051 11.625-11.625 11.625C5.58014 23.625.375 18.4217.375 12 .375 5.58202 5.58014.375 12 .375 18.4199.375 23.625 5.58202 23.625 12zM12.312 4.21875c-2.55459 0-4.18387 1.07611-5.46328 2.98866-.16575.24778-.1103.58195.12726.76209L8.6025 9.20278c.24398.18502.59161.141.78117-.09947.83733-1.06209 1.41153-1.67798 2.68603-1.67798.9577 0 2.1421.61631 2.1421 1.5449 0 .702-.5795 1.06257-1.525 1.59267-1.1027.6181-2.5618 1.3875-2.5618 3.3121v.1875c0 .3106.2519.5625.5625.5625h2.625c.3106 0 .5625-.2519.5625-.5625V14c0-1.3341 3.8993-1.3897 3.8993-5 0-2.71884-2.8202-4.78125-5.4623-4.78125zM12 15.8438c-1.189 0-2.15625.9672-2.15625 2.1562 0 1.1889.96725 2.1562 2.15625 2.1562s2.1562-.9673 2.1562-2.1562c0-1.189-.9672-2.1562-2.1562-2.1562z" fill={props.pathFill || palette.customBlue.primaryBlue} />
    </g>
  </SvgIcon>
);

export default QuestionIcon;
