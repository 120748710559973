import React from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import StyledBoxWrapper from './StyledCard/StyledCard.styled';
import noop from '../utils/noop';
import { palette } from '../theme/palette';

type Alignment = 'inherit' | 'left' | 'center' | 'right' | 'justify';

interface Props {
  description?: string,
  title?: string,
  buttonTitle?: string,
  onClick?: () => void
  titleAlignment?:Alignment
  contentWrapperMinHeightAuto?: boolean
  Icon: React.ComponentType<any>;
}

const styles = {
  title: {
    '&.MuiTypography-root': {
      fontSize: '20px',
      lineHeight: '27.28px',
      fontWeight: 'bold',
      weight: '800',
      textTransform: 'capitalize',
      paddingLeft: 1,
      marginTop: 3,
      marginBottom: 3,
    },
  },
  contentWrapper: {
    minWidth: '250px',
    justifyContent: 'center',
    display: 'flex',
    padding: 1,
    minHeight: 150,
  },
  cardTitle: {
    minWidth: '250px',
    justifyContent: 'start',
    alignItems: 'center',
    display: 'flex',
    padding: 1,
    flexDirection: 'column',
  },
  viewBtn: {
    width: '132px',
    height: '44px',
  },
  btnBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  iconTile:{
    '&.MuiSvgIcon-root':{
      fontSize: '64px',
    },
  },
};

const IconCard = ({
  title,
  buttonTitle,
  onClick,
  titleAlignment = 'center',
  Icon,
}: Props) => {
  return (
    <StyledBoxWrapper width="100%" alignItems="center">
      <Box sx={{ ...styles.cardTitle }}>
        <Icon
          fillColor={palette.customBlue.primaryBlue}
          sx={{ ...styles.iconTile }}
        />
        <Typography align={titleAlignment} sx={{ ...styles.title }} component='p'>
          {title}
        </Typography>
      </Box>
      <Box sx={{ ...styles.btnBox }}>
        {buttonTitle && (
          <Button
            onClick={onClick || noop}
            variant="contained"
            color="primary"
            sx={{ ...styles.viewBtn }}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>
    </StyledBoxWrapper>
  );
};

export default IconCard;
