import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import {  Box } from '@mui/material';

import {
  NotifierProps,
  NotifierType,
  OpenSnackbarFunction,
} from '../../variables/types';
import useNotifier from './use-notifier';
import SnackbarContentWrapper from './SnackbarContentWrapper';

let openSnackbarFn: OpenSnackbarFunction;

const Notifier = () => {
  const {
    open,
    message,
    variant,
    customIcon,
    actionButtonText,
    onActionButtonClick,
    handleSnackbarClose,
    openSnackbar,
    showMessage,
  } = useNotifier();

  useEffect(() => {
    openSnackbarFn = openSnackbar.current;
  }, [openSnackbar]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      open={open}
      ContentProps={{
        'aria-describedby': 'snackbar-message',
      }}
    >
      <Box>
        {message ? (
          <SnackbarContentWrapper
            onClose={handleSnackbarClose}
            variant={variant}
            message={showMessage}
            customIcon={customIcon}
            showViewButton={actionButtonText ? true : false}
            onViewButtonClick={onActionButtonClick}
          />
        ) : undefined}
      </Box>
    </Snackbar>
  );
};

export const openSnackbar = (
  {
    message,
    customIcon,
    actionButtonText,
    onActionButtonClick,
    messageTitle,
  }: NotifierProps,
  variant: NotifierType,
) => {
  openSnackbarFn &&
  openSnackbarFn(
    {
      message,
      customIcon,
      actionButtonText,
      onActionButtonClick,
      messageTitle,
    },
    variant,
  );
};

export default Notifier;

