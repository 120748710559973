import React from 'react';
import { Box, Typography } from '@mui/material';
import BenchmarkTable from './BenchmarkTable';
import Layout from '../../../components/Layout';

const List = () => {
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 200px"
          gap='32px'
          alignItems="center"
        >
          <Box>
            <Typography variant="pageTitle">Benchmarks</Typography>
          </Box>
        </Box>
        <Box display="flex" mt={2}>
          <BenchmarkTable />
        </Box>
      </Box>
    </Layout>
  );
};

export default List;
