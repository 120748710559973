import React, { useMemo, useState } from 'react';
import VocabularyAndGrammarIcon from '../../../components/Icons/VocabularyAndGrammarIcon';
import DescriptionAndExplanation from '../../../components/Icons/DescriptionAndExplanation';
import PointsAndReason from '../../../components/Icons/PointsAndReason';
import { Skills, SpeakingSkills } from '../../../variables/types';
import InterpretationIcon from '../../../components/Icons/InterpretationIcon';
import VocabularyIcon from '../../../components/Icons/VocabularyIcon';
import GrammarIcon from '../../../components/Icons/GrammarIcon';
import FluencyIcon from '../../../components/Icons/FluencyIcon';
import PronunciationIcon from '../../../components/Icons/PronunciationIcon';
import {
  ANCHOR_GRADE_1_2,
  ANCHOR_GRADE_3_5,
  ANCHOR_GRADE_6_8,
  ANCHOR_GRADE_9_12,
  ANCHOR_GRADE_PK_K, DEFAULT_SCHEME, SCHEME_1,
} from '../../../variables/constant';
import { useAuthProvider } from '../../../core/authContext';
import { palette  } from '../../../theme/palette';

interface Props {
  section: string;
}

export interface SkillLevel {
  description?: string,
  url?: string,
  fileType?: string,
  scoreType: string,
  disable?: boolean,
  scoreValue?: number,
}

export interface Datum {
  name: string,
  Icon: React.ComponentType<any>,
  subText?: string
  scoreTypes?: SkillLevel[]
}

export interface Skill {
  pageCaption: string,
  pageSubCaption: string,
  data?: Datum[],
  ANCHOR_GRADE_PK_K?: SkillLevel[],
  ANCHOR_GRADE_1_2?: SkillLevel[],
  ANCHOR_GRADE_3_5?: SkillLevel[],
  ANCHOR_GRADE_6_8?: SkillLevel[],
  ANCHOR_GRADE_9_12?: SkillLevel[],
}
export interface AnchorDataByCategory {
  interpretation?: SkillLevel[],
  vocabulary?: SkillLevel[],
  grammar?: SkillLevel[],
  fluency?: SkillLevel[],
  pronunciation?: SkillLevel[],
  vocabularyAndGrammar?: SkillLevel[],
  descriptionAndExplanation?: SkillLevel[],
  pointsAndReasons?: SkillLevel[],
}

export interface AnchorTableData {
  ANCHOR_GRADE_PK_K: AnchorDataByCategory,
  ANCHOR_GRADE_1_2: AnchorDataByCategory,
  ANCHOR_GRADE_3_5: AnchorDataByCategory,
  ANCHOR_GRADE_6_8: AnchorDataByCategory,
  ANCHOR_GRADE_9_12?: AnchorDataByCategory,
}

const scoreTypes: string[] = ['limited', 'emerging', 'expanding', 'bridging', 'advanced'];
const speakingIndicators = ['Interpretation', 'Vocabulary', 'Grammar', 'Fluency', 'Pronunciation'];

const bucketAudiosBaseURL = `https://${
  import.meta.env.REACT_APP_DOCUMENT_BUCKET_NAME
}`
    + '.s3-accelerate'
    + '.amazonaws.com/static_resource/audios/speaking_anchor_new';

const skillsWriting: any = {
  vocab_and_grammar: 'vocabularyAndGrammar',
  description_and_explanation: 'descriptionAndExplanation',
  points_and_reasons: 'pointsAndReasons',
};

const speakingData = (gradeLevel: string) => speakingIndicators.reduce((acc: any, item) => {
  const indicatorData = scoreTypes.map((scoreType: string, idx: number) => ({
    url: `${bucketAudiosBaseURL}/${gradeLevel}/Score${idx + 1}-${item}.mp3`,
    fileType: 'audio/wav',
    scoreType: scoreType,
    scoreValue: idx + 1,
  }));
  acc[item.toLowerCase()] = indicatorData;
  return acc;
}, {});

const SpeakingTableData: AnchorTableData = {
  ANCHOR_GRADE_1_2: speakingData('1_2'),
  ANCHOR_GRADE_3_5: speakingData('3_5'),
  ANCHOR_GRADE_6_8: speakingData('6_8'),
  ANCHOR_GRADE_9_12: speakingData('9_12'),
  ANCHOR_GRADE_PK_K: speakingData('PK_K'),
};

const AnchorList: Record<'speaking' | 'writing', Skill> = {
  speaking: {
    pageCaption: 'Speaking Anchors',
    pageSubCaption: 'Samples of students’ responses at each level',
    data: [
      {
        name: 'Interpretation',
        Icon: InterpretationIcon,
        subText: 'How well did the student comprehend, interpret, and analyze the narrative or content-related image to describe, explain, or justify claims/opinions with reasons?',
      },
      {
        name: 'Vocabulary',
        Icon: VocabularyIcon,
        subText: 'How well did the student use precise vocabulary to '
          + 'describe the scene, including academic vocabulary and '
          + 'content-specific vocabulary?',
      },
      {
        name: 'Grammar',
        Icon: GrammarIcon,
        subText: 'How well did the student produce grammatically correct '
          + 'speech, including use of word order to talk about the '
          + 'picture in a cohesive and connected way?',
      },
      {
        name: 'Fluency',
        Icon: FluencyIcon,
        subText: 'How well did the student produce speech that is smooth '
          + 'and sustained?',
      },
      {
        name: 'Pronunciation',
        Icon: PronunciationIcon,
        subText: 'How well did the student produce speech with accurate '
          + 'pronunciation and intonation that does not impede '
          + 'meaning?',
      },
    ],
  },
  writing: {
    pageCaption: 'Writing Anchors',
    pageSubCaption: 'Here are examples of student writing at each level.',
    data: [
      {
        name: 'Vocabulary & Grammar',
        Icon: VocabularyAndGrammarIcon,
        subText: 'How well did the student use '
          + 'vocabulary, grammar, and mechanics in their writing?',
      },
      {
        Icon: DescriptionAndExplanation,
        name: 'Description/Explanation',
        subText: 'How well did the student describe a picture with details '
          + 'and connect ideas in a meaningful and cohesive way?',
      },
      {
        Icon: PointsAndReason,
        name: 'Points & Reasons',
        subText: 'How well did the student support a claim '
          + 'or opinion with evidence and/or reasons?',
      },
    ],
    ANCHOR_GRADE_PK_K: [
      {
        description: 'No written response or response in heritage language or letter written does not represent the first sound of the specified word.',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'Student writes an initial letter in an initial position for a word (“b” for book)',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'Student writes two letters of a word including an initial letter in an initial position for a word and another letter in any position in the word (“grl” for girl)',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'Student writes entire words appropriate to image phonetically (“boy”)',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'Student writes whole words and/or attempts to construct phrases or short sentences (“a girl”; “the book”)',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    ANCHOR_GRADE_1_2: [
      {
        description: 'I see a school. I see un policia asiendo que el bus se pare. ',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'I se i schol the studens or walking to the schol a men using a sain that seds stop and a rout.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'I can tell this is a school because there are kids , a dad waking her kid and a man stooping a bus.\n \nOne day the peoples are taking the children to school. Then the man is stopping the school bus with a sign of stop. Finally the school has a beautiful flag, grass, doors, children, and a clock.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'I see that it is a nice morning people are walking to school. There is a trafic helper helping people get across. well it looks like its time for school. and I see a flag that school is good.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I believe this is outside of a school because I see a big red school bilding and a man walking a kid to school. In addicion, there is a group of kids walking to school. Finally there is a man telling a bus to stop driving because there are people crossing the road.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    ANCHOR_GRADE_3_5: [
      {
        description: 'wi going to do art and also wi have art pictures up in the wall.',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'In this story is a art clas and what the techer is doing is pating and the gril and the techer and 2 students pating.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'The teacher is passing out crayons to the students. And the students are making a project after they are done making the project.They paint it or draw on it. There is a clock on the wall and drawings.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'There were two teachers who liked art so they started a art club and a lot of students joined that day. They did a lot of activities but one person didnt like the art club because he thought it was a waist of time. When he started he didnt like it but when he went everyday he liked it more and more.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I can tell that this is a picture of an art classroom because students are working on art projects. The teacher is picking up unwanted markers, while the two boys at the table paint foam cubes with the colors red and green. The boy in the orange shirt looks focused. Another student is not painting, but drawing with markers. I think she is drawing the outside, because I can slightly see a sun and grass. I see a student in the back, who is painting a striped sphere with the colors orange and yellow. In conclusion, this image shows students actively working on art projects.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    ANCHOR_GRADE_6_8: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    ANCHOR_GRADE_9_12: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
};

const WritingTableData: AnchorTableData = {
  ANCHOR_GRADE_PK_K: {
    vocabularyAndGrammar: [
      {
        description: 'Evolet',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'i c   e\n' + 'i c b\n' + 'i  c    mk',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'MEGR     PEDUS        ELUPHT          TOCEE          PEGRIN',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'i        see         eat\\.\n' + '  i        see          zoo\\.\n' + '  i         see         tree\n' + '   i         see      boe',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I like pnguwn.\n' + 'I see the peadas.\n' + 'It is the eleft.\n' + 'I see the pnguwn.\n' + 'I like the zoo.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'yo megusta el elefante',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'elf   qd     qgwmg',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'raM    eeN    BLu   Gra',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'se 1pecoc\n' + ' se 6pagwinz',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'i see to elfit in a wor\\.  I see faf pigs day pIiyin in os in wor\\.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pointsAndReasons: [
      {
        description: 'Lxusdncasjnwwdhnjifweqdaejvhiuewf',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'z',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'UTRA R A   T ZOO',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'i see a pwen.      i see  a pad.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'mokey ar   eat  banana    elfin  ra   eat   wanr',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
  ANCHOR_GRADE_1_2: {
    vocabularyAndGrammar: [
      {
        description: 'the gamin gir',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'i see a clock and a scoolroof and a flag and a klowd and a scoolbus and a backpack and a stopsin',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'I see a man and a girl. The kids are wakling to school. The school bus is yelow.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'A girl is going to school with her dad.\n' + ' A man has a stop sign because pepole are walking in the roed\n' +
            ' The kids get out of the bus the kids go to school\n' + ' \n' + ' The school bus is taking kids to school.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I see a Dad walking his daughter to school. There is a bus going to school to drop off kids. There is kids going inside the school. I see an American flag waving in the air. There are trees in the back of the school. I see buildings behind the trees. There\'s a man holding the stop sign for the Dad and daughter could go. There is clouds in the sky sometimes to block the sun. There are windows in the school for the kids could look out of them.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'i see a pink',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'i see a man waking a girl to shcool and a stop sin and bus',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'So in the picture I see a dad with their child walking to school and some kids walking to school too and a flag on the side of the school and their just all walking to school.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'The girl and his dad are walking to school.The man is holding the stop sign so people could cross. The amircan flag is waving.The school windows are diffrent colors.The girl has a pink backpack.The school bus is yellow.There are trees in the backround.The school is red pink ish.the dad has a blue shirt and the girl has a purple shirt and the dad is wearing gray pants the girl is wearing green pants.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'Theres a dad walking his daughter and says you are so beautiful then the beautiful daughter thanks him and says your so kind you are a kind dad the kind dad thanks her and keeps walking. Then, the cross guard stops the bus with his red stop sign. Next, the beautiful daughter says thank you and tells him to be carefull he replies im a carefull cross guard. Theres also a nice group of kids that just did the plege of alligance to the American flag. The dad and the daughter made it to school at eight o clock on time.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pointsAndReasons: [
      {
        description: 'school grl stop bos bab',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: '"I See a stop sin\n' + ' and a school bas\n' + ' and kis \n' + ' and Dad with lis',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'the kids are walking to school and the crossing gardis helping them cross safe the school bus is takeing some kids to school',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'This picture is acbout the School.\n' +
            ' Out side of the school there is safty guird. He is stoping the vahical for kids to cross the way to goto the school. Some students are outside of the outside of the they already cross the streat.\n' +
            ' School bus is waithing to pass the kids. There is ameriacan flag out side of the School. There is stop sign on the hand of the safty guird.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'It\'s time for school and the kids are excited to go to school and learn. June and her dad are crossing the road and the crossing guard stops the school bus with his stop sign. June and her dad safely make it to school. June isn\'t late to school either.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
  ANCHOR_GRADE_3_5: {
    vocabularyAndGrammar: [
      {
        description: 'ticher,cat ,car,dod,sit, pensol,cudo',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'the girl is painting and one teacher is painting a boll and two boys painting cubes and other teacher passing crayons and a letters.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'So this picture is about a Art classorm. I know this is a Art class because I see paintings on the wall, and a sing were is says "Art is fun!". I see a teeacher pasing out markers to students, the other teacher is painting a ball, and for last I see a students painting on a paper.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'I think this is a art classroom because there is a sign that says "Art Is Fun!" I also think that this is a art classroom because the teacher is giving markers to the kid or the student, and there are blocks. One of the student is painting a block, he is painting it red. Then there is another person in the back painting something round. Thats why I think this is a art classroom.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I this picture I see 5 kids.First, there are 3 boys and 2 girls.There are 3 stations and the first station is painting on clay and molding clay.Next, at the second station there is a kid with black locks and a yellow shirt that is painting on a drawing sheet.I see one blue chair and 2 brown chairs.After that, at the thrird station there are 3 kids.The staition has 1 kid with black hair and a orange shirt and he is painting a cube red. There is also one girl at the table and she has black hair and a purple striped shirt and she is currently putting down markers on the table. There is also another boy at the table and he has a blue striped shirt and he has black hair and he is painting a cube green.Then, I see 6 paintings in the backround.The first painting has a tree, a dog,a bird and a sun. The next painting that I see is a sunflower and then there\'s a cat then a swirl that is blue and then a red car and a dog with a star on top of it. Finally, the art room that they are in has a blue wall and there is also a black clock and there\'s also a sign that states art is fun. The floor in this room is white and all the kids look around 10 years old.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'here are patl',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'I see paint, a student drawing, 3 students painting, a teacher, 6 pictures on the wall, a clock, and a sign on the wall. (:',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'The teacher is passing out crayons to the students. And the students are making a project after they are done making the project.They paint it or draw on it. There is a clock on the wall and drawings.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'There are two teens running a art club or a day care. There are three other kids one of them is a girl and the two other kids are boys. The boys are painting cube susing the primary colors. The girl is sitting at her own circle table and there is a white floor. There are blue walls, and on those walls, there are painting that the kids made.The girl who is sitting at her own table, is drawing instesd of painting.The colors that she is using is pink, purple, and green.\n' +
            ' \n' +
            '  THE END',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'We here in a art classn room. First, there are three students and two teachers. One of the teachers are painting a pot orange and yellow she has paint surronding her the colors are green, red, yellow, orange, white, and black. Next, there are two boys at a table painting blocks one student is painting a block red and the other student is painting a block green. There are also paints, paintbrushes, and markers by them. Next there is a teacher picking up are objects. Next, there is pictures on the walls with a clock. There is a picture of a car, flowers, swirls, dog under the sun, and a picture frame with a picture. There is a bird in the sky, the sun, dog, and a tree. Lastly, there is a girl coloring a picture with papers and crayons by her.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pointsAndReasons: [
      {
        description: 'l see un gril , much de peiper',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'I see sudent cloring on paper and a techer paniting a vase and a girl picking up marcer and to sudent paning blocks.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'A art teacher is showing there kids how to draw and paint . A girl is drawling and coloring. and the two friends are painting squares while the teacher is passing out coler macers to coler with.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'In my opinion on what i think is going on this picture is its a art classroom.And the reason why i think its an art classroom is because there is many art supplies and things to paint like crayons, paint, blocks to paint and a sphere wich a girl is already painting.there is a art techer passing out markers for the kids to use.On the left there is a girl that isnt painting, shes drawing a picture with crayons and paper.On the wall there are many things like a sign that says art is fun , a drawn picture of the outside, many picures or drawings, and a clock that lets them know when art starts and ends.there is a window for the kids to see the outside.And that is what this picture is about.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I think what is happening is that people are in art class, why I think that because people are drawing, and painting and smiling and there are many reasons why this is an art class and this is why. First there is a girl drawing on a peice of paper and she has some extra paper if she wants to draw more drawings, and she also has colored pencils if she wants to color in the drawing. Second theres a girl painting a sphere to make it look like a planet, and shes using some paint and paintbrushes to make the planet, and she is also doing that on the desk. Third there are some drawings on the wall so that means that some kids drew some drawings and hung it on the wall. Forth there are 2 boys painting squares, and they also painted some extra blocks with some paint, also a techer is passing out some supplies like markers and putting them on the table. And then there is a sign that says that Art is fun, and there is some drawings of a tree and a dog and a window. So thats why I think this is an art class.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
  ANCHOR_GRADE_6_8: {
    vocabularyAndGrammar: [
      {
        description: 'A lot of people',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'the girl use the computer for search the photes. the guy toma a phote for la girl.the girls colgan the photes in the wall.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'In this picture you can see a women and a boy loking up some images in the computer. You can also see a boy taking photos of the girl that is holding a sertifict. The last one that you can see is the two womens that are puting up some photos.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'The boy in the top left coner is taking a picture of the girl infront of him. The girl is holding the trophe so she won a contast or somthing.In the botom left coner the student looks like he is helping the teacher put pictures on his project. The teacher is helping the kid put pictures on his project. The top right the girl in the green shirt is holding wet pisctures. The other girl is putting the pictures to dry while her freind is handing them to her.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'The teacher is reviewing the pictures that the students took. The student in blue is helping the teacher reviewing the pictures. The two girls wearing green and blue are hanging up the pictures that the students took. The boy in white is taking pictures of the girl in purple. The girl in purple is holding an award while getting her photo taken by the other student.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'photo camera teacher student',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'In the picture i see a girl on the computer , and two girls hanging uo stuff on the wall and then i see a boy taking a picture of a girl holding up a reward .',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'I see a person who\'s working on something on a computer. i see a person hanging pictures on a wall. i see a person who is taking a picture of someone. i see cameras, mouse, keyboard, chair, a person, jeans, hijab, and a paper. also, i think this is a kind of classroom where they do projects and arts.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'A class are doing a variety of things inside the classroom. One of the students is taking pictures for another student. The other students are hanging up drawings that were made by their peers. Another student is showing the teacher something and the teacher is looking at the drawings her students have made. This class were doing these activities at school.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'There is a boy taking a picture of a girl holding up a medal. She probably won a contest or got a highscore on the test. Also there is a boy and a girl at a computer. The boy looks like he is helping the girl with the pictures on the cuomputer. She is looking at the picture on the computer. There is a camera in the background that is used for taking pictures. Two girls in the backgroung are pinning pictures on a piece of yarn or string. The setting is a green room with grey tiles and brown desks. Looks like a school project for the yearbook or for pictures that are going to be hung up on the walls around the school. Is this for the yearbook or something else?',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pointsAndReasons: [
      {
        description: 'the people she lot the photos',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'What is see in this picture is a girl who is smileing who just won an award award and a boy who is taking oicture and a teacher and a boy looking at a comeputer and two girls hanging up pictuer.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'This picture shows a boy looking at a computer while a girl is using a computer and sitting on a chair. I see a boy taking a picture of a girl that is holding a reward. Another thing i see is two girls hanging up pictures of a horse, lake, and a person. This is what i see in the picture.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'In this classroom they are doing a proyect for the school. I know this because the teacher is showing to his student what is the pictures they are using. And in the left side there are two students taking extra photos for the proyect. The kid is holding the camera and the girl is holding a important document. In the rigth side there are two women putting pictures in the wall. In the table that is infront of the two Women there is a little camera and a amount of paper on the table.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'In this picture, I see a man with a white T-shirt, a pair of jeans, and black shoes with white shoe laces, taking a picture of a woman who\'s wearing purple pants and unique orange shoes with black shoe laces, holding a yellow piece of paper in her hands. I know this because the man is holding a camera, and the woman standing in front of him is smiling.\n' +
            ' I also see a boy with a blue and orange golf shirt, and a girl with black straight hair with black glasses printing pictures that the boy took. I know that they are printing pictures because other girls that are standing in a corner of the room have the same pictures that are on the computer screen.\n' +
            ' I also see a girl with a black scarf and a blue shirt with a long brown skirt, and a woman with straight brown hair with a green jacket, hanging some pictures that the girl printed. I know that they are hanging pictures because the girl with the black scarf is clipping the pictures on a straight string. And I also see some blank pieces of paper and a camera on a desk.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
  ANCHOR_GRADE_9_12: {
    vocabularyAndGrammar: [
      {
        description: 'unas mujeres estan colgando unas photos',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'looking the two girls hanging the photos and snoyher group looking at the photos on the computer and a boy taking pictures of a girl who has a medal',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'It was a special day in class, because we went to take pictures outside the school first, we took photos of the horses, mountais and a boy and we also took photos of the school next, we went te school and took the photos and hung them on the rope and finally, the girl got a diploma for taking good photos.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'This picture looks as a photograpy class, we can see two girls hanging the pictures on a wall located in the background of the room, I can see that one is from Middle East and the other girl is could be American. Next to them we can see two persons taking pictures (the guy taking a picture of the girl with a prize) and she is posing for it. In the desktop are another two persons editing the pictures in the computer and they have pictures about piramids and a horse so I suppose they traveled to many parts with the school people.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'The teacher is reviewing the pictures that the students took. The student in blue is helping the teacher reviewing the pictures. The two girls wearing green and blue are hanging up the pictures that the students took. The boy in white is taking pictures of the girl in purple. The girl in purple is holding an award while getting her photo taken by the other student.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'Boy and girl look the compiurer.',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'i see 2 boys and 4 grils and one of them are the techer and the 2 grils are hanging picheres and the boy is taken one of the girl.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'In this image its classmates working on there assigments each one of the students has a partner first they take a picture of there partner. Then they go to print the picture out. After the image is printed out they go to the wall and hang up the image.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'These people are apart of a photography club or group. There is a Girl that is working on the computer to help the group getn their photos on paper. There is A boy who is telling the girl that is on the computer what to do. There are these two girls that are hanging up these pictures to either dry or look at the photos the shot. Their is a girl talking to the cameraman about their group and the award that they won because of the photos they took and entered and photogtaphy contest and looks like they won the competition.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'A man wearing a white T-shirt and jeans on the left is taking a picture of a woman wearing a purple suit holding a prize on the other side. On the wall to the right, one of the two women wearing a hijab uses clips to hang pictures from a string. A woman in a green shirt and gray skirt in front of her hijab woman is handing her a picture of herself. Next to them is a desk with a camera and a stack of papers. A man wearing a blue t-shirt with horizontal orange stripes talks while pointing fingers at a woman working on a computer monitor. The woman in a white shirt is using a keyboard and mouse. These people are in a room with green walls and a checkered tile floor.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pointsAndReasons: [
      {
        description: 'I see a boy a girl,chool,picture,peper,desk,table and hourse',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'IThe boy and girl is talking and looking pictures\n' + ' The two girls is presented pictures\n' +
            ' The boy is recording video\n' + ' The girl is present one art',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'Six guys are in a photo club, they are divied in three groups. Each group is doing something different. The first group is taking some pictures, the second group is editing some pictures and the third group is hanging some pictures that they already print.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'In this picture i see a room with 6 people, they\'re friends and are studying photography. In the bottom i see Carlos and Maria, they are a couple, she is working in a presentation with the photos that everybody took in the year , this presentation is for the new students that comes to the class because this group of friends leave this year, also in the left side we see David taking photos of Karen with her diploma, she looks very happy because took so many time to pass the class, the room is surrounded by 4 green walls, also has a gray roof with squares, finally in the right side of the picture we see Natalie putting the photos to decorate the room and julia is helping her, next to them we see a table with sheets and a camera on top.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I\'m going to analyze this image from my point of view, first we can see that there are six people in a photography room in which there are several photographs, we can infer that it is a competition since I can see a person with an award and two other people looking at some photographs on the computer as judges, we can say that the person who is receiving the award is a photography student, something that What strikes me is that the people are from different cultures, I think that one of the people is Moroccan, skin tones and hair types, the room where these people meet is a room with cold colors like green, the images that we observe on the computer are the same images that appear hanging on the wall by the other students, the winner of the contest was probably photographed to appear in some magazine or newspaper, this was my analysis of the photograph, what I managed to perceive from this image.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
};

export const getOverlayAnchorInfo = (grade: string | undefined | null, writingSkill: string | null, speakingSkill: SpeakingSkills | null, scoreValue: number) => {
  const skillWriting:string = writingSkill !== null ? skillsWriting?.[writingSkill] : '';
  switch (grade) {
    case 'PK':
    case 'TK':
    case 'K':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_PK_K?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : WritingTableData.ANCHOR_GRADE_PK_K?.[skillWriting]?.find((data: SkillLevel) => data.scoreValue === scoreValue)?.description;
    case '1':
    case '2':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_1_2?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : WritingTableData.ANCHOR_GRADE_1_2?.[skillWriting]?.find((data: SkillLevel) => data.scoreValue === scoreValue)?.description;
    case '3':
    case '4':
    case '5':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_3_5?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : WritingTableData.ANCHOR_GRADE_3_5?.[skillWriting]?.find((data: SkillLevel) => data.scoreValue === scoreValue)?.description;
    case '6':
    case '7':
    case '8':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_6_8?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : WritingTableData.ANCHOR_GRADE_6_8?.[skillWriting]?.find((data: SkillLevel) => data.scoreValue === scoreValue)?.description;
    case '9':
    case '10':
    case '11':
    case '12':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_9_12?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : WritingTableData.ANCHOR_GRADE_9_12?.[skillWriting]?.find((data: SkillLevel) => data.scoreValue === scoreValue)?.description;
    default:
      return '';
  }
};

export interface TableEntity {
  fill: string | undefined,
  textColor: string | undefined,
  label: string,
  description: string,
}

export interface ScoreScheme {
  default: TableEntity[],
  texas: TableEntity[]
}

const TableChip: ScoreScheme = {
  default: [
    {
      fill: palette.customRed.buttonFontRed,
      textColor: palette.customWhite.main,
      label: 'Level 1: Limited',
      description: 'Limited/no response in English, may contain student’s first language',
    },
    {
      fill: palette.customYellow.main,
      textColor: palette.fontColors.fontBlack,
      label: 'Level 2: Emerging',
      description: 'Emerging English with significant errors',
    },
    {
      fill: palette.fontColors.buttonFontGreen,
      textColor: palette.customWhite.main,
      label: 'Level 3: Expanding',
      description: 'Responses are more precise and contain moderate errors',
    },
    {
      fill:palette.customBlue.primaryBlue,
      textColor: palette.customWhite.main,
      label: 'Level 4: Bridging',
      description: 'Correct and relevant responses with minimal errors',
    },
    {
      fill: palette.customBlue.cornflowerBlue,
      textColor: palette.customWhite.main,
      label: 'Level 5: Advanced',
      description: 'Meets or exceeds grade-level ELA/ELD and CCSS/CCR standards',
    },
  ],
  texas: [
    {
      fill: palette.customRed.buttonFontRed,
      textColor: palette.customWhite.main,
      label: 'Level 1: Beginner',
      description: ' Limited/no response in English. May contain student’s home language.',
    },
    {
      fill: palette.customYellow.main,
      textColor: palette.fontColors.fontBlack,
      label: 'Level 2: Intermediate',
      description: 'Has simple language structure, utilizes high frequency vocabulary, verbalizes with routine and social vocabulary.',
    },
    {
      fill: palette.fontColors.buttonFontGreen,
      textColor: palette.customWhite.main,
      label: 'Level 3: Advanced',
      description: 'Uses grade level appropriate English language response with minimal errors, with second language support.',
    },
    {
      fill:palette.customBlue.primaryBlue,
      textColor: palette.customWhite.main,
      label: 'Level 4: Advanced High',
      description: 'Uses grade level appropriate English language response, comparable to English speaking peer, with minimum second language support.',
    },
    {
      fill: palette.customBlue.cornflowerBlue,
      textColor: palette.customWhite.main,
      label: 'Proficient',
      description: 'Meets or exceeds ELPs criteria and grade level TEKS/CCR standards.',
    },
  ],
};

export interface GradeEntity {
  label: string,
  pt_image?: string,
}

const GradeChip: GradeEntity[] = [
  {
    label: ANCHOR_GRADE_PK_K,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/PK-K-Zoo.png',
  },
  {
    label: ANCHOR_GRADE_1_2,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/1-2-School-Community-Gr.PK-K.jpg',
  },
  {
    label: ANCHOR_GRADE_3_5,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/Art-class-Gr.3-5.jpg',
  },
  {
    label: ANCHOR_GRADE_6_8,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/Photography-Class-Gr.6-8.jpg',
  },
  {
    label: ANCHOR_GRADE_9_12,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/Photography-Class-Gr.6-8.jpg',
  },
];

const useAnchorData = ({ section }: Props) => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const [grade, setGrade] = useState(ANCHOR_GRADE_PK_K);
  const anchorData = useMemo(() => (section === Skills.Speaking
    ? AnchorList.speaking : AnchorList.writing), [section]);

  const getTableChipData = (scoreScheme: string) => {
    switch (scoreScheme) {
      case DEFAULT_SCHEME:
        return TableChip.default;
      case SCHEME_1:
        return TableChip.texas;
      default:
        return TableChip.default;
    }
  };

  const tableChipData = useMemo(() => getTableChipData(user.score_scheme?.title_key), [user.score_scheme]);
  const gradeChipData = useMemo(() => GradeChip, []);

  const getGradeData = (gradeType: string) => {
    switch (gradeType) {
      case ANCHOR_GRADE_PK_K:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_PK_K
          : WritingTableData.ANCHOR_GRADE_PK_K;
      case ANCHOR_GRADE_1_2:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_1_2
          : WritingTableData.ANCHOR_GRADE_1_2;
      case ANCHOR_GRADE_3_5:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_3_5
          : WritingTableData.ANCHOR_GRADE_3_5;
      case ANCHOR_GRADE_6_8:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_6_8
          : WritingTableData.ANCHOR_GRADE_6_8;
      case ANCHOR_GRADE_9_12:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_9_12
          : WritingTableData.ANCHOR_GRADE_9_12;
      default:
        return null;
    }
  };
  const anchorContent = useMemo(() => getGradeData(grade), [getGradeData, grade]);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>();
  const [showPreview, setShowPreview] = useState(false);

  return ({
    anchorData,
    tableChipData,
    gradeChipData,
    grade,
    setGrade,
    anchorContent,
    selectedPlayerId,
    setSelectedPlayerId,
    showPreview,
    setShowPreview,
  });
};

export default useAnchorData;
