import { StyledChipBox } from '../Chip/ChipsCount.styled';
import { palette } from '../../theme/palette';
import NumberFormat from 'react-number-format';
import React from 'react';

interface NavigationChipProps{
  dataCount?: number
}

const NavigationChip = ({ dataCount }:NavigationChipProps) => {
  return <StyledChipBox
    sx={{ backgroundColor: palette.customBackground.dragBackground }}
    hascolor={palette.customBlack.lightBlack}
    label={(
      <NumberFormat
        displayType="text"
        value={dataCount ?? '-'}
        thousandSeparator
      />
    )}
  />;
};

export  default  NavigationChip;
