/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

const PrintIcon = (props: any) => {
  let { fillColor } = props;
  fillColor = fillColor ? fillColor : palette.customBlue.primaryBlue;
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00039 8.8999H6.50039V8.3999V4.7999C6.50039 3.75056 7.35105 2.8999 8.40039 2.8999H15.6004C16.6497 2.8999 17.5004 3.75056 17.5004 4.7999V8.3999V8.8999H18.0004H19.2004C20.2497 8.8999 21.1004 9.75056 21.1004 10.7999V14.3999C21.1004 15.4492 20.2497 16.2999 19.2004 16.2999H18.0004H17.5004V16.7999V19.1999C17.5004 20.2492 16.6497 21.0999 15.6004 21.0999H8.40039C7.35105 21.0999 6.50039 20.2492 6.50039 19.1999V16.7999V16.2999H6.00039H4.80039C3.75105 16.2999 2.90039 15.4492 2.90039 14.3999V10.7999C2.90039 9.75056 3.75105 8.8999 4.80039 8.8999H6.00039ZM16.1004 4.7999V4.2999H15.6004H8.40039H7.90039V4.7999V8.3999V8.8999H8.40039H15.6004H16.1004V8.3999V4.7999ZM16.1004 14.3999V13.8999H15.6004H8.40039H7.90039V14.3999V19.1999V19.6999H8.40039H15.6004H16.1004V19.1999V14.3999Z"
        stroke={fillColor}
        fill={fillColor}
      />
    </SvgIcon>
  );
};
export default PrintIcon;
