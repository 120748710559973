/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React, { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const ConfirmationImage: FC<any> = ({ imagePrimaryColor, ...remainingProps }: any) => (
  <SvgIcon
    fontSize="small"
    width="401"
    height="468"
    viewBox="0 0 401 468"
    fill="black"
    {...remainingProps}
  >
    <g clipPath="url(#clip0)">
      <path d="M332.777 358.429C319.799 323.144 288.283 304.015 255.283 290.272C238.042 283.029 220.43 276.158 204.672 266.129C199.851 262.972 195.217 259.629 190.767 255.915C179.458 245.887 168.891 232.701 163.329 218.587C159.992 210.415 155.728 203.172 149.24 196.858C136.819 184.601 119.763 177.358 102.521 176.801C73.415 175.687 46.348 192.587 29.1066 215.058C8.1575 242.358 0.927263 278.201 0.000309706 312.187C-0.926643 346.729 5.00586 382.387 24.4719 411.729C43.1963 439.958 71.0049 455.558 103.819 461.687C142.936 468.929 184.093 469.487 223.767 466.144C254.171 463.544 289.21 460.015 313.681 439.587C336.855 419.901 342.973 386.472 332.777 358.429Z" fill="black" />
      <path d="M263.44 457.972C228.03 465.215 190.767 466.329 154.801 464.472C121.06 462.615 83.0547 459.829 54.5046 439.587C27.8083 420.458 12.2355 389.815 6.1176 358.058C-0.371075 324.815 1.29744 287.672 11.4939 255.358C22.0612 221.929 46.3474 191.101 81.2008 181.815C96.5882 177.729 113.273 179.215 127.734 185.529C142.009 191.844 154.801 203.544 160.177 218.401C163.143 226.387 167.593 233.629 172.598 240.501C177.604 247.187 182.98 253.687 189.284 259.072C200.778 268.915 214.497 275.972 228.401 282.101C242.676 288.601 257.507 294.172 271.597 300.858C282.72 306.244 293.659 312.372 302.928 320.544C326.658 341.529 340.933 373.844 331.849 405.415C323.136 437.544 293.659 451.844 263.44 457.972Z" fill="white" />
      <path d="M303.3 320.73C294.03 312.559 283.092 306.244 271.969 301.044C257.879 294.173 243.048 288.602 228.772 282.287C215.054 276.159 201.335 269.102 189.655 259.259C183.352 253.873 177.975 247.373 172.97 240.687C167.964 234.002 163.33 226.573 160.549 218.587C159.993 217.102 159.436 215.616 158.695 214.13C152.762 209.859 146.274 206.516 139.414 205.03C129.774 200.759 119.021 202.802 110.679 209.116C99.1844 217.659 94.5496 231.773 91.5834 245.144C85.2801 275.602 86.207 307.359 102.521 334.473C117.538 359.359 142.566 376.816 171.116 382.387C187.801 385.544 204.301 383.873 220.801 380.159C231.739 377.744 242.677 374.587 253.615 372.544C253.615 372.544 253.8 372.544 253.8 372.73C282.165 378.116 293.474 411.916 274.193 433.459C256.21 453.144 226.548 453.887 201.891 454.444C173.341 455.187 144.976 452.587 116.982 448.502C116.797 448.13 116.426 447.759 115.87 447.759C113.83 447.573 111.791 447.573 109.752 447.387C100.111 445.902 90.6564 444.23 81.0161 442.373C65.6287 438.287 50.9828 432.159 37.2639 423.987C42.4549 429.744 48.202 435.13 54.6906 439.587C83.0554 460.016 121.061 462.802 154.987 464.473C190.953 466.33 228.216 465.216 263.626 457.973C293.659 451.844 323.322 437.544 332.406 405.787C341.119 374.03 327.03 341.902 303.3 320.73Z" fill="#D3DEE3" />
      <path d="M303.114 158.972C303.114 157.486 302.743 156.186 302.187 154.886C300.704 152.1 297.738 150.429 294.771 150.986C294.401 149.686 293.845 148.386 292.918 147.272C291.434 145.415 288.839 144.857 286.8 145.6C289.581 137.8 291.805 127.957 285.131 125.915C281.423 124.8 278.828 128.329 277.53 131.3C274.749 138.357 272.895 145.972 270.485 153.215C270.3 153.586 270.485 153.772 270.485 154.143C268.261 155.443 266.592 157.3 265.48 159.715C262.884 164.915 263.811 170.857 266.221 175.872C258.435 192.957 247.682 209.486 230.626 218.215C213.2 227.129 190.767 225.086 178.902 208.372C178.717 208.186 178.532 208 178.346 207.815C177.79 207.072 176.678 206.7 175.936 207.815C166.481 223.043 161.105 241.8 169.077 258.886C176.863 275.786 195.217 281.357 212.458 280.8C250.463 279.315 280.867 249.6 285.131 212.272L288.839 195.372C289.766 191.472 290.322 189.243 293.474 186.643C295.698 184.786 297.738 183.115 299.592 180.886C301.075 180.329 302.372 179.4 303.485 178.472C308.49 173.272 312.013 161.2 303.114 158.972Z" fill="black" />
      <path d="M294.401 161.014C295.142 158.786 295.513 156.186 295.328 153.586C301.26 152.1 300.519 161.014 299.406 164.543C298.665 166.771 297.367 169 295.328 170.3C292.918 171.786 289.766 171.414 288.654 168.814C291.434 167.328 293.474 163.986 294.401 161.014Z" fill="white" />
      <path d="M292.361 152.657C292.732 154.7 292.546 157.114 292.176 158.972C291.805 160.643 291.063 162.314 289.951 163.8C289.58 164.357 289.024 164.729 288.653 165.286C286.985 167.143 285.131 167.143 283.091 164.914C284.945 164.357 286.243 162.872 286.614 160.457C286.799 157.857 285.316 155.629 283.091 154.143C283.648 153.029 284.389 151.357 285.316 149.314C285.687 149.5 286.243 149.314 286.614 148.943C289.951 144.672 292.176 151.172 292.361 152.657Z" fill="white" />
      <path d="M276.788 142.814C277.715 139.657 279.013 135.199 280.311 132.228C281.609 129.442 285.502 127.585 286.614 132.971C286.614 133.528 286.614 133.899 286.429 134.457C286.614 136.128 285.687 139.471 285.131 141.328C284.204 145.414 282.536 149.128 280.682 152.842V153.028C279.94 152.657 279.013 152.471 278.272 152.471C276.603 152.285 275.12 152.471 273.637 152.842C274.749 149.314 275.862 145.971 276.788 142.814Z" fill="white" />
      <path d="M172.97 260.187C163.886 244.215 168.521 225.458 177.419 210.601C186.503 222.673 201.335 227.687 215.795 225.644C216.537 242.73 217.093 259.815 217.835 277.087C217.835 277.272 217.835 277.458 218.02 277.458C217.649 277.458 217.464 277.458 217.093 277.644C200.593 279.13 181.683 275.601 172.97 260.187Z" fill="white" />
      <path d="M293.659 182.928C292.361 184.043 289.766 184.785 288.653 186.085C284.389 191.1 284.575 203.728 283.277 209.857C279.755 227.871 274.007 243.842 260.845 256.842C249.907 267.614 235.632 274.857 220.43 277.085C219.688 259.814 219.132 242.542 218.39 225.271C220.8 224.714 223.21 224.157 225.62 223.228C246.755 215.428 259.918 196.3 268.817 176.614C269.002 176.057 269.002 175.685 268.631 175.314C268.631 175.128 268.631 174.757 268.446 174.571C265.665 169 265.294 162.128 270.3 157.671C272.524 155.628 275.861 154.7 278.828 155.443C280.867 156 287.17 159.9 281.238 163.057C279.755 163.8 276.603 162.685 275.12 162.128C273.451 161.571 272.71 164.171 274.378 164.728C275.861 165.285 277.901 165.657 279.755 165.657C280.311 168.257 282.721 170.114 285.502 169.743C286.243 171.971 288.097 173.457 290.322 174.014C289.766 176.8 290.878 179.585 293.659 180.7C294.215 180.885 294.771 181.071 295.327 181.071C295.142 181.628 294.4 182.185 293.659 182.928Z" fill="white" />
      <path d="M304.227 172.9C303.3 174.571 302.002 176.242 300.333 177.357C298.109 178.842 291.249 178.842 293.474 174.014C297.367 173.457 299.963 170.114 301.446 166.771C302.002 165.285 302.558 163.614 302.929 161.757C307.193 163.428 306.266 169.185 304.227 172.9Z" fill="white" />
      <path d="M215.054 336.328C212.643 328.714 208.565 321.657 205.042 314.6C199.666 303.643 197.256 292.314 196.144 280.057C194.475 260.185 195.958 239.2 187.06 220.628C183.908 213.943 179.088 208 173.155 203.543C164.256 197.043 153.504 193.143 142.751 191.471C139.414 190.914 136.077 191.1 132.74 191.843C126.066 193.328 119.948 197.228 115.499 202.428C105.117 214.685 102.151 232.143 100.297 247.557C95.8473 283.214 101.409 323.7 119.763 355.085C127.92 369.014 139.6 377.557 155.729 379.971C170.931 382.2 189.47 382.385 202.818 374.028C216.537 365.671 219.688 351 215.054 336.328Z" fill="black" />
      <path d="M212.087 360.843C204.301 376.443 184.835 378.672 169.633 378.3C153.689 378.115 138.673 374.957 128.105 362.143C118.465 350.443 113.089 334.657 109.01 320.357C104.746 304.943 102.336 288.972 102.151 273C101.965 255.915 103.634 238.272 108.825 221.929C112.903 208.929 124.398 192.029 139.97 193.515C144.791 193.886 149.425 195.372 153.875 197.043C158.139 198.529 162.403 200.015 166.481 202.057C191.324 214.872 192.065 245.886 193.363 270.215C194.104 284.515 195.402 298.629 201.149 311.815C207.267 326.672 220.43 344.129 212.087 360.843Z" fill="white" />
      <path d="M378.938 406.527C360.584 399.284 340.192 402.07 324.063 413.027C323.321 413.584 323.321 414.513 323.692 415.07C319.242 421.385 314.608 429.185 318.13 436.985C321.096 443.299 327.77 444.413 334.074 444.227C345.012 443.856 356.135 442.37 367.073 440.513C376.157 439.027 388.022 438.099 396.18 433.456C411.752 424.17 386.725 409.499 378.938 406.527Z" fill="black" />
      <path d="M396.365 429.742C395.252 431.599 391.359 432.528 389.691 432.899C375.601 436.985 360.584 439.213 346.124 440.699C340.191 441.256 332.776 442.742 326.843 441.07C313.31 437.17 321.652 422.499 326.658 415.628C327.029 415.256 327.029 414.885 326.843 414.513C338.523 407.085 352.056 403.742 365.775 405.785C372.82 406.899 379.494 409.128 385.612 412.842C388.393 414.513 391.915 416.371 393.955 418.785C395.623 420.642 397.663 427.513 396.365 429.742Z" fill="white" />
      <path d="M318.316 298.628C278.271 278.571 233.221 295.842 195.031 311.628C193.733 312.185 193.919 314.228 195.031 314.413C192.25 325.556 199.295 336.142 209.491 340.785C224.879 347.842 242.12 342.271 258.249 343.756C295.698 347.285 315.535 384.056 326.844 415.813C326.102 416.556 326.287 418.228 327.771 418.228C341.489 418.042 354.467 414.328 366.146 407.271C366.332 407.271 366.332 407.085 366.517 406.899C366.517 406.713 366.703 406.713 366.703 406.528V406.342C366.703 406.342 366.703 406.342 366.703 406.156C365.961 366.042 357.989 318.499 318.316 298.628Z" fill="black" />
      <path d="M329.625 415.257C320.17 388.328 306.451 360.286 279.569 346.914C265.851 340.228 252.317 340.414 237.3 341.343C228.958 341.714 220.244 341.9 212.458 338.928C202.447 335.028 194.661 325 197.998 314.043C197.998 313.857 197.998 313.486 197.998 313.3C234.334 298.443 276.789 282.286 314.979 300.114C355.023 318.686 363.181 365.671 363.922 405.414C353.355 411.543 341.861 414.886 329.625 415.257Z" fill={imagePrimaryColor || palette.customPink.main} />
      <path d="M351.872 421.756C333.518 414.513 313.125 417.299 296.996 428.256C296.255 428.813 296.255 429.742 296.625 430.299C292.176 436.613 287.541 444.413 291.064 452.213C294.03 458.528 300.704 459.642 307.007 459.456C317.945 459.085 329.069 457.599 340.007 455.742C349.091 454.256 360.956 453.328 369.113 448.685C384.686 439.399 359.658 424.913 351.872 421.756Z" fill="black" />
      <path d="M369.298 444.971C368.186 446.828 364.293 447.757 362.624 448.128C348.535 452.214 333.518 454.442 319.058 455.928C313.125 456.485 305.709 457.971 299.777 456.299C286.243 452.399 294.586 437.728 299.592 430.857C299.962 430.485 299.962 430.114 299.777 429.742C311.457 422.314 324.99 418.971 338.709 421.014C345.754 422.128 352.428 424.357 358.546 428.071C361.327 429.742 364.849 431.599 366.888 434.014C368.557 436.057 370.596 442.928 369.298 444.971Z" fill="white" />
      <path d="M345.383 418.228C345.939 413.028 343.529 406.714 342.973 401.885C342.046 394.271 341.119 386.657 339.636 379.042C337.04 364.185 332.776 349.328 325.175 336.142C318.872 325.371 310.159 315.528 299.221 309.028C269.373 290.457 235.261 298.628 204.486 311.442C203.374 309.957 200.222 303.828 197.997 303.828C197.626 303.828 197.256 304.014 196.885 304.2C183.166 309.957 170.189 317.2 156.284 322.585C148.313 325.742 140.341 328.528 132.184 331.128C128.105 332.428 124.026 333.728 119.948 334.842C117.909 335.4 115.684 335.771 113.83 336.514C111.234 337.628 112.532 340.971 113.088 343.014C118.094 358.985 128.105 372.357 143.122 379.785C161.29 388.7 182.61 388.514 202.076 384.057C213.199 381.457 224.137 377.557 234.705 373.285C245.272 369.014 258.435 362.7 269.187 369.757C278.828 376.071 282.721 391.114 285.687 401.328C287.726 408.385 289.209 415.442 290.322 422.685C290.878 426.585 291.249 430.3 291.62 434.2C291.99 437.914 291.249 440.514 295.884 440.885C301.445 441.257 307.192 439.585 312.569 438.285C318.687 436.614 324.434 434.2 329.81 431.228C332.591 429.557 335.372 427.885 337.782 426.028C339.821 424.542 343.9 422.871 344.641 420.085C345.197 419.528 345.383 418.971 345.383 418.228Z" fill="black" />
      <path d="M279.198 377.185C267.89 357.871 248.794 365.113 231.924 371.799C209.306 380.899 184.278 388.142 159.807 382.756C137.931 377.928 118.65 362.142 115.499 339.113C144.049 331.499 171.487 320.728 197.812 306.985L202.076 312.742C194.104 316.085 186.318 319.799 179.088 323.328C177.604 324.071 178.902 326.485 180.385 325.742C215.609 308.656 260.474 288.228 298.108 311.442C333.889 333.728 338.894 380.156 342.231 418.228C342.231 418.413 342.417 418.785 342.417 418.971C329.254 431.228 312.569 437.913 294.771 438.285C293.659 417.856 289.58 395.013 279.198 377.185Z" fill={imagePrimaryColor || palette.customPink.main} />
      <path d="M311.086 301.787C312.569 301.787 314.052 301.787 314.979 301.23C316.277 300.487 317.019 298.073 317.389 296.773C317.76 294.915 317.946 291.944 315.535 291.573C311.086 291.015 306.266 291.201 301.817 291.201C292.176 290.83 282.536 290.644 272.896 290.458C269.188 290.458 265.48 290.273 261.958 290.273L236.188 289.53C231.924 289.344 227.475 289.344 223.211 289.158C221.172 289.158 218.947 288.973 216.908 288.973C215.61 288.973 212.644 288.23 211.346 289.158C209.492 290.458 209.307 292.687 210.048 294.544C210.604 296.401 211.717 298.258 213.385 299.373C215.61 300.673 218.391 299.93 220.801 299.93L230.627 300.116C237.301 300.301 243.789 300.301 250.463 300.487C263.626 300.673 276.974 301.044 290.137 301.23C293.845 301.23 297.553 301.416 301.26 301.416C303.67 301.416 306.266 301.601 308.676 301.601C309.418 301.601 310.159 301.787 311.086 301.787Z" fill="black" />
      <path d="M260.104 298.073C251.947 297.887 243.789 297.701 235.632 297.515C231.554 297.515 227.29 297.33 223.211 297.33C221.172 297.33 219.133 297.33 217.093 297.33C215.425 297.33 213.942 296.958 213.015 295.287C212.644 294.544 212.273 293.43 212.273 292.501C212.458 290.644 220.059 291.758 221.543 291.758C225.992 291.944 230.441 291.944 234.705 292.13C243.604 292.315 252.317 292.687 261.216 292.873C270.3 293.058 279.199 293.43 288.283 293.615C292.733 293.801 297.367 293.801 301.817 293.987C304.041 293.987 306.452 294.173 308.676 294.173C309.974 294.173 314.98 293.244 314.609 295.473C314.423 296.587 314.238 298.258 313.311 298.815C312.013 299.558 310.159 299.187 308.676 299.001C306.637 298.815 304.598 298.815 302.558 298.815C297.738 298.63 292.918 298.63 288.098 298.444C279.014 298.258 269.559 298.258 260.104 298.073Z" fill="white" />
      <path d="M341.861 216.915C341.49 216.73 340.934 216.73 340.378 216.544C322.024 214.687 303.671 212.087 285.502 209.673C283.092 209.301 279.014 207.815 276.789 209.487C275.12 210.787 275.306 213.387 274.935 215.244C274.379 218.215 274.008 221.373 273.452 224.344C272.525 230.473 271.413 236.601 270.486 242.73C268.446 254.801 266.592 266.873 264.553 278.944C263.626 284.515 262.328 290.83 267.89 294.358C274.008 298.258 284.575 296.587 291.249 296.773C299.963 297.144 310.159 299.001 318.687 296.958C325.361 295.473 328.698 290.644 330.181 284.33C333.148 273.001 335.372 261.487 337.412 249.973C338.339 244.215 339.451 238.644 340.378 232.887C340.934 230.101 341.305 227.13 341.861 224.344C342.232 222.673 344.271 218.215 341.861 216.915Z" fill="black" />
      <path d="M322.024 292.687C315.35 296.958 303.856 294.544 296.255 294.358C288.654 294.173 279.57 295.101 271.969 293.244C261.772 290.644 268.261 271.33 269.559 264.087C270.857 255.73 272.34 247.187 273.823 238.83C274.935 232.144 275.491 225.087 276.233 218.401C276.604 215.801 277.16 212.83 279.941 211.901C281.795 211.344 283.649 211.716 285.502 212.273C293.66 214.316 301.817 215.058 309.974 216.173C316.463 217.101 322.951 217.473 329.44 218.216C332.592 218.587 337.597 217.287 339.451 220.816C341.305 224.158 338.71 228.801 337.968 231.958C335.187 244.587 332.592 257.215 330.182 270.03C329.069 277.087 328.698 288.23 322.024 292.687Z" fill="white" />
      <path d="M339.822 220.445C337.968 216.916 332.962 218.216 329.811 217.845C323.322 216.916 316.833 216.545 310.345 215.802C302.188 214.688 293.845 213.945 285.873 211.902C285.132 211.716 284.39 211.531 283.649 211.345C278.272 234.374 274.564 257.774 272.711 281.174C272.34 287.116 273.081 291.202 276.604 293.802C283.092 294.545 290.137 293.988 296.44 294.174C304.041 294.359 315.536 296.774 322.21 292.502C329.069 288.045 329.255 276.902 330.738 269.474C333.333 256.845 335.743 244.031 338.524 231.402C339.08 228.431 341.676 223.602 339.822 220.445Z" fill="#D3DEE3" />
      <path d="M253.244 261.115C251.576 252.015 239.525 248.301 231.739 248.487C226.548 248.672 221.357 249.972 216.722 252.015C208.194 255.729 202.076 262.787 196.144 269.472C190.582 275.787 184.279 281.729 175.195 281.729C161.847 281.544 160.178 267.615 157.583 257.401C154.987 246.629 152.762 235.672 149.425 225.087C147.201 218.215 144.049 211.715 138.302 207.444C125.881 198.158 111.791 206.515 106.229 219.144C98.9992 235.487 100.297 255.358 106.6 271.887C113.274 297.701 123.842 331.687 154.616 336.515C186.133 341.344 207.082 311.629 221.542 288.601C221.542 288.601 221.542 288.601 221.542 288.415C221.728 288.229 221.913 288.23 222.284 288.044C222.284 288.415 222.655 288.787 223.211 288.972C227.66 290.458 231.924 287.487 232.666 283.029C232.666 282.844 232.666 282.844 232.666 282.658H232.851C237.671 281.915 238.784 278.572 238.228 274.487C239.155 275.787 240.267 276.715 241.935 277.087C244.16 277.458 246.014 275.972 246.57 273.744C247.126 270.772 245.458 267.615 243.975 265.201C243.789 264.829 243.604 264.644 243.419 264.272C245.458 265.572 247.312 267.058 249.907 266.501C252.503 266.129 253.8 263.529 253.244 261.115Z" fill="black" />
      <path d="M105.673 259.072C104.561 253.686 104.004 248.115 104.19 242.358C104.561 231.958 106.6 219.144 114.386 211.529C123.841 202.058 136.819 206.144 143.122 216.915C148.313 225.644 149.796 236.601 152.021 246.258C153.689 253.129 154.801 260.558 156.841 267.429C143.678 276.715 129.588 284.515 114.757 290.644C111.049 280.429 107.898 269.472 105.673 259.072Z" fill="white" />
      <path d="M250.649 262.787C249.722 264.459 247.682 263.716 246.385 262.973C244.531 261.859 243.233 260.559 241.008 260.187C240.823 260.187 240.638 260.187 240.452 260.187C239.34 258.887 238.042 257.587 236.559 256.473C235.261 255.359 233.222 257.401 234.705 258.33C237.857 260.93 240.638 263.901 242.492 267.43C242.862 268.173 243.418 269.287 243.789 270.216C244.16 271.33 245.087 273.373 243.604 273.744C243.418 273.744 242.306 274.301 240.823 273.001C239.711 272.073 238.969 270.959 238.042 269.844C237.115 268.73 236.374 267.43 235.632 266.316C235.447 265.944 235.076 265.573 234.89 265.016C234.705 264.83 234.52 264.273 234.334 264.087C233.593 262.973 232.666 261.859 231.739 260.744C230.441 259.63 228.587 261.487 229.885 262.601C232.666 265.201 240.638 278.016 233.222 279.873C232.666 275.601 229.329 272.073 225.992 269.473C224.694 268.359 222.655 270.216 224.138 271.33C226.548 273.187 228.773 275.23 229.885 278.016C231.553 281.73 229.7 287.859 224.694 286.187C225.25 285.63 225.621 284.887 225.806 283.959C226.363 282.287 223.767 281.544 223.211 283.216C222.469 285.073 220.43 285.63 219.318 287.301C205.784 309.03 183.908 340.601 153.689 333.173C137.746 329.83 127.179 317.759 120.69 303.644C119.021 300.116 117.538 296.587 116.24 292.873C131.072 286.744 145.161 279.13 158.324 269.844C158.695 271.144 159.251 272.444 159.807 273.559C162.032 278.759 166.111 282.473 171.672 283.587C187.987 287.116 196.885 271.144 207.082 261.673C217.835 251.459 236.559 245.33 248.424 257.216C248.98 258.701 251.39 261.116 250.649 262.787Z" fill="white" />
      <path d="M210.789 171.6C207.267 167.143 198.368 166.772 194.29 170.115C190.211 173.643 188.913 179.772 192.065 184.229C195.402 188.872 201.705 189.986 206.525 187.015C210.789 184.415 213.756 177.915 211.716 173.086C211.531 172.715 211.16 172.158 210.789 171.6Z" fill="black" />
      <path d="M207.082 183.486C203.745 186.643 198.554 187.015 195.217 183.672C192.065 180.515 192.621 175.686 195.588 172.715C197.256 171.043 199.666 170.301 202.076 170.486C208.936 170.858 212.458 178.101 207.082 183.486Z" fill="white" />
      <path d="M217.463 131.486C215.053 115.7 202.817 104.186 187.244 101.772C171.857 99.3576 157.026 106.786 147.756 119.043C134.964 135.758 134.964 158.043 142.195 177.172C141.268 185.343 134.594 192.586 137.745 201.129C140.155 207.443 146.273 210.972 152.02 213.386C157.026 215.429 165.368 219.329 169.447 213.758C171.116 211.529 171.116 208.558 171.301 205.958C171.672 202.058 172.228 198.158 172.599 194.258C184.649 198.343 197.812 191.472 205.042 181.072C214.682 167.515 220.059 147.643 217.463 131.486Z" fill="black" />
      <path d="M211.901 160.087C208.194 172.158 200.407 186.458 188.171 191.472C185.761 192.401 182.98 192.958 180.385 192.958C178.531 192.958 176.863 192.401 175.194 192.03C173.34 191.472 171.486 190.544 170.745 192.958C169.261 197.787 170.559 203.172 168.705 208.187C164.998 218.03 153.318 211.715 147.571 208.001C140.897 203.73 138.487 197.415 141.082 189.987C142.38 186.087 144.048 182.372 144.975 178.472C145.161 178.101 145.161 177.544 145.161 177.172C144.975 175.501 143.863 173.272 143.307 171.787C142.751 169.744 142.194 167.701 141.638 165.658C140.711 161.572 140.155 157.301 140.155 153.215C139.97 144.858 141.453 136.501 145.161 128.887C146.829 125.544 148.683 122.387 151.093 119.601C162.773 105.301 182.98 99.3581 199.48 109.015C217.648 119.23 217.278 142.072 211.901 160.087Z" fill="white" />
      <path d="M199.294 108.644C182.609 99.1721 162.587 104.929 150.907 119.229C148.497 122.015 146.643 125.172 144.975 128.515C141.452 136.129 139.784 144.486 139.969 152.844C139.969 153.772 139.969 154.701 140.155 155.629C143.677 157.115 147.57 158.044 151.278 158.786C151.464 159.715 152.205 160.458 153.317 159.901C165.739 151.915 175.935 141.515 183.907 129.072C194.289 134.272 204.485 139.844 214.867 144.672C216.165 130.558 212.642 116.444 199.294 108.644Z" fill="#D3DEE3" />
      <path d="M173.71 191.658C171.115 190.543 168.519 189.243 165.924 188.129C163.699 187.2 159.62 184.229 157.025 185.158C152.576 187.015 155.727 195.558 157.21 198.343C159.435 202.615 163.699 206.7 168.519 207.815C170.188 202.986 169.075 197.786 170.559 192.958C171.115 190.915 172.412 191.1 173.71 191.658Z" fill="#D3DEE3" />
      <path d="M225.065 124.615C226.733 122.572 228.402 120.343 229.885 118.3C231.739 115.886 235.632 112.357 235.447 108.829C235.076 103.629 228.587 104.372 223.953 105.672C226.733 102.329 230.441 97.1288 226.548 94.7145C224.509 93.2288 221.172 93.9716 218.947 94.1574C216.722 94.5288 214.498 94.9002 212.273 95.6431C214.868 91.3716 218.391 84.8716 213.756 83.2002C210.975 82.2716 207.082 84.3145 204.672 85.4288C201.891 86.5431 199.11 87.8431 196.329 89.5145C198.369 85.2431 200.222 79.4859 194.105 80.0431C190.211 80.4145 185.577 83.0145 182.24 84.6859C177.976 86.7288 173.897 89.3288 170.004 92.1145C169.633 89.7002 169.448 87.2859 169.077 84.6859C168.891 83.7573 167.594 82.6431 166.852 83.7573C156.285 96.5716 143.122 108.272 137.19 124.243C131.628 138.357 134.409 154.143 143.493 166.215C144.049 166.957 144.976 166.957 145.532 166.586C145.718 166.586 145.903 166.4 146.088 166.4C161.847 155.443 173.341 140.029 179.273 122.014C192.807 125.172 205.228 130.743 216.537 138.915C216.722 138.915 216.722 139.1 216.908 139.1C217.278 139.472 217.835 139.657 218.391 139.286C222.099 137.057 225.621 134.643 229.329 132.415C231.183 131.3 235.261 129.815 235.447 127.215C235.818 122.757 229.329 123.686 225.065 124.615Z" fill="black" />
      <path d="M228.958 129.071C225.25 131.485 221.357 133.9 217.649 136.128C205.969 127.771 192.992 122.2 179.088 119.043C178.902 119.043 178.717 119.043 178.346 119.043C177.79 119.043 177.048 119.228 176.863 119.971C171.301 137.8 160.178 153.028 144.976 163.614C136.077 150.985 134.594 135.2 141.083 121.271C147.015 108.457 157.582 98.4283 166.667 87.8426C166.852 89.8855 167.223 92.114 167.408 94.1569C167.037 95.2712 168.15 96.5712 169.262 95.6426C173.711 92.2998 178.346 89.3283 183.352 86.914C185.762 85.7997 188.728 83.7569 191.509 83.5712C192.065 83.3855 192.621 83.1998 193.177 83.014C194.475 84.6855 194.661 86.3569 193.734 87.8426C193.363 89.3283 192.25 90.9998 191.509 92.2998C190.767 93.7855 192.621 94.8997 193.548 93.9712H193.734C198.183 91.1855 202.818 88.7712 207.638 87.0998C208.194 86.914 209.863 85.9855 210.419 86.1712C213.941 86.914 211.902 89.3283 211.16 90.6283C209.863 92.8569 208.75 95.2712 207.453 97.4998C207.453 97.4998 207.453 97.6855 207.267 97.6855C206.526 99.1712 208.565 100.471 209.492 99.1712C213.385 97.6855 217.464 96.7569 221.728 96.5712C223.211 96.5712 223.211 96.1997 223.952 97.6855C224.879 99.3569 224.138 99.5426 223.211 100.843C221.913 102.885 220.615 104.743 219.132 106.6C218.947 106.971 218.761 107.343 218.947 107.714C218.761 108.643 219.318 109.757 220.43 109.385C222.84 108.457 226.177 106.971 228.772 107.528C234.334 108.643 229.514 113.471 227.845 115.514C224.508 119.785 221.171 124.057 217.834 128.514C216.722 129.814 219.132 131.3 220.244 129.814C220.801 129.257 221.171 128.514 221.728 127.957C224.138 127.585 226.362 127.214 228.772 126.843C234.334 126.285 230.812 127.771 228.958 129.071Z" fill="black" />
      <path d="M150.167 157.672C146.644 153.214 137.746 152.843 133.667 156.186C129.588 159.714 128.291 165.843 131.442 170.3C134.779 174.943 140.341 176.8 145.161 174.014C149.425 171.414 151.65 164.543 151.094 159.343C151.094 159.157 150.723 158.414 150.167 157.672Z" fill="black" />
      <path d="M151.836 171.043C148.499 174.2 137.931 173.086 134.594 169.743C131.443 166.586 131.999 161.758 134.965 158.786C136.634 157.115 139.044 156.001 141.454 156.558C150.352 158.601 157.212 165.658 151.836 171.043Z" fill="white" />
      <path d="M186.689 156.371C184.649 156.371 182.239 158.6 182.981 161.2C184.093 164.728 187.986 162.5 188.543 159.9C189.284 157.3 188.172 156.371 186.689 156.371Z" fill="black" />
      <path d="M203.93 161.385C201.89 161.385 199.48 163.614 200.222 166.214C201.334 169.742 205.227 167.514 205.784 164.914C206.525 162.314 205.227 161.385 203.93 161.385Z" fill="black" />
      <path d="M197.441 161.572C196.884 163.986 195.587 170.115 192.435 170.672C190.767 170.857 191.508 173.457 193.177 173.272C197.626 172.715 199.109 165.843 200.036 162.315C200.407 160.458 197.811 159.9 197.441 161.572Z" fill="black" />
      <path d="M188.913 174.013C187.986 172.899 186.318 171.97 185.576 171.413C183.722 170.299 181.127 168.256 179.087 169.928C176.121 172.342 175.565 178.285 178.902 180.513C181.127 181.999 183.722 181.442 185.761 179.956C187.245 178.842 190.396 175.87 188.913 174.013Z" fill="black" />
      <path d="M185.762 402.442C169.633 371.428 139.044 349.885 104.561 345.056C104.005 345.056 103.449 345.242 103.263 345.614C102.522 345.428 101.78 345.614 101.409 346.356C93.4375 368.271 104.932 390.742 123.842 402.628C134.223 409.128 146.459 412.285 158.51 413.214C167.594 413.956 181.683 415.256 184.835 404.299C185.577 404.114 186.133 403.185 185.762 402.442Z" fill="black" />
      <path d="M187.06 428.257C178.161 426.214 117.167 416.185 109.752 410.799C93.8081 399.285 97.7013 332.614 103.819 347.656C137.19 352.299 168.891 374.585 184.835 404.485C184.835 404.485 195.958 430.114 187.06 428.257Z" fill="white" />
      <path d="M253.245 373.656C220.987 360.842 183.538 364.371 154.432 383.313C153.876 383.685 153.69 384.242 153.69 384.613C152.949 384.799 152.578 385.542 152.763 386.471C161.106 408.199 184.465 417.67 206.527 414.328C218.577 412.47 229.886 406.899 239.712 399.656C246.942 394.27 258.436 385.913 253.801 375.699C254.172 374.956 253.987 374.028 253.245 373.656Z" fill="black" />
      <path d="M271.042 392.228C263.07 396.685 210.234 428.813 201.15 429.556C181.684 431.228 140.898 378.299 155.544 385.728C183.908 367.342 222.47 363.442 253.986 375.699C253.801 375.699 279.014 387.956 271.042 392.228Z" fill="white" />
      <path d="M287.726 29.3435C277.9 25.2578 265.665 28.4149 259.361 37.1435C251.575 47.7292 254.727 61.6578 259.918 72.8006C260.474 75.0292 260.474 77.2578 259.918 79.3006C258.249 79.1149 256.581 79.8578 255.839 81.5292C255.468 82.4578 255.468 83.3863 255.839 84.1292C255.097 84.5006 254.541 85.0578 254.17 85.8006C253.8 86.7292 253.8 87.6577 254.17 88.4006C253.614 88.9577 253.058 89.8863 253.058 90.8149C253.058 91.9292 253.429 92.8577 254.17 93.6006C254.17 93.972 254.356 94.1577 254.727 94.3435C255.097 94.5292 255.468 94.9006 255.839 95.0863C254.541 101.215 260.288 106.786 266.406 104.372C268.26 103.629 271.226 101.401 270.67 98.9863C270.856 98.9863 271.226 98.9863 271.412 98.9863C274.193 99.3577 276.603 97.3149 276.417 94.5292C277.9 93.6006 278.457 91.372 277.344 89.8863C278.827 88.2149 279.198 85.8006 277.715 83.9435C277.715 83.9435 277.715 83.9435 277.53 83.7577C278.642 81.9006 280.125 80.4149 281.979 79.1149C292.361 76.1435 301.074 67.6006 302.743 56.6435C304.411 45.5006 298.108 33.6149 287.726 29.3435Z" fill="black" />
      <path d="M275.12 88.2141C274.564 88.5855 274.378 89.1427 274.564 89.6998C274.564 90.0712 274.564 90.6284 274.935 90.8141C275.676 91.3712 275.491 92.1141 274.564 92.2998C273.822 92.4855 273.451 93.4141 273.637 93.9712C273.637 94.157 273.822 94.157 273.822 94.3427C274.008 95.2712 273.451 96.1998 272.524 96.1998C272.339 96.1998 272.154 96.0141 271.783 96.0141C266.592 96.757 261.586 95.457 257.322 92.6712C257.137 92.2998 256.952 91.9284 256.581 91.7427C255.654 91.3712 255.839 90.4427 256.395 90.0712C256.952 90.0712 257.508 89.6998 257.879 89.3284V89.1427C257.879 88.957 257.879 88.957 257.879 88.7712C257.879 88.7712 257.879 88.7712 257.879 88.5855C257.879 88.3998 257.879 88.3998 257.879 88.2141C257.693 87.657 257.322 87.2855 256.766 87.0998C256.395 86.5427 256.952 85.9855 257.879 86.1712C258.435 86.357 259.176 85.9855 259.362 85.4284L259.547 85.2427C259.918 84.6855 259.733 83.757 258.991 83.3855C257.879 82.8284 258.249 81.5284 259.362 81.5284C259.362 81.7141 259.547 81.8998 259.733 81.8998C264.182 86.3569 270.3 87.4712 276.047 85.4284C276.232 86.5427 275.861 87.657 275.12 88.2141Z" fill="#D3DEE3" />
      <path d="M299.962 56.2727C299.22 61.1013 296.995 65.5584 293.473 69.087C291.804 70.7584 289.765 72.2441 287.726 73.5441C283.647 75.9584 279.569 76.1441 276.788 80.2298C276.232 80.9727 275.675 82.6441 274.749 83.0156C270.299 84.687 265.664 83.9441 262.142 80.787C261.4 80.2298 262.698 77.2584 262.884 76.5156C263.44 70.9441 259.732 65.9298 258.434 60.7298C256.766 53.8584 256.58 46.2441 260.473 39.9298C265.664 31.2013 277.529 27.8584 286.614 31.7584C296.068 35.8441 301.63 46.4298 299.962 56.2727Z" fill="white" />
      <path d="M281.423 60.5442C280.496 59.0585 278.457 58.3156 276.603 58.687C274.749 58.8727 273.451 59.987 272.153 61.287C271.041 58.8727 268.816 56.8299 266.221 58.8727C262.698 61.4727 265.85 66.6727 266.962 69.6442C266.962 69.8299 267.148 69.8299 267.148 70.0156C266.406 74.4727 265.665 78.9299 264.923 83.2013C264.552 84.8727 267.333 85.6156 267.519 83.9442L269.929 69.6442C270.114 69.087 269.743 68.5299 269.373 68.1585C268.816 66.6727 268.075 65.0013 267.704 63.5156C267.519 62.587 267.148 59.6156 269.187 61.1013C269.929 61.6585 270.114 62.9585 270.299 63.887C270.485 64.6299 271.041 65.0013 271.597 64.8156C271.968 65.0013 272.524 65.0013 272.895 64.4442C274.007 63.1442 276.417 59.987 278.642 61.4727C279.754 62.4013 279.013 63.7013 278.086 64.4442C276.973 65.187 275.49 65.187 274.193 65.3727C273.266 65.3727 272.895 66.1156 272.895 66.8585C271.783 72.4299 270.485 77.8156 269.373 83.387C269.002 85.0585 271.597 85.8013 271.968 84.1299C273.08 78.7442 274.193 73.3584 275.305 67.9727C277.159 67.787 279.013 67.4156 280.311 66.1156C281.979 64.8156 282.535 62.4013 281.423 60.5442Z" fill="black" />
      <path d="M244.531 28.9711C241.935 25.814 239.34 22.6569 236.744 19.4997C235.632 18.1997 233.778 20.0569 234.89 21.3569C237.486 24.514 240.081 27.6711 242.677 30.8283C243.789 32.314 245.643 30.2711 244.531 28.9711Z" fill="black" />
      <path d="M278.457 1.29965C278.272 5.38537 277.901 9.47108 277.716 13.5568C277.53 15.2282 280.311 15.2282 280.497 13.5568C280.682 9.47108 281.053 5.38537 281.238 1.29965C281.238 -0.371776 278.643 -0.371776 278.457 1.29965Z" fill="black" />
      <path d="M326.844 23.0271C322.951 26.37 319.243 29.5271 315.35 32.87C314.052 33.9843 315.906 35.8414 317.204 34.7271C321.097 31.3843 324.805 28.2271 328.698 24.8843C330.181 23.77 328.328 21.7271 326.844 23.0271Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="401" height="468" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ConfirmationImage;
