import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import List from './List';
import React from 'react';
import useTeacherClassesList from './classes-list-hook';
import TeacherSchoolFilter from '../../components/SchoolFilterTeacher';
import RoutingPaths from '../RoutingPath';
import DebounceSearchBar from '../../components/DebounceSearchBar';
import { useImitationProvider } from '../../core/imitationContext';
import TypeformEmbed from '../../components/TypeformEmbed';

const ClassList = () => {
  const {
    classes,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedSchool,
    onSchoolChange,
    rowsPerPage,
    setRowsPerPage,
    searchTerm,
    setSearchTerm,
    teacherDetails,
    onTypeformSubmit, onTypeformReady, typeformData, classCreatedCount,
    userId,
  } = useTeacherClassesList();

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box>
        <Typography variant="pageTitle">
            Classes
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        mt={2}
      >
        <Box width="70%">
          <DebounceSearchBar handleChange={(event)=>setSearchTerm(event.target.value)} searchTerm={searchTerm}/>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          width="100%"
          mt={2}
        >
          <Box width='20%' mr={1}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={RoutingPaths.CreateClass}
              sx={{ px: 4 }}
            >
              Add Class
            </Button>
          </Box>
          <Box width="30%">
            <TeacherSchoolFilter
              value={selectedSchool}
              onChange={onSchoolChange}
              idSelectOnly
              placeholder="All schools"
            />
          </Box>
        </Box>

      </Box>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading}
          totalCount={totalCount}
          order={order}
          pagination={pagination}
          sort={sort}
          classes={classes}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          teacherDetails={teacherDetails}
        />
      </Box>
      {
        !loading && isNotImitated &&
          typeformData && typeformData?.show_typeform && classCreatedCount > 0 &&
          <TypeformEmbed
            formId={typeformData?.typeform_form_id}
            typeformLayout='popover'
            onCloseHandler={onTypeformReady}
            onFormSubmittedHandler={onTypeformSubmit}
            hiddenButton={true}
            hiddenFields={ { user_id : userId } }
            buttonText='Feedback Form'/>
      }
    </Box>
  );
};

export default ClassList;
