/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BackPackIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    {...props}
  >
    <path d="M17.6 6.87661V20.6298C17.6 21.8905 16.61 22.922 15.4 22.922H2.2C0.99 22.922 0 21.8905 0 20.6298V6.87661C0 4.74486 1.408 2.9684 3.3 2.45266V0H6.6V2.2922H11V0H14.3V2.45266C16.192 2.9684 17.6 4.74486 17.6 6.87661ZM2.2 11.461V13.7532H13.2V16.0454H15.4V11.461H2.2Z" />
  </SvgIcon>
);

export default BackPackIcon;
