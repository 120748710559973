import React from 'react';
import { Box, CheckboxProps, InputAdornment, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import { Maybe, PredefinedGrades } from '../../generated/graphql';
import useGradesFilter from '../GradeFilter/grade-filter-hook';
import ClassesIcon from '../Icons/ClassesIcon';

interface Props {
  error?: boolean;
  source?: string[] | undefined,
  className?: string;
  value?: Maybe<string>[];
  onChange?: (newValues: any[]) => void;
  placeholder?: string;
  states?: [string] | undefined;
  districts?: [string] | undefined;
  schools?: [string] | undefined;
  textInputPlaceholder?: string;
}

const PredefinedGradeMultiSelect = ({
  placeholder,
  onChange,
  value,
  states,
  districts,
  schools,
  textInputPlaceholder,
}: Props) => {
  const {
    grades,
    loading,
  } = useGradesFilter({ states, districts, schools });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onSelectChange = (event: any, newValues: any) => {
    onChange?.(newValues?.map((grade: PredefinedGrades) => grade?.id));
  };

  return (
    <Autocomplete
      multiple
      limitTags={1}
      disableCloseOnSelect
      options={grades ?? []}
      getOptionLabel={(option: any) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      value={grades?.filter((grade) => value?.includes(grade?.id!)) ?? []}
      disabled={loading}
      onChange={onSelectChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Box sx={{ maxWidth: '180px' }}>
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.grade ?? ''}</Typography>}
              {...getTagProps({ index })}
            />
          </Box>
        ))
      }
      renderOption={(props, option: any, { selected }) => (
        <Box component="li" {...props}>
          <Checkbox
            color={'primary' as CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
          />
          {`${option?.grade ?? ''}`}
        </Box>
      )}
      renderInput={(params) => (
        <>
          {placeholder && <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>}
          <TextField
            {...params}
            placeholder={params.InputProps.startAdornment ? undefined : (!textInputPlaceholder ? 'Select Grades' : textInputPlaceholder)}
            error={!!value}
            required={value?.length === 0}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <ClassesIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default PredefinedGradeMultiSelect;
