/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Box, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotifierType } from '../../variables/types';
import { palette } from '../../theme/palette';
import MessageBox from './MessageBox';


const variantIcon = {
  success: faCheckCircle,
  warning: faExclamationTriangle,
  error: faExclamationCircle,
  info: faInfoCircle,
};

const styles = {
  success: {
    '&.MuiPaper-root.MuiSnackbarContent-root': {
      backgroundColor: green[600],
    },
  },
  error: {
    '&.MuiPaper-root.MuiSnackbarContent-root': {
      backgroundColor: palette.errors.dark,
    },
  },
  info: {
    '&.MuiPaper-root.MuiSnackbarContent-root': {
      backgroundColor: palette.customBlue.primaryBlue,
    },
  },
  warning: {
    '&.MuiPaper-root.MuiSnackbarContent-root': {
      backgroundColor: amber[700],
    },
  },
  icon: {
    fontSize: '20px',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '8px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  viewButton: {
    '&.MuiButtonBase-root': {
      marginLeft: '8px',
    },
  },
  primaryBackground: {
    '&.MuiPaper-root.MuiSnackbarContent-root': {
      backgroundColor: palette.customBlue.primaryBlue,
    },
  },
};
interface Props {
  message?: string;
  onClose?: () => void;
  variant: NotifierType;
  customIcon?: any,
  showViewButton?: boolean,
  onViewButtonClick?: Function,
}

const SnackbarContentWrapper = ({
  message,
  onClose,
  variant,
  customIcon: CustomIcon,
  showViewButton,
  onViewButtonClick,
} : Props) => {
  const icon = CustomIcon ? <CustomIcon sx={{ ...styles.icon, ...styles.iconVariant }} /> : (
    <FontAwesomeIcon
      icon={variantIcon[variant]}
      style={{ ...styles.icon, ...styles.iconVariant }}
    />
  );

  const viewButton = showViewButton && (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => onViewButtonClick?.()}
      sx={{ ...styles.viewButton }}
    >
      View
    </Button>
  );

  return (
    <SnackbarContent
      sx={{ ...styles[variant], ...(showViewButton) && styles.primaryBackground }}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" style={{ ...styles.message }}>
          {icon}
          <MessageBox message={message} />
          {viewButton}
        </span>
      )}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} style={{ ...styles.icon }} />
        </IconButton>,
      ]}
    />
  );
};

export default SnackbarContentWrapper;
