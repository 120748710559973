import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme?.zIndex?.drawer + 1,
  color: theme.palette.customWhite.main,
}));

type Props = {
  open: boolean
};

export default ({ open }: Props) => {
  return (
    <div>
      <StyledBackdrop open={open}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
    </div>
  );
};
