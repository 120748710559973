import { TeacherListForAdminBenchmarkInput, useTeacherListForAdminBenchmarkQuery } from '../../generated/graphql';


const useAdminTeacherFilter = ({ districtId, schoolIds, gradeIds }: any) => {
  const inputQuery: TeacherListForAdminBenchmarkInput = {
    district_id: districtId,
    school_ids: schoolIds || [],
    grade_ids: gradeIds || [],
  };

  const { data: teachers, loading: loadingTeachers } = useTeacherListForAdminBenchmarkQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: inputQuery,
    },
  });

  return {
    teachers: teachers?.teacherListForAdminBenchmark?.nodes ?? [],
    loadingTeachers,
  };
};

export default useAdminTeacherFilter;