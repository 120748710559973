/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const styles = {
  checkIcon: {
    '&.MuiSvgIcon-root': {
      fill: palette.customWhite.main,
    },
  },
};

const CheckedCircleIcon = (props: any) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill={palette.customWhite.main}
      {...props}
      sx={{ ...styles.checkIcon }}
    >
      <path
        d="M12.75 18L16.25 21.5L23.25 14.5M33.75 18C33.75 20.0683 33.3426 22.1164 32.5511 24.0273C31.7596 25.9381 30.5995 27.6744 29.1369 29.1369C27.6744 30.5995 25.9381 31.7596 24.0273 32.5511C22.1164 33.3426 20.0683 33.75 18 33.75C15.9317 33.75 13.8836 33.3426 11.9727 32.5511C10.0619 31.7596 8.32559 30.5995 6.86307 29.1369C5.40055 27.6744 4.24041 25.9381 3.4489 24.0273C2.65739 22.1164 2.25 20.0683 2.25 18C2.25 13.8228 3.90937 9.81677 6.86307 6.86307C9.81677 3.90937 13.8228 2.25 18 2.25C22.1772 2.25 26.1832 3.90937 29.1369 6.86307C32.0906 9.81677 33.75 13.8228 33.75 18Z"
        stroke={palette.fontColors.fontBlack}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default React.memo(CheckedCircleIcon);
