import { useStudentsFilterQuery } from '../../generated/graphql';

const useStudentsFilter = () => {
  const { data, loading } = useStudentsFilterQuery({
    fetchPolicy: 'network-only',
  });

  return {
    students: data?.students?.nodes ?? [],
    loading,
  };
};

export default useStudentsFilter;
