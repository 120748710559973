import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const CompletedIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width="16" height="16" rx="2" fill={palette.customBlue.primaryBlue}/>
  </SvgIcon>
);

export default CompletedIcon;
