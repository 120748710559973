/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SxProps, Theme } from '@mui/system';
import { palette  } from '../../theme/palette';


interface Props {
  // TODO - handle with correct type
  svg?: any;
  path?: any;
  sx?: SxProps<Theme> | undefined;
}

const ArrowDownIcon = (props: Props) => {
  let { path: pathProps, svg: svgProps } = props;

  // set default values
  if (!pathProps?.fill) {
    if (pathProps) {
      pathProps.fill = palette.customBlue.primaryBlue;
    } else {
      pathProps = { fill: palette.customBlue.primaryBlue };
    }
  }

  if (!svgProps?.fill) {
    if (svgProps) {
      svgProps.fill = palette.customBlue.primaryBlue;
    } else {
      svgProps = { fill: palette.customBlue.primaryBlue };
    }
  }

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      sx={props.sx}
      {...svgProps ?? {}}
    >
      <path
        d="M10.2374 1.26256C10.9209 1.94598 10.9209 3.05402 10.2374 3.73743L4.47487 9.5L10.2374 15.2626C10.9209 15.946 10.9209 17.054 10.2374 17.7374C9.55402 18.4209 8.44598 18.4209 7.76256 17.7374L0.762562 10.7374C0.0791452 10.054 0.0791453 8.94598 0.762563 8.26256L7.76256 1.26256C8.44598 0.579143 9.55402 0.579143 10.2374 1.26256Z"
        {...pathProps ?? {}}
      />
    </SvgIcon>
  );
};

export default ArrowDownIcon;
