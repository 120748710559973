import { styled } from '@mui/material/styles';
import {
  Button,
  DialogTitle,
  DialogContentText,
  Typography,
} from '@mui/material';

export const StyledNextButton = styled(Button)({
  width: '160px',
});

export const StyledNavigationButtonOne = styled(Button)(({ theme: { palette } }) => ({
  border: `solid 3px ${palette.primary.main}`,
  color: palette.primary.main,
  backgroundColor: palette.customWhite.main,
  '&:hover': {
    color: palette.primary.main,
    backgroundColor: palette.customWhite.main,
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme: { palette } }) => ({
  fontWeight: 800,
  fontSize: '32px',
  lineHeight: '32px',
  textAlign: 'center',
  color: palette.fontColors.fontBlack,
}));

export const StyledDialogContentText = styled(DialogContentText)(({ theme: { palette } }) => ({
  fontWeight: 600,
  fontSize: '17px',
  lineHeight: '25px',
  marginTop: '8px',
  textAlign: 'center',
  color: palette.customBlack.lightBlack,
}));

export const StyledDangerButton = styled(Button)(({ theme: { palette } }) => ({
  width: '150px',
  marginRight: '16px',
  backgroundColor: palette.customPink.main,
  color: palette.customRed.main,
  '&::after': {
    borderColor: palette.customPink.main,
  },
}));

export const StyledRecordingWarningLabel = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '25px',
  color: palette.customYellow.secondaryDark,
}));
