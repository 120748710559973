import { Theme } from '@mui/system';
import Radiobutton from './Radiobutton';
import Checkbox from './Checkbox';
import Button from './Button';
import Link from './Link';
import Input from './Input';
import Table from './Table';
import Dialog from './Dialog';
import Autocomplete from './Autocomplete';
import Tooltip from './Tooltip';
import MuiGenerics from './MuiGenerics';
import TextField from './TextField';
import Form from './Form';

const componentsOverride = (theme: Theme) =>
  Object.assign(
    Button,
    Link(),
    Radiobutton(theme),
    Checkbox(theme),
    Input,
    Table,
    Dialog,
    Autocomplete(theme),
    Tooltip(),
    MuiGenerics,
    TextField,
    Form,
  );

export default componentsOverride;
