import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Slide, Typography } from '@mui/material';
import { getMountainTimeZone } from '../../utils/timezone';
import { StyledSpan } from '../ImageUpload/UploadFile.styled';
import SuccessIcon from '../Icons/SuccessIcon';
import { boldFontWeight } from '../Common/Common.styled';
import { Cancel } from '@mui/icons-material';
import { DecoupleLogStatusType } from '../../generated/graphql';
import { StatusIcon, StatusIconProps } from '../../pages/DistrictSync/DistrictSyncTable/List';

export const DecoupleStatusIcon = ({ status, noMargin = true }: StatusIconProps) => {
  const leftIcon = noMargin ? 0 : 1;
  const leftStatus = noMargin ? 1 : 0.5;
  if (status === DecoupleLogStatusType.Failed) {
    return (
      <Box display="flex">
        <Box ml={leftIcon}><Cancel color="error" /></Box>
        <Box ml={leftStatus}>
          <Typography sx={{ ...((!noMargin) && boldFontWeight.boldFont) }}>{status}</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box display="flex">
        <Box ml={leftIcon}><SuccessIcon /></Box>
        <Box ml={leftStatus}>
          <Typography sx={{ ...((!noMargin) && boldFontWeight.boldFont) }}>{status}</Typography>
        </Box>
      </Box>
    );
  }
};
const DecoupleViewDetailsModal = ({ decoupleLog, openDialog, handleClose }: any) => {
  return (
    <Dialog open={openDialog} onClose={handleClose} TransitionComponent={Slide}>
      <DialogContent>
        <Grid color="text.secondary" container direction='column' spacing={2.5}>
          <Grid item>
            <Typography variant='secondaryTitle'>
              {getMountainTimeZone(decoupleLog?.created_at, '')}
              {' Decouple by '}
              {
                decoupleLog?.created_by.first_name + ' ' + decoupleLog?.created_by.last_name
              }
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>{'Started: '}</b>
              <StyledSpan>{getMountainTimeZone(decoupleLog?.created_at, '')}</StyledSpan>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>{'Completed: '}</b>
              <StyledSpan>{getMountainTimeZone(decoupleLog?.updated_at, '')}</StyledSpan>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Box display="flex">
                <Box sx={{ marginRight: '5px' }}>{'Status: '}</Box>
                <StatusIcon status={decoupleLog?.status} noMargin/>
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {'Message: '}
              <StyledSpan>{decoupleLog?.message || '-'}</StyledSpan>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingX: 0, marginLeft: 'auto'  }} >
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
            CLOSE
        </Button>
      </DialogActions>
    </Dialog>);
};

export default DecoupleViewDetailsModal;
