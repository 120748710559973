import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { openSnackbar } from '../Notifier';
import { NotifierType } from '../../variables/types';
import { palette } from '../../theme/palette';


const DecoupleDistrictModal = ({ title, districtID, decoupleDistrictHandler, toggleDecoupleDistrictOpen, decoupleDistrictOpen  }: any) => {
  const decoupleText = 'decouple';
  const [decoupleTextCorrect, setDecoupleTextCorrect] = useState(false);

  const handleDecoupleDistrictClose = () => {
    setDecoupleTextCorrect(false);
    toggleDecoupleDistrictOpen();
  };

  const handleDecoupleDistrictConfirm = () => {
    try {
      decoupleDistrictHandler?.(districtID);
    } catch (er) {
      openSnackbar({ message: 'Failed to decouple district. Please try again later.' }, NotifierType.Error);
      console.log(er);
    }
    handleDecoupleDistrictClose();
  };

  return (
    <Dialog
      open={decoupleDistrictOpen}
      onClose={toggleDecoupleDistrictOpen}
      PaperProps={{
        sx: {
          borderTop: `12px solid ${palette.customBlue.primaryBlue}`,
        },
      }}
    >
      <DialogTitle sx={{ paddingTop: 0, textAlign: 'center' }} >
        <Box sx={{ marginTop: 2 }}>
          <Typography variant='modalTitle1'>
            Decouple {title}?
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <Box>
            <Typography variant={'body1'}>Once this district is decoupled:</Typography>
            <ul style={{ paddingLeft: 12 }}>
              <li ><Typography variant='body1'> This is a <b>complete disconnection</b> from the prior data source. Any additional changes you make will need to be made manually in the FL360 app.</Typography></li>
              <li ><Typography variant='body1'>If you need to sync again, you will need to archive this district and start from scratch.</Typography></li>
              <li ><Typography variant='body1'> Decoupling <b>must be completed at the end of the day</b> as the customer will be unable to log in while this action occurs.</Typography></li>
            </ul>
          </Box>
          <Box sx={{ marginTop: 2, textAlign:'center' }}>
            <Typography variant='tableTitleData' sx={{ fontWeight: 700, marginY: 4 }}>
              Type “decouple” to confirm
            </Typography>
            <TextField
              id="Decouple-district-confirm"
              name="Decouple-district-confirm"
              aria-describedby="Decouple-district-confirm"
              fullWidth
              autoFocus
              autoComplete="off"
              onChange={(e: any) => {
                setDecoupleTextCorrect(e.target.value === decoupleText);
              }}
            />
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingX: 0, marginLeft: 'auto' }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDecoupleDistrictClose}
        >
              Close
        </Button>
        <Button
          variant="contained"
          onClick={handleDecoupleDistrictConfirm}
          disabled={!decoupleTextCorrect}
        >
              Continue
        </Button>
      </DialogActions>

    </Dialog>
  );
};

export default DecoupleDistrictModal;
