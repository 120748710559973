import React from 'react';
import { Box, Checkbox, TextField, Typography } from '@mui/material';
import AppBreadcrumbs, { BreadcrumbItem } from '../../../../components/AppBreadcrumbs';
import Loader from '../../../../components/Loader/loader';
import { NON_SPACE_REGEX } from '../../../../variables/constant';
import { StyledSaveButton } from '../../../Districts/CreateDistrict/create-district.styled';
import { StyledFlexBox } from '../../../../components/Common/Common.styled';
import { palette } from '../../../../theme/palette';
import { useHistory } from 'react-router-dom';
import { adminToolsRoute, superAdminRoute } from '../../../../variables/staticRoutes';
import SuperAdminIcon from '../../../../components/Icons/SuperAdminIcon';
import useSuperAdminForm from './superadmin-form-hook';
import { formStyles } from '../../../Admin/admin-tables-styled';
import { errorMessages, labels } from '../../../Admin/constants';

const SuperAdminForm = () => {
  const {
    loading,
    errors,
    handleSubmit,
    control,
    register,
    createAdmin,
    checked,
    handleChange,
    adminID,
    superAdminDetails,
  } = useSuperAdminForm();
  const breadcrumb: BreadcrumbItem[] = [
    adminToolsRoute,
    superAdminRoute,
    {
      label: adminID ? 'Edit' : 'Create Super Admin',
      route: adminID ? '/settings/super-admin/create' : `/settings/super-admin/create/${adminID}`,
    }];
  const formTitle = adminID ? `Edit ${superAdminDetails?.first_name} ${superAdminDetails?.middle_name} ${superAdminDetails?.last_name}` : 'Add a New Super Admin';
  const history = useHistory();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
      >
        <Box>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
          {!loading && (<Box
            display="flex"
            justifyContent="flex-start"
            alignItems='baseline'
          >
            <SuperAdminIcon
              pathfill={palette.customBlue.primaryBlue}
              sx={{
                height: '30px',
                width: '30px',
                mr: '16px',
              }}
            />
            <Typography variant="pageTitle" sx={{ textTransform: 'none' }}>
              {formTitle}
            </Typography>
          </Box>)}
        </Box>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createAdmin)}
          noValidate
        >
          <Box mt={2}>
            <Box
              display="flex"
              flexDirection="row"
              flexGrow={1}
            >
              <Box mr={3}>
                <Typography variant='sFormTitle' component='p'> First Name </Typography>
                <TextField
                  id="first-name"
                  aria-describedby="first-name"
                  name="first_name"
                  sx={{ ...formStyles.titleInput, width: '250px' }}
                  error={!!errors.first_name}
                  helperText={errors.first_name && errorMessages.firstNameHelperText}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
              <Box mr={3}>
                <Typography variant='sFormTitle' component='p'> Middle Name(Optional) </Typography>
                <TextField
                  id="middle-name"
                  aria-describedby="middle-name"
                  name="middle_name"
                  sx={{ ...formStyles.titleInput, width: '250px' }}
                  error={!!errors.middle_name}
                  helperText={errors.middle_name && errorMessages.middleNameHelperText}
                  inputRef={register()}
                />
              </Box>

            </Box>
            <Box >
              <Typography variant='sFormTitle' component='p'> Last Name </Typography>
              <TextField
                id="last-name"
                aria-describedby="last-name"
                name="last_name"
                sx={{ ...formStyles.textFieldSuperAdmin }}
                error={!!errors.last_name}
                helperText={errors.last_name && errorMessages.lastNameHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
              />
            </Box>

            <StyledFlexBox sx={{ mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Admin ID</Typography>
              <TextField
                id="admin-id"
                aria-describedby="admin-id"
                name="admin_id"
                sx={{ ...formStyles.textFieldSuperAdmin }}
                error={!!errors.admin_id}
                helperText={errors.admin_id && errorMessages.adminIDHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                  pattern: NON_SPACE_REGEX,
                })}
              />
            </StyledFlexBox>
            <StyledFlexBox sx={{ mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Job Title(Optional)</Typography>
              <TextField
                id="title"
                aria-describedby="title"
                name="title"
                sx={{ ...formStyles.textFieldSuperAdmin }}
                error={!!errors.title}
                helperText={errors.title && errorMessages.jobTitleHelperText}
                inputRef={register()}
              />
            </StyledFlexBox>
            <StyledFlexBox sx={{ mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Email</Typography>
              <TextField
                id="email"
                aria-describedby="email"
                name="email"
                sx={{ ...formStyles.textFieldSuperAdmin, ...formStyles.lowerCaseTextFieldAdmin }}
                error={!!errors.email}
                helperText={errors.email && errorMessages.emailHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
              />
            </StyledFlexBox>
            <StyledFlexBox sx={{ mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Phone Number(Optional)</Typography>
              <TextField
                id="phone-number"
                aria-describedby="phone-number"
                name="phone_number"
                sx={{ ...formStyles.textFieldSuperAdmin }}
                inputRef={register()}
              />
            </StyledFlexBox>
            {
              !adminID && (
                <StyledFlexBox sx={{ mt: 0 }}>
                  <Typography variant='sFormTitle' component='p'>Password</Typography>
                  <TextField
                    id="password"
                    aria-describedby="password"
                    name="password"
                    sx={{ ...formStyles.textFieldSuperAdmin }}
                    error={!!errors.password}
                    helperText={errors.password && errorMessages.passwordHelperText}
                    inputRef={register({
                      required: true,
                      validate: (value) => !!value.trim(),
                    })}
                  />
                </StyledFlexBox>
              )
            }
          </Box>
          {
            !adminID && (
              <Box display="flex" justifyContent="flex-start" sx={{ mt: 4, ml: -1.5 }}>
                <Checkbox
                  id="reopen-create-class-form"
                  aria-describedby="reopen-create-class-form"
                  name="reopen_create_checkbox"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  inputRef={register({
                    required: false,
                  })}
                />
                <Typography variant='sLabel' sx={{ pt: 1 }}>{labels.superAdminCheckBoxLabel}</Typography>
              </Box>
            )
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2, minWidth: '500px' }}>

            <StyledSaveButton
              variant="outlined"
              onClick={() => { history.push(adminID ? `/settings/super-admin/${adminID}` : '/settings/super-admin'); }}
            >
              Cancel
            </StyledSaveButton>
            <StyledSaveButton
              variant="contained"
              color="primary"
              type="submit"
              sx={{ width: '220px' }}

            >
              {adminID ? 'Save Changes' : 'Add Super Admin'}
            </StyledSaveButton>
          </Box>
        </form >
      </Box >
      <Loader open={loading} />
    </>
  );
};

export default SuperAdminForm;
