import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useRedirect from '../../../hooks/useRedirect';
import useSpeaking from './speaking-hook';
import { GraphSetData, AnalyticsTabs, SpeakingSkills } from '../../../variables/types';
import BackdropModal from '../../../components/BackdropModal/BackdropModal';
import ScoreTable from '../PerformanceDetails/ScoreTable';
import PerformanceGraph from '../PerformanceDetails/PerformanceGraph';
import { AnalyticsStylesCSS } from '../Analytics.styled';

const Speaking = () => {
  const theme = useTheme();
  const styles = AnalyticsStylesCSS(theme);
  const { navigateTo: navigateToPath } = useRedirect();
  const {
    grammarData,
    grammarCount,
    grammarImage,
    vocabularyData,
    vocabularyCount,
    vocabularyImage,
    pronunciationData,
    pronunciationCount,
    pronunciationImage,
    interpretationData,
    interpretationCount,
    interpretationImage,
    fluencyData,
    fluencyCount,
    fluencyImage,
    loading,
    showPreview,
    setTableData,
    tableData,
    togglePreview,
  } = useSpeaking();

  const navigateTo = (route: string) => () => {
    navigateToPath(route);
  };

  const handleModal = (data: GraphSetData | null) => {
    setTableData(data);
    togglePreview();
  };

  return (
    <Box
      display="grid"
      sx={{
        ...styles.container,
        gridGap: 24,
      }}
      my={4}
      gridTemplateColumns="repeat(3, 1fr)"
    >
      <PerformanceGraph
        data={grammarData}
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Speaking}/details/${SpeakingSkills.Grammar}`)}
        onLinkClick={handleModal}
        title="Grammar"
        totalCount={grammarCount}
        loading={loading}
        iconString={grammarImage}
        clickToRedirect
      />
      <PerformanceGraph
        data={vocabularyData}
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Speaking}/details/${SpeakingSkills.Vocabulary}`)}
        onLinkClick={handleModal}
        title="Vocabulary"
        totalCount={vocabularyCount}
        loading={loading}
        iconString={vocabularyImage}
        clickToRedirect
      />
      <PerformanceGraph
        data={pronunciationData}
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Speaking}/details/${SpeakingSkills.Pronunciation}`)}
        onLinkClick={handleModal}
        title="Pronunciation"
        totalCount={pronunciationCount}
        loading={loading}
        iconString={pronunciationImage}
        clickToRedirect
      />
      <PerformanceGraph
        data={interpretationData}
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Speaking}/details/${SpeakingSkills.Interpretation}`)}
        onLinkClick={handleModal}
        title="Interpretation"
        totalCount={interpretationCount}
        loading={loading}
        iconString={interpretationImage}
        clickToRedirect
      />
      <PerformanceGraph
        data={fluencyData}
        handleButtonClick={navigateTo(`/analytics/${AnalyticsTabs.Speaking}/details/${SpeakingSkills.Fluency}`)}
        onLinkClick={handleModal}
        title="Fluency"
        totalCount={fluencyCount}
        loading={loading}
        iconString={fluencyImage}
        clickToRedirect
      />
      <BackdropModal
        open={showPreview}
        onClose={togglePreview}
      >
        <Box sx={styles.dropContent}>
          <ScoreTable data={tableData} onClose={togglePreview} hoverData />
        </Box>
      </BackdropModal>
    </Box>
  );
};

export default React.memo(Speaking);
