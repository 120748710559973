import React, { useContext } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { matchPath, useHistory } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import { PTSubmissionSteps } from '../../../variables/types';
import ProgressBar from '../../../components/ProgressBar';
import HelpModal from './HelpModal';
import { SubmissionStatus, SubmissionTypeGoalFragment } from '../../../generated/graphql';
import GoalsIcon from '../../../components/Icons/GoalsIcon';
import audio from './LottieData/audio';
import tagging from './LottieData/tagging';
import writing from './LottieData/writing';
import SpeakingWritingPrompt from './SpeakingWritingPrompt';
import { SubmitTaskContext } from '../submit-task-context';
import { palette } from '../../../theme/palette';
import {
  SPEAKING_DEFAULT_ASSIGNMENT_PROMPT_TEXT,
  SPEAKING_DEFAULT_BENCHMARK_PROMPT_TEXT,
  WRITING_DEFAULT_PROMPT_TEXT,
} from '../../../variables/constant';

const styles = {
  icon: {
    marginRight: 1,
  },
  titleIcon: {
    '&.MuiSvgIcon-root': {
      height: '30px',
      width: '30px',
      marginRight: 3,
    },
  },
  helpButton: {
    width: '120px',
    '&.MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  helpIconButton: {
    padding: 2.5,
    marginRight: '10px',
    '&.MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  helpText: {
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: 2,
    letterSpacing: '1px',
    color: palette.customYellow.secondaryDark,
  },
  helpTextGoals: {
    color: palette.customBlue.primaryBlue,
  },
  goalTitle: {
    fontWeight: 700,
    color: palette.fontColors.fontBlack,
    fontSize: '18px',
    lineHeight: '25px',
    marginBottom: '8px',
  },
  goalExample: {
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
};

interface Props {
  status?: SubmissionStatus;
  setShowHelp: Function;
  showHelp: boolean;
  setShowGoals: Function;
  showGoals: boolean;
  currentGoal: SubmissionTypeGoalFragment | null;
  submissionStep: string;
  isBenchmark: boolean;
}

const TopBar = ({
  status,
  showHelp,
  setShowHelp,
  setShowGoals,
  showGoals,
  currentGoal,
  submissionStep,
  isBenchmark,
}: Props) => {
  const { setPlaying, setIsSpeakingWritingPromptPlaying } = useContext(SubmitTaskContext);

  const getProgress = () => {
    switch (status && submissionStep) {
      case SubmissionStatus.Started && PTSubmissionSteps.Labeling:
        return 0;
      case SubmissionStatus.AnnotationCompleted && PTSubmissionSteps.Speaking:
        return 1;
      case SubmissionStatus.SpeakingCompleted && PTSubmissionSteps.Writing:
        return 2;
      case SubmissionStatus.Submitted:
        return 2;
      default:
        return 0;
    }
  };

  const progressMilestoneList = ['Labeling', 'Speaking', 'Writing'];

  const { location } = useHistory();
  const getHelpDialogData = () => {
    let dialogTitle;
    let dialogContent;
    let dialogImage;
    let lottieData;
    let dialogAudio;
    dialogTitle = 'Click and Label';
    dialogContent = 'Label the people, places, objects and actions in the picture.';
    dialogImage = '/images/gif/tagging.gif';
    dialogAudio = '/audio/Label.mp3';
    lottieData = tagging;

    if (matchPath(location.pathname, '/tasks/:id/submit/annotation')) {
      dialogTitle = 'Click and Label';
      dialogContent = 'Label the people, places, objects and actions in the picture.';
      dialogImage = '/images/gif/tagging.gif';
      dialogAudio = '/audio/Label.mp3';
      lottieData = tagging;
    } else if (matchPath(location.pathname, '/tasks/:id/submit/speaking')) {
      dialogTitle = 'Voice Recording';
      dialogContent = isBenchmark ? SPEAKING_DEFAULT_BENCHMARK_PROMPT_TEXT : SPEAKING_DEFAULT_ASSIGNMENT_PROMPT_TEXT;
      dialogImage = '/images/gif/audio.gif';
      dialogAudio = isBenchmark ? '/audio/BenchmarkRecording.mp3' : '/audio/Recording.mp3';
      lottieData = audio;
    } else if (matchPath(location.pathname, '/tasks/:id/submit/writing')) {
      dialogTitle = 'Writing';
      dialogContent = WRITING_DEFAULT_PROMPT_TEXT;
      dialogImage = '/images/gif/writing.gif';
      dialogAudio = '/audio/Writing.mp3';
      lottieData = writing;
    }

    return {
      dialogContent,
      dialogImage,
      dialogTitle,
      dialogAudio,
      lottieData,
    };
  };

  const { dialogTitle, dialogContent, dialogImage, dialogAudio, lottieData } = getHelpDialogData();
  const goalDialogTitle = `Your teacher gave you a ${
    matchPath(location.pathname, '/tasks/:id/submit/speaking') ? 'speaking' : ''
  }${matchPath(location.pathname, '/tasks/:id/submit/writing') ? 'writing' : ''} goal!`;
  const goal = currentGoal?.goal_details?.predefined_goals?.[0]?.goal_list?.[0] ?? null;
  const goalTitle = goal?.description;
  const goalExample = goal?.example;
  const handleHelpDailogClose = () => {
    setShowHelp(false);
    setPlaying?.(false);
  };
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={2} container justifyContent="flex-start">
          <ProgressBar steps={progressMilestoneList} progressValue={getProgress()} />
        </Grid>
        <Grid item xs={8} container justifyContent="space-around">
          {submissionStep !== PTSubmissionSteps.Labeling && (
            <SpeakingWritingPrompt
              taskType={submissionStep}
              setIsPlaying={setIsSpeakingWritingPromptPlaying}
              defaultPromptAudioUrl={dialogAudio}
            />
          )}
        </Grid>
        <Grid item xs={2} justifyContent="flex-end">
          <Box display={'flex'}>
            {!!currentGoal && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowGoals(true)}
                sx={{ ...styles.helpButton }}
              >
                <GoalsIcon sx={{ ...styles.icon }} pathFill={palette.customBlue.primaryBlue} />
                <Typography sx={{ ...styles.helpText, ...styles.helpTextGoals }}>Goals</Typography>
              </Button>
            )}
            <IconButton color="secondary" onClick={() => setShowHelp(true)} sx={{ ...styles.helpIconButton }}>
              <HelpIcon fontSize="large" sx={{ color: palette.customOrange.main }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <HelpModal
        openDialog={showHelp}
        closeDialog={() => setShowHelp(false)}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        dialogImage={dialogImage}
        dialogAudioText={dialogContent}
        dialogAudio={dialogAudio}
        isLottie
        lottieData={lottieData}
        actions={[
          {
            title: 'Ok',
            event: () => {
              handleHelpDailogClose();
            },
            buttonVariant: 'contained',
            buttonColor: 'primary',
            btnType: 'default',
          },
        ]}
      />
      <HelpModal
        openDialog={showGoals}
        closeDialog={() => setShowGoals(false)}
        dialogTitle={goalDialogTitle}
        dialogContent={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            p={2}
            borderRadius="8px"
            border={`2px solid ${palette.customBackground.disabled}`}
          >
            <Typography variant="font22" component="p" sx={{ lineHeight: '32px', marginBottom: '16px' }}>
              Here&apos;s your challenge:
            </Typography>
            <Typography variant="sTaskTitle" component="p" sx={{ marginBottom: '8px', fontWeight: 700 }} align="left">
              {goalTitle}
            </Typography>
            <Typography sx={{ ...styles.goalTitle, ...styles.goalExample }} align="left">
              {goalExample}
            </Typography>
          </Box>
        }
        titleIcon={<GoalsIcon sx={{ ...styles.icon, ...styles.titleIcon }} pathFill={palette.customBlue.primaryBlue} />}
        actions={[
          {
            title: "Let's Go!",
            event: () => setShowGoals(false),
            buttonVariant: 'contained',
            buttonColor: 'primary',
            btnType: 'default',
          },
        ]}
      />
    </>
  );
};

export default React.memo(TopBar);
