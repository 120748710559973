import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { SubmissionOptions } from '../../../AdminBenchmarks/Grade/GradeFilters';
import { PAGE_SIZE } from '../../../../variables/constant';
import { StyledFilterButton } from '../../../AdminAnalytics/AnalyticsFilters/AnalyticsFilters.styled';
import BenchmarkMultiTypeSelectFilter from '../../../../components/BenchmarkMultiTypeSelectFilter';
import GradeStatusFilter from '../../../../components/GradeStatusFilter';
import GradedByFilter from '../../../../components/GradedByFilter';
import { GradedByOptions } from '../../../AdminBenchmarks/Create/Steps/DetailsStep';


const GradeFilters = ({
  selectedBenchmarkTypes,
  setSelectedBenchmarkTypes,
  selectedStatus,
  setSelectedStatus,
  handleApplyFilters,
  handleClearFilters,
  totalCount,
  page = 1,
  selectedGradedBy,
  setSelectedGradedBy,
}: any) => {

  const pageMax = page * PAGE_SIZE;

  const gradedByOptions = [...GradedByOptions, {
    label: 'Graded By All',
    value: 'all',
  }] as any[];

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <GradeStatusFilter
            onChange={(value:any) => value ? setSelectedStatus?.(value) : setSelectedStatus?.(undefined)}
            status={selectedStatus}
            options={SubmissionOptions}
            textInputPlaceholder='All Submissions'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <BenchmarkMultiTypeSelectFilter
            onChange={(values: any) => setSelectedBenchmarkTypes?.(values)}
            value={selectedBenchmarkTypes ?? []}
            textInputPlaceholder='All Benchmarks'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <GradedByFilter
            onChange={(value: any) => setSelectedGradedBy?.(value)}
            gradedBy={selectedGradedBy}
            options={gradedByOptions}
            textInputPlaceholder='Graded By All'
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
        <Grid item>
          <Grid item container spacing={2}>
            <Grid item>
              <StyledFilterButton
                variant="contained"
                color='inherit'
                startIcon={<SyncIcon color="primary" fontSize="medium" />}
                onClick={() => handleClearFilters?.()}
              >
                Clear all filters
              </StyledFilterButton>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleApplyFilters?.()}
              >
                Apply filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item justifyContent="center" spacing={2} pt={4} pr={2}>
          <Typography variant="sLabel">
            {totalCount ? `${pageMax - PAGE_SIZE + 1}-${totalCount < pageMax ? totalCount : pageMax} of ${totalCount}` : '0-0 of 0'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default GradeFilters;
