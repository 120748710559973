import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Admin from './Admin';
import AdminsTable from './AdminsTable';
import Layout from '../../components/Layout';
import { AdminType } from '../../variables/types';
import RoutingPaths from '../RoutingPath';

const Admins = () => (
  <Layout>
    <Switch>
      <Route
        path={RoutingPaths.AdminsTable}
        component={AdminsTable}
        exact
      />
      <Route
        path={[`${RoutingPaths.Admins}/:type/:id`, `${RoutingPaths.Admins}/:type/:id/:sectionType`]}
        component={Admin}
        exact
      />
      <Redirect from={RoutingPaths.Admins} to={`${RoutingPaths.Admins}/${AdminType.School}`} exact />
    </Switch>
  </Layout>
);

export default React.memo(Admins);
