import { useAuthProvider } from '../../../core/authContext';

const useRubricIndicatorAndStandards = () => {
  const { getUser } = useAuthProvider();
  const user = getUser();

  return {
    user,
  };
};

export default useRubricIndicatorAndStandards;
