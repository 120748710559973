import React from 'react';
import { InputAdornment, SelectChangeEvent } from '@mui/material';;
import { palette } from '../../theme/palette';
import GradeSelectionIcon from '../Icons/GradeSelectionIcon';
import { svgStyles } from '../Common/Common.styled';
import { BenchmarkGradedBy } from '../../generated/graphql';
import SelectDropDown from '../SelectDropDown';

interface Props {
  onChange: (value: BenchmarkGradedBy | undefined) => void;
  gradedBy: string | undefined;
  options: any[];
  placeholder?: string;
  textInputPlaceholder?: string;
}

const GradedByFilter = ({ onChange, gradedBy, options, placeholder, textInputPlaceholder }: Props) => {
  const handleOptionChange = (event: SelectChangeEvent<string[]>) => {
    onChange(event.target.value as BenchmarkGradedBy);
  };

  return (
    <SelectDropDown
      onChange={handleOptionChange}
      selectedItem={gradedBy}
      options={options ?? []}
      startAdornment={<>
        <InputAdornment position="start">
          <GradeSelectionIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }} />
        </InputAdornment>

      </>}
    />
  );
};

export default GradedByFilter;
