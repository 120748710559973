/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box, Button,
  Slide, SxProps,
} from '@mui/material';
import TileItem from './TileItem';
import { StyledTypography } from './DashboardFeedbackTiles.styled';
import { CrossIcon } from '../Icons';
import { UnReadFeedbacksFragment } from '../../generated/graphql';
import { isIpad } from '../../variables/constant';
import { StyledSeeNext } from '../../pages/StudentDashboard/StudentDashboard.styled';
import { palette } from '../../theme/palette';
import { Direction, sortByDate } from '../../utils/sort';
import { unescapeSpecialCharacters } from '../../utils/handleEscapeCharacters';

interface Props {
  data?: UnReadFeedbacksFragment[]
  onNextExit: (submissionIds: string[]) => void
  onClose?: () => void
  progressRedirect: (submissionIds: string) => void
}

const feedbackStyles = {
  shadowStyleDefault: {
    width: 'calc(100% - 20px)',
    borderRadius: 12,
    position: 'absolute',
    height: 200,
    zIndex: 8,
    bottom: -5,
    left: 18,
    right: 0,
    backgroundColor: palette.customGrey.lighterGrey,
    border: `2px solid ${palette.customBackground.disabled}`,
  },
  shadowStyle: {
    bottom: '-14px',
    left: '42px',
    zIndex: 7,
    width: 'calc(100% - 65px)',
    backgroundColor: palette.customBackground.loginBackground,
  },
  crossWrapper: {
    cursor: 'pointer',
    '&.MuiButton-root': {
      color: palette.customWhite.main,
      '&:hover': {
        backgroundColor: palette.transparent.main,
      },
    },
  },
  deviceMedia: {
    fontSize: '12px',
    padding: '12px',
    height: '40px',
    width: '100px',
    position: 'absolute',
    right: '0px',
    bottom: '50px',
  },
};


const DashboardFeedbackTiles = ({ data, onNextExit, onClose, progressRedirect }: Props) => {
  const next = () => {
    const submissionId = data?.[0]?.id;
    if (submissionId) {
      onNextExit([submissionId]);
    }
  };

  const renderShadows = () => {
    if (data?.length! > 1) {
      return (
        <>
          <Box sx={{ ...feedbackStyles.shadowStyleDefault as SxProps }} />
          <Box sx={{ ...feedbackStyles.shadowStyleDefault, ...feedbackStyles.shadowStyle } as SxProps} />
        </>
      );
    }
    return true;
  };
  const renderBlueHeader = () => {
    if (data?.length) {
      return (
        <Box sx={{
          padding: '12px 36px',
          background: palette.customBlue.primaryBlue,
          borderRadius: '12px 12px 0 0',
          display: 'flex',
          justifyContent: 'space-between',
          color: palette.customWhite.main,
        }}
        >
          <StyledTypography sx={{ mt: 1.2 }}>You received teacher feedback</StyledTypography>
          <Button
            sx={{ ...feedbackStyles.crossWrapper }}
            onClick={onClose}
          >
            <CrossIcon />
          </Button>
        </Box>
      );
    }
    return true;
  };

  const sortFeedBacks = (data: any[]) => {
    const sortedFeedbacks = data?.sort(sortByDate({ direction: Direction.Down, name: 'submitted_at' })
    );
    return sortedFeedbacks
  }
  const sorted_feedback = sortFeedBacks(data || [])
  return (
    <Box sx={{ position: 'relative', height: 385 }} >
      {renderBlueHeader()}
      <Box
        position="relative"
        zIndex={9}
      >
        {sorted_feedback?.map((feedback: UnReadFeedbacksFragment) => {
          const assignmentOrBenchmark = feedback?.assignment ?? feedback?.getBenchmark;
          const resourceFile = assignmentOrBenchmark?.resource?.file;
          return (
            <Slide
              key={feedback?.id}
              direction="left"
              in={data?.[0]?.id === feedback?.id}
              mountOnEnter
              unmountOnExit
            >
              <Box>
                <TileItem
                  imageSrc={resourceFile?.thumbnail_url ? resourceFile?.thumbnail_url : resourceFile?.url || ''}
                  teacherFeedback={unescapeSpecialCharacters(feedback?.getAdditionalFeedback?.feedback_notes || '')}
                  assignmentDate={feedback?.submitted_at}
                  assignmentName={assignmentOrBenchmark?.title}
                  audioFileID={feedback?.getAdditionalFeedback?.audio[0]?.url}
                  submissionId={feedback?.id}
                  progressRedirect={progressRedirect}
                />
                {sorted_feedback?.length > 1
                  && (
                    <StyledSeeNext
                      size="large"
                      variant="outlined"
                      onClick={next}
                      sx={{ ...(isIpad) && feedbackStyles.deviceMedia }}
                    >
                      See Next
                    </StyledSeeNext>
                  )}
              </Box>
            </Slide>
          );
        },
        )}
      </Box>
      {renderShadows()}
    </Box>
  );
};
export default DashboardFeedbackTiles;
