import { styled, Tab, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '&.MuiTabs-root': {
    border: theme.spacing(0),
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));


export const StyledAllTab  = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    backgroundColor: theme.palette.primary.main,
    '&.MuiButtonBase-root': {
      marginRight: theme.spacing(2),
      borderBottom: 0,
      borderRadius: theme.spacing(1.25, 1.25, 0, 0),
      color: theme.palette.fontColors.fontBlack,
      textTransform: 'none',
      backgroundColor: theme.palette.customGrey.main,
    },
    '& .tag': {
      borderRadius: '45%',
      color: theme.palette.fontColors.fontBlack,
      width: theme.spacing(4.375),
      display: 'inline-block',
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.customWhite.main,
    },
    '&.Mui-selected': {
      '&.MuiButtonBase-root': {
        color: theme.palette.customWhite.main,
        backgroundColor: theme.palette.primary.main,
      },
      '& .tag': {
        backgroundColor: `${theme.palette.customWhite.main} !important`,
        color: `${theme.palette.fontColors.fontBlack} !important`,
      },
    },
  },
}));