import { useContext } from 'react';
import { FeatureFlagContext } from '../core/featureContext';

const useFeature = (feature: string) => {
  const { featureFlags } = useContext(FeatureFlagContext);
  const isEnabled = featureFlags?.[feature] || false;
  return {
    isEnabled,
  };
};

export default useFeature;
